import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import CompanyLogo from './CompanyLogo'
import Switch from '../../../shared/components/UIElements/Switch'

import styles from './CompanyTool.module.css'

const CompanyTool = () => {
  const dispatch = useDispatch()
  const { companyDetails, updateCompanyDetails, draftConfiguration } = useEnhencedCatalog()
  const userImage = useSelector(state => state.auth.user.image)
  const userBgRemovedImage = useSelector(
    state => state.auth.user.bgRemovedImage,
  )
  const [loading, setLoading] = useState(false)

  const [updatedUserImage, setUpdatedUserImage] = useState(userImage)
  const [updatedUserBgRemovedImage, setUpdatedUserBgRemovedImage] =
    useState(userBgRemovedImage)

  const [isVisible, setIsVisible] = useState(draftConfiguration?.showCompanyLogo)
  const [isExiting, setIsExiting] = useState(false)

  useEffect(() => {
    setUpdatedUserImage(userImage)
    setUpdatedUserBgRemovedImage(userBgRemovedImage)
  }, [userImage, userBgRemovedImage])

  const handleVisibilityChange = (value) => {
    if (value === 'no' && !isExiting) {
      setIsExiting(true)
      setTimeout(() => {
        dispatch(
          aiCreativeEditorActions.setDraftConfiguration({
            showCompanyLogo: false,
          })
        )
        setIsExiting(false)
      }, 300) // animation duration
    } else {
      dispatch(
        aiCreativeEditorActions.setDraftConfiguration({
          showCompanyLogo: value === 'yes',
        })
      )
    }
  }

  useEffect(() => {
    setIsVisible(draftConfiguration?.showCompanyLogo)
  }, [draftConfiguration?.showCompanyLogo])

  return (
    <>
      <div className={`${styles.toolbarBoxInsideItem} ${styles.enhencedToolItem}`}>
        <div className={styles.toolbarBoxInsideItemTitle}>
          Show Company Logo
        </div>
        <div className={styles.toolbarBoxInsideItemContent}>
          <Switch
            value={draftConfiguration?.showCompanyLogo ? 'yes' : 'no'}
            onChange={handleVisibilityChange}
            options={['no', 'yes']}
            size="small"
          />
        </div>
      </div>
      <div className={styles.conditionalContent}>
        {(isVisible || isExiting) && (
          <>
            <div className={styles.dividerLine}></div>
            <div className={`${styles.toolbarBoxInsideItem} ${styles.enhencedToolItem}`}>
              <div className={styles.toolbarBoxInsideItemTitle}>Company Logo</div>
              <div className={styles.toolbarBoxInsideItemContent}>
                <CompanyLogo loading={loading} setLoading={setLoading} />
              </div>
            </div>
            <div className={styles.dividerLine}></div>
            <div className={`${styles.toolbarBoxInsideItem} ${styles.enhencedToolItem}`}>
              <div className={styles.toolbarBoxInsideItemTitle}>
                Remove Logo Background
              </div>
              <div className={styles.toolbarBoxInsideItemContent}>
                <Switch
                  value={companyDetails.usesBgRemovedLogo ? 'yes' : 'no'}
                  onChange={(value) => {
                    updateCompanyDetails({
                      companyLogoUrl: value === 'yes' ? updatedUserBgRemovedImage : updatedUserImage,
                      usesBgRemovedLogo: value === 'yes', // TODO: Move this to the draft configuration
                    })
                  }}
                  options={['no', 'yes']}
                  size="small"
                  disabled={loading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CompanyTool
