import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { useConstants } from '../../shared/hooks/constants-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import Switch from '../../shared/components/UIElements/Switch'
import PricingCard from './PricingCard'
import greenBg from '../../shared/assets/images/admin/green-pricing-background.svg'
import { authActions } from '../../store/auth'

import TestimonialsSwiper from './TestimonialsSwiper'
import PricingCompare from './PricingCompare'

import 'swiper/css'
import './PricingCards.css'

const PricingCards = props => {
  const { pricingConstants: constants } = useConstants()
  const { monthlyPackages, yearlyPackages } = constants

  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    subscriptionDetails,
    isFromShopify,
    activePkg,
    hasCustomPlan,
    isUpdate,
    openAreYouSureModal,
    countryCode,
    showTestimonials = true,
  } = props

  const [monthYearSwitch, setMonthYearSwitch] = useState('MONTHLY')
  const [packagesInUse, setPackagesInUse] = useState(yearlyPackages)

  const customPlan = hasCustomPlan ? props.customPlan : null
  const cards = [[], [], [], []]
  const auth = useSelector(state => state.auth)
  const user = auth.user

  useEffect(() => {
    setPackagesInUse(
      monthYearSwitch === 'MONTHLY' ? monthlyPackages : yearlyPackages,
    )
  }, [monthYearSwitch, monthlyPackages, yearlyPackages])

  // Sort cards
  const getSortOrder = (name) => {
    const orderMap = {
      'Starter': 1,
      'Growth': 2,
      'Pro': 3,
      'Enterprise': 4,
      'Ad Health Checkup': 5
    };
    return orderMap[name] || 99;
  }

  const goToBilling = plan => {
    sessionStorage.setItem('tempSubscriptionPlan', JSON.stringify(plan))
    navigate(`/subscription/billing`)
  }

  const getStarted = plan => {
    if (isUpdate) {
      openAreYouSureModal(plan)
      return
    } else {
      let planRef =
        countryCode === 'TR' ? plan.iyzicoPlanNameTR : plan.stripePlanCode

        dispatch(
          authActions.updateUserObjOneField({
            field: 'isInSignupFlow',
            value: false,
          }),
        )

      if (plan.actionName === 'Book a Demo') {
        window.open('https://enhencer.com/start-now', '_blank')
      } else if (isFromShopify) {
        props.startShopifyPayment({
          price: plan.discountedPrice ? plan.discountedPrice : plan.price,
          planName: plan.name,
          interval: monthYearSwitch,
          additionalFee: plan.additionalFee,
        })
      } else {
        goToBilling({
          planRef: planRef,
          planName: plan.name,
          planPrice: plan.discountedPrice ? plan.discountedPrice : plan.price,
          currency: plan.currency,
          paymentInterval: monthYearSwitch,
        })
      }
    }
  }

  packagesInUse?.forEach((p, i) => {
    if (isFromShopify) {
      if (p.name === 'Ad Health Checkup') {
        cards[3].push(
          <PricingCard
            key={p.name}
            plan={p}
            countryCode={countryCode}
            isFromShopify={isFromShopify}
            getStarted={getStarted}
            activePkg={activePkg}
            isUpdate={isUpdate}
            openAreYouSureModal={openAreYouSureModal}
          />
        );
      } else if (['Starter', 'Growth'].includes(p.name)) {
        // Starter and Growth cards in first row
        cards[0].push(
          <PricingCard
            key={p.name}
            plan={p}
            countryCode={countryCode}
            isFromShopify={isFromShopify}
            getStarted={getStarted}
            activePkg={activePkg}
            isUpdate={isUpdate}
            openAreYouSureModal={openAreYouSureModal}
          />
        );
      } else if (['Pro', 'Enterprise'].includes(p.name)) {
        // Pro and Enterprise cards in second row
        cards[1].push(
          <PricingCard
            key={p.name}
            plan={p}
            countryCode={countryCode}
            isFromShopify={isFromShopify}
            getStarted={getStarted}
            activePkg={activePkg}
            isUpdate={isUpdate}
            openAreYouSureModal={openAreYouSureModal}
          />
        );
      }
    } else {
      // Users not from Shopify, all cards in one row
      cards[0].push(
        <PricingCard
          key={p.name}
          plan={p}
          countryCode={countryCode}
          isFromShopify={isFromShopify}
          getStarted={getStarted}
          activePkg={activePkg}
          isUpdate={isUpdate}
          openAreYouSureModal={openAreYouSureModal}
        />
      );
    }
  });

  // Sort cards specially
  if (isFromShopify) {
    cards[0].sort((a, b) => getSortOrder(a.props.plan.name) - getSortOrder(b.props.plan.name));
    cards[1].sort((a, b) => getSortOrder(a.props.plan.name) - getSortOrder(b.props.plan.name));
  }

  const startCheckoutForCustomPlan = () => {
    if (isFromShopify) {
      props.startShopifyPayment({
        price: customPlan.price,
        planName: 'Custom Plan',
        interval: customPlan.paymentInterval || 'MONTHLY',
      })
    } else {
      goToBilling({
        planRef: customPlan.refCode,
        planName: 'Custom Plan',
        planPrice: customPlan.price,
        paymentInterval: customPlan.paymentInterval || 'MONTHLY',
        currency: customPlan.currency
      })
    }
  }

  return hasCustomPlan ? (
    <div className="custom-plan-card">
      <div>
        <h2>A custom plan has been assigned to you</h2>
        <div className="price-container">
          <div className="price">
            <div style={{ fontSize: '1.5em' }}>
              {customPlan.currency === 'USD' ? '$ ' : '₺ '} {customPlan.price}
              <span style={{ fontSize: '0.5em' }}>{customPlan.paymentInterval === 'MONTHLY' ? ' per month' : ' per year'}</span>
            </div>
          </div>
          <button onClick={startCheckoutForCustomPlan}>
            <div>Continue</div>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={`pricing-cards-cont ${monthYearSwitch} ${isUpdate ? "update-plan" : ""}`}>
      <h1 className="intro">
        {isUpdate ? (
          <div className="update-plan-header">
            {' '}
            Update Your Plan
            <div
              className="back-button"
              onClick={() => navigate('/account/subscription')}
            >
              Go back
            </div>
          </div>
        ) : (
          <div className="plans-green-header">
            <div className="small-text">Pick a Plan to </div>
            <div className="gradient-text">Grow your Business with AI Ads</div>
          </div>
        )}
      </h1>
      {/* <h4 className="explanation-text">
        Choose Yearly to save <span className="green">≈72%</span> on your AI Ads
        Package and secure your investments with a{' '}
        <span className="green">100% Refund Guarantee</span>
      </h4> */}
      <br></br>
      <div className="switch-cont">
        {!hasCustomPlan && (
          <Switch
            lowerCaseOptions={true}
            options={['MONTHLY', 'YEARLY']}
            value={monthYearSwitch}
            onChange={setMonthYearSwitch}
          />
        )}
        {/* <div className="yearly-discount-text">
        (Save 50%)   
      </div> */}
      </div>
      <div className="main-pricing-cards-box">
        {isFromShopify ? (
          <>
            <div className="small-row first">{cards[0]}</div>
            <div className="small-row second">{cards[1]}</div>
            <div className="ad-health-checkup-container">{cards[3]}</div>
          </>
        ) : (
          <div className="pricing-row">{cards[0]}</div>
        )}
      </div>
    

      <div className="bg-cont">
        <img src={greenBg}></img>
      </div>
      <PricingCompare />

      {showTestimonials && (
        <div className="testimonials">
          <TestimonialsSwiper />
        </div>
      )}
    </div>
  )
}

export default PricingCards
