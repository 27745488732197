import React, { useState, useRef, useEffect } from 'react'
import { FaPalette } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import { ArrowContainer, Popover } from 'react-tiny-popover';

import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { palettes as constantPalettes } from '../../support/constants'

import styles from './BoxColor.module.css';

const BoxColor = () => {
	const dispatch = useDispatch()
	const { backgroundRemoving, draftConfiguration } =
		useEnhencedCatalog()
	const [palettes, setPalettes] = useState(constantPalettes)
	const [selectedPalette, setSelectedPalette] = useState([])
	const [selectedPaletteIndex, setSelectedPaletteIndex] = useState(null)
	const [activeColorOnPicker, setActiveColorOnPicker] = useState({
		index: null,
		color: '#000',
	})

	const [isPopoverOpen, setIsPopoverOpen] = useState(false)

	const pickerRef = useRef(null)

	const handlePaletteClick = (clickedPalette, index) => {
		if (backgroundRemoving) return

		setSelectedPalette(clickedPalette)
		setSelectedPaletteIndex(index)
		dispatch(
			aiCreativeEditorActions.setDraftConfiguration({
				palette: {
					index,
					color1: clickedPalette[0],
					color2: clickedPalette[1],
					color3: clickedPalette[2],
				},
			}),
		)
		setIsPopoverOpen(false)
	}

	const handleColorChange = (color, index) => {
		const newPalette = [...selectedPalette]
		newPalette[index] = color.hex
		setSelectedPalette(newPalette)
		setPalettes(
			palettes.map((palette, paletteIndex) => {
				if (paletteIndex === selectedPaletteIndex) {
					return newPalette
				}
				return palette
			}),
		)

		setActiveColorOnPicker({
			index,
			color,
		})

		dispatch(
			aiCreativeEditorActions.setDraftConfiguration({
				palette: {
					index: selectedPaletteIndex,
					color1: newPalette[0],
					color2: newPalette[1],
					color3: newPalette[2],
				},
			}),
		)
	}

	const handleHexInputChange = (event, index) => {
		const newPalette = [...selectedPalette]
		newPalette[index] = event.target.value
		setSelectedPalette(newPalette)
		setPalettes(
			palettes.map((palette, paletteIndex) => {
				if (paletteIndex === selectedPaletteIndex) {
					return newPalette
				}
				return palette
			}),
		)

		dispatch(
			aiCreativeEditorActions.setDraftConfiguration({
				palette: {
					index: selectedPaletteIndex,
					color1: newPalette[0],
					color2: newPalette[1],
					color3: newPalette[2],
				},
			}),
		)
	}

	const handleClickOutside = event => {
		if (pickerRef.current && !pickerRef.current.contains(event.target)) {
			setActiveColorOnPicker({ index: null, color: '#000' })
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		setPalettes([...constantPalettes])
	}, [])

	useEffect(() => {
		if (backgroundRemoving) return

		if (draftConfiguration?.palette) {
			const { index, color1, color2, color3 } =
				draftConfiguration.palette
			setSelectedPalette([color1, color2, color3])
			setSelectedPaletteIndex(index)
			setPalettes(palettes => {
				const newPalettes = [...constantPalettes]
				newPalettes[index] = [color1, color2, color3]
				return newPalettes
			})
			return
		}

		setSelectedPalette(constantPalettes[0])
		setSelectedPaletteIndex(0)
		setPalettes([...constantPalettes])
	}, [backgroundRemoving, draftConfiguration])

	return (
		<div className={styles.toolbarBox}>
			<div className={styles.toolbarBoxTitle}>
				<div className={styles.toolbarBoxTitleText}>
					Color

					<Popover
						isOpen={isPopoverOpen}
						positions={['left']}
						// padding={10}
						onClickOutside={() => setIsPopoverOpen(false)}
						content={({ position, childRect, popoverRect }) => (
							<ArrowContainer
								position={position}
								childRect={childRect}
								popoverRect={popoverRect}
								arrowColor={'#c5c5c5'}
								arrowSize={10}
								arrowStyle={{ opacity: 1 }}
								className="popover-arrow-container"
								arrowClassName="popover-arrow"
							>
								<div className={styles.paletteBox}>
									<h5>Palettes</h5>
									<div className={styles.palettes}>
										{(palettes ?? []).map((palette, paletteIndex) => (
											<div
												key={paletteIndex}
												className={`${styles.paletteRow} ${selectedPaletteIndex === paletteIndex ? styles.paletteRowSelected : ''}`}
												onClick={() => handlePaletteClick(palette, paletteIndex)}
											>
												{(palette ?? []).map((color, colorIndex) => (
													<div
														key={colorIndex}
														className={styles.colorBox}
														style={{ backgroundColor: color }}
													></div>
												))}
											</div>
										))}
									</div>
								</div>
							</ArrowContainer>
						)}
					>
						<button className={styles.toolbarBoxInsideItemContentButton}
							onClick={e => {
								e.stopPropagation()
								setIsPopoverOpen(!isPopoverOpen)
							}}>
							<FaPalette className={styles.toolbarBoxInsideItemContentButtonIcon} />
							Palettes
						</button>
					</Popover>

				</div>
			</div>
			<div className={styles.toolbarBoxInside}>
				<div className={styles.toolbarBoxInsideItem}>
					<div className={styles.toolbarBoxInsideItemTitle}>
						Brand Color
					</div>
					<div className={styles.toolbarBoxInsideItemContent}>
						<div className={styles.toolbarBoxInsideItemContentColor}
							onClick={() => {
								return setActiveColorOnPicker({ index: 0, color: selectedPalette[0] });
							}}>
							<div className={styles.toolbarBoxInsideItemContentColorBox} style={{ backgroundColor: selectedPalette[0] }}></div>
							<div className={styles.toolbarBoxInsideItemContentColorInput}>{selectedPalette[0]?.toUpperCase()}</div>
						</div>
					</div>
				</div>
				<div className={styles.toolbarBoxInsideItem}>
					<div className={styles.toolbarBoxInsideItemTitle}>
						Light Color
					</div>
					<div className={styles.toolbarBoxInsideItemContent}>
						<div className={styles.toolbarBoxInsideItemContentColor}
							onClick={() => {
								return setActiveColorOnPicker({ index: 1, color: selectedPalette[1] });
							}}>
							<div className={styles.toolbarBoxInsideItemContentColorBox} style={{ backgroundColor: selectedPalette[1] }}></div>
							<div className={styles.toolbarBoxInsideItemContentColorInput}>{selectedPalette[1]?.toUpperCase()}</div>
						</div>
					</div>
				</div>
				<div className={styles.toolbarBoxInsideItem}>
					<div className={styles.toolbarBoxInsideItemTitle}>
						Dark Color
					</div>
					<div className={styles.toolbarBoxInsideItemContent}>
						<div className={styles.toolbarBoxInsideItemContentColor}
							onClick={() => {
								return setActiveColorOnPicker({ index: 2, color: selectedPalette[2] });
							}}>
							<div className={styles.toolbarBoxInsideItemContentColorBox} style={{ backgroundColor: selectedPalette[2] }}></div>
							<div className={styles.toolbarBoxInsideItemContentColorInput}>{selectedPalette[2]?.toUpperCase()}</div>
						</div>
					</div>
				</div>

			</div>
			{activeColorOnPicker.index !== null && (
				<div className={styles.colorPicker} ref={pickerRef}>
					<SketchPicker
						color={activeColorOnPicker.color}
						onChangeComplete={color => {
							handleColorChange(color, activeColorOnPicker.index)
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default BoxColor;



