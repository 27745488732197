import React, { useEffect, useState } from 'react'
import './QuickDropdown.css'
import { FaSave } from 'react-icons/fa'
import { useAdmin } from '../../../shared/hooks/admin-hook'

const QuickDropdown = ({ className, preText, preSelectedOption, emptyText, options, onSave, isShorteningName = false, isConvertingFromId = false }) => {
  const { convertManagerIdToName, convertManagerNameToId } = useAdmin()
  const [selected, setSelected] = useState(isConvertingFromId
    ? convertManagerIdToName(preSelectedOption).found
    : preSelectedOption)
  const [isEditing, setIsEditing] = useState(false)
  const [showingOptions, setShowingOptions] = useState(options)
  
  useEffect(() => {
    if (!options.includes(preSelectedOption)) {
      if (isConvertingFromId) {
        setShowingOptions([convertManagerIdToName(preSelectedOption).found, ...showingOptions.map((e) => convertManagerIdToName(e).found)])
      } else {
        setShowingOptions([preSelectedOption, ...options])
      }
    } else {
      if (isConvertingFromId) {
        setShowingOptions(options.map((e) => convertManagerIdToName(e).found))
      } else {
        setShowingOptions(options)
      }
    }

  }, [])

  const shortenPersonName = (name) => {
    if (name && name.length > 11 && name.includes(" ")) {
      let splittedName = name.split(" ")
      if (splittedName.length > 2) {
        return `${splittedName[0]} ${splittedName[1][0]}.${splittedName[2][0]}.`
      } else {
        return `${splittedName[0]} ${splittedName[1][0]}.`
      }
    }
    return name
  }

  return (<div className={className}>
    {isEditing ? (
      <div
        onClick={e => e.stopPropagation()}
        className="options-dropdown account"
      >
        <select
          defaultValue={selected}
          onChange={(e) => setSelected(e.target.value)}
        >
          {showingOptions.map(option => (
            <option value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
        <button onClick={async (e) => {
          if (isConvertingFromId) {
            await onSave(convertManagerNameToId(selected).found)
          } else {
            await onSave(selected)
          }
          setIsEditing(false)
        }} className="save-btn">
          <FaSave className="icon" />
        </button>
        <button
          onClick={() => {
            setSelected(isConvertingFromId
              ? convertManagerIdToName(preSelectedOption).found
              : preSelectedOption)
            return setIsEditing(prev => !prev)
          }}
          className="cancel-btn"
        >
          <span className="icon">X</span>
        </button>
      </div>
    ) : (

      <span className={`name-text ${selected ? '' : 'not-set'}`}
        onClick={e => {
          e.stopPropagation()
          setIsEditing(prev => !prev)
        }}
      >
        {preText} {(isShorteningName ? shortenPersonName(selected) : selected) || emptyText}
      </span>
    )}
  </div>)

}

export default QuickDropdown
