import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import '../CampaignReportComparison.css'
import helper from '../../../../../shared/util/helper'
import numberFormatter from '../../../../../shared/util/numberFormatter'
import { getDataKey, cardColors } from '../CampaignReportComparison';


const AlternativeComparisonView = ({
	data,
	currencySymbol,
	labels,
	selectedMetric,
	setSelectedMetric,
	metricOptions,
	onBackClick
}) => {
	return (
		<div className="crc-alternative-view">
			<div className="crc-alternative-header">
				<button onClick={onBackClick} className="crc-back-button">
					← Back to Overview
				</button>
				<div className="crc-metric-selector">
					<select
						value={selectedMetric}
						onChange={(e) => setSelectedMetric(e.target.value)}
						className="crc-metric-dropdown"
					>
						{metricOptions.map(option => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</div>
			</div>
			<div className="crc-single-chart">
				<AlternativeComparisonChart
					title={selectedMetric}
					afterData={{
						data: data.after[getDataKey(selectedMetric)].chartData,
						label: 'With AI Ads'
					}}
					color={cardColors[selectedMetric]}
					labels={labels}
					prefix={selectedMetric === 'Performance' ? '' :
						(selectedMetric === 'AdSpend' || selectedMetric === 'Sales') ? currencySymbol : null}
				/>
			</div>
		</div>
	);
};


const AlternativeComparisonChart = ({
	title,
	afterData,
	color,
	labels,
	prefix
}) => {
	const chartRef = useRef(null);
	const chartInstanceRef = useRef(null);

	useEffect(() => {
		if (chartRef.current) {
			const ctx = chartRef.current.getContext('2d');

			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}

			chartInstanceRef.current = new Chart(ctx, {
				type: 'line',
				data: {
					labels,
					datasets: [
						{
							label: afterData.label,
							data: afterData.data,
							borderColor: color,
							backgroundColor: `${color}20`,
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							pointBackgroundColor: color,
						}
					]
				},
				options: {
					animations: {
						y: {
							type: 'number',
							easing: 'easeOutQuart',
							duration: 50,
							from: NaN,
							delay(ctx) {
								if (ctx.type !== 'data' || ctx.yStarted) {
									return 0;
								}
								ctx.yStarted = true;
								return ctx.index * 50;
							}
						}
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: true,
							position: 'top',
							align: 'end',
							labels: {
								usePointStyle: true,
								pointStyle: 'circle',
								padding: 20
							}
						},
						tooltip: {
							enabled: true,
							callbacks: {
								title: function (tooltipItems) {
									if (!tooltipItems || !tooltipItems[0] || !labels) return '';

									const date = labels[tooltipItems[0].dataIndex];
									if (!date) return '';

									const [firstDate, secondDate] = date.split(",");
									return helper.convertDateToMonthAndDay(firstDate) + " - " + helper.convertDateToMonthAndDay(secondDate);
								},
								label: function (context) {
									let value = numberFormatter.formatNumber(context.parsed.y, title === 'Performance' ? 2 : 0);
									return `${context.dataset.label}: ${prefix || ''}${value}${title === 'Performance' ? 'x' : ''}`;
								}
							}
						}
					},
					scales: {
						x: {
							grid: {
								display: false
							},
							ticks: {
								callback: function (value, index) {
									if (!labels) return '';
									const date = labels[index].split(",")[0];
									return helper.convertDateToMonthAndDay(date);
								}
							}
						},
						y: {
							beginAtZero: true,
							grid: {
								color: '#E5E5E5'
							},
							ticks: {
								callback: function (value) {
									return `${prefix || ''}${numberFormatter.formatNumber(value, title === 'Performance' ? 2 : 0)}${title === 'Performance' ? 'x' : ''}`;
								}
							}
						}
					},
				}
			});
		}

		return () => {
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}
		};
	}, [afterData, color, title, prefix, labels]);

	return (
		<div className="crc-alternative-chart">
			<canvas ref={chartRef} height="400" />
		</div>
	);
};

export default AlternativeComparisonView