import React, { useEffect, useMemo, useState } from "react";
import { FaArrowRight, FaChevronLeft, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import countriesData from "../../shared/data/countries.json";

import "./Payment.css"
import { useForm } from "../../shared/hooks/form-hook";
import { VALIDATOR_PHONE, VALIDATOR_REQUIRE } from "../../shared/util/validators";
import { useDispatch, useSelector } from "react-redux";
import { generalActions } from "../../store/general";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { authActions } from "../../store/auth";
import helper from "../../shared/util/helper";
import errorHandler from "../../shared/util/errorHandler";

const Billing = props => {
    const dispatch = useDispatch()
    const { sendRequest } = useHttpClient()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const user = auth.user
    const tempSubscriptionPlan = JSON.parse(sessionStorage.getItem('tempSubscriptionPlan'))
    if (!tempSubscriptionPlan) {
        navigate('/account/subscription')
    }
    const { planRef, planName, planPrice, currency, paymentInterval } = tempSubscriptionPlan
    const [agreedToTerms, setAgreedToTerms] = useState(false)
    const [busy, setBusy] = useState(false)

    const countries = useMemo(
        () => countriesData.map(c => c.name),
        [countriesData],
    )
    const [countryCode, setCountryCode] = useState("TR")
    const [gsmNumber, setGsmNumber] = useState("+90")

    useEffect(() => {
        let isMounted = true; // track if the component is mounted

        const getLocation = async () => {
            try {
                const res = await sendRequest(
                    `https://api.ipregistry.co/?key=${process.env.REACT_APP_IP_REGISTRY_KEY}`,
                    'GET',
                )
                setCountryCode(res.location.country.code)
                setGsmNumber("+" + res.location.country.calling_code)
            }
            catch (err) {
                console.log(err)
            }

        }
        getLocation()
    }, [])


    useEffect(() => {

        const pageTitle = "Subscription Billing"
        dispatch(generalActions.setPageTitle({ pageTitle }))
        document.querySelector(".App").classList.add("payment");

        return () => {
            document.querySelector(".App").classList.remove("payment")
        }
    }, [])

    const [formState, inputHandler, setFormData] = useForm(
        {
            name: {
                value: user?.billingInfo?.name || user?.name,
                isValid: false,
            },
            surname: {
                value: user?.billingInfo?.surname || "",
                isValid: false,
            },
            identityNumber: {
                value: user?.billingInfo?.identityNumber !== "1428077" ? user?.billingInfo?.identityNumber : "" || "",
                isValid: false,
            },
            countryCode: {
                value: gsmNumber || "",
                isValid: false,
            },
            phoneNumber: {
                value: user?.billingInfo?.phoneNumber || user?.contactNumber,
                isValid: false,
            },
            city: {
                value: user?.billingInfo?.city || "",
                isValid: false,
            },
            country: {
                value: user?.billingInfo?.country || user?.country || "",
                isValid: false,
            },
            address: {
                value: user?.billingInfo?.address || "",
                isValid: false,
            },
            zipCode: {
                value: user?.billingInfo?.zipCode || "",
                isValid: false,
            },
        },
        false
    );

    // const countryChanged = (newCountry) => {
    //     let newCode = helper.getCountryPhonePrefix(newCountry)
    //     setCountryCode(newCode)

    //     setFormData({
    //         ...formState.inputs,
    //         countryCode: {
    //             value: newCode,
    //             isValid: true
    //         }
    //     }, true)
    // }

    const continueToPayment = async (e) => {
        e.preventDefault()
        if (agreedToTerms) {
            let identity = formState.inputs.identityNumber.value
            if (identity === "") {
                identity = "1428077"
            }

            let billingInfo = {
                plan: planRef,
                tempSubscriptionPlan: tempSubscriptionPlan,
                price: tempSubscriptionPlan.planPrice,
                currency: tempSubscriptionPlan.currency,
                planName: tempSubscriptionPlan.planName,
                paymentInterval: paymentInterval,
                name: formState.inputs.name.value,
                surname: formState.inputs.surname.value,
                identityNumber: identity,
                email: user.email,
                gsmNumber: gsmNumber + formState.inputs.phoneNumber.value,
                countryCode: gsmNumber,
                phoneNumber: formState.inputs.phoneNumber.value,
                city: formState.inputs.city.value,
                country: formState.inputs.country.value,
                address: formState.inputs.address.value,
                zipCode: formState.inputs.zipCode.value
            }

            let checkoutFormEndpoint = "getCheckoutForm"
            let isFromTurkey = countryCode === "TR"

            setBusy(true)

            try {

                await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/updateBillingInfo`, "POST", JSON.stringify({ userId: user.id, billingInfo: billingInfo }), {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                })

                dispatch(authActions.updateBillingInfo(billingInfo)) // update billing info in redux store
                sessionStorage.setItem("billingInfo", JSON.stringify(billingInfo))
                sessionStorage.setItem("iyziEndpoint", checkoutFormEndpoint)

                if (!isFromTurkey) {
                    navigate('/subscription/stripePayment', { state: { billingInfo: billingInfo } }); //if user is not from turkey, use stripe payment
                } else {

                    navigate('/subscription/payment/');
                }

            } catch (err) {
                errorHandler(err)
            } finally {
                //TODO: unmounted thing
                setBusy(false)
            }


        } else {
            errorHandler({message: "Please agree to the terms and conditions"})
        }

    }


    return (
        <div className="payment">


            <div className="checkout-form">
                <div className="header-row">
                    <h3>Billing Details</h3>
                    <Link to="/subscription" className="cancel-payment">
                        <FaTimes />
                        Cancel
                    </Link>
                </div>
                <div className="package-details additional-info">
                    <p>
                        Selected plan:&nbsp;
                        <span className="name" style={{ color: "green" }}>
                            {planName}
                        </span>
                    </p>

                    <p>
                        <span className="price">{helper.getCurrencySymbol(currency) + planPrice.toString()}</span>
                        &nbsp; <span>{paymentInterval === "YEARLY" ? "per year" : "per month"}</span>
                    </p>
                </div>
                <form className="billing-form" onSubmit={continueToPayment}>
                    <div className="form-flex">
                        <div>


                            <Input
                                element="input"
                                id="name"
                                type="text"
                                label="First Name"
                                initialValue={formState.inputs.name.value}
                                required={true}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="First name cannot be empty."
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="surname"
                                type="text"
                                label="Last Name"
                                initialValue={formState.inputs.surname.value}
                                required={true}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Last name cannot be empty."
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="identityNumber"
                                type="text"
                                initialValue={formState.inputs.identityNumber.value}
                                label="Identity Number"
                                errorText=""
                                onInput={inputHandler}
                            />

                            <div className="row phone-number">
                                <Input
                                    element="input"
                                    id="countryCode"
                                    type="phone"
                                    label="Country Code"
                                    placeholder="+1"
                                    initialValue={formState.inputs.countryCode.value}
                                    forceValue={gsmNumber}
                                    required={true}
                                    validators={[VALIDATOR_REQUIRE()]}
                                    errorText="Please enter your country code."
                                    onInput={inputHandler}
                                />
                                <Input
                                    element="input"
                                    id="phoneNumber"
                                    type="phone"
                                    label="Phone Number"
                                    initialValue={formState.inputs.phoneNumber.value}
                                    required={true}
                                    validators={[VALIDATOR_PHONE()]}
                                    errorText="Please enter a valid phone number."
                                    onInput={inputHandler}
                                />
                            </div>
                        </div>

                        <div>
                            <Input
                                element="dropdown"
                                label="Country"
                                initialValue={formState.inputs.country.value}
                                id="country"
                                type="text"
                                onInput={inputHandler}
                                required={true}
                                options={countries}
                            />
                            <Input
                                element="input"
                                id="city"
                                type="text"
                                label="City"
                                initialValue={formState.inputs.city.value}
                                required={true}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="City cannot be empty."
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="address"
                                type="text"
                                label="Address"
                                required={true}
                                initialValue={formState.inputs.address?.value}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="address cannot be empty."
                                onInput={inputHandler}
                            />
                            <Input
                                element="input"
                                id="zipCode"
                                type="text"
                                label="ZIP Code"
                                initialValue={formState.inputs.zipCode?.value}
                                errorText=""
                                onInput={inputHandler}
                            />
                        </div>
                    </div>

                    <div className="agreement-line">
                        <input type="checkbox" checked={agreedToTerms} onChange={() => { setAgreedToTerms(!agreedToTerms) }} />
                        <div>I have read and agree to the <a href="https://enhencer.com/terms-of-use" target="_blank">Terms and Conditions</a>.</div>
                    </div>
                    <div style={{ fontSize: "0.8em", margin: "0 auto", textAlign: "center" }}>
                        Fields with asterix<span style={{ color: "red" }}>*</span> must be filled.
                    </div>

                    <Button loading={busy} className="action-button" onClick={continueToPayment}>
                        Continue to payment
                        <FaArrowRight className="icon" />
                    </Button>

                </form>
            </div>



        </div>
    )
}

export default Billing;