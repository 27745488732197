import React from 'react'
import { useSelector } from 'react-redux'
import helper from '../../../shared/util/helper'
import FluidCard from '../../../shared/components/UIElements/FluidCard'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import AdCreativePreviewer from './AdCreativePreviewer'

import './FullFunnelPreviewCard.css'

const FullFunnelPreviewCard = ({ campaign }) => {
  const { navigateWithAdminParams } = useAdmin()
  const facebook = useSelector(state => state.facebook)
  const fullFunnelEffectiveStatuses = useSelector(state => state.facebook.fullFunnelEffectiveStatuses)

  // Use the same logic as in FullFunnelSidebar for determining status
  const isActive = fullFunnelEffectiveStatuses[campaign._id] === 'ACTIVE'
  const status = isActive ? 'ACTIVE' : 'INACTIVE'

  const createdAt = (() => {
    let date;
    if (campaign?.startTime) {
      date = new Date(campaign.startTime);
    } else if (campaign?.createdAt) {
      date = new Date(campaign.createdAt);
    } else {
      date = new Date(); // fallback
    }

    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  })();

  return (
    <FluidCard
      onClick={() =>
        navigateWithAdminParams(
          `/ai-ads/full-funnel-campaigns?id=${campaign._id}`,
        )
      }
      className="ai-ads-campaign campaign"
    >
      <FluidCard.Content>
        <div className="content-wrapper">
          <div className="campaign-content-area">
            <div className="info-area">
              <div className="column">
                <FluidCard.Header>
                  <div className="header-wrapper">
                    <div className="row">
                      <span className="label">{campaign.label}</span>
                    </div>
                  </div>
                </FluidCard.Header>

                <span className="daily-budget">
                  <span className="bold">Daily Budget: </span>
                  {helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)}{campaign.dailyBudget}
                </span>
                <span className="countries">
                  <span className="bold">Countries: </span>
                  {campaign.countries.toString().replaceAll(',', ', ')}
                </span>
              
                <div className="row status-row">
                  <div className="row status-row">
                    <div
                      title={`${status?.[0] + status?.slice(1).toLowerCase()}`}
                      className={`status ${isActive ? 'active' : ''}`}
                    ></div>
                    <div className="status-text">
                      {isActive ? 'Active' : 'Inactive'}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="campaign-created-at">
                  <span className="bold">Created At: </span>
                  <span className="created-at-text">{createdAt}</span>
                </div>
              </div>
            </div>
            <div className="image-area">
              <AdCreativePreviewer
                className="no-border"
                existingCampaignCreative={
                  campaign.adCreatives[Object.keys(campaign.adCreatives)[0]]
                }
              />
            </div>
          </div>
        </div>
      </FluidCard.Content>
    </FluidCard>
  )
}

export default FullFunnelPreviewCard
