import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp11bg from '../../../../shared/assets/images/ai-creative/common_temp11bg.svg'

export const Template11Config = {
  name: 'Apperal #3',
  containerId: 'template11-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 149,
        y: 95
      },
      scale: {
        width: WORKBENCH_SIZE.width * 0.720,
        height: WORKBENCH_SIZE.height * 0.824
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp11bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#FEDD8C',
      draggable: false,
      clickThrough: true
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width * 0.16,
        y: WORKBENCH_SIZE.height * 0.78
      },
      scale: {
        maxWidth: 140,
        maxHeight: 100
      },
      dynamicSrc: 'companyLogo',
      required: false
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width * 0.17,
        y: WORKBENCH_SIZE.height * 0.12
      },
      width: 250,
      height: 300,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 60,
      fontStyle: 'bold',
      color: 'color2',
      text: '{{productName}}',
      fontIndex: 1
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 420,
        y: WORKBENCH_SIZE.height - 185
      },
      width: 265,
      height: 60,
      fontFamily: 'Bayon',
      fontSize: 80,
      fontStyle: 'bold',
      color: 'color2',
      verticalAlign: 'middle',
      align: 'center',
      text: '{{productPrice}}',
      fontIndex: 2
    }
  ]
}