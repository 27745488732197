import React from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import { FaTimes } from 'react-icons/fa'
import Input from '../FormElements/Input'
import { useForm } from '../../hooks/form-hook'
import { useHttpClient } from '../../hooks/http-hook'
import helper from '../../util/helper'

const AddNewAudienceEvent = () => {
  const { closeModal, modalData } = useModal()
  const { sendRequest } = useHttpClient()

  const DEFAULT_EVENTS = [
    { name: "enh_gdn_rem", adPlatform: "Google" },
    { name: "enh_conv_high_intent_rem", adPlatform: "Facebook" },
    { name: "enh_dpa_high_intent_rem", adPlatform: "Facebook" },
    { name: "enh_perf_max_rem", adPlatform: "Google" },
    { name: "enh_search_rem", adPlatform: "Google" },
    { name: "enh_rlsa_rem", adPlatform: "Google" },
    { name: "enh_conv_lal", adPlatform: "Facebook" },
    { name: "enh_dpa_lal", adPlatform: "Facebook" },
    { name: "enh_traffic_lal", adPlatform: "Google" },
  ]

  const [
    formState,
    inputHandler,
    setFormData,
  ] = useForm(
    {
      eventName: {
        value: "enh_conv_lal",
        isValid: true
      }
    },
    true,
  )



  const create = async () => {

    let event = helper.findBy(DEFAULT_EVENTS, "name", formState.inputs.eventName.value)
    modalData.callback({
      newEventName: formState.inputs.eventName.value,
      adPlatform: event.adPlatform
    })
    closeModal()
  }

  const close = () => {
    closeModal()
  }

  return (
    <div className="alarm-modal create-alarm-modal">
      <div className="modal-title">
        <div className="alarm-name">Add new audience event</div>

        <Button size="small" onClick={close} >
          <FaTimes />
        </Button>
      </div>
      <div className="modal-middle">
        <Input
          element="dropdown"
          initialValue={formState.inputs.eventName.value}
          forceValue={formState.inputs.eventName.value}
          id="eventName"
          type="text"
          onInput={inputHandler}
          options={DEFAULT_EVENTS.map(e => e.name)}
        />
      </div>
      <div className="modal-footer">
        <Button size="small" className="action-button yes" onClick={create}>
          Create
        </Button>
      </div>
    </div>
  )
}

export default AddNewAudienceEvent
