import { WORKBENCH_SIZE } from '../../../support/constants'
import temp20bg from '../../../../shared/assets/images/ai-creative/temp20bg.svg'

export const Template20Config = {
  name: 'Gradient Bubbles',
  containerId: 'template20-carousel-single',
  backgroundColor: 'color1',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp20bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#0039CF',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 750,
        maxHeight: 750,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 22,
        y: WORKBENCH_SIZE.height - 120,
      },
      width: 500,
      text: '{{productName}}', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 49.59,
      color: 'color3',
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width * 0.65,
        y: 54,
      },
      text: '{{productPrice}}', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 68,
      color: 'color3',
    },
  ],
}
