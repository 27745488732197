import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';

import { useAdmin } from './admin-hook';

export const useAdminAuth = () => {

  const location = useLocation()
  const { endViewingAsUser } = useAdmin();

  const adminUser = useSelector(state => state.auth.user)

  useEffect(() => {
    if (adminUser?.isSpecialUser) {
      checkUrl()
    }
  }, [location.pathname, adminUser])

  const checkUrl = async () => {
    const routeName = location.pathname.replace("/", "")
    if (routeName.includes("admin-panel") && !routeName.includes("user-detail")) {
      endViewingAsUser()
    }
  }


  return {
  }
}
