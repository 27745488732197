import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import './Tooltip.css'

const Tooltip = ({
  size = 'md',
  content,
  isHidden,
  delay = 500,
  offset = 10,
  position = 'right',
  children,
  ...props
}) => {
  const [show, setShow] = useState(false)
  const [positionStyle, setPositionStyle] = useState({})
  const tooltipContainerRef = useRef(null)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (!show || !content || !tooltipContainerRef.current) {
      return
    }

    const tooltip = document.querySelector('.tooltip')
    const child = tooltipContainerRef.current.children[0] // The element that the tooltip is attached to. 

    // FIXME: When the element that the tooltip is attached to is a fragment and not a standard element
    // like a div or span, the tooltip crashes since the child value is undefined.
    // We should either not use fragments as children or find a better way to get the child element.

    const tooltipRect = tooltip.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    let topAmount, leftAmount

    switch (position) {
      case 'top':
        topAmount = childRect.top - tooltipRect.height - offset
        leftAmount = childRect.left + (childRect.width / 2) - (tooltipRect.width / 2)
        break
      case 'bottom':
        topAmount = childRect.bottom + offset
        leftAmount = childRect.left + (childRect.width / 2) - (tooltipRect.width / 2)
        break
      case 'left':
        topAmount = childRect.top + (childRect.height / 2) - (tooltipRect.height / 2)
        leftAmount = childRect.left - tooltipRect.width - offset
        break
      case 'right':
      default:
        topAmount = childRect.top + (childRect.height / 2) - (tooltipRect.height / 2)
        leftAmount = childRect.right + offset
        break
    }

    setPositionStyle({
      top: topAmount,
      left: leftAmount,
    })
  }, [show, content, offset, position, tooltipContainerRef])

  // Hide the tooltip when scrolling
  useEffect(() => {
    const handleScroll = () => {
      setShow(false)
    }

    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])

  const handleMouseEnter = e => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, delay)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current)
    setShow(false)
  }

  if (isHidden) {
    return children
  }

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="tooltip"
      ref={tooltipContainerRef}
      {...props}
    >
      {children}
      {show && content &&
        ReactDOM.createPortal(
          <div style={positionStyle} className={`tooltip ${size}`}>
            {content}
          </div>,
          document.body,
        )}
    </div>
  )
}

export default Tooltip
