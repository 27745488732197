import React, { useEffect, useState } from 'react'

import './CampaignCreativesSection.css'

const CampaignCreativesSection = ({ selectCurrentCreative, adCreatives }) => {
  const [currentCreative, setCurrentCreative] = useState(
    Object.values(adCreatives)[0],
  )

  useEffect(() => {
    setCurrentCreative(Object.values(adCreatives)[0])
  }, [adCreatives])

  const handleCreativeSelection = creative => {
    setCurrentCreative(creative)
    selectCurrentCreative(creative)
  }

  return (
    <div
      className="campaign-creatives-section campaign-form"
      style={{ marginTop: '2em' }}
    >
      <div className="header-row">
        <h3>Creatives</h3>
      </div>

      {adCreatives && Object.keys(adCreatives).length ? (
        <div className="campaign-creatives-list">
          {Object.values(adCreatives).map((creative, index) => (
            <div
              className={`campaign-creative-chip${currentCreative.name === creative.name ? ' active' : ''}`}
              key={creative.name}
              onClick={() => {
                handleCreativeSelection(creative)
              }}
            >
              <span>{creative.name || `Creative ${index + 1}`}</span>
            </div>
          ))}
        </div>
      ) : (
        <p className="helper-text">
          No creatives. Start crafting one in editor
        </p>
      )}
    </div>
  )
}

export default CampaignCreativesSection
