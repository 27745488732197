import { useCallback } from "react";
import { useHttpClient } from "./http-hook";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth";
const useAIAgent = () => {

    const dispatch = useDispatch()
    const authToken = useSelector(state => state.auth.token)
    const user = useSelector(state => state.auth.user)
    const facebook = useSelector(state => state.facebook)
    const { sendRequest } = useHttpClient()
    const authHeader = {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
    }

    const generateOrGetPreviousGeneralSummary = async (forceFetch = false) => {
        try {
            if (!forceFetch && user?.aiSummary?.summaryAnswer) {
                const createdAt = user?.aiSummary?.createdAt
                // if the summary is not older than 1 day, return the summary
                if (createdAt && new Date(createdAt).getTime() + 1 * 24 * 60 * 60 * 1000 > Date.now()) {
                    return { summaryAnswer: user.aiSummary.summaryAnswer, createdAt: createdAt }
                }
            }
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/ai-agent/generateGeneralSummary`,
                'POST',
                JSON.stringify({ userId: user.id }),
                authHeader
            )
            dispatch(
                authActions.updateUserObjManyFields({
                    aiSummary: {
                        summaryAnswer: response.summaryAnswer,
                        createdAt: response.createdAt,
                    },
                }),
            )
            return response
        } catch (error) {
            throw error
        }
    }

    const askFollowupQuestionToSummary = async (questionText) => {
        const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/ai-agent/askFollowupQuestionToSummary`,
            'POST',
            JSON.stringify({ userId: user.id, questionText: questionText }),
            authHeader
        )
        return response.answer
    }

    const generateCampaignsSummary = async (forceFetch = false) => {
        if (!forceFetch && user?.aiSummary?.campaignsSummaryAnswer) {
            const createdAt = user?.aiSummary?.campaignsSummaryCreatedAt
            // if the summary is not older than 1 day, return the summary
            if (createdAt && new Date(createdAt).getTime() + 1 * 24 * 60 * 60 * 1000 > Date.now()) {
                return { summaryAnswer: user.aiSummary.campaignsSummaryAnswer, createdAt: createdAt }
            }
        }
        let campaignsData = {
            fullFunnels: facebook.fullFunnels,
            timeseriesChartData: facebook.timeseriesChartData,
            overallReportForFullFunnels: { ...facebook.overallReportForFullFunnels },
            accountCurrency: facebook.selectedAdAccount?.currency,
        }

        if (campaignsData.overallReportForFullFunnels?.adsetLevel) {
            delete campaignsData.overallReportForFullFunnels.adsetLevel;
        }

        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/ai-agent/generateCampaignsSummary`,
                'POST',
                JSON.stringify({ userId: user.id, campaignsData }),
                authHeader
            )
            dispatch(
                authActions.updateUserObjManyFields({
                    aiSummary: {
                        summaryAnswer: response.summaryAnswer,
                        createdAt: response.createdAt,
                    },
                }),
            )
            return response
        } catch (error) {
            console.log("Summary error", error)
        }
    }

    const askFollowupQuestionToCampaignsSummary = async (questionText) => {
        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/ai-agent/askFollowupQuestionToCampaignsSummary`,
                'POST',
                JSON.stringify({ userId: user.id, questionText: questionText }),
                authHeader
            )
            return response.answer
        } catch (error) {
            console.log("Summary error", error)
        }
    }

    return {
        generateOrGetPreviousGeneralSummary,
        askFollowupQuestionToSummary,
        generateCampaignsSummary,
        askFollowupQuestionToCampaignsSummary,
    }

}

export default useAIAgent

