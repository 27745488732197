import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp3bg from '../../../../shared/assets/images/ai-creative/common_temp3bg.svg'

export const Template3Config = {
  name: 'Shop new collection',
  containerId: 'template3-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp3bg,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true
    },
    // Second Background Layer SVG (same as background in this case)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp3bg,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height / 2 + 100
      },
      width: 600,
      text: '30% OFF',
      fontFamily: 'Readex Pro',
      fontSize: 101.3688,
      color: 'color2',
      fontIndex: 1
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height / 2
      },
      width: 980,
      text: 'USE CODE: GET30',
      fontFamily: 'Readex Pro',
      fontSize: 70,
      color: 'color2',
      letterSpacing: 4,
      fontIndex: 2
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 230,
        y: 40
      },
      width: 300,
      height: 100,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 50,
      fontStyle: 'bold',
      color: 'color3',
      center: true,
      verticalAlign: 'middle',
      text: '{{productPrice}}'
    }
  ]
}