import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import NavBar from '../Navigation/NavBar'
import NotificationPopup from '../UIElements/NotificationPopup'
import Modal from '../UIElements/Modal'
import BottomNotificationBar from '../UIElements/BottomNotificationBar'
import ImageOverlay from '../UIElements/ImageOverlay'

import TopBar from '../Navigation/TopBar'
import Button from '../FormElements/Button'
import { FaArrowLeft } from 'react-icons/fa'
import { useUser } from '../../hooks/user-hook'
import ViewingAsUserBox from './ViewingAsUserBox'

const AppLayout = ({ children }) => {
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(true)
  const auth = useSelector(state => state.auth)
  const user = useSelector(state => state.auth.user)
  const isSpecialUser = user?.isSpecialUser || false
  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser')) || {}
  const { goBackToAdmin } = useUser()

  return (
    <div
      className={`App ${isNavbarExpanded ? '' : 'navbar-collapsed'} ${user &&
        !isSpecialUser &&
        user.completedRegistration &&
        !user.isInSignupFlow
        ? 'basic-user'
        : ''
        }`}
      id="app"
    >
      <NotificationPopup />
      <Modal />

      {auth.user && isSpecialUser && <TopBar />}

      <div className="main-cont">
        {viewingAsUser?.userToken && viewingAsUser?.userRole !== 'Agency' && (
          <ViewingAsUserBox onBackClick={goBackToAdmin} />
        )}
        <main>{children}</main>
        {auth.user && !isSpecialUser && (
          <NavBar
            isExpanded={isNavbarExpanded}
            onToggle={() => setIsNavbarExpanded(!isNavbarExpanded)}
          />
        )}
      </div>
      <BottomNotificationBar />
      <ImageOverlay />
    </div>
  )
}

export default AppLayout
