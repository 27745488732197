import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaSave } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useAuth } from '../../shared/hooks/auth-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import ProfilePageLayout from '../components/ProfilePageLayout'
import Button from '../../shared/components/FormElements/Button'

import './AccountSettings.css'
import dateFormatter from '../../shared/util/dateFormatter'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'
import Switch from '../../shared/components/UIElements/Switch'

const AccountSettings = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const facebook = useSelector(state => state.facebook)
  const { auth } = useAuth()
  const user = auth.user

  const [isEmailContentShown, setIsEmailContentShown] = useState(false)
  const [isPasswordContentShown, setIsPasswordContentShown] = useState(false)

  const [emailToRender, setEmailToRender] = useState(user?.email)

  const [email, setEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [savingEmail, setSavingEmail] = useState(false)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [savingPassword, setSavingPassword] = useState(false)
  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
    user.crmDetails?.emailNotificationsDisabled ? 'no' : 'yes',
  )

  const handleInputChange = e => {
    const { name, value } = e.target

    if (name === 'email') {
      setEmail(value)
    }

    if (name === 'confirmEmail') {
      setConfirmEmail(value)
    }

    if (name === 'oldPassword') {
      setOldPassword(value)
    }

    if (name === 'newPassword') {
      setNewPassword(value)
    }

    if (name === 'confirmPassword') {
      setConfirmPassword(value)
    }
  }

  // Validate email
  useEffect(() => {
    if (!email || !confirmEmail) {
      setIsEmailValid(false)
      return
    }

    if (email !== confirmEmail) {
      setIsEmailValid(false)
      return
    }

    setIsEmailValid(true)
  }, [email, confirmEmail])

  // Validate password
  useEffect(() => {
    if (!oldPassword) {
      setIsPasswordValid(false)
      return
    }

    if (!newPassword || !confirmPassword) {
      setIsPasswordValid(false)
      return
    }

    if (newPassword !== confirmPassword) {
      setIsPasswordValid(false)
      return
    }

    setIsPasswordValid(true)
  }, [oldPassword, confirmPassword])

  const mailNotificationOptionChanged = async () => {
    const newEmailNotificationsEnabled = 
      emailNotificationsEnabled === 'yes' ? 'no' : 'yes';
    setEmailNotificationsEnabled(newEmailNotificationsEnabled);
  
    const payload = JSON.stringify({
      userId: user._id,
      emailNotificationsDisabled: newEmailNotificationsEnabled === 'no',
    });
  
    const endpoint = `${process.env.REACT_APP_BACKEND_URL}/users/changeEmailNotificationSettings`;
  
    try {
      const result = await sendRequest(endpoint, 'POST', payload, {
        Authorization: `Bearer ${auth.token}`,
        'Content-Type': 'application/json',
      });
      return result;
    } catch (error) {
      throw error;
    }
  };
  

  const renderEmailCardContent = () => {
    return (
      <div className="email-card-content-wrapper">
        <div className="current-address">
          <span className="title">Current Address:</span>
          <span className="email">{emailToRender}</span>
        </div>
        <form onSubmit={saveEmail}>
          <input
            onChange={handleInputChange}
            name="email"
            value={email}
            placeholder="New Email Address"
            type="email"
          />
          <input
            onChange={handleInputChange}
            name="confirmEmail"
            value={confirmEmail}
            placeholder="Confirm Email Address"
            type="email"
          />
          <div className="buttons">
            <span
              onClick={() => setIsEmailContentShown(false)}
              className="cancel-button"
            >
              Cancel
            </span>
            <Button
              loading={savingEmail}
              disabled={!isEmailValid}
              className="save-button"
            >
              <FaSave className="icon" />
              Save
            </Button>
          </div>
        </form>
      </div>
    )
  }

  const renderPasswordCardContent = () => {
    return (
      <div className="password-card-content-wrapper">
        <form onSubmit={e => savePassword(e)}>
          <input
            onChange={handleInputChange}
            name="oldPassword"
            value={oldPassword}
            placeholder="Old Password"
            type="password"
          />
          <input
            onChange={handleInputChange}
            name="newPassword"
            value={newPassword}
            placeholder="New Password"
            type="password"
          />
          <input
            onChange={handleInputChange}
            name="confirmPassword"
            value={confirmPassword}
            placeholder="Confirm Password"
            type="password"
          />
          <div className="buttons">
            <span
              onClick={() => setIsPasswordContentShown(false)}
              className="cancel-button"
            >
              Cancel
            </span>
            <Button
              loading={savingPassword}
              disabled={!isPasswordValid}
              className="save-button"
            >
              <FaSave className="icon" />
              Save
            </Button>
          </div>
        </form>
      </div>
    )
  }

  const save = async (endpoint, payload) => {
    try {
      const result = await sendRequest(endpoint, 'POST', payload, {
        Authorization: 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      })
      return result
    } catch (err) {
      throw err
    }
  }

  const saveEmail = async e => {
    try {
      e.preventDefault()
      setSavingEmail(true)

      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/users/changeEmail`
      const payload = JSON.stringify({ email })

      await save(endpoint, payload)

      setEmailToRender(email)
      dispatch(
        authActions.updateUserObj({
          ...user,
          email,
        }),
      )

      toast.success('E-mail successfully changed')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSavingEmail(false)
    }
  }

  const savePassword = async e => {
    try {
      e.preventDefault()
      setSavingPassword(true)

      const endpoint = `${process.env.REACT_APP_BACKEND_URL}/users/changePassword`
      const payload = JSON.stringify({
        userId: user._id,
        oldPassword,
        newPassword,
      })

      await save(endpoint, payload)

      toast.success('Password successfully changed')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSavingPassword(false)
    }
  }

  if (!user) return null

  return (
    <ProfilePageLayout>
      <ProfilePageLayout.Header></ProfilePageLayout.Header>
      <ProfilePageLayout.Content>
        <div className="account-settings">
          <div className="dates">
            <span className="last-login">
              Last Login: {dateFormatter(user?.lastLoginAt, true) ?? 'No info'}
            </span>
            <span className="account-created">
              Account Created:{' '}
              {dateFormatter(user?.createdAt, true) ?? 'No info'}
            </span>
          </div>
          <div className="cards-container">
            <SettingsCard
              title="Email Address"
              description={emailToRender}
              buttonText="Change"
              content={renderEmailCardContent()}
              showContent={isEmailContentShown}
              onClick={() => setIsEmailContentShown(true)}
            />
            <SettingsCard
              title="Facebook Pixel"
              description={facebook.selectedPixel?.name}
              buttonText="Change"
              onClick={() => navigate('/config')}
            />
            <SettingsCard
              title="Password"
              description={
                user.shopify
                  ? 'You signed up using Shopify, so you don’t have a password'
                  : 'Update your password'
              }
              buttonDisabled={user.shopify}
              buttonText="Change"
              content={renderPasswordCardContent()}
              showContent={isPasswordContentShown}
              onClick={() => setIsPasswordContentShown(true)}
            />
            <SettingsCard
              title="Ad Account"
              description={facebook.selectedAdAccount?.name}
              buttonText="Change"
              onClick={() => navigate('/config')}
            />
            <SettingsCard
              title="Facebook Account"
              description={facebook.auth?.name}
              buttonText="Change"
              onClick={() => navigate('/config')}
            />
            <SettingsCard
              title="Google Connection"
              description={
                user.googleAds?.adAccountName || user.googleAds?.conversionId
                  ? 'Active'
                  : 'Not Active'
              }
              buttonText="Contact your Account Manager"
              onClick={() => {
                openModal({
                  type: Modals.GOOGLE_ADS_SUPPORT,
                  closeOnClickOutside: true,
                  performanceManager: user.crmDetails?.performanceManager,
                })
              }}
            />
            <SettingsCard
              title="Facebook Business"
              description={`${facebook.selectedBusiness?.name} - BUSINESS ID: ${facebook.selectedBusiness?.id}`}
              buttonText="Change"
              onClick={() => navigate('/config')}
            />
            <SettingsCard
              title="Email Notifications"
              isSwitch={true}
              emailNotificationsEnabled={emailNotificationsEnabled}
              mailNotificationOptionChanged={mailNotificationOptionChanged}
            />
          </div>
        </div>
      </ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

const SettingsCard = ({
  title,
  description,
  buttonDisabled,
  buttonText,
  isSwitch,
  emailNotificationsEnabled,
  mailNotificationOptionChanged,
  content,
  showContent,
  onClick,
}) => {
  return (
    <div className="settings-card">
      {showContent ? (
        <React.Fragment>
          <div className="content-wrapper">
            <span className="title">{title}</span>
            {content}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="texts">
            <span className="title">{title}</span>
            <span className="description">{description}</span>
          </div>
          {!isSwitch && (
            <Button
              disabled={buttonDisabled}
              onClick={onClick}
              className="change-button"
            >
              {buttonText}
            </Button>
          )}
          {isSwitch && (
            <Switch
              value={emailNotificationsEnabled}
              onChange={mailNotificationOptionChanged}
              options={['no', 'yes']}
            />
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default AccountSettings
