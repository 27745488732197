import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowRight } from 'react-icons/fa'
import { generalActions } from '../../store/general'
import Button from '../../shared/components/FormElements/Button'

import SubscriptionPlanInfoCard from '../components/SubscriptionPlanInfoCard'

const SubscriptionCompletedSuccessfully = props => {
  const dispatch = useDispatch()
  
  const userSubscription = useSelector(state => state.auth.user.subscription)

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle: 'Subscription Started Successfully' }))
  }, [])

  return (
    <div className="payment-completed">
      <div className="subscription-success">
        <h1>Subscription Started Successfully</h1>



        <div className="content">
          {userSubscription ? (
            <div className="subscribed">

              <SubscriptionPlanInfoCard pageType="payment-completed" />

            </div>
          ) :
            <div>
              Error occured.
            </div>
          }
        </div>

        <div className="row actions">
          <Button to="/config">
            Connections Page
            <FaArrowRight />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCompletedSuccessfully
