import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import instagramIcon from '../../assets/images/icons/instagram-icon-sm.png'
import metaIcon from '../../assets/images/icons/meta-icon-sm.png'
import { useFacebookCreative } from '../../hooks/facebook/creative-hook'
import { useModal } from '../../hooks/modal-hook'
import helper from '../../util/helper'
import Button from '../FormElements/Button'
import AdCreativePreviewer from '../../../ai-ads/components/campaign/AdCreativePreviewer'

import './CampaignCreationSuccess.css'

const CampaignCreationSuccess = ({ data }) => {
  const { fullFunnel, callback, enhencedCatalogDetails } = data
  const facebook = useSelector(state => state.facebook)

  const { getAdPreviews } = useFacebookCreative()
  const { closeModal } = useModal()
  const [preview, setPreview] = useState(null)

  const campaignCreation = fullFunnel.createdAt
    ? new Date(fullFunnel.createdAt)
    : new Date()
  const campaignCreationTimestamp = campaignCreation.getTime()
  const sevenDaysLater = 7 * 24 * 60 * 60 * 1000
  const recommendedDate = new Date(campaignCreationTimestamp + sevenDaysLater)

  const generateAndSetRenderedPreview = previewResults => {
    const desktopFeedStandardPreview = previewResults.find(
      p => p.format === 'DESKTOP_FEED_STANDARD',
    )

    if (desktopFeedStandardPreview) {
      let { format, body } = desktopFeedStandardPreview

      body = body.replace('scrolling="yes"', 'scrolling="no"')

      const previewToRender = (
        <div
          key={format}
          className={`ad-creative-container ${format
            .toLowerCase()
            .replaceAll('_', '-')}`}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      )

      setPreview(previewToRender)
    }
  }

  useEffect(() => {
    
    const getPreviewsOfCreative = async cid => {
      const previewResults = await getAdPreviews({
        creativeId: cid ? cid : '120200070614810307',
      })

      generateAndSetRenderedPreview(previewResults)
    }

    // TODO: may be we can display preview for each creative
    let firstCreativeId = Object.keys(fullFunnel.adCreatives)[0]
    getPreviewsOfCreative(firstCreativeId)
  }, [])

  return (
    <div className="campaign-creation">
      <div className="close-modal">
        <div
          className="cross"
          onClick={() => {
            callback()
            closeModal()
          }}
        />
      </div>
      <div className="content">
        <div className="information-container">
          <div className="top">
            <h3 className="title">
              Your AI Ads are <span className="gradient-text">now live</span>
            </h3>
            <p className="description">
              We recommend keeping your AI Ads running at least until
              {` ${moment(recommendedDate).format('MMMM Do')} `}
              to maximize your results and achieve peak performance!
            </p>
          </div>
          <div className="bottom">
            <div className="campaign-details">
              <ul>
                <li>Gender: {helper.getGenderString(fullFunnel.genders)}</li>
                <li>
                  Region: {fullFunnel.countries.toString().replaceAll(',', ', ')}
                </li>
                <li>
                  Daily Budget:{' '}
                  {`${helper.getCurrencySymbol(
                    facebook.selectedAdAccount.currency,
                  )}${fullFunnel.dailyBudget}`}
                </li>
                <li>
                  Age: {fullFunnel.age.min} - {fullFunnel.age.max}
                </li>
              </ul>
            </div>
            <div className="button-container">
              <Button
                onClick={() => {
                  callback()
                  closeModal()
                }}
                className="continue-btn"
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
        {!enhencedCatalogDetails ? (
          <div className="preview-container">{preview}</div>
        ) : (
          <div className="preview-container">
            <AdCreativePreviewer enhencedCatalogDetails={enhencedCatalogDetails} className="enhenced-catalog modal-preview" />
          </div>
        )}
      </div>
      <div className="background">
        <div className="circles-container">
          <div className="circle dashed"></div>
          <div className="circle"></div>
        </div>
      </div>
      <div className="icons">
        <div className="icon instagram">
          <img src={instagramIcon} alt="Meta" />
        </div>
        <div className="icon meta">
          <img src={metaIcon} alt="Meta" />
        </div>
      </div>
    </div>
  )
}

export default CampaignCreationSuccess
