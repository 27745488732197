import React, { useEffect, useState } from 'react'
import { FaCopy, FaHistory, FaLink, FaPenSquare, FaRegClock, FaSave, FaShopify } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../../store/admin'
import { useHttpClient } from '../../../shared/hooks/http-hook'

import { Link, useNavigate } from 'react-router-dom'
import helper from '../../../shared/util/helper'
import CustomerCardAlarmPart from '../customers/CustomerCardAlarmPart'
import NotesPopup from '../cards-components/NotesPopup'

import QuickDropdown from '../cards-components/QuickDropdown'
import errorHandler from '../../../shared/util/errorHandler'
import Checkbox from '../../../shared/components/FormElements/Checkbox'
import { getDaysPassedSince } from '../../../shared/util/dateFormatter'
import CustomerCardInput from '../cards-components/CustomerCardInput'
import SegmentationScoreIcon from '../cards-components/SegmentationScoreIcon'
import UtmsPopup from '../cards-components/UtmsPopup'

import './SignUpCard.css'

const SignUpCard = ({ signUp }) => {
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const { salesPeople } = useSelector(state => state.admin)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const fullPhoneNumber = signUp.crmDetails?.contactNumber ? ((signUp.crmDetails?.contactNumberCountryCode || "") + signUp.crmDetails?.contactNumber) : ""
  
  const [isSignUpMeetingDone, setIsSignUpMeetingDone] = useState(signUp.signUpDetails?.isSignUpMeetingDone ?? false)
  
  const saveIsSignUpMeetingDone = async (isSignUpMeetingDone) => {
    await save('isSignUpMeetingDone', isSignUpMeetingDone)
    setIsSignUpMeetingDone(isSignUpMeetingDone)
  }


  const save = async (field, payload) => {
    const query = { cardType: 'signUp' }
    let changingPart;

    switch (field) {
      case 'calls':
      case 'talks':
      case 'emails':
      case 'smses':
      case 'salesPerson':
      case 'adHealthCheckupMeeting':
        query[`crmDetails.${field}`] = payload
        changingPart = "crmDetails"
        break;
      case 'isSignUpMeetingDone':
      case 'status':
      case 'ourNote':
        query[`signUpDetails.${field}`] = payload
        changingPart = "signUpDetails"
        break;
      default:
        break;
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${signUp.id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      let newUserData = {
        ...signUp,
        crmAlarmsSignUp: result.user.crmAlarmsSignUp,
        [changingPart]: {
          ...signUp[changingPart],
          [field]:
            typeof payload !== 'object'
              ? payload
              : {
                ...signUp[changingPart][field],
                ...payload,
              },
        },
      }
      if (payload === null || payload === undefined) {
        delete newUserData[changingPart][field]
      }

      dispatch(
        adminActions.findSignUpByIdAndUpdate({
          id: signUp.id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const getUsername = signUp => {
    let domain = signUp.shopify
      ? signUp.shopify.name || signUp.shopify.domain
      : signUp.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : signUp.crmDetails?.contactName
  }

  const copyPhoneNumber = (e) => {
    e.stopPropagation()
    if (fullPhoneNumber) {
      navigator.clipboard.writeText(fullPhoneNumber)
      toast.info('Phone number copied')
    }
  }

  function getStatusClass(status, subscriptionStatus) {
    let fastChurnSuffix = ""
    if (subscriptionStatus === "Fast Churn") {
      fastChurnSuffix = " fast-churn"
    }
    switch (status) {
      case "Touch":
        return 'touch' + fastChurnSuffix
        break;
      case "No Touch":
        return 'no-touch' + fastChurnSuffix
        break;
      default:
        return '' + fastChurnSuffix
        break;
    }
  }

  const goToDetails = () => {
    dispatch(adminActions.setNavigatedFromCustomerID(signUp.id))
    dispatch(adminActions.setCustomer(signUp))
    window.open(`/admin-panel/user-detail/${signUp.id}?isSignUp=1`, '_blank')
  }

  return (
    <div
      data-id={signUp.id}
      className={`sign-up-card ${getStatusClass(signUp.signUpDetails?.status, signUp.crmDetails?.subscription?.status)}`}
      key={signUp.id}
    >
      <div className="card-header">
        <div className="section">
          <SegmentationScoreIcon userId={signUp.id} previouslySegmentationInfo={signUp.segmentationInfo} />
          {signUp.shopify && <FaShopify className="icon" />}
          <span className="username">{getUsername(signUp)}</span>
        </div>
        <div style={{ justifyContent: 'flex-end' }} className='section'>
          <div style={{ whiteSpace: 'nowrap' }}>{"Day " + getDaysPassedSince(signUp.createdAt)}</div>
          <a className='icon' style={{ color: "white" }} href={"https://" + signUp.url} target='_blank'>
            <FaLink />
          </a>
          <NotesPopup user={signUp} username={signUp.name} onClick={goToDetails} />
        </div>
      </div>
      <div className="card-content">
        <div className='section statuses'>
          <div className='signed-up-date'>🗓️ {helper.convertDateToString(signUp.createdAt)}</div>
          <div className={`signup-status ${getStatusClass(signUp.signUpDetails?.status, signUp.crmDetails?.subscription?.status)}`}>
            <QuickDropdown
              className="signup-status"
              options={
                ["Touch", "No Touch"]
              }
              emptyText={"<Not set>"}
              preSelectedOption={signUp.crmDetails?.subscription?.status === "Fast Churn" ? "Fast Churn" : signUp.signUpDetails?.status}
              onSave={async (value) => {
                await save('status', value)
              }} />
          </div>
        </div>

        <div className="section contact">
          <div className='inputs'>
            <div className="line">
              <CustomerCardInput
                label={"Calls"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.calls?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastCall)}
                onSave={async (value) => {
                  await save('calls', { count: value, lastModified: Date.now() })
                }}
              />
              <CustomerCardInput
                label={"Talks"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.talks?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastTalk)}
                onSave={async (value) => {
                  await save('talks', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
            <div className="line">
              <CustomerCardInput
                label={"Emails"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.emails?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastEmail)}
                onSave={async (value) => {
                  await save('emails', { count: value, lastModified: Date.now() })
                }}
              />
              <CustomerCardInput
                label={"SMSes"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.smses?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastSMS)}
                onSave={async (value) => {
                  await save('smses', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
            <div className='line'>
              <div className="input-checkbox">
                <label htmlFor={`meeting-done-${signUp.id}`}>Meeting done:</label>
                <Checkbox
                  name={"isSignUpMeetingDone"}
                  label={""}
                  value={signUp.signUpDetails?.isSignUpMeetingDone ?? false}
                  checked={signUp.signUpDetails?.isSignUpMeetingDone ?? false}
                  onChange={(event) => {
                    saveIsSignUpMeetingDone(event.target.value !== "true")
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section contact-info">
          <span
            className={`phone ${fullPhoneNumber ? '' : 'not-available'}`}
            onClick={copyPhoneNumber}
            title={fullPhoneNumber ? 'Click to copy' : 'No phone number info'}
          >
            <div>
              <div>
                {fullPhoneNumber ? fullPhoneNumber : 'No phone'}
                <FaCopy className="icon" />
              </div>
            </div>
          </span>
          <div className='right-side'>
            <div className='email'><Link
              className="email"
              to={`mailto:${signUp.email}`}
              title="Send new mail"
            >
              <FaPenSquare className="icon" />
              {signUp.email}
            </Link></div>
          </div>
        </div>

        <div className="section">
          <div className="item">
            <UtmsPopup utms={signUp.signUpDetails?.utms}
              child=
              {<div>UTMs: {signUp.signUpDetails?.utms?.utm_medium ?? signUp.signUpDetails?.utms?.utm_source ?? "<Not set>"}</div>}
            ></UtmsPopup>
          </div>
        </div>
        <div className="section crm-info">
          <div>
            {(!!salesPeople && Object.keys(salesPeople).length) ? <QuickDropdown
              className="account-manager"
              options={
                Object.keys(salesPeople)
              }
              preText={"Sales Person: "}
              emptyText={"<Not set>"}
              preSelectedOption={signUp.crmDetails?.salesPerson}
              isConvertingFromId={true}
              isShorteningName={true}
              onSave={async (value) => {
                await save('salesPerson', value)
              }} /> : null}
          </div>

          <div className='country'>{signUp.crmDetails?.country || "<No country>"}</div>
        </div>
        <div className='section'>
          <CustomerCardAlarmPart user={signUp} findByIdAndUpdate={adminActions.findSignUpByIdAndUpdate} alarmsPath="crmAlarmsSignUp" />
        </div>
      </div>
    </div >
  )
}

export default SignUpCard
