import React, { useEffect, useMemo, useState } from 'react'
import { FaCog, FaTrash, FaEye, FaEyeSlash, FaChevronDown, FaPencilAlt, FaTimes, FaCheck, FaToggleOn, FaToggleOff, FaPlusCircle } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useSelector, useStore } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useModal } from '../../../shared/hooks/modal-hook'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import { useFacebookCampaignEditor } from '../../../shared/hooks/facebook/campaign-editor-hook'
import useNavigator from '../../../shared/hooks/navigator-hook'
import countriesData from '../../../shared/data/countries.json'
import errorHandler from '../../../shared/util/errorHandler'
import helper from '../../../shared/util/helper'
import CampaignFormDisplayTypes from '../../../shared/enums/CampaignFormDisplayTypes'
import Modals from '../../../shared/enums/Modals'
import FluidCard from '../../../shared/components/UIElements/FluidCard'
import Switch from '../../../shared/components/UIElements/Switch'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import LoadingDotsSpinner from '../../../shared/components/UIElements/LoadingDotsSpinner'
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import Button from '../../../shared/components/FormElements/Button'
import CampaignReport from '../report/CampaignReport'
import CampaignLabel from './CampaignLabel'
import PublishButton from './PublishButton'
import CancelButton from './CancelButton'
import AdCreativePreviewer from './AdCreativePreviewer'
import CampaignDetailErrors from './CampaignDetailErrors'
import CampaignTargetingForm from './CampaignTargetingForm'
import CampaignCreativesSection from './CampaignCreativesSection'
import CampaignCreativesSectionEditMode from './CampaignCreativesSectionEditMode'

import './FullFunnelDetail.css'
import { useFullFunnel } from '../../../shared/hooks/facebook/full-funnel-hook'

const FullFunnelDetail = ({
  fullFunnelId,
  isExpanded: parentIsExpanded,
}) => {
  const location = useLocation()
  const { openModal } = useModal()
  const { addParamToUrl, removeParamFromUrl } = useNavigator()
  const facebook = useSelector(state => state.facebook)
  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser'))

  const fullFunnel = useMemo(
    () => facebook.fullFunnels.find(f => f._id === fullFunnelId),
    [fullFunnelId, facebook.fullFunnels]
  )
  const isProcessing = useSelector(state => state.facebookCampaignEditor?.loadings.isProcessingTextFields)
  const currentCampaign = useSelector(state => state.facebookCampaignEditor?.currentCampaign)
  const store = useStore()

  const [localIsExpanded, setLocalIsExpanded] = useState(false)
  const { navigateWithAdminParams } = useAdmin()
  const fullFunnelEffectiveStatuses = useSelector(state => state.facebook.fullFunnelEffectiveStatuses)
  const effectiveStatus = fullFunnelEffectiveStatuses[fullFunnelId] || 'Off'
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const label = fullFunnel?.label || ''
  const [errors, setErrors] = useState({})
  const [switchEnabled, setSwitchEnabled] = useState(effectiveStatus === 'ACTIVE' ? 'Active' : 'Off')
  const [currentCreative, setCurrentCreative] = useState(Object.values(fullFunnel?.adCreatives)[0])
  const [missingCampaigns, setMissingCampaigns] = useState([])

  const activeDisplay = useSelector(state => state.facebookCampaignEditor.activeDisplay)

  const { updateFullFunnel, updateFullFunnelStatus, deleteFullFunnel, checkIfFullFunnelHasMissingCampaigns, createMissingCampaignsInFullFunnel } = useFullFunnel()

  const {
    startExistingCampaignEditing,
    endCampaignEditing,
    endCampaignEditingWithoutModal,
    changeActiveDisplay,
  } = useFacebookCampaignEditor()

  const isAdAcountCorrect =
    fullFunnel?.adAccountId === facebook.selectedAdAccount.id
  const isPixelCorrect = fullFunnel?.pixelId === facebook.selectedPixel.id
  const isBusinessCorrect =
    fullFunnel?.businessId === facebook.selectedBusiness.id

  const createdAt = (() => {
    let date
    if (fullFunnel?.startTime) {
      date = new Date(fullFunnel.startTime)
    } else if (fullFunnel?.createdAt) {
      date = new Date(fullFunnel.createdAt)
    } else {
      date = new Date() // fallback
    }

    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  })()

  useEffect(() => {
    if (parentIsExpanded !== undefined) {
      setLocalIsExpanded(parentIsExpanded)
    }
  }, [parentIsExpanded])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const editParam = searchParams.get('edit')
    const aiCreativeId = searchParams.get('aiCreativeId')

    if (editParam && !isEditing) {
      handleEdit({
        aiCreativeId
      })
    }

    if (!editParam && currentCampaign) {
      endCampaignEditingWithoutModal()
      setIsEditing(false)
    }
  }, [location.search])

  useEffect(() => {
    if (!fullFunnel.createdOnFacebookPanel) {
      let result = checkIfFullFunnelHasMissingCampaigns({
        fullFunnelId
      })
      console.log("result", result)
      if (result.length > 0) {
        setMissingCampaigns(result)
      }
    }

    const { adCampaign, adsets, ads, adCreative } = fullFunnel
    const structure = {
      adCampaign: adCampaign?.error,
      adCreative: adCreative?.error,
      adsets: {
        remarketing: adsets?.remarketing?.error,
        lookalike: adsets?.lookalike?.error,
        broad: adsets?.broad?.error,
      },
      ads: {
        remarketing: ads?.remarketing?.error,
        lookalike: ads?.lookalike?.error,
        broad: ads?.broad?.error,
      },
    }

    setErrors(structure)
  }, [])

  useEffect(() => {
    setSwitchEnabled(effectiveStatus === 'ACTIVE' ? 'Active' : 'Off')
  }, [effectiveStatus])

  const handlePublish = async () => {
    let editorState = store.getState().facebookCampaignEditor
    let finalEditorState = editorState.currentCampaign
    let initialEditorState = editorState.initialCampaign

    const finalDailyBudget = finalEditorState.targeting.dailyBudget
    const finalAge = finalEditorState.targeting.age
    const finalGenders = finalEditorState.targeting.genders
    const finalCountries = finalEditorState.targeting.countries

    const initialDailyBudget = initialEditorState.targeting.dailyBudget
    const initialAge = initialEditorState.targeting.age
    const initialGenders = initialEditorState.targeting.genders
    const initialCountries = initialEditorState.targeting.countries

    const countriesChanged = !helper.isEqual(initialCountries, finalCountries)

    const finalCreatives = finalEditorState.adCreatives
    const initialCreatives = initialEditorState.adCreatives

    let finalCreativeIds = Object.keys(finalCreatives)
    let initialCreativeIds = Object.keys(initialCreatives)

    let creativesChanged = finalCreativeIds.length !== initialCreativeIds.length
    finalCreativeIds.forEach(finalCreativeId => {
      // if final creative id doesnt exists in the initial list or it exists but edited so has replacesCreativeWithId field
      if (
        !initialCreativeIds.includes(finalCreativeId) ||
        finalCreatives[finalCreativeId].replacesCreativeWithId
      ) {
        creativesChanged = true
      }
    })

    console.log(
      'initial and final budgets: ',
      initialDailyBudget,
      finalDailyBudget,
    )
    const data = {
      campaignName: finalEditorState.label,
      fullFunnelId: fullFunnel._id,
      label:
        initialEditorState.label.trim() !== finalEditorState.label.trim()
          ? finalEditorState.label.trim()
          : null,
      countryCodes: countriesChanged
        ? finalCountries.map(r => helper.findBy(countriesData, 'name', r).code)
        : null,
      countries: countriesChanged ? finalCountries : null,
      dailyBudget:
        parseInt(finalDailyBudget) !== initialDailyBudget
          ? parseInt(finalDailyBudget)
          : null,
      age:
        initialAge.min !== finalAge.min || initialAge.max !== finalAge.max
          ? finalAge
          : null,
      genders:
        finalGenders !== initialGenders
          ? helper.getGendersList(finalGenders)
          : null,
      id: fullFunnel.id,
      adCampaign: fullFunnel.adCampaign,
      adsets: fullFunnel.adsets,
      ads: fullFunnel.ads,

      trafficCampaign: fullFunnel.trafficCampaign,
    }

    if (creativesChanged) {
      data.adCreatives = finalCreatives
    }

    try {
      setIsEditing(false)
      setIsLoading(true)
      await updateFullFunnel(data)
      sessionStorage.setItem("updatedAdsetsRecently", "true")
      toast.success('Your changes were published successfully!')
      setIsLoading(false)
    } catch (err) {
      errorHandler(err)
    }
  }

  const handleCancel = () => {
    endCampaignEditing({
      callback: () => {
        removeParamFromUrl('edit')
        setIsEditing(false)
      },
    })
  }

  const handleStatusSwitch = async futureState => {
    console.log('state', futureState)

    const futureStatus = futureState === 'Off' ? 'PAUSED' : 'ACTIVE'

    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: `Are you sure you want to ${futureState === 'Active' ? 'activate' : 'deactivate'
        } this campaign?`,
    })

    if (confirmed) {
      setIsLoading(true)
      let result = await updateFullFunnelStatus({
        fullFunnelId: fullFunnel._id,
        status: futureStatus,
      })
      if (result) {
        sessionStorage.setItem("updatedAdsetsRecently", "true")
        setSwitchEnabled(futureState)
        toast.success(
          futureState === 'Off' ? 'Campaign deactivated!' : 'Campaign activated!'
        )
        endCampaignEditingWithoutModal()
        setIsEditing(false)
        setIsLoading(false)
        removeParamFromUrl('edit')
      }
    }
  }


  const removeCampaign = async () => {
    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question:
        'The campaign will be permanently deleted, and this action is irreversible. Are you sure you want to proceed?',
    })

    if (confirmed) {
      setIsLoading(true)

      await deleteFullFunnel({
        id: fullFunnelId
      })
      setIsLoading(false)
      navigateWithAdminParams('/ai-ads/full-funnel-campaigns')
    }
  }

  const handleEdit = ({ aiCreativeId }) => {
    startExistingCampaignEditing({
      fullFunnel,
      existingAICreativeId: aiCreativeId
    })
    setIsEditing(true)
    setLocalIsExpanded(true)
  }

  const editButtonClicked = () => {
    if (fullFunnel.createdOnFacebookPanel) {
      setIsEditing(false)
      setLocalIsExpanded(true)
      return
    }

    if (isAdAcountCorrect && isPixelCorrect && isBusinessCorrect) {
      addParamToUrl({ edit: 1 })
    } else {
      var modalConfig = {
        title: 'Campaign and connection mismatch',
        imageUrl:
          'https://cdn.enhencer.com/website-assets/images/icon-set/error.png',
        text: '',
        actions: [
          {
            text: 'Edit connection',
            callback() {
              navigateWithAdminParams('/config')
            },
          },
        ],
      }
      if (!isAdAcountCorrect) {
        modalConfig.text +=
          "Your selected Ad Account is different from campaigns's Ad Account. Please select the same Ad Account when you create the campaign."
      } else if (!isPixelCorrect) {
        modalConfig.text +=
          "Your selected Pixel is different from campaigns's Pixel. Please select the same Pixel when you create the campaign."
      } else if (!isBusinessCorrect) {
        modalConfig.text +=
          "Your selected Business is different from campaigns's Business. Please select the same Business when you create the campaign."
      }

      openModal({
        type: Modals.GENERIC_MODAL,
        data: modalConfig,
      })
    }
  }

  const handleDelete = () => {
    setIsEditing(false)
    removeCampaign()
  }

  const handleExpandToggle = () => {
    const newValue = !localIsExpanded
    setLocalIsExpanded(newValue)
  }

  const toggleTab = tab => {
    changeActiveDisplay({ display: tab })
  }

  const addMissingCampaigns = async () => {
    await createMissingCampaignsInFullFunnel({
      fullFunnelId
    })
    //setHasMissingCampaigns(false)
  }

  return (
    <FluidCard className="campaign-detail" data-expanded={localIsExpanded}>
      <FluidCard.Header>
        <div className="header-wrapper">
          <div className="options">
            {isEditing ? (
              <CampaignLabel />
            ) : (
              <div className="campaign-header-column">
                <div className="campaign-label" title={label}>
                  {label}
                </div>
                <div className="campaign-label-date">
                  Created at: {createdAt}
                </div>
              </div>
            )}
          </div>
          {isEditing ? (
            isLoading ? null : (
              <div className="actions">
                {isProcessing && <LoadingDotsSpinner />}
                <CancelButton
                  className="cancel-button"
                  onClick={handleCancel}
                >
                  <FaTimes />
                  Cancel
                </CancelButton>
                <PublishButton className="publish" busy={isLoading} onClick={handlePublish}>
                  <FaCheck />
                  Publish
                </PublishButton>
                <Button className="danger" onClick={handleDelete}>
                  <FaTrash />
                  Remove
                </Button>
                {effectiveStatus && (
                  <div className="status-switch-wrapper">
                    <div className="campaign-status">
                      Campaign Status:{' '}
                      <span className="status" style={{ background: 'none' }}>
                        {fullFunnel.reports?.campaignLevel?.effectiveStatus}
                      </span>
                    </div>
                    <Switch
                      value={switchEnabled}
                      onChange={handleStatusSwitch}
                      options={['Off', 'Active']}
                      icons={{
                        Off: <FaToggleOff />,
                        Active: <FaToggleOn />
                      }}
                    />
                  </div>
                )}
              </div>
            )
          ) : (
            <div className="actions">
              {viewingAsUser && missingCampaigns.length > 0 &&
                <Tooltip
                  position="bottom"
                  content={
                    <div>
                      <span style={{ borderBottom: '1px solid white', paddingBottom: '5px' }}>Missing campaigns</span>
                      <ul style={{ paddingLeft: '10px' }}>
                        {missingCampaigns.map(campaign => (
                          <li key={campaign}>
                            {campaign.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                >
                  <Button
                    className="edit"
                    onClick={addMissingCampaigns}
                    disabled={fullFunnel.createdOnFacebookPanel}
                  >
                    <FaPlusCircle />
                    Add missing campaigns
                  </Button>
                </Tooltip>
              }

              <Tooltip
                isHidden={fullFunnel.createdOnFacebookPanel ? false : true}
                position="bottom"
                content={
                  "This campaign created on facebook panel. Editing disabled."
                }
              >

                <Button
                  className="edit"
                  onClick={editButtonClicked}
                  disabled={fullFunnel.createdOnFacebookPanel}
                >
                  <FaPencilAlt />
                  Edit
                </Button>
              </Tooltip>
              <Button className="view" onClick={handleExpandToggle}>
                {localIsExpanded ? (
                  <>
                    <FaEyeSlash />
                    Hide Details
                  </>
                ) : (
                  <>
                    <FaEye />
                    View Details
                  </>
                )}
              </Button>

              {fullFunnel.createdOnFacebookPanel && localIsExpanded && (
                <>
                  <Button className="danger" onClick={handleDelete}>
                    <FaTrash />
                    Remove
                  </Button>
                  <Switch
                    value={switchEnabled}
                    onChange={handleStatusSwitch}
                    options={['Off', 'Active']}
                    icons={{
                      Off: <FaToggleOff />,
                      Active: <FaToggleOn />
                    }}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </FluidCard.Header>
      <div className={`content-wrapper ${localIsExpanded ? 'expanded' : ''}`}>
        <FluidCard.Content>
          <CampaignDetailErrors errors={errors} />
          <div
            className={`campaign-content-wrapper ${isEditing ? 'editing' : ''}`}
          >
            {isLoading ? (
              <InlineLoadingSpinner message="Updating the campaign..." />
            ) : (
              <>
                {isEditing ? (
                  <>
                    <div className="campaign-sections">
                      <div
                        className="campaign-tabs"
                        data-active-tab={activeDisplay}
                      >
                        <div
                          className={`campaign-tab ${activeDisplay === CampaignFormDisplayTypes.TARGETING ? 'active' : ''}`}
                          onClick={() => toggleTab(CampaignFormDisplayTypes.TARGETING)}
                        >
                          {CampaignFormDisplayTypes.TARGETING}
                        </div>
                        <div
                          className={`campaign-tab ${activeDisplay === CampaignFormDisplayTypes.CREATIVE ? 'active' : ''}`}
                          onClick={() => toggleTab(CampaignFormDisplayTypes.CREATIVE)}
                        >
                          {CampaignFormDisplayTypes.CREATIVE}
                        </div>
                      </div>

                      <div className="campaign-content">
                        <div className="left-side">
                          {activeDisplay === CampaignFormDisplayTypes.TARGETING && (
                            <CampaignTargetingForm />
                          )}
                          {activeDisplay === CampaignFormDisplayTypes.CREATIVE && (
                            <CampaignCreativesSectionEditMode />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="left-side">
                    <div className="campaign-form">
                      <h3>Targeting</h3>
                      <div className="row">
                        <label>Countries:</label>
                        <span className="text-field">
                          {fullFunnel.countries.join(',')}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>Daily Budget:</label>
                          <span className="text-field daily-budget">
                            {helper.getCurrencySymbol(
                              facebook.selectedAdAccount?.currency || 'USD',
                            )}
                            {fullFunnel.dailyBudget}
                          </span>
                        </div>
                        <div className="col"></div>
                      </div>

                      {
                        fullFunnel.boostBudget ?
                          <div className="row">
                            <div className="col">
                              <label>Boost Budget:</label>
                              <span className="text-field daily-budget">
                                {helper.getCurrencySymbol(
                                  facebook.selectedAdAccount?.currency || 'USD',
                                )}
                                {fullFunnel.boostBudget}
                              </span>
                            </div>
                            <div className="col"></div>
                          </div>
                          : null
                      }

                      <div className="row">
                        <label>Gender:</label>
                        <span className="text-field">
                          {helper.getGenderString(fullFunnel.genders)}
                        </span>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label>Age:</label>
                          <span className="text-field age">
                            {fullFunnel.age.min}
                            &nbsp;-&nbsp;
                            {fullFunnel.age.max}
                          </span>
                        </div>
                        <div className="col"></div>
                      </div>
                    </div>
                    <CampaignCreativesSection
                      selectCurrentCreative={setCurrentCreative}
                      adCreatives={fullFunnel.adCreatives}
                    />
                  </div>
                )}

                <AdCreativePreviewer
                  existingCampaignCreative={isEditing ? null : currentCreative}
                />
              </>
            )}
          </div>
        </FluidCard.Content>
        {!isEditing && localIsExpanded && (
          <div
            className={`toggle-button ${localIsExpanded ? 'expanded' : ''}`}
            onClick={handleExpandToggle}
          >
            <div className="icon-wrapper">
              <span className="toggle-text">
                <span
                  className={`text-content ${localIsExpanded ? 'visible' : ''}`}
                >
                  Show Less Details
                </span>
              </span>
              <FaChevronDown />
            </div>
          </div>
        )}
      </div>

      {!isEditing && (
        <div
          className={`campaign-summary-section ${localIsExpanded ? 'expanded' : ''}`}
        >
          <CampaignReport singleFullFunnel={fullFunnel} />
        </div>
      )}
    </FluidCard>
  )
}

export default FullFunnelDetail
