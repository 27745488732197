import { WORKBENCH_SIZE } from '../../../support/constants'
import temp18bg from '../../../../shared/assets/images/ai-creative/temp18bg.svg'
import temp18fg from '../../../../shared/assets/images/ai-creative/temp18fg.svg'

export const Template18Config = {
  hidden: true,
  name: 'Black Friday Looped',
  containerId: 'template18-carousel-single',
  backgroundColor: 'color3',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp18bg,
      isSvg: true,
      svgColor: 'color3',
      svgCustomReplace: '#292929',
      draggable: false,
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp18fg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#191919',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 650,
        maxHeight: 650,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Primary Text (Discount Code Label)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 325,
        y: WORKBENCH_SIZE.height - 130,
      },
      width: 650,
      text: 'USE CODE:',
      fontFamily: 'Anuphan',
      fontSize: 32,
      color: 'color2',
      letterSpacing: 8,
      align: 'center',
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 325,
        y: WORKBENCH_SIZE.height - 80,
      },
      width: 650,
      text: 'GET30', // Dynamic placeholder
      fontFamily: 'Anuphan',
      fontSize: 48,
      fontStyle: 'bold',
      color: 'color2',
      letterSpacing: 1.09,
      align: 'center',
      fontIndex: 2,
    },
  ],
}
