import React from 'react'
import { MdTabletMac } from 'react-icons/md'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'

import ProductsSelectionList from '../components/miscellaneous/ProductsSelectionList'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'
import Button from '../../shared/components/FormElements/Button'

import styles from './ProductsSelection.module.css'

const ProductsSelection = props => {
  const { search } = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(search)
  const from = searchParams.get('from')

  /**
   * We may come to this product selection page from either the editor or
   * the creative lab home page, and we store the previous page in the 'from' query param.
   * Using the 'from' query param, we determine which back button to show.
   */
  const navButton =
    from === 'editor' ? (
      <Button
        className={styles.backButton}
        onClick={() => {
          navigate('/ai-ads/creative-lab/editor')
        }}
      >
        <FaChevronLeft className={styles.backButtonIcon} />
        Back to Editor
      </Button>
    ) : (
      <Button
        className={styles.backButton}
        onClick={() => {
          navigate('/ai-ads/creative-lab')
        }}
      >
        <FaChevronLeft className={styles.backButtonIcon} />
        Back
      </Button>
    )

  return (
    <div className={styles.container}>
      <Breadcrumb actions={navButton}>
        <Link to="/ai-ads/creative-lab/">
          <div className={styles.catalogTopLogoBackButton}>
            <span className={styles.catalogTopLogoText}>AI Creative Lab</span>
            <MdTabletMac className={styles.catalogTopLogoDeviceIcon} />
          </div>
        </Link>
      </Breadcrumb>
      <div className={styles.productSelectionTitle}>
        <span>Select products for your creative</span>
      </div>
      <div className={styles.mainRow}>
        <div className={styles.flow}>
          <div className={styles.productSectionBody}>
            <ProductsSelectionList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsSelection
