import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaEdit } from 'react-icons/fa'

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import useAICreatives from '../../../shared/hooks/ai-creatives-hook'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import helper from '../../../shared/util/helper'
import errorHandler from '../../../shared/util/errorHandler'
import Button from '../../../shared/components/FormElements/Button'

import styles from './AllSavedOverlay.module.css'
import useNavigator from '../../../shared/hooks/navigator-hook'

const AllSavedOverlay = ({ setShowAllSavedOverlay }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { navigateWithParams } = useNavigator()
  const {
    productOnDraft,
    draftConfiguration,
    selectedProductsWithConfigurations,
    discount,
    companyDetails,
  } = useEnhencedCatalog()

  const [isSaving, setIsSaving] = useState(false)
  const { createNew } = useAICreatives()

  const selectProduct = productId => {
    const product = helper.findBy(
      selectedProductsWithConfigurations,
      'id',
      productId,
    )

    dispatch(
      aiCreativeEditorActions.setDraftConfiguration({
        ...draftConfiguration,
        id: productId,
        initial: true,
        productName: product.name,
      }),
    )

    dispatch(aiCreativeEditorActions.setProductOnDraft(product))
  }

  const editProduct = productId => {
    selectProduct(productId)

    const productToEdit = helper.findBy(
      selectedProductsWithConfigurations,
      'id',
      productId,
    )

    const configuration = { initial: false, ...productToEdit.configuration }

    dispatch(
      aiCreativeEditorActions.setSelectedProducts(
        selectedProductsWithConfigurations.map(product => {
          if (product.id === productId) {
            return { ...product, isSaved: false }
          }
          return product
        }),
      ),
    )

    dispatch(
      aiCreativeEditorActions.setProductOnDraft({
        ...productToEdit,
        isSaved: false,
      }),
    )

    dispatch(aiCreativeEditorActions.setDraftConfiguration(configuration))

    setShowAllSavedOverlay(false)
  }

  const handleProceedToCampaignLaunch = async () => {
    try {
      setIsSaving(true)

      const newCreativeId = await createNew({
        selectedProductsWithConfigurations,
        discount,
        companyDetails,
      })

      const urlParams = new URLSearchParams(location.search)
      const campaignId = urlParams.get('campaignId')
      const isEdit = urlParams.get('edit')
      
      if (isEdit && campaignId) {
        navigateWithParams(`/ai-ads/full-funnel-campaigns`, {
          aiCreativeId: newCreativeId,
          campaignId,
          edit: isEdit,
        })
      } else {
        navigateWithParams(`/ai-ads/full-funnel-campaigns/create`, {
          aiCreativeId: newCreativeId,
        })
      }

      sessionStorage.removeItem('currentAiCreativeId')
      sessionStorage.removeItem('aiCreativeProducts')
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className={`${styles.savedOverlay} ${styles.fullsize}`}>
      <div className={styles.savedOverlayContent}>
        <div className={styles.lastStepOverlay}>
          <div className={styles.savedOverlayContentTopPart}>
            <div className={styles.savedOverlayContentSmallTitle}>
              It's time to launch!
            </div>
            <h2 className={styles.savedOverlayContentTitle}>
              Congratulations on making your AI Ad Creatives!
            </h2>
          </div>

          <div className={styles.productGrid}>
            {selectedProductsWithConfigurations.map((product, index) => (
              <div key={index} className={styles.productItem}>
                {product.preview ? (
                  <img src={product.preview.url} alt={`Product ${index + 1}`} />
                ) : (
                  <div className={styles.noPreview}>
                    <p>
                      An error occurred while uploading the preview to Facebook.
                    </p>
                  </div>
                )}
                <button
                  className={styles.editButton}
                  onClick={() => editProduct(product.id)}
                >
                  Edit this product
                  <FaEdit className={styles.editIcon} />
                </button>
              </div>
            ))}
          </div>

          <div className={styles.actionButtons}>
            <Button
              primary={true}
              size={'medium'}
              disabled={isSaving}
              onClick={handleProceedToCampaignLaunch}
            >
              Proceed to campaign launch ✨
            </Button>
            <Button
              textButton={true}
              className={styles.continueEditingButton}
              onClick={() => editProduct(productOnDraft.id)}
            >
              Continue Editing
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllSavedOverlay
