import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  VALIDATOR_MAX,
  VALIDATOR_MIN,
  VALIDATOR_REQUIRE,
  VALIDATOR_AGE_RANGE,
} from '../../../shared/util/validators'
import helper from '../../../shared/util/helper'
import { useForm } from '../../../shared/hooks/form-hook'
import StrokeInput from '../../../shared/components/FormElements/StrokeInput'
import { useFacebookCampaignEditor } from '../../../shared/hooks/facebook/campaign-editor-hook'
import { Popover } from 'react-tiny-popover'
import RegionPopoverContent from './RegionPopoverContent'

import './CampaignForm.css'

const COUNTRY_COUNT_LIMIT = 5

const CampaignTargetingForm = () => {
  const [isRegionPopoverOpen, setIsRegionPopoverOpen] = useState(false)

  const selectedAdAccount = useSelector(
    state => state.facebook.selectedAdAccount,
  )
  const facebookSettings = useSelector(state => state.facebook.settings)
  const targeting = useSelector(
    state => state.facebookCampaignEditor.currentCampaign?.targeting,
  )
  const regions = targeting?.countries ?? []
  const age = targeting?.age

  const { changeTargeting, startLoadingState, endLoadingState } =
    useFacebookCampaignEditor()

  const initialFormState = {
    dailyBudget: {
      value: targeting?.dailyBudget || '',
      isValid:
        !!targeting?.dailyBudget &&
        targeting?.dailyBudget >= facebookSettings.minDailyBudget,
    },
    genders: {
      value: targeting?.genders ?? 'All',
      isValid: true,
    },
    age: {
      value: age || { min: '', max: '' },
      isValid: age?.min && age?.max,
    },
  }

  const [formState, inputHandler] = useForm(initialFormState, true)

  const renderCountriesInput = () => {
    const isMobile = window.innerWidth <= 468

    return (
      <StrokeInput
        leftTitle
        label="Countries"
        id="countries"
        value={regions}
        validators={[VALIDATOR_REQUIRE()]}
        errorText="Please select at least one country"
        showInitialError={true}
        renderCustomInput={inputProps => (
          <Popover
            isOpen={isRegionPopoverOpen}
            positions={
              isMobile ? ['bottom'] : ['bottom', 'right', 'left', 'top']
            }
            padding={isMobile ? 0 : -25}
            containerStyle={
              isMobile
                ? {
                    top: '-10%',
                    left: '-7%',
                    width: '100%',
                    borderRadius: '8px',
                    zIndex: 9999,
                  }
                : undefined
            }
            onClickOutside={() => {
              setIsRegionPopoverOpen(false)
              inputProps.onBlur()
            }}
            content={
              <RegionPopoverContent
                regions={regions}
                setRegions={newRegions => {
                  changeTargeting({ countries: newRegions })
                  inputProps.onChange({ target: { value: newRegions } })
                }}
                close={() => {
                  setIsRegionPopoverOpen(false)
                  inputProps.onBlur()
                }}
                countryCountLimit={COUNTRY_COUNT_LIMIT}
              />
            }
          >
            <div>
              <input
                {...inputProps}
                type="text"
                value={regions.toString().replaceAll(',', ', ')}
                onClick={() => setIsRegionPopoverOpen(true)}
                placeholder="Select"
                readOnly
                className="stroke-input"
              />
            </div>
          </Popover>
        )}
      />
    )
  }

  return (
    <div className="campaign-form" data-form-type="targeting">
      <div className="row">{renderCountriesInput()}</div>

      <div className="row">
        <StrokeInput
          leftTitle
          element="money-input"
          label="Daily Budget"
          id="dailyBudget"
          type="number"
          step="1"
          currency={selectedAdAccount?.currency || 'USD'}
          initialValue={formState.inputs.dailyBudget.value}
          onChange={value => {
            changeTargeting({
              dailyBudget: value,
              isDailyBudgetMoreThanMinimum: value >= facebookSettings.minDailyBudget,
            })
          }}
          validators={[VALIDATOR_MIN(facebookSettings.minDailyBudget)]}
          errorText={`Budget must be more than ${helper.getCurrencySymbol(selectedAdAccount?.currency || 'USD')}${facebookSettings.minDailyBudget.toFixed(1)}`}
          showInitialError={true}
          onInput={inputHandler}
        />
      </div>

      <div className="row gender-input">
        <StrokeInput
          leftTitle
          element="select"
          label="Gender"
          id="genders"
          initialValue={formState.inputs.genders.value ?? 'All'}
          onInput={inputHandler}
          options={[
            { value: 'All', label: 'All' },
            { value: 'Female', label: 'Female' },
            { value: 'Male', label: 'Male' },
          ]}
          onChange={value => {
            changeTargeting({
              genders: value,
            })
          }}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={
            !formState.inputs.genders.isValid ? 'Please select a gender' : null
          }
        />
      </div>

      <div className="row">
        <StrokeInput
          leftTitle
          label="Age"
          id="age"
          element="range-input"
          initialValue={formState.inputs.age.value}
          onInput={inputHandler}
          validators={[VALIDATOR_AGE_RANGE()]}
          onChange={value => {
            changeTargeting({
              age: value,
            })
          }}
          errorText="Please enter a valid age range"
          showInitialError={true}
        />
      </div>
    </div>
  )
}

export default CampaignTargetingForm
