import React, { useState, useEffect, useMemo } from 'react'
import Chart from 'chart.js/auto'
import './CampaignReportComparison.css'
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
} from 'chart.js';
import { useHttpClient } from '../../../../shared/hooks/http-hook'
import { useSelector } from 'react-redux';
import helper from '../../../../shared/util/helper'
import {
  FaInfoCircle
} from 'react-icons/fa';
import ReportComparisonTypes from '../../../../shared/enums/ReportComparisonTypes';
import BeforeVsAfterView from './views/BeforeVsAfterView';
import EnhencerVsOthersView from './views/EnhencerVsOthersView';
import AlternativeComparisonView from './views/AlternativeComparisonView';
import TrendAnalysisView from './views/TrendAnalysisView';
import BlockingOverlay from './BlockingOverlay';
Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const cardColors = {
  'Ad Spend': '#0585E0',
  Reach: '#00A090',
  Clicks: '#2FA700',
  Sales: '#00963E',
  Performance: '#00AEE8',
}

const ComparisonSelect = ({ selectedComparison, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const comparisonChartVisibility = useSelector(state => state.auth.user?.crmDetails?.comparisonChartVisibility)

  const comparisonOptions = [
    {
      value: ReportComparisonTypes.TREND_ANALYSIS,
      label: 'Trend Analysis',
      description: 'Analyze performance trends over time with detailed period comparisons and percentage changes',
      isVisible: true
    },
    {
      value: ReportComparisonTypes.AI_ADS_VS_OTHERS,
      label: 'AI Ads vs Others',
      description: 'See how AI-optimized campaigns perform compared to your other campaigns in terms of ROAS, spend, and revenue',
      isVisible: (comparisonChartVisibility?.aiAdsVsOthersRoas || comparisonChartVisibility?.aiAdsVsOthersShares) ?? true
    },
    {
      value: ReportComparisonTypes.BEFORE_AFTER,
      label: 'Before vs After AI Ads',
      description: 'Compare metrics before and after implementing AI Ads to measure the impact on your campaigns',
      isVisible: comparisonChartVisibility?.beforeAfter ?? true
    }
  ];

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    onSelect(value);
    setIsOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.crc-comparison-select')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="crc-comparison-select">
      <button
        className={`crc-comparison-button ${isOpen ? 'open' : ''}`}
        onClick={handleClick}
      >
        {comparisonOptions.find(option => option.value === selectedComparison)?.label}
      </button>
      <div className={`crc-comparison-options ${isOpen ? 'open' : ''}`}>
        {comparisonOptions.filter(option => option.isVisible).length > 0
          ? comparisonOptions.filter(option => option.isVisible).map(option => (
            <div
              key={option.value}
              className={`crc-comparison-option ${selectedComparison === option.value ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option.value)}
            >
              <span className="crc-option-title">{option.label}</span>
              <span className="crc-option-description">{option.description}</span>
            </div>
          ))
          : <div className="crc-comparison-option">Comparisons are not ready yet</div>}
      </div>
    </div>
  );
};

const CampaignReportComparison = ({ initialTab, currency, popupUserFacebookAds, popupUserFacebookInfo }) => {
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [data, setData] = useState(null)
  const [isAlternativeView, setIsAlternativeView] = useState(false)
  const [selectedMetric, setSelectedMetric] = useState('Performance')
  const comparisonChartVisibility = useSelector(state => state.auth.user?.crmDetails?.comparisonChartVisibility)
  const [selectedComparison, setSelectedComparison] = useState(initialTab || ReportComparisonTypes.TREND_ANALYSIS)
  const { sendRequest } = useHttpClient()
  const facebookGraphUrl = process.env.REACT_APP_FACEBOOK_GRAPH_URL
  const connectionPageIsReady = useSelector(state => {
    if (popupUserFacebookInfo) {
      return true;
    }
    return state.facebook.connectionPageIsReady;
  })
  const fullFunnels = useSelector(state => popupUserFacebookInfo?.fullFunnels || state.facebook.fullFunnels)
  const selectedAdAccountId = useSelector(state => popupUserFacebookInfo?.selectedAdAccountId || state.facebook?.selectedAdAccount?.id)
  const accessToken = useSelector(state => popupUserFacebookInfo?.fbAccessToken || state.facebook?.auth?.accessToken)
  const facebookAds = useSelector(state => popupUserFacebookAds || state.auth.user.facebookAds)
  const weeklyReports = facebookAds?.weeklyReports
  const [chartLabels, setChartLabels] = useState([])

  const getCampaignInfo = (fullFunnels) => {
    const getEarliestCampaign = (fullFunnels) => {
      return fullFunnels?.reduce((earliest, funnel) => {
        const startTime = funnel.startTime;
        return !earliest || startTime < earliest ? startTime : earliest;
      }, null);
    };
    const calculateDayCount = (earliestCampaign) => {
      const days = Math.ceil((new Date().valueOf() - new Date(earliestCampaign).valueOf()) / (1000 * 60 * 60 * 24));
      return days > 180 ? 180 : days;
    };

    function calculateTimeRelatedProps(dayCount) {
      const timeIncrement = Math.floor(dayCount / 5) > 90 ? 90 : Math.floor(dayCount / 5);
      const yesterday = new Date(Date.now() - (86400000));
      const since = new Date();
      since.setDate(since.getDate() - dayCount);
      const sinceStr = since.toISOString().split('T')[0];
      const untilStr = yesterday.toISOString().split('T')[0];
      return { sinceStr, untilStr, timeIncrement };
    }

    const earliestCampaign = getEarliestCampaign(fullFunnels);
    const dayCount = calculateDayCount(earliestCampaign);
    return {
      isCampaignNew: earliestCampaign && ((new Date()).valueOf() - (new Date(earliestCampaign)).valueOf()) < 1000 * 60 * 60 * 24 * 3,
      fullFunnelsExist: fullFunnels && fullFunnels.length > 0,
      timeRelatedProps: calculateTimeRelatedProps(dayCount)
    };
  };

  const { isCampaignNew, fullFunnelsExist, timeRelatedProps } = getCampaignInfo(fullFunnels);
  const metricOptions = [
    { label: 'Returns on Ad Spend (ROAS)', value: 'Performance' },
    { label: 'Ad Spend', value: 'AdSpend' },
    { label: 'Reach', value: 'Reach' },
    { label: 'Clicks', value: 'Clicks' },
    { label: 'Sales', value: 'Sales' }
  ]

  useEffect(() => {
    if (selectedAdAccountId?.currency) {
      setCurrencySymbol(helper.getCurrencySymbol(selectedAdAccountId.currency))
    } else {
      setCurrencySymbol(helper.getCurrencySymbol(currency))
    }
  }, [selectedAdAccountId])


  useEffect(() => {
    const fetchHistoricalData = async () => {
      if (!fullFunnels || !selectedAdAccountId || !accessToken || !weeklyReports?.beforeEnhencerSlice?.dayCountBeforeEnhencer) return;
      const { sinceStr, untilStr, timeIncrement } = timeRelatedProps;

      const campaignIds = fullFunnels.reduce((ids, fullFunnel) => {
        fullFunnel.adCampaigns?.forEach(adCampaign => {
          if (adCampaign.campaign.objective === "OUTCOME_SALES") {
            ids.push(adCampaign.campaign.id)
          }
        })
        return ids
      }, [])

      const attributionSetting = "['7d_click','1d_view']"
      const fields = "spend,reach,impressions,frequency,ctr,clicks,cpm,cpc,purchase_roas,actions,action_values,cost_per_action_type"

      const enhencerFilter = [{ field: 'campaign.id', operator: 'IN', value: [campaignIds.join(",")] }]
      const othersFilter = [{ field: 'objective', operator: 'EQUAL', value: 'OUTCOME_SALES' }, { field: 'campaign.id', operator: 'NOT_IN', value: [campaignIds.join(",")] }]

      // Fetch Enhencer campaigns data
      const enhencerUrl = `${facebookGraphUrl}/${selectedAdAccountId}/insights?action_attribution_windows=${attributionSetting}&time_range={'since':'${sinceStr}','until':'${untilStr}'}&fields=${fields}&filtering=${JSON.stringify(enhencerFilter)}&time_increment=${timeIncrement}&access_token=${accessToken}`

      // Fetch other campaigns data
      const othersUrl = `${facebookGraphUrl}/${selectedAdAccountId}/insights?action_attribution_windows=${attributionSetting}&time_range={'since':'${sinceStr}','until':'${untilStr}'}&fields=${fields}&filtering=${JSON.stringify(othersFilter)}&time_increment=${timeIncrement}&access_token=${accessToken}`

      try {
        const [enhencerResponse, othersResponse] = await Promise.all([
          sendRequest(enhencerUrl, 'GET', null, {}, true),
          sendRequest(othersUrl, 'GET', null, {}, true)
        ]);

        if (enhencerResponse?.data && othersResponse?.data) {
          const processedComparisonData = processComparisonData(enhencerResponse?.data, othersResponse?.data, weeklyReports?.beforeEnhencerSlice);
          setChartLabels(processedComparisonData.labels)
          setData(processedComparisonData.data)
          if (!popupUserFacebookInfo && comparisonChartVisibility?.aiAdsVsOthersRoas) {
            setSelectedComparison(ReportComparisonTypes.AI_ADS_VS_OTHERS)
          }
        } else {
          setDummyLabels()
        }
      } catch (error) {
        console.error('Error fetching historical data:', error)
      }
    }

    const setDummyLabels = () => {
      setSelectedComparison(ReportComparisonTypes.AI_ADS_VS_OTHERS)
      const dummyLabels = Array(10).fill().map((_, i) => `Day ${i + 1}`)
      setChartLabels(dummyLabels)
    }

    if (connectionPageIsReady) {
      if (fullFunnels && fullFunnelsExist && !isCampaignNew) {
        fetchHistoricalData()
      } else {
        setDummyLabels()
      }
    }
  }, [fullFunnels, connectionPageIsReady])

  const handleCardClick = (metric) => {
    setSelectedMetric(metric)
    setIsAlternativeView(true)
  }

  const handleBackClick = () => {
    setIsAlternativeView(false)
  }
  return (
    <div className="crc-container">
      {connectionPageIsReady && (
        <BlockingOverlay
          fullFunnelsExist={fullFunnelsExist}
          isCampaignNew={isCampaignNew}>
          <div className="crc-header-controls">
            <div className="crc-title-group">
              <ComparisonSelect
                selectedComparison={selectedComparison}
                onSelect={setSelectedComparison}
              />
            </div>
          </div>
          {selectedComparison === ReportComparisonTypes.BEFORE_AFTER && (
            <div className="crc-info-box">
              <div className="crc-info-icon">
                <FaInfoCircle />
              </div>
              <div className="crc-info-text">
                <span className="crc-info-highlight">AI Ads results</span> are from the last {weeklyReports?.beforeEnhencerSlice?.dayCountBeforeEnhencer || 0} days with Enhencer AI Ads.{' '}
                <span className="crc-info-highlight">Before AI Ads results</span> are from the {weeklyReports?.beforeEnhencerSlice?.dayCountBeforeEnhencer || 0} days before Enhencer AI Ads.
              </div>
            </div>
          )}
          {!isAlternativeView ? (
            selectedComparison === ReportComparisonTypes.BEFORE_AFTER ? (
              <BeforeVsAfterView
                popupUserFacebookAds={popupUserFacebookAds}
                popupUserFacebookInfo={popupUserFacebookInfo}
                data={data}
                currencySymbol={currencySymbol}
                onCardClick={handleCardClick}
              />
            ) : selectedComparison === ReportComparisonTypes.AI_ADS_VS_OTHERS ? (
              <EnhencerVsOthersView
                popupUserFacebookAds={popupUserFacebookAds}
                popupUserFacebookInfo={popupUserFacebookInfo}
                data={data}
                currencySymbol={currencySymbol}
                labels={chartLabels}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
                metricOptions={metricOptions}
              />
            ) : selectedComparison === ReportComparisonTypes.TREND_ANALYSIS ? (
              <TrendAnalysisView
                popupUserFacebookAds={popupUserFacebookAds}
                popupUserFacebookInfo={popupUserFacebookInfo}
                currencySymbol={currencySymbol}
                popupUserFullFunnels={fullFunnels}
              />
            ) : null
          ) : (
            <AlternativeComparisonView
              data={data}
              currencySymbol={currencySymbol}
              labels={chartLabels}
              selectedMetric={selectedMetric}
              setSelectedMetric={setSelectedMetric}
              metricOptions={metricOptions}
              onBackClick={handleBackClick}
            />
          )}
        </BlockingOverlay>
      )}
    </div>
  )
}

export const getDataKey = (metric, attributionWindow) => {
  let result = metric.toLowerCase();
  switch (metric) {
    case 'AdSpend': result = 'adSpend'; break;
    case 'Performance': result = 'roas' + (attributionWindow === '7d_click' ? '_7d_click' : ''); break;
    default: result = result + (attributionWindow === '7d_click' ? '_7d_click' : '');
  }
  return result;
};

const processComparisonData = (enhencerResponseData, othersResponseData, beforeEnhencerSlice) => {
  const chartData = {
    labels: [],
    enhencer: {
      spend: [],
      reach: [],
      clicks: [],
      ctr: [],
      impressions: [],
      sales: [],
      sales_7d_click: [],
      roas: [],
      roas_7d_click: [],
      purchases: [],
      purchases_7d_click: [],
      costPerPurchase: [],
      costPerPurchase_7d_click: [],
    },
    others: {
      spend: [],
      reach: [],
      clicks: [],
      ctr: [],
      impressions: [],
      sales: [],
      sales_7d_click: [],
      roas: [],
      roas_7d_click: [],
      purchases: [],
      purchases_7d_click: [],
      costPerPurchase: [],
      costPerPurchase_7d_click: [],
    }
  }

  // Process Enhencer campaigns data
  enhencerResponseData.forEach(dataPoint => {
    chartData.labels.push(`${dataPoint.date_start},${dataPoint.date_stop}`)
    chartData.enhencer.spend.push(parseFloat(dataPoint.spend || 0))
    chartData.enhencer.reach.push(parseInt(dataPoint.reach || 0))
    chartData.enhencer.clicks.push(parseInt(dataPoint.clicks || 0))
    chartData.enhencer.impressions.push(parseInt(dataPoint.impressions || 0))

    const impressions = parseInt(dataPoint.impressions || 0)
    const clicks = parseInt(dataPoint.clicks || 0)
    const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0
    chartData.enhencer.ctr.push(parseFloat(ctr.toFixed(2)))

    const purchases = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')
      ? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase').value)
      : 0
    const purchases_7d_click = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')
      ? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.enhencer.purchases.push(purchases)
    chartData.enhencer.purchases_7d_click.push(purchases_7d_click)
    const sales = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase').value)
      : 0
    const sales_7d_click = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.enhencer.sales.push(sales)
    chartData.enhencer.sales_7d_click.push(sales_7d_click)

    const roas = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase').value)
      : 0
    const roas_7d_click = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.enhencer.roas.push(roas)
    chartData.enhencer.roas_7d_click.push(roas_7d_click)

    const costPerPurchase = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase').value)
      : 0
    const costPerPurchase_7d_click = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.enhencer.costPerPurchase.push(costPerPurchase)
    chartData.enhencer.costPerPurchase_7d_click.push(costPerPurchase_7d_click)
  })

  // Process other campaigns data
  othersResponseData.forEach(dataPoint => {
    chartData.others.spend.push(parseFloat(dataPoint.spend || 0))
    chartData.others.reach.push(parseInt(dataPoint.reach || 0))
    chartData.others.clicks.push(parseInt(dataPoint.clicks || 0))
    chartData.others.impressions.push(parseInt(dataPoint.impressions || 0))

    const impressions = parseInt(dataPoint.impressions || 0)
    const clicks = parseInt(dataPoint.clicks || 0)
    const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0
    chartData.others.ctr.push(parseFloat(ctr.toFixed(2)))

    const purchases = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')
      ? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase').value)
      : 0
    const purchases_7d_click = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')
      ? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.others.purchases.push(purchases)
    chartData.others.purchases_7d_click.push(purchases_7d_click)
    const sales = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase').value)
      : 0
    const sales_7d_click = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.others.sales.push(sales)
    chartData.others.sales_7d_click.push(sales_7d_click)

    const roas = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase').value)
      : 0

    const roas_7d_click = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.others.roas.push(roas)
    chartData.others.roas_7d_click.push(roas_7d_click)

    const costPerPurchase = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase').value)
      : 0
    const costPerPurchase_7d_click = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')
      ? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')['7d_click'] || 0)
      : 0
    chartData.others.costPerPurchase.push(costPerPurchase)
    chartData.others.costPerPurchase_7d_click.push(costPerPurchase_7d_click)
  })

  // Calculate totals for Enhencer campaigns
  const enhencerTotals = {
    spend: chartData.enhencer.spend.reduce((sum, val) => sum + val, 0),
    reach: chartData.enhencer.reach.reduce((sum, val) => sum + val, 0),
    clicks: chartData.enhencer.clicks.reduce((sum, val) => sum + val, 0),
    sales: chartData.enhencer.sales.reduce((sum, val) => sum + val, 0),
    purchases: chartData.enhencer.purchases.reduce((sum, val) => sum + val, 0),
    impressions: chartData.enhencer.impressions.reduce((sum, val) => sum + val, 0),
  }

  // Calculate totals for Other campaigns
  const othersTotals = {
    spend: chartData.others.spend.reduce((sum, val) => sum + val, 0),
    reach: chartData.others.reach.reduce((sum, val) => sum + val, 0),
    clicks: chartData.others.clicks.reduce((sum, val) => sum + val, 0),
    sales: chartData.others.sales.reduce((sum, val) => sum + val, 0),
    purchases: chartData.others.purchases.reduce((sum, val) => sum + val, 0),
    impressions: chartData.others.impressions.reduce((sum, val) => sum + val, 0),
  }

  const afterData = {
    adSpend: {
      total: enhencerTotals.spend,
      cpm: enhencerTotals.spend > 0 ?
        (enhencerTotals.spend / enhencerTotals.impressions) * 1000 : 0,
      chartData: chartData.enhencer.spend
    },
    adSpend_7d_click: {
      total: enhencerTotals.spend,
      cpm: enhencerTotals.spend > 0 ?
        (enhencerTotals.spend / enhencerTotals.impressions) * 1000 : 0,
      chartData: chartData.enhencer.spend
    },
    reach: {
      reach: enhencerTotals.reach,
      frequency: enhencerTotals.reach > 0 ?
        (enhencerTotals.impressions / enhencerTotals.reach).toFixed(2) : 0,
      chartData: chartData.enhencer.reach
    },
    clicks: {
      clicks: enhencerTotals.clicks,
      ctr: enhencerTotals.impressions > 0 ?
        ((enhencerTotals.clicks / enhencerTotals.impressions) * 100).toFixed(2) : 0,
      chartData: chartData.enhencer.clicks
    },
    sales: {
      amount: enhencerTotals.sales,
      purchases: enhencerTotals.purchases,
      chartData: chartData.enhencer.sales
    },
    sales_7d_click: {
      amount: enhencerTotals.sales,
      purchases: enhencerTotals.purchases,
      chartData: chartData.enhencer.sales_7d_click
    },
    roas: {
      roas: enhencerTotals.spend > 0 ?
        (enhencerTotals.sales / enhencerTotals.spend).toFixed(2) : 0,
      costPerPurchase: enhencerTotals.purchases > 0 ?
        (enhencerTotals.spend / enhencerTotals.purchases).toFixed(2) : 0,
      chartData: chartData.enhencer.roas
    },
    roas_7d_click: {
      roas: enhencerTotals.spend > 0 ?
        (enhencerTotals.sales / enhencerTotals.spend).toFixed(2) : 0,
      costPerPurchase: enhencerTotals.purchases > 0 ?
        (enhencerTotals.spend / enhencerTotals.purchases).toFixed(2) : 0,
      chartData: chartData.enhencer.roas_7d_click
    }
  };

  const othersData = {
    adSpend: {
      total: othersTotals.spend,
      cpm: othersTotals.spend > 0 ?
        (othersTotals.spend / othersTotals.impressions) * 1000 : 0,
      chartData: chartData.others.spend
    },
    adSpend_7d_click: {
      total: othersTotals.spend,
      cpm: othersTotals.spend > 0 ?
        (othersTotals.spend / othersTotals.impressions) * 1000 : 0,
      chartData: chartData.others.spend
    },
    reach: {
      reach: othersTotals.reach,
      frequency: othersTotals.reach > 0 ?
        (othersTotals.impressions / othersTotals.reach).toFixed(2) : 0,
      chartData: chartData.others.reach
    },
    clicks: {
      clicks: othersTotals.clicks,
      ctr: othersTotals.impressions > 0 ?
        ((othersTotals.clicks / othersTotals.impressions) * 100).toFixed(2) : 0,
      chartData: chartData.others.clicks
    },
    sales: {
      amount: othersTotals.sales,
      purchases: othersTotals.purchases,
      chartData: chartData.others.sales
    },
    sales_7d_click: {
      amount: othersTotals.sales,
      purchases: othersTotals.purchases,
      chartData: chartData.others.sales_7d_click
    },
    roas: {
      roas: othersTotals.spend > 0 ?
        (othersTotals.sales / othersTotals.spend).toFixed(2) : 0,
      costPerPurchase: othersTotals.purchases > 0 ?
        (othersTotals.spend / othersTotals.purchases).toFixed(2) : 0,
      chartData: chartData.others.roas
    },
    roas_7d_click: {
      roas: othersTotals.spend > 0 ?
        (othersTotals.sales / othersTotals.spend).toFixed(2) : 0,
      costPerPurchase: othersTotals.purchases > 0 ?
        (othersTotals.spend / othersTotals.purchases).toFixed(2) : 0,
      chartData: chartData.others.roas_7d_click
    }
  };

  const beforeEnhencerData = beforeEnhencerSlice || {
    spend: 0,
    dailyBudget: 0,
    reach: 0,
    frequency: 0,
    clicks: 0,
    ctr: 0,
    sales: 0,
    purchases: 0,
    roas: 0,
    costPerPurchase: 0,
    impressions: 0,
    cpm: 0
  };

  const beforeData = {
    adSpend: {
      total: beforeEnhencerData.spend || 0,
      cpm: beforeEnhencerData.spend > 0 ?
        ((beforeEnhencerData.spend / (beforeEnhencerData.impressions) * 1000)) : 0,
      chartData: Array(6).fill(0)
    },
    adSpend_7d_click: {
      total: beforeEnhencerData.spend || 0,
      cpm: beforeEnhencerData.spend > 0 ?
        ((beforeEnhencerData.spend / (beforeEnhencerData.impressions) * 1000)) : 0,
      chartData: Array(6).fill(0)
    },
    reach: {
      reach: beforeEnhencerData.reach || 0,
      frequency: beforeEnhencerData.reach > 0 ?
        (beforeEnhencerData.impressions / beforeEnhencerData.reach) : 0,
      chartData: Array(6).fill(0)
    },
    clicks: {
      clicks: beforeEnhencerData.clicks || 0,
      ctr: beforeEnhencerData.impressions > 0 ?
        (beforeEnhencerData.clicks / beforeEnhencerData.impressions) * 100 : 0,
      chartData: Array(6).fill(0)
    },
    sales: {
      amount: beforeEnhencerData.sales || 0,
      purchases: beforeEnhencerData.purchases || 0,
      chartData: Array(6).fill(0)
    },
    sales_7d_click: {
      amount: beforeEnhencerData.sales || 0,
      purchases: beforeEnhencerData.purchases || 0,
      chartData: Array(6).fill(0)
    },
    roas: {
      roas: beforeEnhencerData.sales > 0 ?
        (beforeEnhencerData.sales / beforeEnhencerData.spend) : 0,
      costPerPurchase: beforeEnhencerData.purchases > 0 ?
        (beforeEnhencerData.spend / beforeEnhencerData.purchases) : 0,
      chartData: Array(6).fill(0)
    },
    roas_7d_click: {
      roas: beforeEnhencerData.sales > 0 ?
        (beforeEnhencerData.sales / beforeEnhencerData.spend) : 0,
      costPerPurchase: beforeEnhencerData.purchases > 0 ?
        (beforeEnhencerData.spend / beforeEnhencerData.purchases) : 0,
      chartData: Array(6).fill(0)
    }
  };

  return {
    data: {
      after: afterData,
      before: beforeData,
      others: othersData
    },
    labels: chartData.labels,
  }
}

export default CampaignReportComparison 