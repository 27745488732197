import React, { useRef, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import { FaTimes } from 'react-icons/fa'

const ExpandNote = props => {
  const { closeModal, modalData } = useModal()

  const [noteValue, setNoteValue] = useState(props.data.noteText ?? "")
  let noteInputRef = useRef()

  const create = () => {
    const enteredNote = noteInputRef.current.value
    modalData.callback({ note: enteredNote })
    closeModal()
  }

  const remove = () => {
    modalData.removeCallback()
    closeModal()
  }

  const close = () => {
    closeModal()
  }
  const handleNoteChange = (event) => {
    setNoteValue(event.target.value);
  };

  return (
    <div className="alarm-modal edit-alarm-modal">
      <div className="modal-title">
        <div className='alarm-name'>Customer Note</div>
        <div>-</div>
        <div className='customer-name'>{props.data.name}</div>
        <Button size="small" onClick={close}><FaTimes /></Button>
      </div>
      <div className="modal-middle">
        <div className='property row form-control'>
          <div className='property-desc'>Note:</div>
          <textarea ref={noteInputRef} type='text' className='title-input' value={noteValue} onChange={handleNoteChange} rows="5"></textarea>
        </div>
      </div>
    </div>
  )
}


export default ExpandNote
