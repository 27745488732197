import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { toast } from 'react-toastify'

import helper from '../util/helper'
import { aiCreativeEditorActions } from '../../store/ai-creative-editor'
import {
  palettes,
  NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES,
  NUMBER_OF_COMMON_TEMPLATES,
  NUMBER_OF_ENHENCED_CATALOG_TEMPLATES,
  WORKBENCH_SIZE,
} from '../../ai-creative/support/constants'
import { useHttpClient } from './http-hook'
import { useImageUpload } from './image-upload-hook'
import { useFacebook } from './facebook-hook'
import { aiCreativesActions } from '../../store/ai-creatives'
import { authActions } from '../../store/auth'
import errorHandler from '../util/errorHandler'

export const useEnhencedCatalog = () => {
  const dispatch = useDispatch()
  const authToken = useSelector(state => state.auth.token)
  const userId = useSelector(state => state.auth.user.id)
  const userImage = useSelector(state => state.auth.user.image)
  const companyName = useSelector(state => state.auth.user.companyName)

  const {
    isStageLoading,
    companyDetails,
    discount,
    selectedProductsWithConfigurations,
    draftConfiguration,
    productOnDraft,
    backgroundRemoving,
    isSingleSaving,
    isBatchSaving,
    isEnhencedCatalogSaving,
    result,
    error,
    activeMainDisplay,
    previewHelperText,
    isComparingWithOriginal,
    savingQueue,
    currentSavingProductId,
    areCatalogProductsFetched,
    totalProducts,
  } = useSelector(state => state.aiCreativeEditor)
  const userAICreativesIdList = useSelector(
    state => state.auth.user.aiCreatives,
  )
  const { getProductsOfEdge } = useFacebook()

  const facebook = useSelector(state => state.facebook)
  const { fullList } = useSelector(state => state.aiCreatives)
  const { sendRequest } = useHttpClient()
  const { uploadImageToFacebook } = useImageUpload()

  /**
   * Sets the isStageLoading state.
   */
  const setIsStageLoading = useCallback(
    isLoading => {
      dispatch(aiCreativeEditorActions.setIsStageLoading(isLoading))
    },
    [dispatch],
  )

  /**
   * Sets the isSingleSaving state.
   */
  const setIsSingleSaving = useCallback(
    isSaving => {
      dispatch(aiCreativeEditorActions.setIsSingleSaving(isSaving))
    },
    [dispatch],
  )

  /**
   * Sets the isBatchSaving state.
   */
  const setIsBatchSaving = useCallback(
    isSaving => {
      dispatch(aiCreativeEditorActions.setIsBatchSaving(isSaving))
    },
    [dispatch],
  )

  /**
   * Sets the isEnhencedCatalogSaving state.
   */
  const setIsEnhencedCatalogSaving = useCallback(
    isSaving => {
      dispatch(aiCreativeEditorActions.setIsEnhencedCatalogSaving(isSaving))
    },
    [dispatch],
  )

  /**
   * Returns the value of the hideCurrentCreativeCard state from the session storage.
   */
  const isCurrentCreativeCardHidden = useMemo(
    () =>
      JSON.parse(
        sessionStorage.getItem('aiCreativeIsCurrentCreativeCardHidden'),
      ),
    [],
  )

  /**
   * Sets the productOnDraft (the product that is currently being shown in the canvas) state.
   */
  const setProductOnDraft = useCallback(
    product => {
      dispatch(aiCreativeEditorActions.setProductOnDraft(product))
    },
    [dispatch],
  )

  /**
   * Loads the selected products from the session storage.
   */
  const loadCachedSelectedProducts = useCallback(() => {
    const aiCreativeProducts = sessionStorage.getItem('aiCreativeProducts')

    if (aiCreativeProducts) {
      dispatch(
        aiCreativeEditorActions.setSelectedProducts(
          JSON.parse(aiCreativeProducts),
        ),
      )
    }
  }, [dispatch])

  /**
   * Returns the saved configuration of the product with the given id.
   */
  const getSavedConfigurationOfProduct = useCallback(
    productId => {
      if (!productId) return null

      const configuration = helper.findBy(
        selectedProductsWithConfigurations,
        'id',
        productId,
      )?.configuration

      return configuration
    },
    [selectedProductsWithConfigurations],
  )

  /**
   * Returns the final preview object of the product with the given id.
   */
  const getSavedPreviewOfProduct = useCallback(
    productId => {
      if (!productId) return null

      const preview = helper.findBy(
        selectedProductsWithConfigurations,
        'id',
        productId,
      )?.preview

      return preview
    },
    [selectedProductsWithConfigurations],
  )

  /**
   * Returns the saved configuration of the product that is currently being edited.
   */
  const savedConfigurationOfProductOnDraft = useMemo(() => {
    return getSavedConfigurationOfProduct(productOnDraft?.id)
  }, [productOnDraft?.id, getSavedConfigurationOfProduct])

  /**
   * Returns the saved preview of the product that is currently being edited.
   */
  const savedPreviewOfProductOnDraft = useMemo(() => {
    return getSavedPreviewOfProduct(productOnDraft?.id)
  }, [productOnDraft?.id, getSavedPreviewOfProduct])

  /**
   * Makes a request to the backend to remove the background of the selected products.
   * Previously removed backgrounds are cached in the local storage to prevent unnecessary requests.
   * If the image has a transparent background, it will not be sent to the backend.
   */
  const removeBackgroundImages = useCallback(async () => {
    dispatch(aiCreativeEditorActions.setBackgroundRemoving(true))

    const imagesToSendPromises = selectedProductsWithConfigurations
      .filter(product => !localStorage.getItem(`removed-bg-${product.id}`))
      .map(async product => {
        try {
          const hasTransparency = await helper.checkAlpha(
            product.originalImageUrl,
          )

          if (hasTransparency) {
            return null
          }

          return {
            imageUrl: product.originalImageUrl,
            objectId: product.id,
            userId: userId,
          }
        } catch (err) {
          // If the image is not accessible (e.g. because of CORS),
          // we will still send the image to the backend and try if it can be accessed from there.
          // In most cases, since we cannot access the image from the backend too, we can consider not sending the image in the future.
          // So, this is an experimental approach for now.
          return {
            imageUrl: product.originalImageUrl,
            objectId: product.id,
            userId: userId,
          }
        }
      })

    const imagesToSend = (await Promise.all(imagesToSendPromises)).filter(
      Boolean,
    )

    if (!imagesToSend.length) {
      const updatedProducts = selectedProductsWithConfigurations.map(
        product => {
          return {
            ...product,
            transparentImageUrl:
              localStorage.getItem(`removed-bg-${product.id}`) ||
              `${process.env.REACT_APP_BACKEND_URL}/proxy/getProxyImage?url=${encodeURIComponent(product.originalImageUrl)}`,
          }
        },
      )

      dispatch(aiCreativeEditorActions.setSelectedProducts(updatedProducts))
      dispatch(aiCreativeEditorActions.setBackgroundRemoving(false))
      return
    }

    try {
      const response = await fetch(
        `https://aicreative.enhencer.com/remove_bg`,
        {
          method: 'POST',
          body: JSON.stringify({ images: imagesToSend }),
        },
      )

      const data = await response.json()

      // TODO: Use camelCase for image_url and object_id
      const resultsByProductId = data.results.reduce((acc, item) => {
        if (item.image_url) {
          localStorage.setItem(`removed-bg-${item.object_id}`, item.image_url)
          acc[item.object_id] = item.image_url
          return acc
        }
        return acc
      }, {})

      const errorsByProductId = data.results.reduce((acc, item) => {
        if (item.error) {
          acc[item.object_id] = { message: item.error, code: item.status_code }
          return acc
        }
        return acc
      }, {})

      const updatedProducts = selectedProductsWithConfigurations.map(
        product => {
          const newProduct = {
            ...product,
            transparentImageUrl:
              localStorage.getItem(`removed-bg-${product.id}`) ||
              resultsByProductId[product.id] ||
              `${process.env.REACT_APP_BACKEND_URL}/proxy/getProxyImage?url=${encodeURIComponent(product.originalImageUrl)}`,
          }

          const error = errorsByProductId[product.id]

          if (error) {
            switch (error.code) {
              case 403:
                newProduct.error =
                  "We're unable to remove the background of this image due to permission restrictions. Please check the image settings and add Enhencer to your whitelist."
                break
              case 500:
                newProduct.error =
                  "We're unable to remove the background of this image right now. Please try again later."
                break
              default:
                newProduct.error =
                  'Something went wrong. Please try again later.'
                break
            }
          }

          return newProduct
        },
      )

      dispatch(aiCreativeEditorActions.setSelectedProducts(updatedProducts))
    } catch (err) {
      errorHandler(err)
      dispatch(aiCreativeEditorActions.setError(err.message))
    } finally {
      dispatch(aiCreativeEditorActions.setBackgroundRemoving(false))
    }
  }, [dispatch, selectedProductsWithConfigurations, userId])

  /*
   * Makes a request to the backend to generate a discount code for the selected products.
   * The discount code is applied to the products on Shopify.
   * It matches the products that are coming from Facebook with the products on Shopify by the retailer_product_group_id.
   */
  const generateDiscountCode = useCallback(async () => {
    try {
      const productRetailerGroupIds = selectedProductsWithConfigurations.map(
        product => product.retailer_product_group_id,
      )

      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/discounts/generateDiscountCode`,
        'POST',
        JSON.stringify({
          discountAmount: discount.amount,
          discountType: discount.type,
          discountCode: discount.code,
          productIds: productRetailerGroupIds,
        }),
        {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      )

      if (result && result.message === 'success') {
        const { amount, type, code } = result.data

        dispatch(
          aiCreativeEditorActions.setDiscountDetails({
            amount: Math.round(amount) * -1,
            type: type,
            code: code,
            isActive: true,
          }),
        )
      }
    } catch (err) {
      errorHandler(err)
      dispatch(aiCreativeEditorActions.setError(err.message))
    }
  }, [dispatch, sendRequest, authToken, discount])

  /**
   * Sets the draft configuration with the default values.
   */
  const setInitialConfiguration = useCallback(
    ({ type }) => {
      let templateId = 'template1-common'

      if (type === 'carousel' || type === 'single') {
        const totalTemplates =
          NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES + NUMBER_OF_COMMON_TEMPLATES
        if (totalTemplates > NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES) {
          const lastCommonTemplate =
            totalTemplates - NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES
          templateId = `${lastCommonTemplate}-common`
        } else {
          templateId = `${NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES}-carousel-single`
        }
      }

      if (type === 'enhenced-catalog') {
        const totalTemplates =
          NUMBER_OF_ENHENCED_CATALOG_TEMPLATES + NUMBER_OF_COMMON_TEMPLATES
        if (totalTemplates > NUMBER_OF_ENHENCED_CATALOG_TEMPLATES) {
          const lastCommonTemplate =
            totalTemplates - NUMBER_OF_ENHENCED_CATALOG_TEMPLATES
          templateId = `${lastCommonTemplate}-common`
        } else {
          templateId = `${NUMBER_OF_ENHENCED_CATALOG_TEMPLATES}-enhenced-catalog`
        }
      }

    dispatch(
      aiCreativeEditorActions.setDraftConfiguration({
        initial: true,
        template: { id: templateId, hasLogo: true }, // Select the last template
        palette: {
          index: 0,
          color1: palettes[0][0],
          color2: palettes[0][1],
          color3: palettes[0][2],
          color4: palettes[0][3],
        },
        showCompanyLogo: false,
      }),
    )

      dispatch(
        aiCreativeEditorActions.setCompanyDetails({
          companyLogoUrl: userImage,
          companyName,
        }),
      )
    },
    [dispatch],
  )

  /**
   * Saves the current configurations to the product with the given id.
   */
  const applyConfigurationToProduct = useCallback(
    (productId, history) => {
      dispatch(
        aiCreativeEditorActions.setConfigurationToProduct({
          productId,
          configuration: draftConfiguration,
          history,
        }),
      )
      dispatch(aiCreativeEditorActions.setIsSingleSaving(true))
    },
    [dispatch, draftConfiguration],
  )

  /**
   * Saves the current configurations and captures previews for all products.
   */
  const applyConfigurationToAllProducts = useCallback(
    historiesByProductId => {
      selectedProductsWithConfigurations.forEach(product => {
        const historyObject = historiesByProductId[product.id]
        if (!historyObject) return

        dispatch(
          aiCreativeEditorActions.setConfigurationToProduct({
            productId: product.id,
            configuration: draftConfiguration,
            history: [historyObject],
          }),
        )
      })

      const queue = selectedProductsWithConfigurations.map(
        product => product.id,
      )
      setSavingQueue(queue)

      setCurrentSavingProductId(queue[0])

      dispatch(aiCreativeEditorActions.setIsBatchSaving(true))
    },
    [applyConfigurationToProduct, selectedProductsWithConfigurations, dispatch],
  )

  /**
   * Captures the final preview on the canvas for the given product.
   * It prevents the transformer from being visible on the preview and creates the preview image with the correct size.
   */
  const savePreviewToProduct = useCallback(
    async (stage, productId, scaleRatio) => {
      if (!facebook.auth?.accessToken) {
        return
      }

      const transformer = stage.findOne('Transformer')
      const textTransformer = stage.findOne('.TextTransformer')

      if (transformer || textTransformer) {
        transformer?.hide()
        textTransformer?.hide()
      }

      let pixelRatio = scaleRatio

      if (!scaleRatio) {
        const canvasContainer = document.querySelector('.konvajs-content')
        pixelRatio = WORKBENCH_SIZE.width / canvasContainer.offsetWidth
      }

      try {
        const dataURL = await stage.toDataURL({ pixelRatio })
        const result = await uploadImageToFacebook({
          file: dataURL,
          accessToken: facebook.auth.accessToken,
        })

        if (transformer) {
          transformer.show()
        }

        if (textTransformer) {
          textTransformer.show()
        }

        dispatch(
          aiCreativeEditorActions.setPreviewToProduct({
            productId: productId,
            preview: result,
          }),
        )
      } catch (error) {
        console.log('error', error)
      }

    },
    [dispatch, uploadImageToFacebook, facebook.auth?.accessToken],
  )

  /**
   * Hides the transformer on the stage.
   */
  const disableTransformers = useCallback(stageRef => {
    const transformer = stageRef.current.findOne('Transformer')
    if (transformer) {
      transformer.hide()
    }
  }, [])

  /**
   * Resets the enhenced catalog state to the initial values.
   */
  const resetEnhencedCatalog = useCallback(() => {
    dispatch(aiCreativeEditorActions.resetEnhencedCatalog())
  }, [dispatch])

  const applyDiscount = useCallback(
    async ({ discountAmount, discountType, discountCode, isActive }) => {
      dispatch(
        aiCreativeEditorActions.setDiscountDetails({
          amount: discountAmount,
          type: discountType,
          code: discountCode,
          isActive,
        }),
      )

      cleanAllConfigurations()
      /* if (isActive) {
      in the future we will apply the new discount to all products
        applyNewDiscountToAllProducts({
          amount: discountAmount,
          type: discountType,
          code: discountCode,
        })
      } */

      toast.success(
        isActive
          ? 'Discount updated, reconfigure all the products'
          : 'Discount deactivated',
      )
    },
    [sendRequest, dispatch, authToken, selectedProductsWithConfigurations],
  )

  const updateDiscount = ({
    discountAmount,
    discountType,
    discountCode,
    isActive,
  }) => {
    let newDiscount = {
      ...discount,
    }
    if (discountAmount !== undefined) newDiscount.amount = discountAmount
    if (discountType !== undefined) newDiscount.type = discountType
    if (discountCode !== undefined) newDiscount.code = discountCode
    if (isActive !== undefined) newDiscount.isActive = isActive

    dispatch(aiCreativeEditorActions.setDiscountDetails(newDiscount))
  }

  const updateCompanyDetails = ({ usesBgRemovedLogo, companyLogoUrl }) => {
    let newCompanyDetails = {
      ...companyDetails,
    }
    if (usesBgRemovedLogo !== undefined)
      newCompanyDetails.usesBgRemovedLogo = usesBgRemovedLogo
    if (companyLogoUrl !== undefined)
      newCompanyDetails.companyLogoUrl = companyLogoUrl

    dispatch(aiCreativeEditorActions.setCompanyDetails(newCompanyDetails))
  }

  const updateCustomTexts = (texts, initial) => {
    let newDraftConfiguration = {
      initial,
    }
    if (texts.customDiscountText !== undefined)
      newDraftConfiguration.customDiscountText = texts.customDiscountText
    if (texts.customDiscountCodeText !== undefined)
      newDraftConfiguration.customDiscountCodeText =
        texts.customDiscountCodeText
    if (texts.productName !== undefined)
      newDraftConfiguration.productName = texts.productName
    dispatch(
      aiCreativeEditorActions.setDraftConfigurationTexts(newDraftConfiguration),
    )
  }

  const cleanAllConfigurations = () => {
    let newProductsWithConfiguration = selectedProductsWithConfigurations.map(
      product => {
        if (product.configuration) {
          return {
            ...product,
            configuration: null,
          }
        }
        return product
      },
    )

    dispatch(
      aiCreativeEditorActions.updateAllProductsWithConfigurations(
        newProductsWithConfiguration,
      ),
    )
  }

  const applyNewDiscountToAllProducts = ({ amount, type, code }) => {
    let newProductsWithConfiguration = selectedProductsWithConfigurations.map(
      product => {
        if (product.configuration) {
          return {
            ...product,
            configuration: {
              ...product.configuration,
              customDiscountText: amount + '% OFF',
              customDiscountCodeText: 'CODE: ' + code,
            },
          }
        }
        return product
      },
    )

    dispatch(
      aiCreativeEditorActions.updateAllProductsWithConfigurations(
        newProductsWithConfiguration,
      ),
    )
  }

  /**
   * Makes a request to the backend to retrieve the previously generated and saved in the database discount codes.
   */
  const getSavedDiscountCodes = useCallback(async () => {
    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/discounts/getSavedDiscountCodes`,
        'GET',
        null,
        {
          Authorization: `Bearer ${authToken}`,
        },
      )

      return result.data
    } catch (err) {
      errorHandler(err)
      dispatch(aiCreativeEditorActions.setError(err.message))
    }
  }, [sendRequest, dispatch, authToken])

  /**
   * Adds the given state (shape positions, sizes, etc.) to the history array as the initial state of the history.
   * It is used for the undo and redo functionality.
   */
  const initHistory = useCallback(
    state => {
      dispatch(aiCreativeEditorActions.setHistory([state]))
      dispatch(aiCreativeEditorActions.setHistoryStep(0))
    },
    [dispatch],
  )

  /**
   * Adds a new state of the given key (companyLogo, productName, etc.) to the history array.
   */
  const addToHistory = useCallback(
    (key, state) => {
      dispatch(aiCreativeEditorActions.addToHistory({ key, value: state }))
    },
    [dispatch],
  )

  /**
   * For some templates, the initial positions of the shapes are calculated dynamically and
   * the values that come from the 'templateConfig.initialPositions' are overwritten (See GenericTemplate.js).
   * This function updates the history object to save the calculated positions.
   */
  const updateHistoryObject = useCallback(
    (key, state) => {
      const product = selectedProductsWithConfigurations.find(
        p => p.id === productOnDraft.id,
      )
      if (!product?.history) return
      dispatch(
        aiCreativeEditorActions.setHistoryByKey({
          key,
          value: state,
        }),
      )
    },
    [dispatch, productOnDraft],
  )

  /**
   * The history array of the currently previewed product.
   * This history is used for undo/redo functionality, storing previous states of the product configuration.
   * Returns an empty array if no product is being previewed or if the product has no history.
   */
  const history = useMemo(() => {
    if (!productOnDraft) return []
    return (
      selectedProductsWithConfigurations.find(p => p.id === productOnDraft.id)
        ?.history || []
    )
  }, [selectedProductsWithConfigurations, productOnDraft])

  /**
   * The current history step of the previewed product.
   * This step indicates the current position in the undo/redo history.
   * Used in conjunction with the history array to determine which state to display.
   * Returns 0 if no product is being previewed or if the product has no history step set.
   */
  const historyStep = useMemo(() => {
    if (!productOnDraft) return 0
    return (
      selectedProductsWithConfigurations.find(p => p.id === productOnDraft.id)
        ?.historyStep || 0
    )
  }, [selectedProductsWithConfigurations, productOnDraft])

  /**
   * Reduces the history step by 1 to undo the last change.
   */
  const handleUndo = useCallback(() => {
    if (historyStep === 0) return

    dispatch(aiCreativeEditorActions.setHistoryStep(historyStep - 1))
  }, [dispatch, historyStep])

  /**
   * Increases the history step by 1 to redo the last change.
   */
  const handleRedo = useCallback(() => {
    if (historyStep === history.length - 1) return

    dispatch(aiCreativeEditorActions.setHistoryStep(historyStep + 1))
  }, [dispatch, historyStep, history])

  /**
   * Sets the isComparingWithOriginal state.
   */
  const handleCompareWithOriginal = value => {
    dispatch(aiCreativeEditorActions.setIsComparingWithOriginal(value))
  }

  /**
   * Sets the current saving product id for batch saving.
   */
  const setCurrentSavingProductId = useCallback(
    productId => {
      dispatch(aiCreativeEditorActions.setCurrentSavingProductId(productId))
    },
    [dispatch],
  )

  /**
   * Sets the saving queue for batch saving.
   */
  const setSavingQueue = useCallback(
    queue => {
      dispatch(aiCreativeEditorActions.setSavingQueue(queue))
    },
    [dispatch],
  )

  /**
   * Handles the completion of saving a product for batch saving.
   */
  const handleProductSaveComplete = useCallback(
    productId => {
      const currentQueueIndex = savingQueue.indexOf(productId)
      const nextProductId = savingQueue[currentQueueIndex + 1]

      if (!nextProductId) {
        setSavingQueue([])
        setCurrentSavingProductId(null)
        dispatch(aiCreativeEditorActions.setIsBatchSaving(false))
        return
      }

      setCurrentSavingProductId(nextProductId)
    },
    [savingQueue, dispatch],
  )

  /**
   * Fetches the catalog products for the given catalog from Facebook and stores them in the database.
   */
  const startFetchingCatalogProducts = useCallback(
    async ({ catalogId, productSetId, primaryFeedId }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/startFetchingCatalogProducts?catalogId=${catalogId}&productSetId=${productSetId}&primaryFeedId=${primaryFeedId}&accessToken=${facebook.auth.accessToken}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          },
        )

        const result = await response.json()

        return result
      } catch (error) {
        errorHandler(error)
        dispatch(aiCreativeEditorActions.setError(error.message))
      }
    },
    [dispatch, authToken, facebook],
  )

  /**
   * Checks the fetching progress of the catalog products for the given catalog.
   */
  const getCatalogProductsProgress = useCallback(
    async ({ catalogId, productSetId, primaryFeedId }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/getCatalogProductsProgress?catalogId=${catalogId}&productSetId=${productSetId}&primaryFeedId=${primaryFeedId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          },
        )

        const result = await response.json()

        return result
      } catch (error) {
        errorHandler(error)
        dispatch(aiCreativeEditorActions.setError(error.message))
      }
    },
    [dispatch, authToken],
  )

  /**
   * Fetches the catalog products for the given catalog from the database.
   */
  const getCatalogProductsFromDB = useCallback(
    async ({ catalogId, productSetId, primaryFeedId, after }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/getCatalogProducts?catalogId=${catalogId}&productSetId=${productSetId}&primaryFeedId=${primaryFeedId}${after ? `&after=${after}` : ''}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          },
        )
        const data = await response.json()

        if (!response.ok) {
          throw new Error(
            data.message || 'Could not fetch products from database',
          )
        }

        return data
      } catch (error) {
        throw error
      }
    },
    [authToken],
  )

  /**
   * Gets products from Facebook's API with the given parameters.
   */
  const getCatalogProductsFromFacebook = useCallback(
    async ({ edgeName, edgeId, filter, after, productSetId }) => {
      try {
        if (!facebook.auth?.accessToken) {
          throw new Error('Facebook access token not found')
        }

        const config = {
          edgeName,
          edgeId,
          fields:
            'image_url,name,price,url,retailer_id,retailer_product_group_id,description,gtin,currency,product_sets',
          accessToken: facebook.auth.accessToken,
        }

        if (filter) config.filter = filter
        if (after) config.after = after

        const { products, paging } = await getProductsOfEdge(config)

        if (!products) {
          throw new Error('No products returned from Facebook')
        }

        const filteredProducts = products.filter(product => {
          return product.product_sets.data.some(set => set.id === productSetId)
        })

        return {
          products: filteredProducts,
          paging,
        }
      } catch (error) {
        throw error
      }
    },
    [facebook.auth?.accessToken, getProductsOfEdge],
  )

  /**
   * Creates a bulk AI creative for the given catalog.
   */
  const createBulkAICreative = useCallback(
    async ({
      catalogId,
      productSetId,
      primaryFeedId,
      supplementaryFeedId,
      svgTemplate,
      configuration,
    }) => {
      try {
        const result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/createBulkAICreative`,
          'POST',
          JSON.stringify({
            catalogId,
            productSetId,
            primaryFeedId,
            supplementaryFeedId,
            svgTemplate,
            configuration: JSON.stringify(configuration),
            accessToken: facebook.auth.accessToken,
          }),
          {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        )

        dispatch(
          aiCreativesActions.addNewCreative({
            _id: result.data._id,
            id: result.data._id,
            enhencedCatalogDetails: result.data.enhencedCatalogDetails,
          }),
        )

        dispatch(
          authActions.updateUserObjOneField({
            field: 'aiCreatives',
            value: [result.data._id, ...userAICreativesIdList],
          }),
        )

        return result.data
      } catch (error) {
        errorHandler(error)
        dispatch(aiCreativeEditorActions.setError(error.message))
      }
    },
    [sendRequest, dispatch, authToken, facebook],
  )

  /**
   * Adds or removes a product from the ignored products list.
   */
  const toggleProductIgnoreStatus = useCallback(
    async ({ aiCreativeId, productId }) => {
      try {
        const result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/toggleProductIgnoreStatus`,
          'PATCH',
          JSON.stringify({
            aiCreativeId,
            productId,
          }),
          {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        )

        return result
      } catch (error) {
        dispatch(aiCreativeEditorActions.setError(error.message))
        throw error
      }
    },
    [sendRequest, authToken],
  )

  /**
   * Creates a supplementary feed for the given catalog.
   */
  const createSupplementaryFeed = useCallback(
    async ({ catalogId, primaryFeedId, creativeId }) => {
      try {
        const result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/createSupplementaryFeed`,
          'POST',
          JSON.stringify({
            accessToken: facebook.auth.accessToken,
            catalogId,
            primaryFeedId,
            creativeId,
          }),
          {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        )

        const updatedList = fullList.map(creative => {
          if (creative._id === result.data._id) {
            return {
              ...result.data,
            }
          }

          return creative
        })

        dispatch(aiCreativesActions.setFullList(updatedList))

        return result.data.enhencedCatalogDetails.supplementaryFeedId
      } catch (error) {
        dispatch(aiCreativeEditorActions.setError(error.message))
        throw error
      }
    },
    [sendRequest, authToken, facebook],
  )

  /**
   * Removes a supplementary feed for the given creative.
   */
  const removeSupplementaryFeed = useCallback(
    async ({ creativeId, supplementaryFeedId }) => {
      try {
        const result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/removeSupplementaryFeed`,
          'PATCH',
          JSON.stringify({
            creativeId,
            supplementaryFeedId,
            accessToken: facebook.auth.accessToken,
          }),
          {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        )

        const updatedList = fullList.map(creative => {
          if (creative._id === result.data._id) {
            return {
              ...result.data,
            }
          }

          return creative
        })

        dispatch(aiCreativesActions.setFullList(updatedList))

        return result.data
      } catch (error) {
        errorHandler(error)
        dispatch(aiCreativeEditorActions.setError(error.message))
      }
    },
    [sendRequest, authToken, facebook],
  )

  /**
   * Gets the upload status of the given feed.
   */
  const getFeedUploadStatus = useCallback(async ({ feedId }) => {
    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_FACEBOOK_GRAPH_URL}${feedId}/uploads?access_token=${facebook.auth.accessToken}`,
        'GET',
        null,
        {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      )

      if (result.data[0].end_time) {
        return {
          isUploaded: true,
          uploadTime: result.data[0].end_time,
        }
      }

      return {
        isUploaded: false,
        uploadTime: null,
      }
    } catch (error) {
      errorHandler(error)
      dispatch(aiCreativeEditorActions.setError(error.message))
    }
  }, [])

  /**
   * Checks the done status of the bulk creative generation.
   */
  const getBulkCreativeProgress = useCallback(
    async ({
      catalogId,
      productSetId,
      primaryFeedId,
      currentTotalAICreatives,
    }) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/ai-creative/getBulkCreativeProgress?catalogId=${catalogId}&productSetId=${productSetId}&primaryFeedId=${primaryFeedId}&currentTotalAICreatives=${currentTotalAICreatives}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          },
        )

        const result = await response.json()

        return result
      } catch (error) {
        errorHandler(error)
        dispatch(aiCreativeEditorActions.setError(error.message))
      }
    },
    [dispatch, authToken, facebook],
  )

  /**
   * Sets the areCatalogProductsFetched state.
   */
  const setAreCatalogProductsFetched = useCallback(
    value => {
      dispatch(aiCreativeEditorActions.setAreCatalogProductsFetched(value))
    },
    [dispatch],
  )

  /**
   * Sets the total products state.
   */
  const setTotalProducts = useCallback(
    value => {
      dispatch(aiCreativeEditorActions.setTotalProducts(value))
    },
    [dispatch],
  )

  return {
    result,
    error,
    activeMainDisplay,
    previewHelperText,
    companyDetails,
    discount,
    isStageLoading,
    selectedProductsWithConfigurations,
    draftConfiguration,
    productOnDraft,
    backgroundRemoving,
    savedConfigurationOfProductOnDraft,
    savedPreviewOfProductOnDraft,
    isSingleSaving,
    isBatchSaving,
    isEnhencedCatalogSaving,
    history,
    historyStep,
    isComparingWithOriginal,
    isCurrentCreativeCardHidden,
    savingQueue,
    currentSavingProductId,
    areCatalogProductsFetched,
    totalProducts,

    setIsStageLoading,
    setIsSingleSaving,
    setIsBatchSaving,
    setIsEnhencedCatalogSaving,
    loadCachedSelectedProducts,
    removeBackgroundImages,
    getSavedConfigurationOfProduct,
    getSavedPreviewOfProduct,
    setInitialConfiguration,
    applyConfigurationToProduct,
    applyConfigurationToAllProducts,
    disableTransformers,
    savePreviewToProduct,
    resetEnhencedCatalog,
    applyDiscount,
    updateDiscount,
    updateCompanyDetails,
    updateCustomTexts,
    getSavedDiscountCodes,
    initHistory,
    addToHistory,
    updateHistoryObject,
    handleUndo,
    handleRedo,
    handleCompareWithOriginal,
    setCurrentSavingProductId,
    setSavingQueue,
    handleProductSaveComplete,
    setProductOnDraft,
    setAreCatalogProductsFetched,
    setTotalProducts,
    createBulkAICreative,
    toggleProductIgnoreStatus,
    createSupplementaryFeed,
    removeSupplementaryFeed,
    getBulkCreativeProgress,
    getFeedUploadStatus,
    startFetchingCatalogProducts,
    getCatalogProductsProgress,
    getCatalogProductsFromDB,
    getCatalogProductsFromFacebook,
  }
}
