import React from 'react'
import { useSelector } from 'react-redux'
import './SubscriptionStatusSlider.css'
import {
  FaCheckCircle,
  FaExclamationTriangle,
  FaChevronUp,
} from 'react-icons/fa'
import numberFormatter from '../../../shared/util/numberFormatter'
import { useUser } from '../../../shared/hooks/user-hook'
import { useConstants } from '../../../shared/hooks/constants-hook'
import Button from '../../../shared/components/FormElements/Button'
import { useNavigate } from 'react-router-dom'

const SubscriptionStatusSlider = () => {
  const { pricingConstants } = useConstants()
  const navigate = useNavigate()

  const isFromShopify = useSelector(
    state => state.auth.user?.shopify !== undefined,
  )

  let currencyRate = pricingConstants?.usdTlCurrencyRate
  const currency = useSelector(state => state.auth.user.facebookAds?.currency)
  const currencySymbol = currency === 'TRY' ? '₺' : '$'

  const isSubscriptionActive = useSelector(
    state => state.auth.user?.subscription?.status === 'active',
  )
  const plan = useSelector(state => state.auth.user?.subscription?.plan)
  const interval = useSelector(state => state.auth.user?.subscription?.interval)
  const paymentInterval = useSelector(
    state => state.auth.user?.subscription?.paymentInterval,
  )
  const monthlyAdSpent = useSelector(
    state => state.auth.user?.facebookAds?.monthlyAdSpent,
  )

  const { getPackageFeatures } = useUser()
  const currentPackage = getPackageFeatures()
  let maxAdSpent = parseInt(currentPackage?.maxAdSpent?.replace(/,/g, ''))
  let minAdSpent = parseInt(currentPackage?.minAdSpent?.replace(/,/g, ''))

  if (currency === 'TRY') {
    maxAdSpent = maxAdSpent * currencyRate
    minAdSpent = minAdSpent * currencyRate
  }


  const upgradePlan = () => {
    navigate('/account/subscription', {})
  }

  return (
    <div className="subscription-status-slider">
      <h2>Subscription Status</h2>
      <div className="content">
        <div className="status-card">
          {isSubscriptionActive ? (
            <div className="subscription-status-card">
              <div className="subscription-header">
                <div className="row">
                  <div className="subscription-header-text">
                    {plan} -{' '}
                    {(interval || paymentInterval || 'Monthly').toLowerCase()}{' '}
                  </div>
                  <FaCheckCircle className="check-icon" />
                </div>
                {monthlyAdSpent > 0 && (
                  <div className="secondary-text">Monthly Ad Spent</div>
                )}
              </div>

              {(monthlyAdSpent > 0) && !isNaN(maxAdSpent) && maxAdSpent ? (
                <div className="slider-container">
                  <div className="slider-track">
                    <span className="slider-value start">
                      {currencySymbol}
                      {(minAdSpent / 1000).toFixed(0)}K
                    </span>
                    {monthlyAdSpent >= minAdSpent && (
                      <span
                        className="slider-value current"
                        style={{
                          left: `${(monthlyAdSpent / maxAdSpent) * 100}%`,
                        }}
                      >
                        {currencySymbol}
                        {numberFormatter.formatNumber(monthlyAdSpent, 0)}
                      </span>
                    )}
                    <span className="slider-value end">
                      {currencySymbol}
                      {(maxAdSpent / 1000).toFixed(0)}K
                    </span>
                  </div>
                  <div className="slider-line">
                    <div
                      className="slider-progress"
                      style={{
                        width: `${(monthlyAdSpent / maxAdSpent) * 100}%`,
                        maxWidth: '100%',
                      }}
                    />
                    <div
                      className={
                        monthlyAdSpent >= minAdSpent
                          ? 'slider-point active'
                          : 'slider-point'
                      }
                      style={{ left: '0%' }}
                    ></div>
                    <div
                      className={
                        monthlyAdSpent >= minAdSpent
                          ? 'slider-point active'
                          : 'slider-point'
                      }
                      style={{
                        left: `${(monthlyAdSpent / maxAdSpent) * 100}%`,
                      }}
                    ></div>
                    <div
                      className={
                        monthlyAdSpent >= maxAdSpent
                          ? 'slider-point active'
                          : 'slider-point'
                      }
                      style={{ left: '100%' }}
                    ></div>
                  </div>
                </div>
              ) : null}
              {(monthlyAdSpent > 0) && !isNaN(minAdSpent) && (monthlyAdSpent < minAdSpent) && (
                <div className="warning-box">
                  <p>
                    <FaExclamationTriangle className="warning-icon" /> Your
                    monthly Ad Spent is {currencySymbol}
                    {numberFormatter.formatNumber(monthlyAdSpent, 0)}. It is
                    below the minimum limit for your plan.
                  </p>
                </div>
              )}

              {(monthlyAdSpent > 0) && !isNaN(maxAdSpent) && (monthlyAdSpent > maxAdSpent) && (
                <div className="warning-box">
                  <p>
                    <FaExclamationTriangle className="warning-icon" />{' '}
                    {isFromShopify
                      ? `Your monthly Ad Spent is ${currencySymbol}${numberFormatter.formatNumber(monthlyAdSpent, 0)}. It is above the maximum limit for your plan. Please upgrade your plan.`
                      : `Your monthly Ad Spent is ${currencySymbol}${numberFormatter.formatNumber(monthlyAdSpent, 0)}. It is above the maximum limit for your plan. Please contaxt us to upgrade your plan.`}
                  </p>

                  {isFromShopify && (
                    <Button
                      className="upgrade-plan"
                      onClick={() => upgradePlan()}
                    >
                      Upgrade Plan <FaChevronUp />{' '}
                    </Button>
                  )}
                </div>
              )}

              {(monthlyAdSpent > 0) && !maxAdSpent && (
                <p>
                  Your monthly Ad Spent: {currencySymbol}
                  {numberFormatter.formatNumber(monthlyAdSpent, 0)}
                </p>
              )}

              {!monthlyAdSpent && !maxAdSpent && (
                <p>Your monthly Ad Spent: {currencySymbol}0</p>
              )}
            </div>
          ) : (
            <p className="status-text">No Subscription Found</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionStatusSlider
