import React, { useMemo, useState, useEffect } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import items from '../../../shared/data/recommended-shopify-apps.json'
import shopifyLogo from '../../../shared/assets/images/icons/shopify-bag.png'

import './RecommendedShopifyAppsSlider.css'

const RecommendedShopifyAppsSlider = () => {
  const SLIDE_INTERVAL = 5000; 

  const [currentItemInfo, setCurrentItemInfo] = useState({
    idx: 0,
    isOpen: false,
  })

  const currentItem = useMemo(
    () => items[currentItemInfo.idx],
    [currentItemInfo.idx],
  )

  useEffect(() => {
    let intervalId;

    const startInterval = () => {
      intervalId = setInterval(() => {
        setCurrentItemInfo(prev => ({
          idx: prev.idx === items.length - 1 ? 0 : prev.idx + 1,
          isOpen: false,
        }));
      }, SLIDE_INTERVAL);
    };

    const stopInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    const sliderElement = document.querySelector('.recommended-shopify-apps-slider');
    
    if (sliderElement) {
      sliderElement.addEventListener('mouseenter', stopInterval);
      sliderElement.addEventListener('mouseleave', startInterval);
    }

    startInterval();

    return () => {
      stopInterval();
      if (sliderElement) {
        sliderElement.removeEventListener('mouseenter', stopInterval);
        sliderElement.removeEventListener('mouseleave', startInterval);
      }
    };
  }, [items.length]);

  const handlePrev = () => {
    if (currentItemInfo.idx === 0) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx - 1,
      isOpen: false,
    }))
  }

  const handleNext = () => {
    if (currentItemInfo.idx === items.length - 1) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx + 1,
      isOpen: false,
    }))
  }

  return (
    <div className="recommended-shopify-apps-slider">
      <h2>Recommended Apps</h2>

      {items.length > 1 && (
        <React.Fragment>
          <div
            onClick={handlePrev}
            className={`controller prev${
              currentItemInfo.idx === 0 ? ' disabled' : ''
            }`}
          >
            <FaAngleLeft className="icon" />
          </div>
          <div
            onClick={handleNext}
            className={`controller next${
              currentItemInfo.idx === items.length - 1 ? ' disabled' : ''
            }`}
          >
            <FaAngleRight className="icon" />
          </div>
        </React.Fragment>
      )}

      <div className="content">
        <div className="company">
          <div className="main">
            <img className="logo" src={currentItem.logo} alt="App Logo" />
            <div className="info">
              <h3>{currentItem.company}</h3>
              <p className="description">{currentItem.description}</p>
            </div>
          </div>
          <div className='slider-bottom-section'>
            <div className="slider-indicators">
              {items.map((_, index) => (
                <div
                  key={index}
                  className={`slider-indicator ${
                    index === currentItemInfo.idx ? 'active' : ''
                  }`}
                  onClick={() =>
                    setCurrentItemInfo(prev => ({
                      ...prev,
                      idx: index,
                    }))
                  }
                />
              ))}
            </div>
            <div>
              <a
                href={currentItem.url}
                target="_blank"
                className="link"
                rel="noreferrer"
              >
                Try it
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecommendedShopifyAppsSlider