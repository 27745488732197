import React, { useRef, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import CustomDatePicker from '../UIElements/CustomDatePicker'
import { FaTimes } from 'react-icons/fa'

const UpdateMeetingDate = props => {
  const { closeModal, modalData } = useModal()

  let [newMeetingDate, setNewMeetingDate] = useState(props.data.demoMeeting.meetingStarts ? new Date(props.data.demoMeeting.meetingStarts) : new Date())
  const [timeHour, setTimeHour] = useState(props.data.demoMeeting.meetingStarts ? new Date(props.data.demoMeeting.meetingStarts).getHours() : 0);
  const [timeMinute, setTimeMinute] = useState(props.data.demoMeeting.meetingStarts ? new Date(props.data.demoMeeting.meetingStarts).getMinutes() : 0);


  const create = () => {
    modalData.callback({ newMeetingDate })
    closeModal()
  }

  const close = () => {
    closeModal()
  }

  // Generating hours from 00:00 to 23:45
  const hours = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      const hour = (i < 10 ? '0' : '') + i + ':' + (j < 10 ? '0' : '') + j;
      hours.push(hour);
    }
  }

  function convertToHourFormat(hourInteger) {
    const hour = (hourInteger < 10 ? '0' : '') + hourInteger;
    return hour;
  }

  // Function to handle change in end hour
  const handleTimeHourChange = (e) => {
    const [hourStr, minuteStr] = e.target.value.split(":");
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);

    setTimeHour(hour);
    setTimeMinute(minute);
    let oldDueDate = new Date(newMeetingDate.getFullYear(), newMeetingDate.getMonth(), newMeetingDate.getDate(), 0, 0, 0); // Reset hours and minutes
    const newDueDate = new Date(oldDueDate.getTime() + (hour * 60 + minute) * 60 * 1000);
    setNewMeetingDate(newDueDate);
  };


  return (
    <div className="alarm-modal update-meeting-date-modal">
      <div className="modal-title">
        <div className='alarm-name'>Update Meeting</div>
        <div>-</div>
        <div className='customer-name'>{props.data.demoMeeting.name}</div>
        <Button size="small" onClick={close}><FaTimes /></Button>
      </div>
      <div className="modal-middle">
        <div className='property row'>
          <div className='property-desc'>Date:</div>
          <div className='due-date-selector form-control'>
            <div>
              <CustomDatePicker
                placeholder="DD/MM/YYYY"
                date={newMeetingDate ?? new Date()}
                onChange={d => {
                  const dateAtMidnight = new Date(d.getFullYear(), d.getMonth(), d.getDate())
                  const newDueDate = new Date(dateAtMidnight.getTime() + (timeHour * 60 + timeMinute) * 60 * 1000);
                  setNewMeetingDate(newDueDate)
                }}
              />
            </div>
            <div>-</div>
            <div>
              <select className='selectable' id="endHour"
                value={`${convertToHourFormat(timeHour)}:${convertToHourFormat(timeMinute)}`}
                onChange={handleTimeHourChange}>
                {hours.map((hour, index) => (
                  <option key={index} value={hour}>{hour}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <Button size="small" className="action-button update" onClick={() => {
          create()
        }}>
          Update
        </Button>
      </div>
    </div>
  )
}


export default UpdateMeetingDate
