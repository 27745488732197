import React, { useEffect, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import CustomDatePicker from '../UIElements/CustomDatePicker'
import { FaLink, FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import PeopleSelector from '../UIElements/PeopleSelector'
import { Link } from 'react-router-dom'
import Modals from '../../enums/Modals'
import StrokeInput from '../FormElements/StrokeInput'

const NewAlarm = props => {
  const { closeModal, openModal } = useModal()

  const allPredefinedAlarms = {
    b2: {
      title: 'Demo Meeting',
      nextAlarm: 'b3',
      nextAlarmTitle: 'Follow-up for Subscription',
    },
    sc2: {
      title: 'Demo Meeting',
      nextAlarm: 'sc3',
      nextAlarmTitle: 'Follow-up for Subscription',
    },
    c2: {
      title: 'Onboarding Meeting',
      nextAlarm: 'c3',
      nextAlarmTitle: 'Follow-up Meeting',
    },
    b3: {
      title: 'Follow-up for Subscription',
    },
    sc3: {
      title: 'Follow-up for Subscription',
    },
    c3: {
      title: 'Follow-up Meeting',
    },
    a1: {
      title: 'Call',
    },
    a2: {
      title: 'Send e-mail',
    },
  }

  const selectSublist = function () {
    let result = {}
    switch (props.data.customer.type) {
      case 'Demo':
        result.sc2 = allPredefinedAlarms.b2
        result.sc3 = allPredefinedAlarms.b3
        result.a1 = allPredefinedAlarms.a1
        result.a2 = allPredefinedAlarms.a2
        break
      case 'Sign Up':
        result.sc2 = allPredefinedAlarms.sc2
        result.sc3 = allPredefinedAlarms.sc3
        result.a1 = allPredefinedAlarms.a1
        result.a2 = allPredefinedAlarms.a2
        break
      case 'Customer':
        result.c2 = allPredefinedAlarms.c2
        result.c3 = allPredefinedAlarms.c3
        result.a1 = allPredefinedAlarms.a1
        result.a2 = allPredefinedAlarms.a2
        break
      default:
        break
    }
    return result
  }
  let predefinedAlarms = selectSublist()

  let now = new Date()
  let [dueDate, setDueDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59),
  )
  const currentUserId = useSelector(state => state.auth.user.id)
  const [timeHour, setTimeHour] = useState(23)
  const [timeMinute, setTimeMinute] = useState(59)
  const [titleValue, setTitleValue] = useState('')
  const [noteValue, setNoteValue] = useState('')
  let [isTimeActive, setIsTimeActive] = useState(false)
  const [selectedPredefinedAlarm, setSelectedPredefinedAlarm] = useState(null)
  const [showMeetingLinkInput, setShowMeetingLinkInput] = useState(false)
  const [meetingLink, setMeetingLink] = useState('')
  const { managerUsers } = useSelector(state => state.admin)
  const [attachedUserIds, setAttachedUserIds] = useState([])

  useEffect(() => {
    setAttachedUserIds([currentUserId])
  }, [currentUserId])

  const create = () => {
    props.data.callback({
      dueDate,
      title: titleValue,
      note: noteValue,
      selectedPredefinedAlarm,
      meetingLink,
      attachedUserIds,
    })
    closeModal()
  }

  const close = () => {
    closeModal()
  }

  // Generating hours from 00:00 to 23:45
  const hours = []
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      const hour = (i < 10 ? '0' : '') + i + ':' + (j < 10 ? '0' : '') + j
      hours.push(hour)
    }
  }

  function convertToHourFormat(hourInteger) {
    const hour = (hourInteger < 10 ? '0' : '') + hourInteger
    return hour
  }

  const handleTitleChange = (event) => {
    const newValue = typeof event === 'string' ? event : event?.target?.value || '';
    setTitleValue(newValue);
  };

  const handleNoteChange = (event) => {
    const newValue = typeof event === 'string' ? event : event?.target?.value || '';
    setNoteValue(newValue);
  };

  // Function to handle change in end hour
  const handleTimeHourChange = e => {
    const [hourStr, minuteStr] = e.target.value.split(':')
    const hour = parseInt(hourStr)
    const minute = parseInt(minuteStr)

    setTimeHour(hour)
    setTimeMinute(minute)
    let oldDueDate = new Date(
      dueDate.getFullYear(),
      dueDate.getMonth(),
      dueDate.getDate(),
      0,
      0,
      0,
    ) // Reset hours and minutes
    const newDueDate = new Date(
      oldDueDate.getTime() + (hour * 60 + minute) * 60 * 1000,
    )
    setDueDate(newDueDate)
  }

  const handlePredefinedAlarmSelect = alarm => {
    setSelectedPredefinedAlarm(alarm)
    setTitleValue(alarm.title)
  }

  const clearPredefinedAlarm = () => {
    setSelectedPredefinedAlarm(null)
    setTitleValue('')
    setDueDate(new Date())
    setTimeHour(23)
    setTimeMinute(59)
    setIsTimeActive(false)
  }

  useEffect(() => {
    if (timeHour === 23 && timeMinute === 59) {
      setIsTimeActive(false)
    } else {
      setIsTimeActive(true)
    }
  }, [timeHour, timeMinute])

  const handleMeetingLinkChange = (event) => {
    const newValue = typeof event === 'string' ? event : event?.target?.value || '';
    setMeetingLink(newValue);
  };

  const toggleMeetingLinkInput = () => {
    setShowMeetingLinkInput(!showMeetingLinkInput)
  }

  return (
    <div className="alarm-modal create-alarm-modal">
      <div className="modal-title">
        <div className="alarm-name">Set new alarm</div>
        <div>-</div>
        <div className="customer-name">{props.data.customer.name}</div>
        <Button size="small" onClick={close}>
          <FaTimes />
        </Button>
      </div>
      <div className="modal-middle">
        <div className="property row">
          <div className="property-desc">Date:</div>
          <div className="due-date-selector form-control">
            <div>
              <CustomDatePicker
                placeholder="DD/MM/YYYY"
                date={dueDate ?? new Date()}
                onChange={d => {
                  const dateAtMidnight = new Date(
                    d.getFullYear(),
                    d.getMonth(),
                    d.getDate(),
                  )
                  const newDueDate = new Date(
                    dateAtMidnight.getTime() +
                      (timeHour * 60 + timeMinute) * 60 * 1000,
                  )
                  setDueDate(newDueDate)
                }}
                disabled={selectedPredefinedAlarm !== null}
              />
            </div>
            <div>-</div>
            <div>
              {isTimeActive ? (
                <select
                  className="selectable"
                  id="endHour"
                  value={`${convertToHourFormat(timeHour)}:${convertToHourFormat(timeMinute)}`}
                  onChange={handleTimeHourChange}
                >
                  {hours.map((hour, index) => (
                    <option key={index} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>
              ) : (
                <Button
                  onClick={() => {
                    setTimeHour(0)
                    setTimeMinute(0)
                    setIsTimeActive(true)
                  }}
                >
                  Add time
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="form-control">
          <StrokeInput
            id="title"
            element="input"
            type="text"
            label="Title"
            value={titleValue}
            onChange={handleTitleChange}
            disabled={selectedPredefinedAlarm !== null}
            required
            errorLine={false}
          />
          {selectedPredefinedAlarm && (
            <Button
              className="remove-predefined-button"
              size="small"
              onClick={clearPredefinedAlarm}
            >
              <FaTimes />
            </Button>
          )}
           <div
          className="property row"
          style={{ fontSize: '0.8em', marginLeft: '6px' }}
        >
          {selectedPredefinedAlarm ? (
            selectedPredefinedAlarm.nextAlarmTitle ? (
              <div className="alarm-due-date">
                {'When completed: Create "' +
                  selectedPredefinedAlarm.nextAlarmTitle +
                  '" alarm'}
              </div>
            ) : null
          ) : (
            <Link
              onClick={() =>
                openModal({
                  type: Modals.PREDEFINED_ALARMS,
                  alarms: predefinedAlarms,
                  onSelect: handlePredefinedAlarmSelect,
                })
              }
            >
              Select from pre-defined alarms
            </Link>
          )}
        </div>
        </div>

       

        <StrokeInput
          id="note"
          element="textarea"
          label="Note"
          value={noteValue}
          onChange={handleNoteChange}
          rows={3}
          errorLine={false}
        />

        <PeopleSelector
          attachedUserIds={attachedUserIds}
          managerUsers={managerUsers}
          onChange={setAttachedUserIds}
        />

        {showMeetingLinkInput ? (
          <StrokeInput
            id="meetingLink"
            element="input"
            type="text"
            label="Meeting Link"
            value={meetingLink}
            onChange={handleMeetingLinkChange}
            placeholder="Paste your meeting link here"
            errorLine={false}
          />
        ) : (
          <Button
            onClick={toggleMeetingLinkInput}
            className="attach-meeting-link-button"
          >
            <FaLink /> Attach a meeting link
          </Button>
        )}
      </div>
      <div className="modal-footer">
        <Button size="small" className="action-button yes" onClick={create}>
          {props.data.confirmButtonText ?? 'Create'}
        </Button>
      </div>
    </div>
  )
}

export default NewAlarm
