import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { authActions } from '../../store/auth';
import { useHttpClient } from './http-hook';
import { adminActions } from '../../store/admin';
import { facebookActions } from '../../store/facebook';
import { projectActions } from '../../store/project';

import { toast } from 'react-toastify';
import helper from '../util/helper';
import errorHandler from '../util/errorHandler';


export const useAdmin = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { sendRequest } = useHttpClient()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const authToken = useSelector(state => state.auth.token)
  const auth = useSelector(state => state.auth)
  const admin = useSelector(state => state.admin)

  const {
    lastCursorCustomers,
    customers,
    lastCursorDemoMeetings,
    demoMeetings,
    lastCursorSignUps,
    signUps,
    lastCursorTickets,
    tickets,
    teamInfo,
    hasUnreadMessages,
    userInView,
    managerUsers,
    agencies,
  } = admin

  const convertManagerIdToName = id => {
    const foundOne = managerUsers[id]
    return { found: foundOne?.name ?? id, isConverted: !!foundOne }
  }
  const convertManagerNameToId = name => {
    const foundOne = helper.findBy(Object.values(managerUsers), 'name', name)
    return { found: foundOne?._id ?? name, isConverted: !!foundOne }
  }

  const convertAgencyIdToName = id => {
    const foundOne = agencies ? agencies[id] : null
    return { found: foundOne?.name ?? id, isConverted: !!foundOne }
  }

  const convertAgencyNameToId = name => {
    const foundOne = helper.findBy(Object.values(agencies), 'name', name)
    return { found: foundOne?._id ?? name, isConverted: !!foundOne }
  }

  const getManagerUsers = async () => {
    try {
      const data = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getManagerUsers`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )

      const managersArray = Object.values(data.managers)

      const performanceManagers = managersArray
        .filter(manager => manager.accessDetails.role === 'Performance Manager')
        .reduce((acc, manager) => {
          acc[manager._id] = manager
          return acc
        }, {})

      const salesPeople = managersArray
        .filter(manager => manager.accessDetails.role === 'Sales Person')
        .reduce((acc, manager) => {
          acc[manager._id] = manager
          return acc
        }, {})

      const accountManagers = managersArray
        .filter(manager => manager.accessDetails.role === 'Account Manager')
        .reduce((acc, manager) => {
          acc[manager._id] = manager
          return acc
        }, {})
      dispatch(adminActions.setManagerUsers(data.managers))
      dispatch(adminActions.setPerformanceManagers(performanceManagers))
      dispatch(adminActions.setAccountManagers(accountManagers))
      dispatch(adminActions.setSalesPeople(salesPeople))
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const getTeamInfo = async () => {
    if (!helper.isEmptyObject(teamInfo)) {
      return
    }
    try {
      const data = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getTeamInfo`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )
      dispatch(adminActions.setTeamInfo(data))

      await getManagerUsers()
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const getPerformanceManagerProfileForUser = async ({
    performanceManager,
    authToken,
  }) => {
    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/getPerformanceManagerProfile/${performanceManager}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )

      return result.profile
    } catch (err) {
      throw err
    }
  }

  const startViewingAsUser = ({ user, pageRoute = "/ai-ads/full-funnel-campaigns", passingState, openInNewTab }) => {

    let domain = user.shopify ? user.shopify.name || user.shopify.domain : user.url
    const tempCompanyName = domain ? domain : user.name

    // dispatch(adminActions.setUserInView(user))

    dispatch(adminActions.setNavigatedFromCustomerID(user.id))
    dispatch(adminActions.setCustomer(user))
    if (passingState) {
      if (openInNewTab) {
        window.open(
          `${pageRoute}?user_in_view_id=${user.id}&user_in_view_name=${tempCompanyName}&admin=1`,
        )
        console.log('testttitigigigi')
      } else {
        navigate(
          `${pageRoute}?user_in_view_id=${user.id}&user_in_view_name=${tempCompanyName}&admin=1`,
          { state: passingState },
        )
      }
    } else {
      if (openInNewTab) {
        window.open(
          `${pageRoute}?user_in_view_id=${user.id}&user_in_view_name=${tempCompanyName}&admin=1`,
        )
      } else {
        navigate(
          `${pageRoute}?user_in_view_id=${user.id}&user_in_view_name=${tempCompanyName}&admin=1`,
        )
      }
    }
  }

  const loginToUser = async ({ user }) => {
    try {

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/loginAsUser`,
        'POST',
        JSON.stringify({ email: user.email }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        }
      )

      const token = responseData.token
      const projectId = responseData.projectId
      const updatedUser = helper.getUIReadyUserObject({ user: responseData.user })

      const viewingAsUserData = {
        userMail: user.email,
        adminToken: auth.token,
        projectId,
        userToken: token,
        userRole: auth.user.accessDetails.role,
        userId: user.id
      }

      sessionStorage.setItem('viewingAsUser', JSON.stringify(viewingAsUserData))

      dispatch(authActions.loginAsAdmin({ token, projectId, user: updatedUser, adminToken: auth.token }))


      navigate('/')

    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const endViewingAsUser = () => {
    dispatch(adminActions.setUserInView(null))
    dispatch(facebookActions.clearInfo())
  }

  const filterEmptyValues = filter => {
    const filteredFilter = {}

    for (const key in filter) {
      if (Array.isArray(filter[key]) && filter[key].length > 0) {
        filteredFilter[key] = filter[key]
      } else if (typeof filter[key] === 'object' && filter[key] !== null) {
        const nestedFilter = filterEmptyValues(filter[key])
        if (Object.keys(nestedFilter).length > 0) {
          filteredFilter[key] = nestedFilter
        }
      } else if (filter[key]) {
        filteredFilter[key] = filter[key]
      }
    }

    //filter also if yes same value in array
    for (const key in filteredFilter) {
      if (Array.isArray(filteredFilter[key]) && filteredFilter[key].length > 0) {
        filteredFilter[key] = filteredFilter[key].filter((value, index, self) => self.indexOf(value) === index)
      }
    }

    return filteredFilter
  }

  const getOneDemo = async ({ userId }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/demo-meetings/${userId}`

    const responseData = await sendRequest(url, 'GET', null, {
      Authorization: 'Bearer ' + authToken,
    })
    const fetchedUsers = responseData.meetings
    return fetchedUsers[0]
  }
  const getOneSignUp = async ({ userId }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/admin/getSignUps/${userId}`

    const responseData = await sendRequest(url, 'GET', null, {
      Authorization: 'Bearer ' + authToken,
    })
    const fetchedUsers = responseData.users
    return fetchedUsers[0]
  }
  const getOneCustomer = async ({ userId }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL
      }/admin/getUsersForPerformancePanel/${userId}?list=${'Customers'}`

    const responseData = await sendRequest(url, 'GET', null, {
      Authorization: 'Bearer ' + authToken,
    })
    const fetchedUsers = responseData.users
    return fetchedUsers[0]
  }

  const getCustomers = async ({ freshFetch = false, filter, search }) => {
    if (freshFetch) {
      const wrapper = document.querySelector('.admin-panel-list-wrapper')
      if (wrapper !== undefined && wrapper !== null) {
        wrapper.scrollTo(0, 0)
      }
    }
    try {
      let newCustomers = freshFetch ? [] : [...customers]
      let url = `${process.env.REACT_APP_BACKEND_URL
        }/admin/getUsersForPerformancePanel/?list=${'Customers'}&search=${search}`
      const filteredFilter = filterEmptyValues(filter)

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `&filters=${filterParams}`
      }
      const lastCursorCreatedAt = lastCursorCustomers
      if (lastCursorCreatedAt && !freshFetch) {
        url += `&cursorCreatedAt=${lastCursorCreatedAt}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })
      const fetchedUsers = responseData.users
      const totalLength = responseData.totalUserCount
      const filteredUserCount = responseData.filteredUserCount
      const cursorCreatedAt = responseData.cursorCreatedAt
      const summary = responseData.summary
      if (cursorCreatedAt) {
        //pagination cursor set
        dispatch(adminActions.setLastCursorCustomers(cursorCreatedAt))
      } else {
        //end of the list
        dispatch(adminActions.setLastCursorCustomers(null))
      }
      const resultList = newCustomers.concat(fetchedUsers)
      dispatch(adminActions.setCustomers(resultList))
      dispatch(adminActions.setPanelSummary(summary))
      dispatch(
        adminActions.setUserCounts({
          total: totalLength,
          filtered: filteredUserCount,
        }),
      )
      return { total: totalLength, filtered: filteredUserCount }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const getAgencies = async () => {
    try {
      const data = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getAgencies`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )
      dispatch(adminActions.setAgencies(data.agencies))
    } catch (err) {
      errorHandler(err)
    }
  }

  const getAgenciesUsers = async ({ agencyId, freshFetch = false, filter, search }) => {
    if (freshFetch) {
      const wrapper = document.querySelector('.admin-panel-list-wrapper')
      if (wrapper !== undefined && wrapper !== null) {
        wrapper.scrollTo(0, 0)
      }
    }
    try {
      let newCustomers = freshFetch ? [] : [...customers]
      let url = `${process.env.REACT_APP_BACKEND_URL}/admin/getUsersForPerformancePanel?agencyId=${agencyId}&list=${'Agencies'}&search=${search}&isAgency=true`

      const filteredFilter = filterEmptyValues(filter)

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `&filters=${filterParams}`
      }

      const lastCursorCreatedAt = lastCursorCustomers
      if (lastCursorCreatedAt && !freshFetch) {
        url += `&cursorCreatedAt=${lastCursorCreatedAt}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
      })

      const fetchedUsers = responseData.users
      const totalLength = responseData.totalUserCount
      const filteredUserCount = responseData.filteredUserCount
      const cursorCreatedAt = responseData.cursorCreatedAt
      const summary = responseData.summary

      if (cursorCreatedAt) {
        dispatch(adminActions.setLastCursorCustomers(cursorCreatedAt))
      } else {
        dispatch(adminActions.setLastCursorCustomers(null))
      }

      const resultList = newCustomers.concat(fetchedUsers)
      dispatch(adminActions.setAgenciesUsers(resultList))
      dispatch(adminActions.setPanelSummary(summary))

      dispatch(adminActions.setAgencyCount({
        total: totalLength,
        filtered: filteredUserCount,
      }))
      return { total: totalLength, filtered: filteredUserCount }
    } catch (err) {
      errorHandler(err)
    }
  }

  const getDemoMeetings = async ({ freshFetch = false, filter, search }) => {
    if (freshFetch) {
      const wrapper = document.querySelector('.demo-meetings-list-wrapper')
      if (wrapper !== undefined && wrapper !== null) {
        wrapper.scrollTo(0, 0)
      }
    }
    try {
      let newDemoMeetings = freshFetch ? [] : [...demoMeetings]
      let url = `${process.env.REACT_APP_BACKEND_URL
        }/demo-meetings/?search=${search}`
      const filteredFilter = filterEmptyValues(filter)

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `&filters=${filterParams}`
      }
      const lastCursorCreatedAt = lastCursorDemoMeetings
      if (lastCursorCreatedAt && !freshFetch) {
        url += `&cursorCreatedAt=${lastCursorCreatedAt}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })

      const fetchedDemoMeetings = responseData.meetings
      const totalDemoMeetingCount = responseData.totalDemoMeetingCount
      const filteredDemoMeetingCount = responseData.filteredDemoMeetingCount
      const cursorCreatedAt = responseData.cursorCreatedAt
      const summary = responseData.summary
      if (cursorCreatedAt) {
        //pagination cursor set
        dispatch(adminActions.setLastCursorDemoMeetings(cursorCreatedAt))
      } else {
        //end of the list
        dispatch(adminActions.setLastCursorDemoMeetings(null))
      }
      const resultList = newDemoMeetings.concat(fetchedDemoMeetings)
      dispatch(adminActions.setDemoMeetings(resultList))
      dispatch(adminActions.setDemoMeetingSummary(summary))
      dispatch(
        adminActions.setDemoMeetingsCount({
          total: totalDemoMeetingCount,
          filtered: filteredDemoMeetingCount,
        }),
      )
      return {
        total: totalDemoMeetingCount,
        filtered: filteredDemoMeetingCount,
      }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const getAllAlarmsBetweenDates = async ({ middleDate, filters }) => {
    try {
      const startDate = new Date(middleDate)
      startDate.setDate(startDate.getDate() - 1)
      const endDate = new Date(middleDate)
      endDate.setDate(endDate.getDate() + 2)

      const filteredFilter = filterEmptyValues(filters)

      let url = `${process.env.REACT_APP_BACKEND_URL
        }/alarms/getAlarms?start=${formatDate(startDate)}&end=${formatDate(endDate)}`

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `&filters=${filterParams}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })
      const fetchedAlarms = responseData.combinedResults

      dispatch(adminActions.setAllAlarms(fetchedAlarms))
      return { fetchedAlarms }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const getOverdueAlarmCounts = async ({ filters }) => {
    try {
      const filteredFilter = filterEmptyValues(filters)

      let url = `${process.env.REACT_APP_BACKEND_URL
        }/alarms/getOverdueAlarmCounts`

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `?filters=${filterParams}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })
      const countGroupedBy = responseData.countGroupedBy

      dispatch(adminActions.setOverdueAlarmCounts(countGroupedBy))
      return { countGroupedBy }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }
  const getNoAlarmUserCounts = async ({ filters }) => {
    try {
      const filteredFilter = filterEmptyValues(filters)

      let url = `${process.env.REACT_APP_BACKEND_URL
        }/alarms/getNoAlarmUserCounts`

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `?filters=${filterParams}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })
      const countGroupedBy = responseData.countGroupedBy

      dispatch(adminActions.setNoAlarmUserCounts(countGroupedBy))
      return { countGroupedBy }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const getSignUps = async ({ freshFetch = false, filter, search }) => {
    if (freshFetch) {
      const wrapper = document.querySelector('.sign-ups-list-wrapper')
      if (wrapper !== undefined && wrapper !== null) {
        wrapper.scrollTo(0, 0)
      }
    }
    try {
      let newSignUps = freshFetch ? [] : [...signUps]
      let url = `${process.env.REACT_APP_BACKEND_URL
        }/admin/getSignUps/?search=${search}`
      const filteredFilter = filterEmptyValues(filter)

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `&filters=${filterParams}`
      }
      const lastCursorCreatedAt = lastCursorSignUps
      if (lastCursorCreatedAt && !freshFetch) {
        url += `&cursorCreatedAt=${lastCursorCreatedAt}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })
      const fetchedSignUps = responseData.users
      const totalSignUpCount = responseData.totalSignUpCount
      const filteredSignUpCount = responseData.filteredSignUpCount
      const cursorCreatedAt = responseData.cursorCreatedAt
      const summary = responseData.summary
      if (cursorCreatedAt) {
        //pagination cursor set
        dispatch(adminActions.setLastCursorSignUps(cursorCreatedAt))
      } else {
        //end of the list
        dispatch(adminActions.setLastCursorSignUps(null))
      }
      const resultList = newSignUps.concat(fetchedSignUps)
      dispatch(adminActions.setSignUps(resultList))
      dispatch(adminActions.setSignUpSummary(summary))
      dispatch(
        adminActions.setSignUpsCount({
          total: totalSignUpCount,
          filtered: filteredSignUpCount,
        }),
      )
      return { total: totalSignUpCount, filtered: filteredSignUpCount }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const getTickets = async ({ freshFetch = false, filter, search = '' }) => {
    if (freshFetch) {
      const wrapper = document.querySelector('.support-tickets-list-wrapper')
      if (wrapper !== undefined && wrapper !== null) {
        wrapper.scrollTo(0, 0)
      }
    }
    try {
      let newTickets = freshFetch ? [] : [...tickets]
      let url = `${process.env.REACT_APP_BACKEND_URL}/admin/getSupportTickets/?search=${search}`
      const filteredFilter = filterEmptyValues(filter)

      if (Object.keys(filteredFilter).length > 0) {
        const filterParams = encodeURIComponent(JSON.stringify(filteredFilter))
        url += `&filters=${filterParams}`
      }
      const lastCursorCreatedAt = lastCursorTickets
      if (lastCursorCreatedAt && !freshFetch) {
        url += `&cursorCreatedAt=${lastCursorCreatedAt}`
      }

      const responseData = await sendRequest(url, 'GET', null, {
        Authorization: 'Bearer ' + authToken,
      })
      const fetchedTickets = responseData.tickets
      const totalLength = responseData.totalTicketCount
      const filteredTicketCount = responseData.filteredTicketCount
      const cursorCreatedAt = responseData.cursorCreatedAt

      if (cursorCreatedAt) {
        // pagination cursor set
        dispatch(adminActions.setLastCursorTickets(cursorCreatedAt))
      } else {
        // end of the list
        dispatch(adminActions.setLastCursorTickets(null))
      }

      const hasUnreadMessages = fetchedTickets.some(ticket =>
        ticket.messages.some(
          message => message.receiver === auth.user._id && !message.read,
        ),
      )

      dispatch(adminActions.setHasUnreadMessages(hasUnreadMessages))

      const resultList = newTickets.concat(fetchedTickets)
      dispatch(adminActions.setTickets(resultList))
      dispatch(
        adminActions.setTicketCounts({
          total: totalLength,
          filtered: filteredTicketCount,
        }),
      )
      return { total: totalLength, filtered: filteredTicketCount }
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  /**
   * When displaying a user's AI Ads page through the admin panel,
   * we include a couple of query parameters in the URL.
   * To ensure the persistence of these admin parameters,
   * use this function when navigating to another URL.
   *
   * @param {string} to - A location that is the destination of some navigation.
   */
  const navigateWithAdminParams = to => {
    const userInViewId = query.get('user_in_view_id')
    const userInViewName = query.get('user_in_view_name')
    const admin = query.get('admin')

    const adminParams = [
      userInViewId && `user_in_view_id=${userInViewId}`,
      userInViewName && `user_in_view_name=${userInViewName}`,
      admin && `admin=${admin}`,
    ]
      .filter(Boolean)
      .join('&')

    const separator = to.includes('?') ? '&' : '?'
    const url = adminParams ? `${to}${separator}${adminParams}` : to

    navigate(url)
  }

  const viewedReportDetail = async () => {
    const queryData = {
      'crmDetails.adminViewedDetailedCampaignReport': Date.now(),
    }

    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userInView.id}`,
      'PATCH',
      JSON.stringify(queryData),
      {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
      },
    )

    dispatch(
      adminActions.setUserInView({
        ...userInView,
        crmDetails: {
          ...userInView.crmDetails,
          adminViewedDetailedCampaignReport: Date.now(),
        },
      }),
    )
    dispatch(
      adminActions.findCustomerByIdAndUpdate({
        id: userInView.id,
        data: {
          ...userInView,
          crmDetails: {
            ...userInView.crmDetails,
            adminViewedDetailedCampaignReport: Date.now(),
          },
        },
      }),
    )
  }

  const getProjectOfCustomer = async userId => {
    if (userId) {
      try {
        const project = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/dev/getProjectByUserId/${userId}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + auth.token,
          },
        )
        dispatch(projectActions.setProject(project))
      } catch (err) {
      }
    }
  }

  return {
    getCustomers,
    startViewingAsUser,
    loginToUser,
    endViewingAsUser,
    getPerformanceManagerProfileForUser,
    navigateWithAdminParams,
    viewedReportDetail,
    convertManagerIdToName,
    convertManagerNameToId,
    convertAgencyIdToName,
    convertAgencyNameToId,
    getAgenciesUsers,
    hasUnreadMessages,
    getTickets,
    getDemoMeetings,
    getAllAlarmsBetweenDates,
    getOverdueAlarmCounts,
    getNoAlarmUserCounts,
    getSignUps,
    getTeamInfo,
    getOneDemo,
    getOneSignUp,
    getOneCustomer,
    getProjectOfCustomer,
    getAgencies,
  }
}
