import { useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const useNavigator = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const search = useMemo(() => {
        return new URLSearchParams(location.search)
    }, [location.search])

    const paramsObject = useMemo(() => {
        const paramObj = {};
        for (var value of search.keys()) {
            paramObj[value] = search.get(value);
        }

        return paramObj;
    }, [search])

    const navigateWithParams = (path, params) => {

        const newSearchParams = new URLSearchParams(search);

        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                if (value === null || value === undefined) {
                    newSearchParams.delete(key);
                } else {
                    newSearchParams.set(key, value);
                }
            });
        }

        const searchString = newSearchParams.toString();
        const queryString = searchString ? `${searchString}` : '';

        let separator = path.includes('?') ? '&' : '?'

        navigate(`${path}${separator}${queryString}`)
    };

    const addParamToUrl = (param) => {
        const newSearchParams = new URLSearchParams(search);
        Object.keys(param).forEach(key => {
            newSearchParams.set(key, param[key]);
        });

        const searchString = newSearchParams.toString();
        const queryString = searchString ? `${searchString}` : '';
        navigate(`${location.pathname}?${queryString}`)
    }

    const removeParamFromUrl = (param) => {
        const newSearchParams = new URLSearchParams(search);
        newSearchParams.delete(param);
        const searchString = newSearchParams.toString();
        const queryString = searchString ? `${searchString}` : '';
        navigate(`${location.pathname}?${queryString}`)
    }

    return {
        navigateWithParams,
        addParamToUrl,
        removeParamFromUrl,
        searchParams: paramsObject
    }
}

export default useNavigator
