import React from 'react'
import { useSelector } from 'react-redux'

import styles from './ExistingSupplementaryWarning.module.css'

const ExistingSupplementaryWarning = ({ onTryAgain, isLoading, selectedCatalog, selectedPrimaryFeed }) => {
  const selectedBusinessId = useSelector(state => state.facebook.selectedBusiness?.id)
  const handleTryAgain = () => {
    if (!onTryAgain || isLoading) return
    onTryAgain()
  }

  const handleRemoveExistingSupplementaryFeed = () => {
    window.open(`https://business.facebook.com/commerce/catalogs/${selectedCatalog?.id}/feeds/${selectedPrimaryFeed?.id}/settings?business_id=${selectedBusinessId}`, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className={styles.existingSupplementaryWarning}>
      <p>
        <strong>Oops!</strong> This catalog already has a <strong>supplementary feed</strong> linked.
      </p>
      <p>
        In order to use Enhenced Catalog, you need to <span
          onClick={handleRemoveExistingSupplementaryFeed}
          className={isLoading ? styles.disabledTryAgain : styles.tryAgain}
          role="button"
          tabIndex={0}
        >
          remove the existing one on Facebook
        </span>
        and then{' '}
        <span
          onClick={handleTryAgain}
          className={isLoading ? styles.disabledTryAgain : styles.tryAgain}
          role="button"
          tabIndex={0}
        >
          try again
        </span>
        .
      </p>
      <p className={styles.note}>
        Note: When switching back to your primary feed from supplementary feed on Facebook, changes may take some time to be reflected.
      </p>
    </div>
  )
}

export default ExistingSupplementaryWarning
