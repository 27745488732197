import React, { useState, useEffect } from 'react';
import cookies from '../../../shared/util/cookies';
import './TypewriterText.css';
import { useSelector } from 'react-redux';

const TypewriterText = ({ html, questions = [], speed = 10, onQuestionClick, contentKey, alwaysAnimate = false }) => {
	const [displayedText, setDisplayedText] = useState('');
	const [displayedQuestions, setDisplayedQuestions] = useState([]);
	const [isMainTextDone, setIsMainTextDone] = useState(false);
	const [shouldAnimate, setShouldAnimate] = useState(true);

	const userId = useSelector(state => state.auth.user.id)

	// Check if content has been shown before using cookies
	useEffect(() => {
		if (!html) return;

		const cookieKey = `twr_${userId}_${contentKey}`;
		const hasBeenShown = cookies.get(cookieKey);
		if (hasBeenShown && !alwaysAnimate) {
			setShouldAnimate(false);
			setDisplayedText(html);
			setIsMainTextDone(true);
			if (questions?.length) {
				setDisplayedQuestions(questions);
			}
		} else {
			setShouldAnimate(true);
		}
	}, [html, questions]);

	// Animate main text
	useEffect(() => {
		if (!html || !shouldAnimate) return;

		let currentIndex = 0;
		let isMounted = true;

		const interval = setInterval(() => {
			if (currentIndex < html.length) {
				if (isMounted) {
					setDisplayedText(html.slice(0, currentIndex + 1));
					currentIndex++;
				}
				if (currentIndex === 150) {
					// Set cookie to expire in 7 days
					if (!alwaysAnimate) {
						const cookieKey = `twr_${userId}_${contentKey}`;
						cookies.set(cookieKey, 'true', 7);
					}
				}
			} else {
				if (isMounted) {
					setDisplayedText(html); // Ensure full text is set at the end
					setIsMainTextDone(true);
				}
				clearInterval(interval);
			}
		}, speed);

		return () => {
			isMounted = false;
			clearInterval(interval);
		};
	}, [html, speed, shouldAnimate]);

	// Animate questions
	useEffect(() => {
		if (!isMainTextDone || !questions?.length || !shouldAnimate) return;

		let currentIndex = 0;
		let isMounted = true;

		const showNextQuestion = () => {
			if (currentIndex < questions.length && isMounted) {
				setDisplayedQuestions(prev => [...prev, questions[currentIndex]]);
				currentIndex++;

				if (currentIndex < questions.length) {
					setTimeout(showNextQuestion, speed * 60);
				}
			}
		};

		setTimeout(showNextQuestion, speed * 60);

		return () => {
			isMounted = false;
		};
	}, [isMainTextDone, questions, speed, shouldAnimate]);

	return (
		<div className="typewriter-container">
			<div
				className="typewriter-text"
				dangerouslySetInnerHTML={{ __html: displayedText }}
			/>
			{Array.isArray(questions) && displayedQuestions.length > 0 && (
				<div className="enhencer-ai-agent-questions">
					<h4>More questions:</h4>
					<div className="question-cards">
						{displayedQuestions.map((question, index) => (
							<div
								key={index}
								className="question-card"
								onClick={() => onQuestionClick?.(question)}
							>
								{question}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

export default TypewriterText;