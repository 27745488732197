import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaLock } from 'react-icons/fa';
import { toast } from "react-toastify";

import { useHttpClient } from "../../shared/hooks/http-hook";
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png';
import Button from "../../shared/components/FormElements/Button";
import StrokeInput from "../../shared/components/FormElements/StrokeInput";
import { VALIDATOR_MINLENGTH, VALIDATOR_PASSWORD_MATCH } from "../../shared/util/validators";

import 'react-toastify/dist/ReactToastify.css';
import errorHandler from "../../shared/util/errorHandler";

const ResetPassword = props => {
    const query = new URLSearchParams(window.location.search);

    const token = query.get("token");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordUpdatedComplete, setPasswordUpdatedComplete] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const { sendRequest } = useHttpClient();

    const onSubmit = async event => {
        event.preventDefault();
        
        if (password === "") { 
            errorHandler({message: "Password is required"})
        } else if (password.length < 6) {
            errorHandler({message: "Password must be at least 6 characters"})
        } else if (password.length > 30) {
            errorHandler({message: "Password must be maximum 30 characters"})
        } else if (password !== password2) {
            errorHandler({message: "Passwords do not match"})
        } else {
            const userData = {
                token: token,
                password: password,
                password2: password2
            };
            
            sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/resetPassword`,
                "POST",
                JSON.stringify(userData),
                {
                    "Content-Type": "application/json",
                }

                    );
            setPasswordUpdatedComplete(true);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prev => !prev);
    };

    return (
        <React.Fragment>
            <div className="full-container">
                <div className="login-cont">
                    <div style={{ display: 'flex' }}>
                        <img className="logo" src={enhencerLogo} alt="Logo"/>
                    </div>
                    <p>Enter your new password.</p>
                    <form onSubmit={onSubmit}>
                       <div className="input-field">
                            <StrokeInput
                                element="input"
                                id="password"
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(val) => setPassword(val)}
                                showPasswordToggle={true}
                                showPassword={showPassword}
                                onTogglePassword={togglePasswordVisibility}
                                validators={[VALIDATOR_MINLENGTH(6)]}
                                errorText="Password must be at least 6 characters"
                                required
                            />
                        </div>
                        <div className="input-field">
                            <StrokeInput
                                element="input"
                                id="password2"
                                type="password"
                                placeholder="Confirm Password"
                                value={password2}
                                onChange={(val) => setPassword2(val)}
                                showPassword={showPassword}
                                validators={[VALIDATOR_PASSWORD_MATCH(password, password2)]}
                                errorText="Passwords don't match"
                                required
                            />
                        </div>
                        <div className="cont">
                            <Button className="action-button" type="submit">
                                Reset Password
                            </Button>
                        </div>
                    </form>
                    {passwordUpdatedComplete ? (
                        <div className="input-cont">
                            <p>Password updated successfully. <Link to="/login">Login</Link></p>
                        </div>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {}
)(ResetPassword);