import { WORKBENCH_SIZE } from '../../../support/constants'
import temp14fg from '../../../../shared/assets/images/ai-creative/temp14fg.svg'

export const Template14Config = {
  hidden: true,
  name: 'Black Friday Blitz',
  containerId: 'template14-carousel-single',
  backgroundColor: 'color3',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 800,
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      center: true,
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp14fg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#FF8000',
      draggable: false,
      clickThrough: true,
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 369,
        y: WORKBENCH_SIZE.height - 330,
      },
      text: '30% OFF',
      width: 738,
      align: 'left',
      fontFamily: 'Bayon',
      fontSize: 106,
      fontStyle: 'bold',
      color: 'color2',
      letterSpacing: 1.08,
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 369,
        y: WORKBENCH_SIZE.height - 230,
      },
      text: 'USE CODE: GET30',
      width: 738,
      align: 'left',
      fontFamily: 'Bayon',
      fontSize: 38,
      color: 'color2',
      letterSpacing: 1.09,
      fontIndex: 2,
    },
  ],
}
