import { useEffect, useRef, useCallback } from 'react';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';

export const useSocket = () => {
    const socketRef = useRef();
    const userId = useSelector(state => state.auth.user.id);
    const authToken = useSelector(state => state.auth.token);
    useEffect(() => {
        const socketUrl = process.env.REACT_APP_WS_URL; // Use the new WS URL
        
        socketRef.current = io(socketUrl, {
            transports: ['websocket', 'polling'],
            auth: {
                token: authToken
            },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            timeout: 10000,
            forceNew: true
        });

        // Register the socket with userId when connection is established
        socketRef.current.on('connect', () => {
            socketRef.current.emit('register', userId);
        });

        socketRef.current.on('connect_error', (error) => {
        });

        socketRef.current.on('error', (error) => {
        });

        // Add a general event listener for all events (for debugging)
        socketRef.current.onAny((eventName, ...args) => {
        });

        // Cleanup on unmount
        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [authToken, userId]);

    const subscribeToProgress = useCallback((userId, callback, campaignType) => {
        return new Promise((resolve) => {
            if (!socketRef.current) {
                resolve();
                return;
            }

            const trySubscribe = () => {
                if (socketRef.current.connected) {
                    // Create a wrapper callback that includes the campaign type
                    const wrappedCallback = (data) => {
                        
                        // Only call the callback if the campaign type matches
                        if (data.type === campaignType) {
                            callback(data);
                        }
                    };

                    // Listen for campaign_progress events
                    socketRef.current.on('campaign_progress', wrappedCallback);

                    resolve(() => {
                        socketRef.current.off('campaign_progress', wrappedCallback);
                    });
                } else {
                    setTimeout(trySubscribe, 100);
                }
            };

            trySubscribe();
        });
    }, []);

    return {
        socket: socketRef.current,
        subscribeToProgress
    };
}; 