import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AIAdsPageLayout from '../components/AIAdsPageLayout'
import SidebarContent from '../components/home/SidebarContent'
import { authActions } from '../../store/auth'
import YourSummaryCard from '../components/home/YourSummaryCard'
import CampaignReportComparison from '../components/report/overall-report/CampaignReportComparison'
import './AIAds.css'

const AIAds = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      authActions.updateUserObjManyFields({
        isInSignupFlow: false,
      }),
    )
  }, [])

  return (
    <AIAdsPageLayout>
      <AIAdsPageLayout.Content>
        <div className="ai-ads-home-grid">
          <div className="summary">
            <YourSummaryCard />
          </div>
          <div className="history">
            <CampaignReportComparison />
          </div>
        </div>
      </AIAdsPageLayout.Content>
      <AIAdsPageLayout.Sidebar>
        <SidebarContent />
      </AIAdsPageLayout.Sidebar>
    </AIAdsPageLayout>
  )
}

export default AIAds
