import React from 'react'
import { MdChevronRight } from 'react-icons/md'
import './Breadcrumb.css'

const Breadcrumb = ({ children, actions }) => {
  const activeChildren = React.Children.toArray(children).filter(Boolean)
  
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-container">
      <div className="breadcrumb-wrapper">
        <ul className="breadcrumb">
          {activeChildren.map((child, index) => {
            const isLast = index === activeChildren.length - 1

            return (
              <li
                key={index}
                className={`breadcrumb-item${isLast ? ' active' : ''}`}
                aria-current={isLast ? 'page' : null}
              >
                {child}
                {!isLast && <MdChevronRight className="breadcrumb-separator" />}
              </li>
            )
          })}
        </ul>

        {actions && <div className="breadcrumb-actions">{actions}</div>}
      </div>
    </nav>
  )
}

export default Breadcrumb