const customerCategoryTypes = [
  'Fashion/Erkek',
  'Fashion/Kadın',
  'Home Decor',
  'Gifts',
]

const userProgressSteps = [
  'GTM Access',
  'GTM Publish',
  'Data connected',
  'Audiences defined on FB/Google Ads',
  'Enhencer Audience is ready',
  'Create Facebook A/B test ',
  'Enhencer Audience in all campaigns',
]

const campaigns = [
  {
    name: 'Remarketing Audience',
    campaign: 'Conversion Campaign',
    platform: 'Facebook',
    isActive: true,
  },
  {
    name: 'Remarketing Audience',
    campaign: 'Dynamic Product Ads Campaign',
    platform: 'Facebook',
    isActive: false,
  },
  {
    name: 'Remarketing Audience',
    campaign: 'Google Display Network - GDN',
    platform: 'Google',
    isActive: true,
  },
  {
    name: 'High Intent Remarketing Audience',
    campaign: 'Conversion Campaign',
    platform: 'Facebook',
    isActive: true,
  },
  {
    name: 'High Intent Remarketing Audience',
    campaign: 'Dynamic Product Ads Campaign',
    platform: 'Facebook',
    isActive: false,
  },
  {
    name: 'Remarketing Audience',
    campaign: 'Performance Max',
    platform: 'Google',
    isActive: true,
  },
  {
    name: 'Remarketing Audience',
    campaign: 'Search Campaign',
    platform: 'Google',
    isActive: true,
  },
  {
    name: 'Remarketing Audience',
    campaign: 'RLSA Campaign',
    platform: 'Google',
    isActive: false,
  },
]

const audienceResultIntervals = [
  { name: 'Last 7 Days', days: 7 },
  { name: 'Last 14 Days', days: 14 },
  { name: 'Last 28 Days', days: 28 },
]

const topEnhencerCategories = [
  {
    name: 'Animals & Pet Supplies',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/animal.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Apparel & Accessories',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Arts & Entertainment & Media',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/arts.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Baby & Toddler',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/baby.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Business & Industrial & Office Supplies',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/business.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Electronics & Cameras & Optics',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/electronics.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Food & Beverages & Tobacco',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/food.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Furniture & Home & Garden',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/furniture.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Hardware',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/hardware.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Health & Beauty',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/health.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Luggage & Bags',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/luggage.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Mature',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/mature.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Software',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/software.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Sporting Goods',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/sporting.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Toys & Games',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/toys.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Vehicles & Parts',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/vehicles.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
]

const apparelAccessoriesEnhencerCategories = [
  {
    name: 'Accessories',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/accessories.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Activewear & Outerwear',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/activewear.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Dresses',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/dresses.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Jewelry',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/jewelry.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Outfit Sets',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/outfit.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Pants',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/pants.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Shirts & Tops',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/shirts.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Shoes',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/shoes.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Modest Hijab',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/modest.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.jpeg',
    selected: false,
  },
  {
    name: 'Shorts & Skirts & Skorts',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/shorts.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Sleepwear & Loungewear',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/sleepwear.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Suits',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/suits.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Swimwear',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/swimwear.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Underwear & Socks',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/underwear.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Uniforms',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/uniforms.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Wedding & Bridal Party Dresses',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/wedding.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
  {
    name: 'Handbags & Wallets & Cases',
    iconUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel/handbag.svg',
    imageUrl:
      'https://cdn.enhencer.com/admin-assets/images/select-category-page/white-bg.png',
    selected: false,
  },
]

const callTimeRanges = {
  morning: '09:00-12:00',
  afternoon: '12:00-18:00',
  evening: '18:00-22:00',
}

const performanceManagers = [
  'Cengiz Kalkan',
  'Cerena Ateş',
  'Görkem Beşer',
  'Selin Altıntaş',
  'Serkan Oğuz',
  'Kamer Şahin',
  'Fırat Çetin',
  'Sarper Subaşı',
]

const accountManagers = [
  'Nehir Kaya',
  'Osman Zafer Yılmaz',
  'Özlem Malatyalı',
  'Cengiz Kalkan',
  'Cerena Ateş',
  'Görkem Beşer',
  'Selin Altıntaş',
  'Serkan Oğuz',
]

// New UI Constants - End

export default {
  customerCategoryTypes,
  userProgressSteps,
  campaigns,
  audienceResultIntervals,
  topEnhencerCategories,
  apparelAccessoriesEnhencerCategories,
  callTimeRanges,
  performanceManagers: performanceManagers,
  accountManagers: accountManagers,
}
