import React, { useState, useEffect } from 'react'
import Chart from 'chart.js/auto'
import '../CampaignReportComparison.css'
import {
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Legend,
	Tooltip,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { useHttpClient } from '../../../../../shared/hooks/http-hook';
import numberFormatter from '../../../../../shared/util/numberFormatter'
import {
	FaArrowUp,
	FaArrowDown,
	FaArrowRight,
	FaInfoCircle
} from 'react-icons/fa';
import DualLineChart, { TrendLineChart } from '../components/DualLineChart';
import { getDataKey } from '../CampaignReportComparison';
import helper from '../../../../../shared/util/helper';

Chart.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const TrendAnalysisView = ({ currencySymbol, popupUserFacebookAds, popupUserFacebookInfo, popupUserFullFunnels }) => {
	const [timeRange, setTimeRange] = useState('1w');
	const [selectedMetric, setSelectedMetric] = useState('Performance');
	const [selectedAttributionWindow, setSelectedAttributionWindow] = useState('7d_click');
	const [trendData, setTrendData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { sendRequest } = useHttpClient();
	const selectedAdAccountId = useSelector(state => popupUserFacebookInfo?.selectedAdAccountId || state.facebook?.selectedAdAccount?.id);
	const accessToken = useSelector(state => popupUserFacebookInfo?.fbAccessToken || state.facebook?.auth?.accessToken);
	const dayCount = useSelector(state => popupUserFacebookAds?.weeklyReports?.enhOverall?.dayCount || state.auth.user.facebookAds?.weeklyReports?.enhOverall?.dayCount)
	const facebookGraphUrl = process.env.REACT_APP_FACEBOOK_GRAPH_URL;
	const fullFunnels = useSelector(state => popupUserFullFunnels || state.facebook.fullFunnels)

	let timeIncrement = 1;
	if (timeRange === '1w') {
		timeIncrement = 1;
	} else if (timeRange === '2w') {
		timeIncrement = 3;
	} else if (timeRange === '1m') {
		timeIncrement = 5;
	} else if (timeRange === '3m') {
		timeIncrement = 10;
	}

	const timeRangeOptions = [
		{ value: '1w', label: 'Weekly', days: 14 },
		{ value: '2w', label: 'Bi-Weekly', days: 30 },
		{ value: '1m', label: 'Monthly', days: 60 },
		{ value: '3m', label: 'Quarterly', days: 180 },
	];

	const metricOptions = [
		{ value: 'Performance', label: 'ROAS' },
		{ value: 'AdSpend', label: 'Ad Spend' },
		{ value: 'Sales', label: 'Sales' },
	];

	const attributionWindowOptions = [
		{ value: '7d_click', label: '7d Click' },
		{ value: '7d_click_1_day_view', label: '7d Click + 1d View' },
	];

	const fetchTrendData = async (sinceStr, untilStr, timeIncrement = 1) => {
		if (!fullFunnels || !selectedAdAccountId || !accessToken) return null;

		const campaignIds = fullFunnels.reduce((ids, fullFunnel) => {
			fullFunnel.adCampaigns?.forEach(adCampaign => {
				ids.push(adCampaign.campaign.id);
				/* if (adCampaign.campaign.objective === "OUTCOME_SALES") {
				} */
			})
			return ids;
		}, []);

		const fields = "spend,reach,impressions,frequency,ctr,clicks,cpm,cpc,purchase_roas,actions,action_values,cost_per_action_type";

		const attributionSetting = "['7d_click','1d_view']"

		const enhencerUrl = `${facebookGraphUrl}/${selectedAdAccountId}/insights?action_attribution_windows=${attributionSetting}&time_range={'since':'${sinceStr}','until':'${untilStr}'}&fields=${fields}&filtering=[{'field':'campaign.id','operator':'IN','value':[${campaignIds.join(",")}]}]&time_increment=${timeIncrement}&access_token=${accessToken}`;

		try {
			const enhencerResponse = await sendRequest(enhencerUrl, 'GET', null, {}, true);
			if (enhencerResponse?.data) {
				const chartData = {
					labels: [],
					enhencer: {
						adSpend: [],
						adSpend_7d_click: [],
						reach: [],
						clicks: [],
						ctr: [],
						impressions: [],
						sales: [],
						sales_7d_click: [],
						roas: [],
						roas_7d_click: [],
						purchases: [],
						purchases_7d_click: [],
						costPerPurchase: [],
						costPerPurchase_7d_click: [],
					}
				};
				// Process Enhencer campaigns data
				enhencerResponse.data.forEach(dataPoint => {
					chartData.labels.push(`${dataPoint.date_start},${dataPoint.date_stop}`)
					chartData.enhencer.adSpend.push(parseFloat(dataPoint.spend || 0))
					chartData.enhencer.adSpend_7d_click.push(parseFloat(dataPoint.spend || 0))
					chartData.enhencer.reach.push(parseInt(dataPoint.reach || 0))
					chartData.enhencer.clicks.push(parseInt(dataPoint.clicks || 0))
					chartData.enhencer.impressions.push(parseInt(dataPoint.impressions || 0))

					const impressions = parseInt(dataPoint.impressions || 0)
					const clicks = parseInt(dataPoint.clicks || 0)
					const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0
					chartData.enhencer.ctr.push(parseFloat(ctr.toFixed(2)))

					const purchases = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')
						? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase').value)
						: 0
					const purchases_7d_click = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')
						? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')['7d_click'] || 0)
						: 0
					chartData.enhencer.purchases.push(purchases)
					chartData.enhencer.purchases_7d_click.push(purchases_7d_click)

					const sales = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')
						? parseFloat(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase').value)
						: 0
					const sales_7d_click = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')
						? parseFloat(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')['7d_click'] || 0)
						: 0
					chartData.enhencer.sales.push(sales)
					chartData.enhencer.sales_7d_click.push(sales_7d_click)

					const roas = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')
						? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase').value)
						: 0
					const roas_7d_click = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')
						? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')['7d_click'] || 0)
						: 0
					chartData.enhencer.roas.push(roas)
					chartData.enhencer.roas_7d_click.push(roas_7d_click)

					const costPerPurchase = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')
						? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase').value)
						: 0
					const costPerPurchase_7d_click = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')
						? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')['7d_click'] || 0)
						: 0
					chartData.enhencer.costPerPurchase.push(costPerPurchase)
					chartData.enhencer.costPerPurchase_7d_click.push(costPerPurchase_7d_click)
				})

				return chartData;
			}
		} catch (error) {
			console.error('Error fetching trend data:', error);
			return null;
		}
	};

	useEffect(() => {
		const loadTrendData = async () => {
			setIsLoading(true);
			const selectedRange = timeRangeOptions.find(option => option.value === timeRange);
			if (!selectedRange) {
				setIsLoading(false);
				return;
			}
			const yesterday = new Date(Date.now() - 86400000);
			const since = new Date(yesterday);
			since.setDate(yesterday.getDate() - selectedRange.days);
			const sinceStr = since.toISOString().split('T')[0];
			const untilStr = yesterday.toISOString().split('T')[0];


			const newTrendData = await fetchTrendData(sinceStr, untilStr, timeIncrement);
			if (newTrendData) {
				setTrendData(newTrendData);
			}
			setIsLoading(false);
		};

		loadTrendData();
	}, [timeRange, fullFunnels, selectedAdAccountId, accessToken]);

	const getLastNDays = (data, days) => {
		if (!Array.isArray(data)) return [];
		const length = data.length;
		return data.slice(Math.max(length - days, 0));
	};

	const calculatePeriodAverages = (data) => {
		if (!Array.isArray(data)) return { firstHalf: 0, secondHalf: 0 };

		const halfPeriod = Math.floor(data.length / 2);
		const firstHalf = data.slice(0, halfPeriod);
		const secondHalf = data.slice(halfPeriod);

		const calculateAverage = (arr) => {
			if (!arr.length) return 0;
			return arr.reduce((sum, val) => sum + val, 0) / arr.length;
		};

		return {
			firstHalf: calculateAverage(firstHalf),
			secondHalf: calculateAverage(secondHalf)
		};
	};

	const getChartData = (metric, attributionWindow) => {
		if (!trendData || isLoading) return { data: [], labels: [], averages: { firstHalf: 0, secondHalf: 0 } };
		const dataKey = getDataKey(metric, attributionWindow);
		const selectedRange = timeRangeOptions.find(option => option.value === timeRange);
		const days = selectedRange?.days || 14;
		const metricData = trendData.enhencer[dataKey];

		if (!metricData) return { data: [], labels: [], averages: { firstHalf: 0, secondHalf: 0 } };

		const lastNDays = getLastNDays(metricData, days);
		const labels = getLastNDays(trendData.labels || [], days);

		return {
			data: lastNDays,
			labels,
			averages: calculatePeriodAverages(lastNDays)
		};
	};

	const formatValue = (value, metric) => {
		if (metric === 'Performance') return numberFormatter.formatNumber(value, 2);
		return numberFormatter.formatNumber(value, 0);
	};

	const getPrefix = (metric) => {
		if (metric === 'AdSpend' || metric === 'Sales') return currencySymbol;
		return '';
	};

	const getSuffix = (metric) => {
		if (metric === 'Performance') return 'x';
		if (metric === 'Clicks' && metric.includes('rate')) return '%';
		return '';
	};

	const getMetricTitle = (metric) => {
		switch (metric) {
			case 'Performance':
				return 'ROAS Trend Analysis';
			case 'AdSpend':
				return 'Ad Spend Trend Analysis';
			case 'Sales':
				return 'Sales Trend Analysis';
			default:
				return 'Trend Analysis';
		}
	};

	const getMetricTooltip = (metric) => {
		switch (metric) {
			case 'Performance':
				return 'Compares average ROAS between first and second half of the selected period';
			case 'AdSpend':
				return 'Compares average ad spend between first and second half of the selected period';
			case 'Sales':
				return 'Compares average sales between first and second half of the selected period';
			default:
				return 'Compares averages between first and second half of the selected period';
		}
	};

	const calculatePercentageChange = (firstHalf, secondHalf) => {
		if (firstHalf === 0) return secondHalf > 0 ? 100 : 0;
		const change = ((secondHalf - firstHalf) / firstHalf) * 100;
		return change;
	};

	const getChangeIndicator = (change) => {
		if (change > 0) return <FaArrowUp />;
		if (change < 0) return <FaArrowDown />;
		return <FaArrowRight />;
	};

	const selectedRange = timeRangeOptions.find(option => option.value === timeRange);
	const halfPeriod = Math.floor(selectedRange?.days / 2);

	const chartConfig = {
		borderColor: '#85ca6e',
		backgroundColor: 'rgba(133, 202, 110, 0.1)',
		fill: true,
		tension: 0.4,
		borderWidth: 2,
		pointRadius: 3,
		pointBackgroundColor: '#85ca6e',
	};

	const getPeriodLabels = (metric) => {
		switch (metric) {
			case 'Performance':
				return {
					first: 'Previous Period Average ROAS',
					second: 'Recent Period Average ROAS'
				};
			case 'AdSpend':
				return {
					first: 'Previous Period Average Spend',
					second: 'Recent Period Average Spend'
				};
			case 'Sales':
				return {
					first: 'Previous Period Average Revenue',
					second: 'Recent Period Average Revenue'
				};
			default:
				return {
					first: `Previous ${halfPeriod} Days Average`,
					second: `Recent ${halfPeriod} Days Average`
				};
		}
	};

	return (
		<div className="crc-alternative-view no-border">
			<div className="crc-trend-controls">
				<div className="crc-trend-selectors">
					<div className="crc-trend-selector-group">
						<span className="crc-trend-selector-label">Metric</span>
						<select
							value={selectedMetric}
							onChange={(e) => setSelectedMetric(e.target.value)}
							className="crc-trend-metric-dropdown"
						>
							{metricOptions.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					<div className="crc-trend-selector-group">
						<span className="crc-trend-selector-label">Time Range</span>
						<select
							value={timeRange}
							onChange={(e) => setTimeRange(e.target.value)}
							className="crc-trend-range-dropdown"
						>
							{timeRangeOptions.map(option => (
								<option key={option.value} value={option.value} disabled={
									option.value === '1w' ? dayCount <= 7
										: option.value === '2w' ? dayCount <= 30
											: option.value === '1m' ? dayCount <= 60
												: option.value === '3m' ? dayCount <= 90 : false}>
									{option.label}
								</option>
							))}
						</select>
					</div>
					<div className="crc-trend-selector-group">
						<span className="crc-trend-selector-label">Attribution</span>
						<select
							value={selectedMetric === 'AdSpend' ? 'Not applicable' : selectedAttributionWindow}
							onChange={(e) => setSelectedAttributionWindow(e.target.value)}
							className="crc-trend-metric-dropdown"
							disabled={selectedMetric === 'AdSpend'}
						>
							{selectedMetric === 'AdSpend' ? <option value='Not applicable'>Not applicable</option> : attributionWindowOptions.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className="crc-chart-wrapper full-width chart-with-border">
				<div className="crc-chart-header">
					<h3 className="crc-chart-title">{getMetricTitle(selectedMetric)}</h3>
					<div className="crc-mini-info">
						<div className="crc-mini-info-icon">
							<FaInfoCircle />
						</div>
						<div className="crc-mini-info-tooltip">
							{getMetricTooltip(selectedMetric)}
						</div>
					</div>
				</div>
				<div className="crc-period-bar">
					<div className="crc-period-segment previous">
						<div className="crc-period-segment-content">
							Previous Period
							<span className="crc-period-days">{halfPeriod} days</span>
							<span className="crc-period-average">
								Avg: {getPrefix(selectedMetric)}{formatValue(getChartData(selectedMetric, selectedAttributionWindow).averages.firstHalf, selectedMetric)}{getSuffix(selectedMetric)}
							</span>
						</div>
					</div>
					<div className="crc-period-segment recent">
						<div className="crc-period-segment-content">
							Recent Period
							<span className="crc-period-days">{halfPeriod} days</span>
							<span className="crc-period-average">
								Avg: {getPrefix(selectedMetric)}{formatValue(getChartData(selectedMetric, selectedAttributionWindow).averages.secondHalf, selectedMetric)}{getSuffix(selectedMetric)}
							</span>
						</div>
					</div>
					{(() => {
						const firstHalf = getChartData(selectedMetric, selectedAttributionWindow)
						const firstHalfAvg = firstHalf.averages.firstHalf;
						const secondHalf = getChartData(selectedMetric, selectedAttributionWindow)
						const secondHalfAvg = secondHalf.averages.secondHalf;
						const change = calculatePercentageChange(firstHalfAvg, secondHalfAvg);
						const indicator = getChangeIndicator(change);
						return (
							<div className={`crc-period-change-overlay ${change > 0 ? 'positive' : change < 0 ? 'negative' : ''}`}>
								{indicator}
								<span className="crc-period-change-value">
									{Math.abs(change).toFixed(1)}%
								</span>
							</div>
						);
					})()}
				</div>
				<div className="crc-single-chart">
					<TrendLineChart
						title={selectedMetric}
						data={{
							enhencer: getChartData(selectedMetric, selectedAttributionWindow).data,
							others: null
						}}
						labels={getChartData(selectedMetric, selectedAttributionWindow).labels}
						prefix={getPrefix(selectedMetric)}
						suffix={getSuffix(selectedMetric)}
						dataset1Config={{
							label: metricOptions.find(option => option.value === selectedMetric)?.label || selectedMetric,
							...chartConfig
						}}
						options={{
							plugins: {
								legend: {
									display: false
								},
								annotation: {
									annotations: {
										firstHalfAvg: {
											type: 'line',
											yMin: getChartData(selectedMetric, selectedAttributionWindow).averages.firstHalf,
											yMax: getChartData(selectedMetric, selectedAttributionWindow).averages.firstHalf,
											xMin: 0,
											xMax: halfPeriod - 1,
											borderColor: 'rgba(148, 149, 155, 0.5)',
											borderWidth: 2,
											borderDash: [5, 5],
											label: {
												content: `${getPeriodLabels(selectedMetric).first}: ${getPrefix(selectedMetric)}${formatValue(getChartData(selectedMetric, selectedAttributionWindow).averages.firstHalf, selectedMetric)}${getSuffix(selectedMetric)}`,
												enabled: true,
												position: 'start'
											}
										},
										secondHalfAvg: {
											type: 'line',
											yMin: getChartData(selectedMetric, selectedAttributionWindow).averages.secondHalf,
											yMax: getChartData(selectedMetric, selectedAttributionWindow).averages.secondHalf,
											xMin: halfPeriod,
											xMax: selectedRange.days - 1,
											borderColor: 'rgba(var(--main-green-rgb), 0.5)',
											borderWidth: 2,
											borderDash: [5, 5],
											label: {
												content: `${getPeriodLabels(selectedMetric).second}: ${getPrefix(selectedMetric)}${formatValue(getChartData(selectedMetric, selectedAttributionWindow).averages.secondHalf, selectedMetric)}${getSuffix(selectedMetric)}`,
												enabled: true,
												position: 'end'
											}
										}
									}
								}
							}
						}}
					/>
				</div>
			</div>
		</div>
	);
};

export default TrendAnalysisView