import React, { useState, useEffect, useCallback } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook';
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor';
import { processTextPlaceholders, formatPrice } from '../../support/utils';
import {
  BsTextLeft,
  BsTextCenter,
  BsTextRight,
  BsArrowUp,
  BsArrowDown,
  BsDash,
  BsArrowUpSquare,
  BsArrowDownSquare,
  BsTrash,
  BsX
} from 'react-icons/bs';

import toolbarStyles from '../layout/Toolbar.module.css';
import styles from './ElementControls.module.css';

// Font families available for text elements
const fontFamilies = [
  'Be Vietnam Pro',
  'Open Sans',
  'Inter',
  'Quicksand',
  'Roboto',
  'Times New Roman',
  'Courier New',
  'Arial',
  'Verdana',
  'Bayon',
  'Anuphan',
  'Staatliches',
  'Readex Pro',
];

const ElementControls = ({ selectedElementInfo }) => {
  const dispatch = useDispatch();
  const { draftConfiguration, addToHistory, productOnDraft, discount, companyDetails } = useEnhencedCatalog();
  const companyName = useSelector(state => state.auth.user.companyName);
  const currency = useSelector(state => state.facebook?.selectedAdAccount?.currency ?? "USD");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [elementProperties, setElementProperties] = useState({
    text: '',
    fontFamily: 'Arial',
    fontSize: 20,
    color: '#000000',
    fill: '#000000',
    opacity: 1,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    verticalAlign: 'middle'
  });
  const [zPosition, setZPosition] = useState(null);
  const [hasPlaceholders, setHasPlaceholders] = useState(false);

  // Get the element configuration from the template
  const elementConfig = React.useMemo(() =>
    selectedElementInfo?.templateConfig?.elements?.[selectedElementInfo.element]
    , [selectedElementInfo?.element, selectedElementInfo?.templateConfig]);

  const elementNode = React.useMemo(() =>
    selectedElementInfo?.elementNode
    , [selectedElementInfo?.elementNode]);

  const elementType = React.useMemo(() =>
    selectedElementInfo?.elementType
    , [selectedElementInfo?.elementType]);

  // Initialize element properties when selection changes
  useEffect(() => {
    if (!elementNode || !elementConfig) return;

    // Prevent unnecessary updates by checking if the node has actually changed
    const newProperties = {};

    if (elementType === 'text') {
      newProperties.text = elementNode.getAttr('originalText') ?? (elementNode.text() || '');
      newProperties.fontFamily = elementNode.fontFamily() || 'Arial';
      newProperties.fontSize = elementNode.fontSize() || 20;
      newProperties.color = elementNode.fill() || '#000000';
      newProperties.fontStyle = elementNode.attrs.fontStyle || 'normal';
      newProperties.letterSpacing = elementNode.letterSpacing() || 0;
      newProperties.textAlign = elementNode.align() || 'left';
      newProperties.verticalAlign = elementNode.verticalAlign() || 'middle';

      // Check if text contains placeholders
      setHasPlaceholders(checkForPlaceholders(newProperties.text));
    } else if (elementType === 'rect' || elementType === 'circle') {
      newProperties.fill = elementNode.fill() || '#000000';
      newProperties.stroke = elementNode.stroke() || '';
      newProperties.strokeWidth = elementNode.strokeWidth() || 0;
    } else if (elementType === 'image') {
      // For images, we might want to show opacity or filters
      newProperties.opacity = elementNode.opacity() || 1;
    }

    // Only update state if values have changed
    if (JSON.stringify(newProperties) !== JSON.stringify(elementProperties)) {
      setElementProperties(newProperties);
    }

    // Update z-position information
    if (selectedElementInfo.getElementZPosition) {
      const position = selectedElementInfo.getElementZPosition(selectedElementInfo.element);
      setZPosition(position);
    }
  }, [elementNode, elementConfig, elementType, selectedElementInfo]);

  // Initialize z-position when component mounts or when selectedElementInfo changes
  useEffect(() => {
    if (selectedElementInfo && selectedElementInfo.element !== null && selectedElementInfo.getElementZPosition) {
      const position = selectedElementInfo.getElementZPosition(selectedElementInfo.element);
      setZPosition(position);
    }
  }, [selectedElementInfo]);

  // Check if text contains any placeholders
  const checkForPlaceholders = (text) => {
    if (!text) return false;
    const placeholderRegex = /{{[^{}]+}}/g;
    return placeholderRegex.test(text);
  };

  // Insert placeholder at cursor position or append to end
  const insertPlaceholder = (placeholder) => {
    if (!elementNode) return;

    // Get the textarea element
    const textarea = document.querySelector(`.${styles.textArea}`);
    if (!textarea) return;

    const currentText = elementProperties.text;
    let newText;

    // If textarea is focused, insert at cursor position
    if (document.activeElement === textarea) {
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;

      newText = currentText.substring(0, startPos) + placeholder + currentText.substring(endPos);

      // Set cursor position after the inserted placeholder
      setTimeout(() => {
        textarea.focus();
        textarea.setSelectionRange(startPos + placeholder.length, startPos + placeholder.length);
      }, 0);
    } else {
      // If not focused, append to the end
      newText = currentText ? `${currentText} ${placeholder}` : placeholder;
    }

    // Update state with new text
    setElementProperties(prev => ({ ...prev, text: newText }));

    // Update placeholder state
    setHasPlaceholders(true);

    // Update the canvas through handleTextChange
    handleTextChange({ target: { value: newText } });
  };

  // Update element text content
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setElementProperties(prev => ({ ...prev, text: newText }));

    // Check if text contains placeholders
    setHasPlaceholders(checkForPlaceholders(newText));

    if (elementNode) {
      // Process any placeholders in the text
      elementNode.setAttr('originalText', newText);
      const processedText = processTextPlaceholders(newText, {
        productName: draftConfiguration?.productName || productOnDraft?.name || '',
        productPrice: formatPrice(productOnDraft?.price || 0, productOnDraft?.currency || currency),
        companyName: companyName || '',
        discountAmount: discount?.amount + (discount?.type === 'AMOUNT' ? formatPrice(discount?.amount || 0, currency) : "%"),
        discountCode: discount?.code || '',
      });

      elementNode.text(processedText);

      // Calculate font metrics for height adjustment
      recalculateHeightOfText({ elementNode, text: processedText });

      elementNode.getLayer().batchDraw();

      // Save to history - save the original text with placeholders
      addToHistory(`element_${selectedElementInfo.element}`, {
        text: newText,
        height: elementNode.height()
      });
    }
  };

  const recalculateHeightOfText = ({ elementNode, text }) => {
    const fontSize = elementNode.fontSize();
    const lineHeight = fontSize * 1.2;

    // Calculate height based on text wrapping within the container width
    const containerWidth = elementNode.width();
    let newHeight;

    if (containerWidth) {
      // Create a temporary canvas element to measure text dimensions
      const tempCanvas = document.createElement('canvas');
      const context = tempCanvas.getContext('2d');

      // Set the font properties to match the text element
      context.font = `${elementNode.attrs.fontStyle || ''} ${fontSize}px ${elementNode.fontFamily() || 'Arial'}`;

      // Split text into words and calculate wrapped lines
      const words = text.split(' ');
      const lines = [];
      let currentLine = words[0] || '';

      for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const testLine = currentLine + ' ' + word;
        const metrics = context.measureText(testLine);

        if (metrics.width > containerWidth) {
          lines.push(currentLine);
          currentLine = word;
        } else {
          currentLine = testLine;
        }
      }

      // Add the last line
      if (currentLine) {
        lines.push(currentLine);
      }

      // Handle explicit line breaks in the text
      const explicitLineBreaks = text.split('\n');
      let totalLines = 0;

      if (explicitLineBreaks.length > 1) {
        // Process each explicit line separately for word wrapping
        explicitLineBreaks.forEach(lineText => {
          if (!lineText.trim()) {
            // Count empty lines as one line
            totalLines += 1;
            return;
          }

          // Calculate word wrapping for this explicit line
          const lineWords = lineText.split(' ');
          let lineCurrent = lineWords[0] || '';
          let lineCount = lineCurrent ? 1 : 0;

          for (let i = 1; i < lineWords.length; i++) {
            const lineWord = lineWords[i];
            const lineTestLine = lineCurrent + ' ' + lineWord;
            const lineMetrics = context.measureText(lineTestLine);

            if (lineMetrics.width > containerWidth) {
              lineCount++;
              lineCurrent = lineWord;
            } else {
              lineCurrent = lineTestLine;
            }
          }

          totalLines += lineCount;
        });
      } else {
        // No explicit line breaks, use the lines calculated earlier
        totalLines = lines.length;
      }

      // Calculate height based on number of lines
      newHeight = totalLines * lineHeight + (fontSize * 0.5);
    } else {
      // Fallback to simple line count if width is not available
      const lines = text.split('\n');
      newHeight = lines.length * lineHeight + (fontSize * 0.5);
    }

    elementNode.height(newHeight);
  }

  // Update element font family
  const handleFontFamilyChange = (e) => {
    const newFontFamily = e.target.value;
    setElementProperties(prev => ({ ...prev, fontFamily: newFontFamily }));

    if (elementNode) {
      elementNode.fontFamily(newFontFamily);
      recalculateHeightOfText({ elementNode, text: elementNode.text() });
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        fontFamily: newFontFamily,
        height: elementNode.height()
      });
    }
  };

  // Update element font size
  const handleFontSizeChange = (e) => {
    const newFontSize = parseInt(e.target.value, 10);
    if (isNaN(newFontSize)) return;

    setElementProperties(prev => ({ ...prev, fontSize: newFontSize }));

    if (elementNode) {
      elementNode.fontSize(newFontSize);
      recalculateHeightOfText({ elementNode, text: elementNode.text() });
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        fontSize: newFontSize,
        height: elementNode.height()
      });
    }
  };

  // Update element letter spacing
  const handleLetterSpacingChange = (e) => {
    const newLetterSpacing = parseFloat(e.target.value);
    if (isNaN(newLetterSpacing)) return;

    setElementProperties(prev => ({ ...prev, letterSpacing: newLetterSpacing }));

    if (elementNode) {
      elementNode.letterSpacing(newLetterSpacing);
      recalculateHeightOfText({ elementNode, text: elementNode.text() });
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        letterSpacing: newLetterSpacing,
        height: elementNode.height()
      });
    }
  };

  // Update text alignment
  const handleTextAlignChange = (newAlign) => {
    setElementProperties(prev => ({ ...prev, textAlign: newAlign }));

    if (elementNode) {
      elementNode.align(newAlign);
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        textAlign: newAlign
      });
    }
  };

  // Update vertical alignment
  const handleVerticalAlignChange = (newVerticalAlign) => {
    setElementProperties(prev => ({ ...prev, verticalAlign: newVerticalAlign }));

    if (elementNode) {
      elementNode.verticalAlign(newVerticalAlign);
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        verticalAlign: newVerticalAlign
      });
    }
  };

  // Update element color
  const handleColorChange = (color) => {
    if (!color || !color.hex) return;

    const newColor = color.hex;
    setElementProperties(prev => ({
      ...prev,
      color: newColor,
      fill: newColor
    }));

    if (elementNode) {
      if (elementType === 'text') {
        elementNode.fill(newColor);
      } else if (elementType === 'rect' || elementType === 'circle') {
        elementNode.fill(newColor);
      }

      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        fill: newColor
      });
    }
  };

  // Toggle bold text
  const toggleBold = () => {
    let newFontStyle;

    // Handle combined states
    if (elementProperties.fontStyle === 'normal' || !elementProperties.fontStyle) {
      newFontStyle = 'bold';
    } else if (elementProperties.fontStyle === 'bold') {
      newFontStyle = 'normal';
    } else if (elementProperties.fontStyle === 'italic') {
      newFontStyle = 'italic bold';
    } else if (elementProperties.fontStyle === 'italic bold') {
      newFontStyle = 'italic';
    }

    setElementProperties(prev => ({ ...prev, fontStyle: newFontStyle }));

    if (elementNode) {
      elementNode.setAttr('fontStyle', newFontStyle);
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        fontStyle: newFontStyle
      });
    }
  };

  // Toggle italic text
  const toggleItalic = () => {
    let newFontStyle;

    // Handle combined states
    if (elementProperties.fontStyle === 'normal' || !elementProperties.fontStyle) {
      newFontStyle = 'italic';
    } else if (elementProperties.fontStyle === 'italic') {
      newFontStyle = 'normal';
    } else if (elementProperties.fontStyle === 'bold') {
      newFontStyle = 'italic bold';
    } else if (elementProperties.fontStyle === 'italic bold') {
      newFontStyle = 'bold';
    }

    setElementProperties(prev => ({ ...prev, fontStyle: newFontStyle }));

    if (elementNode) {
      elementNode.setAttr('fontStyle', newFontStyle);
      elementNode.getLayer().batchDraw();

      // Save to history
      addToHistory(`element_${selectedElementInfo.element}`, {
        fontStyle: newFontStyle
      });
    }
  };

  // Render controls based on element type
  const renderElementControls = () => {
    if (!elementType) return null;

    return (
      <>
        {/* Element-specific controls */}
        {(() => {
          switch (elementType) {
            case 'text':
              return renderTextControls();
            case 'rect':
            case 'circle':
              return renderShapeControls();
            case 'image':
              return renderImageControls();
            default:
              return <div>No specific controls available for this element type.</div>;
          }
        })()}
      </>
    );
  };

  // Render Layer Position controls (common for all element types)
  const renderLayerControls = () => {
    return (
      <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
        <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
          Layer Controls
        </div>
        <div className={`${toolbarStyles.toolbarBoxInsideItemContent} ${styles.textStyleControls}`}>
          <button
            className={styles.styleButton}
            onClick={handleBringToFront}
            disabled={zPosition?.isTop}
            title="Move to front"
          >
            <BsArrowUpSquare className={styles.icon} />
          </button>
          <button
            className={styles.styleButton}
            onClick={handleBringForward}
            disabled={zPosition?.isTop}
            title="Move forward"
          >
            <BsArrowUp className={styles.icon} />
          </button>
          <button
            className={styles.styleButton}
            onClick={handleSendBackward}
            disabled={zPosition?.isBottom}
            title="Move backward"
          >
            <BsArrowDown className={styles.icon} />
          </button>
          <button
            className={styles.styleButton}
            onClick={handleSendToBack}
            disabled={zPosition?.isBottom}
            title="Move to back"
          >
            <BsArrowDownSquare className={styles.icon} />
          </button>
        </div>
      </div>
    );
  };

  // Render controls for text elements
  const renderTextControls = () => {
    return (
      <>
        {/* Text Content */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${toolbarStyles.toolbarBoxInsideItemVertical} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Content
          </div>
          <div className={toolbarStyles.toolbarBoxInsideItemContentColumn}>
            <textarea
              className={`${toolbarStyles.toolbarBoxInsideItemContentInput} ${styles.textArea} ${hasPlaceholders ? styles.hasPlaceholders : ''}`}
              value={elementProperties.text}
              onChange={handleTextChange}
              rows={3}
            />
            <div className={styles.placeholderHelper}>
              <div className={styles.placeholderHelperTitle}>
                Available placeholders:
              </div>
              <div className={styles.placeholderList}>
                <span className={styles.placeholderTag} onClick={() => insertPlaceholder('{{productName}}')}>&#123;&#123;productName&#125;&#125;</span>
                <span className={styles.placeholderTag} onClick={() => insertPlaceholder('{{productPrice}}')}>&#123;&#123;productPrice&#125;&#125;</span>
                <span className={styles.placeholderTag} onClick={() => insertPlaceholder('{{companyName}}')}>&#123;&#123;companyName&#125;&#125;</span>
              </div>
            </div>
          </div>
        </div>

        {/* Font Family */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Font Family
          </div>
          <div className={toolbarStyles.toolbarBoxInsideItemContent}>
            <select
              className={toolbarStyles.toolbarBoxInsideItemContentSelect}
              value={elementProperties.fontFamily}
              onChange={handleFontFamilyChange}
            >
              {fontFamilies.map(family => (
                <option key={family} value={family}>{family}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Text Style Controls */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Text Style
          </div>
          <div className={`${toolbarStyles.toolbarBoxInsideItemContent} ${styles.textStyleControls}`}>
            <button
              className={`${styles.styleButton} ${elementProperties.fontStyle?.includes('bold') ? styles.styleButtonActive : ''}`}
              onClick={toggleBold}
              title="Bold"
            >
              B
            </button>
            <button
              className={`${styles.styleButton} ${elementProperties.fontStyle?.includes('italic') ? styles.styleButtonActive : ''}`}
              onClick={toggleItalic}
              title="Italic"
            >
              I
            </button>
          </div>
        </div>

        {/* Text Alignment */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Text Alignment
          </div>
          <div className={`${toolbarStyles.toolbarBoxInsideItemContent} ${styles.textStyleControls}`}>
            <button
              className={`${styles.styleButton} ${elementProperties.textAlign === 'left' ? styles.styleButtonActive : ''}`}
              onClick={() => handleTextAlignChange('left')}
              title="Left align"
            >
              <BsTextLeft />
            </button>
            <button
              className={`${styles.styleButton} ${elementProperties.textAlign === 'center' ? styles.styleButtonActive : ''}`}
              onClick={() => handleTextAlignChange('center')}
              title="Center align"
            >
              <BsTextCenter />
            </button>
            <button
              className={`${styles.styleButton} ${elementProperties.textAlign === 'right' ? styles.styleButtonActive : ''}`}
              onClick={() => handleTextAlignChange('right')}
              title="Right align"
            >
              <BsTextRight />
            </button>
          </div>
        </div>

        {/* Vertical Alignment */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Vertical Alignment
          </div>
          <div className={`${toolbarStyles.toolbarBoxInsideItemContent} ${styles.textStyleControls}`}>
            <button
              className={`${styles.styleButton} ${elementProperties.verticalAlign === 'top' ? styles.styleButtonActive : ''}`}
              onClick={() => handleVerticalAlignChange('top')}
              title="Top align"
            >
              <BsArrowUp />
            </button>
            <button
              className={`${styles.styleButton} ${elementProperties.verticalAlign === 'middle' ? styles.styleButtonActive : ''}`}
              onClick={() => handleVerticalAlignChange('middle')}
              title="Middle align"
            >
              <BsDash />
            </button>
            <button
              className={`${styles.styleButton} ${elementProperties.verticalAlign === 'bottom' ? styles.styleButtonActive : ''}`}
              onClick={() => handleVerticalAlignChange('bottom')}
              title="Bottom align"
            >
              <BsArrowDown />
            </button>
          </div>
        </div>

        {/* Font Size */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Font Size
          </div>
          <div className={`${toolbarStyles.toolbarBoxInsideItemContent} ${styles.textStyleControls}`}>
            <button
              className={styles.styleButton}
              onClick={() => {
                handleFontSizeChange({ target: { value: elementProperties.fontSize - 10 } });
              }}
            >
              -
            </button>
            <button
              className={styles.styleButton}
              onClick={() => {
                handleFontSizeChange({ target: { value: elementProperties.fontSize + 10 } });
              }}
            >
              +
            </button>
          </div>
        </div>

        {/* Letter Spacing */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Letter Spacing
          </div>
          <div className={`${toolbarStyles.toolbarBoxInsideItemContent} ${styles.textStyleControls}`}>
            <button
              className={styles.styleButton}
              onClick={() => {
                const newLetterSpacing = Math.max(-10, elementProperties.letterSpacing - 4);
                handleLetterSpacingChange({ target: { value: newLetterSpacing } });
              }}
            >
              -
            </button>
            <button
              className={styles.styleButton}
              onClick={() => {
                const newLetterSpacing = Math.min(30, elementProperties.letterSpacing + 4);
                handleLetterSpacingChange({ target: { value: newLetterSpacing } });
              }}
            >
              +
            </button>
          </div>
        </div>

        {/* Text Color */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Text Color
          </div>
          <div className={toolbarStyles.toolbarBoxInsideItemContent}>
            <div
              className={toolbarStyles.toolbarBoxInsideItemContentColor}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <div
                className={toolbarStyles.toolbarBoxInsideItemContentColorBox}
                style={{ backgroundColor: elementProperties.color || '#000000' }}
              />
              <div className={toolbarStyles.toolbarBoxInsideItemContentColorInput}>
                {(elementProperties.color || '#000000').toUpperCase()}
              </div>
            </div>
          </div>
        </div>

        {/* Color Picker */}
        {showColorPicker && (
          <div className={styles.colorPickerContainer}>
            <SketchPicker
              color={elementProperties.color || '#000000'}
              onChangeComplete={handleColorChange}
            />
            <button
              className={styles.closePickerButton}
              onClick={() => setShowColorPicker(false)}
            >
              Close
            </button>
          </div>
        )}
      </>
    );
  };

  // Render controls for shape elements (rect, circle)
  const renderShapeControls = () => {
    return (
      <>
        {/* Fill Color */}
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Fill Color
          </div>
          <div className={toolbarStyles.toolbarBoxInsideItemContent}>
            <div
              className={toolbarStyles.toolbarBoxInsideItemContentColor}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <div
                className={toolbarStyles.toolbarBoxInsideItemContentColorBox}
                style={{ backgroundColor: elementProperties.fill || '#000000' }}
              />
              <div className={toolbarStyles.toolbarBoxInsideItemContentColorInput}>
                {(elementProperties.fill || '#000000').toUpperCase()}
              </div>
            </div>
          </div>
        </div>

        {/* Color Picker */}
        {showColorPicker && (
          <div className={styles.colorPickerContainer}>
            <SketchPicker
              color={elementProperties.fill || '#000000'}
              onChangeComplete={handleColorChange}
            />
            <button
              className={styles.closePickerButton}
              onClick={() => setShowColorPicker(false)}
            >
              Close
            </button>
          </div>
        )}
      </>
    );
  };

  // Render controls for image elements
  const renderImageControls = () => {
    return (
      <>
        <div className={`${toolbarStyles.toolbarBoxInsideItem} ${styles.elementControlsItem}`}>
          <div className={toolbarStyles.toolbarBoxInsideItemTitle}>
            Opacity
          </div>
          <div className={toolbarStyles.toolbarBoxInsideItemContent}>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={elementProperties.opacity || 1}
              onChange={(e) => {
                const newOpacity = parseFloat(e.target.value);
                setElementProperties(prev => ({ ...prev, opacity: newOpacity }));

                if (elementNode) {
                  elementNode.opacity(newOpacity);
                  elementNode.getLayer().batchDraw();

                  // Save to history
                  addToHistory(`element_${selectedElementInfo.element}`, {
                    opacity: newOpacity
                  });
                }
              }}
              className={styles.rangeInput}
            />
            <span className={styles.opacityValue}>
              {Math.round((elementProperties.opacity || 1) * 100)}%
            </span>
          </div>
        </div>
      </>
    );
  };

  // Z-index control handlers
  const handleBringToFront = () => {
    if (selectedElementInfo.bringElementToFront && selectedElementInfo.element !== null) {
      try {
        selectedElementInfo.bringElementToFront(selectedElementInfo.element);
      } catch (error) {
        // Error handling silently
      }

      // Update z-position after the operation
      setTimeout(() => {
        if (selectedElementInfo.getElementZPosition) {
          try {
            const position = selectedElementInfo.getElementZPosition(selectedElementInfo.element);
            setZPosition(position);
          } catch (error) {
            // Error handling silently
          }
        }
      }, 0);
    }
  };

  const handleBringForward = () => {
    if (selectedElementInfo.bringElementForward && selectedElementInfo.element !== null) {
      try {
        selectedElementInfo.bringElementForward(selectedElementInfo.element);
      } catch (error) {
        // Error handling silently
      }

      // Update z-position after the operation
      setTimeout(() => {
        if (selectedElementInfo.getElementZPosition) {
          try {
            const position = selectedElementInfo.getElementZPosition(selectedElementInfo.element);
            setZPosition(position);
          } catch (error) {
            // Error handling silently
          }
        }
      }, 0);
    }
  };

  const handleSendBackward = () => {
    if (selectedElementInfo.sendElementBackward && selectedElementInfo.element !== null) {
      try {
        selectedElementInfo.sendElementBackward(selectedElementInfo.element);
      } catch (error) {
        // Error handling silently
      }

      // Update z-position after the operation
      setTimeout(() => {
        if (selectedElementInfo.getElementZPosition) {
          try {
            const position = selectedElementInfo.getElementZPosition(selectedElementInfo.element);
            setZPosition(position);
          } catch (error) {
            // Error handling silently
          }
        }
      }, 0);
    }
  };

  const handleSendToBack = () => {
    if (selectedElementInfo.sendElementToBack && selectedElementInfo.element !== null) {
      try {
        selectedElementInfo.sendElementToBack(selectedElementInfo.element);
      } catch (error) {
        // Error handling silently
      }

      // Update z-position after the operation
      setTimeout(() => {
        if (selectedElementInfo.getElementZPosition) {
          try {
            const position = selectedElementInfo.getElementZPosition(selectedElementInfo.element);
            setZPosition(position);
          } catch (error) {
            // Error handling silently
          }
        }
      }, 0);
    }
  };

  // Handle element removal
  const handleRemoveElement = () => {
    if (!elementNode || !selectedElementInfo) return;

    // Get the element index
    const elementIndex = selectedElementInfo.element;

    // Get a reference to the layer before destroying the element
    const layer = elementNode.getLayer();

    // Get the stage
    const stage = elementNode.getStage();

    // Check if there's a direct reference to the transformer layer
    if (selectedElementInfo.transformerLayerRef) {
      // If we have a direct reference, use it
      const transformerLayer = selectedElementInfo.transformerLayerRef.current;
      if (transformerLayer) {
        const mainTransformer = transformerLayer.findOne('.Transformer');
        const textTransformer = transformerLayer.findOne('.TextTransformer');

        if (mainTransformer) {
          mainTransformer.nodes([]);
          mainTransformer.visible(false);
        }

        if (textTransformer) {
          textTransformer.nodes([]);
          textTransformer.visible(false);
        }

        transformerLayer.batchDraw();
      }
    }
    // Otherwise, find and remove transformers if stage exists
    else if (stage) {
      // Try multiple methods to find the transformer layer
      let transformerLayer = stage.findOne('.transformer-layer') || stage.findOne('#transformer-layer');

      // If we couldn't find by name, try to look for the layer containing transformers
      if (!transformerLayer) {
        // Try to find the layer containing transformers
        const layers = stage.getLayers();
        transformerLayer = layers.find(layer =>
          layer.findOne('.Transformer') || layer.findOne('.TextTransformer')
        );
      }

      // If we found a transformer layer, clean up the transformers
      if (transformerLayer) {
        const mainTransformer = transformerLayer.findOne('.Transformer');
        const textTransformer = transformerLayer.findOne('.TextTransformer');

        if (mainTransformer) {
          mainTransformer.nodes([]);
          mainTransformer.visible(false);
        }

        if (textTransformer) {
          textTransformer.nodes([]);
          textTransformer.visible(false);
        }

        // Update transformer layer
        transformerLayer.batchDraw();
      }
    }

    // Remove the element from the canvas
    elementNode.destroy();

    // Update the layer if it exists
    if (layer) {
      layer.batchDraw();
    }

    // Reset selection by updating the parent state
    // Direct setSelectedElement function might not be passed, so we need to do this indirectly
    // The component that uses ElementControls will detect that the element no longer exists
    // and update the selection accordingly

    // Update history if needed
    if (addToHistory) {
      addToHistory(`element_${elementIndex}`, {
        removed: true
      });
    }
  };

  // Handle element deselection
  const handleDeselectElement = () => {
    if (!elementNode || !selectedElementInfo) return;

    // Get the stage
    const stage = elementNode.getStage();

    // Check if there's a direct reference to the transformer layer
    if (selectedElementInfo.transformerLayerRef) {
      // If we have a direct reference, use it
      const transformerLayer = selectedElementInfo.transformerLayerRef.current;
      if (transformerLayer) {
        const mainTransformer = transformerLayer.findOne('.Transformer');
        const textTransformer = transformerLayer.findOne('.TextTransformer');

        if (mainTransformer) {
          mainTransformer.nodes([]);
          mainTransformer.visible(false);
        }

        if (textTransformer) {
          textTransformer.nodes([]);
          textTransformer.visible(false);
        }

        transformerLayer.batchDraw();
      }
    }
    // Otherwise, find and remove transformers if stage exists
    else if (stage) {
      // Try multiple methods to find the transformer layer
      let transformerLayer = stage.findOne('.transformer-layer') || stage.findOne('#transformer-layer');

      // If we couldn't find by name, try to look for the layer containing transformers
      if (!transformerLayer) {
        // Try to find the layer containing transformers
        const layers = stage.getLayers();
        transformerLayer = layers.find(layer =>
          layer.findOne('.Transformer') || layer.findOne('.TextTransformer')
        );
      }

      // If we found a transformer layer, clean up the transformers
      if (transformerLayer) {
        const mainTransformer = transformerLayer.findOne('.Transformer');
        const textTransformer = transformerLayer.findOne('.TextTransformer');

        if (mainTransformer) {
          mainTransformer.nodes([]);
          mainTransformer.visible(false);
        }

        if (textTransformer) {
          textTransformer.nodes([]);
          textTransformer.visible(false);
        }

        // Update transformer layer
        transformerLayer.batchDraw();
      }
    }

    // Reset selection in the parent component by setting the element info to null values
    if (selectedElementInfo.onDeselectElement) {
      selectedElementInfo.onDeselectElement();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      {/* Element Action Buttons */}
      <div className={styles.removeElementContainer}>
        <button
          className={`${styles.removeButton}`}
          onClick={handleRemoveElement}
          title="Remove Element"
        >
          <BsTrash className={styles.icon} /> Remove Element
        </button>

        <button
          className={`${styles.removeButton}`}
          onClick={handleDeselectElement}
          title="Deselect Element"
          style={{ marginTop: '8px' }}
        >
         Deselect Element
        </button>
      </div>

      {/* Element Controls */}
      <div className={`${styles.elementControlsContainer}`}>
        <div className={styles.elementControlsTitle}>
          <div>
            {elementType ? `${elementType.charAt(0).toUpperCase() + elementType.slice(1)} Controls` : 'Element Controls'}
          </div>
        </div>
        <div className={styles.elementControlsContent}>
          {renderElementControls()}
        </div>
      </div>

      {/* Layer Controls */}
      <div className={`${styles.elementControlsContainer}`}>
        <div className={styles.elementControlsTitle}>
          <div>
            Layer Controls
          </div>
        </div>
        <div className={styles.elementControlsContent}>
          {renderLayerControls()}
        </div>
      </div>
    </div>
  );
};

export default ElementControls;