import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import PropTypes from 'prop-types'
import './IntroHeadlineContainer.css'
import blur from '../../../shared/assets/images/intro/blur.svg'
import enhencerLogo from '../../../shared/assets/images/intro/enhencer-logo.svg'

const IntroHeadlineContainer = ({
  headlines,
  animationEnabled = true,
  animationDuration = 3000,
  showEnhencerLogo = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (animationEnabled) {
      const headlineInterval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % headlines.length)
      }, animationDuration)

      return () => clearInterval(headlineInterval)
    }
  }, [headlines.length, animationEnabled, animationDuration])

  const renderHeadline = (headline, index) => (
    <h1 className="intro-headline" key={index}>
      {headline.text && <span>{headline.text}</span>}
      {headline.highlight && (
        <span className="highlight">{headline.highlight}</span>
      )}
      {headline.text2 && <span> {headline.text2}</span>}
    </h1>
  )

  return (
    <>
      {showEnhencerLogo && (
        <img src={enhencerLogo} alt="Enhencer Logo" className="intro-logo" />
      )}

      <div className="intro-headline-container">
        <img src={blur} alt="Blur" className="intro-blur" />
        <AnimatePresence mode="wait">
          {animationEnabled ? (
            <motion.div
              key={currentIndex}
              className="intro-headline-wrapper"
              initial={{ opacity: 0, y: '50%' }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: '-50%' }}
              transition={{
                duration: 0.6,
                ease: [0.43, 0.13, 0.23, 0.86],
              }}
            >
              {renderHeadline(headlines[currentIndex], currentIndex)}
            </motion.div>
          ) : (
            <div className="intro-headline-wrapper">
              {headlines.map((headline, index) =>
                renderHeadline(headline, index),
              )}
            </div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

IntroHeadlineContainer.propTypes = {
  headlines: PropTypes.arrayOf(
    PropTypes.shape({
      highlight: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  animationEnabled: PropTypes.bool,
  showEnhencerLogo: PropTypes.bool,
  animationDuration: PropTypes.number,
}

export default IntroHeadlineContainer
