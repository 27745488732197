import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaCheck } from 'react-icons/fa'
import IntroHeadlineContainer from '../components/intro/IntroHeadlineContainer'
import StrokeInput from '../../shared/components/FormElements/StrokeInput'
import Button from '../../shared/components/FormElements/Button'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import backgroundImage from '../../shared/assets/images/register-decor/register-computer-bg.png'

import countriesData from '../../shared/data/countries.json'
import helper from '../../shared/util/helper'
import analytics from '../../shared/util/analytics'
import BLOCKED_COUNTRY_CODES from '../../shared/data/blocked-country-phone-codes.json'

import {
  VALIDATOR_COUNTRY_CODE,
  VALIDATOR_PHONE_ONLY_DIGITS,
  VALIDATOR_REQUIRE,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import { authActions } from '../../store/auth'
import { generalActions } from '../../store/general'

import './CompleteRegistrationPhone.css'
import './Auth.css'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'
import { useUser } from '../../shared/hooks/user-hook'
import cookies from '../../shared/util/cookies'
import { useAuth } from '../../shared/hooks/auth-hook'

const CompleteRegistrationPhone = () => {
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { error, sendRequest, clearError } = useHttpClient()
  const navigate = useNavigate()
  const { setUtmsOfSignup } = useUser()
  const { completePhoneRegistration } = useAuth()
  const [countryError, setCountryError] = useState('')
  const [verifyNumberError, setVerifyNumberError] = useState('')
  const [busy, setBusy] = useState(false)
  const isMounted = useRef(true)
  const [shouldSkipVerification, setShouldSkipVerification] = useState(
    process.env.NODE_ENV !== 'production',
  )

  const prioritizedCountries = [
    'United States',
    'Canada',
    'United Kingdom',
    'Australia',
  ]
  const headlines = [
    {
      text: 'Almost there!  Please provide your ',
      highlight: 'Phone Number',
    },
  ]
  const countries = useMemo(() => {
    const prioritized = countriesData
      .filter(country => prioritizedCountries.includes(country.name))
      .sort(
        (a, b) =>
          prioritizedCountries.indexOf(a.name) -
          prioritizedCountries.indexOf(b.name),
      )
      .map(country => country.name)

    const others = countriesData
      .filter(country => !prioritizedCountries.includes(country.name))
      .map(country => country.name)

    return [...prioritized, ...others]
  }, [countriesData])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (auth.user.completedRegistration) {
      navigate('/')
    } else {
      const utmSource = cookies.get('utm_source')
      const utmCampaign = cookies.get('utm_campaign')
      const utmMedium = cookies.get('utm_medium')
      const utmTerm = cookies.get('utm_term')

      if (!auth.user?.signupDetails?.utms?.utm_source && utmSource) {
        setUtmsOfSignup({
          utms: {
            utm_source: utmSource,
            utm_campaign: utmCampaign,
            utm_medium: utmMedium,
            utm_term: utmTerm,
          },
        })
      }
    }

  
  }, [auth.user, navigate, setUtmsOfSignup])

  useEffect(() => { 
    
    handleAppClass(true)

    return () => {

      handleAppClass(false)
    }
  }, [])

  const initialFormState = {
    country: {
      value: auth.user?.country || '',
      isValid: false,
    },
    contactNumber: {
      value: auth.user?.contactNumber || '',
      isValid: !!auth.user?.contactNumber,
    },
    contactNumberCountryCode: {
      value:
        auth.user?.contactNumberCountryCode ||
        (auth.user?.country
          ? helper.getCountryPhonePrefix(auth.user?.country)
          : ''),
      isValid: !!auth.user?.country || !!auth.user?.contactNumberCountryCode,
    },
  }

  const [formState, inputHandler] = useForm(initialFormState, false)

  const handleAppClass = t => {
    const app = document.getElementById('app')
    if (app) {
      app.classList.toggle('full-screen-noscroll', t)
    }
  }

  const submit = async event => {
    event.preventDefault()
    let countryError = false
    let verificationError = false

    if (formState.inputs.country.value === '') {
      countryError = true
      setCountryError('Please select your country')
    } else {
      countryError = false
      setCountryError('')
    }

    if (
      !auth.user?.contactNumberVerifiedAt &&
      process.env.NODE_ENV === 'production'
    ) {
      verificationError = true
      setVerifyNumberError('Please verify your phone number')
    } else {
      verificationError = false
      setVerifyNumberError('')
    }

    if (countryError || verificationError) return

    setBusy(true)
    try {
      await completePhoneRegistration({
        country: formState.inputs.country.value,
      })
    } catch (err) {
      errorHandler(err)
    } finally {
      if (isMounted.current) {
        setBusy(false)
      }
    }
  }

  const logout = () => {
    dispatch(authActions.logout({}))
    dispatch(generalActions.setPageTitle({ pageTitle: null }))
  }

  const handleSMSVerificationButton = () => {
    if (process.env.NODE_ENV === 'production') {
      if (auth.user?.contactNumberVerifiedAt) {
        return
      }

      const contactNumber = formState.inputs.contactNumber.value
      const contactNumberCountryCode =
        formState.inputs.contactNumberCountryCode.value

      if (BLOCKED_COUNTRY_CODES.includes(contactNumberCountryCode)) {
        return toast.info(
          'Unfortunately, we are currently not available in your country. We hope to be available soon. Thank you for your patience!',
          {
            autoClose: 6000,
          },
        )
      }

      openModal({
        type: Modals.SMS_VERIFICATION,
        closeOnClickOutside: true,
        contactNumber,
        contactNumberCountryCode,
      })
    }
  }

  const isNumberVerified = useMemo(() => {
    const currentContactNumber =
      auth.user?.contactNumberCountryCode + auth.user?.contactNumber
    const contactNumberInForm =
      formState.inputs.contactNumberCountryCode.value +
      formState.inputs.contactNumber.value

    return (
      (currentContactNumber === contactNumberInForm &&
        auth.user?.contactNumberVerifiedAt) ||
      shouldSkipVerification
    )
  }, [
    auth.user?.contactNumber,
    auth.user?.contactNumberCountryCode,
    formState.inputs.contactNumber.value,
    formState.inputs.contactNumberCountryCode.value,
    shouldSkipVerification,
  ])

  useEffect(() => {
    const selectedCountry = formState.inputs.country.value

    if (!selectedCountry) {
      return
    }

    const countryCode = helper.getCountryPhonePrefix(selectedCountry)
    if (countryCode) {
      inputHandler('contactNumberCountryCode', countryCode, true)
    }
  }, [formState.inputs.country.value, inputHandler])

  const isFormValid = useMemo(() => {
    return (
      formState.inputs.country.isValid &&
      formState.inputs.contactNumber.isValid &&
      formState.inputs.contactNumberCountryCode.isValid &&
      isNumberVerified
    )
  }, [formState.inputs, isNumberVerified])
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="full-container complete-registration">
        <div className="form-section">
          <div className="login-cont">
            <IntroHeadlineContainer
              headlines={headlines}
              animationEnabled={false}
            />
            <form onSubmit={submit}>
              <StrokeInput
                forceValue={initialFormState.country.value}
                element="select"
                id="country"
                label="Select your country"
                onInput={inputHandler}
                options={[
                  { value: '', label: 'Select a country' },
                  ...countries.map(country => ({
                    value: country,
                    label: country,
                  }))
                ]}
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please select a country"
              />
              <div className="country error-text"></div>

              <div className="contact-number-wrapper">
                <div className="input-row">
                  <StrokeInput
                    label="Code"
                    initialValue={
                      initialFormState.contactNumberCountryCode.value
                    }
                    forceValue={formState.inputs.contactNumberCountryCode.value}
                    element="input"
                    id="contactNumberCountryCode"
                    type="text"
                    onInput={inputHandler}
                    validators={[VALIDATOR_COUNTRY_CODE()]}
                    errorText="Please enter a valid country code"
                    placeholder="+1"
                    readOnly
                  />
                  <StrokeInput
                    label="Phone Number"
                    initialValue={initialFormState.contactNumber.value}
                    forceValue={formState.inputs.contactNumber.value}
                    element="input"
                    id="contactNumber"
                    type="tel"
                    validators={[
                      VALIDATOR_PHONE_ONLY_DIGITS(),
                      VALIDATOR_REQUIRE(),
                    ]}
                    errorText="Please enter a valid phone number"
                    onInput={inputHandler}
                  />
                  <button
                    disabled={
                      !formState.inputs.contactNumber.isValid ||
                      !formState.inputs.contactNumberCountryCode.isValid ||
                      !formState.inputs.contactNumberCountryCode.value
                    }
                    onClick={handleSMSVerificationButton}
                    className={`verify-button ${isNumberVerified ? 'verified' : ''
                      }`}
                    type="button"
                  >
                    {isNumberVerified ? (
                      <>
                        <span>Verified</span>
                        <FaCheck />
                      </>
                    ) : (
                      'Verify'
                    )}
                  </button>
                </div>
                {verifyNumberError && (
                  <div className="verify error-text">{verifyNumberError}</div>
                )}
              </div>

              <div className="cont">
                <Button
                  loading={busy}
                  className="action-button"
                  type="submit"
                  disabled={!isFormValid}
                >
                  Continue
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="almost-done-section">
          <img className="background" src={backgroundImage} alt="Background" />
        </div>
      </div>
    </React.Fragment>
  )
}

export default CompleteRegistrationPhone
