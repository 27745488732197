import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import shopifyIcon from '../../../shared/assets/images/icons/shopify-bag.png'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { projectActions } from '../../../store/project'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import { useConstants } from '../../../shared/hooks/constants-hook'
import { useModal } from '../../../shared/hooks/modal-hook'
import Modals from '../../../shared/enums/Modals'

import './AdminUserDetailName.css'
import errorHandler from '../../../shared/util/errorHandler'

const AdminUserDetailName = ({ isSignUp }) => {
  const { privateConstants: constants } = useConstants()
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [moveToLeadsNotificationVisible, setMoveToLeadsNotificationVisible] = useState(false)
  const [moveToWelcomeNotificationVisible, setMoveToWelcomeNotificationVisible] = useState(false)
  const { startViewingAsUser, loginToUser } = useAdmin()

  const { pathname } = useLocation()
  const userId = pathname.split('/')[3]
  const [displayName, setDisplayName] = useState()

  const { openModal, closeModal } = useModal()

  useEffect(() => {
    let dName = customer.shopify
      ? customer.shopify.name || customer.shopify.domain
      : customer.url || customer.name

    dName = dName?.replace('https://', '').replace('.myshopify.com', '')
    if (dName && dName[dName.length - 1] === '/') {
      dName = dName.substring(0, dName.length - 1)
    }

    setDisplayName(dName)

  }, [customer])

  const areYouSureMoveToLeads = async () => {
    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: `Are you sure to move "${customer.email}" customer to leads?`,
    })
    
    if (confirmed) {
      moveToLeads()
    }
  }

  const moveToLeads = () => {
    const data = {
      'leadDetails.isWon': false,
      'leadDetails.funnel': constants.leadFunnelSteps,
      'crmDetails.subscription.status': 'No subscription',
      'crmDetails.funnel': constants.funnel,
    }

    setMoveToLeadsNotificationVisible(true)
    setTimeout(() => {
      setMoveToLeadsNotificationVisible(false)

      try {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userId}`,
          'PATCH',
          JSON.stringify(data),
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )
        toast.success('Changes saved!')
        navigate('/admin-panel')
      } catch (err) {
        errorHandler(err)
      }
    }, 1500)
  }

  const areYouSureMoveToWelcome = async () => {
    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: `Are you sure to move "${customer.email}" customer to welcome?`,
    })
    
    if (confirmed) {
      moveToWelcome()
    }
  }
  const moveToWelcome = () => {
    const data = {
      'leadDetails.isWon': true,
      'crmDetails.contractStartDate': new Date().toISOString(),
      'crmDetails.subscription.status': 'Welcome',
      'crmDetails.subscription.price': 500,
      'crmDetails.subscription.currency': 'USD',
      'crmDetails.subscription.paymentInterval': 'ONE-TIME',
      'crmDetails.subscription.interval': 'ONE-TIME',
      'subscription.status': 'active',
      'subscription.createdDate': new Date().toISOString(),
      'subscription.lastPaymentDate': new Date().toISOString(),
      'subscription.plan': 'Welcome',
      'subscription.price': '500',
      'subscription.currency': 'USD',
      'subscription.pricingVersion': 'v5',
      'subscription.paymentInterval': 'ONE-TIME',
      'subscription.interval': 'ONE-TIME',
      'subscription.isSetByAdmin': true,
      'connectFlow.dataCollectionStarted': Date.now(),
    }

    setMoveToWelcomeNotificationVisible(true)
    setTimeout(() => {
      setMoveToWelcomeNotificationVisible(false)

      try {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userId}`,
          'PATCH',
          JSON.stringify(data),
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )
        toast.success('Changes saved!')
        navigate('/admin-panel')
      } catch (err) {
        errorHandler(err)
      }
    }, 1500)
  }

  const copyLink = () => {
    navigator.clipboard.writeText(userId)
    toast.info('Customer ID copied.')
  }

  const goToAudience = async () => {
    if (!customer.job) {
      toast.warn('Audience is not created yet!')
    } else if (customer.job) {
      if (!customer.isReady) {
        toast.warn('Audience creation in progress. Please wait for it.')
      } else {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${customer.id}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          )
          console.log("going to audience ", responseData)
          dispatch(
            projectActions.setAdminUserUsersCampaigns({
              campaigns: responseData.campaigns,
            }),
          )

          startViewingAsUser({
            user: customer,
            pageRoute: `/audience-event-manager`,
          })
        } catch (err) {
          errorHandler(err)
        }
      }
    }
  }


  const viewAsUser = () => {
    loginToUser({ user: customer })
  }

  const goToWebsite = () => {
    if (customer.shopify) {
      window.open("https://" + customer.shopify.domain, "_blank")
    } else if (customer.url) {
      let url = customer.url
      if (!url.includes("https://")) {
        url = "https://" + url
      }
      window.open(url, "_blank")
    } else {
      errorHandler({ message: "Website URL not defined." })
    }
  }

  return (
    <div className="user-name-area">
      <div className="domain">
        <span className="text" onClick={goToWebsite} title="Visit website">
          {displayName}
        </span>
        {customer.shopify && (
          <img
            src={shopifyIcon}
            alt="Shopify"
            width="40"
            height="40"
            className="shopify-icon"
          />
        )}
      </div>
      <ul className="actions">

        <li onClick={areYouSureMoveToLeads} className="link">
          Move to leads
        </li>
        {isSignUp && (
          <li onClick={areYouSureMoveToWelcome} className="link">
            Move to welcome
          </li>
        )}
        <li onClick={copyLink} className="link">
          Copy ID
        </li>
        <li onClick={goToAudience} className="link">
          Audience Page
        </li>
        <li onClick={viewAsUser} className="link">
          Go to Account
        </li>

      </ul>
      {moveToLeadsNotificationVisible && (
        <div className="move-to-leads-overlay">
          <div>Moving to leads...</div>
        </div>
      )}
      {moveToWelcomeNotificationVisible && (
        <div className="move-to-leads-overlay">
          <div>Moving to welcome...</div>
        </div>
      )}
    </div>
  )
}

export default AdminUserDetailName