import React, { useEffect, useMemo, useRef, useState } from 'react'
import Konva from 'konva'
import { useSelector } from 'react-redux'

import { WORKBENCH_SIZE } from '../../support/constants'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import {
  loadAndChangeSvgColor,
  formatPrice,
  resizeImageToFit,
  calculateBoxFitCover,
  centerTheElement,
} from '../../support/utils'
import DiscountTypes from '../../../shared/enums/DiscountTypes'

const PREVIEW_SIZE = {
  width: 200,
  height: 200,
}

const Preview = ({
  templateConfig,
  product,
  positions,
  setHistoriesByProductId,
}) => {
  const stageRef = useRef(null)
  const backgroundLayerRef = useRef(null)
  const productImageLayerRef = useRef(null)
  const secondBackgroundLayerRef = useRef(null)
  const itemsLayerRef = useRef(null)
  const transformerLayerRef = useRef(null)
  const productImageNodeRef = useRef(null)
  const companyLogoNodeRef = useRef(null)
  const foregroundLayerRef = useRef(null)
  const currency = useSelector(
    state => state.facebook?.selectedAdAccount?.currency ?? 'USD',
  )
  const {
    draftConfiguration,
    companyDetails,
    discount,
    isBatchSaving,
    currentSavingProductId,
    handleProductSaveComplete,
    savePreviewToProduct,
  } = useEnhencedCatalog()

  // Keep a useState for being sure about image loadings status for product image & company logo both is available
  const [loadedImages, setLoadedImages] = useState({
    background: null,
    secondBackgroundLayerSvg: null,
    productImage: null,
    companyLogo: null,
    foregroundSvg: null,
  })

  const currentPalette = useMemo(() => {
    return (
      draftConfiguration?.palette ?? {
        color1: '#FF6F61',
        color2: '#FFFFFF',
        color3: '#2C2C2C',
      }
    )
  }, [draftConfiguration.palette])

  const currentFontFamily = useMemo(() => {
    return {
      primary: draftConfiguration?.primaryFontFamily,
      secondary: draftConfiguration?.secondaryFontFamily,
    }
  }, [
    draftConfiguration.primaryFontFamily,
    draftConfiguration.secondaryFontFamily,
  ])

  const discountAmountText = useMemo(() => {
    return discount.amount
      ? discount.amount +
      (discount.type === DiscountTypes.AMOUNT
        ? formatPrice(discount.amount, currency)
        : '%')
      : '__'
  }, [discount])

  const resizeStage = () => {
    if (stageRef.current) {
      const containerWidth = PREVIEW_SIZE.width
      const containerHeight = PREVIEW_SIZE.height

      let horizontalScale = containerWidth / WORKBENCH_SIZE.width
      let verticalScale = containerHeight / WORKBENCH_SIZE.height
      let scale = Math.min(horizontalScale, verticalScale)

      stageRef.current.width(WORKBENCH_SIZE.width * scale)
      stageRef.current.height(WORKBENCH_SIZE.height * scale)
      stageRef.current.scale({ x: scale, y: scale })
      stageRef.current.draw()
      return {
        width: WORKBENCH_SIZE.width * scale,
        height: WORKBENCH_SIZE.height * scale,
      }
    }

    return {
      width: WORKBENCH_SIZE.width,
      height: WORKBENCH_SIZE.height,
    }
  }

  useEffect(() => {
    loadImages()
  }, [product?.id, templateConfig.containerId])

  useEffect(() => {
    if (!canContinue()) {
      return
    }

    createStage()

    return () => {
      if (stageRef.current) {
        stageRef.current.destroy()
      }
    }
  }, [loadedImages, templateConfig.containerId])

  useEffect(() => {
    if (
      !isBatchSaving ||
      (currentSavingProductId && currentSavingProductId !== product.id)
    )
      return

    const saveCurrent = async () => {
      try {
        const ratio = WORKBENCH_SIZE.width / PREVIEW_SIZE.width
        await savePreviewToProduct(stageRef.current, product.id, ratio)
        handleProductSaveComplete(product.id)
      } catch (error) {
        console.error('Error saving preview:', error)
        handleProductSaveComplete(product.id) // Move to next product even if there is an error
      }
    }

    saveCurrent()
  }, [
    isBatchSaving,
    currentSavingProductId,
    product.id,
    savePreviewToProduct,
    handleProductSaveComplete,
  ])

  useEffect(() => {
    if (!canContinue()) {
      return
    }
    updateFonts()
  }, [currentFontFamily, loadedImages])

  useEffect(() => {
    if (!canContinue()) {
      return
    }
    updateColors()
  }, [currentPalette, loadedImages])

  useEffect(() => {
    if (!canContinue()) {
      return
    }
    updateCompanyDetails()
  }, [
    loadedImages,
    draftConfiguration.showCompanyLogo,
    draftConfiguration.showCompanyName,
    companyDetails.companyLogoUrl,
    companyDetails.companyLogoUrl,
    companyDetails.companyName,
    templateConfig.placeProductNameUnderCompanyName,
  ])

  useEffect(() => {
    if (!canContinue()) {
      return
    }

    const primaryTextInitialProps = templateConfig.initialPositions.primaryText
    if (primaryTextInitialProps) {
      const primaryText = itemsLayerRef.current.findOne('.primaryText')
      if (primaryText) {
        if (draftConfiguration.customDiscountText) {
          primaryText.text(draftConfiguration.customDiscountText)
        } else {
          primaryText.text(
            modifyDiscountTexts(
              primaryTextInitialProps.text,
              discountAmountText,
            ),
          )
        }
      }
    }

    const secondaryTextInitialProps =
      templateConfig.initialPositions.secondaryText
    if (secondaryTextInitialProps) {
      const secondaryText = itemsLayerRef.current.findOne('.secondaryText')
      if (secondaryText) {
        if (draftConfiguration.customDiscountCodeText) {
          secondaryText.text(draftConfiguration.customDiscountCodeText)
        } else {
          secondaryText.text(
            modifyDiscountTexts(secondaryTextInitialProps.text, discount.code),
          )
        }
      }
    }
  }, [
    loadedImages,
    discount,
    draftConfiguration.customDiscountText,
    draftConfiguration.customDiscountCodeText,
  ])

  const loadImages = async () => {
    const images = {}
    const loadImagePromises = []

    // Helper function to load and process SVG images
    const loadSvgImage = (svgUrl, color, customReplace) => {
      return new Promise(resolve => {
        loadAndChangeSvgColor(
          svgUrl,
          color,
          coloredSvgUrl => {
            Konva.Image.fromURL(coloredSvgUrl, image => {
              resolve(image)
            })
          },
          customReplace,
        )
      })
    }

    // Load background SVG
    if (templateConfig.backgroundSvg) {
      loadImagePromises.push(
        loadSvgImage(
          templateConfig.backgroundSvg,
          currentPalette[templateConfig.backgroundSvgColor],
          templateConfig.backgroundSvgCustomReplaceThisDirectly,
        ).then(image => {
          images.background = image
        }),
      )
    }

    // Load foreground SVG
    if (templateConfig.foregroundSvg) {
      loadImagePromises.push(
        loadSvgImage(
          templateConfig.foregroundSvg,
          currentPalette[templateConfig.foregroundSvgColor],
          templateConfig.foregroundSvgCustomReplaceThisDirectly,
        ).then(image => {
          images.foregroundSvg = image
        }),
      )
    }

    // Load second background layer SVG
    if (templateConfig.secondBackgroundLayerSvg) {
      loadImagePromises.push(
        loadSvgImage(
          templateConfig.secondBackgroundLayerSvg,
          currentPalette[templateConfig.secondBackgroundLayerSvgColor],
          templateConfig.secondBackgroundLayerSvgCustomReplaceThisDirectly,
        ).then(image => {
          images.secondBackgroundLayerSvg = image
        }),
      )
    }

    // Load company logo
    if (companyDetails.companyLogoUrl && !loadedImages.companyLogo) {
      loadImagePromises.push(
        new Promise(resolve => {
          const imageObj = new Image()
          let imageSource = companyDetails.companyLogoUrl

          if (companyDetails.companyLogoUrl.includes('s3.amazonaws.com')) {
            let url = new URL(companyDetails.companyLogoUrl, window.location.origin);
            imageSource = url.searchParams.size > 0
              ? companyDetails.companyLogoUrl + `&konva=${Date.now()}`
              : companyDetails.companyLogoUrl + `?konva=${Date.now()}`
          }

          imageObj.src = imageSource
          imageObj.crossOrigin = 'Anonymous'
          imageObj.onload = () => {
            images.companyLogo = imageObj
            resolve()
          }
        }),
      )
    } else if (loadedImages.companyLogo) {
      images.companyLogo = loadedImages.companyLogo
    }

    // Load product image
    const imageUrl = templateConfig.initialPositions.productImage
      ?.withBackground
      ? `${process.env.REACT_APP_BACKEND_URL}/proxy/getProxyImage?url=${encodeURIComponent(product?.originalImageUrl)}`
      : product?.transparentImageUrl

    loadImagePromises.push(
      new Promise(resolve => {
        const imageObj = new Image()
        let imageSource = imageUrl

        if (
          !templateConfig.initialPositions.productImage?.withBackground && // Only apply S3 handling for non-proxied images
          (imageUrl.includes('s3.amazonaws.com') ||
            imageUrl.includes('eu-central-1.amazonaws.com'))
        ) {
          let url = new URL(imageUrl, window.location.origin)
          imageSource =
            url.searchParams.size > 0
              ? imageUrl + `&konva=${Date.now()}`
              : imageUrl + `?konva=${Date.now()}`
        }

        imageObj.src = imageSource
        imageObj.crossOrigin = 'Anonymous'
        imageObj.onload = () => {
          images.productImage = imageObj
          resolve()
        }
      }),
    )

    // Wait for all images to load
    await Promise.all(loadImagePromises)
    setLoadedImages(images)
  }

  const createStage = () => {
    const newHistory = { ...positions }

    stageRef.current = new Konva.Stage({
      container: `${templateConfig.containerId}-${product.id}`,
      width: WORKBENCH_SIZE.width,
      height: WORKBENCH_SIZE.height,
    })

    backgroundLayerRef.current = new Konva.Layer()
    productImageLayerRef.current = new Konva.Layer()
    secondBackgroundLayerRef.current = new Konva.Layer()
    secondBackgroundLayerRef.current.listening(false)
    itemsLayerRef.current = new Konva.Layer()
    transformerLayerRef.current = new Konva.Layer()
    foregroundLayerRef.current = new Konva.Layer()

    const backgroundConfig = {
      x: 0,
      y: 0,
      height: WORKBENCH_SIZE.height,
      width: WORKBENCH_SIZE.width,
      name: 'background',
    }

    if (templateConfig.backgroundColor) {
      backgroundConfig.fill = currentPalette[templateConfig.backgroundColor]
    }

    if (templateConfig.backgroundGradient?.type === 'linear') {
      backgroundConfig.fillLinearGradientStartPoint =
        templateConfig.backgroundGradient.from.position
      backgroundConfig.fillLinearGradientEndPoint =
        templateConfig.backgroundGradient.to.position
      backgroundConfig.fillLinearGradientColorStops = [
        templateConfig.backgroundGradient.from.stop,
        currentPalette[templateConfig.backgroundGradient.from.color],
        templateConfig.backgroundGradient.to.stop,
        currentPalette[templateConfig.backgroundGradient.to.color],
      ]
    }

    const background = new Konva.Rect(backgroundConfig)
    backgroundLayerRef.current.add(background)

    if (templateConfig.backgroundSvg) {
      loadedImages.background.setAttrs(templateConfig.backgroundSvgAttrs)
      loadedImages.background.addName('background-svg')
      backgroundLayerRef.current.add(loadedImages.background)
      backgroundLayerRef.current.draw()
    }

    if (templateConfig.secondBackgroundLayerSvg) {
      loadedImages.secondBackgroundLayerSvg.setAttrs(
        templateConfig.secondBackgroundLayerSvgAttrs,
      )
      loadedImages.secondBackgroundLayerSvg.addName('background-second-svg')
      secondBackgroundLayerRef.current.add(
        loadedImages.secondBackgroundLayerSvg,
      )
      secondBackgroundLayerRef.current.draw()
    }

    // Company Logo
    const companyLogoInitialProps = templateConfig.initialPositions.companyLogo
    if (companyLogoInitialProps && loadedImages.companyLogo) {
      const companyLogoConfig = {
        ...companyLogoInitialProps,
      }

      if (positions.companyLogo?.x)
        companyLogoConfig.x = positions.companyLogo.x
      if (positions.companyLogo?.y)
        companyLogoConfig.y = positions.companyLogo.y
      if (positions.companyLogo?.width || positions.companyLogo?.height) {
        const { width, height } = resizeImageToFit({
          x: companyLogoConfig.x,
          y: companyLogoConfig.y,
          width: loadedImages.companyLogo.naturalWidth,
          height: loadedImages.companyLogo.naturalHeight,
          maxWidth: positions.companyLogo?.width,
          maxHeight: positions.companyLogo?.height,
        })

        companyLogoConfig.width = width
        companyLogoConfig.height = height

        newHistory.companyLogo = {
          ...newHistory.companyLogo,
          width,
          height,
        }
      }

      const companyLogo = new Konva.Image({
        ...companyLogoConfig,
        image: loadedImages.companyLogo,
        draggable: false,
      })

      companyLogoNodeRef.current = companyLogo

      itemsLayerRef.current.add(companyLogo)

      if (!draftConfiguration.showCompanyLogo) {
        companyLogoNodeRef.current.hide()
      }
    }

    // Product Image
    const productImageInitialProps =
      templateConfig.initialPositions.productImage
    if (productImageInitialProps && loadedImages.productImage) {
      const productImageConfig = {
        ...productImageInitialProps,
      }

      if (positions.productImage?.x)
        productImageConfig.x = positions.productImage.x
      if (positions.productImage?.y)
        productImageConfig.y = positions.productImage.y
      if (positions.productImage?.width || positions.productImage?.height) {
        const { width, height } = resizeImageToFit({
          x: productImageConfig.x,
          y: productImageConfig.y,
          width: loadedImages.productImage.naturalWidth,
          height: loadedImages.productImage.naturalHeight,
          maxWidth: positions.productImage?.width,
          maxHeight: positions.productImage?.height,
        })

        productImageConfig.width = width
        productImageConfig.height = height

        newHistory.productImage = {
          ...newHistory.productImage,
          width,
          height,
        }
      }
      let productImageGroup = null
      if (productImageInitialProps.objectFit === 'cover') {
        const fittingResult = calculateBoxFitCover({
          img: { width: loadedImages.productImage.naturalWidth, height: loadedImages.productImage.naturalHeight },
          box: { width: productImageConfig.width, height: productImageConfig.height, positionx: productImageConfig.x, positiony: productImageConfig.y }
        });
        productImageConfig.x = fittingResult.x
        productImageConfig.y = fittingResult.y
        productImageConfig.width = fittingResult.width
        productImageConfig.height = fittingResult.height

        productImageGroup = new Konva.Group({
          clip: {
            x: productImageInitialProps.x,
            y: productImageInitialProps.y,
            width: productImageInitialProps.width,
            height: productImageInitialProps.height,
          }
        })
      }

      const productImage = new Konva.Image({
        ...productImageConfig,
        image: loadedImages.productImage,
        draggable: false,
        name: 'productImage',
        shadowColor: 'black',
        shadowBlur: 50,
        shadowOffset: { x: 0, y: 10 },
        shadowOpacity: productImageInitialProps?.withBackground ? 0.0 : 0.4,
      })

      if (productImageInitialProps.moveToTop) {
        productImageLayerRef.current.moveToTop()
      }

      productImageNodeRef.current = productImage
      if (productImageGroup) {
        productImageGroup.add(productImage)
        productImageLayerRef.current.add(productImageGroup)
      } else {
        productImageLayerRef.current.add(productImage)
      }
    }

    // Additional Product Image
    const additionalProductImageInitialProps = templateConfig.initialPositions.additionalProductImage
    if (additionalProductImageInitialProps) {

      const additionalProductImageConfig = {
        ...additionalProductImageInitialProps,
      }

      if (additionalProductImageInitialProps.maxWidth || additionalProductImageInitialProps.maxHeight) {
        const { x, y, width, height } = resizeImageToFit({
          x: additionalProductImageConfig.x,
          y: additionalProductImageConfig.y,
          width: loadedImages.productImage.naturalWidth,
          height: loadedImages.productImage.naturalHeight,
          maxWidth: additionalProductImageConfig.maxWidth,
          maxHeight: additionalProductImageConfig.maxHeight,
        })

        additionalProductImageConfig.x = x
        additionalProductImageConfig.y = y
        additionalProductImageConfig.width = width
        additionalProductImageConfig.height = height
      }

      if (additionalProductImageInitialProps.center) {
        const { x, y } = centerTheElement({
          x: additionalProductImageConfig.x,
          y: additionalProductImageConfig.y,
          width: loadedImages.productImage.naturalWidth,
          height: loadedImages.productImage.naturalHeight,
        })

        additionalProductImageConfig.x = x
        additionalProductImageConfig.y = y
      }

      let additionalProductImageGroup = null
      if (additionalProductImageInitialProps.objectFit === 'cover') {
        const fittingResult = calculateBoxFitCover({
          img: { width: loadedImages.productImage.naturalWidth, height: loadedImages.productImage.naturalHeight },
          box: { width: additionalProductImageConfig.width, height: additionalProductImageConfig.height, positionx: additionalProductImageConfig.x, positiony: additionalProductImageConfig.y }
        });
        additionalProductImageConfig.x = fittingResult.x
        additionalProductImageConfig.y = fittingResult.y
        additionalProductImageConfig.width = fittingResult.width
        additionalProductImageConfig.height = fittingResult.height

        additionalProductImageGroup = new Konva.Group({
          clip: {
            x: additionalProductImageInitialProps.x,
            y: additionalProductImageInitialProps.y,
            width: additionalProductImageInitialProps.width,
            height: additionalProductImageInitialProps.height,
          }
        })
      }
      const additionalProductImage = new Konva.Image({
        ...additionalProductImageConfig,
        image: loadedImages.productImage,
        draggable: true,
        name: 'additionalProductImage',
        shadowColor: 'black',
        shadowBlur: 50,
        shadowOffset: { x: 0, y: 10 },
        shadowOpacity: additionalProductImageInitialProps?.withBackground ? 0.0 : 0.4,
        _cropWidth: additionalProductImageInitialProps.width,
        _cropHeight: additionalProductImageInitialProps.height,
        _cropX: additionalProductImageInitialProps.x,
        _cropY: additionalProductImageInitialProps.y,
      })
      if (additionalProductImageInitialProps.brightness) {
        additionalProductImage.cache()
        additionalProductImage.filters([
          Konva.Filters.Brighten,
        ])
        additionalProductImage.brightness(additionalProductImageInitialProps.brightness)
      }

      if (additionalProductImageGroup) {
        additionalProductImageGroup.add(additionalProductImage)
        productImageLayerRef.current.add(additionalProductImageGroup)
      } else {
        productImageLayerRef.current.add(additionalProductImage)
      }
    }

    // Product Name
    const productNameInitialProps = templateConfig.initialPositions.productName
    if (productNameInitialProps) {
      const initialWidthProductName = productNameInitialProps?.width
      const initialHeightProductName = productNameInitialProps?.height

      const productNameGroupSettings = { ...productNameInitialProps }
      delete productNameGroupSettings.fontIndex

      const productNameContainerConfig = {
        ...productNameGroupSettings,
        width: initialWidthProductName,
        draggable: false,
        name: 'productNameContainer',
      }

      if (positions.productName?.x)
        productNameContainerConfig.x = positions.productName.x
      if (positions.productName?.y)
        productNameContainerConfig.y = positions.productName.y
      if (positions.productName?.width)
        productNameContainerConfig.width = positions.productName.width
      if (positions.productName?.height)
        productNameContainerConfig.height = positions.productName.height
      if (positions.productName?.scaleX)
        productNameContainerConfig.scaleX = positions.productName.scaleX
      if (positions.productName?.scaleY)
        productNameContainerConfig.scaleY = positions.productName.scaleY

      const productNameContainer = new Konva.Group(productNameContainerConfig)
      itemsLayerRef.current.add(productNameContainer)

      const productName = new Konva.Text({
        text: product.name,
        fontSize: productNameInitialProps.fontSize ?? 40.5,
        fontFamily: productNameInitialProps.fontFamily ?? 'Be Vietnam Pro',
        fontStyle: productNameInitialProps.fontStyle ?? '500',
        fill: currentPalette[productNameInitialProps.color],
        width: productNameContainer.getWidth(),
        height: productNameContainer.getHeight(),
        draggable: false,
        align:
          productNameInitialProps.align ||
          (productNameInitialProps.center ? 'center' : 'left'),
        verticalAlign:
          productNameInitialProps.verticalAlign ||
            productNameInitialProps.center
            ? 'middle'
            : 'top',
        name: 'productName',
        fontIndex: productNameInitialProps.fontIndex ?? 1,
      })

      if (productNameInitialProps.toUpperCase) {
        productName.text(productName.text().toUpperCase())
      }

      productNameContainer.add(productName)
    }

    // Product Price
    const productPriceInitialProps =
      templateConfig.initialPositions.productPrice
    if (productPriceInitialProps) {
      const productPriceConfig = {
        ...productPriceInitialProps,
      }

      const productPriceContainer = new Konva.Group({
        ...productPriceConfig,
        draggable: false,
        name: 'productPriceContainer',
      })

      if (productPriceInitialProps?.circle) {
        const productPriceBackCircle = new Konva.Circle({
          x: 0 ?? productPriceInitialProps.radius,
          y: 0 ?? productPriceInitialProps.radius,
          radius: productPriceInitialProps.radius,
          fill: currentPalette[productPriceInitialProps?.circleColor],
        })
        productPriceContainer.add(productPriceBackCircle)
      }

      const productPrice = new Konva.Text({
        x: productPriceInitialProps.circle
          ? 0 - productPriceInitialProps.width / 2
          : 0,
        y: productPriceInitialProps.circle
          ? 0 - productPriceInitialProps.height / 2
          : 0,
        width: productPriceInitialProps.width,
        height: productPriceInitialProps.height,
        text: formatPrice(product.price, product.currency),
        fill: currentPalette[productPriceInitialProps.color],
        fontFamily: productPriceInitialProps.fontFamily ?? 'Be Vietnam Pro',
        fontSize: productPriceInitialProps.fontSize ?? 32,
        fontStyle: productPriceInitialProps.fontStyle,
        name: 'productPrice',
        align: productPriceInitialProps.align,
        verticalAlign: productPriceInitialProps.verticalAlign,
      })

      if (productPriceInitialProps?.textBefore) {
        productPrice.text(
          productPriceInitialProps.textBefore + productPrice.text(),
        )
      }

      productPriceContainer.add(productPrice)

      itemsLayerRef.current.add(productPriceContainer)
    }

    // Primary Text
    const primaryTextInitialProps = templateConfig.initialPositions.primaryText

    if (primaryTextInitialProps) {
      const primaryTextConfig = {
        ...primaryTextInitialProps,
        draggable: false,
        name: 'primaryText',
        fill: currentPalette[primaryTextInitialProps?.color],
        fontFamily: 'Be Vietnam Pro',
        fontSize: primaryTextInitialProps?.fontSize ?? 40.5,
        letterSpacing: primaryTextInitialProps?.letterSpacing ?? 1,
        fontStyle: primaryTextInitialProps?.fontStyle ?? 'bold',
        align: primaryTextInitialProps?.align ?? 'left',
        verticalAlign: primaryTextInitialProps?.verticalAlign ?? 'middle',
        x: primaryTextInitialProps.x,
        y: primaryTextInitialProps.y,
      }

      if (positions.primaryText?.x)
        primaryTextConfig.x = positions.primaryText.x
      if (positions.primaryText?.y)
        primaryTextConfig.y = positions.primaryText.y
      if (positions.primaryText?.width)
        primaryTextConfig.width = positions.primaryText.width
      if (positions.primaryText?.height)
        primaryTextConfig.height = positions.primaryText.height
      if (positions.primaryText?.scaleX)
        primaryTextConfig.scaleX = positions.primaryText.scaleX
      if (positions.primaryText?.scaleY)
        primaryTextConfig.scaleY = positions.primaryText.scaleY

      const primaryText = new Konva.Text(primaryTextConfig)

      if (primaryTextInitialProps.text) {
        primaryText.text(primaryTextInitialProps.text)
      }

      itemsLayerRef.current.add(primaryText)
    }

    // Secondary Text
    const secondaryTextInitialProps =
      templateConfig.initialPositions.secondaryText
    if (secondaryTextInitialProps) {
      const secondaryTextConfig = {
        ...secondaryTextInitialProps,
        fill: currentPalette[secondaryTextInitialProps.color],
        fontStyle: 'bold',
        name: 'secondaryText',
        letterSpacing: secondaryTextInitialProps.letterSpacing ?? 1,
        draggable: false,
      }

      if (positions.secondaryText?.x)
        secondaryTextConfig.x = positions.secondaryText.x
      if (positions.secondaryText?.y)
        secondaryTextConfig.y = positions.secondaryText.y
      if (positions.secondaryText?.width)
        secondaryTextConfig.width = positions.secondaryText.width
      if (positions.secondaryText?.height)
        secondaryTextConfig.height = positions.secondaryText.height
      if (positions.secondaryText?.scaleX)
        secondaryTextConfig.scaleX = positions.secondaryText.scaleX
      if (positions.secondaryText?.scaleY)
        secondaryTextConfig.scaleY = positions.secondaryText.scaleY

      const secondaryText = new Konva.Text(secondaryTextConfig)

      itemsLayerRef.current.add(secondaryText)
    }

    itemsLayerRef.current.draw()

    stageRef.current.add(backgroundLayerRef.current)
    stageRef.current.add(productImageLayerRef.current)
    stageRef.current.add(secondBackgroundLayerRef.current)
    stageRef.current.add(itemsLayerRef.current)
    stageRef.current.add(transformerLayerRef.current)
    stageRef.current.add(foregroundLayerRef.current)

    resizeStage()
    setHistoriesByProductId(state => ({
      ...state,
      [product.id]: newHistory,
    }))
  }

  const updateFonts = () => {
    if (!currentFontFamily) {
      return
    }

    if (itemsLayerRef.current) {
      const primaryFontTexts = itemsLayerRef.current.find(
        node => node.attrs.fontIndex === 1,
      )
      const secondaryFontTexts = itemsLayerRef.current.find(
        node => node.attrs.fontIndex === 2,
      )

      if (primaryFontTexts) {
        primaryFontTexts.forEach(node => {
          node.setAttr('fontFamily', currentFontFamily.primary)
        })
      }
      if (secondaryFontTexts) {
        secondaryFontTexts.forEach(node => {
          node.setAttr('fontFamily', currentFontFamily.secondary)
        })
      }

      itemsLayerRef.current.batchDraw()
    }
  }

  const updateColors = () => {
    if (!canContinue()) {
      return
    }
    const background = backgroundLayerRef.current.findOne('.background')

    if (templateConfig.backgroundColor) {
      background.fill(
        currentPalette[templateConfig.backgroundColor] ??
        templateConfig.backgroundColor,
      )
    }

    if (templateConfig.backgroundGradient?.type === 'linear') {
      background.fillLinearGradientStartPoint(
        templateConfig.backgroundGradient.from.position,
      )
      background.fillLinearGradientEndPoint(
        templateConfig.backgroundGradient.to.position,
      )
      background.fillLinearGradientColorStops([
        templateConfig.backgroundGradient.from.stop,
        currentPalette[templateConfig.backgroundGradient.from.color],
        templateConfig.backgroundGradient.to.stop,
        currentPalette[templateConfig.backgroundGradient.to.color],
      ])
    }

    if (templateConfig.backgroundSvg) {
      loadAndChangeSvgColor(
        templateConfig.backgroundSvg,
        currentPalette[templateConfig.backgroundSvgColor],
        coloredSvgUrl => {
          Konva.Image.fromURL(coloredSvgUrl, image => {
            image.setAttrs(templateConfig.backgroundSvgAttrs)
            const oldSvg = backgroundLayerRef.current.findOne('.background-svg')
            if (oldSvg) {
              oldSvg.destroy()
            }
            image.addName('background-svg')
            backgroundLayerRef.current.add(image)
            backgroundLayerRef.current.draw()
          })
        },
        templateConfig.backgroundSvgCustomReplaceThisDirectly,
      )
    }

    if (templateConfig.secondBackgroundLayerSvg) {
      loadAndChangeSvgColor(
        templateConfig.secondBackgroundLayerSvg,
        currentPalette[templateConfig.secondBackgroundLayerSvgColor],
        coloredSvgUrl => {
          Konva.Image.fromURL(coloredSvgUrl, image => {
            image.setAttrs(templateConfig.secondBackgroundLayerSvgAttrs)
            const oldSvg = secondBackgroundLayerRef.current.findOne(
              '.background-second-svg',
            )
            if (oldSvg) {
              oldSvg.destroy()
            }
            image.addName('background-second-svg')
            secondBackgroundLayerRef.current.add(image)
            secondBackgroundLayerRef.current.draw()
          })
        },
        templateConfig.secondBackgroundLayerSvgCustomReplaceThisDirectly,
      )
    }

    const productNameInitialProps = templateConfig.initialPositions.productName

    const productName = itemsLayerRef.current.findOne('.productName')
    productName?.fill(currentPalette[productNameInitialProps.color])

    const productPriceInitialProps =
      templateConfig.initialPositions.productPrice
    const productPrice = itemsLayerRef.current.findOne('.productPrice')
    productPrice?.fill(currentPalette[productPriceInitialProps.color])

    if (productPriceInitialProps?.circle) {
      const productPriceBackCircle = itemsLayerRef.current.findOne('Circle')
      productPriceBackCircle?.fill(
        currentPalette[productPriceInitialProps?.circleColor],
      )
    }

    const primaryTextInitialProps = templateConfig.initialPositions.primaryText

    const secondaryTextInitialProps =
      templateConfig.initialPositions.secondaryText

    if (primaryTextInitialProps) {
      const primaryText = itemsLayerRef.current.findOne('.primaryText')
      primaryText.fill(currentPalette[primaryTextInitialProps.color])
    }

    if (secondaryTextInitialProps) {
      const secondaryText = itemsLayerRef.current.findOne('.secondaryText')
      secondaryText.fill(currentPalette[secondaryTextInitialProps.color])
    }

    backgroundLayerRef.current.batchDraw()
    itemsLayerRef.current.batchDraw()
  }

  const updateCompanyDetails = () => {
    if (companyLogoNodeRef.current) {
      if (draftConfiguration.showCompanyLogo) {
        companyLogoNodeRef.current?.show()
      } else {
        companyLogoNodeRef.current?.hide()
      }
    }
  }

  const canContinue = () => {
    const result =
      (templateConfig.backgroundSvg ? !!loadedImages.background : true) &&
      (templateConfig.secondBackgroundLayerSvg
        ? !!loadedImages.secondBackgroundLayerSvg
        : true) &&
      (draftConfiguration.companyLogoUrl ? !!loadedImages.companyLogo : true) &&
      (templateConfig.foregroundSvg ? !!loadedImages.foregroundSvg : true) &&
      loadedImages.productImage
    return result
  }

  const modifyDiscountTexts = (text, value) => {
    return text.replace('{{x}}', value)
  }

  return (
    <div
      id={`${templateConfig.containerId}-${product.id}`}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    />
  )
}

export default Preview
