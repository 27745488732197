import { useSelector } from "react-redux"
import { useFacebookReport } from "../../../shared/hooks/facebook/report-hook"
import { useEffect, useState } from "react"
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import './ActivityLogTable.css'
import Button from "../../../shared/components/FormElements/Button"
import InlineLoadingSpinner from "../../../shared/components/UIElements/InlineLoadingSpinner"
import helper from "../../../shared/util/helper"

const ActivityLogTable = () => {
    const { fetchAdAccountActivity } = useFacebookReport()
    const adAccountActivity = useSelector(state => state.facebook.adAccountActivity)
    const activity = adAccountActivity?.data
    const nextUrl = adAccountActivity?.nextUrl
    const activitiesLoading = useSelector(state => state.facebook.loadings?.activities)
    const [isTableVisible, setIsTableVisible] = useState(false)

    useEffect(() => {
        fetchAdAccountActivity({})
    }, [])

    const handleNextPage = () => {
        fetchAdAccountActivity({ nextUrl })
    }

    const formatEventType = (eventType) => {
        return eventType
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    const formatJsonValue = (value) => {
        try {
            if (!value) return 'None';
            const jsonStr = typeof value === 'string' ? value : JSON.stringify(value);
            return JSON.stringify(JSON.parse(jsonStr), null, 2);
        } catch (e) {
            return value || 'None';
        }
    }

    const toggleTableVisibility = () => {
        setIsTableVisible(prev => !prev)
    }

    return <div>
        <div className="activity-log-header">
            <div className="activity-log-table-header">
                {isTableVisible ? "Activity Log Table" : "Show activities"}
                <button
                    className="visibility-toggle-btn"
                    onClick={toggleTableVisibility}
                    title={isTableVisible ? "Hide table" : "Show table"}
                >
                    {isTableVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
            </div>
        </div>
        {
            activitiesLoading ?
                <InlineLoadingSpinner />
                :
                activity?.length > 0 && isTableVisible ?
                    <>
                    <div className="activity-log-container">
                        <table className="facebook-activity-table">
                            <thead>
                                <tr>
                                    <th>Actor</th>
                                    <th>Event Time</th>
                                    <th>Event Type</th>
                                    <th>Object Name</th>
                                    <th>Extra Data</th>
                                    <th>Application Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {activity.map((activity) => {
                                    const extraData = activity.extra_data
                                    const oldValue = JSON.parse(extraData).old_value
                                    const newValue = JSON.parse(extraData).new_value
                                    return (
                                        <tr key={activity.id}>
                                            <td>{activity.actor_name}</td>
                                            <td>{helper.convertDateToString(activity.event_time)}</td>
                                            <td>{formatEventType(activity.event_type)}</td>
                                            <td>{activity.object_name}</td>
                                            <td>
                                                <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                                                    <b>Old value:</b> &nbsp;
                                                    {formatJsonValue(oldValue)}
                                                </pre>
                                                <br />
                                                <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                                                    <b>New value:</b> &nbsp;
                                                    {formatJsonValue(newValue)}
                                                </pre>
                                            </td>
                                            <td>{activity.application_name}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {
                        nextUrl &&
                        <Button size="small" className="activity-log-next-button" onClick={handleNextPage} loading={activitiesLoading} loadingText="Loading more...">Next</Button>
                    }
                </>
                :
                <div>
                    <p>{activity?.length === 0 ? "No activity log found" : ""}</p>
                </div>
        }
    </div>
}

export default ActivityLogTable