import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

import { useHttpClient } from '../../shared/hooks/http-hook'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import analytics from '../../shared/util/analytics'
import { authActions } from '../../store/auth'
import { generalActions } from '../../store/general'


const PaymentCompleted = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { sendRequest } = useHttpClient()

  let location = useLocation()
  const stripeSubscription = location.state?.stripe_subs // for stripe
  const query = new URLSearchParams(location.search)
  let token = ''
  let subscriptionCompleted = query.get('subscription_completed')
  let singlePayment = query.get('sp')
  if (subscriptionCompleted || stripeSubscription) {
    token = query.get('token') || stripeSubscription.id
  } else {
    navigate('/account/subscription')
  }

  const [processing, setProcessing] = useState(true)
  const [paymentError, setPaymentError] = useState(false)
  const auth = useSelector(state => state.auth)
  const user = auth.user

  const authHeader = {
    Authorization: 'Bearer ' + auth.token,
    'Content-Type': 'application/json',
  }

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle: 'Payment Successful' }))
  }, [])

  useEffect(() => {
    console.log('PaymentCompleted useEffect')
    let isMounted = true

    const completeSubscription = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/paymentCompleted`,
          'POST',
          JSON.stringify({
            token: token,
            userId: user.id,
            singlePayment: singlePayment,
            stripeSubscription: stripeSubscription,
          }),
          authHeader,
        )

        if (response && response.success) {
          dispatch(
            authActions.updateUserObjOneField({
              field: "subscription",
              value: response.subscriptionDetails,
            }),
          )
          analytics.completeSubscription({
            value: response.subscriptionDetails.price,
            currency: response.subscriptionDetails.currency,
            subscriptionId: response.subscriptionDetails.refCode,
            subscriptionPlanName: response.subscriptionDetails.plan,
            userId: user.id,
            userEmail: user.email,
          })
          setTimeout(() => {
            setProcessing(false)
            navigate("/subscription-completed/success");
          }, 2000);

        } else {
          toast.warn(response.message || 'Something went wrong')
          setProcessing(false)
          setPaymentError(true)
        }
      } catch (error) {
        toast.warn(error?.message || 'Something went wrong')
        setProcessing(false)
        setPaymentError(true)
      }
    }

    let isUpdate = query.get('isUpdate') && parseInt(query.get('isUpdate'))
    if (
      user.subscription &&
      user.subscription.status === 'active' &&
      !isUpdate
    ) {
      setProcessing(false)
      navigate('/account/subscription')
    } else {
      completeSubscription()
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="payment-completed">
      <div className="subscription-success">
        {processing ? (
          <div className="row">
            <LoadingSpinner />
            <span>Starting subscription...</span>
          </div>
        ) : (
          <div className="content">
            {paymentError ? (

              <div className="row">
                <FaTimes className="icon red" />
                <span>
                  Subscription failed. Try again later or contact us.
                </span>
              </div>

            ) : (

              <div className="row">
                <FaCheckCircle className="icon" />
                <span>Subscription started</span>
              </div>


            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentCompleted
