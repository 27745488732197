import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import { adminActions } from '../../store/admin'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { toast } from 'react-toastify'
import SpecialUserCard from '../components/SpecialUserCard'

import './SpecialUsers.css'
import usePermissionHook from '../../shared/hooks/user-permission-hook'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'

const SpecialUsers = () => {
  const dispatch = useDispatch()
  const { openModal } = useModal()
  const { sendRequest } = useHttpClient()
  const [isLoading, setIsLoading] = useState(false)

  const { getSpecialUsers } = usePermissionHook()
  const auth = useSelector(state => state.auth)
  const specialUsers = useSelector(state => state.admin.specialUsers)

  const authHeader = {
    Authorization: 'Bearer ' + auth.token,
    'Content-Type': 'application/json',
  }


  useEffect(() => {
    if (specialUsers.length) {
      return
    }
    getSpecialUsersData()
  }, [])

  const getSpecialUsersData = async () => {
    setIsLoading(true)
    await getSpecialUsers()
    setIsLoading(false)
  }

  const addNewUser = async email => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getUserByEmail/${email.email}`,
        'GET',
        null,
        authHeader,
      )
      let user = responseData.user
      dispatch(adminActions.addSpecialUser(user))
      return user
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <div className="special-users-container">
      <div className="layout">
        <div className="content">
          <div className="top">
            <div
              className="create-new-button"
              onClick={() => {
                openModal({
                  type: Modals.ADD_USER,
                  closeOnClickOutside: true,
                  callback: async email => {
                    addNewUser(email)
                  },
                })
              }}
            >
              Add User
            </div>
          </div>



          {!isLoading && !specialUsers.length ? (
            <h2 className="not-found-text">
              No special user found.
            </h2>
          ) : (
            <div className="demo-meetings-list-wrapper">
              <div className="demo-meetings-list">
                {specialUsers?.map(specialUser => (
                  <SpecialUserCard key={specialUser._id} user={specialUser} />
                ))}
                {/* {specialUsers?.map(specialUser => (
                <div>{specialUser.email}</div>
              ))} */}
              </div>

            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SpecialUsers
