import { useCallback } from "react";
import { useHttpClient } from "./http-hook";
import { useDispatch, useSelector } from "react-redux";
import { facebookCampaignEditorActions } from "../../store/facebookCampaignEditor";


const useAdTextGenerator = () => {

    const authToken = useSelector(state => state.auth.token)
    const userCategories = useSelector(state => state.auth.user.userSelectedEnhencerCategories)
    const companyName = useSelector(state => state.auth.user.companyName)
    const userId = useSelector(state => state.auth.user.id)
    const websiteUrl = useSelector(state => state.auth.user.url)
    const shopifyDomain = useSelector(state => state.auth.user.shopify?.domain)
    const currentChat = useSelector(state => state.facebookCampaignEditor.adTextGeneratorChat)
    const { sendRequest } = useHttpClient()
    const dispatch = useDispatch()
    const authHeader = {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
    }

    const generateAllAdTextTypes = async ({ selectedAdAccountId }) => {
        const chatInSession = sessionStorage.getItem(`adTextGeneratorChat_${userId}`)
        if (chatInSession) {
            dispatch(facebookCampaignEditorActions.setAllAdTextTypes(JSON.parse(chatInSession)))
            return JSON.parse(chatInSession)
        }

        const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/facebook/generateAdTexts`,
            'POST',
            JSON.stringify({
                userId,
                userCategories,
                companyName,
                selectedAdAccountId,
                websiteUrl: websiteUrl || shopifyDomain
            }),
            authHeader
        )

        const payload = {
            chatId: response.chatId,
            messages: response.messages,
            informal: [response.adTexts.informal],
            formal: [response.adTexts.formal],
            fomo: [response.adTexts.fomo],
        }
        dispatch(facebookCampaignEditorActions.setAllAdTextTypes(payload))

        sessionStorage.setItem(`adTextGeneratorChat_${userId}`, JSON.stringify(payload))

        return response.adTexts
    }

    const regenerateAdText = async ({ type }) => {
        try {

            dispatch(facebookCampaignEditorActions.startRegeneratingAdText(type))
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/facebook/regenerateAdText`,
                'POST',
                JSON.stringify({
                    type,
                    chatId: currentChat.chatId,
                    previousMessages: currentChat.messages
                }),
                authHeader
            )

            dispatch(facebookCampaignEditorActions.addNewAdTextToType({
                type,
                text: response.generatedAdText,
                messages: response.messages,
                userId
            }))

        } catch (error) {
            console.error(error)
        }
    }

    return {
        generateAllAdTextTypes,
        regenerateAdText,
        currentChat
    }

}

export default useAdTextGenerator

