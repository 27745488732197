import { WORKBENCH_SIZE } from '../../../support/constants'
import temp23bg from '../../../../shared/assets/images/ai-creative/temp23bg.svg'
import temp23fg from '../../../../shared/assets/images/ai-creative/temp23fg.svg'

export const Template23Config = {
  hidden: true,
  name: 'Black Friday Mono',
  containerId: 'template23-carousel-single',
  forceDiscount: true,
  backgroundColor: '#9A9A9A',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp23bg,
      draggable: false,
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp23fg,
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 650,
        maxHeight: 750,
      },
      dynamicSrc: 'productImage',
      center: true,
      grayscale: true,
      contrast: 10,
      brighten: -0.35,
    },
    // Primary Text (Discount Percentage)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      width: WORKBENCH_SIZE.width / 2,
      height: WORKBENCH_SIZE.height / 2,
      text: '30% OFF', // Dynamic placeholder
      fontFamily: 'Anuphan',
      fontSize: 101.3688,
      color: 'color2',
      align: 'center',
      center: true,
      lines: true,
      verticalAlign: 'middle',
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 325,
        y: WORKBENCH_SIZE.height - 80,
      },
      width: 650,
      text: 'CODE: GET30', // Dynamic placeholder
      fontFamily: 'Anuphan',
      fontSize: 55.38,
      color: 'color2',
      align: 'center',
      fontIndex: 2,
    },
  ],
}
