import { WORKBENCH_SIZE } from '../../../support/constants'
import temp12bg from '../../../../shared/assets/images/ai-creative/temp12bg.svg'

export const Template12Config = {
  name: 'Minimal Burst',
  containerId: 'template12-carousel-single',
  backgroundColor: 'white',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp12bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#880F00',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 40 + 650 / 2,
        y: 68 + 650 / 2,
      },
      scale: {
        maxWidth: 574,
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      center: true,
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp12bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#880F00',
      draggable: false,
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width - 310,
        y: WORKBENCH_SIZE.height - 250,
      },
      scale: {
        maxHeight: 140,
        maxWidth: 300,
      },
      dynamicSrc: 'companyLogo',
      required: false,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 60,
        y: WORKBENCH_SIZE.height - 100,
      },
      width: 800,
      fontFamily: 'Bayon',
      fontSize: 40,
      color: 'color3',
      containerBg: 'color2',
      toUpperCase: true,
      fontIndex: 1,
      dynamicContent: 'productName',
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 60,
        y: WORKBENCH_SIZE.height - 270,
      },
      text: '30% DISCOUNT',
      fontFamily: 'Bayon',
      fontSize: 86,
      color: 'color1',
      letterSpacing: 0.03,
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 60,
        y: WORKBENCH_SIZE.height - 190,
      },
      text: 'USE CODE: GET30',
      fontFamily: 'Bayon',
      fontSize: 48,
      color: 'color3',
      letterSpacing: 2.5,
      fontIndex: 2,
    },
  ],
}
