import { useDispatch, useSelector } from "react-redux"

import errorHandler from "../util/errorHandler"
import { aiCreativesActions } from "../../store/ai-creatives"
import { authActions } from "../../store/auth"
import { useHttpClient } from "./http-hook"
import { useEnhencedCatalog } from "./enhenced-catalog-hook"

const useGenerativeImages = () => {
    const { sendRequest } = useHttpClient()
    const userId = useSelector(state => state.auth.user.id)
    const { productOnDraft } = useEnhencedCatalog()

    const generateImage = async ({
        templateId,
        exampleId,
        x = 200,
        y = 100,
        scalingFactor = 0.5
    }) => {
        console.log("will generate")
        let imagePath = productOnDraft.transparentImageUrl.substring(productOnDraft.transparentImageUrl.indexOf("ai-creative/"))
        const body = {
            "userId": userId,
            "productImageUrl": imagePath,
            "bgId": templateId,
            "bgExampleId": exampleId,
            "scalingFactor": scalingFactor,
            "location": [parseInt(x), parseInt(y)],
            "objectId": "8"
        }
        console.log("body")
        console.log(body)
        try {
            const response = await fetch(
                `${process.env.REACT_APP_AI_CREATIVE_GENERATIVE_IMAGES_URL}/generate`,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            
            // Handle the image response
            const blob = await response.blob()
            return URL.createObjectURL(blob)
        } catch (error) {
            console.log(error)
        }
    }

    return {
        generateImage
    }
}

export default useGenerativeImages