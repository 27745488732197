import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { FaCheck, FaEdit, FaExclamationTriangle } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import { useModal } from '../../../shared/hooks/modal-hook'
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import Modals from '../../../shared/enums/Modals'

import styles from './ProductList.module.css'

const ProductList = ({ setIsShowingTemplates }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    selectedProductsWithConfigurations,
    productOnDraft,
    getSavedConfigurationOfProduct,
    setInitialConfiguration,
    savedConfigurationOfProductOnDraft,
    draftConfiguration,
    isApplyingConfiguration,
    history,
  } = useEnhencedCatalog()
  const { openModal } = useModal()
  const hasShownTemplatesScreen = useRef(false)
  const { state } = useLocation()

  const selectProduct = product => {
    const savedConfiguration = getSavedConfigurationOfProduct(product.id)

    const draftConfigurationToSet = savedConfiguration
      ? {
          ...savedConfiguration,
          productName: savedConfiguration.productName,
        }
      : getCurrentSavedConfiguration(product.name)

    if (draftConfigurationToSet) {
      setIsShowingTemplates(false)
      dispatch(
        aiCreativeEditorActions.setDraftConfiguration({
          initial: true,
          ...draftConfigurationToSet,
        }),
      )

      // When we make position changes but don't make any changes to the configuration,
      // we need to unset the configuration to be able to set the new one that belongs to the selected product.
      if (!productOnDraft?.isSaved) {
        dispatch(aiCreativeEditorActions.unsetConfigurationOfProductOnDraft())
      }
    } else if (!hasShownTemplatesScreen.current) {
      setInitialConfiguration({ type: 'normal' })
      setIsShowingTemplates(true)
      hasShownTemplatesScreen.current = true
    }

    dispatch(
      aiCreativeEditorActions.setDraftConfiguration({
        initial: true,
        productName: product.name,
      }),
    )
    dispatch(aiCreativeEditorActions.setProductOnDraft(product))
  }

  const getCurrentSavedConfiguration = productName => {
    if (!savedConfigurationOfProductOnDraft) {
      return null
    }

    return {
      ...savedConfigurationOfProductOnDraft,
      productName,
    }
  }

  const tryToSelectProduct = async product => {
    if (isApplyingConfiguration || productOnDraft.id === product.id) {
      return
    }

    const hasPositionChanges = !productOnDraft.isSaved && history?.length > 1
    const hasUnsavedConfiguration =
      !productOnDraft.isSaved && !draftConfiguration.initial

    if (
      product.configuration &&
      (hasPositionChanges || hasUnsavedConfiguration) &&
      productOnDraft.id !== product.id
    ) {
      const confirmed = await openModal({
        type: Modals.ARE_YOU_SURE,
        question:
          'You have unsaved changes. You will lose them, if you proceed. Do you really want to proceed?',
      })

      if (confirmed) {
        selectProduct(product)
      }
      return
    }

    selectProduct(product)
  }

  useEffect(() => {
    selectProduct(productOnDraft)
  }, [])

  return (
    <>
      <div className={styles.productListHeader}>
        <div className={styles.productListTitle}>Choose a product</div>
        <div
          className={styles.manageProducts}
          onClick={() => {
            navigate(`/ai-ads/creative-lab/products-selection?from=editor`)
          }}
        >
          <FaEdit className={styles.manageProductsIcon} />
          Manage products
        </div>
      </div>
      <div className={styles.productListProducts}>
        {selectedProductsWithConfigurations.map(product => (
          <ProductItem
            key={product.id}
            product={product}
            isSelected={productOnDraft?.id === product.id}
            isSaved={product.isSaved}
            hasError={product.error}
            onClick={() => tryToSelectProduct(product)}
          />
        ))}
      </div>
    </>
  )
}

const ProductItem = ({ product, isSelected, isSaved, hasError, onClick }) => {
  return (
    <Tooltip
      size="sm"
      content={
        <div className={styles.productNameTooltipContent}>
          {product.name}
          {hasError && (
            <div className={styles.errorTooltipContent}>{product.error}</div>
          )}
        </div>
      }
      offset={7}
      position="bottom"
      key={product.id}
    >
      <div
        className={`${styles.productListProduct} ${
          isSelected ? styles.productListProductSelected : ''
        }`}
        onClick={onClick}
        key={product.id}
      >
        {isSaved && (
          <div className={styles.savedBadge}>
            <FaCheck />
          </div>
        )}
        {hasError && !isSaved && (
          <div className={styles.errorBadge}>
            <FaExclamationTriangle />
          </div>
        )}
        <div className={styles.productListProductImage}>
          <img src={product.originalImageUrl} alt={product.name} />
        </div>
      </div>
    </Tooltip>
  )
}

export default ProductList
