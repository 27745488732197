import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { adminActions, resettedSignUpFilters } from '../../store/admin'
import { useAdmin } from '../../shared/hooks/admin-hook'
import SignUpsList from '../components/sign-ups/SignUpsList'
import FiltersSection from '../components/listing-components/FiltersSection'
import AdminPanelSignUpsSummarySection from '../components/sign-ups/AdminPanelSignUpsSummarySection'

import './SignUps.css'
import helper from '../../shared/util/helper'

const SignUps = () => {
  const dispatch = useDispatch()
  const { signUps, signUpFilters } = useSelector(state => state.admin)
  const { managerUsers } = useSelector(state => state.admin)

  const { getSignUps, getNoAlarmUserCounts } = useAdmin()

  const [isLoading, setIsLoading] = useState(false)
  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const [searchText, setSearchText] = useState(
    JSON.parse(sessionStorage.getItem('signUpsSearch')) || '',
  )
  const [tempFilter, setTempFilter] = useState(signUpFilters)

  useEffect(() => {
    if (signUps.length) {
      return
    }
    const savedFilters = sessionStorage.getItem('signUpFilters')

    if (savedFilters) {
      const initialFilter = JSON.parse(savedFilters)

      getSignUpsData({ freshFetch: true, initialFilter })
    } else {
      getSignUpsData({ freshFetch: true })
    }
  }, [])

  useEffect(() => {
    setTempFilter(signUpFilters)
  }, [signUpFilters])

  useEffect(() => {
    getNoAlarmUserCounts({
      filters: {
        type: ["Sign Up"]
      }
    })
  }, [])

  const handleFilterChange = ({ groupName, type, value, checked }) => {
    switch (type) {
      case "range":
        const [group, input] = groupName.split('.')
        setTempFilter(prevFilter => {
          const prevGroup = prevFilter[group]

          return {
            ...prevFilter,
            [group]: {
              ...prevGroup,
              [input]: Number(value),
            },
          }
        })
        break;
      case 'checkbox':
        if (groupName === 'sortBy' || groupName === 'completed' || groupName === 'isSignUpMeetingDone') {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [value] : [],
            }
          })
        } else if (groupName === 'performanceManager' || groupName === 'accountManager' || groupName === 'salesPerson') {
          let objId;
          if (value !== '<Not set>') {
            objId = helper.findBy(Object.values(managerUsers), 'name', value)._id
          } else {
            objId = value
          }

          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, objId] : prevGroup.filter(item => item !== objId),
            }
          })
        } else {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, value]
                : prevGroup.filter(item => item !== value),

            }
          })
        }
        break;
      case 'date-range':
        setTempFilter(prevFilter => {
          const { user, ...filter } = prevFilter
          return {
            ...filter,
            [groupName]: value,
          }
        })
        break
      case "hour-range":
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break;
      default:
        break
    }

    setIsFilterChanged(true)
  }

  const handleApplyFilter = () => {
    const filter = { ...tempFilter }

    dispatch(adminActions.setSignUpFilters(filter))
    dispatch(adminActions.setSignUps([]))
    sessionStorage.setItem('signUpFilters', JSON.stringify(tempFilter))
    getSignUpsData({ freshFetch: true, filter })
    setIsFilterChanged(false)
  }

  const resetFilters = () => {
    const filter = { ...resettedSignUpFilters }

    setTempFilter(filter)
    dispatch(adminActions.resetSignUpFilters())
    sessionStorage.setItem(
      'signUpFilters',
      JSON.stringify(resettedSignUpFilters),
    )
    getSignUpsData({ freshFetch: true, filter })
    setIsFilterChanged(false)
  }

  const getSignUpsData = async ({
    freshFetch = false,
    filter = signUpFilters,
    search = searchText,
  }) => {
    setIsLoading(true)
    await getSignUps({ freshFetch, filter, search })
    setIsLoading(false)
  }

  const doSearch = async text => {
    dispatch(adminActions.setSignUps([]))
    setSearchText(text)
    getSignUpsData({ freshFetch: true, search: text })
    sessionStorage.setItem('signUpsSearch', JSON.stringify(text))
  }

  return (
    <div className="sign-ups-container">
      <div className="layout">
        <div className="content">
          {!isLoading && !signUps.length ? (
            <h2 className="not-found-text">
              No sign ups found for these filters.
            </h2>
          ) : (
            <SignUpsList
              isFetching={isLoading}
              reFetch={() => {
                getSignUpsData({ freshFetch: false })
              }}
            />
          )}
        </div>
        <FiltersSection
          page='signUps'
          isFilterChanged={isFilterChanged}
          handleApplyFilter={handleApplyFilter}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          doSearch={doSearch}
          searchText={searchText}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />
      </div>
      <AdminPanelSignUpsSummarySection />
    </div>
  )
}

export default SignUps
