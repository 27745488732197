import React from 'react'
import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner'

import './AdminUserDetailLayout.css'

function AdminUserDetailLayout({ loading, children }) {
  return (
    <div className="performance-user-detail-layout">
      {/* <Drawer dir="left">
        <PerformanceUserDetailNav />
      </Drawer> */}
      <div className='content-container-wrapper'>
        <div className="content-container">
          {loading ? <LoadingSpinner /> : children}
          {/* {children} */}
        </div>
      </div>
    </div>
  )
}

function Header({ children }) {
  return <div className="header-content">{children}</div>
}

function Main({ children }) {
  return <div className="main-content">{children}</div>
}

function Side({ children }) {
  return <div className="side-content">{children}</div>
}

AdminUserDetailLayout.Header = Header
AdminUserDetailLayout.Main = Main
AdminUserDetailLayout.Side = Side

export default AdminUserDetailLayout
