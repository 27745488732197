import React, { useMemo, useState } from 'react';
import { FaSyncAlt } from 'react-icons/fa';
import metaLogo from '../../../shared/assets/images/icons/meta-logo.png';
import numberFormatter from '../../../shared/util/numberFormatter';
import helper from '../../../shared/util/helper';
import Tooltip from '../../../shared/components/UIElements/Tooltip';
import { getShortElapsedTimeString } from '../../../shared/util/dateFormatter';
import Button from '../../../shared/components/FormElements/Button';
import { useModal } from '../../../shared/hooks/modal-hook';
import './CustomerCard.css';
import Modals from '../../../shared/enums/Modals';
import { adminActions } from '../../../store/admin'
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import errorHandler from '../../../shared/util/errorHandler';
import Switch from '../../../shared/components/UIElements/Switch';
import ReportComparisonTypes from '../../../shared/enums/ReportComparisonTypes';

const CustomerCardReportSummaryPartAlternative = ({
	comparisonDataPreview,
	currency,
	popupUserFacebookAds,
	dayCountBeforeEnhencer,
	reportLastUpdatedAt,
	weeklyReports,
	userId,
	username,
	onChangeComparisonChartVisibility,
	comparisonChartVisibility,
	isAgencyPage
}) => {
	const { openModal } = useModal();
	const { sendRequest, isLoading } = useHttpClient()
	const dispatch = useDispatch()
	const authToken = useSelector(state => state.auth.token)

	const [visibleSections, setVisibleSections] = useState({
		beforeAfter: comparisonChartVisibility?.beforeAfter ?? true,
		aiAdsVsOthersRoas: comparisonChartVisibility?.aiAdsVsOthersRoas ?? true,
		aiAdsVsOthersShares: comparisonChartVisibility?.aiAdsVsOthersShares ?? true,
	});

	const toggleSection = (section, value) => {
		const newVisibleSections = { ...visibleSections };
		newVisibleSections[section] = value;
		setVisibleSections(newVisibleSections);
		onChangeComparisonChartVisibility(newVisibleSections);
	};

	const refreshReports = async (e) => {
		e.stopPropagation()

		try {
			let result = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/refreshDailyFacebookReport`,
				'POST',
				JSON.stringify({
					userId
				}),
				{
					Authorization: 'Bearer ' + authToken,
					'Content-Type': 'application/json',
				},
			)

			dispatch(adminActions.updateCustomerFieldInList({
				userId,
				fieldPath: "facebookAds.weeklyReports",
				value: result.weeklyReports
			}))

			toast.success('Report is up to date now!')
		} catch (err) {
			errorHandler(err)
		}
	}

	const getComparisonTextClassName = (a, b) => {
		if (a >= b) return 'up'
		if (a < b) return 'down'
		return ''
	}

	const longerTrendAnalysis = useMemo(() => {
		if (comparisonDataPreview?.quarterlySpendTrend) {
			return {
				title: "Quarterly",
				value: comparisonDataPreview.quarterlySpendTrend
			}

		} else if (comparisonDataPreview?.monthlySpendTrend) {
			return {
				title: "Monthly",
				value: comparisonDataPreview.monthlySpendTrend
			}

		} else if (comparisonDataPreview?.biWeeklySpendTrend) {
			return {
				title: "Bi-Weekly",
				value: comparisonDataPreview.biWeeklySpendTrend
			}
		}
		return null;
	}, [comparisonDataPreview])

	if (!comparisonDataPreview) {
		return (
			<div className="comparison empty-state">
				<div className="heading">
					<span className="large">
						<img src={metaLogo} alt="Meta Logo" />
						Comparison Data
					</span>
				</div>
				<div className="empty-message">
					<div>Comparison data is empty</div>
					<Button
						className="empty-state-refresh-button"
						onClick={refreshReports}
						loading={isLoading}
						loadingText="Fetching..."
					>
						<FaSyncAlt />
						Fetch Report Data
					</Button>
				</div>
			</div>
		);
	}

	const beforeAfter = comparisonDataPreview.beforeAfter || {};

	const sales = beforeAfter.sales || { before: 0, after: 0 };
	const roas = beforeAfter.roas || { before: 0, after: 0 };
	const spend = beforeAfter.spend || { before: 0, after: 0 };



	const millisSinceLastUpdate = reportLastUpdatedAt ? Date.now() - reportLastUpdatedAt : null;

	const refreshTooltipContent = (
		<>
			{reportLastUpdatedAt ? (
				<span>Last updated: {getShortElapsedTimeString(new Date(reportLastUpdatedAt))} ago <br /></span>
			) : ''}
			<span>
				{millisSinceLastUpdate && (millisSinceLastUpdate / (60 * 60 * 1000)) < 1
					? "\nAt least an hour should pass for an update"
					: "\nClick to update"
				}
			</span>
		</>
	);


	const formatDate = (dateString) => {
		if (!dateString) return "<No date>"
		const date = new Date(dateString);
		const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		const year = date.getFullYear()

		const month = date.getMonth() + 1

		return `${day}/${month}/${year}`;
	};

	return (
		<>
			<div className="comparison">
				<div className="overview">
					<Tooltip
						content={isLoading ? "" : refreshTooltipContent}
						placement="top">
						<Button
							className={`corner-refresh-button ${isLoading ? 'loading' : ''}`}
							disabled={millisSinceLastUpdate && (millisSinceLastUpdate / (60 * 60 * 1000)) < 1}
							loading={isLoading}
							loadingText="Fetching..."
							onClick={refreshReports}
						>
							<FaSyncAlt />
							<span>Refresh Report Data</span>
						</Button>
					</Tooltip>
				</div>
				<div className="results three-columns">
					<div className="column before-after" onClick={() => {
						openModal({
							username,
							type: Modals.COMPARISON_MULTIPLE_MODAL,
							closeOnClickOutside: true,
							popupUserFacebookAds: popupUserFacebookAds,
							userId: userId,
							initialTab: 'before-after',
						});
					}}>
						<div className="heading">
							<span className="large">
								<img src={metaLogo} alt="Meta Logo" />
								<Tooltip content={`
		Last ${dayCountBeforeEnhencer} Days: ${formatDate(weeklyReports.enhOverall?.dateStart)}-${formatDate(weeklyReports.enhOverall?.dateEnd)}\n\n 
		Before vs After: ${formatDate(weeklyReports.beforeEnhencerSlice?.dateStart)}-${formatDate(weeklyReports.beforeEnhencerSlice?.dateEnd)}`
								}>
									<div className="title-container">
										<span className="days">Last {dayCountBeforeEnhencer} Days</span>
										<span className="separator">/</span>
										<span className="before-ai-ads">Before vs After AI Ads</span>
									</div>
								</Tooltip>
							</span>

						</div>
						<div className="results">
							<div className="items-wrapper">
								<Tooltip content="Total revenue from your ads" placement="top">
									<div className="item">
										<div className="value">
											<span className={`meta ${getComparisonTextClassName(
												sales.after,
												sales.before
											)}`}>
												<span>
													<span className="currency">{currency ? helper.getCurrencySymbol(currency) : '$'}</span>
													<span>
														{numberFormatter.shortenNumber(sales.after)}
													</span>
												</span>
												<span className="slash">/</span>
											</span>
											<span className="before">
												<span className="currency">{currency ? helper.getCurrencySymbol(currency) : '$'}</span>
												<span>
													{numberFormatter.shortenNumber(sales.before)}
												</span>
											</span>
										</div>
										<div className="description">Revenue</div>
									</div>
								</Tooltip>

								<Tooltip content="Total cost spent on your ads" placement="top">
									<div className="item">
										<div className="value">
											<span className={`meta ${getComparisonTextClassName(
												spend.after,
												spend.before
											)}`}>
												<span>
													<span className="currency">{currency ? helper.getCurrencySymbol(currency) : '$'}</span>
													<span>
														{numberFormatter.shortenNumber(spend.after)}
													</span>
												</span>
												<span className="slash">/</span>
											</span>
											<span className="before">
												<span className="currency">{currency ? helper.getCurrencySymbol(currency) : '$'}</span>
												<span>
													{numberFormatter.shortenNumber(spend.before)}
												</span>
											</span>
										</div>
										<div className="description">Cost</div>
									</div>
								</Tooltip>

								<Tooltip content="Return on Ad Spend (Revenue/Cost)" placement="top">
									<div className="item">
										<div className="value">
											<span className={`meta ${getComparisonTextClassName(
												roas.after,
												roas.before
											)}`}>
												<span>
													{numberFormatter.formatNumber(roas.after, 2)}
												</span>
												<span className="slash">/</span>
											</span>
											<span className="before">
												{numberFormatter.formatNumber(roas.before, 2)}
											</span>
										</div>
										<div className="description">ROAS</div>
									</div>
								</Tooltip>
							</div>
						</div>
						{!isAgencyPage && (
							<div className="section-switch" onClick={(e) => { e.stopPropagation(); }}>
								<Switch
									options={['✓', '✕']}
									value={visibleSections.beforeAfter ? '✓' : '✕'}
									onChange={(value) => toggleSection('beforeAfter', value === '✓')}
								/>
							</div>
						)}
					</div>
					<div className="column" onClick={() => {
						openModal({
							username,
							type: Modals.COMPARISON_MULTIPLE_MODAL,
							closeOnClickOutside: true,
							currency,
							initialTab: 'trend-analysis',
							popupUserFacebookAds: popupUserFacebookAds,
							userId: userId
						});
					}}>
						<h4>Trend Analysis</h4>
						<span className="roas-text">(Ad Spend)</span>
						<div className="items-wrapper">
							{
								dayCountBeforeEnhencer < 14 ?
									<Tooltip content="For trend analysis AI Ads should run at least for 14 days" position="top">
										<div className="item">
											<div className="value">
												-
											</div>
											<div className="description">
												Not enough data
											</div>
										</div>
									</Tooltip>
									:
									<div className="item">
										<div className="value">
											<span className={`trend-value ${comparisonDataPreview.weeklySpendTrend > 0 ? 'better' : 'worse'}`}>
												<span data-symbol="%">{numberFormatter.formatNumber(comparisonDataPreview.weeklySpendTrend, 1)}</span>
											</span>
										</div>
										<div className="description">Weekly</div>
									</div>
							}

							{
								longerTrendAnalysis &&
								<div className="item">
									<div className="value">
										<span className={`trend-value ${longerTrendAnalysis.value > 0 ? 'better' : 'worse'}`}>
											<span data-symbol="%">{numberFormatter.formatNumber(longerTrendAnalysis.value, 1)}</span>
										</span>
									</div>
									<div className="description">{longerTrendAnalysis.title}</div>
								</div>
							}
						</div>
					</div>
					<div className="column" onClick={() => {
						openModal({
							username,
							type: Modals.COMPARISON_MULTIPLE_MODAL,
							closeOnClickOutside: true,
							currency,
							popupUserFacebookAds: popupUserFacebookAds,
							userId: userId,
							initialTab: ReportComparisonTypes.AI_ADS_VS_OTHERS
						});
					}}>
						<h4>AI Ads vs Others</h4>
						<span className="roas-text">(ROAS)</span>
						{
							dayCountBeforeEnhencer < 3 ?

								<Tooltip content="Comparison data will be available after 3 days" position="top">
									<div className="items-wrapper">
										<div className="item">
											<div className="value">
												-
											</div>
											<div className="description">
												Not enough data
											</div>
										</div>
									</div>
								</Tooltip>
								:
								<div className="items-wrapper">
									<Tooltip content="7 days Click" position="top">
										<div className="item">
											<div className="value">
												<span className={`trend-value ${comparisonDataPreview.avgRoas > comparisonDataPreview.avgOthersRoas ? 'better' : 'worse'}`}>
													<span data-symbol="x">{numberFormatter.formatNumber(comparisonDataPreview.avgRoas, 2)}</span>
												</span>
											</div>
											<div className="description">AI Ads</div>
										</div>
									</Tooltip>
									<Tooltip content="7 days Click" position="top">
										<div className="item">
											<div className="value">
												<span className="trend-value others">
													<span data-symbol="x">{numberFormatter.formatNumber(comparisonDataPreview.avgOthersRoas, 2)}</span>
												</span>
											</div>
											<div className="description">Others</div>
										</div>
									</Tooltip>
								</div>
						}
						{!isAgencyPage && (
							<div className="section-switch" onClick={(e) => { e.stopPropagation(); }}>
								<Switch
									options={['✓', '✕']}
									value={visibleSections.aiAdsVsOthersRoas ? '✓' : '✕'}
									onChange={(value) => toggleSection('aiAdsVsOthersRoas', value === '✓')}
								/>
							</div>
						)}
					</div>
					<div className="column ai-ads-share" onClick={() => {
						openModal({
							username,
							type: Modals.COMPARISON_MULTIPLE_MODAL,
							closeOnClickOutside: true,
							popupUserFacebookAds: popupUserFacebookAds,
							userId: userId,
							initialTab: ReportComparisonTypes.AI_ADS_VS_OTHERS,
						});
					}}>
						<h4>AI Ads Share</h4>
						{
							dayCountBeforeEnhencer < 3 ?
								<Tooltip content="Comparison data will be available after 3 days" position="top">
									<div className="items-wrapper">
										<div className="item">
											<div className="value">
												-
											</div>
											<div className="description">
												Not enough data
											</div>
										</div>
									</div>
								</Tooltip>
								:

								<div className="items-wrapper">
									<div className="item">
										<div className="value">
											<span className="meta" style={{
												color: comparisonDataPreview.enhencerSalesShare > 0 ? 'var(--main-green)' : 'inherit'
											}}>
												<span data-symbol="%">{numberFormatter.formatNumber(comparisonDataPreview.enhencerSalesShare, 1)}</span>
											</span>
										</div>
										<div className="description">Sales</div>
									</div>
									<div className="item">
										<div className="value">
											<span className="meta" style={{ color: '#f8b724' }}>
												<span data-symbol="%">{numberFormatter.formatNumber(comparisonDataPreview.enhencerSpendShare, 1)}</span>
											</span>
										</div>
										<div className="description">Ad Spend</div>
									</div>
								</div>
						}
						{!isAgencyPage && (
							<div className="section-switch" onClick={(e) => { e.stopPropagation(); }}>
								<Switch
									options={['✓', '✕']}
									value={visibleSections.aiAdsVsOthersShares ? '✓' : '✕'}
									onChange={(value) => toggleSection('aiAdsVsOthersShares', value === '✓')}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CustomerCardReportSummaryPartAlternative; 