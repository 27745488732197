import React, { useState } from 'react'

import Button from '../../shared/components/FormElements/Button'
import dateFormatter from '../../shared/util/dateFormatter'

import upgrade from '../../shared/assets/images/admin/upgrade-plan.svg'
import { FaChevronUp } from 'react-icons/fa'
import numberFormatter from '../../shared/util/numberFormatter'
import { useUser } from '../../shared/hooks/user-hook'
import SubscriptionPlanInfoCard from './SubscriptionPlanInfoCard'

const SubscriptionDetails = props => {
  const {
    status,
    nextPaymentDate,
    lastPaymentDate,
    subscriptionCreatedDate,
    isFromShopify,
    activePlanName,
    error,
    retryPayment,
    upgradePlan,
    usageCharges,
    monthlyAdSpent,
  } = props

  const { getPackageFeatures } = useUser()

  const [nextPackage, setNextPackage] = useState()
  const currentPackage = getPackageFeatures()

  return (
    <div className="subscribed">
      <div className="subscribe-container">
        <div className="left-part">
          <SubscriptionPlanInfoCard pageType="subscription" />

          <div className="visitor-count-part">
            <div className="visitor-count-header">
              <div>
                Maximum ad spent per month for current plan:{' '}
                <b>${currentPackage.maxAdSpent || 1500}</b>
              </div>
              <div>
                Your ad spent this month:{' '}
                <span className="green-text">
                  {monthlyAdSpent
                    ? '$' + numberFormatter.formatNumber(monthlyAdSpent, 0)
                    : 'Waiting data...'}
                </span>{' '}
              </div>
            </div>
          </div>

          <div className="bottom-payment-info">
            <h4>Your Plan Details</h4>
            <div className="payment-info-row header">
              <div className="plan-info-part">Created Date</div>
              <div className="plan-info-part">Subscription Plan</div>
              <div className="plan-info-part">Last Payment</div>
              <div className="plan-info-part">Next Payment</div>
              <div className="plan-info-part">Status</div>
              {isFromShopify ? <div className="plan-info-part"></div> : ''}
            </div>
            <div className="payment-info-row body">
              <div className="plan-info-part">
                {' '}
                {subscriptionCreatedDate
                  ? dateFormatter(subscriptionCreatedDate, true)
                  : ''}
              </div>
              <div className="plan-info-part">
                {activePlanName ? activePlanName : 'Custom'}
              </div>
              <div className={`plan-info-part ${error ? `error` : ``}`}>
                {error
                  ? `your payment could not be processed on ${dateFormatter(
                    lastPaymentDate,
                    true,
                  )}`
                  : lastPaymentDate
                    ? dateFormatter(lastPaymentDate, true)
                    : ''}
                {error ? (
                  <button className="retry-payment" onClick={retryPayment}>
                    Retry Payment
                  </button>
                ) : (
                  ``
                )}
              </div>
              <div className="plan-info-part">
                {nextPaymentDate ? dateFormatter(nextPaymentDate, true) : ''}
              </div>
              <div className="plan-info-part subscription-status">
                <span className={status}>{status}</span>
              </div>
              {isFromShopify ? (
                <div className="plan-info-part">
                  <span>
                    <div className="upgrade-plan-button">
                      <Button
                        className="upgrade-plan"
                        onClick={() => upgradePlan(nextPackage)}
                      >
                        Upgrade Plan <FaChevronUp />{' '}
                      </Button>
                    </div>
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          {usageCharges?.length ? (
            <div className="bottom-payment-info usage-charges">
              <h4>Additional Fees</h4>

              <div className="payment-info-row header">
                <div className="plan-info-part">Name</div>
                <div className="plan-info-part">Created Date</div>
                <div className="plan-info-part">Price</div>
                <div className="plan-info-part">Billing On</div>
              </div>
              {usageCharges.map(charge => (
                <div
                  className={`payment-info-row body ${charge.name === 'Total' ? 'total' : ''}`}
                  key={charge.id}
                >
                  <div className="plan-info-part">
                    {charge.name || 'AI Ads Weekly Revenue Charge'}
                  </div>
                  <div className="plan-info-part">
                    {dateFormatter(new Date(charge.created_at), true)}
                  </div>
                  <div className="plan-info-part">${charge.price}</div>

                  <div className="plan-info-part">
                    {nextPaymentDate
                      ? dateFormatter(nextPaymentDate, true)
                      : ''}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionDetails
