import React, { useEffect, useState } from "react";
import ChecklistCard from "../../shared/components/UIElements/ChecklistCard";

import "./AdCreativeLibraryBox.css"
import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";
import { useSelector } from "react-redux";
import { FaThumbsUp } from "react-icons/fa";
import { useFacebookCreative } from "../../shared/hooks/facebook/creative-hook";
const AdCreativeLibraryBox = ({ }) => {

    const adLibrary = useSelector(state => state.facebook.adCreativeLibrary)
    const selectedAdAccountId = useSelector(state => state.facebook.selectedAdAccount?.id)

    const adLibraryOfAdAccount = adLibrary?.[selectedAdAccountId]
    const isFetchingAdLibrary = useSelector(state => state.facebook.loadings?.adCreativeLibrary)
    const creativesList = adLibraryOfAdAccount?.creatives ? Object.values(adLibraryOfAdAccount.creatives) : []
    const fetched = adLibraryOfAdAccount?.updatedAt

    const { updateCreativeLibraryIfNecessary } = useFacebookCreative()

    useEffect(() => {
        if (selectedAdAccountId && !fetched && !isFetchingAdLibrary) {
            updateCreativeLibraryIfNecessary({ adAccountId: selectedAdAccountId })
        }
    }, [selectedAdAccountId])

    return <ChecklistCard className="ad-creative-library-box" number={8} name="Fetch Ad Library" completed={fetched}>

        {
            isFetchingAdLibrary ? <InlineLoadingSpinner message="Fetching your ad library..." /> :
                fetched ?
                    <div>

                        {
                            creativesList.length ?
                                <div className="all-set">
                                    <FaThumbsUp className="icon" /> <br /><br />
                                    <div>
                                        Your ad library is ready! You can use it in your full-funnel campaigns.
                                    </div>
                                </div>
                                :
                                <>
                                    Looks like you don't have any previously performed ads in your ad account.
                                    <br />
                                    <br />
                                    Don't worry, we can start from scratch!
                                </>
                        }
                    </div>
                    :
                    <div>
                        We will fetch your ad library from Facebook. This may take up to a minute.
                    </div>
        }


    </ChecklistCard>
}

export default AdCreativeLibraryBox;