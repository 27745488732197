import React, { useRef, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import { FaTimes } from 'react-icons/fa'
import { MdDone, MdEvent, MdOutlineAlarm } from 'react-icons/md'
import OneAlarm from '../../../admin/components/alarms/OneAlarm'

const SeeAllAlarms = props => {
  const { openModal, closeModal, modalData } = useModal()

  const [noteValue, setNoteValue] = useState(props.data.noteText ?? "")
  let noteInputRef = useRef()

  const create = () => {
    const enteredNote = noteInputRef.current.value
    modalData.callback({ note: enteredNote })
    closeModal()
  }

  const close = () => {
    closeModal()
  }


  function convertTimestampToCustomFormat(timestamp) {
    // Create a new Date object with the timestamp in milliseconds
    var date = new Date(timestamp);

    // Define month names array
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Extract day, month, hour, and minute from the date object
    var day = date.getDate();
    var month = monthNames[date.getMonth()];
    var hour = date.getHours();
    var minute = date.getMinutes();
    let dontShowTime = false;
    if (hour === 23 && minute === 59) {
      dontShowTime = true;
    }

    // Format the output string
    var formattedTime = day + " " + month + (dontShowTime ? "" : (" - " + hour.toString().padStart(2, '0') + ":" + minute.toString().padStart(2, '0')));

    return formattedTime;
  }

  return (
    <div className="alarm-modal see-all-alarms-modal">
      <div className="modal-title">
        <div className='alarm-name'>All Alarms</div>
        <div>-</div>
        <div className='customer-name'>{props.data.customer.name}</div>
        <Button size="small" onClick={close}><FaTimes /></Button>
      </div>
      <div className='alarm-part-wrapper'>
        {props.data.alarms.map((oneAlarm) => {
          return <OneAlarm
            key={oneAlarm._id}
            alarm={oneAlarm}
            onClick={() => {
              props.data.openEditAlarmModal(oneAlarm)
            }}
            convertTimestampToCustomFormat={convertTimestampToCustomFormat}
          />


          const isOverdue = !oneAlarm.done && (new Date(oneAlarm.dueDate) < Date.now())
          return <div key={oneAlarm._id} className='one-alarm-container' onClick={() => {
            props.data.openEditAlarmModal(oneAlarm)
          }}>
            {
              isOverdue
                ? (<MdOutlineAlarm style={{ flex: "0 0 auto", color: "red", fontSize: "20px" }} />)
                : <MdDone style={{ flex: "0 0 auto", fontSize: "20px", color: oneAlarm.done ? "#016b92" : "transparent", }} />
            }
            <div className={`due-date ${isOverdue ? "over" : ""}`}>{convertTimestampToCustomFormat(oneAlarm.done ? oneAlarm.completedAt : oneAlarm.dueDate)}</div>
            <div className='text-bar'>
              {oneAlarm.meetingInfo ? <MdEvent color='var(--dark-blue)' size={15} /> : null}
              <div className='title'>{oneAlarm.title}</div>
              <div className='note'>{oneAlarm.note ? "(" + oneAlarm.note + ")" : ""}</div>
            </div>
          </div>
        })}
      </div>
    </div>
  )
}


export default SeeAllAlarms
