import React, { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'

import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import { useSupportTickets } from '../../shared/hooks/support-tickets-hook'
import ProfilePageLayout from '../components/ProfilePageLayout'
import TicketCard from '../components/TicketCard'
import { useModal } from '../../shared/hooks/modal-hook'
import Modals from '../../shared/enums/Modals'

import './Tickets.css'

const Tickets = () => {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { fetchTickets } = useSupportTickets()
  const { loading, tickets } = useSelector(state => state.supportTickets)
  const performanceManagerProfile = useSelector(
    state => state.auth.user?.crmDetails?.performanceManagerProfile,
  )
  const auth = useSelector(state => state.auth)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState()
  const [ticketsToShow, setTicketsToShow] = useState([])
  const tabs = ['all', 'open', 'closed']

  const breakpointColumnsObj = {
    default: 3,
    1200: 2,
    960: 1,
  }

  const handleTabChange = tab => {
    setSelectedTab(tab)

    if (tab === 'all') {
      return setSearchParams({})
    }

    setSearchParams({ filter: tab })
  }

  useEffect(() => {
    fetchTickets()
  }, [])

  useEffect(() => {
    if (loading) {
      return
    }

    const filter = searchParams.get('filter')

    if (!filter) {
      setTicketsToShow(tickets)
      return
    }

    if (filter === 'open') {
      setTicketsToShow(
        tickets.filter(
          ticket =>
            ticket.displayStatus !== 'completed' && ticket.displayStatus !== 'closed',
        ),
      )
      return
    }

    if (filter === 'closed') {
      setTicketsToShow(
        tickets.filter(
          ticket =>
            ticket.displayStatus === 'completed' || ticket.displayStatus === 'closed',
        ),
      )
      return
    }

    if (filter === 'unread_messages') {
      const filteredTickets = tickets.filter(ticket =>
        ticket.messages.some(
          message => message.receiver === auth.user._id && !message.read,
        ),
      )

      if (filteredTickets.length) {
        setTicketsToShow(filteredTickets)
        return
      }

      setTicketsToShow(tickets)
      navigate("/account/tickets") // Remove the "filter=unread_messages" query param from the URL
      return
    }

    setTicketsToShow(tickets)
  }, [loading, searchParams, tickets, auth.user._id])

  useEffect(() => {
    const filter = searchParams.get('filter')

    if (!filter) {
      return setSelectedTab('all')
    }

    if (!tabs.includes(filter)) {
      return
    }

    setSelectedTab(filter)
  }, [searchParams])

  return (
    <ProfilePageLayout>
      <ProfilePageLayout.Header></ProfilePageLayout.Header>
      <ProfilePageLayout.Content>
        <div className="tickets-page-content">
          <div className="buttons">
            <div className="tabs">
              {tabs.map(tab => (
                <div
                  key={tab}
                  onClick={() => handleTabChange(tab)}
                  className={`tab ${selectedTab === tab ? 'active' : ''}`}
                >
                  {tab.charAt(0).toUpperCase() + tab.slice(1)}
                </div>
              ))}
            </div>
            <button
              onClick={() =>
                openModal({
                  type: Modals.CREATE_A_SUPPORT_TICKET,
                  closeOnClickOutside: true,
                  performanceManager: performanceManagerProfile._id,
                })
              }
              className="new-ticket-button"
            >
              <FaPlus className="icon" />
              New Ticket
            </button>
          </div>
          {loading ? (
            <InlineLoadingSpinner message="Loading tickets..." />
          ) : !ticketsToShow.length ? (
            <div className="no-tickets">No tickets found</div>
          ) : (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="tickets-grid"
              columnClassName="tickets-grid_column"
            >
              {ticketsToShow.map(ticket => (
                <TicketCard key={ticket._id} ticket={ticket} />
              ))}
            </Masonry>
          )}
        </div>
      </ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

export default Tickets
