import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { toast } from "react-toastify";

import { useHttpClient } from "../../shared/hooks/http-hook";
import dateFormatter from "../../shared/util/dateFormatter";
import numberFormatter from "../../shared/util/numberFormatter";
import gradientMaker from "../../shared/util/gradientMaker";
import Table from "../../shared/components/UIElements/Table";
import InfoBadge from "../../shared/components/UIElements/InfoBadge";
import Card from "../../shared/components/UIElements/Card";
import CRMGeoChart from "../components/cards-components/CRMGeoChart";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import "./CRMReport.css"
import errorHandler from "../../shared/util/errorHandler";

const additionalFeeMap = {
    "Jan 2024": 330,
    "Feb 2024": 264,
    "Mar 2024": 1122,
    "Apr 2024": 463,
    "May 2024": 848,
    "Jun 2024": 1165,
    "Jul 2024": 194,
    "Aug 2024": 536,
    "Sep 2024": 1335,
    "Oct 2024": 1198,
    "Nov 2024": 2879,
    "Dec 2024": 1776,
    "Jan 2025": 1422,
    "Feb 2025": 2007
}
const additionalFeeYearlyMap = {
    "2024": 12110,
    "2025": 3429,
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

// HTML tooltip functions
const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div.chart-tooltip');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.className = 'chart-tooltip';
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.8)';
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 0;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .2s ease';
        tooltipEl.style.zIndex = '10';
        tooltipEl.style.boxShadow = '0 2px 8px rgba(0, 0, 0, 0.2)';
        tooltipEl.style.padding = '4px 6px';
        tooltipEl.style.minWidth = '190px';
        tooltipEl.style.fontSize = '12px';
        tooltipEl.style.fontFamily = "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const generateMrrTooltipContent = (tooltip, responseData) => {
    const dataIndex = tooltip.dataPoints[0].dataIndex;
    const isLastPoint = dataIndex === responseData.chartLabels.length - 1;
    const currentMRR = responseData.mrrChartData[dataIndex] ?? 0;
    const startOfMonthMRR = responseData.mrrChartData[dataIndex - 1] ?? 0;
    const churnCount = numberFormatter.formatNumber(responseData.monthlyChurnedMRRCountData[dataIndex]) ?? 0;
    const additionCount = numberFormatter.formatNumber(responseData.monthlyNewMRRCountData[dataIndex]) ?? 0;
    const newWelcomeRevenue = numberFormatter.formatNumber(responseData.monthlyNewWelcomeMRRCountData[dataIndex]) ?? 0;
    const monthLabel = responseData.chartLabels[dataIndex];

    let currencyInfo = "Currency: USD";
    if (responseData.exchangeRates && responseData.exchangeRates[monthLabel]) {
        currencyInfo = `Currency: 1 USD = ${responseData.exchangeRates[monthLabel]} TRY`;
    }

    // Create HTML content
    let tooltipHTML = `<div style="font-weight: bold; margin-bottom: 2px; font-size: 11px;">${monthLabel}</div>`;

    if (isLastPoint) {
        const totalOneTimeRevenue = responseData.monthlyNewMRRCountData[dataIndex] + responseData.monthlyNewWelcomeMRRCountData[dataIndex];
        const monthlyTotalRevenue = parseFloat(currentMRR) + parseFloat(newWelcomeRevenue.replace(/,/g, ''));

        tooltipHTML += `
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">📈</span>
                <span style="flex: 1;">MRR:</span>
                <strong>$${numberFormatter.formatNumber(parseFloat(startOfMonthMRR))} →  $${numberFormatter.formatNumber(parseFloat(startOfMonthMRR) - parseFloat(responseData.monthlyChurnedMRRCountData[dataIndex]) + parseFloat(totalOneTimeRevenue))}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">🛑</span>
                <span style="flex: 1;">Churn:</span>
                <strong>$${churnCount}</strong>
            </div>            
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">🧿</span>
                <span style="flex: 1;">New PoC:</span>
                <strong>$${numberFormatter.formatNumber(totalOneTimeRevenue)}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center;">
                <span style="font-size: 12px; margin-right: 4px;">💵</span>
                <span>${currencyInfo}</span>
            </div>
        `;
    } else {
        const monthlyTotalRevenue = parseFloat(currentMRR) + parseFloat(newWelcomeRevenue.replace(/,/g, ''));

        tooltipHTML += `
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">📈</span>
                <span style="flex: 1;">MRR:</span>
                <strong>$${numberFormatter.formatNumber(parseFloat(startOfMonthMRR))} → $${numberFormatter.formatNumber(parseFloat(currentMRR))}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">🟢</span>
                <span style="flex: 1;">New:</span>
                <strong>$${additionCount}</strong>
            </div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">🛑</span>
                <span style="flex: 1;">Churn:</span>
                <strong>$${churnCount}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">💰</span>
                <span style="flex: 1;">Paid PoC:</span>
                <strong>$${newWelcomeRevenue}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center;">
                <span style="font-size: 12px; margin-right: 4px;">💵</span>
                <span>${currencyInfo}</span>
            </div>
        `;
    }

    return tooltipHTML;
};

const externalMrrTooltipHandler = (context, responseData) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set HTML content
    if (tooltip.body) {
        tooltipEl.innerHTML = generateMrrTooltipContent(tooltip, responseData);
    }

    // Make the tooltip visible so we can calculate its dimensions
    tooltipEl.style.opacity = 1;

    // Get dimensions for positioning calculations
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const chartWidth = chart.width;
    const chartHeight = chart.height;
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;

    // Calculate tooltip position that keeps it inside the chart
    let left = positionX + tooltip.caretX;
    let top = positionY + tooltip.caretY - tooltipHeight - 10; // Default to positioning above the point

    // If tooltip would go off the top of the chart, place it below the point instead
    if (top < positionY) {
        top = positionY + tooltip.caretY + 10;
    }

    // If tooltip would go off the right side of the chart, shift it left
    if (left + tooltipWidth / 2 > positionX + chartWidth) {
        left = positionX + chartWidth - tooltipWidth / 2 - 5;
    }

    // If tooltip would go off the left side of the chart, shift it right
    if (left - tooltipWidth / 2 < positionX) {
        left = positionX + tooltipWidth / 2 + 5;
    }

    // Set the position
    tooltipEl.style.left = left + 'px';
    tooltipEl.style.top = top + 'px';
};

const generateActiveCustomersTooltipContent = (tooltip, responseData) => {
    const dataIndex = tooltip.dataPoints[0].dataIndex;
    const isLastPoint = dataIndex === responseData.chartLabels.length - 1;
    const monthLabel = responseData.chartLabels[dataIndex];
    const currentCount = responseData.activeCustomersChartData[dataIndex] ?? 0;
    const lastMonthCount = responseData.activeCustomersChartData[dataIndex - 1] ?? 0;
    const churnCount = responseData.monthlyChurnCountData[dataIndex] ?? 0;
    const additionCount = responseData.monthlyNewCustomerCountData[dataIndex] ?? 0;
    const welcomeCount = responseData.monthlyNewWelcomeCountData[dataIndex] ?? 0;

    // Create HTML content
    let tooltipHTML = `<div style="font-weight: bold; margin-bottom: 2px; font-size: 11px;">${monthLabel}</div>`;

    if (isLastPoint) {
        tooltipHTML += `
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">👥</span>
                <span style="flex: 1;">Customers:</span>
                <strong>${lastMonthCount} → ${lastMonthCount + additionCount + welcomeCount - churnCount}</strong>
            </div> 
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">🧿</span>
                <span style="flex: 1;">New PoC:</span>
                <strong>${welcomeCount + additionCount}</strong>
            </div>
            <div style="display: flex; align-items: center;">
                <span style="font-size: 12px; margin-right: 4px;">🛑</span>
                <span style="flex: 1;">Churn:</span>
                <strong>${churnCount}</strong>
            </div>
        `;
    } else {
        tooltipHTML += `
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">👥</span>
                <span style="flex: 1;">Customers:</span>
                <strong>${lastMonthCount} → ${currentCount}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">🟢</span>
                <span style="flex: 1;">New:</span>
                <strong>${additionCount}</strong>
            </div>
            <div style="display: flex; align-items: center;">
                <span style="font-size: 12px; margin-right: 4px;">🛑</span>
                <span style="flex: 1;">Churn:</span>
                <strong>${churnCount}</strong>
            </div>
            <div style="height: 1px; background: rgba(255,255,255,0.3); margin: 3px 0;"></div>
            <div style="display: flex; align-items: center; margin-bottom: 3px;">
                <span style="font-size: 12px; margin-right: 4px;">💰</span>
                <span style="flex: 1;">One-Time Paid PoC:</span>
                <strong>${welcomeCount}</strong>
            </div>
        `;
    }

    return tooltipHTML;
};

const externalActiveCustomersTooltipHandler = (context, responseData) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set HTML content
    if (tooltip.body) {
        tooltipEl.innerHTML = generateActiveCustomersTooltipContent(tooltip, responseData);
    }

    // Make the tooltip visible so we can calculate its dimensions
    tooltipEl.style.opacity = 1;

    // Get dimensions for positioning calculations
    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    const chartWidth = chart.width;
    const chartHeight = chart.height;
    const tooltipWidth = tooltipEl.offsetWidth;
    const tooltipHeight = tooltipEl.offsetHeight;

    // Calculate tooltip position that keeps it inside the chart
    let left = positionX + tooltip.caretX;
    let top = positionY + tooltip.caretY - tooltipHeight - 10; // Default to positioning above the point

    // If tooltip would go off the top of the chart, place it below the point instead
    if (top < positionY) {
        top = positionY + tooltip.caretY + 10;
    }

    // If tooltip would go off the right side of the chart, shift it left
    if (left + tooltipWidth / 2 > positionX + chartWidth) {
        left = positionX + chartWidth - tooltipWidth / 2 - 5;
    }

    // If tooltip would go off the left side of the chart, shift it right
    if (left - tooltipWidth / 2 < positionX) {
        left = positionX + tooltipWidth / 2 + 5;
    }

    // Set the position
    tooltipEl.style.left = left + 'px';
    tooltipEl.style.top = top + 'px';
};

const CRMReport = props => {
    const auth = useSelector((state) => state.auth);
    const { sendRequest, isLoading } = useHttpClient();
    const [totalActiveCustomers, setTotalActiveCustomers] = useState(0);
    const [retainedCustomersCount, setRetainedCustomersCount] = useState(0);
    const [newPoCCustomersCount, setNewPoCCustomersCount] = useState(0);
    const [churnedCustomersCount, setChurnedCustomersCount] = useState(0);
    const [mrr, setMrr] = useState(0);
    const [lastMonthMrr, setLastMonthMrr] = useState(0);
    const [thisMonthMrr, setThisMonthMrr] = useState(0);
    const [pocValue, setPocValue] = useState(0);
    const [monthlyChurnRate, setMonthlyChurnRate] = useState(0);
    const [mapData, setMapData] = useState([]);
    const [projectedMonthText, setProjectedMonthText] = useState("End of Month Year");
    const activeCustomersChartRef = useRef()
    const mrrChartRef = useRef()
    const churnChartRef = useRef()
    const activeCustomersByPlatformChartRef = useRef()
    const activeCustomersBySourceChartRef = useRef()
    const adSpentByPlatformChartRef = useRef()
    const totalRevenueChartRef = useRef()
    const totalRevenueYearlyChartRef = useRef()

    const chartPointRadius = parseInt(window.innerWidth / 500) > 4 ? 4 : parseInt(window.innerWidth / 500)

    const [activeCustomersPlatformTableData, setActiveCustomersPlatformTableData] = useState({
        head: ["Platform", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeCustomersSourceTableData, setActiveCustomersSourceTableData] = useState({
        head: ["Source", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeCustomersSourceandPlatformTableData, setActiveCustomersSourceandPlatformTableData] = useState({
        head: ["Platform", "Source", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });


    const [activeCustomersCountryTableData, setActiveCustomersCountryTableData] = useState({
        head: ["Country", "Active Customers", "% Share", "Total MRR", "% MRR"],
        rows: []
    });


    const allCustomersSourceTableData = {
        head: ["Source", "Lifetime Value", "CAC", "ROI"], // average mrr was in the second column
        rows: [
            ["Outbound", "$5,707", "$562", "10.2"],
            ["Partner", "$1,129", "$120", "9.4"],
            ["Meta Demo", "$1,387", "$689", "2.0"],
            ["Shopify Signup Demo", "$1,800", "$640", "2.8"],
            ["Self Subscribed", "$1,773", "$640", "2.8"],
            [<b>Grand Total</b>, <b>$4,050</b>, <b>$655</b>, <b>6.2</b>],
        ]
    };
    const labels = ['Jan 21', 'Feb 21', 'Mar 21', 'Apr 21', 'May 21', 'Jun 21', 'Jul 21', 'Aug 21', 'Sep 21', 'Oct 21', 'Nov 21', 'Dec 21',
        'Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22'];

    const labels2 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
    const labels3 = ['Jul-23', 'Aug-23', 'Sep-23', 'Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jul-24', 'Aug-24', 'Sep-24', 'Oct-24', 'Nov-24', 'Dec-24', 'Jan-25', 'Feb-25'];
    const [activeCustomersChartData, setActiveCustomersChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                },
            },
        },
        labels,
        datasets: [
            {
                label: ' Total retained customers',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
        ],

    })
    const [mrrChartData, setMrrChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                    // text: 'Monthly MRR',
                },
            },
        },
        labels,
        datasets: [
            {
                label: ' MRR',
                data: [990, 990, 990, 1190, 1190, 2740, 2959, 4346, 6467, 9806, 10488, 11275, 11962, 13658, 16810, 19897, 23902, 25302, 25842, 26248, 31028, 36960, 42257,
                ],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],
    })

    const [churnChartData, setChurnChartData] = useState({

        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
            },
        },
        labels: labels,
        datasets: [
            {
                label: ' Churn Rate',
                data: [0, 6, 3, 2, 4, 4, 5, 6, 3, 5, 3, 3, 1],
                lineWidth: 0,
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#c15f10',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#c15f10',
                pointHoverBackgroundColor: '#c15f10'
            },
        ],

    })

    const [activeCustomersByPlatformChartData, setActiveCustomersByPlatformChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels,
        datasets: [
            {
                label: ' Other',
                data: [10, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: ' Shopify',
                data: [10, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],

    })

    const [activeCustomersBySourceChartData, setActiveCustomersBySourceChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels,
        datasets: [
            {
                label: ' Inbound',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: ' Outbound',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],

    })

    const [adSpentByPlatformChartData, setAdSpentByPlatformChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels: labels3,
        datasets: [
            {
                label: ' Facebook',
                data: [2791, 1035, 4186, 1590, 1962, 1266, 496, 1108, 4306, 6222, 14769, 12368, 12979, 12796, 8439, 13078, 12461, 16654, 10722, 11028],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: ' Shopify',
                data: [1401, 2030, 2366, 197, 991, 3971, 2120, 3180, 2409, 7121, 15366, 15514, 11067, 11893, 12390, 10438, 11133, 6261, 6524, 2976],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
            {
                label: ' Google',
                data: [1795, 2014, 5435, 2893, 698, 1869, 1776, 2597, 1492, 5075, 10608, 5546, 5177, 5265, 6768, 35, 34, 43, 68, 83],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#f27805',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#f27805',
                pointHoverBackgroundColor: '#f27805'
            },
            {
                label: ' Linkedin',
                data: [0, 824, 1082, 0, 0, 0, 0, 0, 0, 1485, 0, 0, 0, 0, 0, 0, 0, 890, 537, 0],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#1fa323',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#1fa323',
                pointHoverBackgroundColor: '#1fa323'
            },
            {
                label: ' Total Spend',
                data: [11974, 5903, 13069, 4680, 3651, 7106, 4392, 6885, 8207, 19903, 40743, 33428, 29223, 29954, 27597, 23551, 23628, 23848, 17581, 14087],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#a15ea9',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#a15ea9',
                pointHoverBackgroundColor: '#a15ea9'
            }

        ],

    })

    const adSpendByPlatform = {
        head: ["Period", "Google", "Facebook", "Shopify", "Linkedin", "Total Spend"],
        rows: [
            ["July 2023", "$1,795", "$2,791", "$1,401", "0", "$11,974"],
            ["August 2023", "$2,014", "$1,035", "$2,030", "$824", "$5,903"],
            ["September 2023", "$5,435", "$4,186", "$2,366", "$1,082", "$13,069"],
            ["October 2023", "$2,893", "$1,590", "$197", "0", "$4,680"],
            ["November 2023", "$698", "$1,962", "$991", "0", "$3,651"],
            ["December 2023", "$1,869", "$1,266", "$3,971", "0", "$7,106"],
            ["January 2024", "$1,776", "$496", "$2,120", "0", "$4,392"],
            ["February 2024", "$2,597", "$1,108", "$3,180", "0", "$6,885"],
            ["March 2024", "$1,492", "$4,306", "$2,409", "0", "$8,207"],
            ["April 2024", "$5,075", "$6,222", "$7,121", "$1,485", "$19,903"],
            ["May 2024", "$10,608", "$14,769", "$15,366", "0", "$40,743"],
            ["June 2024", "$5,546", "$12,368", "$15,514", "0", "$33,428"],
            ["July 2024", "$5,177", "$12,979", "$11,067", "0", "$29,223"],
            ["August 2024", "$5,265", "$12,796", "$11,893", "0", "$29,954"],
            ["September 2024", "$6,768", "$8,439", "$12,390", "0", "$27,597"],
            ["October 2024", "$35", "$13,078", "$10,438", "0", "$23,551"],
            ["November 2024", "$34", "$12,461", "$11,133", "0", "$23,628"],
            ["December 2024", "$43", "$16,654", "$6,261", "$890", "$23,848"],
            ["January 2025", "$68", "$10,722", "$6,524", "$537", "$17,581"],
            ["February 2025", "$83", "$11,028", "$2,976", "0", "$14,087"],


        ]
    }

    const [totalRevenueChartData, setTotalRevenueChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },

            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                },
            },
        },
        labels,
        datasets: [
            {
                label: '💰 Total Revenue',
                data: [],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#85ca6e',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#85ca6e',
                pointHoverBackgroundColor: '#85ca6e'
            },
        ],
    })

    const [totalRevenueYearlyChartData, setTotalRevenueYearlyChartData] = useState({
        options: {
            interaction: {
                mode: 'index',
                intersect: false,
            },
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                    text: (ctx) => '💰 Total Revenue',
                },
            },
        },
        labels: ['2021', '2022', '2023', '2024', '2025'],
        datasets: [
            {
                label: '💰 Total Revenue',
                data: [],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#4c9035',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseFloat(chartPointRadius * 4),
                pointBorderWidth: 5,
                pointBackgroundColor: '#4c9035',
                pointHoverBackgroundColor: '#4c9035'
            },
        ],
    })

    const pageTitle = "Enhencer Financials"

    useEffect(() => {
        document.title = pageTitle
        document.querySelector(".App").classList.add("full-screen")

        const fetchUsers = async () => {
            if (auth.token) {
                try {
                    let url = `${process.env.REACT_APP_BACKEND_URL}/dev/getReportData`

                    const responseData = await sendRequest(url, 'POST', null, {
                        Authorization: 'Bearer ' + auth.token,
                    })
                    const activeCustomersData = responseData.activeCustomersChartData;
                    setTotalActiveCustomers(activeCustomersData[activeCustomersData.length - 1] + responseData.monthlyNewWelcomeCountData[responseData.monthlyNewWelcomeCountData.length - 1]);
                    const lastMonthCustomerCount = activeCustomersData[activeCustomersData.length - 2] || 0;
                    setRetainedCustomersCount(lastMonthCustomerCount);
                    const newCustomerCount = responseData.monthlyNewCustomerCountData[responseData.monthlyNewCustomerCountData.length - 1] || 0;
                    setNewPoCCustomersCount(newCustomerCount + responseData.monthlyNewWelcomeCountData[responseData.monthlyNewWelcomeCountData.length - 1]);
                    const churnedCustomerCount = responseData.monthlyChurnCountData[responseData.monthlyChurnCountData.length - 1] || 0;
                    setChurnedCustomersCount(churnedCustomerCount);
                    const newLabels = responseData.chartLabels;
                    const mrrData = responseData.mrrChartData;
                    const activeOtherCustomersChartData = responseData.activeOtherCustomersChartData;
                    const activeShopifyCustomersChartData = responseData.activeShopifyCustomersChartData;
                    const activeInboundCustomersChartData = responseData.activeInboundCustomersChartData;
                    const activeOutboundCustomersChartData = responseData.activeOutboundCustomersChartData;

                    // Set projected month text
                    const lastLabel = newLabels[newLabels.length - 1];
                    // Assuming labels are in format like "May 25" or "May-25"
                    const parts = lastLabel.includes('-') ? lastLabel.split('-') : lastLabel.split(' ');
                    let month = parts[0];
                    let year = parts[1];
                    if (year && year.length === 2) {
                        year = "20" + year;
                    }
                    setProjectedMonthText(`End of ${month} ${year}`);

                    // Set last month MRR
                    const lastMonthMrrValue = mrrData[mrrData.length - 2] || 0;
                    setLastMonthMrr(lastMonthMrrValue || 0);
                    const thisMonthMrr = responseData.monthlyChurnedMRRCountData[responseData.monthlyChurnedMRRCountData.length - 1] || 0;
                    setThisMonthMrr(thisMonthMrr);

                    // Calculate PoC (New MRR + One-Time PoC Revenue)
                    const lastNewMrr = responseData.monthlyNewMRRCountData[responseData.monthlyNewMRRCountData.length - 1] || 0;
                    const lastWelcomeMrr = responseData.monthlyNewWelcomeMRRCountData[responseData.monthlyNewWelcomeMRRCountData.length - 1] || 0;
                    const pocTotalValue = lastNewMrr + lastWelcomeMrr;
                    setPocValue(pocTotalValue);

                    // Set current MRR (which is now projected MRR)
                    setMrr("$" + numberFormatter.formatNumber(parseInt(mrrData[mrrData.length - 1])));

                    const discardingMonthCountInChurn = 11
                    const churnData = responseData.churnChartData.slice(discardingMonthCountInChurn);
                    const churnRate = churnData.slice(-6).reduce((a, b) => a + b, 0) / 6;
                    setMonthlyChurnRate(churnRate.toFixed(2) + "%");

                    if (activeCustomersChartRef.current) {
                        const datasetsCopy = activeCustomersChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeCustomersData;

                        setActiveCustomersChartData(Object.assign({}, activeCustomersChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy,
                            options: {
                                ...activeCustomersChartData.options,
                                plugins: {
                                    ...activeCustomersChartData.options.plugins,
                                    tooltip: {
                                        enabled: false,
                                        external: (context) => externalActiveCustomersTooltipHandler(context, responseData)
                                    }
                                }
                            }
                        }))
                    }
                    if (mrrChartRef.current) {
                        const datasetsCopy = mrrChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(mrrChartRef.current.ctx, "#eec52c");
                        datasetsCopy[0].data = mrrData;

                        setMrrChartData(Object.assign({}, mrrChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy,
                            options: {
                                ...mrrChartData.options,
                                plugins: {
                                    ...mrrChartData.options.plugins,
                                    tooltip: {
                                        enabled: false,
                                        external: (context) => externalMrrTooltipHandler(context, responseData)
                                    }
                                }
                            }
                        }))
                    }
                    if (activeCustomersByPlatformChartRef.current) {
                        const datasetsCopy = activeCustomersByPlatformChartData.datasets.slice(0);
                        // datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersByPlatformChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeOtherCustomersChartData;
                        datasetsCopy[1].data = activeShopifyCustomersChartData;
                        setActiveCustomersByPlatformChartData(Object.assign({}, activeCustomersByPlatformChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (activeCustomersBySourceChartRef.current) {
                        const datasetsCopy = activeCustomersBySourceChartData.datasets.slice(0);
                        // datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersBySourceChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeInboundCustomersChartData;
                        datasetsCopy[1].data = activeOutboundCustomersChartData;
                        setActiveCustomersBySourceChartData(Object.assign({}, activeCustomersBySourceChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (churnChartRef.current) {
                        const datasetsCopy = churnChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(churnChartRef.current.ctx, "#c15f10");
                        datasetsCopy[0].data = churnData;

                        const footer = (tooltipItems) => {
                            let churnCount = responseData.monthlyChurnCountData.slice(discardingMonthCountInChurn)[tooltipItems[0].dataIndex] ?? 0
                            return "Churn Count: " + churnCount;
                        };

                        setChurnChartData(Object.assign({}, churnChartData, {
                            labels: newLabels.slice(discardingMonthCountInChurn),
                            datasets: datasetsCopy,
                            options: {
                                ...churnChartData.options,
                                plugins: {
                                    ...churnChartData.options.plugins,
                                    tooltip: {
                                        callbacks: {
                                            footer: footer,
                                        }
                                    }
                                }
                            }
                        }))
                    }

                    const activeCustomersPlatformTableRows = [];
                    const activeCustomersTableDataByPlatform = responseData.activeCustomersTableDataByPlatform;
                    activeCustomersTableDataByPlatform.forEach((result, index) => {
                        if (index !== activeCustomersTableDataByPlatform.length - 1) {
                            activeCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.averageMrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.totalMrr)),
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersPlatformTableRows.push([
                                <b>{result._id.platform}</b>,
                                <b>{result.count}</b>,
                                <b>{"$" + numberFormatter.formatNumber(parseInt(result.averageMrr))}</b>,
                                <b>{"$" + numberFormatter.formatNumber(parseInt(result.totalMrr))}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveCustomersPlatformTableData(Object.assign({}, activeCustomersPlatformTableData, {
                        rows: activeCustomersPlatformTableRows
                    }))

                    const activeCustomersSourceTableRows = [];
                    const activeCustomersTableDataBySource = responseData.activeCustomersTableDataBySource;
                    activeCustomersTableDataBySource.forEach((result, index) => {
                        if (index !== activeCustomersTableDataBySource.length - 1) {
                            activeCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.averageMrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.totalMrr)),
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersSourceTableRows.push([
                                <b>{result._id.source}</b>,
                                <b>{result.count}</b>,
                                <b>{"$" + numberFormatter.formatNumber(parseInt(result.averageMrr))}</b>,
                                <b>{"$" + numberFormatter.formatNumber(parseInt(result.totalMrr))}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveCustomersSourceTableData(Object.assign({}, activeCustomersSourceTableData, {
                        rows: activeCustomersSourceTableRows
                    }))

                    const activeCustomersSourceandPlatformTableRows = [];
                    const activeCustomersTableData = responseData.activeCustomersTableData;
                    activeCustomersTableData.forEach((result, index) => {
                        if (index % 2 === 0) {
                            activeCustomersSourceandPlatformTableRows.push([
                                <td rowSpan={2} key={result._id}>{result._id.platform}</td>,
                                result._id.source,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.averageMrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.totalMrr)),
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersSourceandPlatformTableRows.push([
                                result._id.source,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.averageMrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.totalMrr)),
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        }
                    });

                    setActiveCustomersSourceandPlatformTableData(Object.assign({}, activeCustomersSourceandPlatformTableData, {
                        rows: activeCustomersSourceandPlatformTableRows
                    }))

                    const activeCustomersCountryTableRows = [];
                    const activeCustomersMapData = [["Country", "Active Customers", "Total MRR"]];
                    const activeCustomersMapTableData = responseData.mapTableData;
                    activeCustomersMapTableData.forEach((result, index) => {
                        activeCustomersCountryTableRows.push([
                            result._id.country,
                            result.count,
                            result.percentageShare.toFixed(2) + "%",
                            "$" + numberFormatter.formatNumber(parseInt(result.totalMrr)),
                            result.percentageMrr.toFixed(2) + "%",
                        ]);
                        activeCustomersMapData.push([
                            result._id.country,
                            result.count,
                            result.totalMrr
                        ]);
                        setMapData(activeCustomersMapData);
                    });

                    setActiveCustomersCountryTableData(Object.assign({}, activeCustomersCountryTableData, {
                        rows: activeCustomersCountryTableRows
                    }))

                    const allCustomersPlatformTableRows = [];
                    const lifetimeValueTableDataByPlatform = responseData.lifetimeValueTableDataByPlatform;
                    lifetimeValueTableDataByPlatform.forEach((result, index) => {
                        if (result._id.platform === "Other") {
                            allCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                "$" + "335",
                                "5.6"
                            ]);
                        } else {
                            allCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                "$" + "473",
                                "1.2"
                            ]);
                        }
                    });

                    const allCustomersSourceTableRows = [];
                    const lifetimeValueTableDataBySource = responseData.lifetimeValueTableDataBySource;
                    lifetimeValueTableDataBySource.forEach((result, index) => {
                        if (result._id.source === "Outbound") {
                            allCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                "$" + "317",
                                "5.7"
                            ]);
                        } else {
                            allCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                "$" + "492",
                                "1.1"
                            ]);
                        }
                    });
                    const allCustomersLTVTableRows = [];
                    const lifetimeValueTableData = responseData.lifetimeValueTableData;
                    lifetimeValueTableData.forEach((result, index) => {
                        if (index % 2 === 0) {
                            if (result._id.platform === "Other") {
                                allCustomersLTVTableRows.push([
                                    <td rowSpan={2}>{result._id.platform}</td>,
                                    result._id.source,
                                    result.count,
                                    "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                    "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                    "$" + "220",
                                    "9.4"
                                ]);
                            } else {
                                allCustomersLTVTableRows.push([
                                    <td rowSpan={2}>{result._id.platform}</td>,
                                    result._id.source,
                                    result.count,
                                    "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                    "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                    "$" + "413",
                                    "1.7"
                                ]);
                            }
                        } else {
                            if (result._id.platform === "Other") {
                                allCustomersLTVTableRows.push([
                                    result._id.source,
                                    result.count,
                                    "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                    "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                    "$" + "451",
                                    "1.4"
                                ]);
                            } else {
                                allCustomersLTVTableRows.push([
                                    result._id.source,
                                    result.count,
                                    "$" + numberFormatter.formatNumber(parseInt(result.mrr)),
                                    "$" + numberFormatter.formatNumber(parseInt(result.ltv)),
                                    "$" + "534",
                                    "0.9"
                                ]);
                            }
                        }
                    });

                    if (totalRevenueChartRef.current) {
                        const datasetsCopy = totalRevenueChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(totalRevenueChartRef.current.ctx, "#85ca6e");

                        // Calculate total revenue (current MRR + new MRR + PoC revenue) for each month
                        const totalRevenueData = [];
                        for (let i = 0; i < responseData.chartLabels.length; i++) {
                            const currentMRR = responseData.mrrChartData[i] || 0;
                            const welcomeRevenue = responseData.monthlyNewWelcomeMRRCountData[i] || 0;
                            totalRevenueData.push(currentMRR + welcomeRevenue);
                        }

                        datasetsCopy[0].data = totalRevenueData;

                        const footer = (tooltipItems) => {
                            let lastPoint = tooltipItems[0].dataIndex === responseData.chartLabels.length - 1;
                            let currentMRR = responseData.mrrChartData[tooltipItems[0].dataIndex] ?? 0;
                            let welcomeRevenue = responseData.monthlyNewWelcomeMRRCountData[tooltipItems[0].dataIndex] ?? 0;
                            let additionalFee = additionalFeeMap[responseData.chartLabels[tooltipItems[0].dataIndex]] ?? 0;
                            if (lastPoint) {
                                return '💰 Projected Total Revenue: $' + numberFormatter.formatNumber(currentMRR + welcomeRevenue + additionalFee) +
                                    '\n--------------------------------' +
                                    '\n🚀 Monthly Recurring Revenue: $' + numberFormatter.formatNumber(currentMRR) +
                                    '\n🧿 One-Time PoC Revenue: $' + numberFormatter.formatNumber(welcomeRevenue)
                            } else {
                                return '💰 Total Revenue: $' + numberFormatter.formatNumber(currentMRR + welcomeRevenue + additionalFee) +
                                    '\n--------------------------------' +
                                    '\n🚀 Monthly Recurring Revenue: $' + numberFormatter.formatNumber(currentMRR) +
                                    '\n🧿 One-Time PoC Revenue: $' + numberFormatter.formatNumber(welcomeRevenue) +
                                    '\n💰 Additional Fee: $' + numberFormatter.formatNumber(additionalFee)
                            }
                        };

                        setTotalRevenueChartData(Object.assign({}, totalRevenueChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy,
                            options: {
                                ...totalRevenueChartData.options,
                                plugins: {
                                    ...totalRevenueChartData.options.plugins,
                                    tooltip: {
                                        callbacks: {
                                            footer: footer,
                                            label: () => ""
                                        }
                                    }
                                }
                            }
                        }))
                    }

                    if (totalRevenueYearlyChartRef.current) {
                        const datasetsCopy = totalRevenueYearlyChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(totalRevenueYearlyChartRef.current.ctx, "#4c9035");

                        // Calculate yearly revenue totals from monthly data
                        const yearlyTotals = { '2021': 0, '2022': 0, '2023': 0, '2024': 0, '2025': 0 };

                        for (let i = 0; i < responseData.chartLabels.length; i++) {
                            const label = responseData.chartLabels[i];
                            const year = label.split(' ')[1] || label.split('-')[1]; // Extract year from label format

                            if (year && yearlyTotals.hasOwnProperty(year)) {
                                const currentMRR = responseData.mrrChartData[i] || 0;
                                const newMRR = responseData.monthlyNewMRRCountData[i] || 0;
                                const welcomeRevenue = responseData.monthlyNewWelcomeMRRCountData[i] || 0;
                                yearlyTotals[year] += (currentMRR + newMRR + welcomeRevenue);
                            }
                        }

                        datasetsCopy[0].data = Object.values(yearlyTotals);

                        const footer = (tooltipItems) => {
                            const year = totalRevenueYearlyChartData.labels[tooltipItems[0].dataIndex];
                            let totalForYear = tooltipItems[0].raw;
                            let additionalFee = additionalFeeYearlyMap[year] ?? 0;
                            return 'Total Revenue for ' + year + ': $' + numberFormatter.formatNumber(totalForYear + additionalFee) +
                                '\n💰 Additional Fee: $' + numberFormatter.formatNumber(additionalFee)
                        };
                        setTotalRevenueYearlyChartData(Object.assign({}, totalRevenueYearlyChartData, {
                            datasets: datasetsCopy,
                            options: {
                                ...totalRevenueYearlyChartData.options,
                                plugins: {
                                    ...totalRevenueYearlyChartData.options.plugins,
                                    tooltip: {
                                        callbacks: {
                                            footer: footer,
                                            label: () => ""
                                        }
                                    }
                                }
                            }
                        }))
                    }

                } catch (err) {
                    errorHandler(err)
                }
            }
        };
        fetchUsers();

        document.querySelector("#app main").addEventListener('scroll', handleScroll)

        return () => {
            document.querySelector("#app main").classList.remove("scrolled")
            document.querySelector("#app main").removeEventListener('scroll', handleScroll)
            document.querySelector(".App").classList.remove("full-screen")
        }


    }, [])

    const handleScroll = (e) => {
        let rect = document.querySelector(".control-row").getBoundingClientRect()
        let controlRowY = document.querySelector(".control-row").getBoundingClientRect().y
        let controlRowHeight = rect.height
        if (controlRowY <= 0 && !e.target.classList.contains("scrolled")) {
            e.target.classList.add("scrolled")
        } else if (e.target.scrollTop < controlRowHeight && e.target.classList.contains("scrolled")) {
            e.target.classList.remove("scrolled")
        }
    }

    // const logOut = () => {
    //     if (auth.user.isSpecialUser) {
    //         document.body.classList.remove("adminsr")
    //     }
    //     dispatch(
    //         authActions.logout({})
    //     );
    //     dispatch(
    //         generalActions.setPageTitle({ pageTitle: null })
    //     );
    // }

    return (
        <div className="crm-report">
            <div className="row control-row">
                <h6>Updated at {dateFormatter(new Date())}</h6>
                {
                    isLoading && <LoadingSpinner overlay withoutTopbar message="Loading Reports..." />
                }
            </div>
            <div className="row info-badges">
                <InfoBadge
                    value={
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid rgba(255,255,255,0.2)', paddingRight: '10px', width: '70%' }}>
                                <div style={{ textAlign: 'center', padding: '0 3px' }}>
                                    <div style={{ marginTop: '1px', fontSize: '0.7em' }}>{retainedCustomersCount}</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.6em' }}>Last Month</div>
                                </div>
                                <div style={{ margin: '0 2px', fontSize: '0.7em' }}>-</div>
                                <div style={{ textAlign: 'center', padding: '0 3px' }}>
                                    <div style={{ marginTop: '1px', fontSize: '0.7em' }}>{churnedCustomersCount}</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.6em' }}>Churned</div>
                                </div>
                                <div style={{ margin: '0 2px', fontSize: '0.7em' }}>+</div>
                                <div style={{ textAlign: 'center', padding: '0 3px' }}>
                                    <div style={{ marginTop: '1px', fontSize: '0.7em' }}>{newPoCCustomersCount}</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.6em' }}>New PoC</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ fontSize: '1.15em', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>
                                    {totalActiveCustomers}
                                </div>
                                <div style={{ width: '100%', textAlign: 'center', marginBottom: '4px', fontSize: '0.7em', justifyContent: 'center', alignContent: 'center' }}>
                                    <span style={{ opacity: 0.7 }}>Active Customers</span>
                                </div>
                            </div>
                        </div>
                    }
                />
                <InfoBadge
                    value={
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid rgba(255,255,255,0.2)', paddingRight: '10px', width: '70%' }}>
                                <div style={{ textAlign: 'center', padding: '0 3px' }}>
                                    <div style={{ marginTop: '1px', fontSize: '0.7em' }}>${numberFormatter.formatNumber(parseFloat(lastMonthMrr).toFixed(2))}</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.6em' }}>Last Month</div>
                                </div>
                                <div style={{ margin: '0 2px', fontSize: '0.7em' }}>-</div>
                                <div style={{ textAlign: 'center', padding: '0 3px' }}>
                                    <div style={{ marginTop: '1px', fontSize: '0.7em' }}>${numberFormatter.formatNumber(parseFloat(thisMonthMrr).toFixed(2))}</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.6em' }}>Churned</div>
                                </div>
                                <div style={{ margin: '0 2px', fontSize: '0.7em' }}>+</div>
                                <div style={{ textAlign: 'center', padding: '0 3px' }}>
                                    <div style={{ marginTop: '1px', fontSize: '0.7em' }}>${numberFormatter.formatNumber(parseFloat(pocValue).toFixed(2))}</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.6em' }}>New PoC</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ fontSize: '1.15em', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>
                                    ${numberFormatter.formatNumber((parseFloat(lastMonthMrr) - parseFloat(thisMonthMrr) + parseFloat(pocValue)).toFixed(2))}
                                </div>
                                <div style={{ width: '100%', textAlign: 'center', marginBottom: '4px', fontSize: '0.7em' }}>
                                    <span style={{ opacity: 0.7 }}>Projected MRR</span>
                                </div>
                                <div style={{ width: '100%', textAlign: 'center', marginBottom: '4px', fontSize: '0.4em' }}>
                                    <span style={{ opacity: 0.7 }}>{projectedMonthText}</span>
                                </div>
                            </div>
                        </div>
                    }
                    background={['#eec52c', '#bd9b1d']}
                />
            </div>
            <div className="row wrappable">
                <Card>
                    <Line
                        ref={activeCustomersChartRef}
                        options={activeCustomersChartData.options}
                        data={activeCustomersChartData}
                    />
                </Card>
                <Card>
                    <Line
                        ref={mrrChartRef}
                        options={mrrChartData.options}
                        data={mrrChartData}
                    />
                </Card>
            </div>

            <div className="row wrappable">
                <Card>
                    <h4>Total Revenue by Month</h4>
                    <Line
                        ref={totalRevenueChartRef}
                        options={totalRevenueChartData.options}
                        data={totalRevenueChartData}
                    />
                </Card>
                <Card>
                    <h4>Total Revenue by Year</h4>
                    <Line
                        ref={totalRevenueYearlyChartRef}
                        options={totalRevenueYearlyChartData.options}
                        data={totalRevenueYearlyChartData}
                    />
                </Card>
            </div>

            <div className="row wrappable" style={{ alignItems: 'center' }}>
                <Card>
                    <Table
                        title="Active Customers by Platform"
                        data={activeCustomersPlatformTableData}
                    />
                </Card>
                <Card>
                    <Table
                        title="Active Customers by Source"
                        data={activeCustomersSourceTableData}
                    />
                </Card>
            </div>

            <div className="row wrappable">
                <Card>
                    <Line
                        ref={activeCustomersByPlatformChartRef}
                        options={activeCustomersByPlatformChartData.options}
                        data={activeCustomersByPlatformChartData}
                    />
                </Card>
                <Card>
                    <Line
                        ref={activeCustomersBySourceChartRef}
                        options={activeCustomersBySourceChartData.options}
                        data={activeCustomersBySourceChartData}
                    />
                </Card>
            </div>

            <div className="row wrappable">
                <Card>
                    <Table
                        title="Active Customers by Country"
                        data={activeCustomersCountryTableData}
                    />
                </Card>
                <Card>
                    <h4>Active Customers Map</h4>
                    <CRMGeoChart mapData={mapData} />
                </Card>
            </div>

            <div className="row">
                <InfoBadge
                    value={
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <div style={{ textAlign: 'center', padding: '0 5px' }}>
                                    <div style={{ marginTop: '2px', fontSize: '1.15em', fontWeight: 'bold' }}>$2,782</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.7em' }}>Historical LTV</div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <div style={{ textAlign: 'center', padding: '0 5px' }}>
                                    <div style={{ marginTop: '2px', fontSize: '1.15em', fontWeight: 'bold' }}>$4,175</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.7em' }}>Projected LTV</div>
                                </div>
                            </div>
                        </div>
                    }
                    background={['#eec52c', '#bd9b1d']}
                />
                <InfoBadge
                    value={
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                <div style={{ textAlign: 'center', padding: '0 5px' }}>
                                    <div style={{ marginTop: '2px', fontSize: '1.15em', fontWeight: 'bold' }}>$698</div>
                                    <div style={{ opacity: 0.7, fontSize: '0.7em' }}>Customer Acquisition Cost</div>
                                </div>
                            </div>
                        </div>
                    }
                    background={['#058694', '#066670']}
                />
            </div>
            <div className="row">
                <Card>
                    <Table title="Ad Spent By Platform" data={adSpendByPlatform} />
                </Card>
                <Card>
                    <Line
                        ref={adSpentByPlatformChartRef}
                        options={adSpentByPlatformChartData.options}
                        data={adSpentByPlatformChartData}
                    />
                </Card>
            </div>
            {/* <div className="row">
            <Card>
                <Table title="Inbound Ad Spend (1 Sept 2022 - 13 Mar 2023)" data={inboundAdSpend} />
            </Card>
        </div> */}


            {/* <div className="row">
          <Card>
            <Table
              title="Inbound Ad Spend (14 Aug 2023 - 23 Oct 2023)"
              data={inboundAdSpend}
            />
          </Card>
        </div> */}


            <div className="row">
                {/* <Card>
                <Table title="Inbound Ad Spend (1 Sep - 30 Nov, 2022)" data={inboundSales} />
            </Card> */}
            </div>

            <div className="row">
                <InfoBadge
                    value={monthlyChurnRate}
                    name="Monthly Churn Rate"
                    background={['#c15f10', '#8e4912']}
                />
            </div>
            <Card>
                <Line
                    ref={churnChartRef}
                    options={churnChartData.options}
                    height={70}
                    data={churnChartData}
                />
            </Card>
        </div>
    )
}

export default CRMReport;
