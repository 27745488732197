import React from 'react'
import CreativeTypes from '../../../shared/enums/CreativeTypes'
import aiCreativeLabIcon from '../../../shared/assets/images/ai-ads/ai-creative-lab-icon.svg'
import existingCreativesIcon from '../../../shared/assets/images/ai-ads/use-existing-creatives-icon.svg'
import launchAIAdsIcon from '../../../shared/assets/images/ai-ads/launch-ai-ads-icon.svg'

import './CreativeModeSelection.css'
import { useSelector } from 'react-redux'

const CreativeModeSelection = ({ handleCreativeModeSelect }) => {
  const isTestingAccount = useSelector(state => state.auth.user?.crmDetails?.isTestingAccount)


  return (
    <div className="creative-mode-selection">
      <h2>Select your creative type</h2>

      <div className="creative-modes">
        <div
          className="mode-card"
          onClick={() => handleCreativeModeSelect(CreativeTypes.AI_CREATIVE)}
        >
          <div className="mode-icon">
            <img src={aiCreativeLabIcon} alt="AI Creative Lab" />
          </div>
          <h3>AI Creative Lab</h3>
          <p>Create new ad visuals with AI to boost campaign performance.</p>
        </div>

        <div
          className="mode-card"
          onClick={() => handleCreativeModeSelect(CreativeTypes.HIGH_PERFORMING)}
        >
          <div className="mode-icon">
            <img src={existingCreativesIcon} alt="Use Existing Creatives" />
          </div>
          <h3>Use Existing Creatives</h3>
          <p>Automatically use your top-performing ads with the most clicks from Facebook.</p>
        </div>

        <div
          className="mode-card"
          onClick={() => handleCreativeModeSelect(CreativeTypes.CATALOG)}
        >
          <div className="mode-icon">
            <img src={launchAIAdsIcon} alt="Launch AI Ads" />
          </div>
          <h3>Launch AI Ads from your Catalog</h3>
          <p>Quickly launch ads using images directly from your product catalog.</p>
        </div>


        {
          isTestingAccount &&
          <div
            className="mode-card"
            onClick={() => handleCreativeModeSelect(CreativeTypes.POST)}
          >
            <div className="mode-icon">
              <img src={launchAIAdsIcon} alt="Launch AI Ads" />
            </div>
            <h3>Use existing post</h3>
            <p>Use your posts from your page or Instagram account to create ads.</p>
          </div>
        }
      </div>
    </div>
  )
}

export default CreativeModeSelection