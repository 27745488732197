import React from 'react'
import SuccessStoriesSlider from './SuccessStoriesSlider'
import RecommendedShopifyAppsSlider from './RecommendedShopifyAppsSlider'
import SubscriptionStatusSlider from './SubscriptionStatusSlider'
import './SidebarContent.css'

const SidebarContent = () => {
  return (
    <div className="sidebar-content-grid">
      <div className="success-section">
        <SuccessStoriesSlider />
      </div>
      <div className="recommended-subscription-section">
      <div className="recommended-section">
        <RecommendedShopifyAppsSlider />
      </div>
      <div className="subscription-section">
        <SubscriptionStatusSlider />
      </div>
      </div>
    </div>
  )
}

export default SidebarContent