import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp8bg from '../../../../shared/assets/images/ai-creative/common_temp8bg.svg'

export const Template8Config = {
  name: 'Furniture #2',
  containerId: 'template8-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 167,
        y: 165
      },
      scale: {
        width: WORKBENCH_SIZE.width * 0.690,
        height: WORKBENCH_SIZE.height * 0.695
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp8bg,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp8bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: 'black',
      draggable: false,
      clickThrough: true
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: 210,
        y: 210
      },
      scale: {
        maxWidth: 140,
        maxHeight: 100
      },
      dynamicSrc: 'companyLogo',
      required: false
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 190,
        y: WORKBENCH_SIZE.height - 200
      },
      width: 500,
      height: 60,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 45,
      fontStyle: 'bold',
      color: 'color3',
      verticalAlign: 'middle',
      text: '{{productName}}'
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 360,
        y: WORKBENCH_SIZE.height - 200
      },
      width: 180,
      height: 60,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 40,
      fontStyle: 'bold',
      color: 'color3',
      verticalAlign: 'middle',
      text: '{{productPrice}}'
    }
  ]
}