import React from 'react'
import { FaPauseCircle, FaSpinner, FaExclamationCircle, FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa'

const getStatusIndicator = (status) => {
    if (!status) return 'inactive'

    const statusLower = status.toLowerCase()
    if (status === 'ACTIVE') return 'active'
    if (statusLower.includes('pause')) return 'paused'
    if (statusLower.includes('pending') || statusLower.includes('process')) return 'pending'
    if (statusLower.includes('issue') || statusLower.includes('disapprov')) return 'issue'
    if (statusLower.includes('info')) return 'info'
    if (statusLower.includes('error') || statusLower.includes('rejected') || statusLower.includes('delete')) return 'error'

    return 'inactive'
}

const StatusIndicator = ({ status }) => {
    const indicatorType = getStatusIndicator(status || 'INACTIVE')

    switch (indicatorType) {
        case 'active':
            return <div className="indicator active" />
        case 'paused':
            return <div className="indicator paused"><FaPauseCircle /></div>
        case 'pending':
            return <div className="indicator pending"><FaSpinner /></div>
        case 'issue':
            return <div className="indicator issue"><FaExclamationCircle /></div>
        case 'info':
            return <div className="indicator info"><FaInfoCircle /></div>
        case 'error':
            return <div className="indicator error"><FaExclamationTriangle /></div>
        default:
            return <div className="indicator inactive" />
    }
}

export default StatusIndicator
