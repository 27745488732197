import React, { useEffect, useRef } from 'react';
import Button from '../FormElements/Button';
import { FaArrowLeft } from 'react-icons/fa';
import './ViewingAsUserBox.css';

const ViewingAsUserBox = ({ onBackClick }) => {
  const viewingAsUserBox = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      viewingAsUserBox?.current?.classList?.add('hide');
    }, 2000);
  }, []);

  return (
    <div className="viewing-as-user-warning-container" ref={viewingAsUserBox}>
      <Button textButton className="back-button" onClick={onBackClick}>
        <FaArrowLeft />
        Back
      </Button>
      <div>
        <span>You are viewing as admin</span>
      </div>
    </div>
  );
};

export default ViewingAsUserBox; 