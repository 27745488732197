import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import { useModal } from './modal-hook'
import { generalActions } from '../../store/general'
import { updateUserSubscription } from '../util/subscription'
import Modals from '../enums/Modals'
import errorHandler from '../util/errorHandler'

export const useSubscriptionStatusHandler = () => {
  const { openModal } = useModal()
  const [accountSuspended, setAccountSuspended] = useState(false)
  const { token: authToken, user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser'))

  useEffect(() => {

    if (!authToken) {
      dispatch(generalActions.setBottomNotification({ display: false }))
      return
    }

    if (window.location.pathname.includes('payment-completed')) {
      return
    }
    handleSubscriptionStatus()
  }, [authToken])

  /**
   * Handles various subscription status scenarios for the user:
   * - If the subscription is suspended, triggers the accountSuspended modal.
   * - If the user is on Shopify, checks for an active payment on the platform.
   * - If the user is on stripe, checks for an active payment on the platform.
   * - If the user's payment for the current period is overdue, updates the subscription status to 'payment-error'.
   */
  async function handleSubscriptionStatus() {
    if (!user) {
      return
    }

    if (user.subscription?.status?.toLowerCase() === 'suspended') {
      handleAccountSuspended()
      return
    }

    if (user.shopify && user.subscription) {
      await handleShopifySubscription()
      return
    }
    //STIPE
    if (user.subscription?.source === 'Stripe' && user.subscription?.refCode) {
      await handleStripeSubscription()
      return
    }



    if (
      user.subscription &&
      user.subscription.nextPaymentDate &&
      user.subscription.nextPaymentDate < Date.now() &&
      user.subscription.status !== 'payment-error' &&
      user.subscription.status !== 'suspended'
    ) {
      await handlePaymentError()
    }
  }

  /**
   * Displays the 'account suspended' modal.
   */
  function handleAccountSuspended() {
    setAccountSuspended(true)
    openModal({
      type: Modals.ACCOUNT_SUSPENDED,
      closeOnClickOutside: viewingAsUser ? true : false,
    })
  }

  /**
   * Synchronizes the user's subscription details with Shopify payments.
   */
  async function handleShopifySubscription() {
    if (user.subscription.chargeID) {
      try {
        const result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/shopify/subscription?chargeID=${user.subscription.chargeID}&accessToken=${user.shopify.accessToken}&shopDomain=${user.shopify.domain}`,
          'GET',
        )

        const charge = result.recurring_application_charge
        const nextPayment = new Date(charge.billing_on).getTime()
        const status = charge.status

        if (nextPayment !== user.subscription.nextPaymentDate || status !== user.subscription.status) {
          const subscriptionDetails = {
            ...user.subscription,
            nextPaymentDate: nextPayment,
            status: charge.status,
          }

          await updateUserSubscription(user, subscriptionDetails, {
            sendRequest,
            dispatch,
            authToken,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  /**
 * Synchronizes the user's subscription details with Stripe payments.
 */
  async function handleStripeSubscription() {

    try {
      //get stripe subscription
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/stripe/getSubscription?subscriptionId=${user.subscription.refCode}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      if (response.ok) {
        const subscriptionData = await response.json();
        const nextPayment = subscriptionData.nextPaymentDate;
        const subscriptionStatus = subscriptionData.status;

        if (nextPayment !== user.subscription.nextPaymentDate || subscriptionStatus !== user.subscription.status) {
          try {
            const subscriptionDetails = {
              ...user.subscription,
              nextPaymentDate: nextPayment,
              status: subscriptionStatus,
            }

            await updateUserSubscription(user, subscriptionDetails, {
              sendRequest,
              dispatch,
              authToken,
            })
          } catch (error) {
            console.log(error)
          }
        }

      } else {
        console.error('subscription info couldnt get:', response.statusText);
        errorHandler({message: 'Subscription status could not be updated. Please contact us.'})
      }
    } catch (error) {
      errorHandler({message: 'Subscription status could not be updated. Please contact us.'})
    }
  }


  /**
   * Updates the user's subscription status as 'payment-error'.
   */
  async function handlePaymentError() {
    try {
      const subscriptionDetails = {
        ...user.subscription,
        status: 'payment-error',
      }

      await updateUserSubscription(user, subscriptionDetails, {
        sendRequest,
        dispatch,
        authToken,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    isAccountSuspended: accountSuspended,
  }
}
