import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { PopupButton } from "react-calendly"

import googleLogo from '../../assets/images/icons/google-logo-sm.png'
import youtubeLogo from '../../assets/images/icons/youtube-logo.png'
import Button from '../FormElements/Button'
import { useSelector } from 'react-redux'

import './GoogleAdsSupport.css'

const GoogleAdsSupport = ({ data }) => {
  const performanceManagerProfile = useSelector(state => state.auth.user?.crmDetails?.performanceManagerProfile)
  const user = useSelector(state => state.auth.user)

  return (
    <div className="google-ads-support">
      <header>
        <h2 className="title">
          Book a Support Meeting to set up your
          <span className="highlight">Google Ads!</span>
        </h2>
      </header>
      <div className="icon-bubble google">
        <img className="icon" src={googleLogo} alt="Google" />
      </div>
      <div className="icon-bubble youtube">
        <img className="icon" src={youtubeLogo} alt="YouTube" />
      </div>
      <div className="buttons">
        <PopupButton
          url={performanceManagerProfile?.meetingUrl || process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK}
          rootElement={document.getElementById('root')}
          prefill={{
            email: user?.email || '',
            name: user?.name || '',
          }}
          text="Book a call with us"
          className="book-a-call-btn"
        />
        {/* <Button
          className="customer-support-btn"
          onClick={handleCustomerSupportButton}
        >
          Customer Support
        </Button> */}
      </div>
      <div className="background">
        <div className="bg-bubble xl"></div>
        <div className="bg-bubble lg"></div>
        <div className="bg-bubble md"></div>
        <div className="bg-bubble sm"></div>
      </div>
    </div>
  )
}

export default GoogleAdsSupport
