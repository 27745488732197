import React, { useState } from 'react'
import Button from '../FormElements/Button'
import Switch from '../UIElements/Switch'
import StrokeInput from '../FormElements/StrokeInput'
import WorldIcon from '../../assets/images/icons/world-icon.svg'
import { IoCheckmarkCircleOutline } from 'react-icons/io5'
import './BoostCampaign.css'

const BoostCampaign = ({ onComplete, onCancel, dailyBudget }) => {
  const [allowBoostCampaigns, setAllowBoostCampaigns] = useState(true)
  const [boostBudget, setBoostBudget] = useState(dailyBudget * 0.25)

  const handleConfirm = () => {
    onComplete({
      allowBoostCampaigns,
      boostBudget: allowBoostCampaigns ? boostBudget : 0,
    })
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const handleSwitchChange = value => {
    setAllowBoostCampaigns(value === 'On')
  }

  return (
    <div className="boost-campaign">
      <div className="content">
        <div className="header">
          <div className="header-left">
            <img src={WorldIcon} alt="World Icon" className="world-icon" />
            <div className="title-container">
              <h2>Boost Campaigns</h2>
              <span className="new-badge">NEW</span>
            </div>
          </div>
          <Switch 
            options={['Off', 'On']}
            value={allowBoostCampaigns ? 'On' : 'Off'}
            onChange={handleSwitchChange}
          />
        </div>

        <p className="description">
          Boost Campaign transforms your ads into follower and website traffic magnets, attracting targeted users to your profile. Set it once, and watch your audience expand.
        </p>

        {allowBoostCampaigns ? (
          <div className="boost-budget-selector">
            <StrokeInput
              id="boostBudget"
              element="input"
              type="number"
              label="Additional Daily Budget"
              value={boostBudget}
              onChange={(val) => setBoostBudget(parseFloat(val))}
              min={1}
              step={0.1}
              placeholder="Enter boost budget"
              validators={[]}
              errorLine={false}
            />
          </div>
        ) : (
          <div className="benefits">
            <div className="benefit-item">
              <IoCheckmarkCircleOutline className="check-icon" />
              <span>Increase engagement (followers, likes), across meta platforms.</span>
            </div>
            <div className="benefit-item">
              <IoCheckmarkCircleOutline className="check-icon" />
              <span>Increase website traffic with engaged visitors.</span>
            </div>
          </div>
        )}
      </div>

      <div className="footer">
      
        <Button
          primary
          className="confirm-button"
          onClick={handleConfirm}
        >
          {allowBoostCampaigns ? 'Launch with Boost' : 'Launch Campaign without Boost'}
        </Button>
        <Button className="cancel-button" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default BoostCampaign
