import { WORKBENCH_SIZE } from '../../../support/constants'
import temp17bg from '../../../../shared/assets/images/ai-creative/temp17bg.svg'

export const Template17Config = {
  name: 'Luminous Aura',
  containerId: 'template17-carousel-single',
  backgroundColor: 'color1',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp17bg,
      isSvg: true,
      svgColor: 'color3',
      svgCustomReplace: '#151515',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2 + 30,
      },
      scale: {
        maxWidth: 700,
        maxHeight: 700,
      },
      dynamicSrc: 'productImage',
      center: true,
      moveToTop: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 20,
        y: 110,
      },
      width: WORKBENCH_SIZE.width - 40,
      height: 200,
      text: '{{productName}}', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 72,
      fontStyle: 'bold',
      color: 'color2',
      align: 'center',
      toUpperCase: true,
      fontIndex: 1,
      maxLines: 1,
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: 20,
        y: WORKBENCH_SIZE.height - 100,
      },
      width: WORKBENCH_SIZE.width - 40,
      text: 'Starting from {{productPrice}}', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 60,
      fontStyle: '500',
      color: 'color2',
      align: 'center',
      fontIndex: 2,
    },
    // Primary Text
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 400,
        y: 50,
      },
      width: 800,
      text: 'DESIGN. CREATE. INSPIRE.',
      fontFamily: 'Readex Pro',
      fontSize: 48,
      fontStyle: '400',
      color: 'color2',
      letterSpacing: 0.8,
      align: 'center',
      fontIndex: 1,
    },
  ],
}
