import React, { useState } from 'react'

import CollapseIcon from '../../../shared/assets/images/icons/collapse.svg'
import ExpandIcon from '../../../shared/assets/images/icons/expand.svg'
import Tooltip from '../../../shared/components/UIElements/Tooltip'

// New Templates
import CrystalRadiance from '../../../shared/assets/images/ai-creative/suggested-templates/crystal-radiance.jpg'
import ModernGradient from '../../../shared/assets/images/ai-creative/suggested-templates/modern-gradient.jpg'
import GradientBubbles from '../../../shared/assets/images/ai-creative/suggested-templates/gradient-bubbles.jpg'

// Popular Templates
import FrostedFocus from '../../../shared/assets/images/ai-creative/suggested-templates/frosted-focus.jpg'
import FestiveDelight from '../../../shared/assets/images/ai-creative/suggested-templates/festive-delight.jpg'
import RippleWave from '../../../shared/assets/images/ai-creative/suggested-templates/ripple-wave.jpg'
import CoralCurve from '../../../shared/assets/images/ai-creative/suggested-templates/coral-curve.jpg'
import MysticSpotlight from '../../../shared/assets/images/ai-creative/suggested-templates/mystic-spotlight.jpg'

import styles from './CollapsibleTemplateSidebar.module.css'

const CollapsibleTemplateSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [activeTab, setActiveTab] = useState('new')

  const newTemplates = [
    {
      id: 'new_1',
      img: CrystalRadiance,
      name: 'Crystal Radiance',
    },
    {
      id: 'new_2',
      img: ModernGradient,
      name: 'Modern Gradient',
    },
    {
      id: 'new_3',
      img: GradientBubbles,
      name: 'Gradient Bubbles',
    },
  ]

  const popularTemplates = [
    {
      id: 'popular_1',
      img: FrostedFocus,
      name: 'Frosted Focus',
    },
    {
      id: 'popular_2',
      img: FestiveDelight,
      name: 'Festive Delight',
    },
    {
      id: 'popular_3',
      img: RippleWave,
      name: 'Ripple Wave',
    },
    {
      id: 'popular_4',
      img: CoralCurve,
      name: 'Coral Curve',
    },
    {
      id: 'popular_5',
      img: MysticSpotlight,
      name: 'Mystic Spotlight',
    },
  ]

  return (
    <div
      className={`${styles.collapsibleTemplateSidebar} ${
        isCollapsed ? styles.collapsed : styles.open
      }`}
    >
      <div className={styles.collapser}>
        <button
          className={styles.collapserButton}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? (
            <img src={ExpandIcon} alt="expand" />
          ) : (
            <img src={CollapseIcon} alt="collapse" />
          )}
        </button>
      </div>
      <div className={isCollapsed ? styles.collapsedContent : styles.content}>
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <ul>
              <li
                className={activeTab === 'new' ? styles.active : ''}
                onClick={() => setActiveTab('new')}
              >
                New
              </li>
              <li
                className={activeTab === 'popular' ? styles.active : ''}
                onClick={() => setActiveTab('popular')}
              >
                Popular
              </li>
            </ul>
          </div>
          <div className={styles.body}>
            <div className={styles.templateCards}>
              {activeTab === 'new'
                ? newTemplates.map(t => (
                    <Tooltip
                      content={t.name}
                      position="bottom"
                      size="sm"
                      offset={5}
                      key={t.id}
                    >
                      <div className={styles.templateCard}>
                        <img src={t.img} alt={t.name} />
                      </div>
                    </Tooltip>
                  ))
                : popularTemplates.map(t => (
                    <Tooltip
                      content={t.name}
                      position="bottom"
                      size="sm"
                      offset={5}
                      key={t.id}
                    >
                      <div className={styles.templateCard}>
                        <img src={t.img} alt={t.name} />
                      </div>
                    </Tooltip>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollapsibleTemplateSidebar
