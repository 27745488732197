import React, { useEffect, useRef, useState } from 'react'
import { FaPhone, FaChevronUp, FaChevronDown } from 'react-icons/fa'
import { PopupButton } from "react-calendly"

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import { useSelector } from 'react-redux'
import BarButton from '../UIElements/BarButton'
import Modals from '../../enums/Modals'

import './AccountManagerCard.css'

const AccountManagerCard = props => {
  const performanceManagerProfile = useSelector(
    state => state.auth.user?.crmDetails?.performanceManagerProfile,
  )
  const name = useSelector(state => state.auth.user?.name)
  const email = useSelector(state => state.auth.user?.email)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { openModal } = useModal()

  const boxRef = useRef()

  const openPopover = e => {
    if (!isPopoverOpen) {
      setIsPopoverOpen(true)
      boxRef.current?.classList?.add('open')
      document.addEventListener('mousedown', handleClickOutside, true)
    }
  }

  const closePopover = e => {
    setIsPopoverOpen(false)
    boxRef.current?.classList?.remove('open')
    document.removeEventListener('mousedown', handleClickOutside, true)
  }

  const togglePopover = () => {
    if (!isPopoverOpen) openPopover()
    else closePopover()
  }

  const createASupportTicket = () => {
    openModal({
      type: Modals.CREATE_A_SUPPORT_TICKET,
      closeOnClickOutside: true,
      performanceManager: performanceManagerProfile?._id,
    })
  }

  const handleClickOutside = event => {
    if (
      !event.target.closest('.account-manager-box') &&
      event.target.title !== 'Get Support'
    ) {
      closePopover()
    }
  }

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true)
    }
  }, [])

  return performanceManagerProfile ? (
    <div className="account-manager-box">
      <BarButton
        type="support"
        title="Get Support"
        onClick={togglePopover}
        chevron={
          isPopoverOpen ? (
            <FaChevronUp className="get-support-icon" />
          ) : (
            <FaChevronDown className="get-support-icon" />
          )
        }
        className="bar-button"
      />
      <div
        className={`accordion-part ${isPopoverOpen ? 'open' : ''}`}
        ref={boxRef}
      >
        <div className="sized-box"></div>
        <div className="content">
          <h3>Your Ad Expert</h3>
          <div className="row main-row">
            <img
              src={performanceManagerProfile.image}
              alt="Marketing Expert"
            />
            <div className="">
              <div className="name">{performanceManagerProfile.name}</div>
              <div className="email">
                <a
                  href={`mailto:${performanceManagerProfile.email}`}
                  title={`Mail ${performanceManagerProfile.name}`}
                >
                  {performanceManagerProfile.email}
                </a>
              </div>
            </div>
          </div>
          <div className="buttons">
            <div className="row actions-row">
              <PopupButton
                url={performanceManagerProfile?.meetingUrl || process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK}
                rootElement={document.getElementById('root')}
                prefill={{
                  email: email || '',
                  name: name || '',
                }}
                text="Book a meeting"
              />
            </div>
            <div className="row actions-row">
              <Button onClick={createASupportTicket}>Create a ticket</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="account-manager-box no-manager">
      <BarButton
        type="support"
        title="Get Support"
        onClick={togglePopover}
        chevron={
          isPopoverOpen ? (
            <FaChevronUp className="get-support-icon" />
          ) : (
            <FaChevronDown className="get-support-icon" />
          )
        }
        className="bar-button"
      />
      <div
        className={`accordion-part ${isPopoverOpen ? 'open' : ''}`}
        ref={boxRef}
      >
        <div className="sized-box"></div>
        <div className="content">
          <h3>Get Support</h3>
          <div className="buttons">
            <div className="row actions-row">
              <PopupButton
                url={performanceManagerProfile?.meetingUrl || process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK}
                rootElement={document.getElementById('root')}
                prefill={{
                  email: email || '',
                  name: name || '',
                }}
                text="Book a meeting"
              />
            </div>
            <div className="row actions-row">
              <Button onClick={createASupportTicket}>Create a ticket</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountManagerCard
