import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useModal } from '../../hooks/modal-hook'
import BoostCampaign from './BoostCampaign'
import CampaignCreationProgress from './CampaignCreationProgress'
import { CSSTransition } from 'react-transition-group'
import './CampaignSetupModal.css'
import { facebookActions } from '../../../store/facebook'
import CreativeTypes from '../../enums/CreativeTypes'

const CampaignSetupModal = () => {
    const dispatch = useDispatch()
    const { closeModal, modalData } = useModal()
    
    // modalData ve campaignData kontrolü
    const campaignData = modalData?.campaignData || {}
    const resolve = modalData?.resolve

    const hasOnlyCatalogCreatives = campaignData?.adCreatives ? 
        !Object.values(campaignData.adCreatives).some(creative => creative.type !== CreativeTypes.CATALOG)
        : false

    const [showProgress, setShowProgress] = useState(hasOnlyCatalogCreatives)
    const [boostCampaignData, setBoostCampaignData] = useState(hasOnlyCatalogCreatives ? {
        allowBoostCampaigns: false,
        boostBudget: 0
    } : null)

    const handleBoostCampaignComplete = (data) => {
        setBoostCampaignData(data)
        setShowProgress(true)
    }

    const handleClose = () => {
        if (showProgress) {
            const shouldClose = window.confirm('Campaign creation is in progress. Are you sure you want to cancel?')
            if (!shouldClose) return
        }

        // Clear modal state
        setShowProgress(false)
        setBoostCampaignData(null)

        // Reset campaign creation state in Redux
        dispatch(facebookActions.resetCampaignCreation())

        // Close modal and resolve with null
        closeModal()

        if (resolve) {
            resolve({
                cancelled: true
            })
        }
    }

    const onComplete = ({
        fullFunnel
    }) => {
        dispatch(facebookActions.resetCampaignCreation())
        if (resolve) {
            resolve({
                success: true,
                fullFunnel
            })
        }
        closeModal()
    }

    return (
        <div className="campaign-setup-modal">


            <div className="modal-content">
                <CSSTransition
                    in={!showProgress}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="boost-campaign-wrapper">
                        <BoostCampaign
                            dailyBudget={campaignData.dailyBudget || 0}
                            onCancel={handleClose}
                            onComplete={handleBoostCampaignComplete}
                        />
                    </div>
                </CSSTransition>

                <CSSTransition
                    in={showProgress}
                    timeout={300}
                    classNames="fade"
                    unmountOnExit
                >
                    <div className="progress-wrapper">
                        <CampaignCreationProgress
                            campaignData={{
                                ...campaignData,
                                ...(boostCampaignData?.allowBoostCampaigns ?
                                    { boostBudget: boostCampaignData?.boostBudget }
                                    :
                                    { boostBudget: 0 })
                            }}
                            onComplete={onComplete}
                        />
                    </div>
                </CSSTransition>
            </div>
        </div>
    )
}

export default CampaignSetupModal 