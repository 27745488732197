import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import helper from '../../../shared/util/helper'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { adminActions } from '../../../store/admin'
import CollapsibleCard from '../../../shared/components/UIElements/CollapsibleCard'
import { useConstants } from '../../../shared/hooks/constants-hook'

import './CardAdminAudienceNetwork.css'
import { useModal } from '../../../shared/hooks/modal-hook'
import Button from '../../../shared/components/FormElements/Button'
import Modals from '../../../shared/enums/Modals'
import errorHandler from '../../../shared/util/errorHandler'
import Switch from '../../../shared/components/UIElements/Switch'

function CardAdminAudienceNetwork() {
  const { privateConstants: constants } = useConstants()
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [loading, setLoading] = useState(false)

  const [fetchedTopEnhencerCategories, setFetchedTopEnhencerCategories] =
    useState([])
  const [updatedTopEnhencerCategories, setUpdatedTopEnhencerCategories] =
    useState([])
  const [
    fetchedApparelAccessoriesEnhencerCategories,
    setFetchedApparelAccessoriesEnhencerCategories,
  ] = useState([])
  const [
    updatedApparelAccessoriesEnhencerCategories,
    setUpdatedApparelAccessoriesEnhencerCategories,
  ] = useState([])

  const [fetchedTopEnhencerCategoriesOut, setFetchedTopEnhencerCategoriesOut] =
    useState([])
  const [updatedTopEnhencerCategoriesOut, setUpdatedTopEnhencerCategoriesOut] =
    useState([])
  const [
    fetchedApparelAccessoriesEnhencerCategoriesOut,
    setFetchedApparelAccessoriesEnhencerCategoriesOut,
  ] = useState([])
  const [
    updatedApparelAccessoriesEnhencerCategoriesOut,
    setUpdatedApparelAccessoriesEnhencerCategoriesOut,
  ] = useState([])

  const [isDirty, setIsDirty] = useState(false)
  const [saving, setSaving] = useState(false)
  const [sharing, setSharing] = useState(false)
  const [switchEnabled, setSwitchEnabled] = useState(
    customer.crmDetails?.isAudienceNetworkEnabled ? 'yes' : 'no',
  )
  const [userSelectedEnhencerCategories, setUserSelectedEnhencerCategories] =
    useState(
      customer.userSelectedEnhencerCategories
        ? customer.userSelectedEnhencerCategories
        : [],
    )

  const { pathname } = useLocation()
  const userId = pathname.split('/')[3]

  const selectTopEnhencerCategories = (index, direction) => {
    const categories = direction === "in"
      ? [...updatedTopEnhencerCategories]
      : [...updatedTopEnhencerCategoriesOut]

    if (categories[index].name === 'Apparel & Accessories') {
      if (direction === "in") {
        setUpdatedApparelAccessoriesEnhencerCategories(
          JSON.parse(
            JSON.stringify(constants.apparelAccessoriesEnhencerCategories),
          ),
        )
      } else {
        setUpdatedApparelAccessoriesEnhencerCategoriesOut(
          JSON.parse(
            JSON.stringify(constants.apparelAccessoriesEnhencerCategories),
          ),
        )
      }
    }
    categories[index] = {
      ...categories[index],
      selected: !categories[index].selected,
    }
    if (direction === "in") {
      setUpdatedTopEnhencerCategories(categories)
    } else {
      setUpdatedTopEnhencerCategoriesOut(categories)
    }
  }

  const selectApparelAccessoriesEnhencerCategories = (index, direction) => {
    const categories = direction === "in"
      ? [...updatedApparelAccessoriesEnhencerCategories]
      : [...updatedApparelAccessoriesEnhencerCategoriesOut]

    categories[index] = {
      ...categories[index],
      selected: !categories[index].selected,
    }
    if (direction === "in") {
      setUpdatedApparelAccessoriesEnhencerCategories(categories)
    } else {
      setUpdatedApparelAccessoriesEnhencerCategoriesOut(categories)
    }
  }
  

  const saveSwitch = async () => {
    try {
      const queryData = {
        'crmDetails.isAudienceNetworkEnabled': switchEnabled === 'yes',
      }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userId}`,
        'PATCH',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          data: {
            ...customer,
            crmDetails: {
              ...customer.crmDetails,
              isAudienceNetworkEnabled: switchEnabled === 'yes',
            },
          },
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const saveCategories = async () => {
    const foundNotSelectedTopEnhencerCategories = helper.findBy(
      updatedTopEnhencerCategories,
      'selected',
      true,
    )
    const foundNotSelectedTopEnhencerCategoriesOut = helper.findBy(
      updatedTopEnhencerCategoriesOut,
      'selected',
      true,
    )

    const foundNotSelectedApparelAccessoriesEnhencerCategories =
      helper.findBy(
        updatedApparelAccessoriesEnhencerCategories,
        'selected',
        true,
      )
    const foundNotSelectedApparelAccessoriesEnhencerCategoriesOut =
      helper.findBy(
        updatedApparelAccessoriesEnhencerCategoriesOut,
        'selected',
        true,
      )

    const isApparelAccessoriesEnhencerCategorySelected = helper.findBy(
      updatedTopEnhencerCategories,
      'name',
      'Apparel & Accessories',
    ).selected

    const isApparelAccessoriesEnhencerCategorySelectedOut = helper.findBy(
      updatedTopEnhencerCategoriesOut,
      'name',
      'Apparel & Accessories',
    ).selected

    if (
      !foundNotSelectedTopEnhencerCategories || !foundNotSelectedTopEnhencerCategoriesOut
      ||
      (!foundNotSelectedApparelAccessoriesEnhencerCategories &&
        isApparelAccessoriesEnhencerCategorySelected) ||
      (!foundNotSelectedApparelAccessoriesEnhencerCategoriesOut &&
        isApparelAccessoriesEnhencerCategorySelectedOut)
    ) {
      return toast.warn('Please select at least one category!')
    }

    const enhencerCategories = helper
      .findAll(updatedTopEnhencerCategories, 'selected', true)
      .map(c => c.name)
      .concat(
        helper
          .findAll(
            updatedApparelAccessoriesEnhencerCategories,
            'selected',
            true,
          )
          .map(c => c.name),
      )

    const enhencerCategoriesOut = helper
      .findAll(updatedTopEnhencerCategoriesOut, 'selected', true)
      .map(c => c.name)
      .concat(
        helper
          .findAll(
            updatedApparelAccessoriesEnhencerCategoriesOut,
            'selected',
            true,
          )
          .map(c => c.name),

      )
    const queryData = {
      enhencerCategories: enhencerCategories,
      enhencerCategoriesOut: enhencerCategoriesOut,
    }
    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: <div>
        <h3>Are you sure you want to save these changes?</h3>
        <span>Audiences will be shared to customer:</span>
        <ul>
          {enhencerCategoriesOut.map(cat => {
            return <li style={{ fontSize: ".95em", color: "var(--text-gray)" }} key={cat + "out"}>{cat}</li>
          })}
        </ul>
        <span>Visitors will be saved to our pixel in:</span>
        <ul>
          {enhencerCategories.map(cat => {
            return <li style={{ fontSize: ".95em", color: "var(--text-gray)" }} key={cat + "in"}>{cat}</li>
          })}
        </ul>
      </div>,
    })
    
    if (confirmed) {
      setSaving(true)
      try {
        await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userId}`,
            'PATCH',
            JSON.stringify(queryData),
            {
              Authorization: 'Bearer ' + auth.token,
              'Content-Type': 'application/json',
            },
          )
          await shareNewAudiences()
          dispatch(
            adminActions.findCustomerByIdAndUpdate({
              id: customer.id,
              data: {
                ...customer,
                enhencerCategories: enhencerCategories,
                enhencerCategoriesOut: enhencerCategoriesOut,
              },
            }),
          )

          toast.success('Audiences saved & shared successfully!')

        } catch (err) {
          errorHandler(err)
        } finally {
          setSaving(false)
        }
    }
  }

  const shareNewAudiences = async () => {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/admin/updateEnhencerAudiencesList`,
      'POST',
      JSON.stringify({
        userId: userId,
      }),
      {
        Authorization: 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      },
    )
  }

  useEffect(() => {
    if (switchEnabled === 'no') {
      return
    }

    const topEnhencerCategories = JSON.parse(
      JSON.stringify(constants.topEnhencerCategories),
    )
    const apparelAccessoriesEnhencerCategories = JSON.parse(
      JSON.stringify(constants.apparelAccessoriesEnhencerCategories),
    )
    const topEnhencerCategoriesOut = JSON.parse(
      JSON.stringify(constants.topEnhencerCategories),
    )
    const apparelAccessoriesEnhencerCategoriesOut = JSON.parse(
      JSON.stringify(constants.apparelAccessoriesEnhencerCategories),
    )
    if (customer.enhencerCategories) {
      customer.enhencerCategories.forEach(category => {
        let foundCategory = helper.findBy(
          topEnhencerCategories,
          'name',
          category,
        )
        if (foundCategory) {
          foundCategory.selected = true
        } else {
          foundCategory = helper.findBy(
            apparelAccessoriesEnhencerCategories,
            'name',
            category,
          )
          if (foundCategory) {
            foundCategory.selected = true
          }
        }
      })
    }

    if (customer.enhencerCategoriesOut) {
      customer.enhencerCategoriesOut.forEach(category => {
        let foundCategory = helper.findBy(
          topEnhencerCategoriesOut,
          'name',
          category,
        )
        if (foundCategory) {
          foundCategory.selected = true
        } else {
          foundCategory = helper.findBy(
            apparelAccessoriesEnhencerCategoriesOut,
            'name',
            category,
          )
          if (foundCategory) {
            foundCategory.selected = true
          }
        }
      })
    }

    setUserSelectedEnhencerCategories(customer.userSelectedEnhencerCategories ?? [])

    setFetchedTopEnhencerCategories(topEnhencerCategories)
    setUpdatedTopEnhencerCategories(topEnhencerCategories)
    setFetchedTopEnhencerCategoriesOut(topEnhencerCategoriesOut)
    setUpdatedTopEnhencerCategoriesOut(topEnhencerCategoriesOut)

    setFetchedApparelAccessoriesEnhencerCategories(
      apparelAccessoriesEnhencerCategories,
    )
    setUpdatedApparelAccessoriesEnhencerCategories(
      apparelAccessoriesEnhencerCategories,
    )
    setFetchedApparelAccessoriesEnhencerCategoriesOut(
      apparelAccessoriesEnhencerCategoriesOut,
    )
    setUpdatedApparelAccessoriesEnhencerCategoriesOut(
      apparelAccessoriesEnhencerCategoriesOut,
    )
  }, [switchEnabled, userId])

  useEffect(() => {
    if (
      switchEnabled ===
      (customer.crmDetails?.isAudienceNetworkEnabled ? 'yes' : 'no')
    ) {
      return
    }

    saveSwitch()
  }, [switchEnabled])

  // We track selected categories to show/hide the save button.
  useEffect(() => {
    if (
      !updatedTopEnhencerCategories.length ||
      !updatedApparelAccessoriesEnhencerCategories.length ||
      !updatedTopEnhencerCategoriesOut.length ||
      !updatedApparelAccessoriesEnhencerCategoriesOut.length
    ) {
      setIsDirty(false)
      return
    }

    const isAnyTopEnhencerCategoryChanged = fetchedTopEnhencerCategories.some(
      (cat, index) => {
        return cat.selected !== updatedTopEnhencerCategories[index].selected
      },
    )
    const isAnyTopEnhencerCategoryOutChanged = fetchedTopEnhencerCategoriesOut.some(
      (cat, index) => {
        return cat.selected !== updatedTopEnhencerCategoriesOut[index].selected
      },
    )

    const isAnyApparelAccessoriesEnhencerCategoryChanged =
      fetchedApparelAccessoriesEnhencerCategories.some((cat, index) => {
        return (
          cat.selected !==
          updatedApparelAccessoriesEnhencerCategories[index].selected
        )
      })
    const isAnyApparelAccessoriesEnhencerCategoryOutChanged =
      fetchedApparelAccessoriesEnhencerCategoriesOut.some((cat, index) => {
        return (
          cat.selected !==
          updatedApparelAccessoriesEnhencerCategoriesOut[index].selected
        )
      })


    setIsDirty(
      isAnyTopEnhencerCategoryChanged ||
      isAnyApparelAccessoriesEnhencerCategoryChanged ||
      isAnyTopEnhencerCategoryOutChanged ||
      isAnyApparelAccessoriesEnhencerCategoryOutChanged,
    )
  }, [
    updatedTopEnhencerCategories,
    updatedApparelAccessoriesEnhencerCategories,
    updatedTopEnhencerCategoriesOut,
    updatedApparelAccessoriesEnhencerCategoriesOut,
  ])

  useEffect(() => {
    setIsDirty(false)
    setSwitchEnabled(
      customer.crmDetails?.isAudienceNetworkEnabled ? 'yes' : 'no',
    )
  }, [customer])

  return (
    <CollapsibleCard
      title="Audience Network"
      className="turquoise audience-network-card"
    >
      <div className="input-row first-part">
        <span>Enabled</span>
        <Switch
          value={switchEnabled}
          onChange={setSwitchEnabled}
          options={['no', 'yes']}
        />
      </div>
      {switchEnabled === 'yes' && (
        <React.Fragment>
          <div className="input-row">
            <h3>Top Enhencer Categories</h3>
          </div>
          <div className="map-categories-container">
            <div className="checkbox-group-name">
              <div className="user-selected">
                User
                <br /> Selected
              </div>
              <div className="user-selected">
                Pixel <br />
                IN
              </div>
              <div className="user-selected">
                Audience <br />
                OUT
              </div>
            </div>
            {loading && <span>Loading...</span>}
            {!loading && !updatedTopEnhencerCategories.length && (
              <span className="no-category-text">No category found</span>
            )}

            {!loading &&
              updatedTopEnhencerCategories.length > 0 &&
              updatedTopEnhencerCategories.map((topEnhencerCategory, index) => (
                <div key={topEnhencerCategory.name} className="input-row">
                  <div className="input-group">
                    <input
                      className="user-selected"
                      type="checkbox"
                      checked={userSelectedEnhencerCategories.includes(
                        topEnhencerCategory.name,
                      )}
                      disabled={true}
                    />
                    <input
                      type="checkbox"
                      checked={topEnhencerCategory.selected}
                      onChange={e => {
                        selectTopEnhencerCategories(index, "in")
                      }}
                    />
                    <input
                      type="checkbox"
                      checked={helper.findBy(updatedTopEnhencerCategoriesOut,
                        "name",
                        topEnhencerCategory.name
                      ).selected}
                      onChange={e => {
                        selectTopEnhencerCategories(index, "out")
                      }}
                    />
                  </div>
                  <span>{topEnhencerCategory.name}</span>
                </div>
              ))}
          </div>
        </React.Fragment>)}

      {switchEnabled === 'yes' && (
        <React.Fragment>
          <div className="input-row">
            <h3>Apparel & Accessories Categories</h3>
          </div>
          <div className="map-categories-container">

            <div className="checkbox-group-name">
              <div className="user-selected">
                User
                <br /> Selected
              </div>
              <div className="user-selected">
                Pixel <br />
                IN
              </div>
              <div className="user-selected">
                Audience <br />
                OUT
              </div>
            </div>
            {loading && <span>Loading...</span>}
            {!loading && !updatedApparelAccessoriesEnhencerCategories.length && (
              <span className="no-category-text">No category found</span>
            )}

            {!loading &&
              updatedApparelAccessoriesEnhencerCategories.length > 0 &&
              (helper.findBy(
                updatedTopEnhencerCategories,
                'name',
                'Apparel & Accessories',
              ).selected || helper.findBy(
                updatedTopEnhencerCategoriesOut,
                'name',
                'Apparel & Accessories',
              ).selected) &&
              updatedApparelAccessoriesEnhencerCategories.map(
                (apparelAccessoriesEnhencerCategory, index) => (
                  <div
                    key={apparelAccessoriesEnhencerCategory.name}
                    className="input-row"
                  >
                    <div className="input-group">
                      <input
                        className="user-selected"
                        type="checkbox"
                        checked={userSelectedEnhencerCategories.includes(
                          apparelAccessoriesEnhencerCategory.name,
                        )}
                        disabled={true}
                      />
                      <input
                        type="checkbox"
                        checked={apparelAccessoriesEnhencerCategory.selected}
                        onChange={e => {
                          selectApparelAccessoriesEnhencerCategories(
                            index,
                            "in",
                          )
                        }}
                      />
                      <input
                        type="checkbox"
                        checked={helper.findBy(
                          updatedApparelAccessoriesEnhencerCategoriesOut,
                          "name",
                          apparelAccessoriesEnhencerCategory.name).selected}
                        onChange={e => {
                          selectApparelAccessoriesEnhencerCategories(
                            index,
                            "out",
                          )
                        }}
                      />
                    </div>
                    <span>{apparelAccessoriesEnhencerCategory.name}</span>
                  </div>
                ),
              )}
          </div>
        </React.Fragment>)}
      {isDirty && (
        <div className="save-button-container">
          <Button
            loadingText="Saving"
            loading={saving}
            disabled={saving}
            className="save-button"
            onClick={saveCategories}
          >
            Save & Share

          </Button>
        </div>
      )}
    </CollapsibleCard>
  )
}

export default CardAdminAudienceNetwork
