import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import helper from '../util/helper'
import { facebookActions } from '../../store/facebook'
import { useModal } from '../../shared/hooks/modal-hook'

import useFacebookAudiences from './facebook/audience-hook'
import { useFacebookError } from './facebook/error-hook'

import { useFacebookReport } from './facebook/report-hook'


export const useFacebook = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const { closeModal } = useModal()
  const { handleError } = useFacebookError()
  const { checkForAudienceErrors } = useFacebookAudiences()
  const { fetchStatusesForFullFunnels } = useFacebookReport()

  const isSpecialUser = useSelector(state => state.auth.user?.isSpecialUser)
  const userId = useSelector(state => state.auth.user?.id)
  const userCountry = useSelector(state => state.auth.user?.country)
  const authToken = useSelector(state => state.auth.token)
  const authHeader = {
    Authorization: 'Bearer ' + authToken,
    'Content-Type': 'application/json',
  }
  
  const facebookGraphUrl = process.env.REACT_APP_FACEBOOK_GRAPH_URL
  const facebook = useSelector(state => state.facebook)


  const initializeFacebookInfo = async fb => {
    // console.log("initializing facebook info", fb)
    dispatch(facebookActions.connectionPageIsReady(false))

    if (!isSpecialUser) {
      let promises = []
      promises.push(
        getAdAccounts({
          userId: fb.auth.id,
          accessToken: fb.auth.accessToken,
          selectedAdAccountId: fb.selectedAdAccount?.id,
          freshFetch: false,
        }),
      )

      promises.push(
        getBusinesses({
          userId: fb.auth.id,
          accessToken: fb.auth.accessToken,
          selectedBusinessId: fb.selectedBusiness?.id,
          freshFetch: false,
        })
      )


      if (fb.selectedAdAccount) {

        promises.push(
          getPixelsOfAdAccount({
            adAccountId: fb.selectedAdAccount.id,
            accessToken: fb.auth.accessToken,
            selectedPixelId: fb.selectedPixel?.id,
          }),
        )
      }

      promises.push(
        fetchStatusesForFullFunnels({
          adAccountId: fb.selectedAdAccount?.id,
          accessToken: fb.auth.accessToken,
          fullFunnels: fb.fullFunnels
        })
      )
      
      try {
        let r = await Promise.all(promises)
        dispatch(facebookActions.connectionPageIsReady(true))
        // console.log("initializing facebook info finish")
      } catch (err) {
        dispatch(facebookActions.connectionPageIsReady(true))
        handleError(err)
      }
    } else { // if admin

      let newFb = { ...fb }
      if (fb.selectedAdAccount && fb.selectedAdAccount.id) {
        newFb.adAccounts = [fb.selectedAdAccount]
        newFb.adAccountSelectionIsEligible = true
      }
      if (fb.selectedBusiness && fb.selectedBusiness.id) {
        newFb.businesses = [fb.selectedBusiness]
        newFb.businessSelectionIsEligible = true
      }
      if (fb.selectedPixel && fb.selectedPixel.id) {
        newFb.pixels = [fb.selectedPixel]
        newFb.pixelSelectionIsEligible = true
      }
      
      dispatch(facebookActions.updateInfo(newFb))
      dispatch(facebookActions.connectionPageIsReady(true))
    }
  }

  const getAdAccounts = async ({
    userId,
    accessToken,
    selectedAdAccountId,
    freshFetch = false,
  }) => {
    let url = `${facebookGraphUrl}${userId}/adaccounts?fields=name,account_id,currency,business,tos_accepted&access_token=${accessToken}`

    dispatch(facebookActions.startLoading({ field: "adAccounts" }))

    let caching = selectedAdAccountId ? true : false

    let adAccounts = await fetchWithPagination({ url, caching })

    dispatch(facebookActions.stopLoading({ field: "adAccounts" }))
    dispatch(
      facebookActions.updateInfo({
        adAccounts: adAccounts,
        adAccountSelectionIsEligible: adAccounts
          .map(act => act.id)
          .includes(selectedAdAccountId),
      }),
    )
    return true
  }

  const getBusinesses = async ({ userId, accessToken, selectedBusinessId, freshFetch = false }) => {
    let url = `${facebookGraphUrl}${userId}/businesses?&access_token=${accessToken}`

    let caching = selectedBusinessId ? true : false

    dispatch(facebookActions.startLoading({ field: "businesses" }))

    let businesses = await fetchWithPagination({ url, caching })


    dispatch(
      facebookActions.updateInfo({
        businesses,
        businessSelectionIsEligible: businesses
          .map(b => b.id)
          .includes(selectedBusinessId),
      }),
    )
    dispatch(facebookActions.stopLoading({ field: "businesses" }))

    // await next(url)
  }

  const getPixelsOfAdAccount = async ({
    adAccountId,
    accessToken,
    selectedPixelId,
  }) => {
    //accessToken = 'EAADZA7gZBdvh4BO5JZAZARCgFmgmB5DxLqATtOwGPQZAOtaZCMyCmJrAKTqo9VZCGQ3ZC13F46bcuxHGTUZCJBUVR7mhSDPxV6v8aVpTSl4E5wCaHvCDIXDsmjyO6InGzddpZCbYhOyCnr9OSpVZAA32bcddnCZA4kcTmbwu6tRvtgPJA9fSVPVXjZB4205A3dFIH88kUppBuYYs9ooROHdtbZCgZDZD';
    let url = `${facebookGraphUrl}${adAccountId}/adspixels?fields=name,is_unavailable,last_fired_time&access_token=${accessToken}`
    dispatch(facebookActions.startLoading({ field: "pixels" }))

    let caching = selectedPixelId ? true : false
    let pixels = await fetchWithPagination({ url, caching })

    dispatch(facebookActions.updateInfo({
      pixels: pixels,
      pixelSelectionIsEligible: pixels
        .map(p => p.id)
        .includes(selectedPixelId),
    }))
    dispatch(facebookActions.stopLoading({ field: "pixels" }))
  }

  const selectAdAccount = async (account, isCheckup) => {
    dispatch(facebookActions.adAccountSelected(account))
    let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/adAccountSelected`
    let result = await sendRequest(
      url,
      'POST',
      JSON.stringify({
        userId: userId,
        adAccount: account,
        accessToken: facebook.auth.accessToken,
        isCheckup: isCheckup
      }),
      authHeader,
    )

    dispatch(facebookActions.updateInfo({
      initialOverallReport: result.overallReportSnapshot,
      settings: {
        ...facebook.settings,
        minDailyBudget: result.minDailyBudgetForAdAccountCurrency
      }
    }))

    if (account) {
      getPixelsOfAdAccount({
        adAccountId: account.id,
        accessToken: facebook.auth.accessToken,
        selectedPixelId: facebook.selectedPixel?.id
      })
    }
    return true
  }

  const selectBusiness = business => {
    let url

    dispatch(facebookActions.businessSelected(business))
    url = `${process.env.REACT_APP_BACKEND_URL}/facebook/businessSelected`

    sendRequest(
      url,
      'POST',
      JSON.stringify({
        userId: userId,
        business: business,
      }),
      authHeader,
    )
  }

  const selectPixel = async (pixel, isCheckup) => {
    if (pixel == null || (!pixel.is_unavailable && pixel.last_fired_time)) {
      let url;

      url = `${process.env.REACT_APP_BACKEND_URL}/facebook/pixelSelected`

      try {
        await sendRequest(
          url,
          'POST',
          JSON.stringify({
            userId: userId,
            pixel: pixel,
            isCheckup: isCheckup
          }),
          authHeader,
        )

        dispatch(
          facebookActions.pixelSelected({
            pixel,
          }),
        )

        if (pixel && !isCheckup) {
          await helper.timeout(1000)
          createAudiencesForTheFirstTime({ pixel })
        }

      } catch (err) {
        toast.warn(err?.message || "Could not select pixel. Prevented by Facebook.")
      }
    } else {
      toast.warn("Could not select pixel. Prevented by Facebook.")
    }
  }


  const setActiveFacebookPage = ({ facebookPage }) => async (dispatch, getState) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/activeFacebookPageSelected`,
        'POST',
        JSON.stringify({
          userId: userId,
          facebookPage: facebookPage || null
        }),
        authHeader
      )

      dispatch(facebookActions.updateInfo({
        selectedActiveFacebookPage: facebookPage || null
      }))

      if (facebookPage && (!facebook.instagramAccounts || !facebook.instagramAccounts[facebookPage.id])) {
        await getInstagramAccountsForPage({
          pageId: facebookPage.id,
          pageAccessToken: facebookPage.access_token
        })
      }

    } catch (err) {
      toast.warn(err?.message ||
        facebookPage ? "Facebook page selection failed." : "Facebook page selection could not be cleared."
      )
    }
  }

  const setActiveInstagramAccount = ({ instagramAccount, forcedByFacebookPageSelection, facebookPage }) => async (dispatch, getState) => {
    const { facebook } = getState();

    if (facebook.selectedActiveInstagramAccount?.id !== instagramAccount?.id || (!facebook.selectedActiveInstagramAccount?.username)) {
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/facebook/activeInstagramAccountSelected`,
          'POST',
          JSON.stringify({
            userId: userId,
            instagramAccount: instagramAccount,
            facebookPage: forcedByFacebookPageSelection ? facebookPage : null
          }),
          authHeader
        )

        let updatedFields = {
          selectedActiveInstagramAccount: instagramAccount
        }

        if (forcedByFacebookPageSelection) {
          updatedFields.selectedActiveFacebookPage = facebookPage
        }

        dispatch(facebookActions.updateInfo(updatedFields))
      } catch (err) {
        toast.warn(err?.message || "Could not select instagram account.")
      }
    }
  }


  const createAudiencesForTheFirstTime = async ({ pixel = facebook.selectedPixel }) => {

    dispatch(facebookActions.creatingAudiences(true))

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/createAudiencesForTheFirstTime`
      let audiencesResult = await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: userId,
          accessToken: facebook.auth.accessToken,
          selectedAdAccount: facebook.selectedAdAccount,
          pixel,
          fbAudiences: facebook.audiences,
          country: userCountry
        }),
        authHeader,
      )

      dispatch(facebookActions.creatingAudiences(false))
      checkForAudienceErrors({ audiences: audiencesResult.audiences })

      dispatch(facebookActions.updateInfo({ audiences: audiencesResult.audiences }))

      dispatch(facebookActions.creatingAudiences(false))
    } catch (err) {
      handleError(err)
      closeModal()
    } finally {
      dispatch(facebookActions.creatingAudiences(false))
    }

    return true
  }

  const retryAudienceCreation = async () => {

    dispatch(facebookActions.creatingAudiences(true))

    const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/retryAudienceCreation`
    try {
      let audiencesResult = await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: userId,
          accessToken: facebook.auth.accessToken,
          adAccount: facebook.selectedAdAccount,
          pixel: facebook.selectedPixel,
          currentAudiences: facebook.audiences,
          country: userCountry
        }),
        authHeader,
      )


      dispatch(facebookActions.creatingAudiences(false))

      checkForAudienceErrors({ audiences: audiencesResult.audiences })
      dispatch(facebookActions.updateAField({ field: 'audiences', value: audiencesResult.audiences }))

    } catch (err) {
      handleError(err)
      closeModal()
    }

    return true
  }

  const getCatalogs = async ({ businessId = facebook.selectedBusiness?.id, accessToken = facebook.auth.accessToken, getFromCacheIfAvailable = true }) => {
    let url = `${facebookGraphUrl}${businessId}/owned_product_catalogs?fields=name,product_count&access_token=${accessToken}`
    let catalogs = await fetchWithPagination({ url, caching: true, readFromCache: getFromCacheIfAvailable })
    catalogs = catalogs.filter(a => a.product_count > 0)
    dispatch(facebookActions.updateAField({ field: 'catalogs', value: catalogs }))

    return catalogs
  }

  const getProductSetsOfCatalog = async ({ catalogId, accessToken = facebook.auth.accessToken, getFromCacheIfAvailable = true }) => {

    let url = `${facebookGraphUrl}${catalogId}/product_sets?fields=name,product_count&access_token=${accessToken}`
    let productSets = await fetchWithPagination({ url, caching: true, readFromCache: getFromCacheIfAvailable })
    let allProductsIndex = helper.findIndex(productSets, 'name', 'All products')
    if (allProductsIndex !== 0) {
      let allProductsSet = productSets[allProductsIndex]
      productSets.unshift(allProductsSet)
      productSets.splice(allProductsIndex + 1, 1)
    }


    dispatch(facebookActions.updateAField({
      field: 'productSets',
      value: {
        ...facebook.productSets,
        [catalogId]: productSets
      }
    }))

    return productSets
  }

  const getProductFeedsFromFacebook = async ({ catalogId, accessToken = facebook.auth.accessToken, getFromCacheIfAvailable = true }) => {
    const url = `${facebookGraphUrl}${catalogId}/product_feeds?fields=id,name,primary_feeds,latest_upload,ingestion_source_type&access_token=${accessToken}`
    const productFeeds = await fetchWithPagination({ url, caching: true, readFromCache: getFromCacheIfAvailable })

    dispatch(facebookActions.updateAField({ field: 'productFeeds', value: productFeeds }))

    return productFeeds
  }

  const getFacebookPages = async ({ facebookUserId, accessToken = facebook.auth.accessToken, getFromCacheIfAvailable = true }) => {

    let url = `${facebookGraphUrl}${facebookUserId}/accounts?access_token=${accessToken}`
    let pages = await fetchWithPagination({ url, caching: true, readFromCache: getFromCacheIfAvailable })
    pages.forEach(page => {
      page.profile_pic = `${facebookGraphUrl}${page.id}/picture`
    })
    dispatch(facebookActions.updateAField({ field: 'pages', value: pages }))

    //update selectedActiveFacebookPage's access_token with new data
    if (facebook.selectedActiveFacebookPage && pages.find(p => p.id === facebook.selectedActiveFacebookPage.id)) {
      dispatch(facebookActions.updateAField({
        field: 'selectedActiveFacebookPage',
        value: {
          ...facebook.selectedActiveFacebookPage,
          access_token: pages.find(p => p.id === facebook.selectedActiveFacebookPage.id).access_token
        }
      }))
    }

    return pages
  }

  const getInstagramAccountsForPage = async ({ pageId, pageAccessToken, getFromCacheIfAvailable = true }) => {

    let url = `${facebookGraphUrl}${pageId}/instagram_accounts?fields=username,profile_pic&access_token=${pageAccessToken}`
    let accounts = await fetchWithPagination({ url, caching: true, readFromCache: getFromCacheIfAvailable })

    if (facebook.selectedActiveInstagramAccount && accounts.find(a => a.id === facebook.selectedActiveInstagramAccount.id)) {
      dispatch(facebookActions.updateAField({
        field: 'selectedActiveInstagramAccount',
        value: {
          ...facebook.selectedActiveInstagramAccount,
          profile_pic: accounts.find(a => a.id === facebook.selectedActiveInstagramAccount.id).profile_pic

        }
      }))
    }

    dispatch(facebookActions.updateAField({
      field: 'instagramAccounts',
      value: {
        ...facebook.instagramAccounts,
        [pageId]: accounts
      }
    }))

    return accounts
  }

  const getPostsOfAnAccount = async ({ pageId, accessToken = facebook.auth.accessToken }) => {
    let url = `${facebookGraphUrl}${pageId}?fields=feed{id,permalink_url,message,picture,promotable_id,created_time}&access_token=${accessToken}`

    let res = await sendRequest(
      url,
      'GET',
      null,
      authHeader,
      true,
      true
    )

    dispatch(facebookActions.updateAField({
      field: 'pagePosts',
      value: {
        ...facebook.pagePosts,
        [pageId]: {
          data: res.feed.data,
          next: res.feed.paging.next
        }
      }
    }))

  }

  const getProductsOfEdge = async ({
    edgeName,
    edgeId,
    accessToken = facebook.auth.accessToken,
    fields,
    filter,
    after,
  }) => {
    const cachedProductsKey = `${edgeName === 'productSet' ? 'ps' : edgeName === 'productFeed' ? 'pf' : 'ctg'}_${edgeId}_products${filter ? '_filter__' + filter : ''}`
    const cachedProducts = JSON.parse(sessionStorage.getItem(cachedProductsKey))

    if (!after && cachedProducts) {
      return {
        products: cachedProducts.data,
        paging: cachedProducts.paging,
      }
    }

    try {
      let url = `${facebookGraphUrl}${edgeId}/products?fields=${fields}&access_token=${accessToken}`
      if (filter) url += `&filter=${filter}`
      if (after) url += `&after=${after}`

      const productsResponse = await sendRequest(url, 'GET')

      if (!after) {
        sessionStorage.setItem(
          cachedProductsKey,
          JSON.stringify({
            data: productsResponse.data,
            paging: productsResponse.paging,
          }),
        )
      }

      return {
        products: productsResponse.data,
        paging: productsResponse.paging,
      }
    } catch (err) {
      console.log('error while getting products ', err)
      return []
    }
  }

  const fetchWithPagination = async ({ url, caching = false, readFromCache = true }) => {
    let edges = [];

    const next = async (nextUrl) => {
      try {
        const res = await sendRequest(nextUrl, "GET", null, {}, caching, readFromCache);

        if (res.error) {
          throw { error: res.error };
        }

        edges = edges.concat(res.data);

        if (res.paging?.next) {
          return next(res.paging.next); // Recursively call with the next URL
        } else {
          return edges; // Return the edges when done
        }
      } catch (err) {
        handleError(err);
        return []
      }
    };

    return await next(url); // Ensure the result of `next` is returned
  };

  const verifyPixelEvents = async ( userId, userAdAccountId ) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/users/pixelEventsVerified`,
      'POST',
      JSON.stringify({
        userId: userId,
        userAdAccountId: userAdAccountId,
      }),
      {
        authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
      },
    )
    if (responseData) {
      dispatch(
        facebookActions.updateInfo({
          pixelEventsVerified: responseData.pixelEventsVerified,
        }),
      )

    } else {
      console.log('error occurred')
    }
   
  }


  return {
    initializeFacebookInfo,
    getAdAccounts,
    selectPixel,
    selectAdAccount,
    selectBusiness,
    setActiveFacebookPage,
    setActiveInstagramAccount,
    createAudiencesForTheFirstTime,
    retryAudienceCreation,
    getCatalogs,
    getProductSetsOfCatalog,
    getProductFeedsFromFacebook,
    getFacebookPages,
    getInstagramAccountsForPage,
    getProductsOfEdge,
    getPostsOfAnAccount,
    verifyPixelEvents,
  }
}
