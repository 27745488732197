import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaSave, FaTimes } from "react-icons/fa";

import "./AudienceManager.css"


import filterIcon from "../../shared/assets/images/icons/filter-icon.svg"
import cogIcon from "../../shared/assets/images/icons/cog-icon.svg"
import cogIconGreen from "../../shared/assets/images/icons/cog-icon-green.svg"

import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";


import SegmentList from "../components/SegmentList";
import { projectActions } from "../../store/project";
import helper from "../../shared/util/helper";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import SegmentDetail from "../components/SegmentDetail";
import AudiencesSummary from "../components/AudiencesSummary";
import Button from "../../shared/components/FormElements/Button";
import AudienceSettings from "../components/AudienceSettings";
import { useConstants } from "../../shared/hooks/constants-hook";
import { useCampaigns } from "../../shared/hooks/campaigns-hook";
import errorHandler from "../../shared/util/errorHandler";

const AudienceManager = props => {
    const { publicConstants } = useConstants()
    const location = useLocation();
    const dispatch = useDispatch();

    const [segmentDetailIndex, setSegmentDetailIndex] = useState(0)
    const authUserId = useSelector((state) => state.auth.user.id);
    const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser)
    const isDemoUser = useSelector(state => state.auth.user.isDemo)
    const authToken = useSelector(state => state.auth.token)
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const userInViewId = query.get('user_in_view_id');

    const [isLoading, setIsLoading] = useState(false)
    const [isChartsCardLoading, setIsChartsCardLoading] = useState(false)


    const { sendRequest } = useHttpClient();

    const allCampaigns = useSelector(state => state.project.campaigns);
    const currentCampaign = useSelector(state => state.project.currentCampaign);


    const [targetChoices, setTargetChoices] = useState(null);
    const [audienceSettingsOpen, setAudienceSettingsOpen] = useState(false)

    const [uplift, setUplift] = useState()
    const [topVisitorsPercentage, setTopVisitorsPercentage] = useState()

    const [selectedCampaignId, setSelectedCampaignId] = useState(null)
    const { getCampaignDetailsAndSetCurrentCampaign } = useCampaigns()


    useEffect(() => {
        if (selectedCampaignId && userInViewId) {
            setIsLoading(true)
            getCampaignDetailsAndSetCurrentCampaign({ campaignId: selectedCampaignId, userId: userInViewId })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }, [selectedCampaignId, userInViewId])

    useEffect(() => {
        if (allCampaigns.length > 0 && !selectedCampaignId) {
            setSelectedCampaignId(allCampaigns[0].id)

        }
    }, [allCampaigns])


    useEffect(() => {
        if (currentCampaign && currentCampaign.audience?.segments) {
            let overallSize = 0
            let selectedSize = 0
            currentCampaign.audience.segments.forEach(s => {
                if (s.isSelected) {
                    selectedSize += s.count
                }
                overallSize += s.count
            })

            const selectedSegments = currentCampaign.audience.segments ? helper.findAll(currentCampaign.audience.segments, "isSelected", true) : [];
            const percentage = currentCampaign.audience.expected ? (selectedSize / overallSize * 100).toFixed(1) : ""
            const lift = currentCampaign.audience.expected ? (currentCampaign.audience.expected.propensity / currentCampaign.audience.overall.propensity).toFixed(1) : ""
            setTopVisitorsPercentage(percentage)
            setUplift(lift)
            setAudienceSettingsOpen(false)

            if (location.state && location.state.targetChoices) {
                setTargetChoices(location.state.targetChoices);
            }
        }
    }, [currentCampaign])


    const onToggleSegment = async (index) => {
        if (isSpecialUser) {
            setIsChartsCardLoading(true)
            try {
                let segments = JSON.parse(JSON.stringify(currentCampaign.audience.segments));
                let selectedSegmentNos = JSON.parse(JSON.stringify(currentCampaign.audience.selectedSegmentNos));
                if (index + 1 === selectedSegmentNos.length) {
                    return true
                } else {
                    let lengthOfSelectedSegmentNos = selectedSegmentNos.length;
                    if (index + 1 < lengthOfSelectedSegmentNos) {
                        for (let i = index + 1; i < lengthOfSelectedSegmentNos; i++) {
                            segments[i].isSelected = false;
                        }
                        selectedSegmentNos.splice(index + 1, lengthOfSelectedSegmentNos - index - 1);
                    } else {
                        for (let i = lengthOfSelectedSegmentNos; i < index + 1; i++) {
                            segments[i].isSelected = true;
                            selectedSegmentNos.push(i + 1);
                        }
                    }
                }



                const requestType = "segment";
                const payload = {
                    selectedSegmentNos: selectedSegmentNos
                };
                const data = createDataForRequest(requestType, payload);
                let url = createUrlForRequest();
                const result = await sendRequest(
                    url,
                    "POST",
                    JSON.stringify(data),
                    {
                        Authorization: "Bearer " + authToken,
                        "Content-Type": "application/json",
                    }
                );
                const newAudience = {
                    ...currentCampaign.audience,
                    selectedSegmentNos: selectedSegmentNos,
                    segments: segments,
                    expected: result.expected,
                    live: result.live,
                    expectedConvRateData: result.expectedConvRateData,
                    expectedNumOfVisitorsData: result.expectedNumOfVisitorsData,
                    expectedLabels: result.expectedLabels,
                };
                dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { audience: newAudience } }));
                setSegmentDetailIndex(index)
                return true
            } catch (err) {
                errorHandler(err)
                return false
            } finally {
                setIsChartsCardLoading(false)
            }
        } else {
            setSegmentDetailIndex(index)
        }
    }

    const onDayChange = async (newDayOption) => {
        const dayObj = helper.findBy(publicConstants.audienceResultIntervals, "name", newDayOption);
        const day = dayObj.days
        try {
            const requestType = "day";
            const payload = {
                day: day
            };
            const data = createDataForRequest(requestType, payload);
            let url = createUrlForRequest();
            const result = await sendRequest(
                url,
                "POST",
                JSON.stringify(data),
                {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                }
            );
            const newAudience = {
                ...currentCampaign.audience,
                expected: result.expected,
                live: result.live
            };

            dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { audience: newAudience } }));
        } catch (err) {
            errorHandler(err)
        }
    }

    const createDataForRequest = (requestType, payload) => {
        let data;
        if (requestType === "day") {
            data = {
                audiencePath: currentCampaign.audiencePath,
                selectedSegmentNos: currentCampaign.audience.selectedSegmentNos,
                day: payload.day,
                includePurchased: currentCampaign.audience.includePurchased,
                includeAddtoBasket: currentCampaign.audience.includeAddtoBasket
            };
        } else if (requestType === "segment") {
            data = {
                audiencePath: currentCampaign.audiencePath,
                selectedSegmentNos: payload.selectedSegmentNos,
                day: currentCampaign.audience.day,
                includePurchased: currentCampaign.audience.includePurchased,
                includeAddtoBasket: currentCampaign.audience.includeAddtoBasket,
                isToggleSegment: true
            };
        } else if (requestType === "include-purchased") {
            data = {
                audiencePath: currentCampaign.audiencePath,
                selectedSegmentNos: currentCampaign.audience.selectedSegmentNos,
                day: currentCampaign.audience.day,
                includePurchased: payload.includePurchased,
                includeAddtoBasket: currentCampaign.audience.includeAddtoBasket
            }
        }
        if (isDemoUser) {
            data.isDemo = true;
        } else {
            if (isSpecialUser) {
                data.userId = userInViewId;
            }
        }
        return data;
    }

    const createUrlForRequest = () => {
        let url;
        return `${process.env.REACT_APP_BACKEND_URL}/${isSpecialUser ? 'admin' : 'projects'}/calculateValues`
    }

    const openSegmentDetail = (i) => {
        setSegmentDetailIndex(i)
    }

    const saveAudience = async (multi) => {
        try {
            let url;
            let data;

            if (isSpecialUser) {
                url = `${process.env.REACT_APP_BACKEND_URL}/admin/updateCampaignAudience`;
                data = JSON.stringify({
                    userId: userInViewId,
                    campaignId: currentCampaign.id,
                    audience: currentCampaign.audience,
                    multi: multi
                });
            } else {
                if (isDemoUser) {
                    url = `${process.env.REACT_APP_BACKEND_URL}/projects/updateCampaignAudience`;
                    data = JSON.stringify({
                        isDemo: true,
                        audiencePath: currentCampaign.audiencePath,
                        audience: currentCampaign.audience
                    });
                } else {
                    url = `${process.env.REACT_APP_BACKEND_URL}/projects/updateCampaignAudience`;
                    data = JSON.stringify({
                        userId: authUserId,
                        campaignId: currentCampaign.id,
                        audience: currentCampaign.audience
                    });
                }
            }

            await sendRequest(url, "POST", data,
                {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                }
            );

            if (currentCampaign.audience.isDemo) {
                // dispatch(projectActions.setAudience({ index: -1, audience: audience, isDemo: true }));
            } else {
                dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { audience: currentCampaign.audience } }))

            }
            toast.success("Audience saved!");
        } catch (err) {
            errorHandler(err)
        }
    }

    const toggleAudienceSettings = () => {
        setAudienceSettingsOpen(!audienceSettingsOpen)
    }

    const handleCampaignChange = (event) => {
        const selectedCampaign = allCampaigns.find(c => c.id === event.target.value);
        setSelectedCampaignId(selectedCampaign.id)

    }

    return <div className="audience-page">

        <div className="page-content">
            <div className="audience-manager audience-manager-page">
                <div className="campaign-selector">
                    <div className="campaign-selector-header">
                        Select Event Name
                    </div>
                    <select
                        value={selectedCampaignId || ''}
                        onChange={handleCampaignChange}
                        className="campaign-dropdown"
                    >
                        <option value="">Select Campaign</option>
                        {allCampaigns.map(camp => (
                            <option key={camp.id} value={camp.id}>
                                {camp.audiencePath}
                            </option>
                        ))}
                    </select>
                </div>

                {isLoading ? <InlineLoadingSpinner center={true} /> :
                    currentCampaign && currentCampaign.audience?.segments && (
                        <div className="middle-container column">
                            <div className="result-card">
                                {((isSpecialUser) && targetChoices) && <div className="target-choices">
                                    {targetChoices.map((targetChoice) => (
                                        <div key={targetChoice.name} className={targetChoice.usageLabel === "Use" ? "use-target-choice green" : "use-target-choice gray"}>
                                            {targetChoice.name}
                                        </div>
                                    ))}
                                </div>}
                                <AudiencesSummary isChartLoading={isChartsCardLoading} enhencerAudience={{
                                    expected: currentCampaign.audience.expected,
                                    live: currentCampaign.audience.live,
                                    expectedConvRateData: currentCampaign.audience.expectedConvRateData,
                                    expectedNumOfVisitorsData: currentCampaign.audience.expectedNumOfVisitorsData,
                                    expectedLabels: currentCampaign.audience.expectedLabels,
                                    liveConvRateData: currentCampaign.audience.liveConvRateData,
                                    liveNumOfVisitorsData: currentCampaign.audience.liveNumOfVisitorsData,
                                    liveLabels: currentCampaign.audience.liveLabels,
                                }} overall={currentCampaign.audience.overall}
                                    allVisitorsConvRateData={currentCampaign.audience.allVisitorsConvRateData}
                                    allVisitorsNumOfVisitorsData={currentCampaign.audience.allVisitorsNumOfVisitorsData}
                                    allVisitorsLabels={currentCampaign.audience.allVisitorsLabels}
                                    onDayChange={onDayChange} />

                            </div>

                            <div className="result-card second">
                                <div className="card-header">

                                    {audienceSettingsOpen ?
                                        <React.Fragment>

                                            <div className="row">
                                                <img alt="cogicon" src={cogIcon} className="filters-toggle" />

                                                <h4><span className="green">Filter Category Bundles</span></h4>
                                            </div>
                                            <FaTimes style={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => { setAudienceSettingsOpen(false) }} />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>

                                            <div className="row">
                                                <img alt="filtericon" src={filterIcon} />
                                                <h4>Top <span style={{ fontSize: "1.1em" }}>{topVisitorsPercentage}%</span> of Visitors - <span className="green">Enhencer Remarketing Audience</span></h4>
                                            </div>
                                            <div className="">

                                                {isDemoUser ?
                                                    <div className="sample-text">
                                                        Sample AI Segments
                                                    </div>
                                                    :
                                                    <img alt="cogicon2" src={currentCampaign.filterBundles.length > 0 ? cogIconGreen : cogIcon} onClick={toggleAudienceSettings} className="filters-toggle" />
                                                }

                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="card-content">
                                    {audienceSettingsOpen ?
                                        <div className="audience-settings-container">
                                            <AudienceSettings audience={currentCampaign.audience} />
                                        </div>
                                        :
                                        <div className="row segmentation-row">
                                            {currentCampaign.audience.segments?.length > 0 && <SegmentList openSegmentDetail={openSegmentDetail} segmentDetailIndex={segmentDetailIndex} segments={currentCampaign.audience.segments} onToggleSegment={onToggleSegment} overallPropensity={currentCampaign.audience.overall?.propensity} />}
                                            <div style={{ flex: "1 1 50%" }}>
                                                {currentCampaign.audience.segments?.length > 0 && <SegmentDetail segment={currentCampaign.audience.segments[segmentDetailIndex]} segmentNo={segmentDetailIndex + 1} numberOfSegments={currentCampaign.audience.segments.length} />}
                                                {(isSpecialUser) && <Button className="save-audience-button" onClick={() => { saveAudience(false) }}>
                                                    <FaSave />
                                                    Save Audience
                                                </Button>}
                                                {(isSpecialUser) && <Button className="save-audiences-button" onClick={() => { saveAudience(true) }}>
                                                    <FaSave />
                                                    Save All {currentCampaign.audienceName === "Remarketing Audience" ? "Remarketing" : currentCampaign.audienceName === "High Intent Remarketing Audience" ? "High Intent Remarketing" : "Lookalike"} Audiences
                                                </Button>}

                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>)
                }

            </div>
        </div>
    </div>
}

export default AudienceManager;