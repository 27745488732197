import React, { useEffect, useState, useRef } from 'react'
import Chart from 'chart.js/auto'
import { FaInfoCircle } from 'react-icons/fa'
import helper from '../../../shared/util/helper'
import numberFormatter from '../../../shared/util/numberFormatter'
import './CampaignReport.css'
import TooltipComponent from '../../../shared/components/UIElements/Tooltip'
import './CampaignReportCard.css'

const ReportCard = props => {
  const {
    title,
    subtitle,
    subtitleValue,
    subtitle2,
    subtitle2Value,
    chartData,
    chartLabels,
    chartFullLabels,
    prefix = '',
    isActive,
    onClick,
    tooltipContents,
  } = props

  const cardColors = {
    'Ad Spend': '#0585E0',
    Reach: '#00A090',
    Clicks: '#2FA700',
    Sales: '#00963E',
    Performance: '#00AEE8',
  }

  const chartRef = useRef(null)
  const chartInstanceRef = useRef(null)
  const cardColor = cardColors[title]
  const hasChartData = chartData && chartData.length > 1
  const [showXAxis, setShowXAxis] = useState(false)

  const dummyChartData = Array(5)
    .fill(0)
    .map((_, i) => {
      const baseValue = 20
      const dailyIncrease = 1 + (Math.random() * 0.1 + 0.05)
      return baseValue * Math.pow(dailyIncrease, i)
    })

  const dummyChartLabels = Array(5)
    .fill(0)
    .map((_, i) => {
      const date = new Date()
      date.setDate(date.getDate() - (4 - i))
      return date.toISOString().split('T')[0]
    })

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d')

      chartInstanceRef.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: hasChartData ? chartLabels : dummyChartLabels,
          datasets: [
            {
              data: hasChartData ? chartData : dummyChartData,
              borderColor: hasChartData ? cardColor : '#ededed',
              borderWidth: 3,
              pointRadius: 4,
              pointHitRadius: 10,
              pointBackgroundColor: hasChartData ? cardColor : '#E0E0E0',
              backgroundColor: function (context) {
                if (!hasChartData) return '#E0E0E0'

                const chart = context.chart
                const { ctx, chartArea } = chart

                if (!chartArea) return null

                let gradient = ctx.createLinearGradient(
                  0,
                  chartArea.bottom,
                  0,
                  chartArea.top,
                )
                gradient.addColorStop(0, 'rgba(255, 255, 255, 0)')
                gradient.addColorStop(1, `${cardColor}60`)

                return gradient
              },
              tension: 0.4,
              fill: hasChartData,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            datalabels: {
              display: false,
            },
            legend: { display: false },
            tooltip: {
              enabled: hasChartData,
              displayColors: false,
              titleFont: {
                size: 12,
              },
              bodyFont: {
                size: 16,
                weight: 'bold',
              },
              callbacks: {
                title: function (tooltipItems) {
                  if (!tooltipItems || !tooltipItems[0] || !chartFullLabels) {
                    return ''
                  }

                  const date = chartFullLabels[tooltipItems[0].dataIndex]
                  if (!date) {
                    return ''
                  }
                  const [firstDate, secondDate] = date.split(' - ')
                  return (
                    subtitle +
                    '\n' +
                    (chartFullLabels
                      ? helper.convertDateToMonthAndDay(firstDate) +
                        ' - ' +
                        helper.convertDateToMonthAndDay(secondDate)
                      : '')
                  )
                },
                label: function (context) {
                  let label
                  switch (title) {
                    case 'Ad Spend':
                    case 'Sales':
                      label = numberFormatter.formatNumber(context.parsed.y, 0)
                      break
                    case 'Performance':
                      if (subtitle === 'ROAS') {
                        label = numberFormatter.formatNumber(
                          context.parsed.y,
                          2,
                        )
                      } else {
                        label = numberFormatter.formatNumber(
                          context.parsed.y,
                          0,
                        )
                      }
                      break
                    case 'Clicks':
                      if (subtitle2 === 'CTR') {
                        label = numberFormatter.formatNumber(
                          context.parsed.y,
                          2,
                        )
                      } else {
                        label = numberFormatter.formatNumber(
                          context.parsed.y,
                          0,
                        )
                      }
                      break
                    case 'Reach':
                      if (subtitle2 === 'Frequency') {
                        label = numberFormatter.formatNumber(
                          context.parsed.y,
                          3,
                        )
                      } else {
                        label = numberFormatter.formatNumber(
                          context.parsed.y,
                          0,
                        )
                      }
                      break
                    default:
                      label = numberFormatter.formatNumber(context.parsed.y, 0)
                  }

                  if (prefix && subtitle != 'ROAS') {
                    label = prefix + label
                  }
                  return label
                },
              },
            },
          },
          layout: {
            padding: {
              bottom: 20,
            },
          },
          scales: {
            x: {
              display: showXAxis,
              position: 'bottom',
              grid: {
                display: false,
              },
              ticks: {
                maxRotation: 45,
                minRotation: 45,
                font: {
                  size: 9,
                },
                callback: function (value, index) {
                  const date = chartLabels
                    ? new Date(chartLabels[index])
                    : new Date()
                  return (
                    date.getDate().toString().padStart(2, '0') +
                    '.' +
                    (date.getMonth() + 1).toString().padStart(2, '0')
                  )
                },
              },
            },
            y: {
              display: false,
            },
          },
          elements: {
            line: {
              tension: 0.4,
            },
          },
        },
      })
    }
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [
    chartData,
    cardColor,
    hasChartData,
    chartLabels,
    chartFullLabels,
    prefix,
    title,
  ])

  return (
    <div
      className={`acsr-report-card-container ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      <h3 style={{ color: isActive ? cardColor : 'inherit' }}>{title}</h3>
      <div className="acsr-report-card">
        <div className="acsr-card-content">
          <div className="acsr-subtitle">
            <TooltipComponent content={tooltipContents[subtitle]}>
              <div className="acsr-subtitle-label">
                <span className="acsr-subtitle-text">{subtitle}</span>
                <FaInfoCircle className="info-icon" />
              </div>
            </TooltipComponent>
            <span className="acsr-subtitle-value">
              {prefix && (subtitle === 'Total' || subtitle === 'Sales') && (
                <span className="currency">{prefix}</span>
              )}
              {subtitleValue}
            </span>
          </div>
          <div className="acsr-subtitle">
            <TooltipComponent content={tooltipContents[subtitle2]}>
              <div className="acsr-subtitle-label">
                <span className="acsr-subtitle-text">{subtitle2}</span>
                <FaInfoCircle className="info-icon" />
              </div>
            </TooltipComponent>
            <span className="acsr-subtitle-value">
              {prefix && subtitle2 !== 'Purchases' && (
                <span className="currency">{prefix}</span>
              )}
              {subtitle2Value}
            </span>
          </div>
        </div>
        <div className="acsr-line"></div>
        <TooltipComponent
          content={
            !hasChartData
              ? 'Campaigns should be active for at least 2 days for the chart'
              : ''
          }
          className="acsr-chart-tooltip"
        >
          <div className="acsr-chart">
            <canvas ref={chartRef} height="50" />
          </div>
        </TooltipComponent>
        {isActive && (
          <div
            className="acsr-active-indicator"
            style={{ backgroundColor: cardColor }}
          ></div>
        )}
      </div>
    </div>
  )
}

export default ReportCard
