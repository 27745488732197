import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp1bg from '../../../../shared/assets/images/ai-creative/common_temp1bg.svg'

export const Template1Config = {
  name: 'Time to do',
  containerId: 'template1-common',
  backgroundColor: 'white',
  hasDiscount: true,
  // Sequential elements array (back-to-front)
  elements: [// Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 18,
        y: 20
      },
      scale: {
        width: WORKBENCH_SIZE.width * 0.700,
        height: WORKBENCH_SIZE.height
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover',
      shadowColor: 'black',
      shadowBlur: 50,
      shadowOffset: { x: 0, y: 10 },
      shadowOpacity: 0.0,
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp1bg,
      isSvg: true,
      svgColor: 'color3',
      svgCustomReplace: '#2A2A2A',
      draggable: false,
      clickThrough: true
    },

    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width - 240,
        y: WORKBENCH_SIZE.height - 250
      },
      scale: {
        maxWidth: 300,
        maxHeight: 140
      },
      dynamicSrc: 'companyLogo',
      required: false
    },
    // Primary Text
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 260,
        y: 80
      },
      width: 200,
      height: 300,
      text: `30% OFF`,
      fontFamily: 'Bayon',
      fontSize: 100,
      color: 'color2',
      align: 'center',
      verticalAlign: 'middle',
      letterSpacing: 0.03,
      center: true
    },
    // Secondary Text
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 60,
        y: WORKBENCH_SIZE.height - 150
      },
      text: `BUY NOW`,
      fontFamily: 'Bayon',
      fontSize: 100,
      color: 'color2',
      letterSpacing: 2.5,
    }
  ]
}
