import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaEllipsisV, FaUndo, FaRedo } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { useFacebook } from '../../../shared/hooks/facebook-hook'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import useAICreatives from '../../../shared/hooks/ai-creatives-hook'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import Zoom from 'react-medium-image-zoom'
import Button from '../../../shared/components/FormElements/Button'

import styles from './EnhencedCatalogPreview.module.css'

const EnhencedCatalogPreview = ({
  aiCreativeId,
  enhencedCatalogDetails,
  className,
  completeModal,
}) => {
  const { productSetId, primaryFeedId, ignoredProducts } = enhencedCatalogDetails
  const userId = useSelector(state => state.auth.user.id)

  const { getProductsOfEdge } = useFacebook()
  const { toggleProductIgnoreStatus } = useEnhencedCatalog()
  const aiCreativeService = useAICreatives()

  const [isLoading, setIsLoading] = useState(true)
  const [paginationCursor, setPaginationCursor] = useState(null)
  const [fetchingNextPage, setFetchingNextPage] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [revertedProducts, setRevertedProducts] = useState(ignoredProducts || [])

  const containerRef = useRef(null)

  const getProducts = async ({ edgeName, edgeId, filter, after }) => {
    setIsLoading(true)
    const config = {
      edgeName,
      edgeId,
      fields:
        'image_url,name,price,url,retailer_id,retailer_product_group_id,description,gtin,currency,product_sets',
    }

    if (filter) config.filter = filter
    if (after) config.after = after

    const { products: newProducts, paging } = await getProductsOfEdge(config)

    if (!newProducts || newProducts.length < 25) {
      setPaginationCursor(null)
    } else {
      setPaginationCursor(paging?.cursors?.after)
    }

    const filteredProducts = newProducts.filter(product =>
      product.product_sets.data.some(
        productSet => productSet.id === productSetId,
      ),
    )

    if (after) {
      setProductsList([...productsList, ...filteredProducts])
    } else {
      setProductsList(filteredProducts)
    }
    setIsLoading(false)
  }

  const handleLoadMoreButton = async () => {
    if (!paginationCursor) return
    setFetchingNextPage(true)
    await getProducts({
      edgeName: 'productSet',
      edgeId: productSetId,
      after: paginationCursor,
    })
    setFetchingNextPage(false)

    // Scroll to the bottom of the container
    const container = containerRef.current
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
      })
    }
  }

  const toggleDropdown = (productId) => {
    setActiveDropdown(activeDropdown === productId ? null : productId)
  }

  const handleClickOutside = (event) => {
    if (!event.target.closest(`.${styles.completedProductActions}`)) {
      setActiveDropdown(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Fetch products when productSetId changes
  useEffect(() => {
    getProducts({
      edgeName: 'productFeed',
      edgeId: primaryFeedId,
    })
  }, [primaryFeedId])

  const toggleRevert = async productId => {
    const result = await toggleProductIgnoreStatus({
      aiCreativeId,
      productId,
    })

    if (result.data) {
      setRevertedProducts(prev => {
        if (prev.includes(productId)) {
          return prev.filter(id => id !== productId)
        } else {
          return [...prev, productId]
        }
      })

      aiCreativeService.updateById(aiCreativeId, result.data)

      toast.success(result.message)
    }

    setActiveDropdown(null)
  }

  return (
    <div className={`${styles.enhencedCatalogPreview} ${className}`}>
      {isLoading ? (
        <InlineLoadingSpinner message="Loading products from Facebook..." />
      ) : productsList?.length ? (
        <div className={styles.productsGrid} ref={containerRef}>
          {productsList.map(product => (
            <div
              className={styles.productContainer}
              key={product.id}
              onMouseLeave={() => setActiveDropdown(null)}
            >
              {completeModal ? (
                <div className={styles.completedProductActions}>
                  <button
                    className={styles.productActionsButton}
                    onClick={() => toggleDropdown(product.id)}
                  >
                    <FaEllipsisV />
                  </button>

                  <div
                    className={
                      activeDropdown === product.id
                        ? styles.productActionsMenuVisible
                        : styles.productActionsMenuHidden
                    }
                  >
                    <div
                      className={styles.productActionItem}
                      onClick={() => toggleRevert(product.id)}
                    >
                      {revertedProducts.includes(product.id) ? (
                        <>
                          <FaRedo /> Reapply
                        </>
                      ) : (
                        <>
                          <FaUndo /> Revert
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              <Zoom wrapElement="div">
                <picture className={styles.productImage}>
                  <source
                    media="(max-width: 800px)"
                    srcSet={
                      revertedProducts.includes(product.id)
                        ? product.image_url
                        : `${process.env.REACT_APP_AWS_BASE_URL}/ai-creative/${userId}/enhenced-catalog/${aiCreativeId}/${product.retailer_id || product.id}.jpeg`
                    }
                  />
                  <img
                    alt={product.name}
                    src={
                      revertedProducts.includes(product.id)
                        ? product.image_url
                        : `${process.env.REACT_APP_AWS_BASE_URL}/ai-creative/${userId}/enhenced-catalog/${aiCreativeId}/${product.retailer_id || product.id}.jpeg`
                    }
                    width="500"
                  />
                </picture>
              </Zoom>
            </div>
          ))}
          {paginationCursor && (
            <Button
              className={styles.loadMoreButton}
              primary={true}
              disabled={fetchingNextPage}
              loading={fetchingNextPage}
              onClick={handleLoadMoreButton}
            >
              Load More
            </Button>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default EnhencedCatalogPreview
