import React, { useEffect, useState } from 'react'
import data from "../../../shared/data/ai-creative-generative-templates.json"

import styles from './GenerativeTemplates.module.css'
import StrokeInput from '../../../shared/components/FormElements/StrokeInput';
import { useForm } from '../../../shared/hooks/form-hook';
import useGenerativeImages from '../../../shared/hooks/generative-images-hook';
import Button from '../../../shared/components/FormElements/Button';
import { FaCheck, FaEye } from 'react-icons/fa';
import imageExpander from '../../../shared/util/imageExpander';
import { useModal } from '../../../shared/hooks/modal-hook';
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook';
import Modals from '../../../shared/enums/Modals';

const GenerativeTemplates = () => {


    const { generateImage } = useGenerativeImages()
    const [filteredTemplates, setFilteredTemplates] = useState(data);
    const { openModal } = useModal()
    const {
        productOnDraft
    } = useEnhencedCatalog()

    const [formState, inputHandler] = useForm(
        {
            search: {
                value: "",
                isValid: true,
            },
        },
        true
    );

    useEffect(() => {
        console.log("searchText", formState.inputs.search.value)
        const searchText = formState.inputs.search.value.toLowerCase()

        const filtered = data.filter(template =>
            template.name.toLowerCase().includes(searchText) ||
            template.examples.some(example =>
                example.positive_prompt?.toLowerCase().includes(searchText)
            )
        );
        setFilteredTemplates(filtered);
    }, [formState.inputs.search.value]);

    const handlePreview = (e, imageUrl) => {
        e.stopPropagation()
        imageExpander.expandImage(imageUrl, "")
    }

    const continueWithTemplate = (e, imageUrl, templateId, exampleId) => {
        e.stopPropagation()

        openModal({
            type: Modals.IMAGE_GENERATOR,

            backgroundImage: imageUrl,
            productImage: productOnDraft.transparentImageUrl,
            templateId,
            exampleId,
            callback: (imageUrl) => {
                console.log("completed everything imageUrl", imageUrl)
            }
        })
    }

    return <>
        <div className={styles.searchBarContainer}>
            <StrokeInput
                element="input"
                id="search"
                type="text"
                label="Search"
                placeholder="Minimalist kitchen"
                onInput={inputHandler}
                value={formState.inputs.search.value}
                errorLine={false}
            />
        </div>
        <div className={styles.generativeTemplatesContainer}>
            {
                filteredTemplates.map(template => (
                    <div key={template.id} className={styles.templateItem}>
                        <h2 className={styles.templateName}>{template.name}</h2>
                        <div className={styles.templateImages}>
                            {
                                template.examples.map(example =>
                                    <div key={template.id + example.id} className={styles.templateImageContainer}>
                                        <div className={styles.templateImageOverlay}>
                                            <Button textButton onClick={(e) => handlePreview(e, `${process.env.REACT_APP_AWS_BASE_URL}/ai-creative/background-images/${example.s3_bucket_url}`)}>
                                                <FaEye />
                                                Preview
                                            </Button>
                                            <Button textButton onClick={(e) => continueWithTemplate(e, `${process.env.REACT_APP_AWS_BASE_URL}/ai-creative/background-images/${example.s3_bucket_url}`, template.id, example.id)}>
                                                <FaCheck />
                                                Use
                                            </Button>
                                        </div>
                                        <img
                                            src={`${process.env.REACT_APP_AWS_BASE_URL}/ai-creative/background-images-thumbnail/${example.s3_bucket_url}`}
                                            alt={template.name}
                                            className={styles.templateImage} />
                                        {/* <p className={styles.templateImagePrompt}>{example.positive_prompt}</p> */}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    </>
}

export default GenerativeTemplates