import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { FaSave } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_PASSWORD_MATCH,
} from '../../util/validators'
import Button from '../FormElements/Button'
import Input from '../FormElements/Input'
import { useHttpClient } from '../../hooks/http-hook'
import { useForm } from '../../hooks/form-hook'
import { useModal } from '../../hooks/modal-hook'

import 'react-toastify/dist/ReactToastify.css'
import errorHandler from '../../util/errorHandler'

const ChangePassword = () => {
  const [busy, setBusy] = useState(false)
  const auth = useSelector(state => state.auth)
  const { sendRequest } = useHttpClient()
  const { closeModal } = useModal()
  const [formState, inputHandler, setFormData] = useForm(
    {
      oldPassword: {
        value: '',
        isValid: false,
      },
      newPassword: {
        value: '',
        isValid: false,
      },

      confirmPassword: {
        value: '',
        isValid: false,
      },
    },
    false,
  )

  const save = async e => {
    e.preventDefault()
    const data = {
      userId: auth.user.id,
      oldPassword: formState.inputs.oldPassword.value,
      newPassword: formState.inputs.newPassword.value,
    }
    if (
      formState.inputs.newPassword.value !==
      formState.inputs.confirmPassword.value
    ) {
      errorHandler({message: "New passwords doesn't match."})
      return
    }

    setBusy(true)

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/changePassword`,
        'POST',
        JSON.stringify(data),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      if (result.message === 'Updated successfully') {
        closeModal()
        toast.success('Password changed successfully!')
      } else if (result.message === 'wrong_password') {
        errorHandler({message: 'You entered your current password wrong!'})
      }
    } catch (err) {
      if (err?.message === 'wrong_password') {
        errorHandler({message: 'You entered your current password wrong.'})
      } else {
        errorHandler({message: err?.message || "Something went wrong"})
      }
    } finally {
      setBusy(false)
    }
  }

  return (
    <div
      style={{ marginLeft: '50px', marginRight: '50px' }}
      className="create-audience"
    >
      <div className="header">Change Password</div>
      <div className="content-box">
        <form onSubmit={save}>
          <Input
            element="input"
            id="oldPassword"
            type="password"
            label="Current Password"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={inputHandler}
            errorText="Current password cannot be empty."
          />
          <Input
            element="input"
            id="newPassword"
            type="password"
            label="New Password"
            validators={[VALIDATOR_MINLENGTH(6)]}
            errorText="Password must be at least 6 characters long."
            onInput={inputHandler}
          />
          <Input
            element="input"
            id="confirmPassword"
            type="password"
            label="Confirm Password"
            validators={[
              VALIDATOR_PASSWORD_MATCH(
                formState.inputs.newPassword.value,
                formState.inputs.confirmPassword.value,
              ),
            ]}
            errorText="Passwords doesn't match."
            onInput={inputHandler}
          />
          <Button loading={busy} className="action-button" type="submit">
            <FaSave /> Save
          </Button>
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
