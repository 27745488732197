import React, { useEffect, useState } from 'react'
import {
  FaPlug,
  FaQuestion,
  FaUserNinja,
  FaHome,
  FaSignOutAlt,
  FaUsers,
  FaTable,
  FaNetworkWired,
  FaHandshake,
  FaHeadset,
  FaPhone,
  FaExclamation,
  FaInbox,
  FaCompass,
  FaChevronDown,
  FaChevronUp,
  FaCalendar,
  FaUserTag,
  FaChartPie,
  FaChartLine,
  FaHistory,
} from 'react-icons/fa'

import HomeIcon from '../../assets/images/icons/home.png'
import { Link } from 'react-router-dom'
import googleAdsLogo from '../../assets/images/icons/google-ads-logo.png'
import CheckupIcon from '../../assets/images/ai-ads/checkup.png'

import './BarButton.css'
import NotificationBadge from './NotificationBadge'

const BarButton = props => {
  const [innerElem, setInnerElem] = useState()
  useEffect(() => {
    switch (props.type) {
      case 'admin-panel/support-tickets':
        setInnerElem(
          <React.Fragment>
            <FaInbox className="icon" />
            <div className="button-title">Support Tickets</div>
          </React.Fragment>,
        )
        break
      case 'admin-panel/table':
        setInnerElem(
          <React.Fragment>
            <FaTable className="icon" />
            <div className="button-title">Table View</div>
          </React.Fragment>,
        )
        break
      case 'admin-panel/antable':
        setInnerElem(
          <React.Fragment>
            <FaNetworkWired className="icon" />
            <div className="button-title">AN Stats</div>
          </React.Fragment>,
        )
        break
      case 'admin-panel/leads':
        setInnerElem(
          <React.Fragment>
            <FaUsers className="icon" />
            <div className="button-title">Leads</div>
          </React.Fragment>,
        )
        break
      case 'admin-panel/customers':
        setInnerElem(
          <React.Fragment>
            <FaHandshake className="icon" />
            <div className="button-title">Customers</div>
          </React.Fragment>,
        )
        break
      case 'dev-admin-panel':
      case 'admin-panel':
        setInnerElem(
          <React.Fragment>
            <FaUserNinja className="icon" />
            <div className="button-title">All Users</div>
          </React.Fragment>,
        )
        break
      case 'support':
        setInnerElem(
          <React.Fragment>
            <FaPhone className="icon" />
            {props.elem}
            <div className="button-title">Get Support</div>
            {props.chevron}
          </React.Fragment>,
        )
        break
      case 'connect':
      case 'config':
        setInnerElem(
          <React.Fragment>
            <FaPlug className="icon" />

            <div className="button-title">Connections</div>
          </React.Fragment>,
        )
        break
      case 'home':
      case 'start':
      case 'ai-ads-button':
      case 'ai-ads':
        setInnerElem(
          <React.Fragment>
            <FaHome className="icon" />
            <div className="button-title">{props.title}</div>
          </React.Fragment>,
        )
        break
      case 'help':
        setInnerElem(
          <React.Fragment>
            <FaQuestion className="icon" />
            <div className="button-title">Help</div>
          </React.Fragment>,
        )
        break
      case 'logout':
        setInnerElem(
          <React.Fragment>
            <FaSignOutAlt className="icon" />
            <div className="button-title">Log out</div>
          </React.Fragment>,
        )
        break
      case 'profile-button':
        setInnerElem(
          <React.Fragment>
            {props.elem}
            <div className="button-title">Profile</div>
            {props.chevron}
          </React.Fragment>,
        )
        break
      case 'verifyEvents':
        setInnerElem(
          <React.Fragment>
            <FaExclamation className="icon" />
            <div className="button-title">Verify Events</div>
          </React.Fragment>,
        )
        break
      // case 'startTour':
      //   setInnerElem(
      //     <React.Fragment>
      //       <FaCompass className="icon" />
      //       <div className="button-title">Start Tour</div>
      //     </React.Fragment>
      //   )
      //   break
      case 'googleAds':
        setInnerElem(
          <React.Fragment>
            <img src={googleAdsLogo} className="img-icon" />
            <div className="button-title">Google Ads</div>
          </React.Fragment>,
        )
        break

      case 'adHealthCheckup':
        setInnerElem(
          <React.Fragment>
            <img src={CheckupIcon} className="img-icon" />
            <div className="button-title">Ad Health Checkup</div>
          </React.Fragment>,
        )
        break

      case 'bookADemoButton':
        setInnerElem(
          <React.Fragment>
            <FaCalendar className="icon" />
            <div className="button-title">Book a Demo</div>
          </React.Fragment>,
        )
        break

      case 'admin-panel/team':
        setInnerElem(
          <React.Fragment>
            <FaUserTag className="icon" />
            <div className="button-title">Team</div>
          </React.Fragment>,
        )
        break;

      case 'admin-panel/activity-logs':
        setInnerElem(
          <React.Fragment>
            <FaHistory className="icon" />
            <div className="button-title">Activity Logs</div>
          </React.Fragment>,
        )
        break;

      case 'crm-report':
        setInnerElem(
          <React.Fragment>
            <FaChartLine className="icon" />
            <div className="button-title">CRM Report</div>
          </React.Fragment>,
        )
        break;
      case 'team-report':
        setInnerElem(
          <React.Fragment>
            <FaChartPie className="icon" />
            <div className="button-title">Team Report</div>
          </React.Fragment>,
        )
        break;
      default:
    }
  }, [props.type, props.chevron])

  const checkIfActive = () => {
    if (
      (window.location.pathname === '/admin-panel' ||
        window.location.pathname === '/admin-panel/sign-ups' ||
        window.location.pathname === '/admin-panel/demo-meetings' ||
        window.location.pathname === '/admin-panel/alarms') &&
      props.type === 'admin-panel'
    ) {
      return true
    }

    if (
      window.location.pathname.includes('/account') &&
      props.type === 'profile-button'
    ) {
      return true
    }

    if (
      props.type !== 'admin-panel' &&
      window.location.pathname.includes(props.type)
    ) {
      return true
    }

    if (
      props.type !== 'admin-panel' &&
      window.location.pathname.includes(props.type)
    ) {
      return true
    }

    if (
      props.type === 'ai-ads-button' &&
      window.location.pathname.includes('ai-ads')
    ) {
      return true
    }

    return false
  }

  return props.to ? (
    props.type === 'help' ? (
      <a
        href={props.to}
        className={`bar-button ${props.expandable ? 'expandable' : ''} `}
        target="_blank"
        title={props.title}
        rel="noreferrer"
      >
        {innerElem}
      </a>
    ) : (
      <Link
        to={props.to}
        className={`bar-button ${props.expandable ? 'expandable' : ''} ${props.type === "ai-ads" ? "" : props.type} ${props.type === 'config' ? 'meta-connection' : ''} ${props.type === 'verifyEvents' ? 'warning' : ''} ${checkIfActive() ? 'active' : ''} `}
        onClick={props.onClick}
        title={props.title}
      >
        {props.badge && <NotificationBadge>{props.badge}</NotificationBadge>}
        {innerElem}
      </Link>
    )
  ) : (
    <div
      title={props.title}
      onClick={props.onClick}
      className={`bar-button ${props.expandable ? 'expandable' : ''} ${props.type} ${props.type === 'config' ? 'meta-connection' : ''} ${props.type === 'verifyEvents' ? 'warning' : ''} ${checkIfActive() ? 'active' : ''} `}
    >
      {props.badge && <NotificationBadge>{props.badge}</NotificationBadge>}
      {innerElem}
    </div>
  )
}

export default BarButton
