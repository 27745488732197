import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { projectActions } from '../../store/project'
import { useHttpClient } from '../../shared/hooks/http-hook'
import helper from '../../shared/util/helper'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'
import AIAdsPageLayout from '../components/AIAdsPageLayout'
import SummaryOfAudiences from '../components/aiAudience/SummaryOfAudiences'
import EnhencerRemarketingAudience from '../components/aiAudience/EnhencerRemarketingAudience'
import AudienceProgress from '../components/aiAudience/AudienceProgress'

import './AIAudience.css'
import errorHandler from '../../shared/util/errorHandler'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'

const AIAudience = () => {
  const dispatch = useDispatch()
  const { sendRequest, isLoading } = useHttpClient()

  const campaign = useSelector(state => state.project.currentCampaign)
  const campaignId = useSelector(state => state.project.currentCampaignId)
  const campaigns = useSelector(state => state.project.campaigns)
  const [fetchedCampaigns, setFetchedCampaigns] = useState(false)

  const authToken = useSelector(state => state.auth.token)
  const userId = useSelector(state => state.auth.user.id)
  const userIsDemo = useSelector(state => state.auth.user.isDemo)

  console.log('userID', userId)


  const [stats, setStats] = useState({
    topVisitorsPercentage: 0,
    uplift: 0,
    numberOfSelectedSegments: 0,
  })

  useEffect(() => {
    const getCampaign = async () => {
      const data = JSON.stringify({
        campaignId: campaignId || campaigns[0]?._id,
        userId: userId,
      })
      const url = `${process.env.REACT_APP_BACKEND_URL}/projects/getCampaignDetails/`

      try {
        const responseData = await sendRequest(url, 'POST', data, {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        })

        dispatch(
          projectActions.setCurrentCampaign({
            campaign: {
              ...responseData.campaign,
              isDemo: userIsDemo,
            },
            isDemo: userIsDemo,
          }),
        )
        setFetchedCampaigns(true)
      } catch (err) {
        errorHandler(err)
      }
    }

    if (!fetchedCampaigns && campaignId) {
      getCampaign()
    }
  }, [campaigns])

  useEffect(() => {
    if (!campaign || !campaign.audience.segments) {
      return
    }

    let overallSize = 0
    let selectedSize = 0
    campaign.audience.segments.forEach(s => {
      if (s.isSelected) {
        selectedSize += s.count
      }
      overallSize += s.count
    })

    const selectedSegments = campaign.audience.segments
      ? helper.findAll(campaign.audience.segments, 'isSelected', true)
      : []
    const percentage = campaign.audience.expected
      ? ((selectedSize / overallSize) * 100).toFixed(1)
      : ''
    const lift = campaign.audience.expected
      ? (
        campaign.audience.expected.propensity /
        campaign.audience.overall.propensity
      ).toFixed(1)
      : ''

    setStats({
      topVisitorsPercentage: percentage,
      uplift: lift,
      numberOfSelectedSegments: selectedSegments.length,
    })
  }, [campaign])

  return (
    <AIAdsPageLayout>
      <AIAdsPageLayout.Content>
        <Breadcrumb>
          <Link to="/ai-ads">Meta Ads</Link>
          <Link to="#">AI Audience</Link>
        </Breadcrumb>
        <div className="ai-ads-ai-audience-grid">
          {isLoading ? <InlineLoadingSpinner /> :

            campaign && campaign.audience.segments ? (
              <React.Fragment>
                <SummaryOfAudiences />
                <EnhencerRemarketingAudience stats={stats} />
              </React.Fragment>
            ) : (
              <div>No AI model found</div>
            )
          }
        </div>
      </AIAdsPageLayout.Content>
      {/* <AIAdsPageLayout.Sidebar>
        <AudienceProgress stats={stats} />
      </AIAdsPageLayout.Sidebar> */}
    </AIAdsPageLayout>
  )
}

export default AIAudience