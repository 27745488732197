import React, { useState, useEffect } from 'react';
import './AIAgent.css';
import useAIAgent from '../../shared/hooks/ai-agent-hook';
import aiAdsLogo from '../../shared/assets/images/ai-ads/ai-ads-logo.png'
import cookies from '../../shared/util/cookies';
import { FaSyncAlt } from 'react-icons/fa';
import TypewriterText from '../../shared/components/UIElements/TypewriterText';
import { useSelector } from 'react-redux';

function AIAgent({ isCampaignsPage = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const { generateOrGetPreviousGeneralSummary, askFollowupQuestionToSummary, generateCampaignsSummary, askFollowupQuestionToCampaignsSummary } = useAIAgent()
  const [summary, setSummary] = useState(null)
  const [questions, setQuestions] = useState([])
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [answer, setAnswer] = useState(null)
  const [createdAt, setCreatedAt] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const userId = useSelector(state => state.auth.user.id)

  const generateSummary = async (forceFetch = false) => {
    try {
      const result = isCampaignsPage 
        ? await generateCampaignsSummary(forceFetch) 
        : await generateOrGetPreviousGeneralSummary(forceFetch);
      
      if (!result) {
        console.log('No summary result received');
        return;
      }

      const { summaryAnswer, createdAt } = result;
      
      if (summaryAnswer) {
        setSummary(summaryAnswer);
        setCreatedAt(createdAt);
        try {
          const questionsMatch = summaryAnswer.match(/\{[\s\S]*\}/);
          if (questionsMatch) {
            const questionsObj = JSON.parse(questionsMatch[0]);
            const extractedQuestions = Object.values(questionsObj).filter(q => q);
            setQuestions(extractedQuestions);
          }
        } catch (error) {
          console.log('No questions found in summary');
        }
      }
    } catch (error) {
      console.error('Error generating summary:', error);
    }
  }

  useEffect(() => {
    if (!summary) {
      generateSummary()
    }
  }, [])

  const convertMarkdownToHtml = (markdown) => {
    // Remove the JSON questions block from the displayed text
    const cleanMarkdown = markdown.replace(/\{[\s\S]*\}/, '').replace(/```/g, '').replace("json", '');

    // Handle headers
    let html = cleanMarkdown.replace(/^### (.*$)/gm, '<h3>$1</h3>')
      .replace(/^## (.*$)/gm, '<h2>$1</h2>')
      .replace(/^# (.*$)/gm, '<h1>$1</h1>')
      .replace(/^#### (.*$)/gm, '<h4>$1</h4>')

    // Handle bold and italic
    html = html.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/\*(.*?)\*/g, '<em>$1</em>')

    // Handle ordered lists - Fixed regex
    html = html.replace(/^(\d+)\.\s(.*)$/gm, '<li>$2</li>')
    html = html.replace(/(<li>.*<\/li>)/gs, '<ol>$1</ol>')

    // Handle unordered lists
    html = html.replace(/^\s*\n\*/gm, '<ul>\n*')
      .replace(/^(\*.+)\s*\n([^\*])/gm, '$1\n</ul>\n$2')
      .replace(/^\*(.*)/gm, '<li>$1</li>')

    // Handle paragraphs
    html = html.replace(/^\s*(\n)?(.+)/gm, function (m) {
      return /\<(\/)?(h\d|ul|ol|li|blockquote|pre|img)/.test(m) ? m : '<div>' + m + '</div>'
    })

    // Remove empty paragraphs
    html = html.replace(/<p><\/p>/g, '')

    // Clean up multiple line breaks
    html = html.replace(/\n\n/g, '\n')
    html = html.replace(/\n$/gm, '')

    // Fix any nested lists
    html = html.replace(/<\/ol><ol>/g, '')
    html = html.replace(/<\/ul><ul>/g, '')

    return html
  }

  const handleQuestionClick = async (question) => {
    setSelectedQuestion(question)
    setIsLoading(true)
    try {
      const response = isCampaignsPage ? await askFollowupQuestionToCampaignsSummary(question) : await askFollowupQuestionToSummary(question)
      setAnswer(response)
    } catch (error) {
      console.error('Error getting answer:', error)
    }
    setIsLoading(false)
  }

  const handleBack = () => {
    setSelectedQuestion(null)
    setAnswer(null)
  }


  const handleRefresh = async () => {
    cookies.remove(`twr_${userId}_${isCampaignsPage ? "cmp-sum" : "sum"}`);
    setSummary(null)
    setCreatedAt(null)
    setQuestions([])
    setSelectedQuestion(null)
    setAnswer(null)
    setIsOpen(false)
    setIsLoading(true)
    await generateSummary(true)
    setIsLoading(false)
  }


  return (
    <div className="enhencer-ai-agent-wrapper" style={isCampaignsPage ? { fontSize: '1.08em' } : { fontSize: '.88em' }} >
      <div className="ai-ads-breadcrumb-actions">
        <div className="ai-ads-breadcrumb-actions-item">
          {!isOpen && (summary ? (
            <div
              className="enhencer-ai-agent-button"
              onClick={() => setIsOpen(true)}
            >
              <div className="enhencer-ai-agent-button-content">
                <div className="enhencer-ai-agent-button-title">
                  Enhencer AI Agent
                </div>
                <div className="enhencer-ai-agent-button-info">
                  {isCampaignsPage ? "Campaigns" : "Overall"} insights are ready ✨
                </div>
              </div>
            </div>
          ) : (
            <div
              className="loading-bubble enhencer-ai-agent-button"
              style={{ cursor: 'default' }}
            >
              <div className="enhencer-ai-agent-button-content">
                <div className="enhencer-ai-agent-button-title">
                  Enhencer AI Agent
                </div>
                <div className="enhencer-ai-agent-button-info">
                  <span className="loading-dots">Generating insights...</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`enhencer-ai-agent-overlay ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(false)}
      />
      <div className={`enhencer-ai-agent-sidebar ${isOpen ? 'open' : ''}`}>
        <div className="enhencer-ai-agent-sidebar-inside">
          <div className="enhencer-ai-agent-inner-wrapper">
            <div className="enhencer-ai-agent-header">
              {selectedQuestion ? (
                <div className="back-button" onClick={handleBack}>
                  ← Back
                </div>
              ) : (
                <div
                  onClick={() => setIsOpen(false)}
                  className="enhencer-ai-agent-close"
                />
              )}
              <div className="enhencer-ai-agent-button-title">
                Enhencer AI Agent
              </div>
            </div>
            <div className="enhencer-ai-agent-content">
              {selectedQuestion ? (
                <div className="enhencer-ai-agent-conversation">
                  <div className="question-bubble">
                    {selectedQuestion}
                  </div>
                  <div className="row">
                    <img className="enhencer-ai-agent-avatar" src={aiAdsLogo} alt="AI Agent Avatar" />
                    {isLoading ? (
                      <div className="loading-bubble in-chat">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                    ) : answer && (
                      <div className="answer-bubble">
                        <TypewriterText
                          html={convertMarkdownToHtml(answer)}
                          questions={[]}
                          speed={3}
                          onQuestionClick={handleQuestionClick}
                          alwaysAnimate={true}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : summary ? (
                <div className="enhencer-ai-agent-summary-container">
                  <div className="enhencer-ai-agent-conversation">
                    <div className="row">
                      <img className="enhencer-ai-agent-avatar" src={aiAdsLogo} alt="AI Agent Avatar" />
                      <div className="enhencer-ai-agent-summary">
                        <div className="answer-bubble">
                          <TypewriterText
                            html={convertMarkdownToHtml(summary)}
                            questions={questions}
                            speed={3}
                            onQuestionClick={handleQuestionClick}
                            contentKey={isCampaignsPage ? "cmp-sum" : "sum"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="enhencer-ai-agent-summary-footer row">
                    <div className="enhencer-ai-agent-summary-footer-text">
                      Last updated {createdAt ? new Date(createdAt).toLocaleDateString() : ''} at {createdAt ? new Date(createdAt).toLocaleTimeString() : ''}
                    </div>
                    <div
                      onClick={() => {
                        handleRefresh()
                      }}
                      className="icon-button enhencer-ai-agent-summary-footer-button"
                    >
                      <FaSyncAlt />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AIAgent;
