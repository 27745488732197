import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { InlineWidget } from "react-calendly"
import IntroHeadlineContainer from '../components/intro/IntroHeadlineContainer'
import './YourTeam.css'
import BackgroundImage from '../../shared/assets/images/register-decor/your-team-bg.png'
import ozlem from '../../shared/assets/images/team/ozlem.png'
import gorkem from '../../shared/assets/images/team/gorkem.png'
import Button from '../../shared/components/FormElements/Button'
import { authActions } from '../../store/auth'

const headlines = [
  {
    text: 'Your Assigned ',
    highlight: 'AI Ads Team',
  },
]

const YourTeam = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const name = useSelector(state => state.auth.user.name) || ''
  const email = useSelector(state => state.auth.user.email) || ''

  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    sessionStorage.setItem('in_sign_up_flow', 'false')
    
    dispatch(
      authActions.updateUserObjOneField({
        field: 'isInSignupFlow',
        value: true,
      }),
    )

    const buttonTimer = setTimeout(() => {
      setShowButton(true)
    }, 5000)

    return () => {
      clearTimeout(buttonTimer)
    }
  }, [dispatch])

  useEffect(() => {
    handleAppClass(true)

    window.addEventListener('message', event => {
      if (event.data.event === 'calendly.event_scheduled') {
        setTimeout(() => {
          navigate('/pricing')
        }, 2000)
      }
    })

    return () => {
      handleAppClass(false)
    }
  }, [navigate])

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-scroll')
      : document.getElementById('app')?.classList.remove('full-screen-scroll')
  }

  return (
    <div className="your-team">
      <div className="your-team-page">
        <div className="your-team-container">
          <IntroHeadlineContainer
            headlines={headlines}
            animationEnabled={false}
          />
          <div className="your-team-description">
            Get a discovery meeting with your dedicated ad success team using
            the calendar to the right.
          </div>
          <div className="your-team-highlight">
            We'd love to get to know you better!
          </div>
          <div className="your-team-members">
            <div className="your-team-member">
              <div className="your-team-member-image">
                <img src={ozlem} alt="Özlem" />
              </div>
              <div className="your-team-member-info">
                <div className="your-team-member-name">Ozlem</div>
                <div className="your-team-member-role">Account Manager</div>
              </div>
            </div>
            <div className="your-team-member">
              <div className="your-team-member-image">
                <img src={gorkem} alt="Görkem" />
              </div>
              <div className="your-team-member-info">
                <div className="your-team-member-name">Gorkem</div>
                <div className="your-team-member-role">
                  Digital Marketing Specialist
                </div>
              </div>
            </div>
          </div>
          <div className="cont">
            {showButton && (
              <Button className="action-button" to="/pricing">
                Skip to Subscription
              </Button>
            )}
          </div>
        </div>

        <div className="your-team-calendly">
          <img
            src={BackgroundImage}
            className="background-image"
            alt="Background"
          />
          <InlineWidget
            url={process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_LINK}
            prefill={{
              name,
              email,
            }}
            styles={{
              height: '100%',
              width: '100%'
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default YourTeam
