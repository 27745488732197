import React, { useState } from 'react';
import { FaCloudUploadAlt, FaFileUpload, FaUpload, FaUser } from 'react-icons/fa';

import helper from '../../util/helper';

import './ImageUpload.css';

const ImageUpload = ({ id, onInput, defaultImageURL, maxSizeInMB = 2, checkForAlphaChannel, setHasAlphaChannel, hidePlaceholder = false }) => {
  const [imageURL, setImageURL] = useState(defaultImageURL || '');
  const [imageError, setImageError] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const url = URL.createObjectURL(file);
    const maxSizeInByte = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInByte) {
      setImageError(`Uploaded image size must be smaller than ${maxSizeInMB}MB`);
      onInput(id, null, false);
      return;
    }

    // Check for alpha channel if required
    if (checkForAlphaChannel) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;

        img.onload = async () => {
          const alphaExists = await helper.checkAlpha(img);
          console.log("alpha check done ", alphaExists)
          setHasAlphaChannel(alphaExists);
        };
      };

      reader.readAsDataURL(file);
    }

    setImageError('');
    setImageURL(url);
    onInput(id, file, true);
  };

  return (
    <div className="row">
      <div className={`p-pic-cont ${imageURL ? 'filled' : ''}`}>
        <input
          type="file"
          id="picInput"
          name="image"
          className="file-input"
          accept=".jpg,.jpeg,.png,.webp"
          onChange={handleImageChange}
        />
        {imageURL ? (
          <div>
            <img
              alt="uploaded"
              className="p-pic"
              src={imageURL}
              height="50px"
              width="50px"
            />
          </div>
        ) : (
          !hidePlaceholder && (
            <div>
              <div className="placeholder">
                <FaFileUpload className="icon" />
              </div>
            </div>
          )
        )}
      </div>
      {imageError && <p className="error-text">{imageError}</p>}
    </div>
  );
};

export default ImageUpload;
