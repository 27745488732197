import React, { useEffect } from 'react'
import './YourAdHealthCheckup.css'
import gsap from 'gsap'
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png'
import rectangle1 from '../../shared/assets/images/ad-health-checkup/rectangle-1.png'
import rectangle2 from '../../shared/assets/images/ad-health-checkup/rectangle-2.png'
import rectangle3 from '../../shared/assets/images/ad-health-checkup/rectangle-3.png'
import rectangle4 from '../../shared/assets/images/ad-health-checkup/rectangle-4.png'
import rectangle5 from '../../shared/assets/images/ad-health-checkup/rectangle-5.png'

const YourAdHealthCheckup = () => {
  useEffect(() => {
    document.querySelector('.App').classList.add('ad-health-checkup')

    const tl = gsap.timeline()

    // Text one animation
    tl.from('.opening-text .text.first', {
      x: -200,
      opacity: 0,
      duration: 1,
    })
      // Text two animation
      .from(
        '.opening-text .text.second',
        {
          x: -200,
          opacity: 0,
          duration: 1,
        },
        '-=0.5',
      ) // wait 0.8 seconds before starting the second text animation

      // wait 2 seconds before starting the next animation
      .to({}, 1, {})

    // move rigth and fade out the textsX
    tl.to('.opening-text .text', {
      x: 200,
      opacity: 0,
      duration: 1,
    })

    // tl.from('.report-header .line-1, .report-header, .line-2', {
    //   x: -50,
    //   opacity: 0,
    //   duration: 1,
    // })

    // Fade in logo and big text, fade in rectangle-1
    tl.from('.report-header .logo, .report-header .big-text', {
      x: -50,
      opacity: 0,
      duration: 1,
    }).from(
      '.report-header .rectangle-1',
      {
        opacity: 0,
        duration: 1,
      },
      '-=0.5',
    ) // wait 0.5 seconds before starting rectangle-1 animation

    tl.from('.report-header .small-text', {
      x: -50,
      opacity: 0,
      duration: 1,
    })
      .from(
        '.rectangle-2, .rectangle-4',
        {
          y: 500,
          opacity: 0,
          duration: 1,
        },
        '-=1',
      )
      .from(
        ' .rectangle-3, .rectangle-5',
        {
          y: -50,
          opacity: 0,
          duration: 1,
        },
        '-=1',
      )

    // Kill the timeline when the component unmounts
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <div className="ad-health-checkup-content">
      <div className="report-header">
        <div className="row">
          <div className="left-part">
            <div className="logo">
              <img src={enhencerLogo} />
            </div>
            <div className="big-text">
              Your Ad Health Checkup <span>is here!</span>
            </div>
            <div className="small-text">
              Use the scroll buttons to explore your way through{' '}
              <span className="gradient-text">
                your personalized highlights report.
              </span>{' '}
            </div>
          </div>
          <div className="right-part">
            <div className="line line-1">
              <svg
                width="100vw"
                height="100vh"
                viewBox="0 0 1318 654"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="gradient-line-1"
                  opacity="0.5"
                  d="M21 17.9968L514.533 622.553L1310.5 409.497"
                  stroke="url(#paint0_linear_1550_11606)"
                  strokeWidth="54"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1550_11606"
                    x1="665.75"
                    y1="622.553"
                    x2="-41.3387"
                    y2="424.116"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#46B680" />
                    <stop offset="1" stopColor="#8BC45D" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="line line-2">
              <svg
                width="100vw"
                height="100vh"
                viewBox="0 0 1339 688"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="gradient-line-2"
                  opacity="0.5"
                  d="M37 658.001L530.533 53.4453L1326.5 266.501"
                  stroke="url(#paint0_linear_1550_11605)"
                  strokeWidth="54"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_1550_11605"
                    x1="681.75"
                    y1="53.4453"
                    x2="-25.3387"
                    y2="251.882"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#46B680" />
                    <stop offset="1" stopColor="#8BC45D" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div className="rectangle-1">
              <img src={rectangle1} />
            </div>
            <div className="rectangle-2">
              <img src={rectangle2} />
            </div>
            <div className="rectangle-3">
              <img src={rectangle3} />
            </div>
            <div className="rectangle-4">
              <img src={rectangle4} />
            </div>
            <div className="rectangle-5">
              <img src={rectangle5} />
            </div>
          </div>
        </div>
      </div>
      <div className="opening-text">
        <div className="text  first">
          You have successfully connected your{' '}
          <b>Meta Business account, pixel and product catalog.</b>
        </div>
        <div className="text second">
          We are ready to summarize your{' '}
          <span className="gradient-text">
            <b>Meta Ads highlights and ad health report. </b>
          </span>
        </div>
      </div>
    </div>
  )
}

export default YourAdHealthCheckup
