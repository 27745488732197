import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaCheck, FaUpload } from 'react-icons/fa'
import { toast } from 'react-toastify'

import ProfilePageLayout from '../components/ProfilePageLayout'
import StrokeInput from '../../shared/components/FormElements/StrokeInput'
import Button from '../../shared/components/FormElements/Button'
import ImageUpload from '../../shared/components/FormElements/ImageUpload'
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_DOMAIN,
  VALIDATOR_PHONE,
  VALIDATOR_EMAIL,
} from '../../shared/util/validators'

import { useForm } from '../../shared/hooks/form-hook'
import { generalActions } from '../../store/general'

import './PersonalInfo.css'
import { useUser } from '../../shared/hooks/user-hook'
import errorHandler from '../../shared/util/errorHandler'

const PersonalInfo = () => {
  const pageTitle = 'Edit Profile'

  const auth = useSelector(state => state.auth)
  const user = auth.user
  const dispatch = useDispatch()
  const [saving, setSaving] = useState(false)
  const { saveUserProfileInfo } = useUser()

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: auth.user?.name || '',
        isValid: true,
      },
      companyName: {
        value: auth.user?.companyName || '',
        isValid: true,
      },
      title: {
        value: auth.user?.title || '',
        isValid: true,
      },
      url: {
        value: auth.user?.url || '',
        isValid: true,
      },
      image: {
        value: auth.user?.image || null,
        isValid: true,
      },
      contactNumber: {
        value: auth.user?.contactNumber || '',
        isValid: true,
      },
      contactEmail: {
        value: auth.user?.crmDetails?.contactEmail || '',
        isValid: true,
      },
    },
    true,
  )

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle }))
  }, [])

  const saveProfileHandler = async () => {
    const {
      name,
      companyName,
      title,
      url,
      image,
      contactNumber,
      contactEmail,
    } = formState.inputs


    setSaving(true)
    try {

      await saveUserProfileInfo({
        userId: user.id,
        name: name.value,
        companyName: companyName.value,
        title: title.value,
        url: url.value,
        image: image.value,
        contactNumber: contactNumber.value,
        contactEmail: contactEmail.value,
      })
      toast.success('Changes saved')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  return (
    <ProfilePageLayout>
      <ProfilePageLayout.Header></ProfilePageLayout.Header>
      <ProfilePageLayout.Content>
        <div className="personal-info-content">
          <div className="form col">
            <h4>Representative Details</h4>
            <form>
              <StrokeInput
                id="name"
                element="input"
                type="text"
                label="Full Name"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid name."
                onInput={inputHandler}
                initialValue={formState.inputs.name.value}
                initialValid={true}
              />
              <StrokeInput
                id="title"
                element="input"
                type="text"
                label="Job Title"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid title."
                onInput={inputHandler}
                initialValue={formState.inputs.title.value}
                initialValid={true}
              />
              <StrokeInput
                id="contactNumber"
                element="input"
                type="text"
                label="Phone Number"
                validators={[VALIDATOR_PHONE()]}
                errorText="Please enter a valid phone number."
                onInput={inputHandler}
                initialValue={formState.inputs.contactNumber.value}
                initialValid={true}
              />
              <StrokeInput
                id="contactEmail"
                element="input"
                type="text"
                label="Contact Email"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address."
                onInput={inputHandler}
                initialValue={formState.inputs.contactEmail.value}
                initialValid={true}
              />
            </form>
            {/* This save button is only for mobile view */}
            <Button
              loading={saving}
              onClick={saveProfileHandler}
              className="save-button"
            >
              <FaCheck className="icons" />
              Save image
            </Button>
          </div>
          <div className="col">
            <h4>Company Details</h4>
            <div className="profile-image-area">
              <h5 className="title">Logo</h5>
              <div className="row">
                <label htmlFor="picInput" className="image-upload-area">
                  <ImageUpload
                    id="image"
                    onInput={inputHandler}
                    defaultImageURL={formState.inputs.image.value}
                  />
                </label>
                <div className='upload-new-button-container'>
                  <label htmlFor="picInput">
                    <div className="change-profile-photo">
                      <FaUpload className="icon" />
                      Upload new
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <StrokeInput
              id="companyName"
              element="input"
              type="text"
              label="Company Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a company name."
              onInput={inputHandler}
              initialValue={formState.inputs.companyName.value}
              initialValid={true}
            />
            <StrokeInput
              id="url"
              element="input"
              type="text"
              label="Company Domain"
              validators={[VALIDATOR_DOMAIN()]}
              errorText="Please enter a valid company domain."
              onInput={inputHandler}
              initialValue={formState.inputs.url.value}
              initialValid={true}
            />
          </div>
        </div>
        <Button
          loading={saving}
          onClick={saveProfileHandler}
          className="save-button"
        >
          <FaCheck className="icons" />
          Save Changes
        </Button>
      </ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

export default PersonalInfo
