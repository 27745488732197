import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import useNavigator from '../../../shared/hooks/navigator-hook'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'

import SingleSavedOverlay from './SingleSavedOverlay'
import AllSavedOverlay from './AllSavedOverlay'
import BatchSavingOverlay from './BatchSavingOverlay'
import LoadingSpinner from '../../../shared/components/UIElements/LoadingSpinner'
import ProductList from './ProductList'
import TemplatesGrid from './TemplatesGrid'
import PreviewToolbar from '../tool/PreviewToolbar'
import KonvaArea from '../layout/KonvaArea'
import PreviewActionButtons from '../tool/PreviewActionButtons'
import { useUnsavedChangesWarning } from '../../../shared/hooks/unsaved-changes-warning-hook'
import {
  NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES,
  NUMBER_OF_COMMON_TEMPLATES,
} from '../../support/constants'

import styles from './CarouselWorkbench.module.css'

const CarouselWorkbench = ({ isShowingTemplates, setIsShowingTemplates, onElementSelected }) => {
  const { navigateWithParams } = useNavigator()
  const {
    loadCachedSelectedProducts,
    backgroundRemoving,
    selectedProductsWithConfigurations,
    productOnDraft,
    applyConfigurationToProduct,
    setInitialConfiguration,
    isSingleSaving,
  } = useEnhencedCatalog()
  const dispatch = useDispatch()
  const { searchParams } = useNavigator()
  const isInitialTemplateSelection = useRef(true)
  const [productOnDraftImageLoaded, setProductOnDraftImageLoaded] =
    useState(false)
  const [isProductOnDraftSaved, setIsProductOnDraftSaved] = useState(false)
  const [showBatchSavingOverlay, setShowBatchSavingOverlay] = useState(false)
  const [showAllSavedOverlay, setShowAllSavedOverlay] = useState(false)
  const [allSaved, setAllSaved] = useState(false)
  const stageRef = useRef(null)

  const createTemplateObject = (num, templatePath) => ({
    id: `${num}-${templatePath}`,
    config: require(`../../templates/geometric/${templatePath}/Template${num}`)[
      `Template${num}Config`
    ],
    get hasLogo() {
      return Boolean(this.config.initialPositions?.companyLogo)
    },
    get forceDiscount() {
      return Boolean(this.config.forceDiscount)
    },
    get primaryText() {
      const node =
        this.config.initialPositions?.primaryText ||
        this.config.initialPositions?.productName
      return {
        fontFamily: node?.fontFamily,
      }
    },
    get secondaryText() {
      const node =
        this.config.initialPositions?.secondaryText ||
        this.config.initialPositions?.productPrice
      return {
        fontFamily: node?.fontFamily,
      }
    },
  })

  const importTemplates = (length, templatePath) =>
    Array.from({ length }, (_, i) => createTemplateObject(i + 1, templatePath))

  const commonTemplates = importTemplates(NUMBER_OF_COMMON_TEMPLATES, 'common')

  const carouselAndSingleTemplatesOnly = importTemplates(
    NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES,
    'carousel-single',
  )

  const templates = [...carouselAndSingleTemplatesOnly, ...commonTemplates]

  const handleSave = () => {
    applyConfigurationToProduct(productOnDraft.id, productOnDraft.history)
    setIsProductOnDraftSaved(true)
  }

  const handleTryOnAllProducts = () => {
    setShowBatchSavingOverlay(true)
  }

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('ad-health-checkup')
      : document.getElementById('app')?.classList.remove('ad-health-checkup')
  }

  const renderOverlay = () => {
    if (
      isProductOnDraftSaved &&
      productOnDraft?.isSaved &&
      !isSingleSaving &&
      !showAllSavedOverlay &&
      !allSaved
    ) {
      return <SingleSavedOverlay />
    }

    if (showAllSavedOverlay) {
      return <AllSavedOverlay setShowAllSavedOverlay={setShowAllSavedOverlay} />
    }

    if (showBatchSavingOverlay) {
      return (
        <BatchSavingOverlay
          setShowBatchSavingOverlay={setShowBatchSavingOverlay}
          allSaved={allSaved}
          templates={templates}
        />
      )
    }

    return null
  }

  // Check if all products are saved
  useEffect(() => {
    const areAllSaved = selectedProductsWithConfigurations.every(
      product => product.isSaved,
    )
    setAllSaved(areAllSaved)

    // Show AllSavedOverlay only when transitioning to all saved state
    // and either SingleSavedOverlay or BatchSavingOverlay was showing
    if (areAllSaved && (isProductOnDraftSaved || showBatchSavingOverlay)) {
      setShowAllSavedOverlay(true)
      setShowBatchSavingOverlay(false)
      setIsProductOnDraftSaved(false)
    }
  }, [
    selectedProductsWithConfigurations,
    isProductOnDraftSaved,
    showBatchSavingOverlay,
  ])

  // If there are any unsaved products, show a warning before leaving the page
  useUnsavedChangesWarning(
    selectedProductsWithConfigurations.some(p => !p.isSaved),
  )

  // Check if there are selected products in session storage and set them
  useEffect(() => {
    loadCachedSelectedProducts()
  }, [])

  useEffect(() => {
    handleAppClass(true)

    return () => {
      handleAppClass(false)
    }
  }, [])

  useEffect(() => {
    if (productOnDraft || backgroundRemoving) {
      return
    }

    dispatch(
      aiCreativeEditorActions.setProductOnDraft(
        selectedProductsWithConfigurations[0],
      ),
    )
  }, [productOnDraft, backgroundRemoving])

  useEffect(() => {
    const product = selectedProductsWithConfigurations.find(
      p => p.id === productOnDraft?.id,
    )
    if (product?.transparentImageUrl) {
      setProductOnDraftImageLoaded(true)
    }

    isInitialTemplateSelection.current = !productOnDraft?.isSaved
  }, [productOnDraft, selectedProductsWithConfigurations])

  useEffect(() => {
    return () => {
      dispatch(aiCreativeEditorActions.setProductOnDraft(null))

      // If the user is editing an existing AICreative,
      // we need to remove the currentAiCreativeId and aiCreativeProducts from session storage
      // to avoid showing the editing AICreative as "Current AI Creative" in the Home page
      if (searchParams.aiCreativeId) {
        sessionStorage.removeItem('currentAiCreativeId')
        sessionStorage.removeItem('aiCreativeProducts')
      }
    }
  }, [])

  useEffect(() => {
    if (!selectedProductsWithConfigurations.length) {
      navigateWithParams('/ai-ads/creative-lab/products-selection', {
        from: 'editor',
      })
    }
  }, [selectedProductsWithConfigurations])

  useEffect(() => {
    setInitialConfiguration({ type: 'carousel' })
  }, [])

  if (!selectedProductsWithConfigurations.length) {
    return null
  }

  return (
    <React.Fragment>
      {backgroundRemoving || !productOnDraftImageLoaded ? (
        <div className={styles.backgroundRemoving}>
          <LoadingSpinner message="Preparing your product images, please wait..." />
        </div>
      ) : (
        <>
          <div className={styles.productList}>
            <ProductList setIsShowingTemplates={setIsShowingTemplates} />
          </div>
          {isShowingTemplates ? (
            <TemplatesGrid
              type="carousel"
              templates={templates}
              initial={isInitialTemplateSelection.current}
              onTemplateClick={() => {
                isInitialTemplateSelection.current = false
                setIsShowingTemplates(false)
              }}
            />
          ) : (
            <div className={styles.konvaArea} id="editorFlexibleSpace">
              {renderOverlay()}

              <PreviewToolbar />
              <KonvaArea 
                templates={templates} 
                type="carousel"
                onStageReady={stage => (stageRef.current = stage)}
                onElementSelected={onElementSelected}
              />
              <PreviewActionButtons
                onSave={handleSave}
                onTryOnAllProducts={handleTryOnAllProducts}
              />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default CarouselWorkbench
