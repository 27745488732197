import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useHttpClient } from '../../hooks/http-hook'
import { useModal } from '../../hooks/modal-hook'

import './AdsetAudiencesConfig.css'
import Button from '../FormElements/Button'

const AdsetAudiencesConfig = props => {
  const { sendRequest, isLoading } = useHttpClient()
  const selectedAdAccountId = useSelector(state => state.facebook.selectedAdAccount.id)
  const adAccountAudiences = useSelector(state => state.facebook.audiences[selectedAdAccountId])
  const fullFunnelId = useSelector(state => state.facebook.fullFunnelInViewId)
  const authToken = useSelector(state => state.auth.token)
  const adminToken = useSelector(state => state.auth.adminToken)
  const userId = useSelector(state => state.auth.user.id)

  const { closeModal, modalData } = useModal()

  const {
    adAccountId,
    campaignId,
    adsetName,
    adsetId,
    adsetAudiences: initialAdsetAudiences,
    excludedAudiences: initialExcludedAudiences
  } = props.data

  const [adsetAudiences, setAdsetAudiences] = useState(initialAdsetAudiences)
  const [excludedAudiences, setExcludedAudiences] = useState(initialExcludedAudiences)

  const allAudiencesList = useMemo(() => {
    return Object.values(adAccountAudiences).flat().map(a => {
      return {
        ...a,
        inUse: adsetAudiences.map(aa => aa.id).includes(a.id),
        isExcluded: excludedAudiences.map(aa => aa.id).includes(a.id)
      }
    })
  }, [adAccountAudiences, adsetAudiences, excludedAudiences])


  const handleSave = async () => {
    console.log("handleSave ", adsetAudiences, excludedAudiences)
    
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateAdsetAudiences`,
        'POST',
        JSON.stringify({
          userId,
          fullFunnelId,
          campaignId,
          adsetId,
          audiences: adsetAudiences,
          excludedAudiences,
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${adminToken}`
        }
      )
  
      if (result) {
      
        modalData.resolve({
          audiences: adsetAudiences,
          excludedAudiences,
        })
      }
    } catch (err) {
      toast.warn("Error in updating audiences")
    } finally {
      closeModal()
    }
  }

  const toggleInUse = ({ id, name, inUse }) => {
    console.log("toggleInUse ", id, name, inUse)
    if (inUse) {
      setAdsetAudiences([...adsetAudiences, { id, name }])
    } else {
      setAdsetAudiences(adsetAudiences.filter(a => a.id !== id))
    }
  }

  const toggleExclude = ({ id, name, isExcluded }) => {
    if (isExcluded) {
      setExcludedAudiences([...excludedAudiences, { id, name }])
    } else {
      setExcludedAudiences(excludedAudiences.filter(a => a.id !== id))
    }
  }

  return (
    <div className="adset-audiences-config">
      <h3>
        Targeting of <b>{adsetName}</b> ad set
      </h3>

      <table className='audience-table'>
        <thead>
          <tr>
            <th>Audience</th>
            <th>Use</th>
            <th>Exclude</th>
          </tr>
        </thead>
        <tbody>
          {allAudiencesList.map(a =>
            <tr key={a.id}>
              <td>{a.name}</td>
              <td className='audience-checkbox-container'>
                <input
                className='audience-checkbox'
                  type="checkbox"
                  checked={a.inUse}
                  onChange={(e) => { toggleInUse({ id: a.id, name: a.name, inUse: e.target.checked }) }}
                />
              </td>
              <td className='audience-checkbox-container'>
                <input
                disabled={a.name.includes('Lookalike (')}
                className='audience-checkbox'
                  type="checkbox"
                  checked={a.isExcluded}
                  onChange={(e) => { toggleExclude({ id: a.id, name: a.name, isExcluded: e.target.checked }) }}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="modal-footer">
        <Button onClick={handleSave} loadingText="Saving"
          loading={isLoading} className="action-button update">
          Save
        </Button>
      </div>
    </div>
  )
}

export default AdsetAudiencesConfig
