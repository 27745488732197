import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  FaShopify,
  FaCopy,
  FaPenSquare,
  FaPlug,
  FaRocket,
  FaMinus,
  FaCheckCircle,
  FaTimesCircle,
  FaCalendarCheck
} from 'react-icons/fa'

import CustomerCardNavigationMenu from '../customers/CustomerCardNavigationMenu'
import CustomerCardReportSummaryPart from '../customers/CustomerCardReportSummaryPart'
import { adminActions } from '../../../store/admin'
import { useAdmin } from '../../../shared/hooks/admin-hook'

import CustomerCardAlarmPart from '../customers/CustomerCardAlarmPart'

import SegmentationScoreIcon from '../cards-components/SegmentationScoreIcon'
import Tooltip from '../../../shared/components/UIElements/Tooltip'

import './AgenciesUserCard.css'
import CustomerCardReportSummaryPartAlternative from '../customers/CustomerCardReportSummaryPartAlternative'
import QuickDropdown from '../cards-components/QuickDropdown'
import helper from '../../../shared/util/helper'
const AgenciesUserCard = props => {
  const {
    user,
    dayDifference,
    connectionsCompletedInDays,
    fullFunnelCampaignStartedInDays,
    weeklyReports,
    currency,
    shownInOutsideOfList
  } = props

  const userId = user.id


  const isConnectedToFacebook = user?.facebookAds?.isAccessTokenValid

  const { loginToUser } = useAdmin()

  const fullPhoneNumber = user.crmDetails?.contactNumber ? ((user.crmDetails?.contactNumberCountryCode || "") + user.crmDetails?.contactNumber) : ""
  const rippleAnimateRef = useRef()
  const cardRef = useRef()
  const country = user.crmDetails?.country === "United States" ? "USA" : user.crmDetails?.country === "United Kingdom" ? "UK" : user.crmDetails?.country


  const goToReportPage = e => {
    e.stopPropagation()
    loginToUser({ user: user })
  }

  const username = useMemo(() => {
    let domain = user.shopify
      ? user.shopify.name || user.shopify.domain
      : user.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : user.crmDetails?.contactName
  }, [user])


  const copyPhoneNumber = (e) => {
    e.stopPropagation()
    if (fullPhoneNumber) {
      navigator.clipboard.writeText(fullPhoneNumber)
      toast.info('Phone number copied')
    }
  }

  const writeEmail = (e) => {
    e.stopPropagation()
  }


  return (
    <div data-id={userId} className="admin-panel-agency-user-card" ref={cardRef}>
      <div className="card-header">
        <div className="company">
          {user.shopify && <FaShopify className="icon" />}
          <span className="username-bar" title={user.url}>{username}</span>
        </div>
        <div className="row">
          {!isNaN(dayDifference) ? (
            <div className="days">
              <div className="day">
                <FaCalendarCheck className="icon" /> Day {dayDifference}
              </div>
            </div>
          ) : null}
          <div className="more">
            <CustomerCardNavigationMenu user={user} username={username} openInNewTab={shownInOutsideOfList} isAgencyPage={true} />
          </div>
        </div>
      </div>
      <div className="card-content" ref={rippleAnimateRef}>
        <div className="section status">
          <div className="account-status">
            {user.subscription?.status || "No Subscription"}
          </div>
        </div>
        <div className="section process">
          <div className="overview">

            <div className="item">
              <div className="connection-status">
                {isConnectedToFacebook ? (
                  <Tooltip content="Facebook connection is active">
                    <div className="status-badge success">
                      <FaCheckCircle className="status-icon" />
                      <span className="status-text">Connection</span>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip content="Facebook connection is not active">
                    <div className="status-badge error">
                      <FaTimesCircle className="status-icon" />
                      <span className="status-text">Connection</span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="right-side-column">
              <div className="item">
                <Tooltip content={(!isNaN(connectionsCompletedInDays) ? (
                  `User completed Facebook connections ${connectionsCompletedInDays < 0
                    ? 0
                    : connectionsCompletedInDays
                  } days after they subscribed`
                ) : "They haven't completed Facebook connections yet")}>
                  <div className="day-indicator">
                    <FaPlug className="status-icon" />
                    <span className="day-text">
                      {!isNaN(connectionsCompletedInDays) ? (
                        `Day ${connectionsCompletedInDays < 0
                          ? 0
                          : connectionsCompletedInDays
                        }`
                      ) : (
                        <FaMinus className="no-data-icon" />
                      )}
                    </span>
                  </div>
                </Tooltip>
              </div>

              <div className="item">
                <Tooltip content=
                  {(!isNaN(fullFunnelCampaignStartedInDays) ? (
                    `User started Full Funnel Campaign ${fullFunnelCampaignStartedInDays < 0
                      ? 0
                      : fullFunnelCampaignStartedInDays
                    } days after they subscribed`
                  ) : "They haven't started Full Funnel Campaign yet")}>
                  <div className="day-indicator">
                    <FaRocket className="status-icon" />
                    <span className="day-text">
                      {!isNaN(fullFunnelCampaignStartedInDays) ? (
                        `Day ${fullFunnelCampaignStartedInDays < 0
                          ? 0
                          : fullFunnelCampaignStartedInDays
                        }`
                      ) : (
                        <FaMinus className="no-data-icon" />
                      )}
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <CustomerCardReportSummaryPartAlternative
            comparisonDataPreview={user?.facebookAds?.weeklyReports?.comparisonDataPreview}
            popupUserFacebookAds={user?.facebookAds}
            dayCountBeforeEnhencer={user?.facebookAds?.weeklyReports?.beforeEnhencerSlice?.dayCountBeforeEnhencer || 0}
            weeklyReports={user?.facebookAds?.weeklyReports}
            currency={currency}
            userId={user.id}
            username={username}
            reportLastUpdatedAt={user?.facebookAds?.weeklyReports?.lastUpdatedAt}
            isAgencyPage={true}
          />
        </div>

        <div className="section contact">
          <div className="customer-info">
            <span
              className={`phone ${fullPhoneNumber ? '' : 'not-available'}`}
              onClick={copyPhoneNumber}
              title={fullPhoneNumber ? 'Click to copy' : 'No phone number info'}
            >
              <div>
                <div>
                  {fullPhoneNumber ? fullPhoneNumber : 'No phone'}
                  <FaCopy className="icon" />
                </div>
              </div>
            </span>
            <Link
              className="email"
              onClick={writeEmail}
              to={`mailto:${user.crmDetails?.contactEmail}`}
              title="Send new mail"
            >
              <FaPenSquare className="icon" />
              {user.crmDetails?.contactEmail}
            </Link>
          </div>
        </div>
        <div className="section account-details">
          <div className="line">
            <div className="item">
              <span className='currency'>
                {user.crmDetails?.subscription?.currency
                  ? helper.getCurrencySymbol(
                    user.crmDetails?.subscription?.currency,
                  )
                  : '$'}
              </span>
              <span className='price'>
                {
                  user.crmDetails?.subscription?.price
                  ??
                  (user.crmDetails?.subscription?.mrr * (user.crmDetails?.subscription?.period === "YEARLY" ? 12 : 1))
                }
              </span>
              /{' '}
              {user.crmDetails?.subscription?.period
                ? user.crmDetails?.subscription?.period.toLowerCase().replace('ly', '')
                : 'month'}
            </div>
            <div className="item">
              {country}
            </div>
          </div>
        </div>
        <div className='section'>
          <CustomerCardAlarmPart user={user} findByIdAndUpdate={adminActions.findCustomerByIdAndUpdate} alarmsPath="crmAlarmsAgency" />
        </div>
      </div>
    </div>
  )
}

export default AgenciesUserCard
