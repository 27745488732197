import { useDispatch, useSelector } from "react-redux"

import errorHandler from "../util/errorHandler"
import { aiCreativesActions } from "../../store/ai-creatives"
import { authActions } from "../../store/auth"
import { useHttpClient } from "./http-hook"

const useAICreatives = () => {
    const { sendRequest } = useHttpClient()
    const fullList = useSelector(state => state.aiCreatives?.fullList)
    const loading = useSelector(state => state.aiCreatives.loading)
    const enhencedCatalogDetails = useSelector(state => state.aiCreatives.enhencedCatalogDetails)
    const accessToken = useSelector(state => state.facebook.auth?.accessToken)
    const authToken = useSelector(state => state.auth.token)
    const selectedAdAccountId = useSelector(state => state.facebook.selectedAdAccount?.id)
    const userAICreativesIdList = useSelector(state => state.auth.user.aiCreatives)
    const dispatch = useDispatch()

    const getAll = async () => {
        try {
            dispatch(aiCreativesActions.setLoading(true))
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/getAICreatives?adAccountId=${selectedAdAccountId}&accessToken=${accessToken}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${authToken}`,
                },
            )
            dispatch(aiCreativesActions.setFullList(response.data))
        } catch (err) {
            errorHandler(err)
        } finally {
            dispatch(aiCreativesActions.setLoading(false))
        }
    }

    const getById = async (id, update = true) => {
        const creative = fullList.find(creative => creative._id === id || creative.id === id)
        if (creative) {
            return creative
        }

        if (!update) {
            return null
        }

        try {
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/getAICreativeById/${id}?adAccountId=${selectedAdAccountId}&accessToken=${accessToken}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${authToken}`,
                },
            )

            dispatch(aiCreativesActions.updateCreative({
                _id: id,
                id: id,
                products: response.data.products,
                discount: response.data.discountDetails,
                companyDetails: response.data.companyDetails
            }))

            return response.data
        } catch (err) {
            errorHandler(err)
        }
    }

    const createNew = async ({
        selectedProductsWithConfigurations,
        discount,
        companyDetails,
    }) => {
        const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/saveAICreative`,
            'POST',
            JSON.stringify({
                products: selectedProductsWithConfigurations,
                discountDetails: discount,
                companyDetails: companyDetails,
            }),
            {
                Authorization: 'Bearer ' + authToken,
                'Content-Type': 'application/json',
            },
        )

        const newCreativeId = response.data

        sessionStorage.setItem('currentAiCreativeId', newCreativeId)

        dispatch(
            aiCreativesActions.addNewCreative({
                _id: newCreativeId,
                id: newCreativeId,
                products: selectedProductsWithConfigurations,
                discount,
                companyDetails,
            }),
        )

        dispatch(
            authActions.updateUserObjOneField({
                field: 'aiCreatives',
                value: [newCreativeId, ...userAICreativesIdList],
            }),
        )

        return newCreativeId
    }

    const updateById = (id, data) => {
      const existingCreative = fullList.find(creative => creative._id === id || creative.id === id);

      if (!existingCreative) {
        console.warn('Creative not found in fullList');
        return;
      }

      const updatedCreative = {
        ...existingCreative,
        _id: id,
        id: id,
        enhencedCatalogDetails: {
          ...existingCreative?.enhencedCatalogDetails,
          ...data.enhencedCatalogDetails,
        },
      };

      dispatch(
        aiCreativesActions.updateCreative(updatedCreative)
      )
    }

    const setEnhencedCatalogDetails = (details) => {
        dispatch(aiCreativesActions.setEnhencedCatalogDetails(details))
    }

    const clearEnhencedCatalogDetails = () => {
        dispatch(aiCreativesActions.clearEnhencedCatalogDetails())
    }

    return {
        getAll,
        getById,
        createNew,
        updateById,

        loading,
        fullList,
        enhencedCatalogDetails,

        setEnhencedCatalogDetails,
        clearEnhencedCatalogDetails,
    }
}

export default useAICreatives