import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

import FilterGroup from './FilterGroup'
import AdminSearchBar from './AdminSearchBar'
import useSmallScreen from '../../../shared/hooks/small-screen-hook'
import Switch from '../../../shared/components/UIElements/Switch'
import filterIcon from '../../../shared/assets/images/icons/filter.svg'

import './FiltersSection.css'

const FiltersSection = props => {
  const {
    page,
    handleApplyFilter,
    resetFilters,
    handleFilterChange,
    isFilterChanged,
    tempFilter,
    setTempFilter,
    doSearch,
    searchText,
  } = props

  const [activeFilterGroupCount, setActiveFilterGroupCount] = useState(0)

  // Extract all necessary data using useSelector unconditionally
  const {
    totalUserCount,
    filteredUserCount,
    panelFilters,
    totalDemoMeetingCount,
    filteredDemoMeetingCount,
    demoMeetingFilters,
    totalSignUpCount,
    filteredSignUpCount,
    signUpFilters,
    alarmCalendarFilters,
    totalTicketCount,
    filteredTicketCount,
    ticketFilters,
    totalAgencyCount,
    filteredAgencyCount,
    agencyFilters,
  } = useSelector(state => state.admin)

  let totalItemCount = 0
  let filteredItemCount = 0
  let filters = {}

  switch (page) {
    case 'customers':
      totalItemCount = totalUserCount
      filteredItemCount = filteredUserCount
      filters = panelFilters
      break
    case 'signUps':
      totalItemCount = totalSignUpCount
      filteredItemCount = filteredSignUpCount
      filters = signUpFilters
      break
    case 'demoMeetings':
      totalItemCount = totalDemoMeetingCount
      filteredItemCount = filteredDemoMeetingCount
      filters = demoMeetingFilters
      break
    case 'alarmCalendar':
      filters = alarmCalendarFilters
      break
    case 'supportTickets':
      totalItemCount = totalTicketCount
      filteredItemCount = filteredTicketCount
      filters = ticketFilters
      break
    case 'agency':
      totalItemCount = totalAgencyCount
      filteredItemCount = filteredAgencyCount
      filters = agencyFilters
      break
    default:
      break
  }

  const [properties, setProperties] = useState(
    filters ? Object.keys(filters) : [],
  )
  const { id } = useSelector(state => state.auth.user)
  const { role } = useSelector(state => state.auth.user.accessDetails)
  const [cardFilterSwitch, setCardFilterSwitch] = useState('All Customers')
  const filterChangedRef = useRef(false) // Ref to track filter changes

  useEffect(() => {
    const properties = Object.keys(filters)
    let activeFilterGroupCount = 0
    properties.forEach(p => {
      if (
        ((p === 'calls' ||
          p === 'talks') /* || p === "emails" || p === "smses" */ &&
          (filters[p].min || filters[p].max)) ||
        filters[p].length ||
        (filters[p].value && filters[p].value !== 'maximum')
      ) {
        activeFilterGroupCount++
      }
    })

    setProperties(properties)
    setActiveFilterGroupCount(activeFilterGroupCount)
  }, [filters])

  const isSmallScreen = useSmallScreen(1250)
  const [collapsed, setCollapsed] = useState(isSmallScreen)

  useEffect(() => {
    setCollapsed(isSmallScreen)
  }, [isSmallScreen])

  useEffect(() => {
    if (role !== 'Account Manager' && role !== 'Performance Manager' && page !== 'demoMeetings' && page !== 'supportTickets') {
      return
    }
    if( page === 'demoMeetings' && role !== 'Account Manager') {
      return 
    }
    if( page === 'supportTickets' && role !== 'Performance Manager') {
      return 
    }
    if (cardFilterSwitch === 'My Customers') {
      const groupName =
        role === 'Account Manager' ? 'accountManager' : 'performanceManager'

      setTempFilter(prevFilter => {
        const prevGroup = prevFilter[groupName]
        return {
          ...prevFilter,
          [groupName]: [id],
        }
      })
    } else {
      const groupName =
        role === 'Account Manager' ? 'accountManager' : 'performanceManager'

      setTempFilter(prevFilter => {
        const prevGroup = prevFilter[groupName]
        return {
          ...prevFilter,
          [groupName]: prevGroup.filter(item => item !== id),
        }
      })
    }
    filterChangedRef.current = true
  }, [cardFilterSwitch])

  useEffect(() => {
    if (filterChangedRef.current) {
      handleApplyFilter()
      filterChangedRef.current = false
    }
  }, [tempFilter])

  const handleResetFilters = () => {
 
    props.resetFilters()
    
    sessionStorage.removeItem('dateRangeFilter')
  }

  return (
    <div className={`filter-container${collapsed ? ' collapsed' : ''} ${page}`}>
      <div onClick={() => setCollapsed(false)} className="expand-button">
        <div className="badge">{activeFilterGroupCount}</div>
        <img src={filterIcon}></img>
      </div>

      <div className="content-wrapper">
        <div className="header">
          <div className="button-title-wrapper">
            <div onClick={() => setCollapsed(true)} className="collapse-button">
              <img src={filterIcon}></img>
            </div>
            <h3>
              Filters{' '}
              {activeFilterGroupCount ? `(${activeFilterGroupCount})` : ''}
            </h3>
          </div>
          <div className="helpers">
            <div className="search">
              <AdminSearchBar doSearch={doSearch} searchText={searchText} />
            </div>
            <div className="buttons">
              <button
                onClick={handleApplyFilter}
                className={`update-button ${isFilterChanged ? '' : 'disabled'}`}
              >
                Apply
              </button>
              <button onClick={resetFilters} className="reset-button">
                Reset
              </button>
            </div>
          </div>
        </div>

        <div className="users-count">
          <h2>
            {filteredItemCount} of {totalItemCount}{' '}
            {collapsed ? '' : ' customers'}
          </h2>
        </div>

        {(role === 'Account Manager' || role === 'Performance Manager') && (
          <div className="switch-container">
            {' '}
            <Switch
              options={['My Customers', 'All Customers']}
              value={cardFilterSwitch}
              onChange={setCardFilterSwitch}
            />{' '}
          </div>
        )}

        {filters && properties.length ? (
          <div className="filters">
            {properties.map(property => (
              <React.Fragment key={property}>
                <FilterGroup
                  page={page}
                  key={property}
                  category={property}
                  handleFilterChange={handleFilterChange}
                  filter={filters}
                  tempFilter={tempFilter}
                />
              </React.Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FiltersSection
