import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaCheck, FaUpload } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { useForm } from '../../hooks/form-hook'
import { useHttpClient } from '../../hooks/http-hook'
import { useModal } from '../../hooks/modal-hook'
import Input from '../FormElements/Input'
import Button from '../FormElements/Button'
import ImageUpload from '../FormElements/ImageUpload'
import { supportTicketsActions } from '../../../store/support-tickets' 

import './CreateASupportTicket.css'
import errorHandler from '../../util/errorHandler'

const CreateASupportTicket = ({ data }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { closeModal } = useModal()
  const [formState, inputHandler] = useForm(
    {
      bugType: {
        value: '',
        isValid: true,
      },
      otherBugTypeDescription: {
        value: '',
        isValid: true,
      },
      description: {
        value: '',
        isValid: true,
      },
      image: {
        value: '',
        isValid: true,
      },
      // The following information are not shown on any form,
      // but we should still send them to the backend.
      performanceManager: {
        value: data.performanceManager,
        isValid: true,
      },
      sourcePage: {
        value: window.location.pathname + window.location.search,
        isValid: true,
      },
    },
    true,
  )

  return (
    <div className="create-a-support-ticket">
      <header className="header">
        <h2 className="title">Create a support ticket</h2>
        <div className="close" onClick={() => closeModal()} />
      </header>
      {isSubmitted ? (
        <div className="completed-screen-container">
          <div className="text-wrapper">
            <FaCheck className="icon" />
            <p className="text">
              Thank you! Your support ticket has been successfully created. We
              will notify you when our team responds.
            </p>
            <Link onClick={closeModal} to="/account/tickets" className="all-support-tickets">
              View all support tickets
            </Link>
          </div>
          <Button onClick={closeModal} className="button">
            Close this window
          </Button>
        </div>
      ) : (
        <TicketForm
          inputs={formState.inputs}
          inputHandler={inputHandler}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </div>
  )
}

const TicketForm = ({ inputs, inputHandler, setIsSubmitted }) => {
  const dispatch = useDispatch()
  const { tickets } = useSelector(state => state.supportTickets)
  const {
    bugType,
    otherBugTypeDescription,
    description,
    image,
    performanceManager,
    sourcePage,
  } = inputs
  const { token } = useSelector(state => state.auth)
  const [busy, setBusy] = useState(false)
  const { sendRequest } = useHttpClient()

  const handleSubmit = async e => {
    try {
      e.preventDefault()
      setBusy(true)

      const formData = new FormData()

      // Mandatory fields
      formData.append('bugType', bugType.value)
      if (otherBugTypeDescription.value.trim()) {
        formData.append(
          'otherBugTypeDescription',
          otherBugTypeDescription.value,
        )
      }

      // Optional fields
      if (description.value)
        formData.append('description', description.value.trim())
      if (image.value) formData.append('image', image.value)

      // Hidden fields
      formData.append('performanceManagerId', performanceManager?.value || '')
      formData.append('sourcePage', sourcePage.value)

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/createSupportTicket`,
        'POST',
        formData,
        {
          Authorization: 'Bearer ' + token,
        },
      )
      dispatch(supportTicketsActions.setTickets([response.data, ...tickets]))

      setIsSubmitted(true)
    } catch (err) {
      errorHandler(err)
    } finally {
      setBusy(false)
    }
  }

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      <div className="inputs">
        <div className="input-row">
          <label htmlFor="bugType">Where are you experiencing the issue?</label>
          <div className="bug-type">
            <Input
              element="dropdown"
              initialValue={bugType.value}
              id="bugType"
              label=""
              onInput={inputHandler}
              options={[
                'Facebook Connection',
                'Shopify Store Connection',
                'Payment/Subscription',
                'Meta Ad Campaign',
                'Other',
              ]}
            />
            {bugType.value === 'Other' && (
              <Input
                element="input"
                placeholder="Enter a Bug Type"
                initialValue={otherBugTypeDescription?.value}
                id="otherBugTypeDescription"
                onInput={inputHandler}
              />
            )}
          </div>
        </div>
        <div className="input-row">
          <label htmlFor="description">Please describe the issue</label>
          <Input
            element="textarea"
            placeholder="Enter a Description"
            initialValue={description?.value}
            id="description"
            onInput={inputHandler}
          />
        </div>
        <div className="input-row">
          <label htmlFor="image">Upload a screenshot if needed</label>
          <label className="image-upload-area" htmlFor="picInput">
            <div className="upload-text">
              <FaUpload className="icon" />
              <span className="text">Click to Upload</span>
            </div>
            <ImageUpload
              id="image"
              defaultImageURL={image?.value}
              onInput={inputHandler}
              hidePlaceholder
            />
          </label>
        </div>
      </div>
      <Button
        className="button"
        type="submit"
        disabled={
          busy ||
          !bugType.value ||
          (bugType.value === 'Other' && !otherBugTypeDescription.value.trim())
        }
      >
        Submit
      </Button>
    </form>
  )
}

export default CreateASupportTicket
