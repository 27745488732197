import React, { useState } from "react";
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from "react-redux";
import numberFormatter from "../../shared/util/numberFormatter";
import helper from "../../shared/util/helper";
import { ReactComponent as SegmentTriangleIcon } from "../../shared/assets/images/icons/segment-triangle.svg"
import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";

import "./SegmentItem.css";
import errorHandler from "../../shared/util/errorHandler";

const SegmentItem = (props) => {

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { colorClassname, segment, segmentNo, uplift, numberOfSegments, index } = props;
  //const toggleSegment = props.onToggleSegment;
  const [isLoading, setIsLoading] = useState(false);

  const openSegmentDetail = () => {
    props.openSegmentDetail(index)
  }

  const clicked = async () => {
    setIsLoading(true)
    try {
      await props.onToggleSegment(index)
      setIsLoading(false)
    } catch (err) {
      errorHandler(err)
    }

  }

  return (
    <div className={`row segment-item-row ${props.detailOpen ? "detail-open" : "row segment-item-row"} ${isLoading ? 'loading' : ''}`} >

      <div className={`segment-item ${colorClassname}`} key={index}
        onClick={(e) => {
          // toggleSegment(index);
          clicked(index)
        }}>

        {isLoading ? <InlineLoadingSpinner height='15px' size='xs' />
          :
          <div className="header-row">
            <div className="left-side">
              <div className="segment-name">Segment {segmentNo}</div>
            </div>
            <div className="segment-details-content">
              {auth.user.isSpecialUser ? <div className="numbers">
                <div className="number">
                  {numberFormatter.shortenNumber(segment.count)}
                </div>
                <div className="text">Visitors</div>
              </div> : null
              }

              <div className="numbers">
                <div className="number">
                  {(
                    uplift
                  ).toFixed(1)}
                  x
                </div>
                <div className="text">Uplift</div>
              </div>
            </div>
          </div>
        }
      </div>

      {/* <div className="segment-triangle"></div> */}

      <div className={`segment-triangle ${colorClassname}`} onClick={openSegmentDetail}>
        <SegmentTriangleIcon />
      </div>
    </div>
  );
};

export default SegmentItem;
