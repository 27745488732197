import React, { useState, useEffect } from "react";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import errorHandler from "../../../shared/util/errorHandler";
import numberFormatter from '../../../shared/util/numberFormatter'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'

import facebookAdsLogo from "../../../shared/assets/images/icons/fb-logo.png";
import googleAdsLogo from "../../../shared/assets/images/icons/google-ads-logo.png";
import Button from "../../../shared/components/FormElements/Button";
import { FaCog, FaSave, FaTimes, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";

import NotificationBadge from '../../../shared/components/UIElements/NotificationBadge'

import AudienceSettings from "../../../project/components/AudienceSettings";



const AudienceEventSettings = ({ audEvent, userId, authToken, deleteEvent }) => {

  const { sendRequest } = useHttpClient()

  const [initialTopVisitors, setInitialTopVisitors] = useState(audEvent.conditions.topVisitorsPercentage);
  const [topVisitors, setTopVisitors] = useState(audEvent.conditions.topVisitorsPercentage);

  const [cutOffPurchasePropensity, setCutOffPurchasePropensity] = useState(audEvent.conditions.cutOffPurchasePropensity)
  const [purchasePropensityAboveCutOff, setPurchasePropensityAboveCutOff] = useState(audEvent.conditions.averagePurchasePropensityAboveCutOff)
  const [numberOfSelectedVisitors, setNumberOfSelectedVisitors] = useState(audEvent.conditions.visitorCount)
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setIsDirty] = useState(audEvent.isNewlyAdded)
  const [isNewlyAdded, setIsNewlyAdded] = useState(audEvent.isNewlyAdded)
  const [isFeatureBundlesOpen, setIsFeatureBundlesOpen] = useState(false)


  const sliderValueChange = (e) => {
    setTopVisitors(e.target.value)

  }

  const sliderMouseUp = async (e) => {
    try {
      setIsLoading(true)
      let result = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/calculatePurchasePropensityForTopVisitors?userId=${userId}&cutOffPercentage=${e.target.value}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        }
      )

      setCutOffPurchasePropensity(result.cutOffPurchasePropensity)
      setPurchasePropensityAboveCutOff(result.purchasePropensityAboveCutOff)
      setNumberOfSelectedVisitors(result.visitorCount)
      setIsDirty(true)
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsLoading(false)
    }
  }

  const saveEvent = async () => {
    try {

      console.log(audEvent.adPlatform)

      let url = audEvent.isNewlyAdded ?
        `${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/addNewAudienceEvent`
        :
        `${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/saveAudienceEvent`

      let body = {
        userId,
        eventName: audEvent.eventName,
        visitorCount: numberOfSelectedVisitors,
        topVisitorsPercentage: parseInt(topVisitors),
        cutOffPurchasePropensity,
        purchasePropensityAboveCutOff,
        adPlatform: audEvent.adPlatform
      }


      let result = await sendRequest(url,
        'POST',
        JSON.stringify(body),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        }
      )

      setIsNewlyAdded(false)

      toast.success("Saved!")
      setIsDirty(false)
    } catch (err) {
      errorHandler(err)
    }
  }

  const cancel = async () => {
    if (isNewlyAdded) {
      deleteEvent({
        eventName: audEvent.eventName,
        isNewlyAdded: isNewlyAdded
      })
    } else if (isDirty) {
      setTopVisitors(audEvent.conditions.topVisitorsPercentage);
      setCutOffPurchasePropensity(audEvent.conditions.cutOffPurchasePropensity)
      setPurchasePropensityAboveCutOff(audEvent.conditions.averagePurchasePropensityAboveCutOff)
      setNumberOfSelectedVisitors(audEvent.conditions.visitorCount)
      setIsDirty(false)
    }
  }

  const eventAddedToDB = (eventName) => {

  }

  return <div className="content-box model-settings" style={{ marginBottom: "1em" }}>
    <div className="row audience-event-row">
      <div className="left-column">

        <div className="aud-event-name">
          <img src={audEvent.adPlatform === "Google" ? googleAdsLogo : facebookAdsLogo} />
          {audEvent.eventName}
        </div>
      </div>


      <div className="top-visitors-slider-cont">
        Top visitors:
        %{topVisitors}
        <input className="slider" type="range" value={topVisitors} onChange={sliderValueChange} onMouseUp={sliderMouseUp} />
      </div>

      {
        isLoading ? <div style={{ width: "100%", flex: "0 0 100px", fontSize: ".8em" }}> <InlineLoadingSpinner message="Just a sec..." /> </div>
          :

          <div className="audience-metrics">
            <div>
              # Visitors: <span className="value">{isLoading ? <InlineLoadingSpinner /> : numberFormatter.formatNumber(numberOfSelectedVisitors)}</span>
            </div>
            <div>
              Cutoff propensity: <span className="value">
                %{isLoading ? <InlineLoadingSpinner /> : numberFormatter.formatNumber(cutOffPurchasePropensity * 100, 3)}
              </span>
            </div>
            <div>
              Average propensity: <span className="value">
                %{isLoading ? <InlineLoadingSpinner /> : numberFormatter.formatNumber(purchasePropensityAboveCutOff * 100, 3)}
              </span>
            </div>
          </div>
      }

      <div className="events-action-row">
        <Button onClick={() => { setIsFeatureBundlesOpen(!isFeatureBundlesOpen) }} size="small" className="save-button">
          {
            audEvent.filterBundles.length ?
              <NotificationBadge color="blue">
                <span>
                  {audEvent.filterBundles.length}
                </span>
              </NotificationBadge> : null
          }
          <FaCog />
        </Button>

        {isDirty &&
          <Button primary={true} onClick={saveEvent} size="small" className="save-button" title="Save event">
            <FaSave />
          </Button>
        }
        {
          (isNewlyAdded || isDirty) ?

            <Button danger={true} onClick={cancel} size="small" className="delete-audience-event-button" title="Cancel">
              <FaTimes />
            </Button>
            :
            <Button danger={true} onClick={cancel} size="small" className="delete-audience-event-button" title="Delete event">
              <FaTrash />
            </Button>
        }
      </div>
    </div>

    {
      isFeatureBundlesOpen &&
      <div className="audience-settings-container">
        <AudienceSettings initialFilterBundles={audEvent.filterBundles} isV3={true} eventName={audEvent.eventName} userId={userId} />
      </div>
    }


  </div>

};

export default AudienceEventSettings;
