import React, { useState, useEffect, useMemo } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import './IntroPhonesContainer.css'

import lights from '../../../shared/assets/images/intro/lights.svg'
import leftPhone from '../../../shared/assets/images/intro/left-phone.png'
import rightPhone from '../../../shared/assets/images/intro/right-phone.png'
import left1 from '../../../shared/assets/images/intro/left-1.png'
import left2 from '../../../shared/assets/images/intro/left-2.png'
import left3 from '../../../shared/assets/images/intro/left-3.png'
import left4 from '../../../shared/assets/images/intro/left-4.png'
import right1 from '../../../shared/assets/images/intro/right-1.png'
import right2 from '../../../shared/assets/images/intro/right-2.png'
import right3 from '../../../shared/assets/images/intro/right-3.png'
import right4 from '../../../shared/assets/images/intro/right-4.png'
import shadow from '../../../shared/assets/images/intro/shadow.svg'

const IntroPhonesContainer = () => {
  const [leftScreenIndex, setLeftScreenIndex] = useState(0)
  const [rightScreenIndex, setRightScreenIndex] = useState(0)

  const leftScreens = useMemo(() => [left1, left2, left3, left4], [])
  const rightScreens = useMemo(() => [right1, right2, right3, right4], [])

  const leftScreensLength = useMemo(() => leftScreens.length, [leftScreens])
  const rightScreensLength = useMemo(() => rightScreens.length, [rightScreens])

  useEffect(() => {
    const screenInterval = setInterval(() => {
      setLeftScreenIndex(prevIndex => (prevIndex + 1) % leftScreensLength)
      setRightScreenIndex(prevIndex => (prevIndex + 1) % rightScreensLength)
    }, 3000)

    return () => clearInterval(screenInterval)
  }, [leftScreensLength, rightScreensLength])

  return (
    <div className="intro-phones-container">
      <img src={lights} alt="light effects" className="intro-lights" />

      <img
        src={rightPhone}
        alt="Right Phone"
        className="intro-phones-image intro-right-phone"
      />
      <img
        src={leftPhone}
        alt="Left Phone"
        className="intro-phones-image intro-left-phone"
      />

      <div className="intro-screens-container">
        <div className="intro-screen-mask intro-left-phone-screen">
          <AnimatePresence mode="wait">
            <motion.div
              key={leftScreenIndex}
              className="intro-screen-content"
              initial={{ x: '-100%' }}
              animate={{ x: 0, y: 0 }}
              exit={{ x: '100%' }}
              transition={{
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
              }}
              style={{
                backgroundImage: `url(${leftScreens[leftScreenIndex]})`,
              }}
            />
          </AnimatePresence>
        </div>
        <div className="intro-screen-mask intro-right-phone-screen">
          <AnimatePresence mode="wait">
            <motion.div
              key={rightScreenIndex}
              className="intro-screen-content"
              initial={{ x: '100%', y: '30%' }}
              animate={{ x: 0, y: 0 }}
              exit={{ x: '-100%', y: '-30%' }}
              transition={{
                duration: 0.7,
                ease: [0.6, -0.05, 0.01, 0.99],
              }}
              style={{
                backgroundImage: `url(${rightScreens[rightScreenIndex]})`,
              }}
            />
          </AnimatePresence>
        </div>
        <img src={shadow} alt="light effects" className="intro-shadow" />
      </div>
    </div>
  )
}

export default IntroPhonesContainer
