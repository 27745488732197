import { WORKBENCH_SIZE } from '../../../support/constants'
import temp7bg from '../../../../shared/assets/images/ai-creative/temp7bg.svg'
import temp7bg2 from '../../../../shared/assets/images/ai-creative/temp7bg2.svg'

export const Template7Config = {
  name: 'Floral Rhythm',
  containerId: 'template7-carousel-single',
  backgroundColor: 'color2',
  hasDiscount: true,
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp7bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#064B3E',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: { x: WORKBENCH_SIZE.width / 2, y: WORKBENCH_SIZE.height / 2 },
      center: true,
      scale: { maxWidth: 650, maxHeight: 750 },
      dynamicSrc: 'productImage',
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp7bg2,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true,
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 275,
        y: WORKBENCH_SIZE.height - 120,
      },
      width: 550,
      height: 82,
      text: `30% DISCOUNT!`,
      fontFamily: 'Bayon',
      fontSize: 73,
      letterSpacing: 0.2,
      color: 'color3',
      align: 'center',
      verticalAlign: 'middle',
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: { x: WORKBENCH_SIZE.width / 2 - 415, y: 30 },
      width: 830,
      height: 63,
      text: `USE CODE: GET30`,
      fontFamily: 'Bayon',
      fontSize: 54,
      letterSpacing: 3.5,
      color: 'color3',
      align: 'center',
      verticalAlign: 'middle',
    },
  ],
}
