import { WORKBENCH_SIZE } from '../../../support/constants'
import temp19bg from '../../../../shared/assets/images/ai-creative/temp19bg.svg'

export const Template19Config = {
  name: 'Modern Minimal',
  containerId: 'template19-carousel-single',
  backgroundColor: 'color2',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp19bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: 'black',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: (WORKBENCH_SIZE.width / 2) * 1.35,
        y: (WORKBENCH_SIZE.height / 2) * 1.15,
      },
      scale: {
        maxWidth: 650,
        maxHeight: 650,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 40,
        y: 402,
      },
      width: 340,
      height: 160,
      text: '{{productName}}', // Dynamic placeholder
      fontFamily: 'Roboto',
      fontSize: 36,
      fontStyle: '400',
      color: 'color2',
      verticalAlign: 'middle',
      fontIndex: 1,
    },
    // Primary Text (Discount Percentage)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 40,
        y: 140,
      },
      width: WORKBENCH_SIZE.width - 80,
      text: '30% OFF', // Dynamic placeholder
      fontFamily: 'Staatliches',
      fontSize: 120,
      color: 'color3',
      align: 'left',
      fontIndex: 1,
    },
    // Secondary Text (Sale Label)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 40,
        y: 85,
      },
      width: WORKBENCH_SIZE.width - 80,
      text: 'BLACK FRIDAY SALE',
      fontFamily: 'Roboto',
      fontSize: 36,
      fontStyle: '400',
      color: 'color3',
      align: 'left',
      fontIndex: 2,
    },
  ],
}
