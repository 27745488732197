import React, { useMemo } from 'react'
import { MdTabletMac } from 'react-icons/md'
import { FaHome, FaRocket } from 'react-icons/fa'

import useNavigator from '../../../shared/hooks/navigator-hook'
import Button from '../../../shared/components/FormElements/Button'
import DiscountTool from '../tool/DiscountTool'
import CompanyTool from '../tool/CompanyTool'

import styles from './Toolbar.module.css'

const LeftToolbar = () => {
  const { navigateWithParams, searchParams } = useNavigator()

  const aiCreativeProducts = useMemo(() => {
    return JSON.parse(sessionStorage.getItem('aiCreativeProducts'))
  }, [])

  const aiCreativeIdInSessionStorage = sessionStorage.getItem('currentAiCreativeId')

  const handleLaunchAd = () => {
    navigateWithParams(`/ai-ads/full-funnel-campaigns${searchParams.campaignId ? '' : '/create'}`, { aiCreativeId: aiCreativeIdInSessionStorage })
  }

  return (
    <div className={styles.toolbarContainer}>
      <div className={styles.catalogTopLogo}>
        <span className={styles.catalogTopLogoText}>AI Creative Lab</span>
        <MdTabletMac className={styles.catalogTopLogoDeviceIcon} />
      </div>
      <div className={styles.toolbarLeftInside}>
        <div className={styles.mainToolbarItems}>
          <div className={`${styles.toolbarBox} ${styles.enhencedToolBox}`}>
            <div className={styles.toolbarBoxTitle}>
              <span className={styles.toolbarBoxTitleText}>
                Company Details
              </span>
            </div>
            <div className={styles.toolbarBoxInside}>
              <CompanyTool />
            </div>
          </div>
          {/* <div className={`${styles.toolbarBox} ${styles.enhencedToolBox}`}>
            <div className={styles.toolbarBoxTitle}>
              <span className={styles.toolbarBoxTitleText}>Ad Settings</span>
            </div>
            <div className={styles.toolbarBoxInside}>
              <DiscountTool />
            </div>
          </div> */}
        </div>
        <div className={styles.navigationButtonContainer}>
          <Button
            className={styles.backButton}
            onClick={() => {
              navigateWithParams('/ai-ads/creative-lab')
            }}
          >
            <FaHome className={styles.backButtonIcon} />
            AI Creative Lab Home
          </Button>
          {aiCreativeProducts?.every(product => product.isSaved) && aiCreativeIdInSessionStorage && (
            <Button
              className={styles.backButton}
              onClick={handleLaunchAd}
            >
              <FaRocket className={styles.backButtonIcon} />
              Go to Ad Launcher
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default LeftToolbar
