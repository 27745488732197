import React from 'react'

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import BoxColor from '../tool/BoxColor'
import TextsTool from '../tool/TextsTool'
import FontSelector from '../tool/FontSelector'
import ElementControls from '../tool/ElementControls'

import styles from './Toolbar.module.css'

const RightToolbar = ({ isShowingTemplates, onBrowseTemplatesClick, selectedElementInfo }) => {
  const { draftConfiguration } = useEnhencedCatalog()

  const [imageSrc, setImageSrc] = React.useState(null)

  React.useEffect(() => {
    const loadImage = async () => {
      try {
        const image = await import(
          `../../../shared/assets/images/ai-creative/thumbnails/${draftConfiguration.template.id}.png`
        )
        setImageSrc(image.default)
      } catch (error) {
        console.error('Error loading image:', error)
        setImageSrc(null)
      }
    }

    if (draftConfiguration?.template?.id) loadImage()
  }, [draftConfiguration?.template?.id])

  return (
    <div className={styles.toolbarContainer}>
      <div className={styles.toolbarInside}>
        <div className={styles.toolbarBox}>
          <div className={styles.toolbarBoxTitle}>
            <span className={styles.toolbarBoxTitleText}>Template</span>
          </div>
          <div className={styles.toolbarBoxInside}>
            <div className={styles.toolbarBoxInsideItem}>
              <div
                className={styles.toolbarBoxInsideItemContent}
                style={{ width: '100%' }}
              >
                <button
                  className={`${styles.toolbarBoxInsideItemContentButton} ${styles.browseTemplatesButton}`}
                  onClick={onBrowseTemplatesClick}
                >
                  {imageSrc && (
                    <img
                      className={styles.browseTemplatesIcon}
                      src={imageSrc}
                      alt="browse-templates"
                    />
                  )}
                  Browse Templates
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Display element controls when an element is selected */}
        {!isShowingTemplates && selectedElementInfo?.element !== null ? (
          <ElementControls selectedElementInfo={selectedElementInfo} />
        ) : (
          <BoxColor />
        )}
      </div>
    </div>
  )
}

export default RightToolbar
