import React, { useEffect, useState, useCallback } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ProgressBar from "../../shared/components/UIElements/ProgressBar";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "./ConnectionConfig.css"
import { useLocation } from "react-router-dom";
import ConnectFacebook from "../components/ConnectFacebook";
import ConnectSocialAccounts from "../components/ConnectSocialAccounts";
import ChecklistCard from "../../shared/components/UIElements/ChecklistCard";
import Button from "../../shared/components/FormElements/Button";

import NonShopifyStartSubscription from "../components/NonShopifyStartSubscription";
import ShopifyStartSubscription from "../components/ShopifyStartSubscription";
import StoreConnect from "../components/StoreConnect";
import AudienceCreationBox from "../components/AudienceCreationBox";
import Breadcrumb from "../../shared/components/UIElements/Breadcrumb";
import AdCreativeLibraryBox from "../components/AdCreativeLibraryBox";
import Modals from "../../shared/enums/Modals";
import { useModal } from "../../shared/hooks/modal-hook";
import PixelEventsVerificationRequired from "../../shared/components/Modals/PixelEventsVerificationRequired";
import { useFacebook } from "../../shared/hooks/facebook-hook";

const ConnectionConfig = () => {

  const user = useSelector(state => state.auth.user);
  const storeConnected = user?.shopify ? user?.shopify?.scriptId : user?.connectFlow?.dataCollectionStarted;
  const { isLoading } = useHttpClient();
  const [percentage, setPercentage] = useState(0)
  const facebook = useSelector(state => state.facebook)

  const isLoggedInToFacebook = useSelector(state => state.facebook.isLoggedIn)
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const businessSelectionIsEligible = useSelector(state => state.facebook.businessSelectionIsEligible)
  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const adAccountSelectionIsEligible = useSelector(state => state.facebook.adAccountSelectionIsEligible)
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const pixelSelectionIsEligible = useSelector(state => state.facebook.pixelSelectionIsEligible)
  const hasAudienceError = useSelector(state => state.facebook.hasAudienceError)
  const fbAudiences = useSelector(state => state.facebook.audiences)

  const adCreativeLibrary = useSelector(state => state.facebook.adCreativeLibrary)
  const isFetchingAdCreativeLibrary = useSelector(state => state.facebook.loadings?.adCreativeLibrary)

  const selectedActiveFacebookPage = useSelector(state => state.facebook.selectedActiveFacebookPage)
  const selectedActiveInstagramAccount = useSelector(state => state.facebook.selectedActiveInstagramAccount)
  const { openModal } = useModal()
  const { closeModal } = useModal()

  const { verifyPixelEvents } = useFacebook()

  const pixelEventsVerified = facebook.pixelEventsVerified
  ? facebook.pixelEventsVerified[facebook.selectedAdAccount?.id]
  : null
const shouldShowVerifyEventsButton =
  user?.connectFlow?.completedFacebookConnections <
  new Date().valueOf() - 1000 * 60 * 60 * 24 * 20


  const [stepCompletions, setStepCompletions] = useState({
    subscribed: (user.subscription && user.subscription?.status === 'active'),
    storeConnected: !!storeConnected,
    loggedInToFacebook: false,
    selectedBusiness: false,
    selectedAdAccount: false,
    selectedPixel: false,
    hasAudiencesWithoutError: false,
    selectedActiveFacebookPage: false,
    selectedActiveInstagramAccount: false,
  })

  const location = useLocation()

  useEffect(() => {
    if (location.search !== "") {
      const query = new URLSearchParams(window.location.search);
      if (query.get("io")) {
        const io = parseInt(query.get("io"))

      }
    }
  }, [location])

  useEffect(() => {
    let p = 0

    setStepCompletions({
      subscribed: (user.subscription && user.subscription?.status === 'active'),
      storeConnected: !!storeConnected,
      loggedInToFacebook: isLoggedInToFacebook,
      selectedBusiness: isLoggedInToFacebook && selectedBusiness?.id && businessSelectionIsEligible,
      selectedAdAccount: isLoggedInToFacebook && selectedAdAccount?.id && adAccountSelectionIsEligible,
      selectedPixel: isLoggedInToFacebook && selectedPixel?.id && pixelSelectionIsEligible,
      hasAudiencesWithoutError: selectedAdAccount && !hasAudienceError && fbAudiences[selectedAdAccount.id],
      selectedActiveFacebookPage: isLoggedInToFacebook && selectedActiveFacebookPage,
      selectedActiveInstagramAccount: isLoggedInToFacebook && selectedActiveInstagramAccount,
    })

    if ((user.subscription && user.subscription?.status === 'active')) {
      p += 10
    }

    if (storeConnected) {
      p += 10
    }
    if (storeConnected && isLoggedInToFacebook) {
      p += 10
    }
    if (storeConnected && isLoggedInToFacebook && selectedBusiness?.id && businessSelectionIsEligible) {
      p += 10
    }
    if (storeConnected && isLoggedInToFacebook && selectedAdAccount?.id && adAccountSelectionIsEligible) {
      p += 10
    }
    if (storeConnected && isLoggedInToFacebook && selectedPixel?.id && pixelSelectionIsEligible) {
      p += 10
    }

    if (storeConnected && isLoggedInToFacebook && !hasAudienceError && fbAudiences[selectedAdAccount?.id]) {
      p += 10
    }

    if (storeConnected && isLoggedInToFacebook && selectedAdAccount?.id && !isFetchingAdCreativeLibrary && adCreativeLibrary?.[selectedAdAccount.id]?.updatedAt) {
      p += 10
    }

    if (storeConnected && !hasAudienceError && isLoggedInToFacebook && selectedActiveFacebookPage) {
      p += 10
    }
    if (storeConnected && !hasAudienceError && isLoggedInToFacebook && selectedActiveInstagramAccount) {
      p += 10
    }

    setPercentage(p)
  }, [facebook, storeConnected, isFetchingAdCreativeLibrary])

  useEffect(() => {
    if (percentage === 100) {
      setTimeout(() => {
        const mainElement = document.querySelector('.App main');
        if (mainElement) {
          mainElement.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      }, 100);
    }
  }, [percentage]);

  useEffect(() => {
    if (percentage === 100 && !pixelEventsVerified && shouldShowVerifyEventsButton) {
      openPixelEventsVerificationModal()
      
    }
  }, [percentage]);

  const openPixelEventsVerificationModal = () => {
    const modalConfig = {
      title: 'Verify Enhencer Events',
      imageUrl:
        'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
      modalComponent: <PixelEventsVerificationRequired />,
      text: '',
      actions: [
        {
          text: 'Verified',
          callback() {
            verifyPixelEvents(user.id, facebook.selectedAdAccount.id)
            closeModal()
          },
        },
        {
          text: 'Do it later',
          callback() { },
        },
      ],
    }
    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
      closeOnClickOutside: true,
    })
  }

  return <div className="config-page">
    <div className="breadcrumb-sticky-wrapper">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Breadcrumb>
          <span>Connections</span>
        </Breadcrumb>
      </div>
    </div>
    <div className="config-page-content">
      {isLoading ? <LoadingSpinner /> :
        <React.Fragment>
          <div className="process-status-container">
            <div className="inner-wrapper">
              <div className="process-status-box">
                <div className="process-text">
                  Integration Process Completed: &nbsp;
                  <span className="emphesize">{percentage}%</span>
                </div>
                <ProgressBar percentage={percentage} height={14} />
              </div>
            </div>
          </div>
          <div className="config-grid configure-facebook">

            <ChecklistCard
              number={1}
              name="Start Your Subscription"
              completed={stepCompletions.subscribed}>
              {
                user.shopify ?
                  <ShopifyStartSubscription user={user.isSpecialUser ? user : null} />
                  :
                  <NonShopifyStartSubscription
                    user={user.isSpecialUser ? user : null}
                  />
              }
            </ChecklistCard>

            <ChecklistCard number={2} name="Connect Your Store" completed={stepCompletions.storeConnected} >
              <StoreConnect
                disabled={!stepCompletions.subscribed}
                completed={!stepCompletions.storeConnected}
                user={user.isSpecialUser ? user : null}
              />
            </ChecklistCard>


            {stepCompletions.subscribed && stepCompletions.storeConnected &&
              <ConnectFacebook
                stepCompletions={stepCompletions}
                user={user.isSpecialUser ? user : null}
              />
            }

            {stepCompletions.storeConnected && stepCompletions.selectedPixel &&
              <AudienceCreationBox
                hasAudiencesWithoutError={stepCompletions.hasAudiencesWithoutError}
                user={user.isSpecialUser ? user : null}
              />
            }

            {stepCompletions.storeConnected && stepCompletions.selectedAdAccount && stepCompletions.selectedPixel &&
              <AdCreativeLibraryBox
              />
            }

            {stepCompletions.subscribed && stepCompletions.storeConnected && stepCompletions.loggedInToFacebook && stepCompletions.selectedBusiness && stepCompletions.selectedAdAccount && stepCompletions.selectedPixel && stepCompletions.hasAudiencesWithoutError &&
              <ConnectSocialAccounts
                user={user.isSpecialUser ? user : null}
                stepCompletions={stepCompletions}
              />
            }


          </div>
          {percentage === 100 &&
            <div className="config-completed">
              Your connections are completed, start your campaigns right away!
              <Button to="/ai-ads/full-funnel-campaigns">
                Go to campaigns
                <FaArrowRight />
              </Button>
            </div>
          }
        </React.Fragment>
      }</div>
  </div>
}

export default ConnectionConfig;
