import { WORKBENCH_SIZE } from '../../../support/constants'
import temp24fg from '../../../../shared/assets/images/ai-creative/temp24fg.svg'

export const Template1Config = {
  name: 'Minimal',
  containerId: 'template1-enhenced-catalog',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover',
      center: true
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: temp24fg,
      isSvg: true,
      svgColor: 'color3',
      svgCustomReplace: '#221E17',
      draggable: false,
      clickThrough: true
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: (WORKBENCH_SIZE.width * 23) / 1000,
        y: (WORKBENCH_SIZE.height * 875) / 1000
      },
      width: 500,
      fontFamily: 'Readex Pro',
      fontSize: 50,
      fontStyle: 'bold',
      color: 'color2',
      text: '{{productName}}'
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: (WORKBENCH_SIZE.width * 23) / 1000,
        y: (WORKBENCH_SIZE.height * 763) / 1000
      },
      width: 800,
      text: '30% DISCOUNT!',
      fontSize: 93,
      fontFamily: 'Readex Pro',
      color: 'color2'
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: (WORKBENCH_SIZE.width * 574) / 1000,
        y: (WORKBENCH_SIZE.height * 875) / 1000
      },
      width: 420,
      text: 'USE CODE: GET30',
      fontSize: 50,
      fontFamily: 'Readex Pro',
      color: 'color2',
      align: 'right'
    }
  ]
}
