import React from 'react'
import { useSelector } from 'react-redux'

import './FacebookPostSelector.css'

const FacebookPostSelector = ({ data }) => {
  const posts = useSelector(state => {
    if (state.facebook.pagePosts) {
      return state.facebook.pagePosts[data.pageId]?.data
    }
    return null
  })
  console.log("posts: ", posts)
  console.log("data: ", data)

  return (
    <div className="posts-modal">
      <h4>Posts</h4>

      {
        posts ?
          <div className='posts-container'>
            {
              posts.map(post => (
                <div className='post-item'>
                  <img src={post.picture} alt={post.message.substring(0, 20)} />
                  <div className='post-message'>{post.message.substring(0, 150)}...</div>
                </div>
              ))
            }
          </div> : null
      }
    </div>
  )
}

export default FacebookPostSelector
