export const palettes = [
  ['#FF6F61', '#FFFFFF', '#2C2C2C'],
  ['#6B5B95', '#F0F0F0', '#2D2D2D'],
  ['#88B04B', '#FAFAFA', '#2A2A2A'],
  ['#F7CAC9', '#FFFFFF', '#3D3D3D'],
  ['#92A8D1', '#F8F8F8', '#2C2C2C'],
  ['#955251', '#F5F5F5', '#3B3B3B'],
  ['#B565A7', '#FDFDFD', '#2F2F2F'],
  ['#009B77', '#F2F2F2', '#262626'],
  ['#DD4124', '#FFFFFF', '#2A2A2A'],
  ['#D65076', '#FDFDFD', '#282828'],
  ['#45B8AC', '#F6F6F6', '#2E2E2E'],
  ['#EFC050', '#FFFBEA', '#333333'],
  ['#5B5EA6', '#F3F3F3', '#292929'],
  ['#9B2335', '#FEFEFE', '#272727'],
  ['#DFCFBE', '#FFFFFF', '#343434'],
  ['#BC243C', '#FFF1F1', '#242424'],
  ['#C3447A', '#FFFBFB', '#292929'],
  ['#98B4D4', '#F7F7F7', '#2A2A2A'],
  ['#C4AEAD', '#FFFDFD', '#383838'],
  ['#E15D44', '#FFFFFF', '#292929'],
  ['#4A4E4D', '#F2F2F2', '#222222'],
]

export const WORKBENCH_SIZE = { width: 1080, height: 1080 }

const carouselAndSingleTemplates = require.context('../templates/geometric/carousel-single', false, /\.js$/)
export const NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES = carouselAndSingleTemplates.keys().length // Total number of '.js' files in the templates/geometric/carousel-single folder

const enhencedCatalogTemplates = require.context('../templates/geometric/enhenced-catalog', false, /\.js$/)
export const NUMBER_OF_ENHENCED_CATALOG_TEMPLATES = enhencedCatalogTemplates.keys().length // Total number of '.js' files in the templates/geometric/enhenced-catalog folder

const commonTemplates = require.context('../templates/geometric/common', false, /\.js$/)
export const NUMBER_OF_COMMON_TEMPLATES = commonTemplates.keys().length // Total number of '.js' files in the templates/geometric/common folder
