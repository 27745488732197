import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import helper from '../../shared/util/helper'

import AdminPanelCustomersList from '../components/customers/CustomersList'
import FiltersSection from '../components/listing-components/FiltersSection'
import { adminActions, resettedPanelFilters } from '../../store/admin'

import './AdminPanel.css'
import { useAdmin } from '../../shared/hooks/admin-hook'
import AdminPanelSummarySection from '../components/customers/AdminPanelSummarySection'

const AdminPanel = () => {
  const admin = useAdmin()
  const { getCustomers } = useAdmin()
  const { lastCursorCustomers, customers, panelFilters } = useSelector(state => state.admin)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const { managerUsers } = useSelector(state => state.admin)

  const [searchText, setSearchText] = useState(
    JSON.parse(sessionStorage.getItem('performancePanelSearch')) || '',
  )

  const [tempFilter, setTempFilter] = useState(panelFilters)

  useEffect(() => {
    document.title = "Enhencer Admin Panel"
    if (customers.length) {
      return
    }
    const savedFilters = sessionStorage.getItem('filters')
    if (savedFilters) {
      const initialFilter = JSON.parse(savedFilters)

      getUserPerformance({ freshFetch: true, filter: initialFilter })
    } else {
      getUserPerformance({ freshFetch: true })
    }
  }, [])


  useEffect(() => {
    setTempFilter(panelFilters)
  }, [panelFilters])


  useEffect(() => {
    admin.getNoAlarmUserCounts({
      filters: {
        type: ["Customer"]
      }
    })
  }, [])

  const handleFilterChange = ({ groupName, type, value, checked }) => {
    switch (type) {
      case "range":
        const [group, input] = groupName.split('.')
        setTempFilter(prevFilter => {
          const prevGroup = prevFilter[group]

          return {
            ...prevFilter,
            [group]: {
              ...prevGroup,
              [input]: Number(value),
            },
          }
        })
        break;

      case "checkbox":
        if (groupName === 'sortBy'
          || groupName === 'googleAds'
          || groupName === 'didAccMngrSell'
          || groupName === 'platform'
          || groupName === 'audienceNetwork'
          || groupName === 'aiAds'
          || groupName === 'paused'
          || groupName === 'adsetComparison'
          || groupName === 'usageCharges'
          || groupName === 'manuallyPublishCampaign'
          || groupName === 'isAccessTokenValid'
          || groupName === 'beforeRoas'
          || groupName === 'afterRoas') {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [value] : [],
            }
          })
        } else if (groupName === 'performanceManager' || groupName === 'accountManager' || groupName === 'salesPerson') {
          let objId;
          if (value !== '<Not set>') {
            objId = helper.findBy(Object.values(managerUsers), 'name', value)._id
          } else {
            objId = value
          }
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, objId] : prevGroup.filter(item => item !== objId),
            }
          })
        } else {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, value]
                : prevGroup.filter(item => item !== value),

            }
          })
        }
        break;
      case "date-range":
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break;
      case "hour-range":
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break;
      default:
        break;
    }

    setIsFilterChanged(true)
  }

  const handleApplyFilter = () => {
    dispatch(adminActions.setPanelFilters(tempFilter))
    dispatch(adminActions.setCustomers([]))
    sessionStorage.setItem('filters', JSON.stringify(tempFilter))
    getUserPerformance({ freshFetch: true, filter: tempFilter })
    setIsFilterChanged(false)
  }

  const resetFilters = () => {
    const newFilter = resettedPanelFilters
    setTempFilter(newFilter)
    dispatch(adminActions.resetPanelFilters())
    sessionStorage.setItem('filters', JSON.stringify(newFilter))
    getUserPerformance({ freshFetch: true, filter: newFilter })
    setIsFilterChanged(false)
  }

  const getUserPerformance = async ({ freshFetch = false, filter = panelFilters, search = searchText }) => {
    setIsLoading(true)
    await getCustomers({ freshFetch, filter, search })
    setIsLoading(false)
  }


  const doSearch = async text => {
    dispatch(adminActions.setCustomers([]))
    setSearchText(text)
    getUserPerformance({ freshFetch: true, search: text })
    sessionStorage.setItem('performancePanelSearch', JSON.stringify(text))

  }

  return (
    <div className="admin-panel-container">
      <div className="container-performance">
        {/* {isLoading && <LoadingSpinner message="Loading..." />} */}
        {!isLoading && !customers.length ? (
          <h2 className="not-found-text">No users found for these filters.</h2>
        ) : (
          <AdminPanelCustomersList isFetching={isLoading} reFetch={() => { getUserPerformance({ freshFetch: false }) }} />
        )}
        <FiltersSection
          page="customers"
          isFilterChanged={isFilterChanged}
          handleApplyFilter={handleApplyFilter}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          doSearch={doSearch}
          searchText={searchText}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />
      </div>
      <AdminPanelSummarySection />
    </div>
  )
}

export default AdminPanel
