import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { FaPen } from 'react-icons/fa'
import './CampaignReportTable.css'
import Button from '../../../shared/components/FormElements/Button'
import { useModal } from '../../../shared/hooks/modal-hook'
import Modals from '../../../shared/enums/Modals'
import StatusIndicator from './CampaignStatusIndicator'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import Switch from '../../../shared/components/UIElements/Switch'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import { isEqual } from 'lodash'
import { toast } from 'react-toastify'
import errorHandler from '../../../shared/util/errorHandler'


const CampaignReportTable = ({
  selectedCards,
  tableData,
  metricTitles,
  tooltipContents,
  requestSort,
  sortConfig,
  currencySymbol,
  fullFunnelId,
  objective,
  formatEffectiveStatus,

}) => {

  const { openModal } = useModal()
  const { navigateWithAdminParams } = useAdmin()
  const scrollRef = useRef(null)

  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isTouchPad, setIsTouchPad] = useState(false)
  const [initialLoad, setInitialLoad] = useState(true)

  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser'))
  const adminToken = useSelector(state => state.auth.adminToken)
  const [displayedTableData, setDisplayedTableData] = useState(tableData)
  const userId = useSelector(state => state.auth.user.id)
  const { sendRequest, isLoading } = useHttpClient()
  const [localEdits, setLocalEdits] = useState([])


  const onMouseDown = useCallback((e) => {
    setIsDragging(true)
    setStartX(e.pageX - scrollRef.current.offsetLeft)
    setScrollLeft(scrollRef.current.scrollLeft)
    scrollRef.current.style.scrollBehavior = 'auto'
  }, [])

  const onMouseMove = useCallback((e) => {
    if (!isDragging) return
    e.preventDefault()

    if (scrollRef.current) {
      const x = e.pageX - scrollRef.current.offsetLeft
      const walk = (x - startX) * 1.5
      scrollRef.current.scrollLeft = scrollLeft - walk
    }
  }, [isDragging, startX, scrollLeft])

  const onMouseUp = useCallback(() => {
    setIsDragging(false)
    if (scrollRef.current) {
      scrollRef.current.style.scrollBehavior = 'smooth'
    }
  }, [])

  const handleWheel = useCallback((e) => {
    if (!scrollRef.current) return

    // If deltaX exists, touchpad is being used
    if (Math.abs(e.deltaX) > 0) {
      setIsTouchPad(true)
      return // Preserve default behavior for touchpad
    }

    // Special behavior for mouse wheel (vertical scroll only) 
    if (!isTouchPad && !e.ctrlKey) { // if ctrl key is not pressed
      // Allow normal scroll on Y axis
      return true
    }

    // Horizontal scroll for other cases
    e.preventDefault()
    const scrollAmount = e.deltaY * 2
    scrollRef.current.scrollTo({
      left: scrollRef.current.scrollLeft + scrollAmount,
      behavior: 'smooth'
    })
  }, [isTouchPad])

  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      currentRef.addEventListener('wheel', handleWheel, { passive: false })
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel)
      }
    }
  }, [onMouseUp])

  useEffect(() => {
    // Merge local edits with tableData
    const mergedData = tableData.map(row => {
      const localEdit = localEdits.find(edit => edit.id === row.id)
      return localEdit ? { ...row, ...localEdit } : row
    })

    // Update displayedTableData if tableData changes
    if (!isEqual(mergedData, displayedTableData)) {
      setDisplayedTableData(mergedData)
    }
  }, [tableData, localEdits, displayedTableData])

  const handleRowHover = (index, isEnter) => {
    const rows = document.querySelectorAll(`tr[data-row-index="${index}"]`)
    rows.forEach(row => {
      if (isEnter) {
        row.classList.add('hover-effect')
      } else {
        row.classList.remove('hover-effect')
      }
    })
  }

  const getFormattedStatus = (status) => {
    if (!status) return 'Unknown'

    if (typeof formatEffectiveStatus === 'function') {
      return formatEffectiveStatus(status)
    }

    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
  }

  const editAudiencesOfAdset = async ({
    adsetId,
    adsetName,
    campaignId,
    audiences,
    excludedAudiences
  }) => {
    let result = await openModal({
      type: Modals.ADSET_AUDIENCES_CONFIG,
      closeOnClickOutside: true,
      adsetId,
      adsetName,
      campaignId,
      fullFunnelId,
      adsetAudiences: audiences,
      excludedAudiences: excludedAudiences || [],
    })

    if (result) {
      toast.success("Audiences updated successfully")
      let newAudiences = result.audiences
      let newExcludedAudiences = result.excludedAudiences

      // Update localEdits to reflect the changes
      handleEdit(adsetId, {
        audiences: [...newAudiences],
        excludedAudiences: [...newExcludedAudiences]
      })
    }
  }

  const editDailyBudgetOfAdset = async ({
    adsetId,
  }) => {
    try {
      let result = await openModal({
        type: Modals.ADSET_BUDGETS_CONFIG,
        closeOnClickOutside: true,
        objective,
        adsetRows: tableData.filter(row => row.type === "adset").map(row => ({
          id: row.id,
          name: row.label,
          campaignId: row.parentId,
          budget: parseFloat(row.cardData["Ad Spend"].dailyBudget.replace(",", "")),
        })),
      })


      if (result && result.updatedAdCampaigns && Array.isArray(result.updatedAdCampaigns)) {
        toast.success("Daily budgets updated successfully")

        sessionStorage.setItem("updatedAdsetsRecently", "true")

        // Process updated campaigns
        result.updatedAdCampaigns.forEach(campaignData => {
          if (!campaignData || !campaignData.campaign || !campaignData.campaign.id) {
            console.warn("Invalid campaign data:", campaignData);
            return;
          }

          // Update campaign budget
          const campaignRow = tableData.find(row =>
            row.type === "campaign" && row.id === campaignData.campaign.id
          );

          if (campaignRow) {
            handleEdit(campaignData.campaign.id, {
              cardData: {
                ...campaignRow.cardData,
                "Ad Spend": {
                  ...campaignRow.cardData["Ad Spend"],
                  dailyBudget: campaignData.dailyBudget
                }
              }
            });
          } else {
            console.warn(`Could not find campaign with ID ${campaignData.campaign.id} in tableData`);
          }

          // Update adsets within this campaign
          if (campaignData.adsets && Array.isArray(campaignData.adsets) && campaignData.adsets.length > 0) {
            campaignData.adsets.forEach(adset => {
              if (!adset || !adset.id) {
                console.warn("Invalid adset data:", adset);
                return;
              }

              const adsetRow = tableData.find(row =>
                row.type === "adset" && row.id === adset.id
              );

              if (adsetRow) {
                handleEdit(adset.id, {
                  cardData: {
                    ...adsetRow.cardData,
                    "Ad Spend": {
                      ...adsetRow.cardData["Ad Spend"],
                      dailyBudget: adset.dailyBudget
                    }
                  },
                  // Also update audiences and advantage+ settings if they exist
                  ...(adset.audiences && { audiences: adset.audiences }),
                  ...(adset.hasOwnProperty('includeAdvantagePlusAudience') &&
                    { includeAdvantagePlusAudience: adset.includeAdvantagePlusAudience }),
                  ...(adset.excludedAudiences && { excludedAudiences: adset.excludedAudiences })
                });
              } else {
                console.warn(`Could not find adset with ID ${adset.id} in tableData`);
              }
            });
          }
        });
      } else {
        console.warn("No updated campaigns data received or invalid format");
        if (result === null) {
          // Modal was closed without a valid result
          return;
        }
        errorHandler({message: "Failed to update budgets: Invalid data received"});
      }
    } catch (error) {
      errorHandler({message: "Failed to update budgets. Please try again."});
    }
  }

  const toggleAdvantagePlusAudience = async ({
    wantsToActivate,
    adsetId,
    campaignId,
    adsetName,
  }) => {
    let confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      closeOnClickOutside: true,
      question: <span>Do you want to {wantsToActivate ? 'activate' : 'deactivate'} Advantage+ audience for <b>{adsetName}</b> ad set?</span>
    })

    if (confirmed) {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/toggleAdvantagePlusAudienceForAdset`,
        'POST',
        JSON.stringify({
          userId,
          fullFunnelId,
          campaignId,
          adsetId,
          includeAdvantagePlusAudience: wantsToActivate ? 1 : 0,
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${adminToken}`
        }
      )

      if (result) {
        toast.success(`Advantage+ audience ${wantsToActivate ? 'activated' : 'deactivated'} successfully`)
        // Update localEdits to reflect the changes
        handleEdit(adsetId, {
          includeAdvantagePlusAudience: wantsToActivate ? 1 : 0
        })
      }
    }
  }

  const editInterestsOfAdset = async ({
    adsetId,
    adsetName,
    campaignId,
    interests
  }) => {


  }

  const handleFullFunnelClick = ({ id, type }) => {
    if (type === 'funnel') {
      navigateWithAdminParams(`/ai-ads/full-funnel-campaigns?id=${id}`)
    }
  }

  const formatCampaignLabel = (label) => {
    if (!label) return ''

    let formattedLabel = label.replace(/^.*? - /, '')
    formattedLabel = formattedLabel.replace(/Enhencer\s*/gi, '')

    return formattedLabel
  }

  // Effect that runs when the page is first loaded
  useEffect(() => {
    if (initialLoad && scrollRef.current && selectedCards.length > 0) {
      // When page is refreshed, we don't auto-scroll, just set initialLoad to false
      setTimeout(() => {
        setInitialLoad(false);
      }, 500);
    }
  }, [initialLoad, selectedCards]);

  // Smooth scroll when a new metric table is added
  useEffect(() => {
    if (scrollRef.current) {
      // Compare previous and current selectedCards
      const prevSelectedCardsJSON = sessionStorage.getItem('prevSelectedCards');
      const prevSelectedCards = prevSelectedCardsJSON ? JSON.parse(prevSelectedCardsJSON) : [];

      // Find newly added cards
      const addedCards = selectedCards.filter(card => !prevSelectedCards.includes(card));

      // Only scroll right when a new card is added
      if (addedCards.length > 0) {
        scrollRef.current.scrollTo({
          left: scrollRef.current.scrollWidth,
          behavior: 'smooth'
        });
      }

      // Store current selected cards
      sessionStorage.setItem('prevSelectedCards', JSON.stringify(selectedCards));
    }
  }, [selectedCards]);

  // Function to handle local edits
  const handleEdit = (id, newData) => {
    setLocalEdits(prevEdits => {
      const existingEdit = prevEdits.find(edit => edit.id === id)
      if (existingEdit) {
        return prevEdits.map(edit => (edit.id === id ? { ...edit, ...newData } : edit))
      }
      return [...prevEdits, { id, ...newData }]
    })
  }

  if (!Object.keys(tableData).length) {
    return null
  }

  return (
    <div className="acsr-table-container">
      {isLoading && <div className='loading-overlay'>
        <InlineLoadingSpinner message="Loading..." />
      </div>}

      <div
        className={`acsr-tables-wrapper ${isDragging ? 'dragging' : ''}`}
        ref={scrollRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
        style={{ scrollBehavior: isDragging ? 'auto' : 'smooth' }}
      >
        {selectedCards?.length > 0 && (
          <div className="table-groups-container">
            {/* Name column */}
            <table className="acsr-name-table">
              <thead>
                <tr>
                  <th className="acsr-metric-header label-header">
                    {displayedTableData[0]?.type === 'funnel' ? 'Full-Funnel Name' : 'Campaign/Adset Name'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {displayedTableData.map((row, index) => (
                  <tr
                    key={row._id || `row-${index}`}
                    className={`acsr-table-row ${row.type} ${row.effectiveStatus === 'ACTIVE' ? 'active' : row.effectiveStatus === 'PAUSED' ? 'paused' : row.effectiveStatus === 'PENDING' ? 'pending' : row.effectiveStatus === 'ISSUE' ? 'issue' : row.effectiveStatus === 'INFO' ? 'info' : 'error'}`}
                    data-row-index={index}
                    data-type={row.type}
                    onMouseEnter={() => handleRowHover(index, true)}
                    onMouseLeave={() => handleRowHover(index, false)}
                    onClick={() => handleFullFunnelClick({
                      id: row.id,
                      type: row.type
                    })}
                    style={{ cursor: row.type === 'funnel' ? 'pointer' : 'default' }}
                  >
                    <td className="acsr-label-cell" title={row.label}>

                      <div className="label-with-status">
                        <span className="label-text">
                          {formatCampaignLabel(row.label)}
                        </span>
                        <Tooltip
                          className='status-tooltip'
                          content={`${row.type === 'campaign' ? 'Ad Campaign Status: ' : row.type === 'funnel' ? 'Full Funnel Status: ' : 'Ad Set Status: '}${getFormattedStatus(row.effectiveStatus || 'UNKNOWN')}`}
                        >
                          <StatusIndicator status={row.effectiveStatus} />
                        </Tooltip>
                      </div>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {selectedCards.map(cardGroup => {
              const groupClasses = {
                'Ad Spend': 'ad-spend',
                'Reach': 'reach',
                'Clicks': 'clicks',
                'Sales': 'sales',
                'Performance': 'performance',
              };

              const groupClass = groupClasses[cardGroup];

              return (
                <table key={cardGroup} className={`acsr-metric-table ${groupClass}-group`}>
                  <thead>
                    <tr>
                      {metricTitles[cardGroup].map(title => (
                        <th
                          key={title}
                          onClick={() => displayedTableData[0]?.type === 'funnel' && requestSort(title)}
                          className={`acsr-metric-header ${groupClass}`}
                        >
                          <Tooltip content={tooltipContents[title]}>
                            <span>{title}</span>
                            {displayedTableData[0]?.type === 'funnel' && sortConfig.key === title && (
                              <span className="sort-indicator">
                                {sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}
                              </span>
                            )}
                          </Tooltip>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {displayedTableData.map((row, index) => (
                      <tr
                        key={row._id || `row-${index}`}
                        className={`acsr-table-row ${row.type} ${row.effectiveStatus === 'ACTIVE' ? 'active' : row.effectiveStatus === 'PAUSED' ? 'paused' : row.effectiveStatus === 'PENDING' ? 'pending' : row.effectiveStatus === 'ISSUE' ? 'issue' : row.effectiveStatus === 'INFO' ? 'info' : 'error'}`}
                        data-row-index={index}
                        onMouseEnter={() => handleRowHover(index, true)}
                        onMouseLeave={() => handleRowHover(index, false)}
                      >
                        {Object.keys(row.cardData[cardGroup]).map(key => {
                          const value = row.cardData[cardGroup][key]
                          const needsCurrency = [
                            'totalSpend',
                            'dailyBudget',
                            'cpm',
                            'cpc',
                            'sales',
                            'costPerPurchase',
                          ].includes(key)
                          const needsPercentage = ['ctr'].includes(key)

                          return (
                            <td
                              key={`${row.label}-${cardGroup}-${key}`}
                              className={`acsr-metric-cell ${groupClass}`}
                              onClick={() => viewingAsUser && key === "dailyBudget" && row.type === "adset" && editDailyBudgetOfAdset({
                                adsetId: row.id,
                                adsetName: row.label,
                                campaignId: row.parentId,
                                audiences: row.audiences,
                                excludedAudiences: row.excludedAudiences
                              })}
                              style={{ cursor: viewingAsUser && key === "dailyBudget" && row.type === "adset" ? 'pointer' : 'default' }}
                            >
                              <span>
                                {needsCurrency && (
                                  <span className="currency">
                                    {currencySymbol}
                                  </span>
                                )}
                                {value}
                                {needsPercentage && (
                                  <span className="percentage">%</span>
                                )}
                              </span>
                              {
                                viewingAsUser && key === "dailyBudget" && row.type === "adset" &&
                                <Button className="edit-button" title="Edit Budget" textButton>
                                  <FaPen />
                                </Button>
                              }
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              );
            })}

            {viewingAsUser && fullFunnelId && (
              <table className="acsr-metric-table audiences">
                <thead>
                  <tr>
                    <th>
                      <div className="tooltip-container">
                        <div>Advantage+</div>

                      </div>
                    </th>
                    <th>
                      <div className="tooltip-container">
                        <div>Audiences</div>

                      </div>
                    </th>
                    <th>
                      <div className="tooltip-container">
                        <div>Exclusions</div>
                      </div>
                    </th>
                    <th>
                      <div className="tooltip-container">
                        <div>Interests</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayedTableData.map((row, index) => {
                    let displayedFlexibleSpec = []
                    let displayedFlexibleSpecString = '-'

                    if (row.flexibleSpec && row.flexibleSpec.length > 0) {
                      // Improved processing of flexible spec data
                      row.flexibleSpec.forEach(spec => {
                        let groupItems = []
                        Object.entries(spec).forEach(([key, value]) => {
                          // Add type information to each item for potential future use
                          const itemsWithType = value.map(item => ({
                            ...item,
                            type: key
                          }))
                          groupItems = groupItems.concat(itemsWithType)
                        })
                        displayedFlexibleSpec.push(groupItems)
                      })
                      
                      // Keep the original string format for the cell display
                      displayedFlexibleSpecString = displayedFlexibleSpec.map(group => 
                        group.map(i => i.name).join(', ')
                      ).join(' & ')
                    }

                    return (
                      <tr
                        key={row.id}
                        className={`acsr-table-row ${row.type}`}
                        data-row-index={index}
                        onMouseEnter={() => handleRowHover(index, true)}
                        onMouseLeave={() => handleRowHover(index, false)}
                      >
                        <td className="acsr-metric-cell">
                          <div className="audience-cell">
                            {row.type === 'adset' ? (
                              <div className="switch-wrapper">
                                <Switch
                                  disabled={row.label.includes("Advantage+")}
                                  size="small"
                                  options={['Yes', 'No']}
                                  value={row.includeAdvantagePlusAudience === 1 ? "Yes" : "No"}
                                  onChange={(newValue) => toggleAdvantagePlusAudience({
                                    adsetId: row.id,
                                    campaignId: row.parentId,
                                    adsetName: row.label,
                                    wantsToActivate: newValue === "Yes" ? true : false
                                  })}
                                />
                              </div>
                            ) : '-'}
                          </div>
                        </td>

                        <td className="acsr-metric-cell">
                          <Tooltip content={Array.isArray(row.audiences) && row.audiences?.length > 0 ? row.audiences.map(audience => <div style={{ width: '100%' }} key={audience.id}>· {audience.name}</div>) : '-'} position="bottom">
                            <div
                              className="audience-cell"
                              onClick={() => row.type === 'adset' && !row.label.includes("Advantage+") && editAudiencesOfAdset({
                                adsetId: row.id,
                                adsetName: row.label,
                                campaignId: row.parentId,
                                audiences: row.audiences,
                                excludedAudiences: row.excludedAudiences
                              })}
                              style={{ cursor: row.type === 'adset' && !row.label.includes("Advantage+") ? 'pointer' : 'default' }}
                            >
                              {row.type === 'adset' ? (
                                <>
                                  <div className='audience-list'>
                                    {Array.isArray(row.audiences) && row.audiences?.length > 0
                                      ? row.audiences.map(audience => audience.name).join(', ')
                                      : '-'
                                    }
                                  </div>
                                  <Button
                                    className="edit-button"
                                    textButton
                                    disabled={row.label.includes("Advantage+")}
                                  >
                                    <FaPen />
                                  </Button>
                                </>
                              ) : '-'}
                            </div>
                          </Tooltip>
                        </td>

                        <td className="acsr-metric-cell">
                          <div
                            className="audience-cell"
                            onClick={() => row.type === 'adset' && !row.label.includes("Advantage+") && editAudiencesOfAdset({
                              adsetId: row.id,
                              adsetName: row.label,
                              campaignId: row.parentId,
                              audiences: row.audiences,
                              excludedAudiences: row.excludedAudiences
                            })}
                            style={{ cursor: row.type === 'adset' && !row.label.includes("Advantage+") ? 'pointer' : 'default' }}
                          >
                            {row.type === 'adset' ? (
                              <>
                                <div>
                                  {Array.isArray(row.excludedAudiences) && row.excludedAudiences?.length > 0
                                    ? row.excludedAudiences.map(audience => audience.name).join(', ')
                                    : '-'
                                  }
                                </div>
                                <Button
                                  className="edit-button"
                                  textButton
                                  disabled={row.label.includes("Advantage+")}
                                >
                                  <FaPen />
                                </Button>
                              </>
                            ) : '-'}
                          </div>
                        </td>

                        <td className="acsr-metric-cell">
                          <Tooltip 
                            content={
                              row.flexibleSpec && row.flexibleSpec.length > 0 ? (
                                <div className="structured-tooltip">
                                  {displayedFlexibleSpec.map((group, groupIndex) => (
                                    <React.Fragment key={`group-${groupIndex}`}>
                                      <div style={{ marginBottom: '8px' }}>
                                        {group.map((item, itemIndex) => (
                                          <div key={`item-${groupIndex}-${itemIndex}`} style={{ padding: '2px 0' }}>
                                            • {item.name}
                                          </div>
                                        ))}
                                      </div>
                                      {groupIndex < displayedFlexibleSpec.length - 1 && (
                                        <div style={{ 
                                          margin: '10px 0', 
                                          textAlign: 'center', 
                                          fontWeight: 'bold' 
                                        }}>
                                          &amp;
                                        </div>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </div>
                              ) : '-'
                            } 
                            position="bottom"
                          >
                            <div
                              className="audience-cell"
                              onClick={() => row.type === 'adset' && !row.label.includes("Advantage+") && editInterestsOfAdset({
                                adsetId: row.id,
                                adsetName: row.label,
                                campaignId: row.parentId,
                                interests: row.flexibleSpec
                              })}
                              style={{ cursor: row.type === 'adset' && !row.label.includes("Advantage+") ? 'pointer' : 'default' }}
                            >
                              {row.type === 'adset' ? (
                                <>
                                  <div>
                                    {
                                      displayedFlexibleSpecString
                                    }
                                  </div>
                                  {/* <Button
                                    className="edit-button"
                                    textButton
                                    disabled={row.label.includes("Advantage+")}
                                  >
                                    <FaPen />
                                  </Button> */}
                                </>
                              ) : '-'}
                            </div>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}

          </div>
        )}
      </div>

    </div>
  )
}

export default CampaignReportTable