import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp4bg from '../../../../shared/assets/images/ai-creative/common_temp4bg.svg'

export const Template4Config = {
  name: 'Tech #1',
  containerId: 'template4-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp4bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#0056C6',
      draggable: false,
      clickThrough: true
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp4bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#0056C6',
      draggable: false,
      clickThrough: true
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height - 180
      },
      width: 600,
      height: 200,
      fontFamily: 'Readex Pro',
      fontSize: 60,
      fontStyle: 'bold',
      color: 'color2',
      text: '{{productName}}'
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height - 300
      },
      width: 600,
      text: '30% OFF',
      fontFamily: 'Readex Pro',
      fontSize: 101.3688,
      color: 'color2',
      fontIndex: 1
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 200 - 310 / 2,
        y: WORKBENCH_SIZE.height - 120 - 240 / 2,
      },
      width: 310,
      text: 'USE CODE: GET30',
      fontFamily: 'Readex Pro',
      fontSize: 60,
      color: 'color2',
      letterSpacing: 4,
      center: true,
      align: 'center',
      verticalAlign: 'middle',
      fontIndex: 2
    }
  ]
}