import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AgenciesUserCard from './AgenciesUserCard'

import './AgencyUsersList.css'
import helper from '../../../shared/util/helper'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import { adminActions } from '../../../store/admin'

const AgencyUsersList = props => {
  const { lastCursorCustomers, navigatedFromCustomerID, agenciesUsers } = useSelector(state => state.admin)


  const dispatch = useDispatch()
  const ref = useRef()
  const [isFetching, setIsFetching] = useState(props.isFetching)
  const bottomDetector = () => {
    if (helper.isElementInViewport(ref.current) && !isFetching) {
      setIsFetching(true)
      if (lastCursorCustomers) {
        props.reFetch()
      } else {
        setIsFetching(false)
      }
    }
  }
  useEffect(() => {
    if (navigatedFromCustomerID) {
      const customerCard = document.querySelector("[data-id='" + navigatedFromCustomerID + "']")
      if (customerCard) {
        customerCard.scrollIntoView({ behavior: "smooth" })
        // block: "end"
        //görevini yerine getirdi kaldırıyoruz
        dispatch(adminActions.setNavigatedFromCustomerID(undefined))
      }
    }
  }, [])

  useEffect(() => {
    setIsFetching(props.isFetching)
  }, [props.isFetching])

  useEffect(() => {
    if (!lastCursorCustomers && agenciesUsers?.length) {
      //customers var, lastCursor yok, yani sondayız
      ref.current.style.display = "none"
    } else {
      //ilk çekerken ve aradaki pagination isteklerinde
      ref.current.style.display = "flex"
    }
  }, [lastCursorCustomers])

  return (
    <div className="agency-users-list-wrapper" onScroll={bottomDetector}>
      <div className="agency-users-list">
        {agenciesUsers && Object.keys(agenciesUsers).map(key => {
          const user = agenciesUsers[key]
          const dayDifference = parseInt(user.lifetimeInDays)
          const connectionsCompletedInDays = parseInt(user.connectionsCompletedInDays)
          const fullFunnelCampaignStartedInDays = parseInt(user.fullFunnelCampaignStartedInDays)
          const weeklyReports = user.facebookAds?.weeklyReports
          const currency = user.facebookAds?.currency
          const daysPassedMapping = user.mapping

          return (
            <AgenciesUserCard
              user={user}
              key={user.id}
              dayDifference={dayDifference}
              daysPassedMapping={daysPassedMapping}
              connectionsCompletedInDays={connectionsCompletedInDays}
              fullFunnelCampaignStartedInDays={fullFunnelCampaignStartedInDays}
              weeklyReports={weeklyReports}
              currency={currency}
            />
          )
        })}
      </div>
      <div className='scroll-ending-box' ref={ref}>
        {isFetching && <InlineLoadingSpinner message="Fetching..." />}
      </div>
    </div>
  )
}

export default AgencyUsersList
