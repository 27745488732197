import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRight, FaSyncAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { useFacebookCampaignEditor } from '../../../../../../shared/hooks/facebook/campaign-editor-hook'
import useNavigator from '../../../../../../shared/hooks/navigator-hook'
import useAICreatives from '../../../../../../shared/hooks/ai-creatives-hook'
import { VALIDATOR_REQUIRE } from '../../../../../../shared/util/validators'
import imageExpander from '../../../../../../shared/util/imageExpander'
import Button from '../../../../../../shared/components/FormElements/Button'
import InlineLoadingSpinner from '../../../../../../shared/components/UIElements/InlineLoadingSpinner'
import StrokeInput from '../../../../../../shared/components/FormElements/StrokeInput'
import CustomDropdown from '../../../CustomDropdown'
import AdTextGenerator from '../../../AdTextGenerator'
import { useFacebook } from '../../../../../../shared/hooks/facebook-hook'

import './AICreativeMaker.css'

const AICreativeMaker = () => {
  const { navigateWithParams } = useNavigator()

  const queryParams = new URLSearchParams(window.location.search)
  const existingCampaignId = queryParams.get('id')
  const aiCreativeIdInUrlParams = queryParams.get('aiCreativeId')

  const facebook = useSelector(state => state.facebook)
  const user = useSelector(state => state.auth.user)

  const { isFetchingFacebookPages } = useSelector(state => state.facebookCampaignEditor.loadings)

  const selectedActiveFacebookPage = useSelector(state => state.facebook.selectedActiveFacebookPage)
  const selectedActiveInstagramAccount = useSelector(state => state.facebook.selectedActiveInstagramAccount)

  const currentCreative = useSelector(state => state.facebookCampaignEditor.currentCampaign?.currentCreative)
  const details = currentCreative?.details ?? {}

  const {
    facebookPage,
    instagramAccount,
    aiCreativeId: aiCreativeIdInRedux,
  } = details ?? {}

  const {
    newFacebookPageSelected,
    newInstagramAccountSelected,
    aiCreativeFormTextFieldsUpdated,
    catalogCreativeFormTextFieldsUpdated,
    startLoadingState,
    endLoadingState,

    addAICreativeToCurrentCreative,
    aiCreativeProductsUpdated,
    aiCreativeEnhencedCatalogDetailsUpdated,
  } = useFacebookCampaignEditor()

  const { getFacebookPages, getInstagramAccountsForPage } = useFacebook()

  const { getById, enhencedCatalogDetails } = useAICreatives()

  const [existingAICreative, setExistingAICreative] = useState(null)
  const [textUpdateTimer, setTextUpdateTimer] = useState()
  const [primaryText, setPrimaryText] = useState()
  const [websiteUrl, setWebsiteUrl] = useState()

  const aiCreativeProducts = useMemo(() => {
    if (existingAICreative) {
      return existingAICreative.products
    }
    return []
  }, [existingAICreative])

  const aiCreativeCarouselReady = useMemo(() => {
    return (
      aiCreativeProducts?.length > 0 &&
      aiCreativeProducts.every(product => Boolean(product.preview))
    )
  }, [aiCreativeProducts])

  const goToAICreativeLab = () => {
    navigateWithParams('/ai-ads/creative-lab', {
      campaignId: existingCampaignId,
    })
  }

  const fetchAICreativeDetails = async id => {
    const newAICreative = await getById(id)
    setExistingAICreative(newAICreative)
    let aiCreativeId = newAICreative._id || newAICreative.id

    const isEnhencedCatalog = Boolean(newAICreative.enhencedCatalogDetails)

    if (!isEnhencedCatalog && currentCreative.details.aiCreativeId !== aiCreativeId) {
      aiCreativeProductsUpdated({ aiCreativeProducts: newAICreative.products })
    }

    if (isEnhencedCatalog) {
      aiCreativeEnhencedCatalogDetailsUpdated({
        enhencedCatalogDetails: newAICreative.enhencedCatalogDetails,
      })
    }
  }

  const refreshFacebookPages = async () => {
    startLoadingState('isFetchingFacebookPages')
    await getFacebookPages({
      facebookUserId: facebook.auth.id,
      accessToken: facebook.auth.accessToken,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingFacebookPages')
  }

  const refreshInstagramAccounts = async () => {
    startLoadingState('isFetchingInstagramAccounts')
    await getInstagramAccountsForPage({
      pageId: facebookPage?.id,
      pageAccessToken: facebookPage?.access_token,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingInstagramAccounts')
  }

  // Add image expander listeners when carousel is ready
  useEffect(() => {
    if (!aiCreativeCarouselReady) return

    imageExpander.handleImages()

    return () => {
      imageExpander.removeListeners()
    }
  }, [aiCreativeCarouselReady])

  useEffect(() => {
    if (!aiCreativeIdInUrlParams && !aiCreativeIdInRedux) return


    const existsInUrlButNotInRedux =
      aiCreativeIdInUrlParams && !aiCreativeIdInRedux
    const reduxAndUrlAreDifferent =
      aiCreativeIdInUrlParams &&
      aiCreativeIdInRedux &&
      aiCreativeIdInUrlParams !== aiCreativeIdInRedux

    if (existsInUrlButNotInRedux || reduxAndUrlAreDifferent) {
      addAICreativeToCurrentCreative(aiCreativeIdInUrlParams)
      return
    }

    const fetchCreative = async () => {
      await fetchAICreativeDetails(aiCreativeIdInRedux)
    }

    fetchCreative()
  }, [aiCreativeIdInRedux, aiCreativeIdInUrlParams])

  useEffect(() => {
    if (textUpdateTimer) {
      clearTimeout(textUpdateTimer)
    }

    if (
      primaryText &&
      websiteUrl &&
      (primaryText !== details.primaryText || websiteUrl !== details.websiteUrl)
    ) {
      startLoadingState('isProcessingTextFields')
      let timer = setTimeout(() => {
        if (currentCreative?.details?.catalog?.id) {
          catalogCreativeFormTextFieldsUpdated({
            primaryText: primaryText,
            websiteUrl: websiteUrl,
          })
        } else {
          aiCreativeFormTextFieldsUpdated({
            primaryText: primaryText,
            websiteUrl: websiteUrl,
            aiCreativeProducts: aiCreativeProducts,
          })
        }
        endLoadingState('isProcessingTextFields')
      }, 3000)
      setTextUpdateTimer(timer)
    }

    return () => {
      clearTimeout(textUpdateTimer)
    }
  }, [primaryText, websiteUrl])

  useEffect(() => {
    setWebsiteUrl(
      details?.websiteUrl ||
        (!user.url.includes('https://') ? `https://${user.url}` : user.url),
    )
    setPrimaryText(details?.primaryText)
  }, [])

  return (
    <React.Fragment>
      {!enhencedCatalogDetails && (
        <div className="row input-container-row">
          {!aiCreativeCarouselReady ? (
            <div className="row input-container-row">
              <Button primary={true} onClick={goToAICreativeLab}>
                Go to AI Creative Lab ✨
              </Button>
            </div>
          ) : (
            <>
              <label>Carousel</label>
              <div>
                <div className="ai-creative-images">
                  {aiCreativeProducts.map((product, index) => (
                    <div key={index} className="ai-creative-image">
                      <img
                        className="expandable ai-creative-image-preview"
                        src={product.preview.perma_url || product.preview.url}
                        alt="AI Creative"
                      />
                    </div>
                  ))}
                </div>
                <div className="ai-creative-images-actions">
                  <Button
                    className="ai-creative-images-action-button"
                    size="small"
                    onClick={goToAICreativeLab}
                  >
                    Back to Lab
                    <FaArrowRight className="icon" />
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <div className="row input-container-row">
        <label>Facebook Page</label>

        {isFetchingFacebookPages ? (
          <InlineLoadingSpinner
            size="xs"
            message="Fetching pages"
          ></InlineLoadingSpinner>
        ) : facebook.pages.length ? (
          <CustomDropdown
            placeholder="Select page..."
            initialValue={selectedActiveFacebookPage}
            forcedValue={facebookPage}
            onChange={p => newFacebookPageSelected({ facebookPage: p })}
            options={facebook.pages}
          />
        ) : (
          <p className="error-text dropdown">
            No page found. Please try selecting{' '}
            <Link to="/config?io=3">another ad account</Link> or visit{' '}
            <a
              href={`https://business.facebook.com/settings/pages?business_id=${facebook?.selectedBusiness?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Meta Business Settings
            </a>{' '}
            to create a page.
          </p>
        )}

        {facebook.pages ? (
          <Button
            onClick={refreshFacebookPages}
            size="small"
            className="refresh-button"
            title="Refresh pages list"
          >
            <FaSyncAlt className="icon" />
          </Button>
        ) : null}
      </div>

      <div className="row input-container-row">
        <label>
          Instagram <br /> Account
        </label>

        {facebookPage ? (
          <>
            {facebook.instagramAccounts[facebookPage?.id] &&
            facebook.instagramAccounts[facebookPage?.id].length ? (
              <CustomDropdown
                placeholder="Select account..."
                initialValue={selectedActiveInstagramAccount}
                forcedValue={instagramAccount}
                onChange={i =>
                  newInstagramAccountSelected({ instagramAccount: i })
                }
                options={facebook.instagramAccounts[facebookPage?.id]}
              />
            ) : (
              <p className="error-text dropdown">
                No connected Instagram account found.
              </p>
            )}
            {facebook.instagramAccounts ? (
              <Button
                onClick={refreshInstagramAccounts}
                size="small"
                className="refresh-button"
                title="Refresh Instagram accounts list"
              >
                <FaSyncAlt className="icon" />
              </Button>
            ) : null}
          </>
        ) : (
          <p className="error-text dropdown">Please select a Facebook page</p>
        )}
      </div>

      <div className="row input-container-row no-margin">
        <StrokeInput
          leftTitle
          element="input"
          type="text"
          label="Website URL"
          id="websiteUrl"
          placeholder="https://yourwebsite.com"
          value={websiteUrl}
          onChange={setWebsiteUrl}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={!websiteUrl ? 'Please enter a redirection page' : null}
        />
      </div>

      <div className="row input-container-row no-margin">
        <StrokeInput
          leftTitle
          element="textarea"
          label="Ad Caption"
          id="primaryText"
          rows={4}
          placeholder="Unlock the Future with Our Innovative Solution!"
          value={primaryText}
          onChange={setPrimaryText}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={!primaryText ? 'Please enter a primary text' : null}
        />
      </div>

      <AdTextGenerator
        selectedAdAccountId={facebook.selectedAdAccount.id}
        selectARecommendation={setPrimaryText}
      />
    </React.Fragment>
  )
}

export default AICreativeMaker
