import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { generalActions } from '../../store/general'
import {
  paymentRequiringRoutes,
  updateUserSubscription,
} from '../util/subscription'
import { useHttpClient } from './http-hook'
import { useModal } from './modal-hook'
import Modals from '../enums/Modals'

export const useSubscriptionGuard = () => {
  const { openModal } = useModal()
  const [accountSuspended, setAccountSuspended] = useState(false)
  const { token: authToken, user } = useSelector(state => state.auth)
  const bottomNotification = useSelector(
    state => state.general.bottomNotification,
  )
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser'))

  useEffect(() => {
    if (!user) {
      return
    }

    if (
      user.isSpecialUser ||
      window.location.pathname.includes('subscription') ||
      window.location.pathname.includes('payment')
    ) {
      return
    }


    if (!user.subscription) {
      handleNoSubscription()
      return
    }

    switch (user.subscription.status) {
      case 'payment-error':
        handlePaymentError()
        break

      case 'suspended':
        handleAccountSuspended()
        break

      case 'passive':
        handleNoSubscription()
        break

      default:
        break
    }
  }, [window.location.pathname])

  /**
   * Determines whether to show a payment error warning with the remaining days,
   * or suspend the user's account.
   */
  function handlePaymentError() {
    // Calculate remaining days within a 10-day grace period for completing subscription payment.
    const days = 10 - parseInt((Date.now() - user.subscription.nextPaymentDate) / 1000 / 60 / 60 / 24)

    if (
      days > 0 &&
      !bottomNotification.display &&
      !window.location.pathname.includes('/connect')
    ) {
      dispatch(
        generalActions.setBottomNotification({
          display: true,
          className: 'red',
          type: 'payment_warning',
          dynamicData: {
            days,
          },
        }),
      )
    } else {
      const subscriptionDetails = {
        ...user.subscription,
        status: 'suspended',
      }

      setAccountSuspended(true)

      updateUserSubscription(user, subscriptionDetails, {
        sendRequest,
        dispatch,
        authToken,
      })
    }
  }

  /**
   * Displays the 'account suspended' modal.
   */
  function handleAccountSuspended() {
    setAccountSuspended(true)
    if (!viewingAsUser || viewingAsUser?.userRole === 'Agency') {
      const existingModal = document.querySelector('.payment-required');
      if (!existingModal) {
        openModal({
          type: Modals.ACCOUNT_SUSPENDED,
          closeOnClickOutside: false,
        })
      }
    }
  }

  /**
   * We manage access to the payment-required routes and also display
   * a notification bar when the conditions are met.
   */
  function handleNoSubscription() {
    manageSubscriptionRoutesAccess()

    const d = new Date(user?.createdAt)
    const timeDifferenceInDays =
      (Date.now() - d.valueOf()) / (1000 * 60 * 60 * 24)

    if (
      timeDifferenceInDays > 2 &&
      !window.location.pathname.includes('subscription') &&
      !window.location.pathname.includes('/connect') &&
      !bottomNotification.display
    ) {
      dispatch(
        generalActions.setBottomNotification({
          display: true,
          className: 'yellow',
          type: 'store_connection',
        }),
      )
    }
  }

  /**
   * Manages access to subscription-required routes.
   */
  function manageSubscriptionRoutesAccess() {
    paymentRequiringRoutes.forEach(route => {
      if (window.location.pathname.includes(route) && user) {
        openModal({
          type: Modals.PAYMENT_REQUIRED,
          closeOnClickOutside: false,
        })
        return
      }
    })
  }

  return {
    isAccountSuspended: accountSuspended,
  }
}
