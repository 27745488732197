import React, { useState, useEffect } from 'react';
import { useModal } from '../../hooks/modal-hook';
import './ComparisonMultipleModal.css';
import { useHttpClient } from '../../hooks/http-hook';
import { useSelector } from 'react-redux';
import CampaignReportComparison from '../../../ai-ads/components/report/overall-report/CampaignReportComparison';
import ReportComparisonTypes from '../../enums/ReportComparisonTypes';



const ComparisonMultipleModal = () => {
	const { modalData } = useModal();
	const { currency, initialTab = ReportComparisonTypes.TREND_ANALYSIS, userId, popupUserFacebookAds, username } = modalData;
	const [facebookInfo, setFacebookInfo] = useState(null);
	const { sendRequest } = useHttpClient();
	const auth = useSelector(state => state.auth);

	useEffect(() => {
		const getFacebookInfo = async () => {
			const response = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/getFacebookInfoForComparisonData/${userId}`,
				'GET',
				null,
				{
					Authorization: 'Bearer ' + auth.token,
				},
			)

			setFacebookInfo(response.facebookInfo)
		}
		if (userId) {
			getFacebookInfo()
		}
	}, [userId])
	return facebookInfo ? <div className="comparison-multiple-modal">
		<h1>{username}</h1>
		<CampaignReportComparison
			initialTab={initialTab}
			currency={currency}
			popupUserFacebookAds={popupUserFacebookAds}
			popupUserFacebookInfo={facebookInfo} />
	</div> : null;
};

export default ComparisonMultipleModal;
