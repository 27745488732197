import React, { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

import gradientMaker from '../../../shared/util/gradientMaker'
import numberFormatter from '../../../shared/util/numberFormatter'
import FluidCard from '../../../shared/components/UIElements/FluidCard'
import Switch from '../../../shared/components/UIElements/Switch'

import './SummaryOfAudiences.css'

const SummaryOfAudiences = () => {
  const enhencerAudienceChartRef = useRef()
  const bAudienceChartRef = useRef()
  const [expectedLiveSwitch, setExpectedLiveSwitch] = useState('expected')
  const [selectedChartVariable, setSelectedChartVariable] =
    useState('Conversion Rate')
  const campaign = useSelector(state => state.project.currentCampaign)

  const chartOptions = {
    responsive: true,
    tension: 0.4,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        min: 0,
      },
    },
  }

  const [enhencerAudienceChartData, setEnhencerAudienceChartData] = useState({
    options: chartOptions,
    labels: campaign.audience.expectedLabels,
    datasets: [
      {
        label: selectedChartVariable,
        data: campaign.audience.expectedConvRateData,
        backgroundColor: 'transparent',
        lineWidth: 0,
        borderColor: '#6aae9e',
        fill: true,
        pointRadius: 0,
        pointHitRadius: 10,
        pointHoverRadius: 3,
        pointBorderWidth: window.innerWidth > 1400 ? 3 : 2,
        pointBackgroundColor: '#6aae9e',
        pointHoverBackgroundColor: '#6aae9e',
      },
    ],
  })

  const [bAudienceChartData, setBAudienceChartData] = useState({
    options: chartOptions,
    labels: campaign.audience.allVisitorsLabels,
    datasets: [
      {
        label: selectedChartVariable,
        data: campaign.audience.allVisitorsConvRateData,
        backgroundColor: 'transparent',
        lineWidth: 0,
        borderColor: '#d17459',
        fill: true,
        pointRadius: 0,
        pointHitRadius: 10,
        pointHoverRadius: 3,
        pointBorderWidth: window.innerWidth > 1400 ? 3 : 2,
        pointBackgroundColor: '#d17459',
        pointHoverBackgroundColor: '#d17459',
      },
    ],
  })

  useEffect(() => {
    if (!enhencerAudienceChartRef.current || !bAudienceChartRef.current) {
      return
    }

    let chartYMaxValue = 0
    let newOptions = { ...chartOptions }

    const enhencerChartDatasetsCopy =
      enhencerAudienceChartData.datasets.slice(0)
    enhencerChartDatasetsCopy[0].backgroundColor = gradientMaker(
      enhencerAudienceChartRef.current.ctx,
      '#d9eeeb',
      100,
    )
    enhencerChartDatasetsCopy[0].label = selectedChartVariable

    let data
    let labels

    if (expectedLiveSwitch === 'expected') {
      labels = campaign.audience.expectedLabels

      if (selectedChartVariable === 'Conversion Rate') {
        let values = campaign.audience.expectedConvRateData.map(i => i * 100)

        let maxValue = Math.max(...values)

        if (maxValue >= chartYMaxValue) {
          chartYMaxValue = maxValue
          newOptions.scales.y.max = maxValue
        }

        data = values
      } else {
        let maxValue = Math.max(...campaign.audience.expectedNumOfVisitorsData)

        if (maxValue >= chartYMaxValue) {
          chartYMaxValue = maxValue
          newOptions.scales.y.max = maxValue
        }

        data = campaign.audience.expectedNumOfVisitorsData
      }
    } else {
      labels = campaign.audience.liveLabels

      if (selectedChartVariable === 'Conversion Rate') {
        let values = campaign.audience.liveConvRateData.map(i => i * 100)
        let maxValue = Math.max(...values)

        if (maxValue >= chartYMaxValue) {
          chartYMaxValue = maxValue
          newOptions.scales.y.max = maxValue
        }

        data = values
      } else {
        let maxValue = Math.max(...campaign.audience.liveNumOfVisitorsData)

        if (maxValue >= chartYMaxValue) {
          chartYMaxValue = maxValue
          newOptions.scales.y.max = maxValue
        }

        data = campaign.audience.liveNumOfVisitorsData
      }
    }

    enhencerChartDatasetsCopy[0].data = data

    setEnhencerAudienceChartData(
      Object.assign({}, enhencerAudienceChartData, {
        labels: labels,
        datasets: enhencerChartDatasetsCopy,
        options: newOptions,
      }),
    )

    const bAudienceDataSetsCopy = bAudienceChartData.datasets.slice(0)
    bAudienceDataSetsCopy[0].backgroundColor = gradientMaker(
      bAudienceChartRef.current.ctx,
      '#f3e2dc',
      100,
    )
    bAudienceDataSetsCopy[0].label = selectedChartVariable

    let allVisitorsdata

    if (selectedChartVariable === 'Conversion Rate') {
      allVisitorsdata = campaign.audience.allVisitorsConvRateData.map(
        i => i * 100,
      )

      let maxValue = Math.max(...allVisitorsdata)

      if (maxValue >= chartYMaxValue) {
        chartYMaxValue = maxValue
        newOptions.scales.y.max = maxValue
      }
    } else {
      let maxValue = Math.max(...campaign.audience.allVisitorsNumOfVisitorsData)

      if (maxValue >= chartYMaxValue) {
        chartYMaxValue = maxValue
        newOptions.scales.y.max = maxValue
      }

      allVisitorsdata = campaign.audience.allVisitorsNumOfVisitorsData
    }

    bAudienceDataSetsCopy[0].data = allVisitorsdata

    setBAudienceChartData(
      Object.assign({}, bAudienceChartData, {
        datasets: bAudienceDataSetsCopy,
        options: newOptions,
      }),
    )
  }, [
    expectedLiveSwitch,
    selectedChartVariable,
    enhencerAudienceChartRef,
    bAudienceChartRef,
  ])

  return (
    <FluidCard className="summary-of-audiences">
      <FluidCard.Header>
        <div className="header-wrapper">
          <div className="title">Summary of Audiences</div>
          <div className="dropdown">
            <select>
              <option value="0">Last 30 Days</option>
              <option value="1">Last 3 Months</option>
              <option value="2">Last 6 Months</option>
            </select>
          </div>
        </div>
      </FluidCard.Header>
      <FluidCard.Content>
        <div className="charts-container">
          <div className="card-container">
            <FluidCard className="enhencer-audience">
              <FluidCard.Header>Enhencer Remarketing Audience</FluidCard.Header>
              <FluidCard.Content>
                <div className="content-wrapper">
                  <Line
                    ref={enhencerAudienceChartRef}
                    options={enhencerAudienceChartData.options}
                    data={enhencerAudienceChartData}
                  />
                  <div className="stats">
                    <span
                      className={
                        selectedChartVariable === 'Conversion Rate'
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setSelectedChartVariable('Conversion Rate')
                      }}
                    >
                      {(expectedLiveSwitch === 'expected'
                        ? campaign.audience.expected.propensity * 100
                        : campaign.audience.live.propensity * 100
                      ).toFixed(2)}
                      <span className="symbol">%</span>
                    </span>
                    <span
                      className={
                        selectedChartVariable === 'Number of Visitors'
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setSelectedChartVariable('Number of Visitors')
                      }}
                    >
                      {numberFormatter.formatNumber(
                        expectedLiveSwitch === 'expected'
                          ? campaign.audience.expected.size
                          : campaign.audience.live.size,
                      )}
                    </span>
                    <span>
                      {expectedLiveSwitch === 'expected'
                        ? (
                            campaign.audience.expected.propensity /
                            campaign.audience.overall.propensity
                          ).toFixed(1)
                        : (
                            campaign.audience.live.propensity /
                            campaign.audience.overall.propensity
                          ).toFixed(1)}
                      <span className="symbol">x</span>
                    </span>
                  </div>
                </div>
                <div className="switch-container">
                  <Switch
                    options={['expected', 'live']}
                    value={expectedLiveSwitch}
                    onChange={setExpectedLiveSwitch}
                  />
                </div>
              </FluidCard.Content>
            </FluidCard>
          </div>
          <div className="chart-options">
            <div
              className={
                selectedChartVariable === 'Conversion Rate'
                  ? 'active option'
                  : 'option'
              }
              onClick={() => {
                setSelectedChartVariable('Conversion Rate')
              }}
            >
              Conversion Rate
            </div>
            <div
              className={
                selectedChartVariable === 'Number of Visitors'
                  ? 'active option'
                  : 'option'
              }
              onClick={() => {
                setSelectedChartVariable('Number of Visitors')
              }}
            >
              # of Visitors
            </div>
            <div className="option disabled">Conversion Uplift</div>
          </div>
          <div className="card-container">
            <FluidCard className="b-audience">
              <FluidCard.Header>All Visitors</FluidCard.Header>
              <FluidCard.Content>
                <div className="content-wrapper">
                  <div className="stats">
                    <span
                      className={
                        selectedChartVariable === 'Conversion Rate'
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setSelectedChartVariable('Conversion Rate')
                      }}
                    >
                      {(campaign.audience.overall.propensity * 100).toFixed(2)}
                      <span className="symbol">%</span>
                    </span>
                    <span
                      className={
                        selectedChartVariable === 'Number of Visitors'
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setSelectedChartVariable('Number of Visitors')
                      }}
                    >
                      {numberFormatter.formatNumber(
                        campaign.audience.overall.count,
                      )}
                    </span>
                    <span>
                      1.0<span className="symbol">x</span>
                    </span>
                  </div>
                  <Line
                    ref={bAudienceChartRef}
                    options={bAudienceChartData.options}
                    data={bAudienceChartData}
                  />
                </div>
              </FluidCard.Content>
            </FluidCard>
          </div>
        </div>
      </FluidCard.Content>
    </FluidCard>
  )
}

export default SummaryOfAudiences