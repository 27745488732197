import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  FaUser,
  FaCog,
  FaCreditCard,
  FaListAlt,
  FaAngleRight,
  FaAngleLeft,
  FaEnvelope,
} from 'react-icons/fa'
import { authActions } from '../../store/auth'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'

import {
  NavItems,
  Sidebar,
  SidebarHeader,
} from '../../shared/components/Navigation/Sidebar'

import './ProfilePageLayout.css'

const ProfilePageLayout = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const user = useSelector(state => state.auth.user)
  const isSpecialUser = user?.isSpecialUser || false

  const getBreadcrumbTitle = () => {
    const path = location.pathname
    if (path.includes('/account/info')) return 'Info'
    if (path.includes('/account/tickets')) return 'Support Tickets'
    if (path.includes('/account/subscription')) return 'Subscription'
    if (path.includes('/account/industry-selection')) return 'Industry Selection'
    if (path.includes('/account/settings')) return 'Account Settings'
    return 'Account Management'
  }

  useEffect(() => {
    if (isSpecialUser) {
      const handleResize = () => {
        setIsExpanded(window.innerWidth >= 768)
      }

      window.addEventListener('resize', handleResize)
      handleResize() // İlk yükleme için çağır

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [isSpecialUser])

  const toggleSidebar = () => {
    if (isSpecialUser) {
      setIsExpanded(!isExpanded)
    }
  }

  const navItems = [
    {
      to: '/account/info',
      title: 'Info',
      icon: <FaUser />,
    },
    {
      to: '/account/tickets',
      title: 'Support Tickets',
      icon: <FaEnvelope />,
    },
    {
      to: '/account/subscription',
      title: 'Subscription',
      icon: <FaCreditCard />,
    },
    {
      to: '/account/industry-selection',
      title: 'Industry Selection',
      icon: <FaListAlt />,
    },
    {
      to: '/account/settings',
      title: 'Account Settings',
      icon: <FaCog />,
    },
  ]

  return (
    <div
      className={`profile ${isSpecialUser && !isExpanded ? 'sidebar-collapsed' : ''}`}
    >
      {!isSpecialUser && (
        <div className="breadcrumb-container">
          <Breadcrumb>
            <span>Account Management</span>
            <span>{getBreadcrumbTitle()}</span>
          </Breadcrumb>
        </div>
      )}
      <div className="profile-sidebar-wrapper">
        <Sidebar isOpen={!isSpecialUser || isExpanded}>
          <SidebarHeader>Account Management</SidebarHeader>
          <NavItems items={navItems} />
          {isSpecialUser && (
            <button className="sidebar-toggle-button" onClick={toggleSidebar}>
              {isExpanded ? <FaAngleLeft /> : <FaAngleRight />}
            </button>
          )}
        </Sidebar>
      </div>
      <div className="profile-content-wrapper">{children}</div>
    </div>
  )
}

const Header = ({ children }) => {
  return (
    <div className="profile-header">
      <h1>{children}</h1>
      <div className="line"></div>
    </div>
  )
}

const Content = ({ children }) => {
  return <div className="profile-content">{children}</div>
}

ProfilePageLayout.Header = Header
ProfilePageLayout.Content = Content

export default ProfilePageLayout
