import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './ProcessBar.css';

const ProcessBar = () => {

  const [progress, setProgress] = useState(0)
  const [currentDay, setCurrentDay] = useState(0)

  const totalDays = 60
  const userSubscription = useSelector(state => state.auth.user?.subscription)
  const userCrmDetails = useSelector(state => state.auth.user?.crmDetails)


  useEffect(() => {
    if (!userSubscription || userSubscription.status !== "active") {
      return
    }

    const startDate = userSubscription?.createdDate ? new Date(userSubscription?.createdDate) : userCrmDetails?.subscription?.contractStartDate

    const c = Math.floor((new Date() - startDate) / (1000 * 60 * 60 * 24))
    setCurrentDay(c)
    const p = Math.max(0, Math.min(100,
      (c / totalDays) * 100
    ));

    setProgress(p)


  }, [userSubscription, userCrmDetails])

  if (!userSubscription || userSubscription.status !== "active") {
    return null
  }

  const renderDayMarker = (day) => {
    if (day < 4) {
      return day;
    }
    return `Day ${day}`;
  };

  return currentDay > 0 && currentDay <= 60 ? (
    <div className="process-bar-container">
      <div className="day-markers">
        <span className="day-label start">Day 0</span>
        <span className="day-label middle">Day 30</span>
        <span className="day-label end">Day 60</span>
      </div>
      <div className="process-bar-wrapper">
        <div className="process-bar-segments">
          <div className="segment preparation">
            <div className="process-bar-fill" style={{ width: `${progress <= 50 ? progress * 2 : 100}%` }}>
              {progress <= 50 && (
                <div className="current-day-marker">
                  {renderDayMarker(currentDay)}
                </div>
              )}
            </div>
          </div>
          <div className="segment optimization">
            <div className="process-bar-fill" style={{ width: `${progress > 50 ? (progress - 50) * 2 : 0}%` }}>
              {progress > 50 && (
                <div className="current-day-marker">
                  {renderDayMarker(currentDay)}
                </div>
              )}
            </div>
          </div>
          <div className="animation-container">
            <div className="liquid-animation"></div>
          </div>
        </div>
        <div className="phase-labels">
          <div className="phase-label">Month 1: Preparation</div>
          <div className="phase-label">Month 2: A-B Tests & Optimization</div>
        </div>
      </div>
    </div>
  ) : null
};

export default ProcessBar;