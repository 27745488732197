import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import '../CampaignReportComparison.css'
import helper from '../../../../../shared/util/helper'
import numberFormatter from '../../../../../shared/util/numberFormatter'

const DualLineChart = ({ title, data, labels, prefix, suffix }) => {
	const chartRef = useRef(null);
	const chartInstanceRef = useRef(null);

	useEffect(() => {
		if (chartRef.current) {
			const ctx = chartRef.current.getContext('2d');

			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}

			chartInstanceRef.current = new Chart(ctx, {
				type: 'line',
				data: {
					labels,
					datasets: [
						{
							label: 'Enhencer AI Ads',
							data: data.enhencer,
							borderColor: '#85ca6e',
							backgroundColor: '#85ca6e',
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							hoverRadius: 8,
							pointBackgroundColor: '#85ca6e',
						},
						...(data.others ? [{
							label: 'Other Campaigns',
							data: data.others,
							borderColor: '#94959b',
							backgroundColor: 'rgba(148, 149, 155, 0.1)',
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							hoverRadius: 8,
							pointBackgroundColor: '#94959b',
						}] : [])
					]
				},
				options: {
					interaction: {
						mode: 'index',
						intersect: false,
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: true,
							position: 'top',
							align: 'end',
							labels: {
								usePointStyle: true,
								pointStyle: 'circle',
								padding: 20,
								color: '#1a1a1a'
							}
						},
						tooltip: {
							enabled: true,
							callbacks: {
								title: function (tooltipItems) {
									if (!tooltipItems || !tooltipItems[0] || !labels) return '';
									const date = labels[tooltipItems[0].dataIndex];
									if (!date) return '';
									const [firstDate, secondDate] = date.split(",");
									return helper.convertDateToMonthAndDay(firstDate) + " - " + helper.convertDateToMonthAndDay(secondDate);
								},
								label: function (context) {
									let value = numberFormatter.formatNumber(context.parsed.y, title === 'Performance' ? 2 : 0);
									return `${context.dataset.label}: ${prefix || ''}${value}${suffix || ''}`;
								}
							}
						}
					},
					scales: {
						x: {
							grid: {
								display: false
							},
							ticks: {
								callback: function (value, index) {
									if (!labels) return '';
									const date = labels[index].split(",")[0];
									return helper.convertDateToMonthAndDay(date);
								},
								color: '#666'
							}
						},
						y: {
							beginAtZero: true,
							grid: {
								color: '#E5E5E5'
							},
							ticks: {
								callback: function (value) {
									return `${prefix || ''}${numberFormatter.formatNumber(value, title === 'Performance' ? 2 : 0)}${suffix || ''}`;
								},
								color: '#666'
							}
						}
					},
				}
			});
		}

		return () => {
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}
		};
	}, [data, title, prefix, suffix, labels]);

	return (
		<div className="crc-dual-line-chart">
			<canvas ref={chartRef} height="400" />
		</div>
	);
};


export const TrendLineChart = ({ title, data, labels, prefix, suffix }) => {
	const chartRef = useRef(null);
	const chartInstanceRef = useRef(null);

	useEffect(() => {
		if (chartRef.current) {
			const ctx = chartRef.current.getContext('2d');

			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}

			chartInstanceRef.current = new Chart(ctx, {
				type: 'line',
				data: {
					labels,
					datasets: [
						{
							label: 'Enhencer AI Ads',
							data: data.enhencer,
							borderColor: '#aaa',
							backgroundColor: '#aaa',
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							hoverRadius: 8,
							pointBackgroundColor: '#aaa',
							segment: {
								borderColor: ctx => {
									if (ctx.p0.parsed.x < (labels.length / 2) - 1) {
										return '#f8b724';
									}
									if (ctx.p0.parsed.x > (labels.length / 2) - 1) {
										return '#85ca6e';
									}
									//linear gradient
									const gradient = ctx.chart.ctx.createLinearGradient(
										ctx.chart.width / 2 - (ctx.chart.width / labels.length),
										0,
										ctx.chart.width / 2 + (ctx.chart.width / labels.length),
										0);
									gradient.addColorStop(0, '#f8b724');
									gradient.addColorStop(1, '#85ca6e');
									return gradient;
								},
							}
						},
						...(data.others ? [{
							label: 'Other Campaigns',
							data: data.others,
							borderColor: '#94959b',
							backgroundColor: 'rgba(148, 149, 155, 0.1)',
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							hoverRadius: 8,
							pointBackgroundColor: '#94959b',
						}] : [])
					]
				},
				options: {
					interaction: {
						mode: 'index',
						intersect: false,
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: true,
							position: 'top',
							align: 'end',
							labels: {
								usePointStyle: true,
								pointStyle: 'circle',
								padding: 20,
								color: '#1a1a1a'
							}
						},
						tooltip: {
							enabled: true,
							callbacks: {
								title: function (tooltipItems) {
									if (!tooltipItems || !tooltipItems[0] || !labels) return '';
									const date = labels[tooltipItems[0].dataIndex];
									if (!date) return '';
									const [firstDate, secondDate] = date.split(",");
									return helper.convertDateToMonthAndDay(firstDate) + " - " + helper.convertDateToMonthAndDay(secondDate);
								},
								label: function (context) {
									let value = numberFormatter.formatNumber(context.parsed.y, title === 'Performance' ? 2 : 0);
									return `${context.dataset.label}: ${prefix || ''}${value}${suffix || ''}`;
								}
							}
						}
					},
					scales: {
						x: {
							grid: {
								display: false
							},
							ticks: {
								callback: function (value, index) {
									if (!labels) return '';
									const date = labels[index].split(",")[0];
									return helper.convertDateToMonthAndDay(date);
								},
								color: '#666'
							}
						},
						y: {
							beginAtZero: true,
							grid: {
								color: '#E5E5E5'
							},
							ticks: {
								callback: function (value) {
									return `${prefix || ''}${numberFormatter.formatNumber(value, title === 'Performance' ? 2 : 0)}${suffix || ''}`;
								},
								color: '#666'
							}
						}
					},
				}
			});
		}

		return () => {
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}
		};
	}, [data, title, prefix, suffix, labels]);

	return (
		<div className="crc-dual-line-chart">
			<canvas ref={chartRef} height="400" />
		</div>
	);
};

export const GenericDualLineChart = ({ title, salesChartData, adSpendChartData, labels, prefix, suffix }) => {
	const chartRef = useRef(null);
	const chartInstanceRef = useRef(null);

	useEffect(() => {
		if (chartRef.current) {
			const ctx = chartRef.current.getContext('2d');

			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}

			chartInstanceRef.current = new Chart(ctx, {
				type: 'line',
				data: {
					labels,
					datasets: [
						{
							label: '% of Total Sales',
							data: salesChartData,
							borderColor: '#85ca6e',
							backgroundColor: '#85ca6e',
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							hoverRadius: 8,
							pointBackgroundColor: '#85ca6e',
						},
						{
							label: '% of Total Ad Spend',
							data: adSpendChartData,
							borderColor: '#f8b724',
							backgroundColor: '#f8b724',
							fill: false,
							tension: 0.4,
							borderWidth: 2,
							pointRadius: 3,
							hoverRadius: 8,
							pointBackgroundColor: '#f8b724',
						},
					]
				},
				options: {
					interaction: {
						mode: 'index',
						intersect: false,
					},
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						legend: {
							display: true,
							position: 'top',
							align: 'end',
							labels: {
								usePointStyle: true,
								pointStyle: 'circle',
								padding: 20,
								color: '#1a1a1a'
							}
						},
						tooltip: {
							enabled: true,
							callbacks: {
								title: function (tooltipItems) {
									if (!tooltipItems || !tooltipItems[0] || !labels) return '';
									const date = labels[tooltipItems[0].dataIndex];
									if (!date) return '';
									const [firstDate, secondDate] = date.split(",");
									return helper.convertDateToMonthAndDay(firstDate) + " - " + helper.convertDateToMonthAndDay(secondDate);
								},
								label: function (context) {
									let value = numberFormatter.formatNumber(context.parsed.y, title === 'Performance' ? 2 : 0);
									return `${context.dataset.label}: ${prefix || ''}${value}${suffix || ''}`;
								}
							}
						}
					},
					scales: {
						x: {
							grid: {
								display: false
							},
							ticks: {
								callback: function (value, index) {
									if (!labels) return '';
									const date = labels[index].split(",")[0];
									return helper.convertDateToMonthAndDay(date);
								},
								color: '#666'
							}
						},
						y: {
							beginAtZero: true,
							grid: {
								color: '#E5E5E5'
							},
							ticks: {
								callback: function (value) {
									return `${prefix || ''}${numberFormatter.formatNumber(value, title === 'Performance' ? 2 : 0)}${suffix || ''}`;
								},
								color: '#666'
							}
						}
					},
				}
			});
		}

		return () => {
			if (chartInstanceRef.current) {
				chartInstanceRef.current.destroy();
			}
		};
	}, [salesChartData, adSpendChartData, title, prefix, suffix, labels]);

	return (
		<div className="crc-dual-line-chart">
			<canvas ref={chartRef} height="400" />
		</div>
	);
};


export default DualLineChart