import React from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import './AreYouSure.css'

import 'react-toastify/dist/ReactToastify.css'

const AreYouSure = props => {
  const { closeModal, modalData } = useModal()

  const yes = () => {
    modalData.resolve(true)
    closeModal()
  }

  const no = () => {
    modalData.resolve(false)
    closeModal()
  }

  return (
    <div className="are-you-sure">
      <div className="question">{props.data.question}</div>
      <div className="footer">
        <Button className="action-button no" onClick={no}>
          {props.data.cancelButtonText ?? "Cancel"}
        </Button>
        <Button 
          className={`action-button yes ${props.data.isConfirmButtonDanger ? 'danger' : ''}`} 
          onClick={yes}
        >
          {props.data.confirmButtonText ?? "Confirm"}
        </Button>
      </div>
    </div>
  )
}

export default AreYouSure
