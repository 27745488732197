import React, { useRef, useState } from 'react'

import './HoverCard.css'

// TODO: Add placement (top, bottom, left, right) prop.
const HoverCard = ({ delay = 300, children }) => {
  const [show, setShow] = useState(false)
  const enterTimeoutRef = useRef(null)
  const leaveTimeoutRef = useRef(null)

  return (
    <div className="hover-card-container">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          show,
          setShow,
          delay,
          enterTimeoutRef,
          leaveTimeoutRef,
        }),
      )}
    </div>
  )
}

const Trigger = ({
  enterTimeoutRef,
  leaveTimeoutRef,
  delay,
  setShow,
  children,
}) => {
  const handleMouseEnter = () => {
    clearTimeout(leaveTimeoutRef.current)
    enterTimeoutRef.current = setTimeout(() => {
      setShow(true)
    }, delay)
  }

  const handleMouseLeave = () => {
    clearTimeout(enterTimeoutRef.current)
    leaveTimeoutRef.current = setTimeout(() => {
      setShow(false)
    }, delay)
  }

  return (
    <div
      className="hover-card-trigger"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  )
}

const Content = ({ leaveTimeoutRef, show, setShow, children }) => {
  if (!show) {
    return null
  }

  const handleMouseEnter = () => {
    clearTimeout(leaveTimeoutRef.current)
    setShow(true)
  }

  const handleMouseLeave = () => {
    setShow(false)
  }

  return (
    <div
      className="hover-card-content"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  )
}

HoverCard.Trigger = Trigger
HoverCard.Content = Content

export default HoverCard
