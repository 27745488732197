/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import gsap from 'gsap'
import arrowUp from '../../shared/assets/images/ad-health-checkup/arrow-up.svg'
import arrowDown from '../../shared/assets/images/ad-health-checkup/arrow-down.svg'
import { adHealthCheckupActions } from '../../store/ad-health-checkup'
import useAdHealthCheckup from '../../shared/hooks/ad-health-checkup-hook'
import YourAdHealthCheckup from '../components/YourAdHealthCheckup'
import MetaRevenue from '../components/MetaRevenue'
import RevenueGraph from '../components/RevenueGraph'
import BudgetComparison from '../components/BudgetComparison'
import LetsDive from '../components/LetsDive'
import AdSuccess from '../components/AdSuccess'
import FunPart from '../components/FunPart'
import TopAdSet from '../components/TopAdSet'
import WorstAdSet from '../components/WorstAdSet'
import UseAiAdsNow from '../components/UseAiAdsNow'
import AdHealthScore from '../components/AdHealthScore'
import StartYourJourney from '../components/StartYourJourney'
import BestCreatives from '../components/BestCreatives'
import WorstCreatives from '../components/WorstCreatives'
import { FaTimes } from 'react-icons/fa'
import { throttle } from 'lodash'
import plus from '../../shared/assets/images/ad-health-checkup/plus.png'

import './AdHealthCheckup.css'

const AdHealthCheckup = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const selectedAdAccount = useSelector(
    state => state.facebook.selectedAdAccount,
  )
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const fbInfoFetching = useSelector(state => state.facebook.fetching)
  const userSubscription = useSelector(state => state.auth.user.subscription)
  const isSubscribed = userSubscription && userSubscription.status === 'active'

  const { loading, error, isMouseWheelActive, connected } = useSelector(
    state => state.adHealthCheckup,
  )
  const {
    prepareAdHealthCheckup,
    adHealthCheckupLastViewedSlide,
  } = useAdHealthCheckup()
  const slideCount = connected ? (isSubscribed ? 13 : 14) : 1
  const shopifyName = useSelector(state => state.auth.user.shopify?.name)
  const userName = useSelector(state => state.auth.user.name)
  const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser)
  const adHealthCheckupOfUserInView = useSelector(
    state => state.admin?.userInView?.adHealthCheckup,
  )

  const didYouKnowContent = [
    `<p>
      In 2023, Facebook’s ad revenue hit <span>$135 billion!</span> With
      $135 billion, you could <span>buy everyone on Earth a cup of coffee</span> and still have
      enough left to swim in a Scrooge McDuck-style money vault!
    </p>
    <p>Just practice your dives, gold coins aren't soft!</p>`,

    `<p>
      E-commerce sales are expected to break <span>$6.5 trillion</span> by 2024.
      That's more than the <span>GDP of Japan, Germany, and the UK combined!</span>
    </p>`,

    `<p>
    Online shopping saw a record <span>2 billion users in 2023</span>,
    making it one of the largest virtual gatherings ever.
    If each online shopper formed a single line,
    it would wrap around the <span>Earth's equator over 50 times</span>.
    </p>
    <p> Now that's a checkout queue!</p>`,

    `<p>
    In 2023, Amazon delivered over 4 billion packages worldwide!
    If you lined up all those packages,
    they'd stretch to the moon and back <span>more than ten times<span>.
    </p>`,
  ]

  const [currentContentIndex, setCurrentContentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentContentIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % didYouKnowContent.length
        gsap.fromTo(
          '.did-you-know-paragraph',
          { opacity: 0, y: -5 },
          { opacity: 1, y: 0, duration: 1 },
        )
        return nextIndex
      })
    }, 10000)

    return () => clearInterval(interval)
  }, [didYouKnowContent.length])

  useEffect(() => {
    document.querySelector('.App').classList.add('ad-health-checkup')

    const handleMouseWheel = throttle(
      event => {
        if (event.deltaY > 0) {
          goToNextPage()
        } else {
          goToPreviousPage()
        }
      },
      1300,
      { leading: true, trailing: false },
    )

    if (connected && !loading) {
      if (isMouseWheelActive) window.addEventListener('wheel', handleMouseWheel)
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('wheel', handleMouseWheel)
      window.removeEventListener('keydown', handleKeyDown)

      document.querySelector('.App').classList.remove('ad-health-checkup')
    }
  }, [isMouseWheelActive, loading])

  useEffect(() => {
    if (!loading && connected && !isSpecialUser) {
      ;(async () => {
        dispatch(adHealthCheckupActions.setLoading(true))
        await prepareAdHealthCheckup()
        dispatch(adHealthCheckupActions.setLoading(false))
      })()
    }
  }, [selectedAdAccount?.id, selectedBusiness?.id, selectedPixel?.id])

  useEffect(() => {
    if (isSpecialUser && adHealthCheckupOfUserInView && selectedAdAccount ) {
      dispatch(adHealthCheckupActions.setLoading(true))
      dispatch(
        adHealthCheckupActions.setPreparedData(
          adHealthCheckupOfUserInView[selectedAdAccount?.id][0],
        ),
      )
      dispatch(adHealthCheckupActions.setLoading(false))
    }
  }, [adHealthCheckupOfUserInView])

  const getCurrentSlideFromPath = () => {
    const path = location.pathname.split('/').pop()
    const slideNumber = parseInt(path, 10)
    return isNaN(slideNumber) ? 1 : slideNumber
  }

  const [currentSlide, setCurrentSlide] = useState(getCurrentSlideFromPath())

  const goToNextPage = async () => {
    setCurrentSlide(prevSlide => {
      const newSlide = Math.min(prevSlide + 1, slideCount)
      navigate(`/ad-health-checkup/${newSlide}`)

      adHealthCheckupLastViewedSlide(newSlide)
      return newSlide
    })
  }

  const goToPreviousPage = () => {
    setCurrentSlide(prevSlide => {
      const newSlide = Math.max(prevSlide - 1, 1)
      navigate(`/ad-health-checkup/${newSlide}`)
      return newSlide
    })
  }

  const handleDotClick = index => {
    const direction = index + 1 > currentSlide ? 'left' : 'right'
    const newSlide = index + 1

    if (newSlide > currentSlide) {
      adHealthCheckupLastViewedSlide(newSlide)
    }

    setCurrentSlide(newSlide)
    navigate(`/ad-health-checkup/${newSlide}`)
  }

  const handleKeyDown = e => {
    switch (e.key) {
      case 'ArrowLeft':
      case 'ArrowUp':
        goToPreviousPage()
        break
      case 'ArrowDown':
      case 'ArrowRight':
        goToNextPage()
        break
    }
  }

  const close = () => {
    navigate('/')
  }

  if (
    (!selectedAdAccount || !selectedPixel || !selectedBusiness) &&
    !fbInfoFetching
  ) {
    navigate('/checkup/config')
    return null
  }

  return (
    <React.Fragment>
      <div className="ahcu-header row">
        <div className="row">
         
          <div className="brand-name row">
            {shopifyName ? shopifyName : userName || ''}
          </div>
        </div>

        <div className="close-button" onClick={() => close()}>
          <FaTimes className="icon" />
        </div>
      </div>
      {!loading && !error ? (
        <div className="ad-health-checkup">
          <div className="navigation-row">
            {[...Array(slideCount)].map((_, index) => (
              <div
                key={index}
                className={`navigation-dot ${index + 1 <= currentSlide ? 'active' : ''}`}
                onClick={() => handleDotClick(index)}
              ></div>
            ))}
          </div>
          <div className="components">
            {connected && (
              <Routes>
                <Route path="1" element={<YourAdHealthCheckup />} />
                <Route path="2" element={<MetaRevenue />} />
                <Route path="3" element={<RevenueGraph />} />
                <Route path="4" element={<BudgetComparison />} />
                <Route path="5" element={<LetsDive />} />
                <Route path="6" element={<AdSuccess />} />
                <Route path="7" element={<FunPart />} />
                <Route path="8" element={<TopAdSet />} />
                <Route path="9" element={<WorstAdSet />} />
                <Route path="10" element={<UseAiAdsNow />} />
                <Route path="11" element={<BestCreatives />} />
                <Route path="12" element={<WorstCreatives />} />
                <Route
                  path="13"
                  element={<AdHealthScore isConnected="true" />}
                />
                {!isSubscribed && (
                  <Route path="14" element={<StartYourJourney />} />
                )}
                <Route path="*" element={<YourAdHealthCheckup />} />{' '}
              </Routes>
            )}
            {!connected && (
              <div>
                <Routes>
                  <Route
                    path="0"
                    element={<AdHealthScore isConnected={false} />}
                  />
                </Routes>
              </div>
            )}
          </div>
          <div className="navigation-buttons">
            {currentSlide > 1 && (
              <div className="arrow up">
                <img src={arrowUp} onClick={() => goToPreviousPage()} />
              </div>
            )}
            {currentSlide < slideCount && (
              <div className="arrow down" onClick={() => goToNextPage()}>
                <img src={arrowDown} />
              </div>
            )}
          </div>
        </div>
      ) : error ? (
        <div className="ad-health-checkup-error-modal">
          <div className="content-box">
            <h1>Oops! Something went wrong...</h1>
            <h3>
              We are currently unable to display your Free Ad Health Checkup
              report results due to a rejection from the Facebook API. This
              issue typically occurs when the requested data is too large.
              <br />
              <br />
              Our team has been notified of the error and is actively working to
              resolve it. Please try again later. We appreciate your
              understanding and patience.
            </h3>
          </div>
        </div>
      ) : (
        <div className="ad-health-checkup-waiting-modal">
          <div className="content-box">
            <h1>We are preparing your personalized ad health checkup...</h1>
            <h3>It may take a couple of minutes, please do not close the page</h3>
            <div className="animation-part">
              <div className="empty"></div>
              <div className="left"></div>

              <div className="pluses">
                <img className="plus-1" src={plus} />
                <img className="plus-2" src={plus} />
                <img className="plus-3" src={plus} />
                <div className="ellipse-right"></div>
              </div>
              <div className="right"></div>

              <div className="empty"></div>
            </div>
            <div className="content">
              <h2>Did you Know...</h2>
              <p
                className="did-you-know-paragraph"
                dangerouslySetInnerHTML={{
                  __html: didYouKnowContent[currentContentIndex],
                }}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default AdHealthCheckup
