import React, { useState } from 'react'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { FaReceipt } from 'react-icons/fa'

import './NotesPopup.css'
import helper from '../../../shared/util/helper'

const NotesPopup = props => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const user = props.user


  return (
    <Popover
      isOpen={isPopoverOpen}
      reposition={false}
      positions={['bottom', 'left', 'left', 'bottom']}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
          nudgetLeft={10}
        >
          <div
            onClick={e => e.stopPropagation()}
            className="notes-popup"
          >
            <h4 style={{ margin: "5px" }}>Notes</h4>
            {(!user.crmDetails?.noteList || user.crmDetails?.noteList.length === 0)
              ? <div className='note-container'>No notes present</div>
              : null}
            {(user.crmDetails?.noteList ?? []).map((note) => {
              return <div key={note.createdAt} className='note-container'>
                <div className='note-createdat'>
                  {helper.convertDateToString(note.createdAt)}
                </div>
                <div className='note-text'>
                  <div style={{ whiteSpace: "pre-wrap" }}>{note.text}</div>
                </div>
              </div>
            })}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="notes-popup-icon"
        onMouseEnter={e => {
          setIsPopoverOpen(true)
        }}
        onMouseLeave={e => {
          setIsPopoverOpen(false)
        }}
        onClick={e => {
          e.stopPropagation()
          props.onClick(e)
        }}
      >
        <FaReceipt className='icon' title="Go to User Detail" />
      </div>
    </Popover>
  )
}

export default NotesPopup
