import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaCheck, FaCheckCircle } from 'react-icons/fa'
import fireIcon from '../../shared/assets/images/icons/fire.svg'
import { useModal } from '../../shared/hooks/modal-hook'
import numberFormatter from '../../shared/util/numberFormatter'
import { useNavigate } from 'react-router-dom'
import { authActions } from '../../store/auth'
import FreeAdHealthCheckupIcon from '../../shared/assets/images/icons/free-ad-health-checkup.svg'
import './PricingCard.css'


const PricingCard = props => {
  const { activePkg, isUpdate, openAreYouSureModal } = props
  let {
    name,
    actionName,
    minAdSpent,
    promotionText,
    price,
    discountedPrice,
    additionalFee,
    paymentInterval,
    features,
  } = props.plan

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { openModal } = useModal()
  const user = useSelector(state => state.auth.user)
  const userSubscription = useSelector(state => state.auth.user.subscription)
  const userCrmDetails = useSelector(state => state.auth.user.crmDetails)
  const isFromShopify = user?.shopify

  // If not from Shopify and Starter card, don't show
  if (!isFromShopify && name === 'Starter') {
    return null;
  }

  const planNames = ['XSmall', 'Small', 'Medium', 'Large', 'Enterprise']
  const userSubscriptionInterval =
    userSubscription?.interval || userSubscription?.paymentInterval
  const isActivePackage =
    userSubscription?.plan === name &&
    userSubscription.status === 'active' &&
    userSubscriptionInterval === paymentInterval

  // Enterprise just for Shopify
  const cardClassName = `pricing-card ${
    name === 'Ad Health Checkup' && isFromShopify ? 'ad-health-checkup-card' : ''
  } ${name === 'Starter' ? 'no-border-small' : ''
  } ${name === 'Pro' ? 'no-border-medium' : ''
  } ${name === 'Growth' ? 'no-border-large scale-large' : ''}`

  const showDiscountedPrice = discountedPrice ? true : false
  if (isUpdate) {
    const index = planNames.indexOf(activePkg)

    planNames.forEach((plan, i) => {
      if (plan === name && i < index) {
        actionName = 'Downgrade'
      } else if (plan === name && i > index) {
        actionName = 'Upgrade'
      } else if (plan === name && i === index) {
        actionName = 'Your Plan'
      }
    })
  }

  const getAdHealthCheckup = () => {
    navigate('/ad-health-checkup')
    dispatch(
      authActions.updateUserObjOneField({
        field: 'isInSignupFlow',
        value: false,
      }),
    )
  }

  return (
    <div className={cardClassName}>
      {name === 'Pro' && (
        <div className="most-popular-container">
          <div className="row">
            <img alt="fireicon" src={fireIcon} />
            <h3>Most Popular</h3>
          </div>
        </div>
      )}

      <div className="content">
        {name === 'Enterprise' && isFromShopify ? (
          <>
            <div className="left-section">
              <div className="plan-name-row">
                <h2>{name}</h2>
              </div>

              <div className="price-container">
                {!showDiscountedPrice && paymentInterval === 'YEARLY' && (
                  <div className="old-price">
                    <span className="strike-through">$15,599</span>
                  </div>
                )}
                <div className="price">
                  {showDiscountedPrice ? (
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      justifyContent: 'flex-start',
                    }}>
                      <span style={{
                        position: 'relative',
                        color: '#999',
                        fontSize: '0.6em',
                      }}>
                        ${numberFormatter.formatNumber(price)}
                        <span style={{
                          position: 'absolute',
                          left: 0,
                          top: '50%',
                          right: 0,
                          borderTop: '1.5px solid #999',
                          transform: 'rotate(-20deg)',
                        }}/>
                      </span>
                      <span>${numberFormatter.formatNumber(discountedPrice)}</span>
                    </div>
                  ) : (
                    <>${numberFormatter.formatNumber(price)}</>
                  )}
                  <span className="per-month">
                    &nbsp; per&nbsp;
                    {paymentInterval === 'MONTHLY' ? 'month' : 'year'}{' '}
                  </span>
                </div>
              </div>

              <div className="pageview-container">
                <span className="promotion-text">{promotionText}</span>
              </div>

              <div className="price-container">
                <button
                  className={` ${isActivePackage ? 'active-plan-button' : ''}`}
                  onClick={() => {
                    if (!isActivePackage) props.getStarted(props.plan)
                  }}
                >
                  <div>
                    {isActivePackage ? (
                      <span className="your-plan-text">
                        {' '}
                        <FaCheckCircle className="icon" /> Your Plan
                      </span>
                    ) : (
                      actionName
                    )}
                  </div>
                </button>
              </div>
            </div>

            <ul className={`features ${name}`}>
              {features.map((feature, index) => (
                <li
                  className={feature.isChecked ? '' : 'passive'}
                  key={index}
                >
                  <FaCheck className="icon" />
                  <div>
                    <span>{feature.text}</span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : name === 'Ad Health Checkup' && isFromShopify ? (
          <>
            <div className="left-section">
              <div className="plan-name-row">
                <h2>{name}</h2>
              </div>

              <div className="price-container">
                <div className="custom-price">
                  <span className="name" style={{ fontSize: '.6em' }}>
                    Free
                  </span>
                </div>
              </div>

              <div className="price-container">
                <button
                  className={`ad-health-checkup-meeting`}
                  onClick={() => {
                    getAdHealthCheckup()
                  }}
                >
                  <div>
                    <span className="your-plan-text">
                      {' '}
                      <img
                        src={FreeAdHealthCheckupIcon}
                        alt="Ad Health Checkup"
                        className="ahchu-icon"
                      />{' '}
                      Start your checkup
                    </span>
                  </div>
                </button>
              </div>
            </div>

            <div className="features-section">
              <div className="promotion-text-top">
                {promotionText}
              </div>
              <ul className={`features ${name}`}>
                {features.map((feature, index) => (
                  <li
                    className={feature.isChecked ? '' : 'passive'}
                    key={index}
                  >
                    <FaCheck className="icon" />
                    <div>
                      <span>{feature.text}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </>
        ) : (
          <>
            <div className="plan-name-row">
              <h2>{name}</h2>
            </div>

            <div className="price-container">
              {!showDiscountedPrice &&
                paymentInterval === 'YEARLY' &&
                name === 'Starter' && (
                  <div className="old-price">
                    <span className="strike-through">$5900</span>
                  </div>
                )} 
              {!showDiscountedPrice &&
                paymentInterval === 'YEARLY' &&
                name === 'Growth' && (
                  <div className="old-price">
                    <span className="strike-through">$9000</span>
                  </div>
                )}
              {!showDiscountedPrice &&
                paymentInterval === 'YEARLY' &&
                name === 'Pro' && (
                  <div className="old-price">
                    <span className="strike-through">$12000</span>
                  </div>
                )}
              {!showDiscountedPrice &&
                paymentInterval === 'YEARLY' &&
                name === 'Enterprise' && (
                  <div className="old-price">
                    <span className="strike-through">$15599</span>
                  </div>
                )}

              {showDiscountedPrice && (
                <div className="black-friday-sale">
                  {' '}
                  <span className="black-friday-sale-text"></span>
                </div>
              )}
              {name !== 'Ad Health Checkup' && (
                <div className="price">
                  {showDiscountedPrice ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        style={{
                          position: 'relative',
                          color: '#999',
                          fontSize: '0.6em',
                        }}
                      >
                        ${numberFormatter.formatNumber(price)}
                        <span
                          style={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            right: 0,
                            borderTop: '1.5px solid #999',
                            transform: 'rotate(-20deg)',
                          }}
                        />
                      </span>
                      <span>${numberFormatter.formatNumber(discountedPrice)}</span>
                    </div>
                  ) : (
                    <>${numberFormatter.formatNumber(price)}</>
                  )}
                  <br />
                  <span className="per-month">
                    &nbsp; per&nbsp;
                    {paymentInterval === 'MONTHLY' ? 'month' : 'year'}{' '}
                  </span>
                </div>
              )}

              {name === 'Ad Health Checkup' && (
                <div className="custom-price">
                  <span className="name" style={{ fontSize: '.6em' }}>
                    Free
                  </span>
                </div>
              )}
            </div>

            <div className="pageview-container">
              <span className="promotion-text">{promotionText}</span>
              <br className="break" />
            </div>

            <div className="price-container">
              {name === 'Ad Health Checkup' && (
                <button
                  className={`ad-health-checkup-meeting`}
                  onClick={() => {
                    getAdHealthCheckup()
                  }}
                >
                  <div>
                    <span className="your-plan-text">
                      {' '}
                      <img
                        src={FreeAdHealthCheckupIcon}
                        alt="Ad Health Checkup"
                        className="ahchu-icon"
                      />{' '}
                      Start your checkup
                    </span>
                  </div>
                </button>
              )}

              {name !== 'Ad Health Checkup' && (
                <button
                  className={` ${isActivePackage ? 'active-plan-button' : ''}`}
                  onClick={() => {
                    if (!isActivePackage) props.getStarted(props.plan)
                  }}
                >
                  <div>
                    {isActivePackage ? (
                      <span className="your-plan-text">
                        {' '}
                        <FaCheckCircle className="icon" /> Your Plan
                      </span>
                    ) : (
                      actionName
                    )}
                  </div>
                </button>
              )}
            </div>

            <ul className={`features ${name}`}>
              {features.map((feature, index) => (
                <li
                  className={feature.isChecked ? '' : 'passive'}
                  key={index}
                >
                  <FaCheck className="icon" />
                  <div>
                    <span>{feature.text}</span>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}

export default PricingCard;
