import Konva from 'konva';
import countries from '../../shared/data/countries.json';
import helper from '../../shared/util/helper';
import { WORKBENCH_SIZE } from './constants';

window.cachedSvgs = {};

function changeColorInSvgText(svgUrl, svgText, color, customReplaceThisDirectly) {
	window.cachedSvgs[svgUrl] = svgText;
	let coloredSvg = svgText;
	if (customReplaceThisDirectly) {
		coloredSvg = coloredSvg.replaceAll(new RegExp(customReplaceThisDirectly, 'g'), color);
	} else if (color) {
		coloredSvg = coloredSvg.replace(new RegExp(`fill="[^"]*"`, 'g'), `fill="${color}"`);
	}
	const svgBlob = new Blob([coloredSvg], { type: 'image/svg+xml' });
	const url = URL.createObjectURL(svgBlob);
	return url;
}

export function loadAndChangeSvgColor(svgUrl, color, callback, customReplaceThisDirectly) {
	if (window.cachedSvgs[svgUrl]) {

		callback(changeColorInSvgText(svgUrl, window.cachedSvgs[svgUrl], color, customReplaceThisDirectly));
		return;
	}
	fetch(svgUrl)
		.then(response => response.text())
		.then(svgText => {
			callback(changeColorInSvgText(svgUrl, svgText, color, customReplaceThisDirectly));
		});
}

/**
 * Centers an element within its container.
 * @param {Konva.Node} element - The Konva element to be centered.
 * @param {Object} firstPositions - Initial positions (optional). If provided, centering is based on these coordinates.
 * @param {boolean} shouldSetDirectly - If true, directly sets the new position on the element. Otherwise, only returns the new position.
 * @returns {Object} The new centered position {x, y}.
 */
export const _centerTheElement = (element, firstPositions, shouldSetDirectly = false) => {
	const elementWidth = element.width()
	const elementHeight = element.height()

	const newPosition = {
		x: (firstPositions?.x ?? element.x()) - elementWidth / 2,
		y: (firstPositions?.y ?? element.y()) - elementHeight / 2
	}

	if (shouldSetDirectly) {
		element.x(newPosition.x)
		element.y(newPosition.y)
	}

	return newPosition
}

export const centerTheElement = ({ x, y, width, height }) => {
	return {
		x: x - width / 2,
		y: y - height / 2,
	}
}

export const updateFontSizeToFitIntoBox = (productPrice, size, initialFontSize = 100) => {
	let fontSize = initialFontSize
	productPrice.fontSize(fontSize)
	while (
		productPrice.getTextWidth() > size * 0.80 ||
		productPrice.fontSize() > size * 0.80
	) {
		fontSize -= 0.6
		productPrice.fontSize(fontSize)
	}
}

export const _resizeImageToFit = ({ image, maxWidth, maxHeight, center = true, shouldSetDirectly = false }) => {
	const imageWidth = image.width()
	const imageHeight = image.height()
	let scale = 1

	// Calculate how much are we going to scale down
	if (maxWidth && maxHeight) {
		const scaleX = maxWidth / imageWidth
		const scaleY = maxHeight / imageHeight
		scale = Math.min(scaleX, scaleY)
	} else if (maxWidth) {
		scale = maxWidth / imageWidth
	} else if (maxHeight) {
		scale = maxHeight / imageHeight
	}

	const oldX = image.x()
	const oldY = image.y()
	const oldWidth = image.width()
	const oldHeight = image.height()
	let newScales = {
		scaleX: scale,
		scaleY: scale,
		x: oldX,
		y: oldY
	}

	if (shouldSetDirectly) {
		image.scale({ x: scale, y: scale })
	}

	const newWidth = oldWidth * scale
	const newHeight = oldHeight * scale
	const newX = oldX - (newWidth - oldWidth) / 2
	const newY = oldY - (newHeight - oldHeight) / 2


	if (center) {
		if (shouldSetDirectly) {
			image.position({ x: newX, y: newY })
		}
		newScales.x = newX
		newScales.y = newY
	}

	return newScales
}

export const resizeImageToFit = ({
	x,
	y,
	width,
	height,
	maxWidth,
	maxHeight,
	center = true,
}) => {
	let scale = 1

	if (maxWidth && maxHeight) {
		const scaleX = maxWidth / width
		const scaleY = maxHeight / height
		scale = Math.min(scaleX, scaleY)
	} else if (maxWidth) {
		scale = maxWidth / width
	} else if (maxHeight) {
		scale = maxHeight / height
	}

	const newWidth = width * scale
	const newHeight = height * scale
	let newX = x
	let newY = y

	if (center) {
		newX = x - (newWidth - width) / 2
		newY = y - (newHeight - height) / 2
	}

	return {
		width: newWidth,
		height: newHeight,
		x: newX,
		y: newY,
		scale,
	}
}

export const resizeToFit = ({ object, maxWidth }) => {
	const originalWidth = object.width() / object.scaleX()

	let scale = 1
	if (maxWidth) {
		scale = maxWidth / originalWidth
	}

	object.scale({ x: scale, y: scale })
}

export const formatPrice = (price, currency) => {
	const match = price.match(/[\d,.]+/);
	if (!match) return price;

	let amount = match[0];

	const country = helper.findBy(countries, "currency", currency);
	const currencySymbol = country ? country.currencySymbol : currency;
	const currencyPosition = country ? country.currencyPosition : 'suffix';

	return currencyPosition === 'prefix'
		? `${currencySymbol}${amount}`
		: `${amount}${currencySymbol}`;
};

export const getCrop = (image, size, clipPosition = 'center-middle') => {
	const width = size.width;
	const height = size.height;
	const aspectRatio = width / height;

	let newWidth;
	let newHeight;

	const imageRatio = image.naturalWidth / image.naturalHeight;

	if (aspectRatio >= imageRatio) {
		newWidth = image.naturalWidth;
		newHeight = image.naturalWidth / aspectRatio;
	} else {
		newWidth = image.naturalHeight * aspectRatio;
		newHeight = image.naturalHeight;
	}

	let x = 0;
	let y = 0;
	if (clipPosition === 'left-top') {
		x = 0;
		y = 0;
	} else if (clipPosition === 'left-middle') {
		x = 0;
		y = (image.naturalHeight - newHeight) / 2;
	} else if (clipPosition === 'left-bottom') {
		x = 0;
		y = image.naturalHeight - newHeight;
	} else if (clipPosition === 'center-top') {
		x = (image.width - newWidth) / 2;
		y = 0;
	} else if (clipPosition === 'center-middle') {
		x = (image.naturalWidth - newWidth) / 2;
		y = (image.naturalHeight - newHeight) / 2;
	} else if (clipPosition === 'center-bottom') {
		x = (image.naturalWidth - newWidth) / 2;
		y = image.naturalHeight - newHeight;
	} else if (clipPosition === 'right-top') {
		x = image.naturalWidth - newWidth;
		y = 0;
	} else if (clipPosition === 'right-middle') {
		x = image.naturalWidth - newWidth;
		y = (image.naturalHeight - newHeight) / 2;
	} else if (clipPosition === 'right-bottom') {
		x = image.naturalWidth - newWidth;
		y = image.naturalHeight - newHeight;
	} else if (clipPosition === 'scale') {
		x = 0;
		y = 0;
		newWidth = image.naturalWidth;
		newHeight = image.naturalHeight;
	} else {
		console.error(
			new Error('Unknown clip position property - ' + clipPosition)
		);
	}

	return {
		cropX: x,
		cropY: y,
		cropWidth: newWidth,
		cropHeight: newHeight,
	};
}

/**
 * Creates an SVG that crops an image with a mask.
 * @param {string} imageUrl - The URL of the image to be cropped.
 * @param {string} maskShape - The shape of the mask (e.g., 'circle', 'rect', 'polygon').
 * @param {Object} maskAttributes - Attributes for the mask shape (e.g., {cx, cy, r} for circle).
 * @param {number} width - The width of the resulting SVG.
 * @param {number} height - The height of the resulting SVG.
 * @returns {Promise<string>} - A Promise that resolves to the SVG string that crops the image with the specified mask.
 */
export const createMaskedImageSVG = async (imageUrl, maskShape, maskAttributes, width, height) => {
	try {
		const svgns = 'http://www.w3.org/2000/svg';
		const xlinkns = 'http://www.w3.org/1999/xlink';

		// Download the image and convert it to a data URL
		const response = await fetch(imageUrl);
		if (!response.ok) {
			throw new Error(`Failed to fetch image: ${response.statusText}`);
		}
		const blob = await response.blob();
		const dataUrl = await new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});

		// Create the SVG element
		const svg = document.createElementNS(svgns, 'svg');
		svg.setAttribute('width', width);
		svg.setAttribute('height', height);
		svg.setAttribute('xmlns', svgns);
		svg.setAttribute('xmlns:xlink', xlinkns);

		// Create a unique ID for the mask
		const maskId = `mask-${Math.random().toString(36).substr(2, 9)}`;

		// Create the mask element
		const mask = document.createElementNS(svgns, 'mask');
		mask.setAttribute('id', maskId);

		// Create the mask shape
		const shape = document.createElementNS(svgns, maskShape);
		for (const [attr, value] of Object.entries(maskAttributes)) {
			shape.setAttribute(attr, value);
		}
		shape.setAttribute('fill', 'white');

		// Add the shape to the mask
		mask.appendChild(shape);

		// Create the image element
		const image = document.createElementNS(svgns, 'image');
		image.setAttributeNS(xlinkns, 'xlink:href', dataUrl);
		image.setAttribute('width', '100%');
		image.setAttribute('height', '100%');
		image.setAttribute('preserveAspectRatio', 'xMidYMid slice');
		image.setAttribute('mask', `url(#${maskId})`);

		// Add the mask and image to the SVG
		svg.appendChild(mask);
		svg.appendChild(image);

		// Convert the SVG element to a string
		const serializer = new XMLSerializer();
		return serializer.serializeToString(svg);
	} catch (error) {
		console.error('Error creating masked image SVG:', error);
		throw error;
	}
}

export function getLineGuideStops(stage, skipShape) {
	// we can snap to stage borders and the center of the stage
	var vertical = [0, stage.width() / 2, stage.width()];
	var horizontal = [0, stage.height() / 2, stage.height()];

	// and we snap over edges and center of each object on the canvas
	stage.find("Image,Text,Circle").forEach((guideItem) => {
		if (guideItem === skipShape) {
			return;
		}
		var box = guideItem.getClientRect();
		// and we can snap to all edges of shapes
		vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
		horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
	});
	return {
		vertical: vertical.flat(),
		horizontal: horizontal.flat(),
	};
}

// what points of the object will trigger to snapping?
// it can be just center of the object
// but we will enable all edges and center
export function getObjectSnappingEdges(node) {
	var box = node.getClientRect();
	var absPos = node.absolutePosition();

	return {
		vertical: [
			{
				guide: Math.round(box.x),
				offset: Math.round(absPos.x - box.x),
				snap: 'start',
			},
			{
				guide: Math.round(box.x + box.width / 2),
				offset: Math.round(absPos.x - box.x - box.width / 2),
				snap: 'center',
			},
			{
				guide: Math.round(box.x + box.width),
				offset: Math.round(absPos.x - box.x - box.width),
				snap: 'end',
			},
		],
		horizontal: [
			{
				guide: Math.round(box.y),
				offset: Math.round(absPos.y - box.y),
				snap: 'start',
			},
			{
				guide: Math.round(box.y + box.height / 2),
				offset: Math.round(absPos.y - box.y - box.height / 2),
				snap: 'center',
			},
			{
				guide: Math.round(box.y + box.height),
				offset: Math.round(absPos.y - box.y - box.height),
				snap: 'end',
			},
		],
	};
}

// find all snapping possibilities
export function getGuides(lineGuideStops, itemBounds) {
	var resultV = [];
	var resultH = [];

	lineGuideStops.vertical.forEach((lineGuide) => {
		itemBounds.vertical.forEach((itemBound) => {
			var diff = Math.abs(lineGuide - itemBound.guide);
			// if the distance between guild line and object snap point is close we can consider this for snapping
			if (diff < 10) {
				resultV.push({
					lineGuide: lineGuide,
					diff: diff,
					snap: itemBound.snap,
					offset: itemBound.offset,
				});
			}
		});
	});

	lineGuideStops.horizontal.forEach((lineGuide) => {
		itemBounds.horizontal.forEach((itemBound) => {
			var diff = Math.abs(lineGuide - itemBound.guide);
			if (diff < 10) {
				resultH.push({
					lineGuide: lineGuide,
					diff: diff,
					snap: itemBound.snap,
					offset: itemBound.offset,
				});
			}
		});
	});

	var guides = [];

	// find closest snap
	var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
	var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
	if (minV) {
		guides.push({
			lineGuide: minV.lineGuide,
			offset: minV.offset,
			orientation: 'V',
			snap: minV.snap,
		});
	}
	if (minH) {
		guides.push({
			lineGuide: minH.lineGuide,
			offset: minH.offset,
			orientation: 'H',
			snap: minH.snap,
		});
	}
	return guides;
}

export function drawGuides(layer, guides) {
	guides.forEach((lg) => {
		if (lg.orientation === 'H') {
			var line = new Konva.Line({
				points: [-6000, 0, 6000, 0],
				stroke: 'rgb(0, 161, 255)',
				strokeWidth: 1,
				name: 'guid-line',
				dash: [4, 6],
			});
			layer.add(line);
			line.absolutePosition({
				x: 0,
				y: lg.lineGuide,
			});
		} else if (lg.orientation === 'V') {
			var line = new Konva.Line({
				points: [0, -6000, 0, 6000],
				stroke: 'rgb(0, 161, 255)',
				strokeWidth: 1,
				name: 'guid-line',
				dash: [4, 6],
			});
			layer.add(line);
			line.absolutePosition({
				x: lg.lineGuide,
				y: 0,
			});
		}
	});
}

/**
 * Converts a Konva stage to an SVG string.
 * @param {Konva.Stage} stage - The Konva stage to convert to SVG.
 * @param {Object} options - The options object containing width, height, and templateMode.
 * @param {number} options.width - The width of the SVG.
 * @param {number} options.height - The height of the SVG.
 * @param {boolean} options.templateMode - Whether the SVG is in template mode. Keep this true when sending the SVG to the backend
 * @returns {Promise<string>} A Promise that resolves to the SVG string.
 */
export const convertToSvg = async (
	stage,
	{ width, height, templateMode = true },
) => {
	const fontFamilyMap = {
		'Readex Pro': "'Readex Pro', sans-serif",
		'Be Vietnam Pro': "'Be Vietnam Pro', sans-serif",
		'Arial': 'Arial, sans-serif',
		'Times New Roman': "'Times New Roman', serif",
		'Bayon': "'Bayon', sans-serif",
		'Inter': "'Inter', sans-serif",
		'Open Sans': "'Open Sans', sans-serif",
		'Quicksand': "'Quicksand', sans-serif",
		'Roboto': "'Roboto', sans-serif",
		'Courier New': "'Courier New', monospace",
		'Verdana': "'Verdana', sans-serif",
		'Anuphan': "'Anuphan', sans-serif",
		'Staatliches': "'Staatliches', sans-serif",
	}

	const fontUrlMap = {
		'Readex Pro':
			'https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;700',
		'Be Vietnam Pro':
			'https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;700',
		'Bayon':
			'https://fonts.googleapis.com/css2?family=Bayon&amp;display=swap',
		'Inter':
			'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap',
		'Open Sans':
			'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap',
		'Quicksand':
			'https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&amp;display=swap',
		'Roboto':
			'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap',
		'Times New Roman':
			'https://fonts.googleapis.com/css2?family=Times+New+Roman:wght@400;700&amp;display=swap',
		'Courier New':
			'https://fonts.googleapis.com/css2?family=Courier+New:wght@400;700&amp;display=swap',
		'Verdana':
			'https://fonts.googleapis.com/css2?family=Verdana:wght@400;700&amp;display=swap',
		'Anuphan':
			'https://fonts.googleapis.com/css2?family=Anuphan:wght@100;200;300;400;500;600;700&amp;display=swap',
		'Staatliches':
			'https://fonts.googleapis.com/css2?family=Staatliches&amp;display=swap',
	}


	const collectFonts = node => {
		const fonts = new Set()

		if (node.getClassName() === 'Text') {
			fonts.add(node.attrs.fontFamily)
		}

		// If it's a container (group, layer, or stage), check children
		if (node.getChildren) {
			node.getChildren().forEach(child => {
				const childFonts = collectFonts(child)
				childFonts.forEach(font => fonts.add(font))
			})
		}

		return fonts
	}

	const fonts = collectFonts(stage)

	const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	  width="${width || stage.width()}" height="${height || stage.height()}">
	<defs>
	<style type="text/css">
		${Array.from(fonts)
			.filter(font => fontUrlMap[font]) // Only include fonts that have URLs
			.map(font => `@import url('${fontUrlMap[font]}');`)
			.join('\n')}
	</style>
	</defs>`

	// Convert image to base64
	const toBase64 = img => {
		const canvas = document.createElement('canvas')
		canvas.width = img.width
		canvas.height = img.height
		const ctx = canvas.getContext('2d')
		ctx.drawImage(img, 0, 0)
		return canvas.toDataURL()
	}

	// Process transforms
	const getTransform = node => {
		const attrs = node.attrs
		const transforms = []

		if (attrs.x || attrs.y) {
			// Calculate adjusted y position based on font size
			const yOffset = attrs.fontSize ? attrs.fontSize * 0.85 : 0 // Approximate offset to match previous alignment
			const adjustedY = attrs.y ? attrs.y + yOffset : 0 // This corrects the y position of the text without using the dominant-baseline="hanging"

			// Handle case where width is undefined (unlimited width for text)
			const xOffset = !!attrs.width && attrs.align === 'center' ? attrs.width / 2 : 0
			const adjustedX = attrs.x ? attrs.x + xOffset : 0

			transforms.push(`translate(${adjustedX} ${adjustedY})`)
		}
		if (attrs.rotation) {
			// For groups, rotate around their center
			const px = (attrs.width || 0) / 2
			const py = (attrs.height || 0) / 2
			transforms.push(`rotate(${attrs.rotation} ${px} ${py})`)
		}
		if (attrs.scaleX !== 1 || attrs.scaleY !== 1) {
			transforms.push(`scale(${attrs.scaleX || 1} ${attrs.scaleY || 1})`)
		}

		return transforms.length ? `transform="${transforms.join(' ')}"` : ''
	}

	// Shape converter
	const convertShape = async shape => {
		const attrs = shape.attrs

		switch (shape.getClassName()) {
			case 'Group':
				let groupContent = ''
				// Recursively process all children of the group
				for (const child of shape.getChildren()) {
					groupContent += await convertShape(child)
				}
				return `<g ${getTransform(shape)}>${groupContent}</g>`

			case 'Text':
				return `<text
						${getTransform(shape)}
						font-family="${fontFamilyMap[attrs.fontFamily] || `'${attrs.fontFamily}', sans-serif`}"
						font-size="${attrs.fontSize}"
						font-style="${attrs.fontStyle.includes('italic') ? 'italic' : ''}"
						font-weight="${attrs.fontStyle.includes('bold') ? 'bold' : ''}"
						fill="${attrs.fill}"
						vertical-alignment="${attrs.verticalAlign || 'middle'}"
						letter-spacing="${attrs.letterSpacing || 0}px"
						text-anchor="${attrs.align === 'center' ? 'middle' : attrs.align === 'right' ? 'end' : 'start'}"
						width="${attrs.width}"
						height="${attrs.height}"
						shadowBlur="${attrs.shadowBlur || 0}"
						shadowColor="${attrs.shadowColor || 'black'}"
						shadowOffsetX="${attrs.shadowOffsetX || 1}"
						shadowOffsetY="${attrs.shadowOffsetY || 1}"
						shadowOpacity="${attrs.shadowOpacity || 0.5}">
							${attrs.originalText ?? attrs.text}
						</text>`

			case 'Image':
				if (templateMode && attrs.id === 'productImage') {
					if (attrs._cropWidth || attrs._cropHeight) {
						return `<svg
					x="${attrs._cropX}"
					y="${attrs._cropY}"
					width="${attrs._cropWidth}" height="${attrs._cropHeight}"
					viewBox="0 0 ${attrs._cropWidth} ${attrs._cropHeight}"
					preserveAspectRatio="xMidYMid slice"
				>
					<image
					width="${attrs.width}" height="${attrs.height}"
					xlink:href="{{${attrs.id}}}"
					/>
				</svg>`
					} else {
						return `<image ${getTransform(shape)}
				width="${attrs.width}" height="${attrs.height}"
				xlink:href="{{${attrs.id}}}"
				/>`
					}
				}
				const base64 = await toBase64(shape.image())
				if (attrs._cropWidth || attrs._cropHeight) {
					return `<svg 
            x="${attrs._cropX}"
			y="${attrs._cropY}"
            width="${attrs._cropWidth}" height="${attrs._cropHeight}" 
            viewBox="0 0 ${attrs._cropWidth} ${attrs._cropHeight}" 
            preserveAspectRatio="xMidYMid slice"
          >
            <image
			transform="translate(${(attrs._cropWidth - attrs.width) / 2} ${(attrs._cropHeight - attrs.height) / 2})"
            width="${attrs.width}" height="${attrs.height}"
            xlink:href="${base64}"
            />
          </svg>`
				} else {
					return `<image ${getTransform(shape)}
			width="${attrs.width}" height="${attrs.height}"
			xlink:href="${base64}"/>`
				}

			case 'Rect':
				return `<rect ${getTransform(shape)}
			width="${attrs.width}" height="${attrs.height}"
			fill="${attrs.fill || 'none'}"
			stroke="${attrs.stroke || 'none'}"
			stroke-width="${attrs.strokeWidth || 0}"/>`

			case 'Circle':
				return `<circle ${getTransform(shape)}
			r="${attrs.radius}"
			fill="${attrs.fill || 'none'}"
			stroke="${attrs.stroke || 'none'}"
			stroke-width="${attrs.strokeWidth || 0}"/>`

			case 'Line':
				return `<line ${getTransform(shape)}
			x1="${attrs.points[0]}" y1="${attrs.points[1]}"
			x2="${attrs.points[2]}" y2="${attrs.points[3]}"
			stroke="${attrs.stroke}"
			stroke-width="${attrs.strokeWidth}"/>`

			default:
				console.warn(`Shape type ${shape.getClassName()} not supported`)
				return ''
		}
	}

	// Process all layers
	let content = ''
	for (const layer of stage.getLayers()) {
		content += '<g>' // Wrap layer in a group
		for (const shape of layer.getChildren()) {
			content += await convertShape(shape)
		}
		content += '</g>'
	}

	return svg + content + '</svg>'
}

export const calculateBoxFitCover = (params) => {
	const {
		img: { width: imgWidth, height: imgHeight },
		box: { width: boxWidth, height: boxHeight, positionx: boxX, positiony: boxY }
	} = params;

	// Calculate scale ratios
	const scaleX = boxWidth / imgWidth;
	const scaleY = boxHeight / imgHeight;

	// Use the larger scaling factor to ensure coverage
	const scale = Math.max(scaleX, scaleY);

	// Calculate new image dimensions
	const newWidth = imgWidth * scale;
	const newHeight = imgHeight * scale;

	// Calculate position to center the image in the box
	const newX = boxX + (boxWidth - newWidth) / 2;
	const newY = boxY + (boxHeight - newHeight) / 2;

	return {
		width: newWidth,
		height: newHeight,
		x: newX,
		y: newY
	};
}

/**
 * Processes text content and replaces placeholders with actual data
 * @param {string} text - The text content with potential placeholders
 * @param {object} data - Object containing the data to replace placeholders
 * @returns {string} - Text with placeholders replaced with actual data
 */
export const processTextPlaceholders = (text, data) => {
	if (!text) return '';

	// Define all supported placeholders and their corresponding values
	const placeholders = {
		'{{productName}}': data.productName || '',
		'{{productPrice}}': data.productPrice || '',
		'{{companyName}}': data.companyName || '',
	};

	// Replace all placeholders in the text
	let processedText = text;
	Object.entries(placeholders).forEach(([placeholder, value]) => {
		processedText = processedText.replace(new RegExp(placeholder, 'g'), value);
	});

	return processedText;
};
