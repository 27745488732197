import React, { useEffect, useState } from 'react'

import { FaArrowRight, FaQuestionCircle, FaTimesCircle } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ProgressBar from '../../shared/components/UIElements/ProgressBar'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { adHealthCheckupActions } from '../../store/ad-health-checkup'
import useAdHealthCheckup from '../../shared/hooks/ad-health-checkup-hook'

import './ConnectionConfig.css'

import { useLocation, useNavigate } from 'react-router-dom'
import ConnectFacebook from '../components/ConnectFacebook'
import Button from '../../shared/components/FormElements/Button'
import { useModal } from '../../shared/hooks/modal-hook'
import { toast } from 'react-toastify'
import needAssistanceIcon from '../../shared/assets/images/icons/need-assistance.svg'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'
import { PopupButton } from "react-calendly"

const CheckupConnectionConfig = () => {
  const user = useSelector(state => state.auth.user)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [percentage, setPercentage] = useState(0)
  const [assistanceCardIsVisible, setAssistanceCardIsVisible] = useState(false)
  const [isAssistanceCardExtended, setIsAssistanceCardExtended] = useState(true)

  const [stepCompletions, setStepCompletions] = useState({
    storeConnected: false,
    loggedInToFacebook: false,
    selectedBusiness: false,
    selectedAdAccount: false,
    selectedPixel: false,
  })

  const isAllCardsVisible = user?.isSpecialUser

  const [isEnhencerPixel, setIsEnhencerPixel] = useState(false)
  const location = useLocation()
  const isLoggedIn = useSelector(state => state.facebook.isLoggedIn)
  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const businessSelectionIsEligible = useSelector(state => state.facebook.businessSelectionIsEligible)
  const pixelSelectionIsEligible = useSelector(state => state.facebook.pixelSelectionIsEligible)
  const adAccountSelectionIsEligible = useSelector(state => state.facebook.adAccountSelectionIsEligible)

  const { loading } = useSelector(state => state.adHealthCheckup)
  const query = new URLSearchParams(window.location.search)
  const isEditPage = query.get('editConnections')
  const [h1Text, setH1Text] = useState("Let's start with your Facebook account")

  useEffect(() => {
    setTimeout(() => {
      setAssistanceCardIsVisible(true)
    }, 10000);
  }, [])


  useEffect(() => {
    if (location.search !== '') {
      const query = new URLSearchParams(window.location.search)
      if (query.get('io')) {
        const io = parseInt(query.get('io'))

      }
    }
  }, [location])

  useEffect(() => {
    let p = 0
    setStepCompletions({
      loggedInToFacebook: isLoggedIn,
      selectedBusiness: isLoggedIn && selectedBusiness?.id && businessSelectionIsEligible,
      selectedAdAccount: isLoggedIn && selectedAdAccount?.id && adAccountSelectionIsEligible,
      selectedPixel: isLoggedIn && selectedPixel?.id && pixelSelectionIsEligible,
    })

    let newH1Text = "Let's start with your Facebook account"

    if (isLoggedIn) {
      p += 25
      newH1Text = "Now, proceed with your business"
    }
    if (isLoggedIn && selectedBusiness?.id && businessSelectionIsEligible) {
      p += 25
      newH1Text = "Select your active Ad Account"
    }
    if (isLoggedIn && selectedAdAccount?.id && adAccountSelectionIsEligible) {
      p += 25
      newH1Text = "Lastly, your Facebook Pixel"
    }
    if (isLoggedIn && selectedPixel?.id && pixelSelectionIsEligible) {
      p += 25
      newH1Text = "You are all set!"
    }

    setH1Text(newH1Text)
    setPercentage(p)

    // if (p === 100) {
    //   if (!isEnhencerPixel && !isEditPage) {
    //     dispatch(adHealthCheckupActions.setConnected(true))
    //     navigate('/ad-health-checkup')
    //   }
    // }
  }, [
    isLoggedIn,
    selectedAdAccount,
    selectedBusiness,
    selectedPixel,
    businessSelectionIsEligible,
    pixelSelectionIsEligible,
    adAccountSelectionIsEligible,
    loading,
  ])

  useEffect(() => {
    if (selectedAdAccount?.id === 'act_629703075077770') {
      setIsEnhencerPixel(true)
      toast.warn('Do not run the calculations with Enhencer Ad Account')
    }
  }, [selectedAdAccount])

  return (
    <div className="config-page">
      <div className="breadcrumb-sticky-wrapper">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <Breadcrumb>
            <span>Connections</span>
          </Breadcrumb>
        </div>
      </div>
      <h1>{h1Text}</h1>
      <div className="config-page-content">
        <div className="process-status-box">
          <div className="process-text">
            Integration Process Completed: &nbsp;
            <span className="emphesize">{percentage}%</span>
          </div>
          <ProgressBar percentage={percentage} height={14} />
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <React.Fragment>

            <div className="config-grid configure-facebook">
              <ConnectFacebook
                stepCompletions={stepCompletions}
                isCheckup={true}
              />
            </div>

            {assistanceCardIsVisible &&
              <div className={`need-assistance ${isAssistanceCardExtended ? 'is-extended' : ''}`} onClick={() => { if (!isAssistanceCardExtended) setIsAssistanceCardExtended(true) }}>
                {
                  isAssistanceCardExtended &&
                  <div className='' onClick={(e) => { e.stopPropagation(); setIsAssistanceCardExtended(false) }}>
                    <FaTimesCircle className='close-icon' />
                  </div>
                }
                <div>
                  <img src={needAssistanceIcon} className='icon' />
                </div>
                <div className='right-side'>
                  <div className='text'>

                    Having trouble with your connections?
                  </div>
                  <PopupButton
                    url={process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_LINK}
                    rootElement={document.getElementById('root')}
                    prefill={{
                      email: user?.email || '',
                      name: user?.name || '',
                    }}
                    text="Book a demo with us!"
                    className="button"
                  />
                </div>

              </div>
            }

          </React.Fragment>
        )}
      </div>
      {percentage === 100 && (
        <div className="config-completed">
          Your connections are completed, you can see your ad health checkup
          now.
          <Button to="/ad-health-checkup">
            Go to Ad Health Checkup
            <FaArrowRight />
          </Button>
        </div>
      )}
    </div>
  )
}

export default CheckupConnectionConfig
