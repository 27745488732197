import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FiAlertCircle } from 'react-icons/fi'
import { FaChevronDown, FaArrowRight, FaExternalLinkAlt } from 'react-icons/fa'

import { useFacebook } from '../../../shared/hooks/facebook-hook'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { useDebounce } from '../../../shared/hooks/debounce-hook'
import ProductSetDropdownHover from '../../../ai-ads/components/campaign/ProductSetDropdownHover'
import CustomDropdown from '../../../ai-ads/components/campaign/CustomDropdown'
import Button from '../../../shared/components/FormElements/Button'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import ExistingSupplementaryWarning from './ExistingSupplementaryWarning'
import helper from '../../../shared/util/helper'

import styles from './EnhencedCatalogProductsSelectionList.module.css'

const EnhencedCatalogProductsSelectionList = () => {
  const navigate = useNavigate()
  const {
    getCatalogs: getCatalogsFromFacebook,
    getProductSetsOfCatalog,
    getProductFeedsFromFacebook,
  } = useFacebook()
  const {
    loadCachedSelectedProducts,
    areCatalogProductsFetched,
    setAreCatalogProductsFetched,
    startFetchingCatalogProducts,
    getCatalogProductsFromDB,
    getCatalogProductsFromFacebook,
  } = useEnhencedCatalog()
  const catalogs = useSelector(state => state.facebook.catalogs)
  const productSets = useSelector(state => state.facebook.productSets)
  const productFeeds = useSelector(state => state.facebook.productFeeds)
  const selectedBusinessId = useSelector(
    state => state.facebook.selectedBusiness?.id,
  )
  const facebookReady = useSelector(
    state => state.facebook.connectionPageIsReady,
  )
  const [selectedCatalog, setSelectedCatalog] = useState(catalogs[0])
  const [selectedProductSet, setSelectedProductSet] = useState()
  const [primaryFeeds, setPrimaryFeeds] = useState([])
  const [supplementaryFeeds, setSupplementaryFeeds] = useState([])
  const [selectedPrimaryFeed, setSelectedPrimaryFeed] = useState(null)
  const [isSupplementaryExists, setIsSupplementaryExists] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText)
  const [isFetchingCatalogs, setIsFetchingCatalogs] = useState(false)
  const [isFetchingProductFeeds, setIsFetchingProductFeeds] = useState(false)
  const [isFetchingProducts, setIsFetchingProducts] = useState(false)
  const [isFetchingProductSets, setIsFetchingProductSets] = useState(false)
  const [paginationCursor, setPaginationCursor] = useState(null)
  const [fetchingNextPage, setFetchingNextPage] = useState(false)
  const hasSearched = useRef(false)

  const isFirstRender = useRef(true)

  const getCatalogs = async () => {
    setIsFetchingCatalogs(true)
    const newCatalogs = await getCatalogsFromFacebook({})
    setSelectedCatalog(newCatalogs[0])
    setIsFetchingCatalogs(false)
  }

  const getProductSets = async catalogId => {
    if (!catalogId) return

    setIsFetchingProductSets(true)
    const newProductSets = await getProductSetsOfCatalog({ catalogId })
    setSelectedProductSet(newProductSets[0])
    setIsFetchingProductSets(false)
  }

  const getProductFeeds = async catalogId => {
    if (!catalogId) return
    setIsFetchingProductFeeds(true)
    await getProductFeedsFromFacebook({
      catalogId,
      getFromCacheIfAvailable: false,
    })
    setIsFetchingProductFeeds(false)
  }

  const getProducts = async ({ edgeName, edgeId, filter, after }) => {
    setIsFetchingProducts(true)

    try {
      const dbProducts = await getCatalogProductsFromDB({
        catalogId: selectedCatalog.id,
        productSetId: selectedProductSet.id,
        primaryFeedId: selectedPrimaryFeed.id,
        after,
      })

      if (!after) {
        setProductsList(dbProducts.products)
      } else {
        setProductsList(prev => [...prev, ...dbProducts.products])
      }
      setPaginationCursor(dbProducts.paging?.cursors?.after)
      setIsFetchingProducts(false)
      return
    } catch (error) {
      // If products not found in database or other error, fetch from Facebook
      try {
        if (!selectedCatalog || !selectedProductSet || !selectedPrimaryFeed) {
          throw new Error(
            'Required catalog, product set, or feed information is missing',
          )
        }

        const fbResult = await getCatalogProductsFromFacebook({
          edgeName,
          edgeId,
          filter,
          after,
          productSetId: selectedProductSet.id,
        })

        if (!fbResult.products || fbResult.products.length < 25) {
          setPaginationCursor(null)
        } else {
          setPaginationCursor(fbResult.paging?.cursors?.after)
        }

        if (after) {
          setProductsList(prev => [...prev, ...fbResult.products])
        } else {
          setProductsList(fbResult.products)
        }
      } catch (fbError) {
        console.error('Error fetching products from Facebook:', fbError)
        setProductsList([])
        setPaginationCursor(null)
      }
    }

    await helper.timeout(500) // TODO: Find a better way to wait for the products to be set
    setIsFetchingProducts(false)
  }

  const searchProducts = async query => {
    const filter = JSON.stringify({
      name: {
        contains: query,
      },
    })
    setIsFetchingProducts(true)

    await getProducts({
      edgeName: 'productFeed',
      edgeId: selectedPrimaryFeed.id,
      filter,
    })
    setIsFetchingProducts(false)
  }

  const handleNextButton = async () => {
    if (isSupplementaryExists) {
      return
    }

    sessionStorage.setItem(
      'enhenced_catalog_product_on_canvas',
      JSON.stringify(productsList[0]),
    )

    navigate(
      `/ai-ads/creative-lab/editor?mode=enhenced-catalog&catalogId=${selectedCatalog.id}&productSetId=${selectedProductSet.id}&primaryFeedId=${selectedPrimaryFeed.id}`,
    )
  }

  const handleLoadMoreButton = async () => {
    if (!paginationCursor) return
    setFetchingNextPage(true)
    await getProducts({
      edgeName: 'productFeed',
      edgeId: selectedPrimaryFeed.id,
      after: paginationCursor,
    })
    setFetchingNextPage(false)
  }

  // Check if there are selected products in session storage and set them
  useEffect(() => {
    loadCachedSelectedProducts()
  }, [])

  // Fetch catalogs and set the first one as selected
  useEffect(() => {
    if (!facebookReady || catalogs.length) return
    getCatalogs()
  }, [facebookReady])

  // Fetch product sets and product feeds of selected catalog
  useEffect(() => {
    if (
      !facebookReady ||
      !selectedCatalog ||
      productSets[selectedCatalog]?.length
    )
      return
    getProductSets(selectedCatalog.id)
    getProductFeeds(selectedCatalog.id)
  }, [selectedCatalog])

  // Fetch products when selected primary feed changes
  useEffect(() => {
    if (!facebookReady || !selectedPrimaryFeed) return

    if (isFirstRender.current) {
      sessionStorage.removeItem(`pf_${selectedPrimaryFeed.id}_products`)
      isFirstRender.current = false
    }

    getProducts({
      edgeName: 'productFeed',
      edgeId: selectedPrimaryFeed.id,
    })
  }, [selectedPrimaryFeed, facebookReady])

  // Search products when debounced search text changes
  useEffect(() => {
    if (!facebookReady || !hasSearched.current) {
      return
    }
    searchProducts(debouncedSearchText)
    hasSearched.current = true
  }, [debouncedSearchText])

  // Set primary and supplementary feeds
  useEffect(() => {
    if (!facebookReady || !productFeeds.length) {
      setPrimaryFeeds([])
      setSupplementaryFeeds([])
      setSelectedPrimaryFeed(null)
      setProductsList([])
      return
    }

    let primaryFeeds = []
    let supplementaryFeeds = []

    productFeeds.forEach(feed => {
      if (feed.ingestion_source_type.toLowerCase() === 'supplementary_feed') {
        supplementaryFeeds.push(feed)
      } else {
        primaryFeeds.push(feed)
      }
    })

    setPrimaryFeeds(primaryFeeds)
    setSupplementaryFeeds(supplementaryFeeds)
    setSelectedPrimaryFeed(primaryFeeds[0])
  }, [productFeeds, facebookReady])

  // Check if the selected primary feed has supplementary feeds
  useEffect(() => {
    if (!facebookReady || !selectedPrimaryFeed) return

    setIsSupplementaryExists(
      supplementaryFeeds.some(feed =>
        feed?.primary_feeds?.includes(selectedPrimaryFeed.id),
      ),
    )
  }, [supplementaryFeeds, facebookReady, selectedPrimaryFeed])

  // Reset areCatalogProductsFetched to false
  useEffect(() => {
    if (areCatalogProductsFetched) {
      setAreCatalogProductsFetched(false)
    }
  }, [areCatalogProductsFetched])

  return (
    <div className={styles.productSelectionList}>
      <div className={styles.leftSide}>
        <div className={styles.mainColumn}>
          <div className={styles.leftSideHeader}>
            {catalogs.length > 0 && (
              <div className={styles.row}>
                <div className={styles.catalogDropdown}>
                  <label className={styles.label}>Catalog</label>
                  <CustomDropdown
                    placeholder="Select catalog..."
                    initialValue={selectedCatalog}
                    forcedValue={selectedCatalog}
                    options={catalogs}
                    isSingleLine={true}
                    onChange={c => setSelectedCatalog(c)}
                    onHover={(edgeId, product_count) => (
                      <ProductSetDropdownHover
                        edgeId={edgeId}
                        productCount={product_count}
                      />
                    )}
                  />
                </div>
                {selectedCatalog && productSets[selectedCatalog.id] && (
                  <div className={styles.catalogDropdown}>
                    <label className={styles.label}>Product Set</label>
                    <CustomDropdown
                      placeholder="Select product set..."
                      initialValue={selectedProductSet}
                      forcedValue={selectedProductSet}
                      options={productSets[selectedCatalog.id]}
                      isSingleLine={true}
                      onChange={p => setSelectedProductSet(p)}
                      onHover={(edgeId, product_count) => (
                        <ProductSetDropdownHover
                          edgeId={edgeId}
                          productCount={product_count}
                        />
                      )}
                    />
                  </div>
                )}
                {selectedCatalog && primaryFeeds.length > 0 && (
                  <div className={styles.catalogDropdown}>
                    <label className={styles.label}>Primary Feed</label>
                    <CustomDropdown
                      placeholder="Select primary feed..."
                      initialValue={selectedPrimaryFeed}
                      forcedValue={selectedPrimaryFeed}
                      options={primaryFeeds}
                      isSingleLine={true}
                      onChange={p => setSelectedPrimaryFeed(p)}
                    />
                  </div>
                )}
                <div className={styles.nextButtonContainer}>
                  <Button
                    className={styles.nextButton}
                    primary={true}
                    onClick={handleNextButton}
                    disabled={
                      !selectedCatalog ||
                      !selectedPrimaryFeed ||
                      isSupplementaryExists ||
                      !selectedPrimaryFeed?.latest_upload
                    }
                  >
                    Continue
                    <FaArrowRight className={styles.nextButtonIcon} />
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className={styles.productsList}>
            {isFetchingCatalogs ||
            isFetchingProductFeeds ||
            isFetchingProducts ||
            isFetchingProductSets ? (
              <InlineLoadingSpinner
                message={
                  isFetchingCatalogs
                    ? 'Loading catalogs...'
                    : isFetchingProductFeeds
                      ? 'Loading product feeds...'
                      : isFetchingProducts
                        ? 'Loading products...'
                        : 'Loading product sets...'
                }
              />
            ) : selectedCatalog && !primaryFeeds?.length ? (
              <div className={styles.helperText}>
                <FiAlertCircle className={styles.helperIcon} size={24} />
                <div>
                  No primary feed found. <br></br>Please
                  <a
                    href={`https://business.facebook.com/commerce/catalogs/${selectedCatalog?.id}/products/?business_id=${selectedBusinessId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    add a product feed <FaExternalLinkAlt />
                  </a>
                  on Facebook to proceed.
                </div>
              </div>
            ) : selectedPrimaryFeed && !selectedPrimaryFeed?.latest_upload ? (
              <div className={styles.helperText}>
                <FiAlertCircle size={24} className={styles.helperIcon} />
                <div>
                  The product feed is currently inactive. Visit Facebook to
                  <a
                    href={`https://business.facebook.com/commerce/catalogs/${selectedCatalog?.id}/products/?business_id=${selectedBusinessId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    update and activate your product feed <FaExternalLinkAlt />
                  </a>
                </div>
              </div>
            ) : isSupplementaryExists ? (
              <ExistingSupplementaryWarning
                selectedCatalog={selectedCatalog}
                selectedPrimaryFeed={selectedPrimaryFeed}
                isLoading={isFetchingProductFeeds}
                onTryAgain={async () => {
                  setIsFetchingProductFeeds(true)

                  sessionStorage.removeItem(
                    `pf_${selectedPrimaryFeed.id}_products`,
                  )

                  if (searchText) {
                    setSearchText('')
                    hasSearched.current = false
                  }

                  await getProductFeeds(selectedCatalog.id)
                  setIsFetchingProductFeeds(false)
                }}
              />
            ) : productsList?.length ? (
              <div className={styles.productsGrid}>
                {productsList.map(product => (
                  <div className={styles.productContainer} key={product.id}>
                    <div className={styles.productImage}>
                      <img src={product.image_url} alt={product.name} />
                    </div>
                    <div className={styles.price}>{product.price}</div>
                    <div className={styles.nameCont}>
                      <div className={styles.productName}>{product.name}</div>
                    </div>
                  </div>
                ))}
                {paginationCursor && (
                  <Button
                    className={styles.loadMoreButton}
                    primary={true}
                    disabled={fetchingNextPage}
                    loading={fetchingNextPage}
                    onClick={handleLoadMoreButton}
                  >
                    <FaChevronDown className={styles.loadMoreIcon} />
                    Load More
                  </Button>
                )}
              </div>
            ) : selectedCatalog && !primaryFeeds.length ? (
              <div className={styles.helperText}>
                <FiAlertCircle
                  size={24}
                  style={{
                    marginBottom: '0.5em',
                    color: 'var(--main-green)',
                  }}
                />
                <div>
                  <strong>Oops!</strong> This catalog doesn't have a{' '}
                  <strong>product feed</strong> linked yet.
                </div>
                <div style={{ margin: '0.5em 0', color: 'var(--text-gray)' }}>
                  To create an Enhenced Catalog, a product feed is required to
                  connect your catalog.
                </div>
                <div>
                  <a
                    href={`https://business.facebook.com/commerce/catalogs/${selectedCatalog?.id}/products/?business_id=${selectedBusinessId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Add a product feed <FaExternalLinkAlt />
                  </a>
                  or <strong>pick another catalog</strong>.
                </div>
              </div>
            ) : selectedCatalog && !productsList?.length ? (
              <div className={styles.helperText}>
                <FiAlertCircle size={24} className={styles.helperIcon} />
                <div>No products found</div>
              </div>
            ) : null}
            {/** TODO: Fix these conditions using early returns */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnhencedCatalogProductsSelectionList
