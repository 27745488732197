import { WORKBENCH_SIZE } from '../../../support/constants'
import temp8bg from '../../../../shared/assets/images/ai-creative/temp8bg.svg'
import temp8bg2 from '../../../../shared/assets/images/ai-creative/temp8bg2.svg'

export const Template8Config = {
  name: 'Midnight Sparkle',
  containerId: 'template8-carousel-single',
  backgroundColor: 'color2',
  hasDiscount: true,
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp8bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#FFBD8B',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: { x: WORKBENCH_SIZE.width / 2, y: WORKBENCH_SIZE.height / 2 },
      center: true,
      scale: { maxWidth: 650, maxHeight: 750 },
      dynamicSrc: 'productImage',
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp8bg2,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: { x: 35, y: 40 },
      width: 365,
      text: '{{productName}}',
      fontFamily: 'Bayon',
      fontSize: 60,
      color: 'color1',
      align: 'left',
      toUpperCase: true,
      maxLines: 1,
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: { x: 35, y: 110 },
      width: 365,
      text: '{{productPrice}}',
      fontFamily: 'Bayon',
      fontSize: 80,
      fontStyle: '500',
      color: 'color1',
      align: 'left',
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: { x: 35, y: WORKBENCH_SIZE.height - 180 },
      text: `30% DISCOUNT!`,
      fontFamily: 'Bayon',
      fontSize: 86,
      letterSpacing: 0.8,
      color: 'color1',
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: { x: 35, y: WORKBENCH_SIZE.height - 100 },
      text: `USE CODE: GET30`,
      fontFamily: 'Bayon',
      fontSize: 48,
      letterSpacing: 3.5,
      color: 'color3',
    },
  ],
}
