import { createSlice } from '@reduxjs/toolkit'
import CreativeEditorMainDisplayOptions from '../shared/enums/CreativeEditorMainDisplayOptions'

const initialState = {
  backgroundRemoving: false,
  error: null,
  isStageLoading: false,
  selectedProductsWithConfigurations: sessionStorage.getItem('aiCreativeProducts')
    ? JSON.parse(sessionStorage.getItem('aiCreativeProducts'))
    : [],
  draftConfiguration: {
    initial: true,
  },
  productOnDraft: null,
  isSingleSaving: false,
  isBatchSaving: false,
  isEnhencedCatalogSaving: false,
  result: null,
  activeMainDisplay: CreativeEditorMainDisplayOptions.PRODUCTS,
  previewHelperText: null,
  discount: {
    isActive: false,
    type: 'percentage',
    amount: null,
    code: '',
    appliedTo: [],
    priceRuleId: null,
    discountCodeId: null,
  },
  companyDetails: {
    companyLogoUrl: '',
    usesBgRemovedLogo: false,
  },
  savingQueue: [],
  currentSavingProductId: null,
  selectedCatalogId: null,
  selectedProductSetId: null,
  areCatalogProductsFetched: false,
  totalProducts: 0,
}

const aiCreativeEditorSlice = createSlice({
  name: 'aiCreativeEditor',
  initialState: initialState,
  reducers: {
    setIsStageLoading(state, action) {
      state.isStageLoading = action.payload
    },
    setIsSingleSaving(state, action) {
      state.isSingleSaving = action.payload
    },
    setIsBatchSaving(state, action) {
      state.isBatchSaving = action.payload
    },
    setIsEnhencedCatalogSaving(state, action) {
      state.isEnhencedCatalogSaving = action.payload
    },
    setBackgroundRemoving(state, action) {
      state.backgroundRemoving = action.payload
    },
    setActiveMainDisplay(state, action) {
      state.activeMainDisplay = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setCompanyDetails(state, action) {
      state.companyDetails = {
        ...state.companyDetails,
        ...action.payload,
      }
    },
    setDiscountDetails(state, action) {
      state.discount = {
        ...state.discount,
        ...action.payload,
      }
    },
    setSelectedProducts(state, action) {
      state.selectedProductsWithConfigurations = action.payload || []
      sessionStorage.setItem(
        'aiCreativeProducts',
        action.payload ? JSON.stringify(action.payload) : [],
      )
    },
    setDraftConfiguration(state, action) {
      const payload = action.payload

      const mode = payload.mode
      delete payload.mode

      if (!payload.initial) {
        payload.initial = false
      }

      state.draftConfiguration = {
        ...state.draftConfiguration,
        ...payload,
      }

      if (!payload.initial) {
        state.selectedProductsWithConfigurations =
          state.selectedProductsWithConfigurations.map(p => {
            if (p.id === state.productOnDraft?.id) {
              p.isSaved = false
            }
            return p
          })

        if (state.productOnDraft) {
          state.productOnDraft.isSaved = false
        }

        if (true || mode !== 'enhenced-catalog') {
          sessionStorage.setItem(
            'aiCreativeProducts',
            JSON.stringify(state.selectedProductsWithConfigurations),
          )
        }
      }
    },
    setProductOnDraft(state, action) {
      state.productOnDraft = action.payload
    },
    unsetConfigurationOfProductOnDraft(state) {
      state.selectedProductsWithConfigurations =
        state.selectedProductsWithConfigurations.map(p => {
          if (p.id === state.productOnDraft?.id) {
            p.configuration = null
          }
          return p
        })
      sessionStorage.setItem(
        'aiCreativeProducts',
        JSON.stringify(state.selectedProductsWithConfigurations),
      )
    },
    setConfigurationToProduct(state, action) {
      const { productId, configuration, history } = action.payload
      const { initial, ...restOfConfiguration } = configuration

      state.selectedProductsWithConfigurations =
        state.selectedProductsWithConfigurations.map(p => {
          if (p.id === productId) {
            if (productId === state.productOnDraft.id) {
              state.productOnDraft = {
                ...p,
                configuration: { ...restOfConfiguration },
                preview: null,
                history: [history.at(-1)],
                historyStep: 0,
              }
            }

            return {
              ...p,
              configuration: { ...restOfConfiguration },
              preview: null,
              history: [history.at(-1)],
              historyStep: 0,
            }
          }
          return p
        })
    },
    setPreviewToProduct(state, action) {
      const { productId, preview } = action.payload

      if (!preview) return

      state.selectedProductsWithConfigurations =
        state.selectedProductsWithConfigurations.map(p => {
          if (p.id === productId) {
            if (productId === state.productOnDraft.id) {
              state.productOnDraft = { ...p, preview, isSaved: true }
            }
            return {
              ...p,
              preview,
              isSaved: true,
            }
          }
          return p
        })
      state.draftConfiguration.initial = true

      sessionStorage.setItem(
        'aiCreativeProducts',
        JSON.stringify(state.selectedProductsWithConfigurations),
      )
    },
    applyConfigurationToAllProducts(state, action) {
      const { configuration, history, historyStep } = action.payload
      const { initial, productName, ...restOfConfiguration } = configuration

      state.selectedProductsWithConfigurations =
        state.selectedProductsWithConfigurations.map(p => ({
          ...p,
          configuration: { ...restOfConfiguration },
          preview: null,
          history: [
            history[historyStep],
          ],
          historyStep: 0,
        }))
    },
    updateAllProductsWithConfigurations(state, action) {
      state.selectedProductsWithConfigurations = action.payload
    },
    resetEnhencedCatalog(state) {
      state.selectedProductsWithConfigurations = []
      state.draftConfiguration = null
      state.productOnDraft = null
      state.isSingleSaving = false
      state.result = null
      state.discount = {
        isActive: false,
        type: 'percentage',
        amount: null,
        code: '',
        appliedTo: [],
        priceRuleId: null,
        discountCodeId: null
      }
      state.companyDetails = {
        companyLogoUrl: '',
        usesBgRemovedLogo: false
      }
    },
    setResult(state, action) {
      state.result = action.payload
    },
    setPreviewHelperText(state, action) {
      state.previewHelperText = action.payload
    },
    setDraftConfigurationTexts(state, action) {
      state.draftConfiguration = {
        ...state.draftConfiguration,
        ...action.payload,
      }
    },
    setHistory(state, action) {
      const newProducts = state.selectedProductsWithConfigurations.map(p => {
        if (p.id === state.productOnDraft.id) {
          state.productOnDraft.history = action.payload
          return {
            ...p,
            history: action.payload,
          }
        }
        return p
      })
      state.selectedProductsWithConfigurations = newProducts
    },
    setHistoryByKey(state, action) {
      const { key, value } = action.payload

      const newProducts = state.selectedProductsWithConfigurations.map(p => {
        if (p.id === state.productOnDraft.id) {
          p.history[p.historyStep][key] = value
          state.productOnDraft.history[p.historyStep][key] = value
        }
        return p
      })

      state.selectedProductsWithConfigurations = newProducts
    },
    setHistoryStep(state, action) {
      const newProducts = state.selectedProductsWithConfigurations.map(p => {
        if (p.id === state.productOnDraft.id) {
          p.historyStep = action.payload
          state.productOnDraft.historyStep = action.payload
        }

        return p
      })
      state.selectedProductsWithConfigurations = newProducts
    },
    addToHistory(state, action) {
      const { key, value } = action.payload
      const MAX_HISTORY = 15

      const newProducts = state.selectedProductsWithConfigurations.map(p => {
        if (p.id === state.productOnDraft.id) {

          // Remove future histories when adding at an intermediate step
          p.history = p.history.slice(0, p.historyStep + 1)

          const newHistoryEntry = {
            ...(p.history[p.historyStep] || {}),
            [key]: value,
          }

          // Add the new entry, by removing the oldest entry if the history capacity is reached
          p.history = [
            ...p.history.slice(1 - MAX_HISTORY),
            newHistoryEntry,
          ]
          state.productOnDraft.history = p.history

          p.historyStep = p.history.length - 1
          state.productOnDraft.historyStep = p.historyStep

          p.isSaved = false // Reset the saved state when adding to history
          state.productOnDraft.isSaved = false
        }
        return p
      })

      state.selectedProductsWithConfigurations = newProducts
      state.productOnDraft.isSaved = false

      sessionStorage.setItem('aiCreativeProducts', JSON.stringify(newProducts))
    },
    setIsComparingWithOriginal(state, action) {
      state.isComparingWithOriginal = action.payload
    },
    setSavingQueue(state, action) {
      state.savingQueue = action.payload
    },
    setCurrentSavingProductId(state, action) {
      state.currentSavingProductId = action.payload
    },
    setSelectedCatalogId(state, action) {
      state.selectedCatalogId = action.payload
    },
    setSelectedProductSetId(state, action) {
      state.selectedProductSetId = action.payload
    },
    setAreCatalogProductsFetched(state, action) {
      state.areCatalogProductsFetched = action.payload
    },
    setTotalProducts(state, action) {
      state.totalProducts = action.payload
    },
  },
})

export const aiCreativeEditorActions = aiCreativeEditorSlice.actions

export default aiCreativeEditorSlice.reducer
