import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_PASSWORD_MATCH,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useAuth } from '../../shared/hooks/auth-hook'
import StrokeInput from '../../shared/components/FormElements/StrokeInput'
import Button from '../../shared/components/FormElements/Button'
import backgroundImage from '../../shared/assets/images/register-decor/password-computer-bg.png'

import './Auth.css'

import 'react-toastify/dist/ReactToastify.css'

import IntroHeadlineContainer from '../components/intro/IntroHeadlineContainer'
import errorHandler from '../../shared/util/errorHandler'
import { authActions } from '../../store/auth'

const CompleteRegistrationPassword = props => {
  const [busy, setBusy] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const isMounted = useRef(true)
  const dispatch = useDispatch()

  const { createPassword } = useAuth()
  const user = useSelector(state => state.auth.user)

  const headlines = user?.name
    ? [
        {
          text: 'Welcome, ',
          highlight:
            user.name.split(' ')[0].charAt(0).toUpperCase() +
            user.name.split(' ')[0].slice(1).toLowerCase() +
            '!',
          text2: 'Choose a password.',
        },
      ]
    : [
        {
          highlight: 'Welcome! ',
          text: 'Choose a password.',
        },
      ]
  const [formState, inputHandler] = useForm(
    {
      password: {
        value: '',
        isValid: false,
      },
      confirmPassword: {
        value: '',
        isValid: false,
      },
    },
    false,
  )

  useEffect(() => {
    handleAppClass(true)
    dispatch(
      authActions.updateUserObjOneField({
        field: 'isInSignupFlow',
        value: true,
      }),
    )
    return () => {
      handleAppClass(false)
      isMounted.current = false
    }
  }, [])

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-noscroll')
      : document.getElementById('app')?.classList.remove('full-screen-noscroll')
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const submit = async event => {
    event.preventDefault()

    const { password, confirmPassword } = formState.inputs

    if (password.value !== confirmPassword.value) {
      alert('Passwords do not match.')
      return
    }

    setBusy(true)

    try {
      await createPassword({
        password: password.value,
      })
    } catch (err) {
      errorHandler(err)
    }

    if (isMounted.current) {
      setBusy(false)
    }
  }

  return (
    <div className="full-container sign-up">
      <div className="login-cont sign-up-cont">
        <div className="sign-up-form-cont">
          <IntroHeadlineContainer
            headlines={headlines}
            animationEnabled={false}
          />
          <form onSubmit={submit}>
            <StrokeInput
              element="input"
              id="password"
              type="password"
              label="Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid password, at least 6 characters."
              onInput={inputHandler}
              showPasswordToggle={true}
              showPassword={showPassword}
              onTogglePassword={togglePasswordVisibility}
            />
            <StrokeInput
              element="input"
              id="confirmPassword"
              type="password"
              label="Confirm Password"
              validators={[
                VALIDATOR_PASSWORD_MATCH(
                  formState.inputs.password.value,
                  formState.inputs.confirmPassword.value,
                ),
              ]}
              errorText="Passwords don't match."
              onInput={inputHandler}
              showPassword={showPassword}
            />
            <div className="cont">
              <Button
                loading={busy}
                className="action-button"
                type="submit"
                disabled={!formState.isValid}
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </div>
      <div className="sign-up-info">
        <img
          alt="Background Image"
          src={backgroundImage}
          className="background"
        />
      </div>
    </div>
  )
}

export default CompleteRegistrationPassword
