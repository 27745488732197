import React, { useEffect } from 'react'
import './IntroComponent.css'

import introBackground from '../../../shared/assets/images/intro/intro-background.svg'

import Button from '../../../shared/components/FormElements/Button'
import ReviewBoxSlider from './ReviewBoxSlider'
import IntroHeadlineContainer from './IntroHeadlineContainer'
import IntroPhonesContainer from './IntroPhonesContainer'

const headlines = [
  {
    text: 'Create Ad Creatives Automatically From Your ',
    highlight: ' Shopify Catalog',
  },
  {
    text: 'Power Your Website with New Traffic with ',
    highlight: ' AI Lookalike',
  },
  {
    text: 'Maximize Your Sales and Scale Your Shop with ',
    highlight: ' AI Remarketing',
  },
  { text: 'Get a Dedicated Performance', highlight: ' Marketing Specialist' },
]

const IntroComponent = ({ onStart }) => {
  useEffect(() => {
    const button = document.querySelector('.intro-start-button')
    button.classList.add('intro-start-button-pulse')

    setTimeout(() => {
      button.classList.remove('intro-start-button-pulse')
    }, 6000)
  }, [])
  return (
    <div
      className="intro-component"
      style={{ backgroundImage: `url(${introBackground})` }}
    >
      <div className="intro-content-wrapper">
        <div className="intro-text-content">
          <IntroHeadlineContainer
            headlines={headlines}
            animationEnabled={true}
            animationDuration={3000}
          />

          <Button className="intro-start-button" onClick={onStart}>
            Start your AI Ads Journey!
          </Button>

          <ReviewBoxSlider />

          <div className="intro-rating">
            {[1, 2, 3, 4, 5].map(star => (
              <span
                key={star}
                className={`intro-star ${star <= 4 ? 'filled' : ''} ${
                  star === 5 ? 'half-filled' : ''
                }`}
              >
                ★
              </span>
            ))}
          </div>
        </div>

        <IntroPhonesContainer />
      </div>
    </div>
  )
}

export default IntroComponent
