import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import aiAdsLogo from '../../shared/assets/images/ai-ads/ai-ads-logo.png'
import roasIcon from '../../shared/assets/images/icons/roas.svg'
import divideSymbol from '../../shared/assets/images/icons/divide-symbol.svg'
import ticketSymbol from '../../shared/assets/images/icons/ticket-symbol.svg'
import aiSymbol from '../../shared/assets/images/icons/ai-symbol.svg'
import helper from '../../shared/util/helper'

import './AdSuccess.css'

const AdSuccess = () => {
  const { averageROAS, averageCTR, averageCostPerPurchase } = useSelector(
    state => state.adHealthCheckup.insights,
  )
  const facebook = useSelector(state => state.facebook)

  useEffect(() => {
    // Animate the .column div to come up from below with increasing opacity
    gsap.from('.ad-success-main .column.text', {
      duration: 1,
      x: -50,
      opacity: 0,
      ease: 'power3.out',
    })
    gsap.from('.ad-success-main .column.cards', {
      duration: 1,
      y: 50,
      opacity: 0,
      ease: 'power3.out',
    })
  }, [])

  return (
    <div className="content-box ad-success-main">
      <div className="column text">
        <div className="second-big-text">
          Your Ad Success - <span className="gradient-text">Simplified</span>{' '}
        </div>
        <div className="small-text gradient-text">
          Your store’s most important ad performance KPIs
        </div>
      </div>

      <div className="column cards">
        <div className="top-row">
          <div className="secondary-text part">Your Ads</div>
          <div className="secondary-text part">AI Ads</div>
        </div>

        <div className="card">
          <div className="row content-part">
            <div>
              <img src={roasIcon} />
            </div>
            <div className="column">
              <div className="secondary-text">Average ROAS</div>
              <div className="smaller-text">
                How efficient are your ad campaigns?
              </div>
            </div>
          </div>
          <div className="row numbers-part">
            <div className="old-value">
              <div className="secondary-text">{averageROAS.toFixed(2)}</div>
            </div>
            <div className="new-value">
              <div className="gradient-text secondary-text">
                {(averageROAS * 1.7).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="green-line"></div>

        <div className="card">
          <div className="row content-part">
            <div>
              <img src={divideSymbol} />
            </div>
            <div className="column">
              <div className="secondary-text">Average Clickthrough Rate</div>
              <div className="smaller-text">
                How often are the people seeing your ads clicking it?
              </div>
            </div>
          </div>
          <div className="row numbers-part">
            <div className="old-value">
              <div className="secondary-text">{averageCTR.toFixed(2)}%</div>
            </div>
            <div className="new-value">
              <div className="gradient-text secondary-text">
                {(averageCTR * 1.3).toFixed(2)}%
              </div>
            </div>
          </div>
        </div>

        <div className="green-line"></div>

        <div className="card">
          <div className="row content-part">
            <div>
              <img src={ticketSymbol} />
            </div>
            <div className="column">
              <div className="secondary-text">Average Cost per Purchase</div>
              <div className="smaller-text">
                How much money are you spending to get a purchase?
              </div>
            </div>
          </div>
          <div className="row numbers-part">
            <div className="old-value">
              <div className="secondary-text">
                <span className="currency">
                  {helper.getCurrencySymbol(
                    facebook.selectedAdAccount?.currency,
                  )}
                </span>
                {averageCostPerPurchase.toFixed(2)}
              </div>
            </div>
            <div className="new-value">
              <div className="gradient-text secondary-text">
                ≈
                <span className="currency">
                  {' '}
                  {helper.getCurrencySymbol(
                    facebook.selectedAdAccount?.currency,
                  )}
                </span>
                {(averageCostPerPurchase / 1.4).toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        <div className="card ai-card">
          <div className="row content-part">
            <div>
              <img src={aiSymbol} />
            </div>
            <div className="column">
              <div className="secondary-text">The AI Ads Difference</div>
              <div className="smaller-text">
                Based on the average AI Ads performance increase
              </div>
            </div>
          </div>
          <div className="row numbers-part">
            <div className="new-value">
              <div className="secondary-text">80% More Sales</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdSuccess
