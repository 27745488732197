import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FaCaretDown } from 'react-icons/fa'
import Chart from 'chart.js/auto'
import { useFacebookReport } from '../../../shared/hooks/facebook/report-hook'
import helper from '../../../shared/util/helper'
import numberFormatter from '../../../shared/util/numberFormatter'
import FacebookDatePicker from '../../../shared/components/UIElements/FacebookDatePicker'
import './CampaignReport.css'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import CampaignReportCard from './CampaignReportCard'
import facebookReportingConstants from '../../../shared/data/facebook-reporting-constants'
import { CategoryScale, LinearScale, PointElement, LineElement, Title, Legend, Tooltip } from 'chart.js'
import CampaignReportTable from './CampaignReportTable'
import ActivityLogTable from './ActivityLogTable'

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const { attributionOptions, objectiveOptions, metricTitles, tooltipContents, metricKeys } = facebookReportingConstants


const CampaignReport = ({ singleFullFunnel }) => {

  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser'))
  const [attribution, setAttribution] = useState('7-day click + 1-day view')
  const [objective, setObjective] = useState('Conversion Campaigns')
  const [overallReportData, setOverallReportData] = useState(null)
  const [currencySymbol, setCurrencySymbol] = useState('$')
  const [selectedCards, setSelectedCards] = useState(['Ad Spend', 'Sales'])
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  })
  const [tableData, setTableData] = useState([])


  const fullFunnels = useSelector(state => state.facebook.fullFunnels)
  const fetchingReports = useSelector(state => state.facebook.fetchingReports)
  const filter = useSelector(state => state.facebook.reportsFilter)
  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const allCampaignsOverall = useSelector(state => state.facebook.overallReportForFullFunnels)

  const fullFunnelInViewId = useSelector(state => state.facebook.fullFunnelInViewId)
  const timeseriesChartData = useSelector(state => state.facebook.timeseriesChartData)

  const [chartsData, setChartsData] = useState({})

  const weeklyReports = useSelector(state => state.auth.user.facebookAds?.weeklyReports)
  const [dayCountBeforeEnhencer, setDayCountBeforeEnhencer] = useState(null)
  const [percentageIncrease, setPercentageIncrease] = useState(null)

  const [showEnhBetterMessage, setShowEnhBetterMessage] = useState(false)

  const facebook = useSelector(state => state.facebook)

  const fullFunnelReportsReady = useSelector(state => state.facebook.fullFunnelReportsReady)

  
  useEffect(() => {
    const savedSelectedCards = localStorage.getItem('selectedCards');
    if (savedSelectedCards) {
      try {
        const parsedCards = JSON.parse(savedSelectedCards);
        if (Array.isArray(parsedCards) && parsedCards.length > 0) {
          setSelectedCards(parsedCards);
        }
      } catch (error) {
        console.error('Error parsing saved selected cards:', error);
      }
    }
  }, []);


  useEffect(() => {
    if (selectedCards.length > 0) {
      localStorage.setItem('selectedCards', JSON.stringify(selectedCards));
    }
  }, [selectedCards]);

  useEffect(() => {
    if (weeklyReports && weeklyReports.enhOverall) {
      if (weeklyReports.beforeEnhencerSlice) {
        setDayCountBeforeEnhencer(
          weeklyReports.beforeEnhencerSlice.dayCountBeforeEnhencer,
        )

        let enhOverallSales = weeklyReports.enhOverall.sales
        let beforeEnhencerSales = weeklyReports.beforeEnhencerSlice.sales
        let percentage =
          ((enhOverallSales - beforeEnhencerSales) / beforeEnhencerSales) * 100

        setPercentageIncrease(percentage)
      } else if (weeklyReports.beforeEnhencer) {
        setDayCountBeforeEnhencer(14)

        let enhOverallSales = weeklyReports.enhOverall.sales
        let beforeEnhencerSales = weeklyReports.beforeEnhencer.sales
        let percentage =
          ((enhOverallSales - beforeEnhencerSales) / beforeEnhencerSales) * 100
        setPercentageIncrease(percentage)
      }

      if (percentageIncrease > 0 && dayCountBeforeEnhencer > 0) {
        setShowEnhBetterMessage(true)
      }
    }
  }, [weeklyReports])

  const { newFilterSelectedForReport, updateTimeSeriesChartData, calculateFunnelLevelReportForObjective, calculateFullFunnelsOverallReport } =
    useFacebookReport()

  const allCampaignsOverallData = allCampaignsOverall
    ? allCampaignsOverall[filter.value]
    : {}

  useEffect(() => {
    if (selectedAdAccount?.currency) {
      setCurrencySymbol(helper.getCurrencySymbol(selectedAdAccount.currency))
    }
  }, [selectedAdAccount])

  useEffect(() => {
    const setDisplayedReportData = async campaignResults => {
      // Use sample data in case of error

      let salesKey = 'sales'
      let purchasesKey = 'purchases'
      let costPerPurchaseKey = 'costPerPurchase'
      let roasKey = 'roas'

      if (attribution === '7-day click') {
        salesKey = 'sales_7d_click'
        purchasesKey = 'purchases_7d_click'
        costPerPurchaseKey = 'costPerPurchase_7d_click'
        roasKey = 'roas_7d_click'
      } else if (attribution === '1-day view') {
        salesKey = 'sales_1d_view'
        purchasesKey = 'purchases_1d_view'
        costPerPurchaseKey = 'costPerPurchase_1d_view'
        roasKey = 'roas_1d_view'
      }

      setOverallReportData({
        percentageIncrease: percentageIncrease,
        adSpendMetrics: {
          totalSpend: formatNumberSafely(campaignResults?.spend, 0) || 0,
          dailyBudget: formatNumberSafely(campaignResults?.dailyBudget, 0) || 0,
        },
        reachMetrics: {
          reach: formatNumberSafely(campaignResults?.reach, 0) || 0,
          frequency: formatNumberSafely(campaignResults?.frequency, 3) || 0,
        },
        clicksMetrics: {
          clicks: formatNumberSafely(campaignResults?.clicks, 0) || 0,
          ctr: formatNumberSafely(campaignResults?.ctr, 2) || 0,
        },
        salesMetrics: {
          sales: formatNumberSafely(campaignResults?.[salesKey], 0) || 0,
          purchases:
            formatNumberSafely(campaignResults?.[purchasesKey], 0) || 0,
        },
        performanceMetrics: {
          roas: formatNumberSafely(campaignResults?.[roasKey], 2) || 0,
          costPerPurchase:
            formatNumberSafely(campaignResults?.[costPerPurchaseKey], 0) || 0,
        },
      })
    }

   
    const savedSelectedCards = localStorage.getItem('selectedCards');
    if (!savedSelectedCards) {
      if (objective === "Conversion Campaigns") {
        setSelectedCards(['Ad Spend', 'Sales'])
      } else if (objective === "Boost Campaigns") {
        setSelectedCards(['Ad Spend', 'Reach', 'Clicks'])
      }
    }

    const funnelIdForReport = fullFunnelInViewId || "allFullFunnels"
    if (!fetchingReports && (singleFullFunnel || allCampaignsOverallData)) {
      if (
        !timeseriesChartData ||
        !timeseriesChartData[funnelIdForReport] ||
        !timeseriesChartData[funnelIdForReport][filter.value] ||
        timeseriesChartData[funnelIdForReport][filter.value].objective !== objective
      ) {
        updateTimeSeriesChartData({ filter, fullFunnel: singleFullFunnel, objective })
      } else {
        setChartsData(
          timeseriesChartData[funnelIdForReport][filter.value],
        )
      }

      setDisplayedReportData(
        singleFullFunnel
          ? singleFullFunnel.reports?.funnelLevel[filter.value]
          : allCampaignsOverallData,
      )
    }
  }, [
    filter,
    fetchingReports,
    allCampaignsOverallData,
    singleFullFunnel,
    timeseriesChartData,
    attribution,
    objective,
  ])

  useEffect(() => {
    const fetchAndCreateReport = async () => {

      if (!fetchingReports && fullFunnelReportsReady) {

        if (singleFullFunnel) {
          const funnelReport = calculateFunnelLevelReportForObjective({ singleFullFunnel, filter, objective })
          await createReportForTable()

        } else if (!singleFullFunnel) {
          const funnelLevelReports = await calculateFullFunnelsOverallReport({ fullFunnels, filter, objective })
          await createReportForTable(funnelLevelReports)
        }
      }
    }

    fetchAndCreateReport()
  }, [objective, filter, singleFullFunnel?._id, fetchingReports, fullFunnelReportsReady]);


  const createReportForTable = async (funnelLevelReports) => {
    let finalTableData = []

    if (singleFullFunnel && singleFullFunnel.reports) {
      // we display one full funnel report and it is ready
      let campaignReports = singleFullFunnel.reports.campaignLevel ? singleFullFunnel.reports.campaignLevel[filter.value] : null
      let adsetReports = singleFullFunnel.reports.adsetLevel ? singleFullFunnel.reports.adsetLevel[filter.value] : null

      // filter the reports based on the objective
      if (objective === "Conversion Campaigns") {
        campaignReports = campaignReports?.filter(report => report.objective === "OUTCOME_SALES")
        adsetReports = adsetReports?.filter(report => report.objective === "OUTCOME_SALES")
      } else if (objective === "Boost Campaigns") {
        campaignReports = campaignReports?.filter(report => report.objective !== "OUTCOME_SALES")
        adsetReports = adsetReports?.filter(report => report.objective !== "OUTCOME_SALES")
      }

      // group the campaigns and related adsets
      campaignReports?.forEach(campaign => {
        let campaignLabel = campaign.campaignName
        if (campaignLabel) {
          let arr = campaignLabel.split(" - ")
          if (arr.length > 2) {
            campaignLabel = arr[1].trim() + " - " + arr[2].trim()
          } else if (arr.length > 1) {
            campaignLabel = arr[0].trim() + " - " + arr[1].trim()
          }
        }

        // add the campaign row
        finalTableData.push({
          id: campaign.campaignId,
          type: 'campaign',
          label: campaignLabel,
          effectiveStatus: campaign.effectiveStatus || campaign.status || 'UNKNOWN',
          cardData: {
            'Ad Spend': getCardData('Ad Spend', campaign),
            Reach: getCardData('Reach', campaign),
            Sales: getCardData('Sales', campaign),
            Clicks: getCardData('Clicks', campaign),
            Performance: getCardData('Performance', campaign),
          },
        })

        // find the related adsets
        const relatedAdsets = adsetReports?.filter(adset => adset.campaignId === campaign.campaignId)
        relatedAdsets?.forEach(adset => {
          
          let adsetRow = {
            id: adset.adsetId,
            type: 'adset',
            parentId: campaign.campaignId,
            label: adset.adsetName,
            effectiveStatus: adset.effectiveStatus || adset.status || 'UNKNOWN',
            cardData: {
              'Ad Spend': getCardData('Ad Spend', adset),
              Reach: getCardData('Reach', adset),
              Sales: getCardData('Sales', adset),
              Clicks: getCardData('Clicks', adset),
              Performance: getCardData('Performance', adset),
            },
          }

          if (viewingAsUser) {
            for (const campaign of singleFullFunnel.adCampaigns) {
              for (const adset of campaign.adsets) {
                if (adset.id === adsetRow.id) {
                  adsetRow = {
                    ...adsetRow,
                    audiences: adset.audiences,
                    excludedAudiences: adset.excludedAudiences,
                    includeAdvantagePlusAudience: adset.includeAdvantagePlusAudience,
                    ...(adset.flexibleSpec && { flexibleSpec: adset.flexibleSpec }),
                  }
                  break
                }
              }
            }
          }

          finalTableData.push(adsetRow)
        })
      })

    } else if (!fetchingReports && !singleFullFunnel && funnelLevelReports) {

      funnelLevelReports.forEach(funnel => {

        let funnelReport = funnel


        let funnelLabel = funnel?.label
        if (funnelLabel) {
          let arr = funnelLabel.split(" - ")
          if (arr.length > 2) {
            funnelLabel = arr[1].trim() + " - " + arr[2].trim()
          } else if (arr.length > 1) {
            funnelLabel = arr[0].trim() + (arr[1] ? " - " + arr[1].trim() : "")
          }
        }

        finalTableData.push({
          id: funnel?.id,
          type: 'funnel',
          label: funnelLabel,
          effectiveStatus: funnel?.effectiveStatus || funnel?.status || 'INACTIVE',
          cardData: {
            'Ad Spend': getCardData('Ad Spend', funnelReport),
            Reach: getCardData('Reach', funnelReport),
            Sales: getCardData('Sales', funnelReport),
            Clicks: getCardData('Clicks', funnelReport),
            Performance: getCardData('Performance', funnelReport),
          },
        })

      })
    }
    setTableData(finalTableData)
  }

  const setNewFilter = filter => {
    newFilterSelectedForReport({ filter, fullFunnel: singleFullFunnel, accessToken: facebook.auth.accessToken, isAllFunnels: singleFullFunnel ? false : true })
  }

  const toggleCardActive = cardTitle => {
    setSelectedCards(prevSelectedCards => {
      if (prevSelectedCards.includes(cardTitle)) {
        if (prevSelectedCards.length > 1) {
          return prevSelectedCards.filter(card => card !== cardTitle)
        }
        return prevSelectedCards
      } else {
        return [...prevSelectedCards, cardTitle]
      }
    })
  }

  const getCardData = useCallback(
    (cardTitle, report) => {
      if (!report) return {}

      switch (cardTitle) {
        case 'Ad Spend':
          return {
            totalSpend: formatNumberSafely(report.spend, 0),
            dailyBudget: formatNumberSafely(report.dailyBudget, 0),
            cpm: formatNumberSafely(
              report.impressions
                ? (report.spend / report.impressions) * 1000
                : 0,
              0,
            ),
          }
        case 'Reach':
          return {
            reach: formatNumberSafely(report.reach, 0),
            impressions: formatNumberSafely(report.impressions, 0),
            frequency: formatNumberSafely(report.frequency, 3),
          }
        case 'Clicks':
          return {
            clicks: formatNumberSafely(report.clicks, 0),
            ctr: formatNumberSafely(report.ctr, 2),
            cpc: formatNumberSafely(report.cpc, 1),
          }
        case 'Sales':
          let addToCartKey = 'addToCart'
          let purchasesKey = 'purchases'
          let salesKey = 'sales'

          if (attribution === '7-day click') {
            addToCartKey = 'addToCart_7d_click'
            purchasesKey = 'purchases_7d_click'
            salesKey = 'sales_7d_click'
          } else if (attribution === '1-day view') {
            addToCartKey = 'addToCart_1d_view'
            purchasesKey = 'purchases_1d_view'
            salesKey = 'sales_1d_view'
          }

          return {
            addToCart: formatNumberSafely(report[addToCartKey], 0),
            purchases: formatNumberSafely(report[purchasesKey], 0),
            sales: formatNumberSafely(report[salesKey], 0),
          }
        case 'Performance':
          let roasKey = 'roas'
          let costPerPurchaseKey = 'costPerPurchase'

          if (attribution === '7-day click') {
            roasKey = 'roas_7d_click'
            costPerPurchaseKey = 'costPerPurchase_7d_click'
          } else if (attribution === '1-day view') {
            roasKey = 'roas_1d_view'
            costPerPurchaseKey = 'costPerPurchase_1d_view'
          }

          return {
            roas: formatNumberSafely(report[roasKey], 2),
            costPerPurchase: formatNumberSafely(report[costPerPurchaseKey], 0),
          }
        default:
          return {}
      }
    },
    [filter, attribution],
  )

  const requestSort = key => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  const formatNumberSafely = (value, decimalPlaces = 0) => {
    if (typeof value === 'number' && !isNaN(value)) {
      return numberFormatter.formatNumber(value, decimalPlaces)
    }

    return '0'
  }

  return (
    <div className="acsr-container">
      <div className="acsr-report-header">
        {!singleFullFunnel && showEnhBetterMessage ? (
          <h2>
            All campaigns generate{' '}
            <span>%{formatNumberSafely(percentageIncrease, 0)} more sales</span>{' '}
            in the last {dayCountBeforeEnhencer} days compared to the previous{' '}
            {dayCountBeforeEnhencer} days before AI Ads.
          </h2>
        ) : null}

        <div className="acsr-filters">
          <div className="filter-group">
            <div className="filter-label">Objective</div>
            <div className="acsr-filter">
              <select
                value={objective}
                onChange={e => setObjective(e.target.value)}
              >
                {objectiveOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <FaCaretDown className="acsr-caret-icon" />
            </div>
          </div>

          <div className="filter-group">
            <div className="filter-label">Time range</div>
            <div className="acsr-filter">
              <FacebookDatePicker
                setFilters={setNewFilter}
                filter={filter}
                className="acsr-date-picker"
              />
              <FaCaretDown className="acsr-caret-icon" />
            </div>
          </div>
          
          <div className="filter-group">
            <div className="filter-label">Attribution</div>
            <div className="acsr-filter">
              <select
                value={attribution}
                onChange={e => setAttribution(e.target.value)}
              >
                {attributionOptions.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <FaCaretDown className="acsr-caret-icon" />
            </div>
          </div>
        </div>
      </div>
      {fetchingReports ? (
        <InlineLoadingSpinner />
      ) : (
        overallReportData && (
          <>
            {
              objective === "Boost Campaigns" && singleFullFunnel && !singleFullFunnel.boostBudget ?
                <div className="no-data-text" style={{ fontSize: "1.2em" }}>
                  Boost campaigns are not active in this full funnel
                </div>
                :


                <div className="acsr-report-grid">
                  <CampaignReportCard
                    title="Ad Spend"
                    subtitle="Total"
                    subtitleValue={overallReportData.adSpendMetrics.totalSpend}
                    subtitle2="Daily"
                    subtitle2Value={overallReportData.adSpendMetrics.dailyBudget}
                    chartData={chartsData.spend}
                    chartLabels={chartsData.labels}
                    chartFullLabels={chartsData.fullLabels}
                    prefix={currencySymbol}
                    isActive={selectedCards.includes('Ad Spend')}
                    onClick={() => toggleCardActive('Ad Spend')}
                    tooltipContents={tooltipContents}
                  />
                  <CampaignReportCard
                    title="Reach"
                    subtitle="Reach"
                    subtitleValue={overallReportData.reachMetrics.reach}
                    subtitle2="Frequency"
                    subtitle2Value={overallReportData.reachMetrics.frequency}
                    chartData={chartsData.reach}
                    chartLabels={chartsData.labels}
                    chartFullLabels={chartsData.fullLabels}
                    isActive={selectedCards.includes('Reach')}
                    onClick={() => toggleCardActive('Reach')}
                    tooltipContents={tooltipContents}
                  />
                  <CampaignReportCard
                    title="Clicks"
                    subtitle="Ad Clicks"
                    subtitleValue={overallReportData.clicksMetrics.clicks}
                    subtitle2="CTR"
                    subtitle2Value={overallReportData.clicksMetrics.ctr}
                    chartData={chartsData.clicks}
                    chartLabels={chartsData.labels}
                    chartFullLabels={chartsData.fullLabels}
                    isActive={selectedCards.includes('Clicks')}
                    onClick={() => toggleCardActive('Clicks')}
                    tooltipContents={tooltipContents}
                  />
                  <CampaignReportCard
                    title="Sales"
                    subtitle="Sales"
                    subtitleValue={overallReportData.salesMetrics.sales}
                    subtitle2="Purchases"
                    subtitle2Value={overallReportData.salesMetrics.purchases}
                    chartData={chartsData.sales}
                    chartLabels={chartsData.labels}
                    chartFullLabels={chartsData.fullLabels}
                    prefix={currencySymbol}
                    isActive={selectedCards.includes('Sales')}
                    onClick={() => toggleCardActive('Sales')}
                    tooltipContents={tooltipContents}
                  />
                  <CampaignReportCard
                    title="Performance"
                    subtitle="ROAS"
                    subtitleValue={overallReportData.performanceMetrics.roas}
                    subtitle2="Cost/Purchase"
                    subtitle2Value={
                      overallReportData.performanceMetrics.costPerPurchase
                    }
                    chartData={chartsData.roas}
                    chartLabels={chartsData.labels}
                    chartFullLabels={chartsData.fullLabels}
                    prefix={currencySymbol}
                    isActive={selectedCards.includes('Performance')}
                    onClick={() => toggleCardActive('Performance')}
                    tooltipContents={tooltipContents}
                  />
                </div>
            }
            {
              tableData?.length > 0 &&
              <CampaignReportTable
                fullFunnelId={singleFullFunnel?._id}
                selectedCards={selectedCards}
                tableData={tableData}
                metricTitles={metricTitles}
                tooltipContents={tooltipContents}
                requestSort={requestSort}
                sortConfig={sortConfig}
                currencySymbol={currencySymbol}
                objective={objective}
              />
            }
            {
              viewingAsUser && (
                <ActivityLogTable />
              )
            }
          </>
        )
      )}
    </div>
  )
}

export default CampaignReport
