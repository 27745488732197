import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import cookies from '../../util/cookies'
import { useHttpClient } from '../http-hook'
import helper from '../../util/helper'
import { facebookActions } from '../../../store/facebook'
import { useFacebook } from '../facebook-hook'

export const useFacebookCreative = () => {
    const { sendRequest } = useHttpClient()
    const { handleError } = useFacebook()
    const facebook = useSelector(state => state.facebook)
    const userId = useSelector(state => state.auth.user?.id)
    const authToken = useSelector(state => state.auth.token)
    const authHeader = {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
    }

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const facebookGraphUrl = process.env.REACT_APP_FACEBOOK_GRAPH_URL
    const [isErrorToastShown, setIsErrorToastShown] = useState(false)


    const createAdCreativeWithCatalog = async ({
        productSet,
        facebookPage,
        instagramAccount,
        primaryText,
        campaignName,
        websiteUrl,
    }) => {
        campaignName = campaignName.replace('🚀', '')

        let utms = `utm_source=Enhencer&utm_medium=Enhencer_Meta_CPC&utm_campaign=${campaignName}`
        let url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/adcreatives?access_token=${facebook.auth.accessToken}`
        let body = {
            name: `enh_adcrtv_${productSet.id}`,
            product_set_id: productSet.id,
            object_type: 'SHARE',
            object_story_spec: {
                page_id: facebookPage.id,
                instagram_actor_id: instagramAccount?.id,
                template_data: {
                    multi_share_end_card: 'true',
                    name: '{{product.name}}',
                    link: `${websiteUrl}?${utms}`,
                    message: primaryText, // this is primary text
                    call_to_action: {
                        type: 'SHOP_NOW',
                    },
                },
            },
            asset_feed_spec: {
                optimization_type: 'FORMAT_AUTOMATION',
                ad_formats: ['CAROUSEL', 'COLLECTION'],
            },
            descriptions: [
                {
                    text: '',
                    From: '{{product.price}}',
                },
            ],
        }

        console.log('create new creative with catalog ', body)

        try {
            if (process.env.NODE_ENV === 'production') {
                let result = await sendRequest(url, 'POST', JSON.stringify(body), {
                    'Content-Type': 'application/json',
                })

                console.log('creative created!')
                console.log('result: ', result)
                if (result.error) {
                    console.log('error while creating the  ad creative...')
                    console.log(result.error)
                    return result.error
                }
                return {
                    ...body,
                    ...result,
                }
            } else {
                await helper.timeout(5000)
                return {
                    id: '120200070614810307',
                    ...body,
                }
            }
        } catch (err) {
            console.log('error while creating the  ad creative 2...')
            console.log(err.error?.error_user_msg || err.error)
            throw err.error
        }
    }

    const createAdCreativeWithCarousel = async ({
        facebookPage,
        instagramAccount,
        primaryText,
        campaignName,
        carouselData,
        websiteUrl,
    }) => {
        campaignName = campaignName.replace('🚀', '')

        const utms = `utm_source=Enhencer&utm_medium=Enhencer_Meta_CPC&utm_campaign=${campaignName}`
        const url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/adcreatives?access_token=${facebook.auth.accessToken}`
        const body = {
            name: `enh_adcrtv_crs_${Date.now()}`,
            object_type: 'SHARE',
            object_story_spec: {
                link_data: {
                    message: primaryText,
                    child_attachments: carouselData.map(item => ({
                        ...item,
                        link: item.link || `${websiteUrl}?${utms}`,
                    })),
                    link: `${websiteUrl}?${utms}`,
                },
                page_id: facebookPage.id,
                instagram_actor_id: instagramAccount?.id,
            },
            degrees_of_freedom_spec: {
                creative_features_spec: {
                    standard_enhancements: {
                        enroll_status: 'OPT_OUT',
                    },
                },
            },
        }

        /**
         * In order to test the created creative on development, we need to send a couple of requests with Graph API Explorer:
         * 1. Use the logged body below and send a POST request to `{adAccountId}/adcreatives` to obtain a creative id
         * 2. Use the returned creative id and send a GET request to `{creativeId}/previews?ad_format=DESKTOP_FEED_STANDARD&width=160&height=285`
         */
        if (process.env.NODE_ENV !== 'production') {
            console.log(
                '%cCreate a new creative using the body below',
                'background: linear-gradient(45deg, #6a11cb, #2575fc); color: #fff; padding: 2px 4px; border-radius: 4px;',
            )
            console.log(JSON.stringify(body, null, 2))
            return {
                id: '120212159253410307',
                ...body,
            }
        }

        try {
            const result = await sendRequest(url, 'POST', JSON.stringify(body), {
                'Content-Type': 'application/json',
            })

            console.log('creative created!')
            console.log('result: ', result)

            if (result.error) {
                console.log('error while creating the  ad creative...')
                console.log(result.error)
                return result.error
            }

            return {
                ...body,
                ...result,
            }
        } catch (err) {
            console.log('error while creating the ai creative...')
            console.log(err.error.error_user_msg)
            return err.error
        }
    }

    const deleteAdCreative = async ({ creativeId }) => {
        let url = `${facebookGraphUrl}${creativeId}?access_token=${facebook.auth.accessToken}`
        try {
            let deleteResult = await sendRequest(url, 'DELETE')
            console.log(deleteResult)
        } catch (err) {
            console.log('error happened while deletion', err)
        }
    }



    const generateAdPreviews = async ({
        productSetId,
        pageId,
        instagramAccountId,
        utms,
        primaryText,
        websiteUrl
    }) => {
        let creative = encodeURIComponent(
            `{
        "object_story_spec": {
          "link_data": {
            "call_to_action": {"type":"SHOP_NOW","value":{"link": "${websiteUrl}"}},
            "description": "Description",
            "link": "${websiteUrl}",
            "message": "${primaryText.length ? primaryText : 'Primary Text'}",
            "name": "Name"
          },
          "page_id": "${pageId}",
          "instagram_actor_id": "${instagramAccountId}",
          "product_set_id": "${productSetId}"
        }
      }`,
        )
        let previews = [
            // { format: "INSTAGRAM_REELS", dimensions: { width: 320, height: 570 } },
            // { format: "INSTAGRAM_STANDARD", dimensions: { width: 320, height: 410 } },
            {
                format: 'DESKTOP_FEED_STANDARD',
                dimensions: { width: 500, height: 600 },
            },
        ]

        let promises = []
        previews.forEach(preview => {
            let url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/generatepreviews?ad_format=${preview.format}&creative=${creative}&access_token=${facebook.auth.accessToken}`
            promises.push(sendRequest(url, 'get'))
        })
        let results = await Promise.all(promises)

        results.forEach((result, index) => {
            previews[index] = {
                ...previews[index],
                body: result.data[0].body,
            }
        })

        return previews
    }

    const getAdPreviews = async ({ creativeId }) => {
        let previews = JSON.parse(
            sessionStorage.getItem(`cr_${creativeId}_previews`),
        )
        let previewsSetTime = parseInt(cookies.get("prw_set"))

        if (previews && previewsSetTime && (Date.now() - previewsSetTime < 12 * 60 * 60 * 1000)) {
            return previews
        } else {
            previews = [
                // { format: "INSTAGRAM_REELS", dimensions: { width: 320, height: 570 } },
                {
                    format: 'DESKTOP_FEED_STANDARD',
                    dimensions: { width: 500, height: 750 },
                },
                /* { format: "MESSENGER_MOBILE_INBOX_MEDIA", dimensions: { width: 400, height: 250 } },
                { format: "INSTAGRAM_PROFILE_FEED", dimensions: { width: 320, height: 410 } }, */
            ]

            let promises = []
            previews.forEach(preview => {
                let url = `${facebookGraphUrl}${creativeId}/previews?ad_format=${preview.format}&width=160&height=285&access_token=${facebook.auth.accessToken}`
                promises.push(sendRequest(url, 'get'))
            })
            let results = await Promise.all(promises)

            results.forEach((result, index) => {
                previews[index] = {
                    ...previews[index],
                    body: result.data[0].body,
                }
            })

            cookies.set("prw_set", Date.now(), 0.2)
            sessionStorage.setItem(
                `cr_${creativeId}_previews`,
                JSON.stringify(previews),
            )
            return previews
        }
    }

    const getPreviousCreatives = async () => {

        let adAccountId = facebook.selectedAdAccount?.id
        let allCreatives;

        if (facebook.adCreativeLibrary && facebook.adCreativeLibrary[adAccountId] && facebook.adCreativeLibrary[adAccountId].creatives) {
            // if page refreshed at least once, then facebook info already includes ad creatives
            allCreatives = Object.values(facebook.adCreativeLibrary[adAccountId]?.creatives)
            let updatedAt = new Date(facebook.adCreativeLibrary[adAccountId].updatedAt)
            if (updatedAt && (Date.now() - updatedAt.valueOf()) > 7 * 24 * 60 * 60 * 1000) {
                // more than a week passed, fetch short run
                let newCreatives = updateCreativeLibraryIfNecessary({ adAccountId })
                allCreatives = allCreatives.concat(newCreatives)
            }

        } else {
            // front end doesnt have the creatives yet (probably single flow)
            const getFromDb = async () => {
                let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/getCreativeLibraryFromDb?userId=${userId}&adAccountId=${facebook.selectedAdAccount.id}`
                let result = await sendRequest(url, 'GET', null, authHeader)
                return result.creatives
            }

            let libraryFromDb = await getFromDb()
            if (!libraryFromDb) {
                await helper.timeout(3000)
                libraryFromDb = await getFromDb()
            }

            allCreatives = libraryFromDb ? Object.values(libraryFromDb) : []
        }

        return allCreatives ?? []

    }

    const updateCreativeLibraryIfNecessary = async ({ adAccountId }) => {

        dispatch(facebookActions.startLoading({ field: "adCreativeLibrary" }))

        let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/getBestPerformedCreatives?userId=${userId}&adAccountId=${adAccountId}&longRun=${false}&accessToken=${facebook.auth.accessToken}`
        try {
            let result = await sendRequest(url, 'GET', null, authHeader)
            dispatch(facebookActions.updateAField({
                field: "adCreativeLibrary",
                value: {
                    [adAccountId]: {
                        creatives: result.data,
                        updatedAt: new Date().toISOString()
                    }
                }
            }))
            dispatch(facebookActions.stopLoading({ field: "adCreativeLibrary" }))
            return result.data ? Object.values(result.data) : []
        } catch (err) {
            handleError(err)
            dispatch(facebookActions.stopLoading({ field: "adCreativeLibrary" }))
            throw err
        }
    }

    const updateCreative = async ({ newCreativeBody, campaignName }) => {
        let utms = `utm_source=Enhencer&utm_medium=Enhencer_Meta_CPC${campaignName ? `&utm_campaign=${campaignName}` : ''}`
        let body = {
            ...newCreativeBody,
            "name": `enh_adcrtvf__${newCreativeBody.id}`
        }
        delete body.id

        let url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/adcreatives?access_token=${facebook.auth.accessToken}`

        try {
            if (process.env.NODE_ENV === "production") {
                let result = await sendRequest(url, 'POST', JSON.stringify(body), { 'Content-Type': 'application/json' })

                if (result.error) {
                    console.log("error while creating the  ad creative...", result.error)
                    return result.error
                }
                console.log("creative created! result: ", result)
                return result

            } else {
                return {
                    id: "120206153182280307"
                }
            }

        } catch (err) {
            handleError(err)
        }
    }

    return {
        createAdCreativeWithCatalog,
        createAdCreativeWithCarousel,
        deleteAdCreative,
        updateCreative,
        generateAdPreviews,
        getAdPreviews,
        getPreviousCreatives,
        updateCreativeLibraryIfNecessary
    }
}