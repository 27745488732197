import React, { Component, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "./FacebookDatePicker.css"
import { FaCalendar, FaCalendarCheck } from "react-icons/fa";

const datePresets = {
    "Lifetime": "maximum",
    "Today": "today",
    "Yesterday": "yesterday",
    "This week": "this_week_sun_today",
    "This month": "this_month",
    "Last month": "last_month",
    "Last 7 days": "last_7d",
    "Last 14 days": "last_14d",
    "Last 30 days": "last_30d",
    "Last 90 days": "last_90d",
    "This year": "this_year",
}

const datePresetsReverse = {
    "maximum": "Lifetime",
    "today": "Today",
    "yesterday": "Yesterday",
    "this_week_sun_today": "This week",
    "this_month": "This month",
    "last_month": "Last month",
    "last_7d": "Last 7 days",
    "last_14d": "Last 14 days",
    "last_30d": "Last 30 days",
    "last_90d": "Last 90 days",
    "this_year": "This year",
}



const FacebookDatePicker = (props) => {

    let filter;
    let start;
    let end;
    if (props.filter) {
        if (props.filter.type === "date_preset") {
            filter = datePresetsReverse[props.filter.value]
        } else if (props.filter.type === "time_range") {
            let range = JSON.parse(props.filter.value)
            filter = range.since + "  :  " + range.until
            start = new Date(range.since)
            end = new Date(range.until)
        }
    }
    
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const [dateInterval, setDateInterval] = useState(filter || "Last 14 days");

    const [isOpen, setIsOpen] = useState(false)

    const togglerRef = useRef()
    const contRef = useRef()

    const openDateSelection = () => {
        console.log("openDateSelection ", isOpen)
        if (!isOpen) {
            /* let rect = togglerRef.current.getBoundingClientRect()
            let x = rect.x
            let y = rect.y
            let width = rect.width
            let height = rect.height

            console.log("x, y, width, height", x, y, width, height)

            contRef.current.style.left = x + "px"
            contRef.current.style.top = y + height + 10 + "px" */
            setIsOpen(true)

            document.addEventListener('mousedown', clickOutsideDateSelection);
        } else {
            closeDateSelection()
        }
    }


    const clickOutsideDateSelection = (event) => {
        let path = event.path || (event.composedPath && event.composedPath())
        let inside = false;
        path.forEach(elem => {
            if (elem.classList !== undefined) {
                if (elem.classList.contains("facebook-date-picker")) {
                    inside = true
                }
            }
        })

        if (!inside) {
            setIsOpen(false)
        }
    }

    const closeDateSelection = () => {
        document.removeEventListener('mousedown', clickOutsideDateSelection);
        setIsOpen(false)
    }

    const selectDatePreset = (name, preset) => {
        // let filters = { datePreset: preset }
        let filters = { type: "date_preset", value: preset }

        setDateInterval(name)
        setIsOpen(false)
        setStartDate(null);
        setEndDate(null);
        
        if (props.setFilters) props.setFilters(filters)
    }

    const selectDateRange = (dates) => {
        const [start, end] = dates
        setDateInterval(null)
        setStartDate(start)
        setEndDate(end)

    }

    const updateDateRange = () => {
        // let filters = { timeRange: JSON.stringify({ since: getFBDateFormat(startDate), until: getFBDateFormat(endDate) }) }
        let filters = {
            type: "time_range",
            value: JSON.stringify({
                since: getFBDateFormat(startDate), until: getFBDateFormat(endDate)
            })
        }

        setDateInterval(getFBDateFormat(startDate) + "  :  " + getFBDateFormat(endDate))
        setIsOpen(false)
        if (props.setFilters) props.setFilters(filters)
    }

    const getFBDateFormat = (date) => {
        let m = (date.getMonth() + 1).toString()
        let d = date.getDate().toString()
        let y = date.getFullYear().toString()
        if (m.length == 1) {
            m = "0" + m
        }
        if (d.length == 1) {
            d = "0" + d
        }
        return y + "-" + m + "-" + d
    }



    return <div className="facebook-date-picker">
        <button ref={togglerRef} onClick={openDateSelection} className="toggle-button">
            <FaCalendarCheck />
            {dateInterval}
        </button>

        <div className={`${isOpen ? 'open' : ''} date-selection-popover`} ref={contRef}>
            <div className="date-selection-content">
                <div className="left-side">
                    <DatePicker
                        selected={startDate}
                        onChange={selectDateRange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                    {endDate && <div className="date-text">

                        {startDate.toDateString()} - {endDate.toDateString()}
                    </div>}
                    <div className="action-buttons">
                        <button className="button" onClick={closeDateSelection}>Cancel</button>
                        <button className="button update" onClick={updateDateRange}>Update</button>
                    </div>
                </div>
                <div className="right-side">
                    <h6>Date&nbsp;presets</h6>
                    <ul>
                        {
                            Object.keys(datePresets).map(key =>
                                <li key={key} onClick={() => { selectDatePreset(key, datePresets[key]) }} className={dateInterval === key ? 'active' : ''}>{key}</li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>


    </div>
}

export default FacebookDatePicker;

