import React, { useState, useCallback } from 'react'

import useNavigator from '../../shared/hooks/navigator-hook'
import ToolbarLeft from '../components/layout/ToolbarLeft'
import ToolbarRight from '../components/layout/ToolbarRight'
import CarouselWorkbench from '../components/miscellaneous/CarouselWorkbench'
import EnhencedCatalogWorkbench from '../components/miscellaneous/EnhencedCatalogWorkbench'

import styles from './Editor.module.css'

const Editor = () => {
  const { searchParams } = useNavigator()
  const [isShowingTemplates, setIsShowingTemplates] = useState(false)
  const [selectedElementInfo, setSelectedElementInfo] = useState({
    element: null,
    elementType: null,
    elementId: null,
    elementNode: null,
    templateConfig: null,
    onDeselectElement: null
  })

  const handleElementSelected = useCallback((info) => {
    // Only update state if there are actual changes to prevent re-renders
    setSelectedElementInfo(prevInfo => {
      // Compare essential parts of the objects to prevent unnecessary updates
      if (
        prevInfo.element === info.element &&
        prevInfo.elementType === info.elementType &&
        prevInfo.elementId === info.elementId
      ) {
        // If the essential info is the same, keep the previous state to maintain reference equality
        return prevInfo;
      }
      
      // Add the handleDeselectElement function to the info object
      return {
        ...info,
        onDeselectElement: handleDeselectElement
      };
    });
  }, []);

  // Function to handle element deselection
  const handleDeselectElement = useCallback(() => {
    // Reset the selection state
    setSelectedElementInfo({
      element: null,
      elementType: null,
      elementId: null,
      elementNode: null,
      templateConfig: null,
      onDeselectElement: null
    });
  }, []);

  return (
    <div className={styles.editorContainer}>
      <div className={styles.leftToolbar}>
        <ToolbarLeft />
      </div>
      <div className={styles.contentArea}>
        {searchParams.mode === 'enhenced-catalog' ? (
          <EnhencedCatalogWorkbench
            isShowingTemplates={isShowingTemplates}
            setIsShowingTemplates={setIsShowingTemplates}
            onElementSelected={handleElementSelected}
          />
        ) : (
          <CarouselWorkbench
            isShowingTemplates={isShowingTemplates}
            setIsShowingTemplates={setIsShowingTemplates}
            onElementSelected={handleElementSelected}
          />
        )}
      </div>
      <div className={styles.rightToolbar}>
        <ToolbarRight
          isShowingTemplates={isShowingTemplates}
          onBrowseTemplatesClick={() => setIsShowingTemplates(true)}
          selectedElementInfo={{
            ...selectedElementInfo,
            onDeselectElement: handleDeselectElement
          }}
        />
      </div>
    </div>
  )
}

export default Editor
