import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { FaUser, FaCreditCard, FaLock, FaCalendar } from 'react-icons/fa'
import enhencerLogo from '../../shared/assets/images/icons/e-logo.png'
import './StripePaymentCard.css'

const StripePaymentCard = props => {
  const navigate = useNavigate()
  // collect data from the user
  let { email, plan, price, paymentInterval, planName, currency, phoneNumber } = props.billingInfo
  plan =  process.env.NODE_ENV === 'development' ? "price_1PYTvuDVZxXin8U3OTtUbM7W" : plan
  const [name, setName] = useState()
  const [priceId, setPriceId] = useState('')
  const [processing, setProcessing] = useState('')
  const [paymentIntent, setPaymentIntent] = useState()

  // stripe items
  const stripe = useStripe()
  const elements = useElements()

  // main function
  const createSubscription = async () => {
    try {
      //create a payment method
      const cardNumberElement = elements.getElement(CardNumberElement)

      setProcessing(true)
      const paymentMethod = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name,
          email,
        },
      })

      // call the backend to create subscription
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/createSubscription`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentMethod: paymentMethod?.paymentMethod?.id,
            name,
            email,
            phoneNumber,
            plan,
          }),
        },
      ).then(res => res.json())


      // Use card Element to tokenize payment details
      const confirmPayment = await stripe?.confirmCardPayment(
        response.clientSecret,
        {
          payment_method: {
            card: cardNumberElement,
            //card: elements?.getElement(cardData),
            billing_details: {
              name: name,
              email: email,
            },
          },
        },
      )

      const subscription = response.subscription

      if (confirmPayment?.error) {
        setProcessing(false)
        alert(confirmPayment.error.message)
      } else {
        setProcessing(false)
        setPaymentIntent(confirmPayment?.paymentIntent)
        navigate('/payment-completed', { state: { stripe_subs: subscription } });
      }
    } catch (error) {
      setProcessing(false)
      alert(error.message)
      console.log(error)
    }
  }

  const COMMON_STYLE_OPTIONS = {
    base: {
      color: '#32325d',
      fontSize: '14px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  }

  const CARD_EXPIRITY_OPTIONS = {
    style: COMMON_STYLE_OPTIONS,
    placeholder: 'mm/yy',
  }

  const CARD_NUMBER_OPTIONS = {
    style: COMMON_STYLE_OPTIONS,
    placeholder: 'Card Number',
  }

  const CARD_CVC_OPTIONS = {
    style: COMMON_STYLE_OPTIONS,
  }

  return (
    <div className="stripe-payment-form">
      <img
        alt="enhencer"
        style={{ height: '1.5em', marginBottom: '0' }}
        src={enhencerLogo}
      />
      <p style={{ marginTop: '5px' }}>
        <span style={{ fontWeight: 'bold' }}>
          {planName} Plan
        </span>
        <br />
        <span style={{ fontSize: '0.8em' }}>
          {paymentInterval}{' '}
          {price}
          {currency}{' '}
        </span>
      </p>

      <div className="card-element">
        <div className="card-icon">
          <FaUser />
        </div>
        <div className="card-input">
          <input
            placeholder="Card Holder Name"
            type="text"
            defaultValue={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </div>

      <div className="card-element">
        <div className="card-icon">
          <FaCreditCard />
        </div>
        <div className="card-input">
          <CardNumberElement options={CARD_NUMBER_OPTIONS} />
        </div>
      </div>

      <div className="card-element">
        <div className="card-icon">
          <FaCalendar />
        </div>
        <div className="card-input">
          <CardExpiryElement options={CARD_EXPIRITY_OPTIONS} />
        </div>
        <div className="card-icon">
          <FaLock />
        </div>
        <div className="card-input">
          <CardCvcElement options={CARD_CVC_OPTIONS} />
        </div>
      </div>

      {/* <CardElement /> */}

      <button
        id="submit"
        onClick={createSubscription}
        disabled={!stripe || processing}
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            `Pay  ${price}${currency}`
          )}
        </span>
      </button>
    </div>
  )
}

export default StripePaymentCard
