import React, { useEffect, useRef, useState } from 'react';
import { MdDone, MdEvent, MdOutlineAlarm } from 'react-icons/md';
import './OneAlarm.css';
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import { useSelector } from 'react-redux';
import { FaUser } from 'react-icons/fa';

const OneAlarm = ({ alarm, onClick, convertTimestampToCustomFormat, onCopy, onDoneUndone, onRemove }) => {
	const [showValidation, setShowValidation] = useState(false);
	const isOverdue = !alarm.done && new Date(alarm.dueDate) < Date.now();
	const containerRef = useRef(alarm._id);

	const [action, setAction] = useState(null);
	const [isHovered, setIsHovered] = useState(false);
	const { managerUsers } = useSelector(state => state.admin)

	useEffect(() => {

		const handleKeyPress = (event) => {
			const key = event.key.toLowerCase();

			if (!showValidation) {
				if (key === 'c') {
					setShowValidation(true);
					setAction('copy');
				} else if (key === 'd') {
					setShowValidation(true);
					setAction('done');
				} else if (key === 'x') {
					setShowValidation(true);
					setAction('remove');
				}
			} else {
				if (key === 'c' && action === 'copy') {
					onCopy(alarm);
					setShowValidation(false);
					setIsHovered(false);
				} else if (key === 'd' && action === 'done') {
					onDoneUndone(alarm);
					setShowValidation(false);
					setIsHovered(false);
				} else if (key === 'x' && action === 'remove') {
					onRemove(alarm);
					setShowValidation(false);
					setIsHovered(false);
				}
			}
		}

		const handleMouseEnter = () => {
			setIsHovered(true);
			window.addEventListener('keydown', handleKeyPress)
		}

		const handleMouseLeave = () => {
			setIsHovered(false);
			window.removeEventListener('keydown', handleKeyPress)
			setShowValidation(false)
			setAction(null);
		}

		if (isHovered) {
			window.addEventListener('keydown', handleKeyPress)
		} else {
			window.removeEventListener('keydown', handleKeyPress)
		}

		const container = containerRef.current
		if (container) {
			container.addEventListener('mouseenter', handleMouseEnter)
			container.addEventListener('mouseleave', handleMouseLeave)
		}

		return () => {
			if (container) {
				container.removeEventListener('mouseenter', handleMouseEnter)
				container.removeEventListener('mouseleave', handleMouseLeave)
			}
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [showValidation, action])

	return (
		<div id={alarm._id} className="one-alarm-container" onClick={onClick} ref={containerRef} title="Press X: Remove, Press D: Done/Undone, Press C: Copy to next day">
			{isOverdue ? (
				<MdOutlineAlarm
					style={{ flex: '0 0 auto', color: 'red', fontSize: '20px' }}
				/>
			) : (
				<MdDone
					style={{
						flex: '0 0 auto',
						fontSize: '20px',
						color: alarm.done ? '#016b92' : 'transparent',
					}}
				/>
			)}
			<div className={`due-date ${isOverdue ? 'over' : ''}`}>
				{convertTimestampToCustomFormat(
					alarm.done ? alarm.completedAt : alarm.dueDate
				)}
			</div>
			<div className="text-bar">
				{alarm.meetingInfo && <MdEvent className="meeting-icon" color='var(--dark-blue)' size={15} />}
				<div className="title">{alarm.title}</div>
				<div className="note">
					{alarm.note ? `(${alarm.note})` : ''}
				</div>
			</div>

			<div className='attached-users' style={{ display: alarm.attachedUserIds?.length > 0 ? 'flex' : 'none' }}>
				{alarm.attachedUserIds?.map(userId => {
					if (!managerUsers[userId]?.image) return <Tooltip key={userId} content={userId}>
						<div key={userId} className='attached-user-mini' style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'gray', color: 'white'}}>
							<FaUser />
						</div></Tooltip>;
					const user = managerUsers[userId];
					return <Tooltip key={userId} content={user?.name}><div key={userId} className='attached-user-mini'>
						<img src={user.image} alt={user?.name} className="user-image" style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover' }} />
					</div></Tooltip>
				})}
			</div>

			{showValidation && (
				<div className="validation-overlay">
					{action === 'copy' && (
						<p>Copy to next day? 'C' to confirm</p>
					)}
					{action === 'done' && (
						<p>Mark as {alarm.done ? 'undone' : 'done'}? 'D' to confirm</p>
					)}
					{action === 'remove' && (
						<p>Remove? 'X' to confirm</p>
					)}
				</div>
			)}
		</div>
	);
};

export default OneAlarm;
