import React, { useEffect, useState } from 'react'
import { FaPlus, FaChevronLeft } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import './FullFunnelSidebar.css'
import { useSelector } from 'react-redux'
import { useModal } from '../../../shared/hooks/modal-hook'
import Modals from '../../../shared/enums/Modals'
const FullFunnelSidebar = ({
  isOpen,
  onClose,
  onInteraction,
  selectedFullFunnelId,
  handleCampaignClick,
  createNewCampaign,
}) => {
  const { navigateWithAdminParams } = useAdmin()
  const { openModal } = useModal()
  const location = useLocation()
  const fullFunnelEffectiveStatuses = useSelector(state => state.facebook.fullFunnelEffectiveStatuses)
  const fullFunnels = useSelector(state => state.facebook.fullFunnels)

  const [activeFullFunnelIds, setActiveFullFunnelIds] = useState([])
  const [inactiveFullFunnelIds, setInactiveFullFunnelIds] = useState([])
  const [reportsLoaded, setReportsLoaded] = useState(false)

  const isOverviewActive = location.pathname === '/ai-ads/full-funnel-campaigns' && !location.search
  const isCreateActive = location.pathname === '/ai-ads/full-funnel-campaigns/create'
  const viewingAsUser = useSelector(state => state.auth.adminToken)

  const handleContentClick = (e) => {
    // If not clicked on close button
    if (!e.target.closest('.close-button')) {
      onInteraction()
    }
  }

  const handleOverviewClick = () => {
    navigateWithAdminParams('/ai-ads/full-funnel-campaigns')
  }


  useEffect(() => {
    const hasReports = Object.keys(fullFunnelEffectiveStatuses).length > 0
    setReportsLoaded(hasReports)

    if (hasReports) {
      const allIds = fullFunnels.map(f => f._id)
      const active = allIds.filter(
        id => fullFunnelEffectiveStatuses[id] === 'ACTIVE',
      )
      const inactive = allIds.filter(
        id => fullFunnelEffectiveStatuses[id] !== 'ACTIVE',
      )

      setActiveFullFunnelIds(active)
      setInactiveFullFunnelIds(inactive)
    }
  }, [fullFunnelEffectiveStatuses, fullFunnels])

  const addExistingCampaign = () => {
    openModal({
      type: Modals.CREATE_FULL_FUNNEL_FROM_EXISTING_CAMPAIGNS,
      closeOnClickOutside: true,
    })
  }

  return (
    <div className={`full-funnel-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-header">
        <h2>Facebook Ads</h2>
        <button className="close-button" onClick={onClose}>
          <FaChevronLeft />
        </button>
      </div>

      <div className="sidebar-content" onClick={handleContentClick}>
        <button
          className={`overview-btn ${isOverviewActive ? 'active' : ''}`}
          onClick={handleOverviewClick}
        >
          Overall
        </button>

        <button
          className={`create-campaign-btn ${isCreateActive ? 'active' : ''}`}
          onClick={createNewCampaign}
        >
          <FaPlus /> Full-Funnel Campaign
        </button>

        {
          viewingAsUser && (
            <button
              className={`create-campaign-btn ${isCreateActive ? 'active' : ''}`}
              onClick={addExistingCampaign}
            >
              <FaPlus /> Existing Campaign
            </button>
          )
        }

        {reportsLoaded ? (
          [
            { title: 'Active Full-Funnels', fullFunnels: fullFunnels.filter(funnel => activeFullFunnelIds.includes(funnel._id)) },
            { title: 'Inactive Full-Funnels', fullFunnels: fullFunnels.filter(funnel => inactiveFullFunnelIds.includes(funnel._id)) },
          ].map((group, index) => (
            <div key={index} className="sub-item-group">
              <div className="sub-item-header">
                <span>{group.title}</span>
                <span className="sub-item-count">{group.fullFunnels.length}</span>
              </div>
              <div className="campaign-group">
                {group.fullFunnels.map(fullFunnel => (
                  <div
                    key={fullFunnel._id}
                    className={`campaign-item ${fullFunnel._id === selectedFullFunnelId ? 'active' : ''
                      }`}
                    onClick={() => handleCampaignClick(fullFunnel._id)}
                  >
                    <div className="campaign-info">
                      <span className="campaign-label">{fullFunnel.label}</span>
                    </div>
                    <div
                      className={`indicator ${group.title === 'Active Full-Funnels'
                        ? 'active'
                        : ''
                        }`}
                    ></div>
                  </div>
                ))}

              </div>
            </div>
          ))
        ) : (
          <div className="sub-item-group">
            <div className="sub-item-header">
              <span>Full Funnels</span>
              <span className="sub-item-count">{fullFunnels?.length}</span>
            </div>
            <div className="campaign-group">
              {fullFunnels?.map(funnel => (
                <div
                  key={funnel._id}
                  className={`campaign-item ${funnel._id === selectedFullFunnelId ? 'active' : ''
                    }`}
                  onClick={() => handleCampaignClick(funnel._id)}
                >
                  <div className="campaign-info">
                    <span className="campaign-label">{funnel.label}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default FullFunnelSidebar 