import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../../shared/components/FormElements/Button";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { toast } from 'react-toastify';

import { FaCalendarAlt, FaCogs, FaPlus } from "react-icons/fa";
import Switch from "../../../shared/components/UIElements/Switch";
import dateFormatter from "../../../shared/util/dateFormatter";
import AudienceEventSettings from "./AudienceEventSettings";
import { useModal } from "../../../shared/hooks/modal-hook";
import Modals from "../../../shared/enums/Modals";
import errorHandler from "../../../shared/util/errorHandler";
import helper from "../../../shared/util/helper";
import { projectActions } from "../../../store/project";
import Tooltip from "../../../shared/components/UIElements/Tooltip";

const DEFAULT_MODEL_SETTINGS = {
  n_estimators: [50],
  min_samples_leaf: [10]
}

const CardAdminRandomForestSettings = ({ userId }) => {
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null
  });
  const [sampleLimit, setSampleLimit] = useState();
  const [thresholds, setThresholds] = useState({});
  const [featureList, setFeatureList] = useState();
  const [targetList, setTargetList] = useState();
  const [modelSettings, setModelSettings] = useState(DEFAULT_MODEL_SETTINGS);
  const dispatch = useDispatch()

  const [initialDates, setInitialDates] = useState({
    startDate: null,
    endDate: null
  });

  const [initialSampleLimit, setInitialSampleLimit] = useState();
  const [initialThresholds, setInitialThresholds] = useState({});
  const [initialFeatureList, setInitialFeatureList] = useState();
  const [initialTargetList, setInitialTargetList] = useState();
  const [initialModelSettings, setInitialModelSettings] = useState(DEFAULT_MODEL_SETTINGS);

  const [isSettingsEmpty, setIsSettingsEmpty] = useState(false)

  const [isTraining, setIsTraining] = useState(false)
  const [lastTrainDate, setLastTrainDate] = useState()
  const [audienceEvents, setAudienceEvents] = useState()

  const projectId = useSelector(state => state.project._id)

  const [schedule, setSchedule] = useState();


  const authToken = useSelector((state) => state.auth.token);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { openModal } = useModal()
  const [isInitialized, setIsInitialized] = useState(false)


  useEffect(() => {
    let isMounted = true
    console.log("yas sir")
    const init = async () => {
      if (userId && authToken && !isInitialized && isMounted) {
        try {

          setIsInitialized(true)
          const datesResult = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/dev/getMinMaxDates`,
            "POST",
            JSON.stringify({
              userId: userId
            }),
            {
              Authorization: "Bearer " + authToken,
              "Content-Type": "application/json",
            }
          );

          const projectResult = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/dev/getProjectByUserId/${userId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + authToken,
            }
          );

          /* const projectResult = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/dev/getProject/${projectId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + authToken,
            }
          ); */

          console.log("projectResult ", projectResult)
          dispatch(projectActions.setProject(projectResult))

          let startDate = new Date(datesResult.MINDATE);
          startDate.setHours(0);
          startDate.setMinutes(0);
          startDate.setSeconds(0);
          const endDate = new Date(datesResult.MAXDATE);

          let savedDates;

          setIsTraining(projectResult.audienceModel?.isTraining)

          setAudienceEvents(projectResult.audienceEvents)

          if (projectResult.audienceModel?.trainDate) {
            setLastTrainDate(dateFormatter(projectResult.audienceModel?.trainDate))
          }

          if (projectResult.audienceModel?.dates) {
            savedDates = {
              startDate: new Date(projectResult.audienceModel?.dates.startDate),
              endDate: new Date(projectResult.audienceModel?.dates.endDate),
            }
          } else {
            savedDates = {
              startDate,
              endDate
            }
          }



          setDates(savedDates);
          setInitialDates(savedDates)

          if (projectResult.audienceModel && projectResult.audienceModel.featureList?.length) {
            console.log("me here")
            setSampleLimit(projectResult.audienceModel.sampleLimit);
            setThresholds(projectResult.audienceModel.thresholds);
            setFeatureList(projectResult.audienceModel.featureList);
            setModelSettings(projectResult.audienceModel.modelSettings && Object.keys(projectResult.audienceModel.modelSettings) ? projectResult.audienceModel.modelSettings : DEFAULT_MODEL_SETTINGS);

            setInitialSampleLimit(projectResult.audienceModel.sampleLimit);
            setInitialThresholds(projectResult.audienceModel.thresholds);
            setInitialFeatureList(projectResult.audienceModel.featureList);


            setInitialModelSettings(projectResult.audienceModel.modelSettings && Object.keys(projectResult.audienceModel.modelSettings) ? projectResult.audienceModel.modelSettings : DEFAULT_MODEL_SETTINGS);

            setTargetList(projectResult.audienceModel.targetList)
            setInitialTargetList(projectResult.audienceModel.targetList)

          } else {
            console.log("me here 2")
            setIsSettingsEmpty(true)
          }

          /* if (job) {
            scheduleDayCount = parseInt(job.repeatInterval.split(" ")[0]);
            setSchedule({
              nextRunAt: job.nextRunAt,
              day: scheduleDayCount,
            });
          } */


        } catch (err) {
          errorHandler(err)
        }
      }
    };
    init();

    return () => {
      isMounted = false
    }
  }, [authToken]);

  const onSampleLimitChange = (e) => {
    setSampleLimit(parseInt(e.target.value));
  };

  const onThresholdsChange = (e) => {
    setThresholds({
      ...thresholds,
      [e.target.id]: parseInt(e.target.value),
    });
  };

  const onSelectFeature = (selection, index) => {
    let tempFeatureList = [...featureList];
    tempFeatureList[index] = {
      ...tempFeatureList[index],
      usageLabel: selection
    }
    setFeatureList(tempFeatureList);
  };

  const onModelSettingsChange = (e) => {
    setModelSettings({
      ...modelSettings,
      [e.target.id]: parseInt(e.target.value),
    });
  };

  const checkIfFormIsDirty = () => {
    return (
      initialDates.endDate !== dates.endDate
      || initialDates.startDate !== dates.startDate
      || (initialFeatureList && initialFeatureList.map(f => f.usageLabel === "Use").length !== initialFeatureList.map(f => f.usageLabel === "Use").length)
      || (modelSettings && (initialModelSettings?.n_estimators !== modelSettings?.n_estimators || initialModelSettings?.min_samples_leaf !== modelSettings?.min_samples_leaf))
      || sampleLimit && initialSampleLimit !== sampleLimit
      || (thresholds && (initialThresholds?.productThreshold !== thresholds?.productThreshold || initialThresholds?.listingThreshold !== thresholds?.listingThreshold))
      || (targetList && initialTargetList.map(f => f.usageLabel === "Use") !== targetList.map(f => f.usageLabel === "Use"))
    )
  }

  const applyThresholds = async (e) => {
    let data = {
      userId,
      manualSettings: {
        modelSettings
      }

    };

    let isDirty = checkIfFormIsDirty()

    if (isDirty && !isSettingsEmpty) {
      data = {
        ...data,
        manualSettings: {
          ...data.manualSettings,
          startDate: dates.startDate,
          endDate: dates.endDate,
          thresholds,
          sampleLimit
        }
      }

    }

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/generateFeaturesAndParams`,
        "POST",
        JSON.stringify(data),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );
      setIsSettingsEmpty(false)
      setSampleLimit(response.sampleLimit);
      setFeatureList(response.featureList);
      setThresholds(response.thresholds)
      setTargetList(response.targetList)

    } catch (err) {
      errorHandler(err)
    }
  };

  const startTraining = async (e) => {
    let isDirty = checkIfFormIsDirty()

    if (isDirty && !isSettingsEmpty) {
      let modelSettingsToSend = {
        ...modelSettings
      }
      if (modelSettingsToSend?.min_samples_leaf) modelSettingsToSend.min_samples_split = modelSettingsToSend.min_samples_leaf * 2;
      const data = {
        "audienceModel.dates": dates,
        "audienceModel.featureList": featureList,
        "audienceModel.sampleLimit": sampleLimit,
        "audienceModel.thresholds": thresholds,
        "audienceModel.modelSettings": modelSettings,
      };
      setIsTraining(true)

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/dev/updateProjectByUserId/${userId}`,
          "PATCH",
          JSON.stringify(data),
          {
            Authorization: "Bearer " + authToken,
            "Content-Type": "application/json",
          }
        );
      } catch (err) {
        errorHandler(err)
      }
    }

    try {
      const startDate = dates.startDate;
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/train`,
        "POST",
        JSON.stringify({
          userId: userId,
          projectId: projectId
        }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );
      toast.success(result.message);
      setTimeout(() => {
        setIsTraining(false)
      }, 1000);
      //TODO: if it is not shopify, show a popup that script needs to change to v3


    } catch (err) {
      errorHandler(err)
    }
  };

  const addNewEvent = async () => {

    openModal({
      type: Modals.ADD_NEW_AUDIENCE_EVENT,
      closeOnClickOutside: true,
      authToken,
      userId,
      callback: ({ newEventName, adPlatform }) => {

        let newEvents = [...audienceEvents]
        newEvents.push({
          ...audienceEvents[0],
          eventName: newEventName,
          adPlatform,
          isNewlyAdded: true
        })

        setAudienceEvents(newEvents)
      },
    })
  }

  const removeEventFromList = (eventName) => {
    let newEvents = [...audienceEvents]
    let index = helper.findIndex(audienceEvents, "eventName", eventName)
    newEvents.splice(index, 1)
    setAudienceEvents(newEvents)
  }

  const deleteEvent = async ({ eventName, isNewlyAdded }) => {


    if (isNewlyAdded) {
      removeEventFromList(eventName)
    } else {
      const confirmed = await openModal({
        type: Modals.ARE_YOU_SURE,
        question:
          'Event will be deleted and will no longer be sent to ad platforms. Are you sure?',
      })

      if (confirmed) {
        deleteEventFromDB({ eventName })
      }
    }
  }

  const deleteEventFromDB = async ({ eventName }) => {
    try {

      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/removeAudienceEvent`,
        'POST',
        JSON.stringify({
          userId,
          eventName: eventName,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        }
      )
      removeEventFromList(eventName)

    } catch (err) {
      errorHandler(err)
    }

  }

  return (
    <React.Fragment>
      <div className="edit-user section">
        {isLoading && (
          <div className="center">
            <LoadingSpinner />
          </div>
        )}
        <div className="header-row">
          <h4>
            Random Forest
          </h4>

          <div className="date-input-cont">
            <div style={{ fontSize: "0.9em" }}>Since</div>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="datepicker"
              pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
              selected={dates.startDate}
              onChange={(date) => {
                setDates({
                  ...dates,
                  ["startDate"]: new Date(date),
                });
              }}
            />
            <FaCalendarAlt className="icon" />
          </div>

        </div>
        <div className="content-box model-settings">
          <div className="attribute-name">Model settings </div>
          <div className="row threshold-row">

            <div>
              <div className="type-row">
                <label>Sample Size</label>
                <div className="text-edit">
                  <input
                    id="sampleLimit"
                    type="number"
                    value={sampleLimit}
                    onChange={onSampleLimitChange}
                  />
                </div>
              </div>
            </div>

            <div className="type-row">
              <label>Product Threshold</label>
              <div className="text-edit">
                <input
                  id="productThreshold"
                  type="number"
                  value={thresholds.productThreshold}
                  onChange={onThresholdsChange}
                />
              </div>
            </div>

            <div className="type-row">
              <label>Listing Threshold</label>
              <div className="text-edit">
                <input
                  id="listingThreshold"
                  type="number"
                  value={thresholds.listingThreshold}
                  onChange={onThresholdsChange}
                />
              </div>
            </div>

            <div className="type-row">
              <label>Number of Trees</label>
              <div className="text-edit">
                <input
                  id="n_estimators"
                  type="number"
                  value={modelSettings?.n_estimators || 50}
                  onChange={onModelSettingsChange}
                />
              </div>
            </div>

            <div className="type-row">
              <label>Minimum Leaf Size</label>
              <div className="text-edit">
                <input
                  id="min_samples_leaf"
                  type="number"
                  value={modelSettings?.min_samples_leaf || 10}
                  onChange={onModelSettingsChange}
                />
              </div>
            </div>

            <Button className="apply" onClick={applyThresholds}>
              {
                isSettingsEmpty ? "Generate" : "Apply"
              }
            </Button>
          </div>
        </div>

        <div className="row feature-tree-row">

          <div className="feature-list">
            <div className="attribute-name">Feature list</div>
            <div className="scrollable-list">

              {
                featureList &&
                featureList.map((feature, index) => (
                  <div key={feature.name + feature.tableType} className="ruleset-row">
                    <div className="switch-container">
                      <Switch options={["Use", "Not"]} value={feature.usageLabel} onChange={(selection) => { onSelectFeature(selection, index) }} />
                    </div>
                    {feature.name} <span className="table-name">{feature.tableType}</span>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="feature-list">
            <div className="attribute-name">Target list</div>
            {
              targetList &&
              targetList.map((feature, index) => (
                <div key={feature.name + feature.tableType} className="ruleset-row">
                  <div className="switch-container">
                    <Switch options={["Use", "Not"]} value={feature.usageLabel} onChange={(selection) => { onSelectFeature(selection, index) }} />
                  </div>
                  {feature.name} <span className="table-name">{feature.tableType}</span>
                </div>
              ))
            }
          </div>

        </div>

        <div className="action-row">
          {lastTrainDate ?
            <span>🤖 Last trained:{' '}
              <b>
                {lastTrainDate}
              </b></span> : null
          }

          <Tooltip isHidden={!isSettingsEmpty}
            content={
              "You need to generate features and thresholds first"
            }
          >
            <Button primary onClick={startTraining} loading={isTraining} loadingText="Training in progress...">
              <FaCogs />
              Start Training
            </Button>
          </Tooltip>

        </div>
      </div>
      {
        audienceEvents &&
        <div className="">
          <div className="header-row">
            <h4>
              REM Events
            </h4>
          </div>

          <div className="content-box">
            {
              audienceEvents?.map(e =>
                <AudienceEventSettings key={e.eventName} audEvent={e} userId={userId} authToken={authToken} deleteEvent={deleteEvent} />
              )
            }
            <Button primary={true} size="small" onClick={addNewEvent} className="add-new-event-button">
              <FaPlus />
              Add new
            </Button>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default CardAdminRandomForestSettings;
