import React from 'react'
import { Link } from 'react-router-dom'
import { MdOutlineAddAlarm } from 'react-icons/md'
import { useModal } from '../../../shared/hooks/modal-hook'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useDispatch, useSelector, useStore } from 'react-redux'

import './CustomerCardAlarmPart.css'
import { toast } from 'react-toastify'
import helper from '../../../shared/util/helper'
import Modals from '../../../shared/enums/Modals'
import errorHandler from '../../../shared/util/errorHandler'
import OneAlarm from '../alarms/OneAlarm'

const CustomerCardAlarmPart = props => {
  const { user, alarmsPath, findByIdAndUpdate } = props

  const store = useStore()
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { sendRequest } = useHttpClient()

  function sortAlarms(unsortedAlarms) {
    let baseAlarms = !unsortedAlarms ? [] : [...unsortedAlarms]

    let notDoneOverDueAlarms = baseAlarms.filter(
      oneAlarm => !oneAlarm.done && new Date(oneAlarm.dueDate) <= Date.now(),
    )
    let notDoneBeforeDueAlarms = baseAlarms.filter(
      oneAlarm => !oneAlarm.done && new Date(oneAlarm.dueDate) > Date.now(),
    )
    let doneAlarms = baseAlarms.filter(oneAlarm => oneAlarm.done)

    notDoneOverDueAlarms.sort((a, b) => {
      const dateDiff = new Date(a.dueDate) - new Date(b.dueDate)
      return dateDiff === 0
        ? new Date(b.createdAt) - new Date(a.createdAt)
        : dateDiff
    })
    notDoneBeforeDueAlarms.sort((a, b) => {
      const dateDiff = new Date(a.dueDate) - new Date(b.dueDate)
      return dateDiff === 0
        ? new Date(b.createdAt) - new Date(a.createdAt)
        : dateDiff
    })
    doneAlarms.sort((a, b) => {
      const dateDiff = new Date(b.completedAt) - new Date(a.completedAt)
      return dateDiff === 0
        ? new Date(b.createdAt) - new Date(a.createdAt)
        : dateDiff
    })

    let concatenatedAlarms = [
      ...notDoneOverDueAlarms,
      ...notDoneBeforeDueAlarms,
      ...doneAlarms,
    ]
    return concatenatedAlarms
  }

  let sortedAlarms = sortAlarms(user[alarmsPath])

  function convertTimestampToCustomFormat(timestamp) {
    // Create a new Date object with the timestamp in milliseconds
    var date = new Date(timestamp)

    // Define month names array
    var monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]

    // Extract day, month, hour, and minute from the date object
    var day = date.getDate()
    var month = monthNames[date.getMonth()]
    var hour = date.getHours()
    var minute = date.getMinutes()
    let dontShowTime = false
    if (hour === 23 && minute === 59) {
      dontShowTime = true
    }

    // Format the output string
    var formattedTime =
      day +
      ' ' +
      month +
      (dontShowTime
        ? ''
        : ' - ' +
        hour.toString().padStart(2, '0') +
        ':' +
        minute.toString().padStart(2, '0'))

    return formattedTime
  }

  const createAlarm = async newAlarmObject => {
    let adminStore = store.getState().admin
    let users;
    let oneUser;
    if (alarmsPath === "crmAlarms") {
      users = adminStore.customers
      oneUser = helper.findBy(users, "id", user.id)
    } else if (alarmsPath === "crmAlarmsSignUp") {
      users = adminStore.signUps
      oneUser = helper.findBy(users, "id", user.id)
    } else if (alarmsPath === "crmAlarmsDemoMeeting") {
      users = adminStore.demoMeetings
      oneUser = helper.findBy(users, "_id", user.id)
    } else if (alarmsPath === "crmAlarmsAgency") {
      users = adminStore.agenciesUsers
      oneUser = helper.findBy(users, "id", user.id)
    }

    const key = Object.keys(newAlarmObject)[0]
    let value = newAlarmObject[key]
    value.alarmsPath = alarmsPath

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createNewAlarm/${user.id}`,
        'PATCH',
        JSON.stringify(value),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      value._id = result.newAlarm._id

      let newUserData
      newUserData = {
        ...user,
        [alarmsPath]: [...(oneUser[alarmsPath] ?? []), value],
      }
      dispatch(
        findByIdAndUpdate({
          id: user.id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const editAlarm = async newAlarmObject => {
    const key = Object.keys(newAlarmObject)[0]
    const value = newAlarmObject[key]
    value.alarmsPath = alarmsPath

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/editAlarm/${user.id}`,
        'PATCH',
        JSON.stringify(value),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      let newUserData
      const oldAlarmIndex = helper.findIndex(sortedAlarms, '_id', value._id)

      let newAlarms = [...sortedAlarms]
      newAlarms[oldAlarmIndex] = value

      newUserData = {
        ...user,
        [alarmsPath]: result.user[alarmsPath],
      }
      dispatch(
        findByIdAndUpdate({
          id: user.id,
          data: newUserData,
        }),
      )
      toast.success('Changes saved!')
      if (result.nextAlarmTitle) {
        toast.success('"' + result.nextAlarmTitle + '" alarm has been created!')
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  const removeAlarm = async alarmId => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/removeAlarm/${user.id}`,
        'PATCH',
        JSON.stringify({ _id: alarmId, alarmsPath }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      let newUserData
      const oldAlarmIndex = helper.findIndex(sortedAlarms, '_id', alarmId)

      let newAlarms = [...sortedAlarms]
      newAlarms.splice(oldAlarmIndex, 1)
      newUserData = {
        ...user,
        [alarmsPath]: newAlarms,
      }

      dispatch(
        findByIdAndUpdate({
          id: user.id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  function openEditAlarmModal(oneAlarm) {
    openModal({
      type: Modals.EDIT_ALARM,
      closeOnClickOutside: true,
      customer: {
        id: user._id,
        name: user.name,
      },
      alarm: oneAlarm,
      callback: async ({ dueDate, title, note, done, attachedUserIds }) => {
        const newAlarmObject = {
          [oneAlarm._id]: {
            ...oneAlarm,
            dueDate,
            title,
            note,
            done,
            attachedUserIds,
          },
        }

        await editAlarm(newAlarmObject)
        if (done && oneAlarm.done !== done && !oneAlarm.nextAlarm) {
          openNewAlarmModal()
        }
      },
      removeCallback: async () => {
        await removeAlarm(oneAlarm._id)
      },
    })
  }
  function openNewAlarmModal() {
    openModal({
      type: Modals.NEW_ALARM,
      closeOnClickOutside: true,
      customer: {
        id: user._id,
        name: user.name,
        type: alarmsPath === "crmAlarms" ?
          "Customer"
          : alarmsPath === "crmAlarmsSignUp" ?
            "Sign Up"
            : alarmsPath === "crmAlarmsDemoMeeting" ?
              "Demo" : ""
      },

      callback: async ({ dueDate, title, note, selectedPredefinedAlarm, meetingLink, attachedUserIds }) => {
        if (selectedPredefinedAlarm) {
          const newAlarmObject = {
            new: {
              dueDate,
              title: selectedPredefinedAlarm.title,
              nextAlarm: selectedPredefinedAlarm.nextAlarm,
              note,
              done: false,
              selectedPredefinedAlarm: selectedPredefinedAlarm,
              attachedUserIds,
            },
          }
          if (meetingLink) {
            newAlarmObject.new.meetingInfo = {
              zoomJoinUrl: meetingLink
            }
          }
          await createAlarm(newAlarmObject)
        } else {
          const newAlarmObject = {
            new: {
              dueDate,
              title,
              note,
              done: false,
              attachedUserIds,
            },
          }
          if (meetingLink) {
            newAlarmObject.new.meetingInfo = {
              zoomJoinUrl: meetingLink
            }
          }
          await createAlarm(newAlarmObject)
        }
      },
    })
  }

  const copyAlarmToTomorrow = async (alarm) => {
    let tomorrow;
    if (alarm.done) {
      tomorrow = new Date(alarm.completedAt);
      tomorrow.setHours(23, 59, 0, 0);
    } else {
      tomorrow = new Date(alarm.dueDate);
    }

    // If it's Friday (5), set to next Monday
    if (tomorrow.getDay() === 5) {
      tomorrow.setDate(tomorrow.getDate() + 3);
    } else {
      tomorrow.setDate(tomorrow.getDate() + 1);
    }

    const newAlarmObject = {
      new: {
        dueDate: tomorrow.toISOString(),
        title: alarm.title,
        note: alarm.note,
        done: false,
        meetingInfo: alarm.meetingInfo,
      },
    };

    await createAlarm(newAlarmObject);
  };

  const markAlarmAsDoneUndone = async (alarm) => {
    const updatedAlarm = {
      [alarm._id]: {
        ...alarm,
        done: !alarm.done,
        completedAt: new Date().toISOString(),
      },
    };

    await editAlarm(updatedAlarm);
  };

  return (
    <div className="alarm-part-wrapper">
      <div
        className="one-alarm-container new"
        onClick={() => {
          openNewAlarmModal()
        }}
      >
        <MdOutlineAddAlarm size={20} color="#2a895b" />
        <div className="new-title">New alarm</div>
      </div>
      {sortedAlarms.slice(0, 2).map(oneAlarm => {
        return (
          <OneAlarm
            key={oneAlarm._id}
            alarm={oneAlarm}
            onClick={() => openEditAlarmModal(oneAlarm)}
            onCopy={() => copyAlarmToTomorrow(oneAlarm)}
            onDoneUndone={() => markAlarmAsDoneUndone(oneAlarm)}
            onRemove={() => removeAlarm(oneAlarm._id)}
            convertTimestampToCustomFormat={convertTimestampToCustomFormat}
          />
        )
      })}
      {sortedAlarms.length > 2 ? (
        <div className="see-all-button">
          <Link
            onClick={() => {
              openModal({
                type: Modals.SEE_ALL_ALARMS,
                closeOnClickOutside: true,
                customer: {
                  id: user._id,
                  name: user.name,
                },
                alarms: sortedAlarms,
                openEditAlarmModal: openEditAlarmModal,
              })
            }}
          >
            See all
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default CustomerCardAlarmPart
