import React, { useState } from 'react'
import {
  FaChevronDown,
  FaSave,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useConstants } from '../../shared/hooks/constants-hook'

import { Link } from 'react-router-dom'
import Checkbox from '../../shared/components/FormElements/Checkbox'

import './SpecialUserCard.css'
import QuickDropdown from './cards-components/QuickDropdown'
import errorHandler from '../../shared/util/errorHandler'

const SpecialUserCard = ({ user }) => {
  const { sendRequest } = useHttpClient()
  const { privateConstants: constants } = useConstants()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()

  const role = user.accessDetails?.role || 'Basic'

  const [permissions, setPermissions] = useState(
    user.accessDetails.permissions || [],
  )

  const [isPageviewPermissionsVisible, setIsPageviewPermissionsVisible] = useState(false)
  const [isCustomerCardPermissionsVisible, setIsCustomerCardPermissionsVisible] = useState(false)
  const [isSignUpCardPermissionsVisible, setIsSignUpCardPermissionsVisible] = useState(false)
  const [isDemoMeetingCardPermissionsVisible, setIsDemoMeetingCardPermissionsVisible] = useState(false)
  const [isCustomerAccountPagePermissionsVisible, setIsCustomerAccountPagePermissionsVisible] = useState(false)

  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState(false)

  const handleCheckboxChange = (permissionKey, checked) => {
    setPermissions(prevPermissions =>
      checked
        ? [...prevPermissions, permissionKey]
        : prevPermissions.filter(p => p !== permissionKey),
    )
    setIsSaveButtonVisible(true)
  }

  const savePermissions = async () => {
    await save('accessDetails.permissions', permissions)
  }

  const save = async (field, payload) => {
    const query = {}
    let isSpecialUser = user.isSpecialUser

    if (payload === 'Basic') {
      isSpecialUser = false
    }

    if (field === 'accessDetails.role') {
      query['accessDetails.role'] = payload
      if (payload === 'Basic') {
        query['isSpecialUser'] = false
      } else {
        query['isSpecialUser'] = true
      }
    }
    if (field === 'accessDetails.permissions') {
      query['accessDetails.permissions'] = payload
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${user._id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      let newUserData = {
        ...user,
        accessDetails: {
          ...user.accessDetails,
          permissions:
            field === 'accessDetails.permissions'
              ? payload
              : result.user.accessDetails.permissions,
          role:
            field === 'accessDetails.role' ? payload : user.accessDetails.role,
        },
        isSpecialUser: isSpecialUser,
      }

      dispatch(
        adminActions.findSpecialUserByIdAndUpdate({
          id: user._id,
          data: newUserData,
        }),
      )

      setIsSaveButtonVisible(false)
      setPermissions(result.user.accessDetails.permissions || permissions)

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <div className={`special-user-card`} key={user._id}>
      <div className={`card-header ${role.toLowerCase().replaceAll(' ', '-')}`}>
        <div className="section" style={{ maxWidth: '90%' }}>
          <span className="username">{user.name.toLowerCase()}</span>
          <span className="username">-</span>
          <span className="role">{role}</span>
        </div>
      </div>
      <div className="card-content">
        <div className="section contact-info">

          <div className="email">
            <Link
              className="email"
              to={`mailto:${user.email}`}
              title="Send new mail"
            >
              {user.email}
            </Link>
          </div>
        </div>
        <div className="section user-role">
          <div className='role-text'>Role:</div>
          <QuickDropdown
            className="role-selection"
            options={
              constants.userRoles
            }
            emptyText={"<Not set>"}
            preSelectedOption={role}
            onSave={async (value) => {
              await save('accessDetails.role', value)
            }} />
        </div>


        <div className="section permissions">
          <span className="section-header" onClick={() => { setIsPageviewPermissionsVisible(!isPageviewPermissionsVisible) }}>
            Page View Permissions
            <FaChevronDown className={`icon ${isPageviewPermissionsVisible ? 'rotated' : ''}`} />
          </span>
          {
            isPageviewPermissionsVisible &&
            <div className="permissions-grid">
              {constants.permissions.pageVisibility.map(permission => {
                return (
                  <Checkbox
                    key={permission.key}
                    label={permission.name}
                    checked={permissions?.includes(permission.key)}
                    onChange={e => {
                      handleCheckboxChange(permission.key, e.target.checked)
                    }}
                  />
                )
              })}
            </div>
          }
        </div>


        <div className="section permissions">
          <span className="section-header" onClick={() => { setIsCustomerCardPermissionsVisible(!isCustomerCardPermissionsVisible) }}>
            Customer Card Permissions
            <FaChevronDown className={`icon ${isCustomerCardPermissionsVisible ? 'rotated' : ''}`} />
          </span>
          {
            isCustomerCardPermissionsVisible &&
            <div className="permissions-grid">
              {constants.permissions.customerCards.map(permission => {
                return (
                  <Checkbox
                    key={permission.key}
                    label={permission.name}
                    checked={permissions?.includes(permission.key)}
                    onChange={e => {
                      handleCheckboxChange(permission.key, e.target.checked)
                    }}
                  />
                )
              })}
            </div>
          }
        </div>

        <div className="section permissions">
          <span className="section-header" onClick={() => { setIsSignUpCardPermissionsVisible(!isSignUpCardPermissionsVisible) }}>
            Sign Up Cards Permissions
            <FaChevronDown className={`icon ${isSignUpCardPermissionsVisible ? 'rotated' : ''}`} />
          </span>
          {
            isSignUpCardPermissionsVisible &&
            <div className="permissions-grid">
              {constants.permissions.signUpCards.map(permission => {
                return ((permission.key === "canChangeEveryField" && role === "Account Manager") || (permission.key !== "canChangeEveryField")) && (
                  <Checkbox
                    key={permission.key}
                    label={permission.name}
                    checked={permissions?.includes(permission.key)}
                    onChange={e => {
                      handleCheckboxChange(permission.key, e.target.checked)
                    }}
                  />
                )
              })}
            </div>
          }
        </div>

        <div className="section permissions">

          <span className="section-header" onClick={() => { setIsDemoMeetingCardPermissionsVisible(!isDemoMeetingCardPermissionsVisible) }}>
            Demo Meeting Cards Permissions
            <FaChevronDown className={`icon ${isDemoMeetingCardPermissionsVisible ? 'rotated' : ''}`} />
          </span>
          {
            isDemoMeetingCardPermissionsVisible &&
            <div className="permissions-grid">
              {constants.permissions.demoMeetingCards.map(permission => {
                return (
                  <Checkbox
                    key={permission.key}
                    label={permission.name}
                    checked={permissions?.includes(permission.key)}
                    onChange={e => {
                      handleCheckboxChange(permission.key, e.target.checked)
                    }}
                  />
                )
              })}
            </div>
          }
        </div>

        <div className="section permissions">
          <span className="section-header" onClick={() => { setIsCustomerAccountPagePermissionsVisible(!isCustomerAccountPagePermissionsVisible) }}>
            Customer Account Page Permissions
            <FaChevronDown className={`icon ${isCustomerAccountPagePermissionsVisible ? 'rotated' : ''}`} />
          </span>
          {
            isCustomerAccountPagePermissionsVisible &&
            <div className="permissions-grid">
              {constants.permissions.accountDetailsPageCards.map(permission => {
                return (
                  <Checkbox
                    key={permission.key}
                    label={permission.name}
                    checked={permissions?.includes(permission.key)}
                    onChange={e => {
                      handleCheckboxChange(permission.key, e.target.checked)
                    }}
                  />
                )
              })}
            </div>
          }
        </div>


        {isSaveButtonVisible && (
          <div className="section">
            <div className="save-button-container">
              <button onClick={savePermissions} className="save-btn">
                <FaSave className="icon" /> Save Changes
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SpecialUserCard
