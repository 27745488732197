import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import CampaignsPageLayout from '../components/campaign/CampaignsPageLayout'
import FullFunnelPreviewCard from '../components/campaign/FullFunnelPreviewCard'
import FullFunnelDetail from '../components/campaign/FullFunnelDetail'

import './FullFunnelCampaigns.css'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { useFullFunnel } from '../../shared/hooks/facebook/full-funnel-hook'


const FullFunnelCampaigns = () => {
  const connectionPageIsReady = useSelector(
    state => state.facebook.connectionPageIsReady,
  )
  const location = useLocation()
  const { newFullFunnelSelected } = useFullFunnel()
  const facebook = useSelector(state => state.facebook)

  const facebookAuth = useSelector(state => state.facebook.auth)
  const isConnected = facebook?.selectedAdAccount?.id && facebook?.selectedBusiness?.id
  const facebookAccessToken = facebookAuth?.accessToken
  const fullFunnels = useSelector(state => state.facebook.fullFunnels)
  const fullFunnelInViewId = useSelector(state => state.facebook.fullFunnelInViewId)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const fullFunnelId = searchParams.get('id')
    if (facebookAccessToken) {
      if (fullFunnelId) {
        newFullFunnelSelected({ fullFunnelId, accessToken: facebookAccessToken })
      } else {
        newFullFunnelSelected({ fullFunnelId: 'allFullFunnels', accessToken: facebookAccessToken })
      }
    }
  }, [location, facebookAccessToken, fullFunnelInViewId])
  

  return !connectionPageIsReady ? (
    <LoadingSpinner />
  ) : facebookAuth && facebookAuth.accessToken ? (
    isConnected ? (
      <CampaignsPageLayout>
        {fullFunnelInViewId &&
        fullFunnels.find(c => c._id === fullFunnelInViewId) ? (
          <FullFunnelDetail
            key={fullFunnelInViewId}
            fullFunnelId={fullFunnelInViewId}
          />
        ) : fullFunnels.length ? (
          <React.Fragment>
            {fullFunnels?.map(c => (
              <FullFunnelPreviewCard campaign={c} key={c._id} />
            ))}
          </React.Fragment>
        ) : <></>}
      </CampaignsPageLayout>
    ) : (
      <div>Not connected</div>
    )
  ) : (
    <div>No Facebook Authentication</div>
  )
}

export default FullFunnelCampaigns
