import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import IntroHeadlineContainer from '../components/intro/IntroHeadlineContainer'

import circleCheck from '../../shared/assets/images/icons/circle-check.svg'
import circleCheckFilled from '../../shared/assets/images/icons/circle-check-filled.svg'
import { useConstants } from '../../shared/hooks/constants-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import ProfilePageLayout from '../components/ProfilePageLayout'

import './SelectCategory.css'
import { FaArrowRight } from 'react-icons/fa'
import Button from '../../shared/components/FormElements/Button'
import { useShopify } from '../../shared/hooks/shopify-hook'
import errorHandler from '../../shared/util/errorHandler'

const SelectCategory = ({ inAccountSettings }) => {
  const navigate = useNavigate()
  const { publicConstants } = useConstants()
  const [saving, setSaving] = useState(false)
  const isMounted = useRef(true)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const { connectShopifyStore } = useShopify()
  const user = auth.user
  const [selectedCategories, setSelectedCategories] = useState(
    user.userSelectedEnhencerCategories || [],
  )
  const initialCategories = user.userSelectedEnhencerCategories || []

  const [isDirty, setIsDirty] = useState(false)
  const [isApparelSelected, setIsApparelSelected] = useState(
    initialCategories.includes('Apparel & Accessories'),
  )
  const headlines = [
    {
      text: 'What’s your ',
      highlight: 'Company’s Sector?',
    },
  ]
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!inAccountSettings) {
      dispatch(
        authActions.updateUserObjOneField({
          field: "isInSignupFlow",
          value: true,
        }),
      )
    }

    return () => {
      isMounted.current = false
    }
  }, [])

  const categorySelected = itemName => {
    const newSelectedCategories = [...selectedCategories]

    if (newSelectedCategories.includes(itemName)) {
      newSelectedCategories.splice(newSelectedCategories.indexOf(itemName), 1)
    } else {
      newSelectedCategories.push(itemName)
    }

    setSelectedCategories(newSelectedCategories)

    publicConstants.topEnhencerCategories.forEach(item => {
      if (item.name === itemName) {
        item.selected = !item.selected
      }
    })
  }

  useEffect(() => {
    if (selectedCategories.includes('Apparel & Accessories'))
      setIsApparelSelected(true)
    else setIsApparelSelected(false)

    if (initialCategories.length !== selectedCategories.length) {
      setIsDirty(true)
      return
    }
    var sortedinitialCategories = initialCategories.slice().sort()
    var sortedselectedCategories = selectedCategories.slice().sort()
    const isChanged = sortedinitialCategories.every(
      (value, index) => value === sortedselectedCategories[index],
    )
    setIsDirty(!isChanged)
  }, [selectedCategories, initialCategories])

  useEffect(() => {
    // Simulate loading delay for demonstration purposes
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])
  useEffect(() => {
    handleAppClass(true)

    return () => {
      handleAppClass(false)
    }
  }, [])

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-noscroll')
      : document.getElementById('app')?.classList.remove('full-screen-noscroll')
  }
  const saveCategories = async isApparelSelected => {
    try {
      setSaving(true)

      const enhencerCategories = selectedCategories

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${inAccountSettings ? 'updateEnhencerCategories' : 'initializeEnhencerCategories'}`,
        'POST',
        JSON.stringify({
          userId: user.id,
          enhencerCategories: enhencerCategories,
        }),
        {
          authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      if (isMounted.current) {
        dispatch(
          authActions.updateUserObj({
            ...user,
            userSelectedEnhencerCategories: enhencerCategories,
            isInSignupFlow: inAccountSettings ? false : true,
          }),
        )
        if (isApparelSelected) {
          if(inAccountSettings){
            navigate('/account/industry-selection/apparel', {
              state: { selectedCategories: selectedCategories },
            })
          }else{
            navigate('/industry-selection/apparel', {
              state: { selectedCategories: selectedCategories },
            })
          }
        } else {
          toast.success('Selected categories saved!')
          if (!inAccountSettings && auth.user.shopify) {
            await connectShopifyStore()
          }
          if (!inAccountSettings) navigate('/your-team')
        }
      }
    } catch (err) {
      errorHandler(err)
    } finally {
      if (isMounted.current) {
        setSaving(false)
      }
    }
  }

  const pageContent = (
    <div className="select-category-container">
      <div className="header-row">
        {isDirty && !isApparelSelected && (
          <Button
            className="save-button"
            disabled={saving}
            onClick={() => saveCategories(isApparelSelected)}
          >
            {' '}
            {saving
              ? 'Saving'
              : inAccountSettings
                ? 'Save'
                : 'Save and Continue'}
          </Button>
        )}
        {isApparelSelected && (
          <Button
            className="save-button"
            disabled={saving}
            onClick={() => saveCategories(isApparelSelected)}
          >
            Apparel Subcategories
            <FaArrowRight className="icon" />
          </Button>
        )}
      </div>
      <div className="category-part">
        <div className="category-part-grid">
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <div className="category-row-item-wrapper" key={index}>
                  <Skeleton height={250} />
                </div>
              ))
            : publicConstants.topEnhencerCategories.map((item, index) => (
                <div
                  className={`category-row-item-wrapper ${
                    selectedCategories.includes(item.name) ? 'selected' : ''
                  }`}
                  key={index}
                >
                  <div
                    className={`category-part-row-item ${
                      selectedCategories.includes(item.name) ? 'selected' : ''
                    }`}
                    onClick={() => categorySelected(item.name)}
                  >
                    <div className="category-image-overlay"></div>
                    <img
                      className="category-image"
                      src={item.imageUrl}
                      alt={item.name}
                    />

                    <div className="category-name-overlay">
                      <img className="icon" src={item.iconUrl}></img>
                      <div className="category-name-overlay-text">
                        {item.name}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  )

  return !inAccountSettings ? (
    <>
      <div className="flow-select-category-container">
        <div className="select-category-desc">
          <IntroHeadlineContainer
            headlines={headlines}
            animationEnabled={false}
          />
        </div>
        {pageContent}
      </div>
    </>
  ) : (
    <ProfilePageLayout>
      <ProfilePageLayout.Header></ProfilePageLayout.Header>
      <ProfilePageLayout.Content>{pageContent}</ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

export default SelectCategory
