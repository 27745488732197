import React, { useReducer, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

import { validate } from '../../util/validators'
import './StrokeInput.css'
import helper from '../../util/helper'

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      let isValid;

      if (action.validateValue) {
        isValid = action.validateValue(action.val)
      } else {
        if (action.element === 'range-input') {
          const value = action.val || { min: '', max: '' }
          isValid = validate(value, action.validators || [])
        } else {
          const value = action.val?.toString() || ''
          isValid = validate(value, action.validators || [])
        }
      }

      return {
        ...state,
        value: action.val,
        isValid,
        isTouched: state.isTouched,
      }
    case 'TOUCH':
      return {
        ...state,
        isTouched: true,
        isValid: state.isValid,
      }
    default:
      return state
  }
}

const StrokeInput = props => {
  const getInitialState = () => {
    return {
      value: props.initialValue || props.value || '',
      isValid: !!props.initialValue,
      isTouched: !!props.isTouched || false
    }
  }

  const [inputState, dispatch] = useReducer(inputReducer, getInitialState())
  const [localShowPassword, setLocalShowPassword] = useState(false)

  const { id, onInput } = props
  const { value, isValid, isTouched } = inputState

  const showPassword = props.showPassword !== undefined ? props.showPassword : localShowPassword

  useEffect(() => {
    if (props.forceValue === undefined || props.forceValue === null) {
      return
    }

    dispatch({
      type: 'CHANGE',
      val: props.forceValue,
      validators: props.validators || [],
      validateValue: props.validateValue,
      element: props.element,
    })
  }, [props.forceValue])

  useEffect(() => {
    if (props.onInput && props.id !== 'age') {
      onInput(id, value, isValid, isTouched)
    }
  }, [id, value, isValid, isTouched, onInput, props.id])

  useEffect(() => {
    if (props.value !== undefined && props.value !== null) {
      dispatch({
        type: 'CHANGE',
        val: props.value,
        validators: props.validators || [],
        validateValue: props.validateValue,
        element: props.element,
      })
    }
  }, [props.value, props.validators])

  const changeHandler = (event, type) => {
    let val = event.target.value

    if (props.id === 'email') {
      val = val.toLowerCase()
    }

    if (props.element === 'range-input') {
      val = {
        ...value,
        [type]: event.target.value,
      }
    }

    if (event.target.type === 'textarea' && props.id !== 'primaryText') {
      val = val.replace(/\r?\n/g, '\\n')
    }

    dispatch({
      type: 'CHANGE',
      val: val,
      validators: props.validators || [],
      validateValue: props.validateValue,
      element: props.element,
    })

    if (props.onChange) {
      props.onChange(val)
    }
  }

  const clickHandler = event => {
    if (props.onClick) {
      props.onClick(event)
    }
  }

  const touchHandler = () => {
    dispatch({ type: 'TOUCH' })
    if (props.onBlur) {
      props.onBlur()
    }
  }

  const togglePasswordVisibility = () => {
    if (props.onTogglePassword) {
      props.onTogglePassword()
    } else {
      setLocalShowPassword(prev => !prev)
    }
  }

  let element
  if (props.renderCustomInput) {
    element = props.renderCustomInput({
      id: props.id,
      value: value,
      onChange: changeHandler,
      onBlur: touchHandler,
      disabled: props.disabled,
      placeholder: props.placeholder,
      type: props.type,
      min: props.min,
      max: props.max,
      step: props.step,
    })
  } else if (props.element === 'select') {
    element = (
      <select
        id={props.id}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={value}
        className="stroke-input"
      >
        {props.options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )
  } else if (props.element === 'input') {
    element =
      props.type === 'password' ? (
        <div className="password-input-wrapper">
          <input
            id={props.id}
            type={showPassword ? 'text' : 'password'}
            placeholder={props.placeholder}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={value}
            disabled={props.disabled}
            className="stroke-input"
          />
          {props.showPasswordToggle && (
            <button
              type="button"
              className="password-toggle-btn"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          )}
        </div>
      ) : (
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={value}
          disabled={props.disabled}
          className="stroke-input"
        />
      )
  } else if (props.element === 'range-input') {
    element = (
      <div className="age-input-wrapper">
        <input
          type="number"
          min="0"
          placeholder="Min"
          value={value.min}
          onChange={e => {
            changeHandler(e, 'min')
          }}
          onBlur={touchHandler}
          className="stroke-input"
        />
        <span className="dash">-</span>
        <input
          type="number"
          min="0"
          placeholder="Max"
          value={value.max}
          onChange={e => {
            changeHandler(e, 'max')
          }}
          onBlur={touchHandler}
          className="stroke-input"
        />
      </div>
    )
  } else if (props.element === 'money-input') {
    element = (
      <div className="daily-budget-wrapper">
        <span className="currency-symbol">
          {helper.getCurrencySymbol(props.currency || 'USD')}
        </span>
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          onChange={changeHandler}
          onBlur={touchHandler}
          value={value}
          disabled={props.disabled}
          className="stroke-input with-currency"
        />
      </div>
    )
  } else if (props.element === 'textarea') {
  element = (
    <textarea
      id={props.id}
      rows={props.rows || 3}
      onChange={changeHandler}
      onBlur={touchHandler}
      onClick={clickHandler}
      value={inputState.value}
      placeholder={props.placeholder}
      disabled={props.disabled}
      className="stroke-textarea"
    />
  )
}

  const errorMessage = props.errorMessageHandler
    ? props.errorMessageHandler(value, isTouched)
    : {
        message: props.errorText,
        className: props.showInitialError 
          ? (!isValid && isTouched ? 'error' : '')
          : (!isValid && isTouched ? 'error' : ''),
      }

  return (
    <div className={`stroke-input-root ${props.leftTitle ? 'left-title' : ''}`}>
      <div
        className={`form-control ${!isValid && isTouched ? 'form-control--invalid' : ''} ${props.errorLine === false ? 'no-error-line' : ''}`}
      >
        {props.label && (
          <label htmlFor={props.id} className="stroke-input-label">
            {props.label}
            {props.required && <span className="required-asterisk">*</span>}
          </label>
        )}
        <div className="input-container">
          {element}
          {props.errorLine !== false && (
          <div className="error-text-container">
            
              <p className={`error-text ${errorMessage.className}`}>
                {props.showInitialError 
                  ? (!isValid ? errorMessage.message : '\u00A0')
                  : (!isValid && isTouched ? errorMessage.message : '\u00A0')}
              </p>
          
          </div>
            )}
        </div>
      </div>
    </div>
  )
}

StrokeInput.defaultProps = {
  showInitialError: false
}

export default StrokeInput
