import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import { projectActions } from '../../store/project'
import { authActions } from '../../store/auth'
import helper from '../util/helper'
import errorHandler from '../util/errorHandler'

export const useCampaigns = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const authToken = useSelector(state => state.auth.token)
  const isSpecialUser = useSelector(state => state.auth.user?.isSpecialUser)
  const isDemoUser = useSelector(state => state.auth.user?.isDemoUser)
  const authUserId = useSelector(state => state.auth.user?.id)

  const campaignsFetched = useSelector(state => state.project.campaignsFetched)

  const { search } = window.location
  const query = new URLSearchParams(search)
  const userInViewId = query.get('user_in_view_id')


  useEffect(() => {
    if (campaignsFetched) dispatch(projectActions.setCampaignsFetched(false))

    const getCampaigns = async () => {
      if (isSpecialUser && userInViewId) {
        if (window.location.pathname.includes('audience-event-manager')) {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${userInViewId}`,
              'GET',
              null,
              {
                Authorization: 'Bearer ' + authToken,
              },
            )
            dispatch(
              projectActions.setCampaigns({
                campaigns: responseData.campaigns,
              }),
            )
            // dispatch(projectActions.setCampaignsFetched(true))
          } catch (err) {
            errorHandler(err)
          }
        } else {
          dispatch(projectActions.setCampaigns({ campaigns: ['enhencer'] }))
          // dispatch(projectActions.setCampaignsFetched(true))
        }
      } else if (!isSpecialUser && user?.id) {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/projects/getCampaignsOfUser/${user.id}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + authToken,
            },
          )
          dispatch(
            projectActions.setCampaigns({
              campaigns: responseData.campaigns,
            }),
          )
          dispatch(projectActions.setCampaignsFetched(true))

          if (!user.isActive) {
            const resData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/checkIfDataActive`,
              'POST',
              null,
              {
                Authorization: 'Bearer ' + authToken,
              },
            )

            if (resData.isActive) {
              let connectFlow = { ...user.connectFlow } || {}
              connectFlow['dataCollectionStarted'] =
                resData.dataCollectionStarted
              connectFlow['estimatedModelRun'] = resData.estimatedModelRun
              dispatch(authActions.updateConnectFlow(connectFlow))
            }
          }
        } catch (err) {
        }
      } else {
        dispatch(projectActions.setCampaignsFetched(true))
      }
    }

    getCampaigns()
  }, [userInViewId, user?.id])

  const getCampaignDetailsAndSetCurrentCampaign = async ({ campaignId, userId }) => {
    let body
    if (isSpecialUser) {
      body = {
        userId: userId,
        campaignId: campaignId,
      }
    } else {
      body = {
        userId: authUserId,
        campaignId: campaignId,
      }
    }

    console.log("body", body)
    let url = `${process.env.REACT_APP_BACKEND_URL}/${isSpecialUser ? 'admin' : 'projects'}/getCampaignDetails/`
    try {
      const responseData = await sendRequest(
        url,
        'POST',
        JSON.stringify(body),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )
      dispatch(
        projectActions.setCurrentCampaign({
          campaign: {
            ...responseData.campaign,
            isDemo: isDemoUser,
          },
          isDemo: isDemoUser,
        }),
      )
    } catch (err) {
      errorHandler(err)
    }
  }

  return {
    campaignsFetched,
    getCampaignDetailsAndSetCurrentCampaign,
  }
}