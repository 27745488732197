import React from 'react'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useSelector } from 'react-redux'

import metaIcon from '../../shared/assets/images/icons/meta-icon-green.svg'
import numberFormatter from '../../shared/util/numberFormatter'
import helper from '../../shared/util/helper'

import './BudgetComparison.css'

const BudgetComparison = () => {
  const { averageBudget } = useSelector(state => state.adHealthCheckup.insights)
  const adAccountCurrency = useSelector(state => state.facebook.selectedAdAccount.currency)
  const currencyExchangeRate = useSelector(state => state.facebook.settings.currencyExchangeRate)
  const roundedAverageBudgetinUSD = averageBudget ? Math.round(averageBudget / currencyExchangeRate) : 0

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
  )

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
        borderRadius: 10,
        borderSkipped: false,
      },
    },

    barThickness: 13,

    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: ['#7D7D7D', '#7D7D7D', '#7D7D7D', '#7D7D7D', 'BLACK', '#7D7D7D'],
        align: 'end',
        anchor: 'end',
        offset: 10,
        formatter: value => `$${value.toLocaleString()}`,
      },
    },
    scales: {
      x: {
        ticks: {
          stepSize: 5000,
          callback: function (value) {
            if (value === 0) return '0'
            return value >= 1000 ? value / 1000 + 'K' : value
          },
        },
      },
      y: {
        ticks: {
          font: {
            size: 15, // Font size for the labels
          },
        },
        grid: {
          display: false,
        },
      },
    },
    datasets: {
      label: false,
    },
  }

  const labels = [
    'Apparel & Accessories',
    'Health & Beauty',
    'Your Budget',
    'Furniture, Home and Garden ',
  ]

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: [8323, 12678, roundedAverageBudgetinUSD, 7458],
        backgroundColor: ['#C9CACC', '#C9CACC', '#46b680', '#C9CACC'],
      },
    ],
  }
  
  return (
    <div className="budget-comparison-main">
      <div className="column">
        <div className="second-big-text">
          Monthly Budget Comparsion -{' '}
          <span className="gradient-text">by industry</span>{' '}
          <img src={metaIcon} />
        </div>
        <div className="small-text gradient-text">
          Let’s Compare your monthly budget to the industry averages
        </div>
      </div>

      <div className="column">
        <div className="smaller-text">Your Monthly Budget</div>
        <div className="second-big-text">
          <span className="currency">
            {helper.getCurrencySymbol(adAccountCurrency)}
          </span>

          {numberFormatter.formatNumber(averageBudget, 0)}
        </div>
      </div>
      <div className="bar-cart-container">
        <Bar options={options} data={data} />
      </div>
    </div>
  )
}

export default BudgetComparison
