import React from 'react'
import Chart from 'chart.js/auto'
import '../CampaignReportComparison.css'
import {
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Legend,
	Tooltip,
} from 'chart.js';
import numberFormatter from '../../../../../shared/util/numberFormatter'
import { getDataKey } from '../CampaignReportComparison';

Chart.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const BeforeVsAfterView = ({ data, currencySymbol, onCardClick }) => {
	if (!data) return null;
	const getCardData = (metric, type) => {
		const metricData = data[type]?.[getDataKey(metric)] || {};
		switch (metric) {
			case 'AdSpend':
				return {
					mainValue: metricData.total || 0,//numberFormatter.shortenNumber(metricData.total || 0),
					mainLabel: "Total",
					secondaryValue: numberFormatter.formatNumber(metricData.cpm || 0, 0),
					secondaryLabel: "CPM"
				};
			case 'Sales':
				return {
					mainValue: metricData.amount || 0,//numberFormatter.shortenNumber(metricData.amount || 0),
					mainLabel: "Amount",
					secondaryValue: metricData.purchases || 0,//numberFormatter.shortenNumber(metricData.purchases || 0),
					secondaryLabel: "Purchases"
				};
			case 'Performance':
				return {
					mainValue: metricData.roas || 0,//numberFormatter.formatNumber(metricData.roas || 0, 2),
					mainLabel: "ROAS",
					secondaryValue: metricData.costPerPurchase || 0,//numberFormatter.shortenNumber(metricData.costPerPurchase || 0),
					secondaryLabel: "Cost/Purchase"
				};
			default:
				return {
					mainValue: 0,
					mainLabel: "",
					secondaryValue: 0,
					secondaryLabel: ""
				};
		}
	};

	return (
		<div className="crc-report-grid two-rows">
			<div className="crc-report-row main-metrics">
				<SimpleComparisonCard
					title="Ad Spend"
					afterData={getCardData('AdSpend', 'after')}
					beforeData={getCardData('AdSpend', 'before')}
					prefix={currencySymbol}
					color="#0585E0"
					onClick={() => onCardClick('AdSpend')}
				/>
				<SimpleComparisonCard
					title="Sales"
					afterData={getCardData('Sales', 'after')}
					beforeData={getCardData('Sales', 'before')}
					prefix={[currencySymbol, '']}
					color="#00963E"
					onClick={() => onCardClick('Sales')}
				/>
				<SimpleComparisonCard
					title="Performance"
					afterData={getCardData('Performance', 'after')}
					beforeData={getCardData('Performance', 'before')}
					prefix={['', currencySymbol]}
					suffix={['x', '']}
					color="#00AEE8"
					onClick={() => onCardClick('Performance')}
				/>
			</div>
		</div>
	);
};

const SimpleComparisonCard = ({
	title,
	afterData,
	beforeData,
	prefix,
	suffix,
	color,
	onClick
}) => {
	const renderValueRow = (afterValue, beforeValue, label, index) => {
		const prefixSymbol = Array.isArray(prefix) ? prefix[index] || '' : prefix;
		const suffixSymbol = Array.isArray(suffix) ? suffix[index] || '' : suffix;
		const renderValue = (value, type) => (
			<span className={`crc-value ${type}`}>
				{prefixSymbol && <span className="crc-currency">{prefixSymbol}</span>}
				{label === 'ROAS' ?
					`${numberFormatter.formatNumber(value, 2)}` :
					`${numberFormatter.shortenNumber(value)}`}
				{suffixSymbol}
				{(
					<span className="crc-value-tooltip">
						{label === 'ROAS' ?
							`${prefixSymbol}${numberFormatter.formatNumber(value, 2)}${suffixSymbol}` :
							`${prefixSymbol}${numberFormatter.formatNumber(value, 2)}`
						}
					</span>
				)}
			</span>
		);

		return (
			<div className="crc-value-row">
				<div className="crc-value-group">
					{renderValue(afterValue, 'after')}
					<span className="crc-metric-label">{label}</span>
					{renderValue(beforeValue, 'before')}
				</div>
			</div>
		);
	};

	return (
		<div
			className="crc-comparison-card simple"
			style={{ '--card-color': color }}
			onClick={onClick}
		>
			<h3>{title}</h3>
			<div className="crc-header">
				<span className="crc-header-label after">AI Ads</span>
				<span className="crc-header-label">Before AI Ads</span>
			</div>
			<div className="crc-comparison-content">
				<div className="crc-values-container">
					{renderValueRow(
						afterData.mainValue,
						beforeData.mainValue,
						afterData.mainLabel,
						0
					)}
					{renderValueRow(
						afterData.secondaryValue,
						beforeData.secondaryValue,
						afterData.secondaryLabel,
						1
					)}
				</div>
			</div>
		</div>
	);
};
export default BeforeVsAfterView