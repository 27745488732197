import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react'

import Editor from '../canvas/Editor'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import helper from '../../../shared/util/helper'
import {
  NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES,
  NUMBER_OF_COMMON_TEMPLATES,
  NUMBER_OF_ENHENCED_CATALOG_TEMPLATES,
} from '../../support/constants'

import styles from './KonvaArea.module.css'
import NewEditor from '../canvas/NewEditor'

const KonvaArea = ({ templates, type, onStageReady, onElementSelected }) => {
  const {
    productOnDraft,
    draftConfiguration,
    isComparingWithOriginal,
    isStageLoading,
  } = useEnhencedCatalog()
  const originalImage = useRef(null)
  const stageRef = useRef(null)
  const controlsRef = useRef(null)

  const [editorControls, setEditorControls] = useState({
    selectedElement: null,
    selectedElementType: null,
    selectedElementId: null,
    bringElementForward: () => {},
    bringElementToFront: () => {},
    sendElementBackward: () => {},
    sendElementToBack: () => {},
    getElementZPosition: () => null,
    getSelectedElementNode: () => null,
  })
  const [zPosition, setZPosition] = useState(null)

  const selectedTemplateId = useMemo(() => {
    let templateId

    if (type === 'carousel' || type === 'single') {
      templateId = `${NUMBER_OF_CAROUSEL_AND_SINGLE_TEMPLATES + NUMBER_OF_COMMON_TEMPLATES}-carousel-single`
    }

    if (type === 'enhenced-catalog') {
      templateId = `${NUMBER_OF_ENHENCED_CATALOG_TEMPLATES + NUMBER_OF_COMMON_TEMPLATES}-enhenced-catalog`
    }

    return draftConfiguration?.template?.id ?? templateId
  }, [draftConfiguration?.template, type])

  const configOfSelectedTemplate = useMemo(() => {
    return helper.findBy(templates, 'id', selectedTemplateId).config
  }, [templates, selectedTemplateId])

  // Get the element type name for the selected element
  const getSelectedElementName = () => {
    if (editorControls.selectedElement === null) return 'Element';
    
    const elementConfig = configOfSelectedTemplate.elements?.[editorControls.selectedElement];
    if (!elementConfig) return 'Element';
    
    // Format the element type with optional ID
    const elementType = elementConfig.type.charAt(0).toUpperCase() + elementConfig.type.slice(1);
    const elementId = elementConfig.id ? ` (${elementConfig.id})` : '';
    
    return `${elementType}${elementId}`;
  };

  // Update z-position information when selected element changes
  React.useEffect(() => {
    // Use controlsRef instead of editorControls to prevent unnecessary rerenders
    if (editorControls.selectedElement !== null && controlsRef.current?.getElementZPosition) {
      const position = controlsRef.current.getElementZPosition(editorControls.selectedElement);
      setZPosition(position);
    } else {
      setZPosition(null);
    }
  }, [editorControls.selectedElement]);

  // Notify parent component when selected element changes
  useEffect(() => {
    if (onElementSelected && configOfSelectedTemplate) {
      // Create a more stable reference that won't change on every render cycle
      const elementNode = editorControls.selectedElement !== null 
        ? controlsRef.current?.getSelectedElementNode?.() 
        : null;

      // Only update when something meaningful has changed
      const elementInfo = {
        element: editorControls.selectedElement,
        elementType: editorControls.selectedElementType,
        elementId: editorControls.selectedElementId,
        elementNode: elementNode,
        templateConfig: configOfSelectedTemplate,
        // Add Z-index control functions
        bringElementToFront: controlsRef.current?.bringElementToFront,
        bringElementForward: controlsRef.current?.bringElementForward,
        sendElementBackward: controlsRef.current?.sendElementBackward,
        sendElementToBack: controlsRef.current?.sendElementToBack,
        getElementZPosition: controlsRef.current?.getElementZPosition
      };
      
      onElementSelected(elementInfo);
    }
  }, [
    editorControls.selectedElement, 
    editorControls.selectedElementType, 
    editorControls.selectedElementId, 
    onElementSelected, 
    configOfSelectedTemplate
  ]);

  // Memoize the handleStageReady function to prevent it from changing on every render
  const handleStageReady = useCallback((stage, controls) => {
    // Only set the stage ref once
    if (!stageRef.current) {
      stageRef.current = stage;
      // Forward the stage to the parent if needed
      if (onStageReady) {
        onStageReady(stage);
      }
    }

    // Store controls in ref to avoid constant re-renders
    if (controls) {
      controlsRef.current = controls;
      
      // Only update state when selectedElement changes
      if (editorControls.selectedElement !== controls.selectedElement) {
        setEditorControls(prevControls => ({
          ...prevControls,
          selectedElement: controls.selectedElement,
          selectedElementType: controls.selectedElementType,
          selectedElementId: controls.selectedElementId,
          bringElementForward: controls.bringElementForward,
          bringElementToFront: controls.bringElementToFront,
          sendElementBackward: controls.sendElementBackward,
          sendElementToBack: controls.sendElementToBack,
          getElementZPosition: controls.getElementZPosition,
          getSelectedElementNode: controls.getSelectedElementNode
        }));
      }
    }
  }, [onStageReady]);

  // Memoize these handlers to prevent re-renders
  const handleBringToFront = useCallback(() => {
    if (controlsRef.current && editorControls.selectedElement !== null) {
      controlsRef.current.bringElementToFront(editorControls.selectedElement);
      // Update z-position after the operation
      setTimeout(() => {
        if (controlsRef.current?.getElementZPosition) {
          const position = controlsRef.current.getElementZPosition(editorControls.selectedElement);
          setZPosition(position);
        }
      }, 0);
    }
  }, [editorControls.selectedElement]);

  const handleBringForward = useCallback(() => {
    if (controlsRef.current && editorControls.selectedElement !== null) {
      controlsRef.current.bringElementForward(editorControls.selectedElement);
      // Update z-position after the operation
      setTimeout(() => {
        if (controlsRef.current?.getElementZPosition) {
          const position = controlsRef.current.getElementZPosition(editorControls.selectedElement);
          setZPosition(position);
        }
      }, 0);
    }
  }, [editorControls.selectedElement]);

  const handleSendBackward = useCallback(() => {
    if (controlsRef.current && editorControls.selectedElement !== null) {
      controlsRef.current.sendElementBackward(editorControls.selectedElement);
      // Update z-position after the operation
      setTimeout(() => {
        if (controlsRef.current?.getElementZPosition) {
          const position = controlsRef.current.getElementZPosition(editorControls.selectedElement);
          setZPosition(position);
        }
      }, 0);
    }
  }, [editorControls.selectedElement]);

  const handleSendToBack = useCallback(() => {
    if (controlsRef.current && editorControls.selectedElement !== null) {
      controlsRef.current.sendElementToBack(editorControls.selectedElement);
      // Update z-position after the operation
      setTimeout(() => {
        if (controlsRef.current?.getElementZPosition) {
          const position = controlsRef.current.getElementZPosition(editorControls.selectedElement);
          setZPosition(position);
        }
      }, 0);
    }
  }, [editorControls.selectedElement]);

  // Check if forward/backward actions should be disabled
  const isForwardDisabled = zPosition?.isTop || editorControls.selectedElement === null;
  const isBackwardDisabled = zPosition?.isBottom || editorControls.selectedElement === null;

  return (
    <div className={styles.konvaAreaContainer} id="konvaContainerBox">
      {isStageLoading && !selectedTemplateId && (
        <div className={styles.konvaAreaContainerLoading}>
          <InlineLoadingSpinner isLoading={isStageLoading} />
        </div>
      )}
      <div
        className={styles.konvaAreaPreview}
        id="stage-parent"
        style={{ visibility: isStageLoading ? 'hidden' : 'visible' }}
      >
        {/* <Editor templateConfig={configOfSelectedTemplate} onStageReady={onStageReady} /> */}
        <NewEditor templateConfig={configOfSelectedTemplate} onStageReady={handleStageReady} />
        <div
          className={`${styles.originalImageContainer} ${isComparingWithOriginal ? styles.visible : styles.hidden}`}
        >
          <img
            className={styles.originalImage}
            ref={originalImage}
            src={productOnDraft?.originalImageUrl}
            alt="Original"
          />
        </div>
      </div>
    </div>
  )
}

export default KonvaArea
