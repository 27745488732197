import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaTimes } from 'react-icons/fa'
import { MdTabletMac } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'

import { useEnhencedCatalog } from '../../shared/hooks/enhenced-catalog-hook'
import { useModal } from '../../shared/hooks/modal-hook.js'
import useAICreatives from '../../shared/hooks/ai-creatives-hook.js'
import useNavigator from '../../shared/hooks/navigator-hook.js'
import Modals from '../../shared/enums/Modals.js'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb.js'
import Button from '../../shared/components/FormElements/Button.js'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner.js'
import PreviousCreativeCard from '../components/miscellaneous/PreviousCreativeCard'
import CurrentCreativeCard from '../components/miscellaneous/CurrentCreativeCard.js'
import GenerateCarouselCreativeButton from '../components/miscellaneous/GenerateCarouselCreativeButton.js'
import GenerateEnhencedCatalogButton from '../components/miscellaneous/GenerateEnhencedCatalogButton.js'
import CollapsibleTemplateSidebar from '../components/miscellaneous/CollapsibleTemplateSidebar.js'

import styles from './Home.module.css'

const Home = () => {
  const { navigateWithParams, searchParams } = useNavigator()
  const navigate = useNavigate()
  const location = useLocation()
  const { resetEnhencedCatalog } = useEnhencedCatalog()
  const user = useSelector(state => state.auth.user)
  const [currentAICreativeProducts, setCurrentAICreativeProducts] = useState([])

  const { openModal } = useModal()
  const selectedAdAccountId = useSelector(
    state => state.facebook.selectedAdAccount?.id,
  )

  const { getAll, loading, fullList: aiCreatives } = useAICreatives()

  const handleGenerateAICreative = async () => {
    const continueToNew = () => {
      sessionStorage.removeItem('aiCreativeProducts')
      resetEnhencedCatalog()
      navigateWithParams(`/ai-ads/creative-lab/products-selection`, {
        from: 'home',
      })
      sessionStorage.removeItem('currentAiCreativeId')
    }

    if (currentAICreativeProducts?.length > 0) {
      const confirmed = await openModal({
        type: Modals.ARE_YOU_SURE,
        question:
          'You have unsaved changes in your current creative. Starting a new one will discard these changes. Would you like to continue?',
      })

      if (confirmed) {
        continueToNew()
      }
    }

    continueToNew()
  }

  const handleGenerateBulkAICreative = async () => {
    const continueToNew = () => {
      sessionStorage.removeItem('aiCreativeProducts')
      resetEnhencedCatalog()
      navigateWithParams(
        `/ai-ads/creative-lab/enhenced-catalog-products-selection`,
        {
          from: 'home',
        },
      )
      sessionStorage.removeItem('currentAiCreativeId')
    }

    if (currentAICreativeProducts?.length > 0) {
      const confirmed = await openModal({
        type: Modals.ARE_YOU_SURE,
        question:
          'You have unsaved changes in your current creative. Starting a new one will discard these changes. Would you like to continue?',
      })

      if (confirmed) {
        continueToNew()
      }
    }

    continueToNew()
  }

  const handleExit = () => {
    navigateWithParams(
      `/ai-ads/full-funnel-campaigns${searchParams.campaignId ? '' : '/create'}`,
      {
        persist: 1,
        ...(searchParams.campaignId && { campaignId: searchParams.campaignId }),
        ...(searchParams.edit && { edit: searchParams.edit }),
      },
    )
  }

  useEffect(() => {
    const aiCreativeProducts = JSON.parse(
      sessionStorage.getItem('aiCreativeProducts'),
    )
    setCurrentAICreativeProducts(aiCreativeProducts)
  }, [])

  useEffect(() => {
    if (!selectedAdAccountId) return
    getAll()
  }, [selectedAdAccountId])

  // Remove all the query params from the url if they exist
  useEffect(() => {
    if (location.search) {
      navigate(location.pathname, { replace: true })
    }
  }, [location.search, location.pathname, navigate])

  return (
    <div className={styles.container}>
      <Breadcrumb
        actions={
          <Button className={styles.backButton} onClick={handleExit}>
            <FaTimes className={styles.backButtonIcon} />
            Exit
          </Button>
        }
      >
        <div className={styles.catalogTopLogo}>
          <span className={styles.catalogTopLogoText}>AI Creative Lab</span>
          <MdTabletMac className={styles.catalogTopLogoDeviceIcon} />
        </div>
      </Breadcrumb>
      <div className={styles.topbarLine} />
      <div className={styles.content}>
        <div className={styles.welcomingArea}>
          <div className={styles.row}>
            <div className={styles.textsContainer}>
              {user?.companyName && (
                <p className={styles.companyName}>Team {user?.companyName},</p>
              )}
              <p className={styles.greeting}>
                Welcome back to your Creative Lab
              </p>
            </div>
            <div className={styles.welcomingAreaActions}>
              <GenerateCarouselCreativeButton
                onClick={handleGenerateAICreative}
              />
              <GenerateEnhencedCatalogButton
                onClick={handleGenerateBulkAICreative}
              />
              {currentAICreativeProducts?.length > 0 && (
                <CurrentCreativeCard
                  name="Current AI Creative"
                  products={currentAICreativeProducts}
                />
              )}
            </div>
          </div>
          {loading && <InlineLoadingSpinner />}
          {aiCreatives.length > 0 && (
            <div className={styles.row}>
              <div className={styles.textsContainer}>
                <p className={styles.secondaryTitle}>
                  Previously Saved AI Creatives
                </p>
              </div>
              <div className={styles.previousCreativesContainer}>
                {aiCreatives.map(aiCreative => (
                  <PreviousCreativeCard
                    key={aiCreative._id}
                    id={aiCreative._id}
                    name={aiCreative.name}
                    createdAt={aiCreative.createdAt}
                    products={aiCreative.products}
                    enhencedCatalogDetails={aiCreative.enhencedCatalogDetails}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <CollapsibleTemplateSidebar />
      </div>
    </div>
  )
}

export default Home
