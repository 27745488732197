import React from 'react'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { useLocation } from 'react-router-dom'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'
import './AIAdsPageLayout.css'
import AIAgent from './AIAgent'

function AIAdsPageLayout({ children }) {
  const { navigateWithAdminParams } = useAdmin()
  const { pathname } = useLocation()

  const showBreadcrumb = pathname === '/ai-ads' || pathname === '/start'

  return (
    <div className="ai-ads">
      {showBreadcrumb && (
        <div className="ai-ads-breadcrumb-wrapper">
          <div className="ai-ads-breadcrumb">
            <Breadcrumb actions={pathname === '/ai-ads' ? <AIAgent /> : null}>
              <span onClick={() => navigateWithAdminParams('/ai-ads')}>
                Home
              </span>
            </Breadcrumb>
          </div>
        </div>
      )}
      <div className="ai-ads-main-content">
        {children}
      </div>
    </div>
  )
}

function Content({ children }) {
  return <div className="ai-ads-content-wrapper">{children}</div>
}

function Sidebar({ children }) {
  return (
    <div className="ai-ads-sidebar-wrapper">
      <div className="sidebar-items">{children}</div>
    </div>
  )
}

AIAdsPageLayout.Content = Content
AIAdsPageLayout.Sidebar = Sidebar

export default AIAdsPageLayout
