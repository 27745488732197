import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp10bg from '../../../../shared/assets/images/ai-creative/common_temp10bg.svg'
import common_temp10fg from '../../../../shared/assets/images/ai-creative/common_temp10fg.svg'

export const Template10Config = {
  name: 'Apperal #2',
  containerId: 'template10-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width * 0.767,
        height: WORKBENCH_SIZE.height * 0.929
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp10bg,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp10fg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#D55708',
      draggable: false,
      clickThrough: true
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width * 0.05,
        y: WORKBENCH_SIZE.height * 0.05
      },
      scale: {
        maxWidth: 140,
        maxHeight: 100
      },
      dynamicSrc: 'companyLogo',
      required: false
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width * 0.73,
        y: WORKBENCH_SIZE.height * 0.10
      },
      width: 250,
      height: 300,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 60,
      fontStyle: 'bold',
      color: 'color3',
      text: '{{productName}}'
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 270,
        y: WORKBENCH_SIZE.height - 130
      },
      width: 265,
      height: 60,
      fontFamily: 'Bayon',
      fontSize: 80,
      fontStyle: 'bold',
      color: 'color3',
      verticalAlign: 'middle',
      align: 'center',
      text: '{{productPrice}}'
    }
  ]
}