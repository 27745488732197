const Modals = Object.freeze({


    "SEGMENT": "SEGMENT",
    "ARE_YOU_SURE": "ARE_YOU_SURE",
    "CHANGE_PASSWORD": "CHANGE_PASSWORD",
    "MODEL_LOGS": "MODEL_LOGS",
    "TERMS_OF_USE": "TERMS_OF_USE",
    "PAYMENT_REQUIRED": "PAYMENT_REQUIRED",
    "ACCOUNT_SUSPENDED": "ACCOUNT_SUSPENDED",
    "SCHEDULE_A_DEMO": "SCHEDULE_A_DEMO",
    "CREATE_CUSTOM_SUBSCRIPTION_PLAN": "CREATE_CUSTOM_SUBSCRIPTION_PLAN",
    "SMS_VERIFICATION": "SMS_VERIFICATION",
    "STRIPE_UPDATE_CREDIT_CARD": "STRIPE_UPDATE_CREDIT_CARD",
    "CONNECTION_REQUIRED": "CONNECTION_REQUIRED",
    "GENERIC_MODAL": "GENERIC_MODAL",
    "PIXEL_EVENTS_VERIFICATION_REQUIRED": "PIXEL_EVENTS_VERIFICATION_REQUIRED",
    "CREATE_A_SUPPORT_TICKET": "CREATE_A_SUPPORT_TICKET",
    "TICKET_DETAILS": "TICKET_DETAILS",
    "GOOGLE_ADS_SUPPORT": "GOOGLE_ADS_SUPPORT",
    "CAMPAIGN_DEACTIVATION": "CAMPAIGN_DEACTIVATION",
    "CAMPAIGN_CREATION": "CAMPAIGN_CREATION",
    "BOOK_A_DEMO_WITH_US": "BOOK_A_DEMO_WITH_US",
    "FACEBOOK_RE_LOGIN": "FACEBOOK_RE_LOGIN",
    "BEFORE_YOU_CANCEL": "BEFORE_YOU_CANCEL",
    "CANCEL_SUBSCRIPTION": "CANCEL_SUBSCRIPTION",
    "NEW_ALARM": "NEW_ALARM",
    "EDIT_ALARM": "EDIT_ALARM",
    "PREDEFINED_ALARMS": "PREDEFINED_ALARMS",
    "ADD_NOTE": "ADD_NOTE",
    "EXPAND_NOTE": "EXPAND_NOTE",
    "SEE_ALL_ALARMS": "SEE_ALL_ALARMS",
    "NEW_DEMO_MEETING": "NEW_DEMO_MEETING",
    "ADMIN_CHECK_UP_MEETING": "ADMIN_CHECK_UP_MEETING",
    "UPDATE_MEETING_DATE": "UPDATE_MEETING_DATE",
    "AD_HEALTH_CHECKUP_MODAL": "AD_HEALTH_CHECKUP_MODAL",
    "START_SCREEN": "START_SCREEN",
    "PEEK_CARD": "PEEK_CARD",
    "ADD_USER": "ADD_USER",
    "LOOKALIKE_AUDIENCE_CONFIG": "LOOKALIKE_AUDIENCE_CONFIG",
    "ADSET_AUDIENCES_CONFIG": "ADSET_AUDIENCES_CONFIG",
    "ADSET_BUDGETS_CONFIG": "ADSET_BUDGETS_CONFIG",
    "ADD_NEW_AUDIENCE_EVENT": "ADD_NEW_AUDIENCE_EVENT",
    "USE_DESKTOP": "USE_DESKTOP",
    "FACEBOOK_POST_SELECTOR": "FACEBOOK_POST_SELECTOR",
    "IMAGE_GENERATOR": "IMAGE_GENERATOR",
    "MICROSOFT_ADS_SUPPORT": "MICROSOFT_ADS_SUPPORT",
    "CAMPAIGN_SETUP": "CAMPAIGN_SETUP",
    "COMPARISON_MULTIPLE_MODAL": "COMPARISON_MULTIPLE_MODAL",
    "CATALOG_PROGRESS_MODAL": "CATALOG_PROGRESS_MODAL",
    "COMPLETE_ENHENCED_CATALOG": "COMPLETE_ENHENCED_CATALOG",
    "CREATE_FULL_FUNNEL_FROM_EXISTING_CAMPAIGNS": "CREATE_FULL_FUNNEL_FROM_EXISTING_CAMPAIGNS",
})

export default Modals;