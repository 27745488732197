import { toast } from "react-toastify"

export default (err) => {
    const abortMessage = "aborted"
    if (err?.message?.includes(abortMessage)) return

    if (err?.status === 403 || err?.status === 401) {
        return toast.warn(err.message || 'You are not authorized to perform this action.')
    }
    if (err?.message === "No such table") {
        return;
    }

    return toast.error(err?.message || 'Something went wrong')

}

const consoleErrorLogger = async (sendRequest) => {

    const sendToDb = async ({
        message, source, lineno, colno, error
    }) => {

        try {
            console.log("will log")

            let res = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/errors/logConsoleError`,
                'POST',
                JSON.stringify({
                    error: error.toString(),
                    message,
                }),
                {
                },
            )

            console.log("error logging result ", res)

        } catch (err) { }
    }

    window.onerror = function (message, source, lineno, colno, error) {
        toast.error('Yess it is.')

        console.log("message")
        console.log(error.stack.toString())

        sendToDb({
            message, source, lineno, colno, error
        })

    }
}

export {
    consoleErrorLogger
}