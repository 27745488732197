import React, { useState, useMemo, useEffect } from 'react'

import Button from '../../../shared/components/FormElements/Button'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import Preview from '../canvas/Preview'

import styles from './BatchSavingOverlay.module.css'

const BatchSavingOverlay = ({ setShowBatchSavingOverlay }) => {
  const {
    selectedProductsWithConfigurations,
    draftConfiguration,
    productOnDraft,
    applyConfigurationToAllProducts,
    isBatchSaving,
    savingQueue,
    currentSavingProductId,
  } = useEnhencedCatalog()
  const [historiesByProductId, setHistoriesByProductId] = useState({})

  const currentTemplateConfig = useMemo(() => {
    const [templateNumber, ...templatePathParts] =
      draftConfiguration.template.id.split('-')
    const templatePath = templatePathParts.join('-')
    return require(
      `../../templates/geometric/${templatePath}/Template${templateNumber}`,
    )[`Template${templateNumber}Config`]
  }, [draftConfiguration])

  const currentPositions = useMemo(() => {
    const product = selectedProductsWithConfigurations.find(
      product => product.id === productOnDraft.id,
    )
    if (!product) return null
    return product.history[product.historyStep]
  }, [selectedProductsWithConfigurations, productOnDraft])

  const handleSaveAll = () => {
    applyConfigurationToAllProducts(historiesByProductId)
  }

  // Calculate progress
  const progress = useMemo(() => {
    if (!savingQueue.length) return 0
    const total = savingQueue.length
    const completed = savingQueue.indexOf(currentSavingProductId)
    return Math.round((completed / total) * 100)
  }, [savingQueue, currentSavingProductId])

  // Keep the escape key handler
  useEffect(() => {
    if (isBatchSaving) return

    const handleEscape = e => {
      if (e.key !== 'Escape') return
      setShowBatchSavingOverlay(false)
    }

    window.addEventListener('keydown', handleEscape)
    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [isBatchSaving, setShowBatchSavingOverlay])

  return (
    <div className={`${styles.savedOverlay} ${styles.fullsize}`}>
      <div className={styles.savedOverlayContent}>
        <div className={styles.lastStepOverlay}>
          <div className={styles.savedOverlayContentTopPart}>
            <div className={styles.savedOverlayContentSmallTitle}>
              Try on All Products
            </div>
            <h2 className={styles.savedOverlayContentTitle}>
              Apply last changes to all products
            </h2>
          </div>

          <div className={styles.productGrid}>
            {selectedProductsWithConfigurations.map((product, index) => (
              <div key={index} className={styles.productItem}>
                <Preview
                  templateConfig={currentTemplateConfig}
                  product={product}
                  positions={currentPositions}
                  setHistoriesByProductId={setHistoriesByProductId}
                />
              </div>
            ))}
          </div>

          <div className={styles.actionButtons}>
            <Button
              primary={true}
              size={'medium'}
              disabled={isBatchSaving}
              onClick={handleSaveAll}
              className={styles.saveAllButton}
            >
              {isBatchSaving ? `Saving ${progress}%` : 'Save All'}
            </Button>
            <div
              className={`${styles.progressBar} ${isBatchSaving ? styles.progressBarVisible : ''}`}
            >
              <div
                className={styles.progressBarFill}
                style={{ width: `${progress}%` }}
              />
            </div>

            <Button
              textButton={true}
              className={styles.continueEditingButton}
              onClick={() => {
                setShowBatchSavingOverlay(false)
              }}
            >
              Cancel without saving
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BatchSavingOverlay
