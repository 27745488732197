import { useHttpClient } from "../http-hook"
import { useSelector, useDispatch } from 'react-redux'
import { facebookActions } from '../../../store/facebook'
import helper from '../../util/helper'
import currencyOffsets from "../../data/marketing-api-currency-offset.json"
import { useFacebookError } from "./error-hook"


const formatEffectiveStatus = (status) => {
    if (!status) return 'Unknown'


    let formattedStatus = status.toLowerCase().replace(/_/g, ' ')


    formattedStatus = formattedStatus.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }).join(' ')

    const specialCases = {
        'Adset Paused': 'Ad Set Paused',
        'In Process': 'In Progress',
        'With Issues': 'Has Issues',
        'Campaign Paused': 'Campaign Paused',
        'Pending Billing Info': 'Pending Billing Information',
        'Preapproved': 'Pre-approved'
    }
    return specialCases[formattedStatus] || formattedStatus
}

export const useFacebookReport = () => {
    const { sendRequest } = useHttpClient()
    const dispatch = useDispatch()
    const { handleError } = useFacebookError()
    const facebook = useSelector(state => state.facebook)
    const isSpecialUser = useSelector(state => state.auth.user?.isSpecialUser)
    const authToken = useSelector(state => state.auth.token)
    const authHeader = {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
    }
    const facebookGraphUrl = process.env.REACT_APP_FACEBOOK_GRAPH_URL


    const fetchReportsForSingleFullFunnel = async ({
        fullFunnel,
        fullFunnelId,
        filter = { type: "date_preset", value: "last_14d" },
        accessToken = facebook.auth.accessToken,
        caching = true
    }) => {
        dispatch(facebookActions.fetchingReports(true))

        try {

            if (!fullFunnel && fullFunnelId) {
                fullFunnel = facebook.fullFunnels?.find(f => f._id === fullFunnelId)
            }

            if (!fullFunnel) {
                console.error('Full funnel not found:', fullFunnelId)
                dispatch(facebookActions.fetchingReports(false))
                return
            }


            if (!fullFunnel.adCampaigns?.length) {
                console.error('No ad campaigns found for full funnel:', fullFunnelId)
                dispatch(facebookActions.fetchingReports(false))
                return
            }

            const fullFunnelIndex = facebook.fullFunnels?.findIndex(f => f._id === fullFunnelId);
            const adAccountId = fullFunnel?.adAccountId
            const adCampaigns = fullFunnel.adCampaigns
            const campaignIds = adCampaigns.map(adCampaign => adCampaign?.campaign?.id).filter(Boolean)

            if (!campaignIds.length) {
                console.error('No valid campaign IDs found for full funnel:', fullFunnelId)
                dispatch(facebookActions.fetchingReports(false))
                return
            }

            let dateFilter = ''
            if (filter.type === "time_range") {
                dateFilter = `.time_range(${filter.value})`
            } else if (filter.type === "date_preset") {
                dateFilter = `.date_preset(${filter.value})`
            }

            const attributionSetting = ".action_attribution_windows(['7d_click','1d_view'])"

            // get adsets reports
            let filtering = `[{"field":"campaign.id","operator":"IN","value":[${campaignIds.join(",")}]}]`
            const url = `${facebookGraphUrl}${adAccountId}/adsets?filtering=${filtering}&fields=name,campaign{objective,name,id},effective_status,daily_budget,start_time,insights${attributionSetting}${dateFilter}{account_currency,clicks,cpm,cpc,impressions,reach,attribution_setting,spend,auction_bid,ctr,conversions,purchase_roas,conversion_values,actions,action_values,cost_per_conversion,cost_per_action_type}&&access_token=${accessToken}`

            const updatedAdsetsRecently = sessionStorage.getItem("updatedAdsetsRecently")
            const updatedStatusesRecently = sessionStorage.getItem("updatedStatusesRecently")
            sessionStorage.removeItem("updatedAdsetsRecently")

            const enableCaching = updatedAdsetsRecently || updatedStatusesRecently ? false : caching
            let nodes = await fetchWithPagination({ url, caching: enableCaching })
            let adsetResponse = {}

            nodes?.forEach(node => {
                let obj = {}
                let insights = node.insights?.data[0]

                if (!insights) return

                obj.campaignId = node.campaign.id
                obj.campaignName = node.campaign.name
                obj.objective = node.campaign.objective
                obj.adsetName = node.name
                obj.adsetId = node.id
                obj.status = node.effective_status
                obj.effectiveStatus = node.effective_status
                obj.startTime = (new Date(node.start_time)).valueOf()
                obj.dailyBudget = parseInt(node.daily_budget) / (currencyOffsets[facebook.selectedAdAccount?.currency] ?? 100)

                // fill insights
                obj.dateStart = insights.date_start
                obj.dateEnd = insights.date_stop
                obj.spend = parseFloat(insights.spend)
                obj.reach = parseInt(insights.reach)
                obj.impressions = parseInt(insights.impressions)
                obj.frequency = parseInt(insights.impressions) / parseInt(insights.reach)
                obj.cpm = parseFloat(insights.cpm)
                obj.clicks = parseInt(insights.clicks)
                obj.cpc = parseFloat(insights.cpc)
                obj.ctr = parseFloat(insights.ctr)

                // fill actions
                obj.addToCart = insights.actions ? parseInt(helper.findBy(insights.actions, 'action_type', 'add_to_cart')?.value) : 0
                obj.purchases = insights.actions ? parseInt(helper.findBy(insights.actions, 'action_type', 'omni_purchase')?.value) : 0

                // fill attribution windows
                obj.addToCart_7d_click = insights.actions ? parseInt(helper.findBy(insights.actions, 'action_type', 'add_to_cart')?.['7d_click']) : 0
                obj.addToCart_1d_view = insights.actions ? parseInt(helper.findBy(insights.actions, 'action_type', 'add_to_cart')?.['1d_view']) : 0
                obj.purchases_7d_click = insights.actions ? parseInt(helper.findBy(insights.actions, 'action_type', 'omni_purchase')?.['7d_click']) : 0
                obj.purchases_1d_view = insights.actions ? parseInt(helper.findBy(insights.actions, 'action_type', 'omni_purchase')?.['1d_view']) : 0

                // fill sales and cost
                if (insights.action_values) {
                    obj.sales = parseInt(helper.findBy(insights.action_values, 'action_type', 'omni_purchase')?.value || '-')
                    obj.sales_7d_click = parseInt(helper.findBy(insights.action_values, 'action_type', 'omni_purchase')?.['7d_click'] || '-')
                    obj.sales_1d_view = parseInt(helper.findBy(insights.action_values, 'action_type', 'omni_purchase')?.['1d_view'] || '-')
                }

                if (insights.cost_per_action_type) {
                    obj.costPerPurchase = parseInt(helper.findBy(insights.cost_per_action_type, 'action_type', 'omni_purchase')?.value || '-')
                    obj.costPerPurchase_7d_click = parseInt(helper.findBy(insights.cost_per_action_type, 'action_type', 'omni_purchase')?.['7d_click'] || '-')
                    obj.costPerPurchase_1d_view = parseInt(helper.findBy(insights.cost_per_action_type, 'action_type', 'omni_purchase')?.['1d_view'] || '-')
                }

                // fill roas
                obj.roas = parseFloat(insights.purchase_roas?.length > 0 ? insights.purchase_roas[0].value : '-')
                obj.roas_7d_click = parseFloat(insights.purchase_roas?.length > 0 ? insights.purchase_roas[0]['7d_click'] : '-')
                obj.roas_1d_view = parseFloat(insights.purchase_roas?.length > 0 ? insights.purchase_roas[0]['1d_view'] : '-')

                adsetResponse[node.campaign.id] = {
                    ...adsetResponse[node.campaign.id],
                    [node.name]: obj
                }
            })

            // Adset level raporları kaydet
            const transformedAdsetResponse = Object.values(adsetResponse).reduce((allAdsets, campaign) => {
                const campaignAdsets = Object.values(campaign);
                return [...allAdsets, ...campaignAdsets];
            }, []);

            // get campaign and funnel level reports
            const campaignLevelReport = generateOverallReportForFullFunnelCampaign({
                reports: transformedAdsetResponse,
                filter,
                reportLevel: "campaign"
            });

            const funnelLevelReport = generateOverallReportForFullFunnelCampaign({
                reports: campaignLevelReport,
                filter,
                reportLevel: "funnel"
            });

            dispatch(facebookActions.updateFullFunnelReports({
                fullFunnelIndex,
                reports: {
                    adsetLevel: {
                        [filter.value]: transformedAdsetResponse
                    },
                    campaignLevel: {
                        [filter.value]: campaignLevelReport
                    },
                    funnelLevel: {
                        [filter.value]: funnelLevelReport
                    }
                }
            }));

            dispatch(facebookActions.fetchingReports(false))
            return {
                adsetLevel: adsetResponse,
                campaignLevel: campaignLevelReport,
                funnelLevel: funnelLevelReport
            }

        } catch (err) {
            console.error('Error in fetchReportsForSingleFullFunnel:', err)
            handleError(err)
            dispatch(facebookActions.fetchingReports(false))
            return null
        }
    }

    const generateOverallReportForFullFunnelCampaign = ({ reports, filter, reportLevel }) => {
        const cumulativeParams = [
            'spend', 'impressions', 'clicks', 'reach', 'purchases', 'sales', 'addToCart', 'dailyBudget',
            'addToCart_7d_click', 'addToCart_1d_view', 'purchases_7d_click', 'purchases_1d_view',
            'sales_7d_click', 'sales_1d_view'
        ];

        const calculateRatios = (acc) => {
            acc.roas = acc.spend ? acc.sales / acc.spend : 0;
            acc.cpc = acc.clicks ? acc.spend / acc.clicks : 0;
            acc.cpm = acc.impressions ? (acc.spend / acc.impressions) * 1000 : 0;
            acc.costPerPurchase = acc.purchases ? acc.spend / acc.purchases : 0;
            acc.ctr = acc.impressions ? (acc.clicks / acc.impressions * 100) : 0;
            acc.frequency = acc.reach ? acc.impressions / acc.reach : 0;
            acc.roas_7d_click = acc.sales_7d_click ? acc.sales_7d_click / acc.spend : 0;
            acc.roas_1d_view = acc.sales_1d_view ? acc.sales_1d_view / acc.spend : 0;
            acc.costPerPurchase_7d_click = acc.purchases_7d_click ? acc.spend / acc.purchases_7d_click : 0;
            acc.costPerPurchase_1d_view = acc.purchases_1d_view ? acc.spend / acc.purchases_1d_view : 0;
            return acc;
        };


        if (reportLevel === "campaign") {
            // create campaign level report
            return Object.values(
                reports.reduce((campaigns, adset) => {
                    const campaignId = adset.campaignId;
                    if (!campaigns[campaignId]) {
                        campaigns[campaignId] = {
                            campaignId,
                            campaignName: adset.campaignName,
                            objective: adset.objective,
                            status: adset.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
                            effectiveStatus: adset.effectiveStatus === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
                            startTime: adset.startTime,
                            dateStart: adset.dateStart,
                            dateEnd: adset.dateEnd
                        };
                        cumulativeParams.forEach(param => campaigns[campaignId][param] = 0);
                    }

                    if (adset.status === 'ACTIVE') campaigns[campaignId].status = 'ACTIVE';
                    if (adset.effectiveStatus === 'ACTIVE') campaigns[campaignId].effectiveStatus = 'ACTIVE';
                    if (!campaigns[campaignId].startTime || campaigns[campaignId].startTime > adset.startTime) {
                        campaigns[campaignId].startTime = adset.startTime;
                    }
                    if (!campaigns[campaignId].dateStart || campaigns[campaignId].dateStart > adset.dateStart) {
                        campaigns[campaignId].dateStart = adset.dateStart;
                    }
                    if (!campaigns[campaignId].dateEnd || campaigns[campaignId].dateEnd < adset.dateEnd) {
                        campaigns[campaignId].dateEnd = adset.dateEnd;
                    }

                    cumulativeParams.forEach(param => {
                        campaigns[campaignId][param] += (adset[param] || 0);
                    });

                    return campaigns;
                }, {})
            ).map(campaign => calculateRatios(campaign));
        } else if (reportLevel === "funnel") {
            // create funnel level report or others
            return calculateRatios(
                reports.reduce((acc, campaign) => {
                    if (!acc.status) {
                        acc.status = campaign.status;
                    } else if (campaign.status === 'ACTIVE') {
                        acc.status = 'ACTIVE';
                    }

                    if (!acc.effectiveStatus) {
                        acc.effectiveStatus = campaign.effectiveStatus;
                    } else if (campaign.effectiveStatus === 'ACTIVE') {
                        acc.effectiveStatus = 'ACTIVE';
                    }

                    if (!acc.startTime || acc.startTime > campaign.startTime) acc.startTime = campaign.startTime;
                    if (!acc.dateStart || acc.dateStart > campaign.dateStart) acc.dateStart = campaign.dateStart;
                    if (!acc.dateEnd || acc.dateEnd < campaign.dateEnd) acc.dateEnd = campaign.dateEnd;

                    cumulativeParams.forEach(param => {
                        acc[param] = (acc[param] || 0) + (campaign[param] || 0);
                    });

                    return acc;
                }, {})
            );
        } else {
            return calculateRatios(
                reports.reduce((acc, report) => {
                    if (!acc.status) {
                        acc.status = report?.status;
                    } else if (report?.status === 'ACTIVE') {
                        acc.status = 'ACTIVE';
                    }

                    if (!acc.effectiveStatus) {
                        acc.effectiveStatus = report?.effectiveStatus;
                    } else if (report?.effectiveStatus === 'ACTIVE') {
                        acc.effectiveStatus = 'ACTIVE';
                    }

                    if (!acc.startTime || acc.startTime > report?.startTime) {
                        acc.startTime = report?.startTime;
                    }
                    if (!acc.dateStart || acc.dateStart > report?.dateStart) {
                        acc.dateStart = report?.dateStart;
                    }
                    if (!acc.dateEnd || acc.dateEnd < report?.dateEnd) {
                        acc.dateEnd = report?.dateEnd;
                    }

                    cumulativeParams.forEach(param => {
                        acc[param] = (acc[param] || 0) + (report?.[param] || 0);
                    });

                    return acc;
                }, {})
            );
        }

    };

    const calculateFunnelLevelReportForObjective = ({ singleFullFunnel, filter, objective, campaignLevelReport }) => {
        let campaignReports = campaignLevelReport ?? singleFullFunnel.reports?.campaignLevel[filter.value]
        let filteredCampaignReports;

        if (!campaignReports) return

        if (objective === "Conversion Campaigns") {
            filteredCampaignReports = campaignReports.filter(report => report.objective === "OUTCOME_SALES")
        } else if (objective === "Boost Campaigns") {
            filteredCampaignReports = campaignReports.filter(report => report.objective !== "OUTCOME_SALES")
        }

        const funnelLevelReport = generateOverallReportForFullFunnelCampaign({
            reports: filteredCampaignReports,
            filter,
            reportLevel: "funnel"
        });
        funnelLevelReport.label = singleFullFunnel?.label

        dispatch(facebookActions.updateFullFunnelReports({
            fullFunnelIndex: facebook.fullFunnels?.findIndex(f => f._id === singleFullFunnel._id),
            reports: {
                funnelLevel: {
                    [filter.value]: funnelLevelReport
                }
            }
        }));
        return funnelLevelReport
    }

    const calculateFullFunnelsOverallReport = async ({ fullFunnels, filter, objective }) => {
        // first find the funnel level reports for the all full funnels

        let funnelLevelReports = []
        await Promise.all(
            fullFunnels.map(async (fullFunnel) => {
                const id = fullFunnel._id
                if (!fullFunnel.reports?.funnelLevel[filter.value]) {
                    let response = await fetchReportsForSingleFullFunnel({ filter, fullFunnelId: id, accessToken: facebook.auth.accessToken })
                    let campaignLevelReport = response?.campaignLevel
                    const funnelLevelReport = calculateFunnelLevelReportForObjective({ singleFullFunnel: fullFunnel, filter, objective, campaignLevelReport })
                    funnelLevelReports.push({ ...funnelLevelReport, id })
                } else {
                    const funnelLevelReport = calculateFunnelLevelReportForObjective({ singleFullFunnel: fullFunnel, filter, objective })
                    funnelLevelReports.push({ ...funnelLevelReport, id })
                }
            })
        );

        // calculate the overall report for the funnel level reports
        let overallReport = generateOverallReportForFullFunnelCampaign({
            reports: funnelLevelReports,
            filter,
            reportLevel: "funnelsOverall"
        });

        // Update overall report in redux store
        dispatch(facebookActions.updateFullFunnelsOverallReport({
            [filter.value]: overallReport
        }));

        return funnelLevelReports
    }


    const getInsightsForChart = async (dateFilter = facebook.reportsFilter.value) => {
        let userId = userId

        if (isSpecialUser) {
            const params = new URLSearchParams(window.location.search)
            userId = params.get('user_in_view_id')
        }

        const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/getInsightsForChart?userId=${userId}&dateFilter=${dateFilter}`
        const insights = await sendRequest(url, 'GET', null, authHeader)

        dispatch(facebookActions.setInsightsForChart(insights))
    }


    const updateTimeSeriesChartData = async ({
        accessToken = facebook.auth.accessToken,
        adAccountId = facebook.selectedAdAccount.id,
        filter = facebook.reportsFilter,
        fullFunnelId = facebook.fullFunnelInViewId || "allFullFunnels",
        fullFunnel,
        objective
    }) => {

        if (!fullFunnel) {
            fullFunnel = facebook.fullFunnels.find(f => f._id === fullFunnelId)
        }
        if (!fullFunnel) return

        if (facebook.timeseriesChartData?.[fullFunnelId]?.[filter.value]?.objective === objective) {
            return facebook.timeseriesChartData[fullFunnelId][filter.value]
        }

        const getTimeIncrementValue = () => {
            if (filter.type === "time_range") {
                const { since, until } = JSON.parse(filter.value)
                const startDate = new Date(since)
                const endDate = new Date(until)
                const diffTime = Math.abs(endDate - startDate)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                return Math.ceil(diffDays / 7)
            } else if (filter.type === "date_preset") {
                switch (filter.value) {
                    case "last_7d": return 1
                    case "last_14d": return 2
                    case "last_30d": return 4
                    case "last_90d": return 13
                    case "maximum": return 30
                    case "today": return 1
                    case "yesterday": return 1
                    case "this_week_sun_today": return 1
                    case "this_month": return 4
                    case "last_month": return 4
                    case "this_year": return 15
                }
            }
        }

        let dateFilter = ''

        if (filter.type === "time_range") {
            dateFilter = `time_range=${filter.value}`
        } else if (filter.type === "date_preset") {
            dateFilter = `date_preset=${filter.value}`
        }

        const fields = "spend,reach,impressions,frequency,ctr,clicks,cpm,cpc,purchase_roas,actions,action_values,cost_per_action_type"

        const campaignIds = []

        if (fullFunnelId && fullFunnelId !== "allFullFunnels") {
            fullFunnel.adCampaigns?.forEach(adCampaign => {
                if ((adCampaign.campaign.objective === "OUTCOME_SALES" && objective === "Conversion Campaigns") || (adCampaign.campaign.objective !== "OUTCOME_SALES" && objective === "Boost Campaigns")) {
                    campaignIds.push(adCampaign.campaign.id)
                }
            })
        } else {
            facebook.fullFunnels?.forEach(fullFunnel => {
                fullFunnel.adCampaigns?.forEach(adCampaign => {
                    if ((adCampaign.campaign.objective === "OUTCOME_SALES" && objective === "Conversion Campaigns") || (adCampaign.campaign.objective !== "OUTCOME_SALES" && objective === "Boost Campaigns")) {
                        campaignIds.push(adCampaign.campaign.id)
                    }
                })
            })
        }


        const url = `${facebookGraphUrl}/${adAccountId}/insights?${dateFilter}&fields=${fields}&filtering=[{'field':'campaign.id','operator':'IN','value':[${campaignIds.join(",")}]}]&time_increment=${getTimeIncrementValue()}&access_token=${accessToken}`

        try {
            const responseForChartData = await sendRequest(url, 'GET', null, authHeader, true)

            const fullChartLabels = []
            const chartLabels = []

            const spendChartData = []

            const reachChartData = []
            const impressionsChartData = []
            const cpmChartData = []
            const frequencyChartData = []

            const ctrChartData = []
            const clicksChartData = []
            const cpcChartData = []

            const purchasesChartData = []
            const addToCartChartData = []
            const salesChartData = []

            const roasChartData = []
            const costPerPurchaseChartData = []


            responseForChartData.data.forEach(dataPoint => {
                spendChartData.push(parseFloat(dataPoint.spend))
                reachChartData.push(parseInt(dataPoint.reach))
                impressionsChartData.push(parseInt(dataPoint.impressions))
                cpmChartData.push(parseFloat(dataPoint.cpm))
                frequencyChartData.push(parseFloat(dataPoint.frequency))

                ctrChartData.push(parseFloat(dataPoint.ctr))
                clicksChartData.push(parseInt(dataPoint.clicks))
                cpcChartData.push(parseFloat(dataPoint.cpc))

                const addToCart = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'add_to_cart') ? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'add_to_cart')?.value) : 0
                addToCartChartData.push(addToCart)

                const purchases = dataPoint.actions && helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase') ? parseInt(helper.findBy(dataPoint.actions, 'action_type', 'omni_purchase')?.value) : 0
                purchasesChartData.push(purchases)

                const sales = dataPoint.action_values && helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase') ? parseInt(helper.findBy(dataPoint.action_values, 'action_type', 'omni_purchase')?.value) : 0
                salesChartData.push(sales)

                const costPerPurchase = dataPoint.cost_per_action_type && helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase') ? parseFloat(helper.findBy(dataPoint.cost_per_action_type, 'action_type', 'omni_purchase')?.value) : 0
                costPerPurchaseChartData.push(costPerPurchase)

                const roas = dataPoint.purchase_roas && helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase') ? parseFloat(helper.findBy(dataPoint.purchase_roas, 'action_type', 'omni_purchase')?.value) : 0
                roasChartData.push(roas)

                chartLabels.push(dataPoint.date_start)
                fullChartLabels.push(`${dataPoint.date_start} - ${dataPoint.date_stop}`)

            })

            dispatch(facebookActions.updateAField({
                field: "timeseriesChartData",
                value: {
                    ...facebook.timeseriesChartData,
                    [fullFunnelId]: {
                        ...facebook.timeseriesChartData?.[fullFunnelId],
                        [filter.value]: {
                            objective,
                            fullLabels: fullChartLabels,
                            labels: chartLabels,

                            spend: spendChartData,

                            reach: reachChartData,
                            impressions: impressionsChartData,
                            cpm: cpmChartData,
                            frequency: frequencyChartData,

                            ctr: ctrChartData,
                            clicks: clicksChartData,
                            cpc: cpcChartData,

                            purchases: purchasesChartData,
                            addToCart: addToCartChartData,
                            sales: salesChartData,

                            roas: roasChartData,
                            costPerPurchase: costPerPurchaseChartData,
                        }
                    }
                }
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const newFilterSelectedForReport = async ({ filter, fullFunnel, accessToken, reportLevel = "campaign", isAllFunnels = false }) => {
        let level = ""
        if (reportLevel === "campaign") {
            level = "campaignLevel"
        } else if (reportLevel === "adset") {
            level = "adsetLevel"
        } else if (reportLevel === "funnels") {
            level = "funnelLevel"
        }

        if (fullFunnel && !fullFunnel.reports[level][filter.value]) {
            await fetchReportsForSingleFullFunnel({ filter, fullFunnelId: fullFunnel._id, accessToken, reportLevel })
        } else if (isAllFunnels) {
            dispatch(facebookActions.setFullFunnelReportsReady(false))
            await Promise.all(facebook.fullFunnels.map(async fullFunnel => {
                if (!fullFunnel.reports[level][filter.value]) {
                    await fetchReportsForSingleFullFunnel({ filter, fullFunnelId: fullFunnel._id, accessToken, reportLevel })
                }
            }))
            dispatch(facebookActions.setFullFunnelReportsReady(true))
        }

        dispatch(facebookActions.setReportsFilter(filter))
    }

    const fetchWithPagination = async ({ url, caching = false, readFromCache = true }) => {
        let edges = [];

        const next = async (nextUrl) => {
            try {
                const res = await sendRequest(nextUrl, "GET", null, {}, caching, readFromCache);
                if (res.error) {
                    throw { error: res.error };
                }

                edges = edges.concat(res.data);

                if (res.paging?.next) {
                    return next(res.paging.next); // Recursively call with the next URL
                } else {
                    return edges; // Return the edges when done
                }
            } catch (err) {
                handleError(err);
                throw err; // Re-throw the error after handling
            }
        };

        return await next(url); // Ensure the result of `next` is returned
    };

    const fetchStatusesForFullFunnels = async ({
        adAccountId = facebook.selectedAdAccount?.id,
        accessToken = facebook.auth?.accessToken,
        fullFunnels = facebook.fullFunnels,
    }) => {
        if (!adAccountId || !accessToken) return
        const campaignIds = []
        fullFunnels.forEach(async (fullFunnel) => {
            const adCampaigns = fullFunnel.adCampaigns
            adCampaigns.forEach(adCampaign => {
                campaignIds.push(adCampaign.campaign.id)
            })
        })

        if (campaignIds.length === 0) return

        const url = `${facebookGraphUrl}/${adAccountId}/campaigns?fields=effective_status&filtering=[{'field':'id','operator':'IN','value':[${campaignIds.join(",")}]}]&access_token=${accessToken}`

        try {
            const updatedStatusesRecently = sessionStorage.getItem("updatedStatusesRecently")
            const enableCaching = updatedStatusesRecently ? false : true
            sessionStorage.removeItem("updatedStatusesRecently")
            const response = await sendRequest(url, 'GET', null, authHeader, enableCaching)
            if (!response || !response.data) return

            // Create a map of campaign effective statuses for quick lookup
            const campaignStatusMap = {}
            response.data.forEach(campaign => {
                campaignStatusMap[campaign.id] = campaign.effective_status
            })

            // Create a map of full funnel IDs to their effective status
            const fullFunnelStatusUpdates = {}

            fullFunnels.forEach(fullFunnel => {
                let hasActiveEffectiveStatus = false

                // Check if any campaign in this funnel is active
                fullFunnel.adCampaigns.forEach(adCampaign => {
                    const campaignId = adCampaign.campaign.id
                    if (campaignStatusMap[campaignId] === 'ACTIVE') {
                        hasActiveEffectiveStatus = true
                    }
                })

                // Store the effective status update for this full funnel
                fullFunnelStatusUpdates[fullFunnel._id] = hasActiveEffectiveStatus ? 'ACTIVE' : 'INACTIVE'
            })
            // Update only the effective status of full funnels in the Redux store
            dispatch(facebookActions.updateFullFunnelEffectiveStatuses(fullFunnelStatusUpdates))

            return fullFunnelStatusUpdates
        } catch (err) {
            console.error('Error fetching campaign statuses:', err)
            return null
        }
    }

    const fetchAdAccountActivity = async ({
        adAccountId = facebook.selectedAdAccount?.id,
        accessToken = facebook.auth?.accessToken,
        nextUrl
    }) => {
        if (!adAccountId || !accessToken) return

        dispatch(facebookActions.startLoading({ field: "activities" }))
        try {
            const limit = 100
            const fields = "actor_id,actor_name,event_time,object_name,extra_data,application_name,event_type"
            
            const url = nextUrl ? nextUrl : `${facebookGraphUrl}/${adAccountId}/activities?fields=${fields}&limit=${limit}&access_token=${accessToken}`
            const response = await sendRequest(url, 'GET', null, authHeader, true)
            dispatch(facebookActions.updateAdAccountActivity(response))
            return response
        } catch (err) {
            console.error('Error fetching ad account activity:', err)
            return null
        } finally {
            dispatch(facebookActions.stopLoading({ field: "activities" }))
        }
    }

    return {
        getInsightsForChart,
        updateTimeSeriesChartData,
        newFilterSelectedForReport,
        fetchReportsForSingleFullFunnel,
        generateOverallReportForFullFunnelCampaign,
        calculateFunnelLevelReportForObjective,
        calculateFullFunnelsOverallReport,
        formatEffectiveStatus,
        fetchStatusesForFullFunnels,
        fetchAdAccountActivity
    }
}