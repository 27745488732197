import { createSlice } from '@reduxjs/toolkit'

const aiCreativesSlice = createSlice({
  name: 'aiCreative',
  initialState: {
    fullList: [],
    enhencedCatalogDetails: null,
    loading: true,
  },
  reducers: {
    setFullList(state, action) {
      state.fullList = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    addNewCreative(state, action) {
      state.fullList = [action.payload, ...state.fullList]
    },
    updateCreative(state, action) {
      state.fullList = state.fullList.map(creative =>
        creative.id === action.payload.id ? action.payload : creative,
      )
    },
    setEnhencedCatalogDetails(state, action) {
      state.enhencedCatalogDetails = action.payload
    },
    clearEnhencedCatalogDetails(state) {
      state.enhencedCatalogDetails = null
    },
  },
})

export const aiCreativesActions = aiCreativesSlice.actions

export default aiCreativesSlice.reducer
