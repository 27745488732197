import React from 'react'
import { FaCheck } from 'react-icons/fa'

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import useNavigator from '../../../shared/hooks/navigator-hook'
import Button from '../../../shared/components/FormElements/Button'

import styles from './PreviewActionButtons.module.css'

const PreviewActionButtons = ({ onSave, onTryOnAllProducts, type }) => {
  const {
    isSingleSaving,
    isEnhencedCatalogSaving,
    productOnDraft,
    areCatalogProductsFetched,
  } = useEnhencedCatalog()
  const { searchParams } = useNavigator()

  if (type === 'enhenced-catalog') {
    const isEditing = Boolean(searchParams.aiCreativeId)

    return (
      <div
        id="action-buttons-container"
        className={styles.actionButtonsContainer}
      >
        <Button
          onClick={onSave}
          primary={true}
          disabled={
            (!areCatalogProductsFetched && !isEditing) ||
            isEnhencedCatalogSaving
          }
          className={styles.savingButton}
        >
          {!areCatalogProductsFetched && !isEditing
            ? 'Fetching products...'
            : isEnhencedCatalogSaving
              ? 'Please wait...'
              : 'Generate ✨'}
        </Button>
      </div>
    )
  }

  return (
    <div
      id="action-buttons-container"
      className={styles.actionButtonsContainer}
    >
      {productOnDraft?.isSaved ? (
        <Button className={styles.savedButton} primary={true}>
          Saved
          <FaCheck className={styles.saveCheck} />
        </Button>
      ) : (
        <Button
          onClick={onSave}
          primary={true}
          disabled={isSingleSaving}
          className={
            isSingleSaving ? styles.savingButton : styles.saveAndContinueButton
          }
        >
          {isSingleSaving ? 'Saving...' : 'Save and Continue ✨'}
        </Button>
      )}
    </div>
  )
}

export default PreviewActionButtons
