import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import circleCheck from '../../shared/assets/images/icons/circle-check.svg'
import circleCheckFilled from '../../shared/assets/images/icons/circle-check-filled.svg'
import { useConstants } from '../../shared/hooks/constants-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import './SelectCategory.css'
import ProfilePageLayout from '../components/ProfilePageLayout'
import Button from '../../shared/components/FormElements/Button'
import { useNavigate } from 'react-router-dom'
import { useShopify } from '../../shared/hooks/shopify-hook'
import errorHandler from '../../shared/util/errorHandler'
import IntroHeadlineContainer from '../components/intro/IntroHeadlineContainer'

const SelectApparelCategory = ({ inAccountSettings }) => {
  const { publicConstants } = useConstants()
  const [saving, setSaving] = useState(false)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sendRequest } = useHttpClient()
  const { connectShopifyStore } = useShopify()
  const user = auth.user
  const [selectedCategories, setSelectedCategories] = useState(
    user.userSelectedEnhencerCategories || [],
  )
  const initialCategories = user.userSelectedEnhencerCategories || []
  const [isDirty, setIsDirty] = useState(false)
  const [loading, setLoading] = useState(true)


  useEffect(() => {
    if (!inAccountSettings) {
      dispatch(
        authActions.updateUserObjManyFields({
          isInSignupFlow: true,
        }),
      )
    }

    // Simulate loading delay for demonstration purposes
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])
  useEffect(() => {
    if(!inAccountSettings){
      handleAppClass(true)

    }


    return () => {
      handleAppClass(false)
    }
  }, [])

  const handleAppClass = t => {
    return t
      ? document
          .getElementById('app')
          ?.classList.add('full-screen-noscroll-padding')
      : document
          .getElementById('app')
          ?.classList.remove('full-screen-noscroll-padding')
  }


  useEffect(() => {
    if (initialCategories.length != selectedCategories.length) {
      setIsDirty(true)
      return
    }
    var sortedinitialCategories = initialCategories.slice().sort()
    var sortedselectedCategories = selectedCategories.slice().sort()
    const isChanged = sortedinitialCategories.every(
      (value, index) => value === sortedselectedCategories[index],
    )
    setIsDirty(!isChanged)
  }, [selectedCategories])

  const categorySelected = itemName => {
    const newSelectedCategories = [...selectedCategories]

    if (newSelectedCategories.includes(itemName)) {
      newSelectedCategories.splice(newSelectedCategories.indexOf(itemName), 1)
    } else {
      newSelectedCategories.push(itemName)
    }

    setSelectedCategories(newSelectedCategories)

    publicConstants.apparelAccessoriesEnhencerCategories.forEach(item => {
      if (item.name === itemName) {
        item.selected = !item.selected
      }
    })
  }
  const headlines = [
    {
      text: 'Apparel? ',
      highlight: 'What kind?',
    },
  ]

  const goBackToMain = itemName => {
    console.log("goBackToMain")
    navigate((inAccountSettings ? '/account' : '') + '/industry-selection')
  }

  const saveCategories = async () => {
    try {
      setSaving(true)
      const enhencerCategories = selectedCategories

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${inAccountSettings ? 'updateEnhencerCategories' : 'initializeEnhencerCategories'}`,
        'POST',
        JSON.stringify({
          userId: user.id,
          enhencerCategories: enhencerCategories,
        }),
        {
          authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        authActions.updateUserObj({
          ...auth.user,
          userSelectedEnhencerCategories: enhencerCategories,
          isInSignupFlow: inAccountSettings ? false : true,
        }),
      )

      toast.success('Selected categories saved!')
      if (!inAccountSettings && auth.user.shopify) {
        await connectShopifyStore() // TODO: is is the correct place to do this?
      }
      if (!inAccountSettings) navigate('/your-team')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const pageContent = (
    <div className="select-category-container">
      <div className="header-row">
        <div className="buttons-row row">
          <Button className="save-button" onClick={goBackToMain}>
            Back to Main Categories
          </Button>
          {isDirty && (
            <Button
              className="save-button"
              disabled={saving}
              onClick={saveCategories}
            >
              {saving
                ? 'Saving'
                : inAccountSettings
                  ? 'Save'
                  : 'Save and Continue'}
            </Button>
          )}
        </div>
      </div>
      <div className="category-part">
        <div className="category-part-grid">
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <div className="category-row-item-wrapper" key={index}>
                  <Skeleton height={250} />
                </div>
              ))
            : publicConstants.apparelAccessoriesEnhencerCategories.map(
                (item, index) => (
                  <div
                    className={`category-row-item-wrapper ${
                      selectedCategories.includes(item.name) ? 'selected' : ''
                    }`}
                    key={index}
                  >
                    <div
                      className={`category-part-row-item ${
                        selectedCategories.includes(item.name) ? 'selected' : ''
                      }`}
                      onClick={() => categorySelected(item.name)}
                    >
                      <div className="category-image-overlay"></div>
                      <img
                        className="category-image apparal-category-image"
                        src={item.imageUrl}
                        alt={item.name}
                      />

                      <div className="category-name-overlay">
                        <img className="icon" src={item.iconUrl}></img>
                        {item.name}
                      </div>
                    </div>
                  </div>
                ),
              )}
        </div>
      </div>
    </div>
  )

  return !inAccountSettings ? (
    <>
      <div className="flow-select-category-container ">
        <div className="select-category-desc">
          <IntroHeadlineContainer
            headlines={headlines}
            animationEnabled={false}
          />
        </div>
        {pageContent}
      </div>
    </>
  ) : (
    <ProfilePageLayout>
      <ProfilePageLayout.Header></ProfilePageLayout.Header>
      <ProfilePageLayout.Content>{pageContent}</ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

export default SelectApparelCategory
