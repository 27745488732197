import { useSelector } from "react-redux"
import { useHttpClient } from "./http-hook"
import { useEffect, useState } from "react"

const useAudienceNetworkLibrary = () => {

    const { sendRequest } = useHttpClient()
    const authToken = useSelector(state => state.auth.token)
    const [library, setLibrary] = useState(null)
    const userCountry = useSelector(state => state.admin?.customer?.country)

    useEffect(() => {
        getAudienceNetworkLibrary()
    }, [])


    const getAudienceNetworkLibrary = async () => {
        if (localStorage.getItem("audienceNetworkLibrary")) {
            const result = JSON.parse(localStorage.getItem("audienceNetworkLibrary"))
            if (result.expires > Date.now()) {
                setLibrary(result.library)
                return
            }
        }

        try {
            const result = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/admin/getAudienceNetworkLibrary`,
                'GET',
                null,
                { Authorization: 'Bearer ' + authToken },
            )

            localStorage.setItem("audienceNetworkLibrary", JSON.stringify(result))
            setLibrary(result.library)
            return
        } catch (err) {
            throw err
        }
    }

    const findAudienceInLibrary = (audienceId) => {
        // Check base audiences
        const baseAudience = library.find(audience => {
            if (audience.id === audienceId) {
                console.log("base audience", audience.id === audienceId)
                return true;
            }
            return false;
        });

        if (baseAudience) {
            return {
                name: baseAudience.name,
                description: baseAudience.description,
                percentage: 0,
                id: baseAudience.id,
                baseAudienceId: baseAudience.id
            };
        }

        // Check LAL 1%
        for (const audience of library) {
            const lal1 = audience.lookalikes;
            const countries_lal1 = Object.keys(lal1);

            for (const country of countries_lal1) {
                if (lal1[country].id === audienceId) {
                    console.log("lal1 country", lal1[country].id === audienceId)
                    return {
                        ...lal1[country],
                        name: audience.name,
                        description: audience.description,
                        percentage: 1,
                        country,
                        baseAudienceId: audience.id
                    };
                }
            }

            // Check LAL 10%
            const lal10 = audience.lookalikes_10;
            const countries_lal10 = Object.keys(lal10);

            for (const country of countries_lal10) {
                if (lal10[country].id === audienceId) {
                    console.log("lal10 country", lal10[country].id === audienceId)
                    return {
                        ...lal10[country],
                        name: audience.name,
                        description: audience.description,
                        percentage: 10,
                        country,
                        baseAudienceId: audience.id
                    };
                }
            }
        }

        return null;
    }

    const findOriginalAudience = (audienceId, percentage) => {
        // Check base audiences
        console.log("searching for base audience", audienceId)
        const baseAudience = library.find(audience => {
            if (audience.id === audienceId) {
                console.log("found base audience", audience.id)
                return true
            }
            return false;
        });
        
        if (percentage === 0) {
            return baseAudience
        }

        if (percentage === 1) {
            return baseAudience.lookalikes[userCountry]
        }
        
        if (percentage === 10) {
            return baseAudience.lookalikes_10[userCountry]
        }

        return null;
    }

    return {
        audienceNetworkLibrary: library,
        libraryLoaded: library !== null,
        findAudienceInLibrary,
        findOriginalAudience
    }



}


export default useAudienceNetworkLibrary