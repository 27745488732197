import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import { FaTimes } from 'react-icons/fa'
import './CancelSubscription.css'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector, useDispatch } from 'react-redux'
import EscapeIcon from '../../assets/images/icons/escape.svg'
import { useHttpClient } from '../../hooks/http-hook'
import { toast } from 'react-toastify'
import { authActions } from '../../../store/auth'
import Modals from '../../enums/Modals'
import errorHandler from '../../util/errorHandler'

const CancelSubscription = props => {
  const { closeModal } = useModal()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const user = auth.user
  const [showDetailsInput, setShowDetailsInput] = useState(false)
  const [cancellationReason, setCancellationReason] = useState('')
  const { sendRequest } = useHttpClient()

  const close = () => {
    closeModal()
    navigate('/account/subscription')
  }

  const handleOptionChange = event => {
    if (event.target.value === 'Other') {
      setShowDetailsInput(true)
    } else {
      setShowDetailsInput(false)
      setCancellationReason(event.target.value)
    }
  }

  const handleDetailsInputChange = event => {
    setCancellationReason(event.target.value)
  }

  const handleCancelSubscription = () => {
    const headers = {
      Authorization: 'Bearer ' + auth.token,
      'Content-Type': 'application/json',
    }

    let promises = [
      sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/cancelSubscription`,
        'POST',
        JSON.stringify({ userId: user.id, reason: cancellationReason}),
        headers,
      ),
    ]

    if (user.shopify) {
      let params = {
        chargeID: user.subscription.chargeID,
        accessToken: user.shopify.accessToken,
        shopDomain: user.shopify.domain,
      }

      if (user.shopify.scriptId) {
        promises.push(
          sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/shopify/removeScript`,
            'POST',
            JSON.stringify({
              id: user.id,
              domain: user.shopify.domain,
              accessToken: user.shopify.accessToken,
              scriptId: user.shopify.scriptId || null,
            }),
            headers,
          ),
        )
      }

      if (user.subscription.chargeID && process.env.NODE_ENV === 'production') {
        promises.push(
          sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/shopify/cancelSubscription`,
            'POST',
            JSON.stringify(params),
            headers,
          ),
        )
      }
    } else {
      if (user.subscription.refCode && user.subscription.source === 'Stripe') {
        promises.push(
          sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/stripe/cancelSubscription`,
            'POST',
            JSON.stringify({
              subscriptionId: user.subscription.refCode,
            }),
            headers,
          ),
        )
      } else if (
        user.subscription.refCode &&
        user.subscription.source === 'Iyzico'
      ) {
        promises.push(
          sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/payment/cancelSubscription`,
            'POST',
            JSON.stringify({
              subscriptionReferenceCode: user.subscription.refCode,
            }),
            headers,
          ),
        )
      }
    }

    const modalConfig = {
      title: 'Your subscription has been canceled',
      text: '',
      imageUrl:
        'https://cdn.enhencer.com/admin-assets/images/icons/cancel-subs-icon.svg',
      actions: [
        {
          text: 'Close',
          callback: () => {
            closeModal()
          }
        },
      ],
    }

    Promise.all(promises)
      .then(result => {
        // setSubscribed(false)
        if (user.shopify) {
          dispatch(
            authActions.updateUserObj({
              ...user,
              isActive: false,
              shopify: {
                ...user.shopify,
                scriptId: null,
              },
              crmDetails: {
                ...auth.user.crmDetails,
                subscription: {
                  ...auth.user.crmDetails.subscription,
                  cancellationReason: cancellationReason,
                },
              },
            }),
          )
          modalConfig.text =
            'We are sorry to see you go. Your subscription has been canceled and your store has been disconnected from Enhencer. The audiences will not work properly.'
        } else {
          modalConfig.text =
            'We are sorry to see you go. Your subscription has been canceled successfully.'
          dispatch(
            authActions.updateUserObj({
              ...user,
              crmDetails: {
                ...auth.user.crmDetails,
                subscription: {
                  ...auth.user.crmDetails.subscription,
                  cancellationReason: cancellationReason,
                },
              },
            }),
          )
        }
        openModal({
          type: Modals.GENERIC_MODAL,
          data: modalConfig,
          replaceAll: true
        })

        dispatch(authActions.cancelSubscription())
      })
      .catch(err => {
        errorHandler(err)
        throw err
      })
  }

  return (
    <div className="cancel-subscription-modal">
      <div className="close-button" onClick={closeModal}>
        <FaTimes />
      </div>

      <h2>We’re sad to see you go</h2>
      <p className="small-text">Would you tell us why?</p>

      <div className="row">
        <div className="image">
          <img src={EscapeIcon} />
        </div>
        <div className="content">
          <div className="options">
            <div className="input-box">
              <input
                type="radio"
                id="option1"
                name="option"
                value="AI Ads are too expensive"
                onChange={handleOptionChange}
              />
              <label htmlFor="option1">AI Ads are too expensive</label>
            </div>

            <div className="input-box">
              <input
                type="radio"
                id="option2"
                name="option"
                value=" We do not have a business need for AI Ads"
                onChange={handleOptionChange}
              />
              <label htmlFor="option2">
                We do not have a business need for AI Ads{' '}
              </label>
            </div>

            <div className="input-box">
              <input
                type="radio"
                id="option3"
                name="option"
                value="  We need features that AI Ads does not have"
                onChange={handleOptionChange}
              />
              <label htmlFor="option3">
                We need features that AI Ads does not have
              </label>
            </div>

            <div className="input-box">
              <input
                type="radio"
                id="option4"
                name="option"
                value="Bug encounters"
                onChange={handleOptionChange}
              />
              <label htmlFor="option4">Bug encounters</label>
            </div>

            <div className="input-box">
              <input
                type="radio"
                id="option5"
                name="option"
                value="Other"
                onChange={handleOptionChange}
              />
              <label htmlFor="option5">Other</label>
            </div>
            {showDetailsInput && (
              <div className="input-box">
                <textarea
                  className="more-details"
                  placeholder="Write more details here"
                  onChange={handleDetailsInputChange}
                ></textarea>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="button-row">
        <Button onClick={handleCancelSubscription}>Continue</Button>
      </div>
    </div>
  )
}

export default CancelSubscription
