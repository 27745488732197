import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaLock, FaPaperPlane, FaPlus, FaSave } from 'react-icons/fa'
import moment from 'moment'
import Zoom from 'react-medium-image-zoom'
import { useNavigate } from 'react-router-dom'

import { useModal } from '../../hooks/modal-hook'
import { useHttpClient } from '../../hooks/http-hook'
import { adminActions } from '../../../store/admin'
import Button from '../FormElements/Button'

import 'react-medium-image-zoom/dist/styles.css'
import './TicketDetails.css'
import errorHandler from '../../util/errorHandler'

const TicketDetails = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { tickets } = useSelector(state => state.admin)
  const { sendRequest } = useHttpClient()
  const { modalData } = useModal()
  const { ticket: ticketData } = modalData

  const [ticket, setTicket] = useState(ticketData)
  const [isEditingNote, setIsEditingNote] = useState(false)
  const [isSavingNote, setIsSavingNote] = useState(false)
  const [note, setNote] = useState(ticketData.teamNotes || '')
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [loadingMessages, setLoadingMessages] = useState(true)
  const [isMessageInputOpen, setIsMessageInputOpen] = useState(false)
  const [isMessageSending, setIsMessageSending] = useState(false)
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'auto' })
  }

  const getUsername = user => {
    let domain = user.shopify
      ? user.shopify.name || user.shopify.domain
      : user.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : user.crmDetails?.contactName
  }

  const updateTicket = async (field, payload) => {
    const query = {
      [field]: payload,
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateSupportTicket/${ticket._id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      const newTicketData = {
        ...ticket,
        [field]: payload,
      }

      dispatch(
        adminActions.findTicketByIdAndUpdate({
          id: ticket._id,
          data: newTicketData,
        }),
      )

      setTicket(newTicketData)

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const addMessage = async () => {
    const body = message.trim()
    if (!body) return

    try {
      setIsMessageSending(true)

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/addSupportTicketMessage/${ticket._id}`,
        'POST',
        JSON.stringify({ receiverId: ticket.user._id, body }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findTicketByIdAndUpdate({
          id: ticket._id,
          data: {
            ...ticket,
            messages: response.data.messages,
          },
        }),
      )

      const messagesOfTicket = response.data.messages
      const addedMessage = messagesOfTicket[messagesOfTicket.length - 1]

      setMessages([...messages, addedMessage])
      setIsMessageInputOpen(false)
      setMessage('')

      toast.success('Message sent!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsMessageSending(false)
    }
  }

  const getMessages = async () => {
    try {
      setLoadingMessages(true)

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getSupportTicketMessages/${ticket._id}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      )

      setMessages(response.data)

      dispatch(
        adminActions.findTicketByIdAndUpdate({
          id: ticket._id,
          data: { ...ticket, messages: response.data },
        }),
      )
    } catch (err) {
      errorHandler(err)
    } finally {
      setLoadingMessages(false)
    }
  }

  const handleMessageInputChange = e => {
    const input = e.target.value
    setMessage(input)
  }

  const handlePriorityChange = async e => {
    await updateTicket('priority', e.target.value)
  }

  const handleStatusChange = async e => {
    await updateTicket('status', e.target.value)
  }

  const saveTeamNote = async () => {
    setIsSavingNote(true)
    await updateTicket('teamNotes', note)
    setIsEditingNote(false)
    setIsSavingNote(false)
  }

  const createUserAvatar = user => {
    if (!user.image) {
      const letter = user.email[0]
      return <div className="name-first-letter">{letter}</div>
    }

    return <img src={user.image} alt="Avatar" />
  }

  const createPerformanceManagerAvatar = imgUrl => {
    return <img src={imgUrl} alt="Avatar" />
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    if (loadingMessages) return

    const hasUnreadMessages = tickets.some(ticket =>
      ticket?.messages.some(
        message => message.receiver === auth.user._id && !message.read,
      ),
    )

    dispatch(adminActions.setHasUnreadMessages(hasUnreadMessages))

    if (!hasUnreadMessages) {
      // Remove the "unreadMessages=1" query parameter from the URL
      navigate('/admin-panel/support-tickets')
    }
  }, [loadingMessages])

  useEffect(() => {
    if (!ticket.teamNotes && !note) {
      setIsEditingNote(false)
      return
    }

    if (ticket.teamNotes !== note) {
      setIsEditingNote(true)
      return
    }

    setIsEditingNote(false)
  }, [note, ticket])

  return (
    <div className="ticket-details-modal">
      <div className="ticket-details-modal-header">
        <h1 className="title">
          {ticket.bugType === 'Other'
            ? ticket.otherBugTypeDescription
            : ticket.bugType}
        </h1>
        <p className="user-info">
          <div className="created-by">
            Created by <strong>{getUsername(ticket.user)}</strong> on{' '}
            <strong>{moment(ticket.createdAt).format('DD/MM/YYYY')}</strong>, at{' '}
            <strong>{moment(ticket.createdAt).format('HH:mm')}</strong>
          </div>
        </p>
        <div className="line" />
      </div>
      <div className="ticket-details-modal-content">
        <div className="chatbox">
          <div className="messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`message ${message.sender === auth.user._id ? 'sent' : 'received'}`}
              >
                <div className="avatar">
                  {message.sender === auth.user._id
                    ? createPerformanceManagerAvatar(
                        ticket.performanceManager.image,
                      )
                    : createUserAvatar(ticket.user)}
                </div>
                <div className="content">
                  <div className="text">{message.body}</div>
                  <div
                    title={moment(message.createdAt).format(
                      'dddd, MMMM DD, YYYY hh:mm:ss A',
                    )}
                    className="time"
                  >
                    {moment(message.createdAt).format('MMM D, h:mm A')}
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          {isMessageInputOpen ? (
            <div className="input">
              <textarea
                value={message}
                onChange={handleMessageInputChange}
                onKeyDown={e =>
                  e.key === 'Escape' && setIsMessageInputOpen(false)
                }
                placeholder="Type a message"
              />
              <Button
                disabled={!message.trim() || isMessageSending}
                onClick={addMessage}
              >
                <FaPaperPlane className="icon" />
                Send
              </Button>
            </div>
          ) : (
            <div className="add-message">
              <Button
                disabled={
                  ticket.status === 'completed' || ticket.status === 'ignored'
                }
                onClick={() => setIsMessageInputOpen(true)}
              >
                {ticket.status === 'completed' ||
                ticket.status === 'ignored' ? (
                  <>
                    <FaLock className="icon" />
                    Ticket Closed
                  </>
                ) : (
                  <>
                    <FaPlus className="icon" />
                    Add Message
                  </>
                )}
              </Button>
            </div>
          )}
        </div>
        <div className="information">
          <div className="details">
            <h2 className="header">Ticket Details</h2>
            <div className="section grouped">
              <div className="group">
                <div className="title">Ticket ID</div>
                <div className="value">{ticket._id}</div>
              </div>
              <div className="group">
                <div className="title">Date</div>
                <div
                  title={moment(ticket.createdAt).format(
                    'dddd, MMMM DD, YYYY hh:mm:ss A',
                  )}
                  className="value"
                >
                  {moment(ticket.createdAt).format('MMMM Do YYYY, HH:mm')}
                </div>
              </div>
            </div>
            <div className="section grouped">
              <div className="group">
                <div className="title">Status</div>
                <div
                  title="Click to update"
                  className={`value status ${ticket.status || 'pending'}`}
                >
                  <select
                    defaultValue={ticket.status || 'pending'}
                    onChange={handleStatusChange}
                  >
                    <option value="pending">Pending</option>
                    <option value="in-progress">In progress</option>
                    <option value="completed">Completed</option>
                    <option value="postponed">Postponed</option>
                    <option value="ignored">Ignored</option>
                  </select>
                  <span>
                    {ticket.status
                      ? ticket.status[0].toUpperCase() + ticket.status.slice(1)
                      : 'Pending'}
                  </span>
                </div>
              </div>
              <div className="group">
                <div className="title">Priority</div>
                <div
                  title="Click to update"
                  className={`value priority ${ticket.priority || 'normal'}`}
                >
                  <select
                    defaultValue={ticket.priority || 'normal'}
                    onChange={handlePriorityChange}
                  >
                    <option value="urgent">Urgent</option>
                    <option value="high">High</option>
                    <option value="normal">Normal</option>
                  </select>
                  <span>
                    {ticket.priority
                      ? ticket.priority[0].toUpperCase() +
                        ticket.priority.slice(1)
                      : 'Normal'}
                  </span>
                </div>
              </div>
            </div>
            {ticket.description && (
              <div className="section">
                <div className="title">Description</div>
                <div className="value description">{ticket.description}</div>
              </div>
            )}
            {ticket.image && (
              <div className="section">
                <div className="title">Attached Screenshot</div>
                <Zoom>
                  <div className="value">
                    <img
                      className="screenshot"
                      src={ticket.image}
                      alt="Screenshot"
                    />
                  </div>
                </Zoom>
              </div>
            )}
            <div className="section">
              <div className="title">Team Note</div>
              <div className="value team-note">
                <div className="note-input">
                  <textarea
                    spellCheck="false"
                    value={note}
                    onChange={e => setNote(e.target.value)}
                    placeholder="Type a note..."
                  ></textarea>
                  {isEditingNote && (
                    <Button
                      className="save-button"
                      loading={isSavingNote}
                      onClick={saveTeamNote}
                    >
                      <FaSave className="icon" />
                      Save
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="details">
            <h2 className="header">Assigned Marketing Expert</h2>
            <div className="section assigned-marketing-expert">
              <div className="avatar">
                <img src={ticket.performanceManager.image} alt="Avatar" />
              </div>
              <div className="texts">
                <span className="name">{ticket.performanceManager.name}</span>
                <span className="email">{ticket.performanceManager.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketDetails
