import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { FaCheckCircle, FaChevronRight, FaEdit } from 'react-icons/fa'

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import helper from '../../../shared/util/helper'

import styles from './SingleSavedOverlay.module.css'

const SingleSavedOverlay = () => {
  const dispatch = useDispatch()
  const {
    productOnDraft,
    draftConfiguration,
    selectedProductsWithConfigurations,
  } = useEnhencedCatalog()

  const previewOfProductOnDraft = useMemo(
    () =>
      helper.findBy(
        selectedProductsWithConfigurations,
        'id',
        productOnDraft?.id,
      )?.preview,
    [productOnDraft?.id],
  )

  const unconfiguredProduct = useMemo(
    () =>
      selectedProductsWithConfigurations.find(
        product => !product.configuration,
      ),
    [selectedProductsWithConfigurations],
  )

  const selectProduct = productId => {
    const product = helper.findBy(
      selectedProductsWithConfigurations,
      'id',
      productId,
    )

    dispatch(
      aiCreativeEditorActions.setDraftConfiguration({
        ...draftConfiguration,
        id: productId,
        initial: true,
        productName: product.name,
      }),
    )

    dispatch(aiCreativeEditorActions.setProductOnDraft(product))
  }

  const editProduct = productId => {
    selectProduct(productId)

    const productToEdit = helper.findBy(
      selectedProductsWithConfigurations,
      'id',
      productId,
    )

    const configuration = { initial: false, ...productToEdit.configuration }

    dispatch(
      aiCreativeEditorActions.setSelectedProducts(
        selectedProductsWithConfigurations.map(product => {
          if (product.id === productId) {
            return { ...product, isSaved: false }
          }
          return product
        }),
      ),
    )

    dispatch(
      aiCreativeEditorActions.setProductOnDraft({
        ...productToEdit,
        isSaved: false,
      }),
    )

    dispatch(aiCreativeEditorActions.setDraftConfiguration(configuration))
  }

  return (
    <div className={styles.savedOverlay}>
      <div className={styles.savedOverlayContent}>
        <div className={styles.oneProductOverlay}>
          <div className={styles.previewYourProductText}>
            <FaCheckCircle className={styles.previewYourProductIcon} />
            Product Image Saved!
          </div>
          <div className={styles.previewContainer}>
            {previewOfProductOnDraft ? (
              <img
                src={previewOfProductOnDraft.url}
                alt={productOnDraft.name}
              />
            ) : (
              <div className={styles.noPreview}>
                <p>
                  An error occurred while uploading the preview to Facebook.
                </p>
              </div>
            )}
          </div>
          <div className={styles.savedOverlayContentBottomPart}>
            <div
              className={`${styles.overlayButtons} ${styles.editThisProduct}`}
              onClick={() => {
                editProduct(productOnDraft.id)
              }}
            >
              Edit this product
              <FaEdit />
            </div>
            <div
              className={`${styles.overlayButtons} ${styles.nextProduct}`}
              onClick={() => {
                if (unconfiguredProduct) {
                  selectProduct(unconfiguredProduct.id)
                }
              }}
            >
              Next product
              <FaChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleSavedOverlay
