import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useHttpClient } from '../http-hook'
import { useDispatch, useSelector } from 'react-redux'
import { facebookActions } from '../../../store/facebook'
import errorHandler from '../../util/errorHandler'

export const useFacebookError = () => {

  const dispatch = useDispatch()
  const userId = useSelector(state => state.auth.user?.id)
  const authToken = useSelector(state => state.auth.token)
  const authHeader = {
    Authorization: "Bearer " + authToken,
    "Content-Type": "application/json",
  }
  const navigate = useNavigate()
  const { sendRequest } = useHttpClient()
  const [isErrorToastShown, setIsErrorToastShown] = useState(false)
  const handleError = (err) => {
    // Don't show toast for AbortError
    if (err.name === "AbortError" && err.message === "signal is aborted without reason") {
      return;
    }

    if (!err.code) {
      return errorHandler({message: err.message || 'Something went wrong'})
    }

    if (err.code !== 190 && err.code !== 200) {
      return errorHandler({message: 'Error connecting to Facebook, please try again later.'})
    }

    if (!isErrorToastShown) {
      errorHandler({message: 'Your session has expired, please log in again.'})
      setIsErrorToastShown(true)
    }

    forcedLogout()
    navigate('/config')
  }

  const forcedLogout = async () => {
    try {

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/logout`,
        "POST",
        JSON.stringify({
          userId,
        }),
        authHeader,
      )

      //remove from local storage
      localStorage.removeItem('facebookAuth')

      dispatch(facebookActions.logOut())
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }

  }


  return { handleError }
}
