import React, { useState } from "react"
import { FaCheck, FaChevronDown, FaPlus, FaTimes, FaTrash } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Button from "../../shared/components/FormElements/Button"
import { useHttpClient } from "../../shared/hooks/http-hook"
import helper from "../../shared/util/helper"
import { projectActions } from "../../store/project"
import "./AudienceSettings.css"
import errorHandler from "../../shared/util/errorHandler"

const AudienceSettings = ({
    audience,
    isV3,
    eventName,
}) => {
    const { sendRequest } = useHttpClient()
    
    const initialFilterBundles = useSelector(state => state.project?.currentCampaign?.filterBundles)
    const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser)
    const authToken = useSelector(state => state.auth.token)
    const authUserId = useSelector(state => state.auth.user.id)
    const isDemoUser = useSelector(state => state.auth.user.isDemo)
    const campaign = useSelector(state => state.project.currentCampaign)
    const featureList = useSelector(state => state.project.audienceModel?.featureList)

    const query = new URLSearchParams(window.location.search);
    const userInViewId = query.get('user_in_view_id');
    
    
    const [filterBundles, setFilterBundles] = useState(initialFilterBundles || [])
    const [listingCategories, setListingCategories] = useState([])
    const [productCategories, setProductCategories] = useState([])
    const [isCreatingNewBundle, setIsCreatingNewBundle] = useState(false)
    const [bundleName, setBundleName] = useState(filterBundles[0]?.name || "")
    const [nameError, setNameError] = useState("")
    


    const generateCategoriesForBundles = () => {
        const selectedCats = filterBundles ?? []

        let categories = [...featureList];
        categories = categories.filter((category) => {
            let table = category.tableName || category.tableType
            return (
                (table === "Product" &&
                    category.aggregationType !== "Distinct Counts") ||
                table.includes("Listing")
            );
        });
        selectedCats.forEach((selectedCategory) => {
            const foundElement = categories.find(function (category) {
                return (
                    selectedCategory["type"] === category.tableType &&
                    selectedCategory["name"] === category.name
                );
            });
            if (foundElement) {
                foundElement.selected = true;
            }
        });
        categories = categories.map(({ tableType, name, selected }) => ({
            tableType,
            name,
            selected,
        }));
        return categories;
    };


    const dispatch = useDispatch()


    const toggleCategory = (categoryType, categoryIndex) => {
        if (categoryType.includes("Listing")) {
            let newListingCats = [...listingCategories]
            newListingCats[categoryIndex].isSelected = !newListingCats[categoryIndex].isSelected
            setListingCategories(newListingCats)
        } else if (categoryType.includes("Product")) {
            let newProductCats = [...productCategories]
            newProductCats[categoryIndex].isSelected = !newProductCats[categoryIndex].isSelected
            setProductCategories(newProductCats)

        }
    }

    const createNewBundle = () => {
        setIsCreatingNewBundle(true)
        let newBundles = [...filterBundles.map(i => { return { ...i, isOpen: false } })]
        let length = newBundles.push({
            name: "",
            isOpen: true,
            selectedCategories: []
        })

        openBundle(length - 1, newBundles)
    }

    const openBundle = (bundleIndex, newlyCreatedBundles) => {

        let newBundles = newlyCreatedBundles ? newlyCreatedBundles : [...filterBundles.map(i => { return { ...i, isOpen: false } })]

        newBundles[bundleIndex].isOpen = true
        let listingCats = []
        let productCats = []

        let allCategories = audience?.categories ?? generateCategoriesForBundles()

        allCategories.forEach((category, categoryIndex) => {

            if (category.tableType.includes("Listing")) {
                listingCats.push({ name: category.name, isSelected: newBundles[bundleIndex].selectedCategories.filter(c => c.type.includes("Listing")).map(o => o.name).includes(category.name) })

            } else if (category.tableType.includes("Product")) {
                productCats.push({ name: category.name, isSelected: newBundles[bundleIndex].selectedCategories.filter(c => c.type.includes("Product")).map(o => o.name).includes(category.name) })

            }
        })

        setFilterBundles(newBundles)
        setListingCategories(listingCats)
        setProductCategories(productCats)

        setBundleName(newBundles[bundleIndex].name)
        // }
    }

    const closeBundle = () => {
        if (isCreatingNewBundle) {
            let newFilterBundles = [...filterBundles]
            newFilterBundles.pop()
            setIsCreatingNewBundle(false)
            setFilterBundles(newFilterBundles)
        } else {
            setFilterBundles([...filterBundles.map(i => { return { ...i, isOpen: false } })])
        }
    }

    const saveBundle = async (bundleIndex) => {
        if (bundleName !== "") {
            setIsCreatingNewBundle(false)
            setNameError("")
            let newBundles = [...filterBundles.map(i => { return { ...i, isOpen: false } })]
            newBundles[bundleIndex].name = bundleName !== "" ? bundleName : newBundles[bundleIndex].name
            newBundles[bundleIndex].selectedCategories =
                listingCategories.filter(c => c.isSelected).map(c => {
                    return { type: "Listing", name: c.name }
                }).concat(productCategories.filter(c => c.isSelected).map(c => {
                    return { type: "Product", name: c.name }
                }))

            try {
                let body = {
                    userId: userInViewId
                }
                let url = `${process.env.REACT_APP_BACKEND_URL}/admin/updateCampaignFilterBundles`

                if (isV3) {
                    body.eventName = eventName;
                    url = `${process.env.REACT_APP_BACKEND_URL}/admin/audience-model/updateAudienceEventFilterBundles`
                } else {
                    body.campaignId = campaign._id;
                }


                body.bundles = newBundles.map(b => {
                    return {
                        name: b.name,
                        selectedCategories: b.selectedCategories
                    }
                })

                await sendRequest(
                    url,
                    "POST",
                    JSON.stringify(body),
                    {
                        Authorization: "Bearer " + authToken,
                        "Content-Type": "application/json",
                    }
                );

                toast.success("Changes saved.")

                if (isV3) {
                    dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { filterBundles: newBundles } }))
                } else {
                    dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { filterBundles: newBundles } }))
                }

                setFilterBundles(newBundles)
            } catch (err) {
                errorHandler(err)
            }
        } else {
            setNameError("Please set a name for the bundle")
        }

    }

    const deleteBundle = async (bundleIndex) => {
        let newBundles = [...filterBundles.map(i => { return { ...i, isOpen: false } })]
        newBundles.splice(bundleIndex, 1)

        try {

            let body = {
                campaignId: campaign._id,
            }
            let url = ''
            if (isSpecialUser) {
                body.bundles = newBundles
                body.userId = userInViewId
                url = `${process.env.REACT_APP_BACKEND_URL}/admin/updateCampaignFilterBundles`

            } else {
                body.userId = authUserId
                body.content = {
                    filterBundles: newBundles
                }
                url = `${process.env.REACT_APP_BACKEND_URL}/projects/updateCampaign`
            }
            await sendRequest(
                url,
                "POST",
                JSON.stringify(body),
                {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                }
            );
            toast.success("Changes saved.")
            dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { filterBundles: newBundles } }))
            setFilterBundles(newBundles)
        } catch (err) {
            errorHandler(err)
        }
    }

    return <div className="audience-settings">
        <div className="filter-bundles">
            <h4>Filter bundles</h4>
            <div>
                {filterBundles.map((bundle, bundleIndex) =>
                    <div key={bundle.name} className="bundle-container">
                        <div className="header">
                            <div className="row">
                                {bundle.name === "" ?
                                    <div className="bundle-name">New Bundle</div>
                                    :
                                    <div className="bundle-name">{bundle.name}</div>}
                                <div className="actions">
                                    {bundle.isOpen ?
                                        <React.Fragment>
                                            {!isCreatingNewBundle && <Button className="button--danger" onClick={() => { deleteBundle(bundleIndex) }}>
                                                <FaTrash title="Delete bundle" className="icon" />
                                                Delete
                                            </Button>

                                            }

                                            <Button className="" onClick={closeBundle}>
                                                <FaTimes title="Close and discard changes" className="icon" />
                                                Cancel
                                            </Button>

                                            <Button className="button--success" onClick={() => { saveBundle(bundleIndex) }}>
                                                <FaCheck title="Save changes" className="icon" />
                                                Save
                                            </Button>
                                        </React.Fragment>
                                        :
                                        <Button onClick={() => { openBundle(bundleIndex) }}>
                                            <FaChevronDown title="Open details" className="icon" />
                                            Details
                                        </Button>

                                    }

                                </div>
                            </div>
                        </div>
                        <div className={`content ${bundle.isOpen ? 'open' : ''}`}>
                            <div className="row">
                                <label>Bundle Name</label>
                                <input type="text" placeholder="Enter a name" value={bundleName} onChange={(e) => { setBundleName(e.target.value) }} required />
                            </div>
                            <span className="error-text">{nameError}</span>
                            <h4 className="attribute-name">Listing Categories
                                {listingCategories.filter(c => c.isSelected).length > 0 ?
                                    <span className="helper">
                                        &nbsp; ({listingCategories.filter(c => c.isSelected).length} selected)
                                    </span> : null
                                }
                            </h4>

                            <div className="categories-grid">
                                {
                                    listingCategories.map((category, categoryIndex) =>
                                        <div key={categoryIndex}
                                            className={category.isSelected ? "category-box selected" : "category-box"}
                                            onClick={() => { toggleCategory("Listing", categoryIndex) }}>
                                            {category.name}
                                        </div>
                                    )
                                }
                            </div>

                            <h4 className="attribute-name">Product Categories
                                {productCategories.filter(c => c.isSelected).length > 0 ?
                                    <span className="helper">
                                        &nbsp; ({productCategories.filter(c => c.isSelected).length} selected)
                                    </span> : null
                                }
                            </h4>

                            <div className="categories-grid">
                                {
                                    productCategories.map((category, categoryIndex) =>
                                        <div key={categoryIndex}
                                            className={category.isSelected ? "category-box selected" : "category-box"}
                                            onClick={() => { toggleCategory("Product", categoryIndex) }}>
                                            {category.name}
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}

                <div style={{ marginLeft: "1.5em" }}>
                    {filterBundles.length === 0 && <p>You don't have any filter bundles</p>}
                    {!isCreatingNewBundle && <Button onClick={createNewBundle} className="button--text">
                        <FaPlus className="icon" />
                        New Bundle
                    </Button>}
                </div>
            </div>
        </div>
    </div>
}

export default AudienceSettings;