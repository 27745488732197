import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import FluidCard from '../../../shared/components/UIElements/FluidCard'
import SegmentList from '../../../project/components/SegmentList'
import SegmentDetail from '../../../project/components/SegmentDetail'

import './EnhencerRemarketingAudience.css'

const EnhencerRemarketingAudience = ({ stats }) => {
  const campaign = useSelector(state => state.project.currentCampaign)
  const [segmentDetailIndex, setSegmentDetailIndex] = useState(0)

  const openSegmentDetail = index => {
    setSegmentDetailIndex(index)
  }

  const onToggleSegment = index => {
    setSegmentDetailIndex(index)
  }

  return (
    <FluidCard className="enhencer-remarketing-audience">
      <FluidCard.Header>
        Top {stats.topVisitorsPercentage}% of Visitors - Enhencer Remarketing
        Audience
      </FluidCard.Header>
      <FluidCard.Content>
        <div className="row segmentation-row">
          {campaign.audience.segments?.length > 0 && (
            <SegmentList
              openSegmentDetail={openSegmentDetail}
              segmentDetailIndex={segmentDetailIndex}
              segments={campaign.audience.segments}
              onToggleSegment={onToggleSegment}
              overallPropensity={campaign.audience.overall?.propensity}
            />
          )}
          <div className="result-card" style={{ flex: '1 1 50%' }}>
            {campaign.audience.segments?.length > 0 && (
              <SegmentDetail
                segment={campaign.audience.segments[segmentDetailIndex]}
                segmentNo={segmentDetailIndex + 1}
                numberOfSegments={campaign.audience.segments.length}
              />
            )}
          </div>
        </div>
      </FluidCard.Content>
    </FluidCard>
  )
}

export default EnhencerRemarketingAudience