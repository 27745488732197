import React, { useEffect, useState } from 'react'
import ChecklistCard from '../../shared/components/UIElements/ChecklistCard'
import Button from '../../shared/components/FormElements/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFacebook } from '../../shared/hooks/facebook-hook'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'

import {
  FaCheckCircle,
} from 'react-icons/fa'

const ConnectSocialAccounts = ({ stepCompletions }) => {
  const dispatch = useDispatch()
  const {
    setActiveFacebookPage,
    setActiveInstagramAccount,
    getFacebookPages,
    getInstagramAccountsForPage
  } = useFacebook()

  const auth = useSelector(state => state.auth)
  const facebook = useSelector(state => state.facebook)
  const user = auth.user
  const facebookUserId = facebook.auth?.id

  const instagramAccounts = useSelector(state => state.facebook.instagramAccounts)
  const facebookPages = useSelector(state => state.facebook.pages)

  const [selectedFacebookPage, setSelectedFacebookPage] = useState(null)
  const [selectedInstagramAccount, setSelectedInstagramAccount] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const instagramAccountsOfSelectedFacebookPage = instagramAccounts[selectedFacebookPage?.id]

  useEffect(() => {
    const fetchPages = async () => {
      if (facebookUserId && (!facebookPages || facebookPages.length === 0)) {
        setIsLoading(true)
        try {
          await getFacebookPages({
            facebookUserId,
            getFromCacheIfAvailable: true
          })

          if (facebook.selectedActiveFacebookPage) {
            await getInstagramAccountsForPage({
              pageId: facebook.selectedActiveFacebookPage.id,
              pageAccessToken: facebook.selectedActiveFacebookPage.access_token,
              getFromCacheIfAvailable: true
            })
          }
        } catch (error) {
          console.error('error while fetching facebook pages', error)
        }
        setIsLoading(false)
      }
    }

    fetchPages()

    setSelectedFacebookPage(facebook.selectedActiveFacebookPage)
    setSelectedInstagramAccount(facebook.selectedActiveInstagramAccount)
  }, [facebookUserId, facebook.selectedActiveFacebookPage, facebook.selectedActiveInstagramAccount])

  const handleFacebookPageSelect = (page) => {
    setSelectedFacebookPage(page)
    setSelectedInstagramAccount(null)
    setIsLoading(true)

    try {
      dispatch(setActiveFacebookPage({
        facebookPage: page
      }))

      stepCompletions.selectedActiveFacebookPage = page
    } catch (error) {
      console.error('error while selecting instagram accounts of selected facebook page', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInstagramAccountSelect = (account) => {
    if (!selectedFacebookPage) {
      return
    }

    setSelectedInstagramAccount(account)

    dispatch(setActiveInstagramAccount({
      instagramAccount: account,
      forcedByFacebookPageSelection: false,
      facebookPage: selectedFacebookPage
    }))

    stepCompletions.selectedActiveInstagramAccount = account
  }

  const clearFacebookPageSelection = () => {
    setSelectedFacebookPage(null)
    setSelectedInstagramAccount(null)
    //clear the redux part

    dispatch(setActiveFacebookPage({
      facebookPage: null
    }))

    dispatch(setActiveInstagramAccount({
      instagramAccount: null,
      forcedByFacebookPageSelection: false,
      facebookPage: null
    }))

    stepCompletions.selectedActiveFacebookPage = null
    stepCompletions.selectedActiveInstagramAccount = null
  }

  const clearInstagramAccountSelection = () => {
    setSelectedInstagramAccount(null)
    dispatch(setActiveInstagramAccount({
      instagramAccount: null,
      forcedByFacebookPageSelection: false,
      facebookPage: null
    }))
    stepCompletions.selectedActiveInstagramAccount = null
  }


  return (
    <React.Fragment>
      <ChecklistCard
        number={9}
        name="Select Active Facebook Page (optional)"
        completed={stepCompletions.selectedActiveFacebookPage}
        clearSelection={selectedFacebookPage ? clearFacebookPageSelection : null}
      >
        <div className="ad-account-select">
          {isLoading ? (
            <InlineLoadingSpinner />
          ) : facebookPages?.length > 0 ? (
            <ul className="checklist-list">
              {facebookPages.map(page => (
                <li
                  key={page.id}
                  onClick={() => handleFacebookPageSelect(page)}
                  className={page.id === selectedFacebookPage?.id ? 'selected' : ''}
                >
                  <div className="row">
                    <div className={`dot green`}></div>
                    <div>
                      <div className="name">{page.name}</div>
                      <div className="id">Page ID: {page.id}</div>
                    </div>
                  </div>
                  <FaCheckCircle className="icon" />
                </li>
              ))}
            </ul>
          ) : (
            <div className="no-account-text">
              No facebook account could be found.
            </div>
          )}
        </div>
      </ChecklistCard>
      {
        selectedFacebookPage && instagramAccountsOfSelectedFacebookPage && (
          <ChecklistCard
            number={10}
            name="Select Active Instagram Account (optional)"
            completed={stepCompletions.selectedActiveInstagramAccount}
            clearSelection={selectedInstagramAccount ? clearInstagramAccountSelection : null}
          >
            <div className="ad-account-select">
              {instagramAccountsOfSelectedFacebookPage && Object.keys(instagramAccountsOfSelectedFacebookPage).length > 0 ? (
                <ul className="checklist-list">
                  {Object.values(instagramAccountsOfSelectedFacebookPage).map(account => (
                    <li
                      key={account.id}
                      onClick={() => handleInstagramAccountSelect(account)}
                      className={account.id === selectedInstagramAccount?.id ? 'selected' : ''}
                    >
                      <div className="row">
                        <div className={`dot green`}></div>
                        <div>
                          <div className="name">{account.username}</div>
                          <div className="id">Account ID: {account.id}</div>
                        </div>
                      </div>
                      <FaCheckCircle className="icon" />
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="no-account-text">
                  No Instagram account could be found.
                </div>
              )}
            </div>
          </ChecklistCard>

        )
      }

    </React.Fragment>
  )
}

export default ConnectSocialAccounts
