import React, { useEffect, useRef, useState } from 'react'
import { FaCheck } from 'react-icons/fa'

import HoverCard from './HoverCard'

import './CustomDropdown.css'

const CustomDropdown = ({
  options,
  onChange,
  onHover,
  initialValue,
  forcedValue,
  placeholder = 'Select...',
  apply,
  isSingleLine
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(initialValue || {})
  const [filteredOptions, setFilteredOptions] = useState(options || [])
  const optionsRef = useRef(null)
  const dropdownRef = useRef(null)
  const inputRef = useRef(null)

  const handleOpen = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }

  const handleSelect = option => {
    if (option.id === selectedOption.id) {
      return
    }

    setSelectedOption(option)
    onChange(option)
    setIsOpen(false)

    if (apply) apply(option)
  }

  /* TODO: double check this part
  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption]) */


  useEffect(() => {
    if (
      !initialValue ||
      selectedOption.name ||
      selectedOption.username ||
      selectedOption.id ||
      selectedOption.profile_pic
    ) {
      return
    }
    setSelectedOption(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (!forcedValue) return

    setSelectedOption(forcedValue)
  }, [forcedValue])

  useEffect(() => {
    inputRef.current.value = ""
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    if (!optionsRef || !dropdownRef) {
      return
    }

    const handleOutsideClick = e => {
      const outsideOptions = !optionsRef.current.contains(e.target)
      const outsideDropdown = !dropdownRef.current.contains(e.target)

      if (outsideDropdown && outsideOptions) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [optionsRef, dropdownRef])

  const Option = ({ option }) => {
    return (
      <div
        onClick={() => {
          if (!option.isDisabled) {
            handleSelect(option)
          }
        }}
        tabIndex={0}
        className={`option${selectedOption?.id === option.id ? ' selected' : ''}
        ${option.isDisabled ? ' disabled' : ''}`}
      >
        {option.profile_pic && (
          <div className="image-area">
            <img
              src={option.profile_pic}
              alt={option.name || option.username}
            />
          </div>
        )}
        <div className="text-box">
          <div className="title">{option.name || option.username}</div>
          <div className="description">ID: {option.id}</div>
        </div>
        <FaCheck className="check-icon" />
      </div>
    )
  }


  /* dont know why we need this,
  but it is problematic then the dropdown is closed and you want to edit other fields in the form
  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }, [inputRef.current]) */

  const onSearch = e => {
    
    const newOptions = options.filter(option =>
      option.name.toLowerCase().includes(e.target.value.toLowerCase()),
    )
    setFilteredOptions(newOptions)
  }

  return (
    <div className={`custom-dropdown${isOpen ? ' open' : ''}`}>
      {selectedOption ? <div tabIndex={0} className="dropdown" ref={dropdownRef} onClick={handleOpen}>
        {selectedOption.profile_pic && (
          <img src={selectedOption.profile_pic} alt="Profile" />
        )}
        <div className="text-box">
          <span className="name">
            {selectedOption.name || selectedOption.username || placeholder}
          </span>
          {!isSingleLine && selectedOption.id && (
            <span className="id">ID: {selectedOption.id || ''}</span>
          )}
        </div>
      </div>
        :
        <div tabIndex={0} className="dropdown" ref={dropdownRef} onClick={handleOpen}>
          <div className="text-box">
            <span className="name">
              {placeholder}
            </span>
          </div>
        </div>}
      <div ref={optionsRef} className="options">
        <input
          ref={inputRef}
          type="text"
          className="search-input"
          placeholder="Search"
          onChange={onSearch}
        />
        <div className='options-optionlist'>
          {onHover ? (
            <React.Fragment>
              {filteredOptions.map(o => (
                <HoverCard key={o.id}>
                  <HoverCard.Trigger>
                    <Option option={o} disabled={o.isDisabled} />
                  </HoverCard.Trigger>
                  <HoverCard.Content>
                    {onHover(o.id, o.product_count)}
                  </HoverCard.Content>
                </HoverCard>
              ))}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {filteredOptions.map(o => (
                <Option key={o.id} option={o} disabled={o.isDisabled} />
              ))}
            </React.Fragment>
          )}
        </div>

      </div>

    </div>
  )
}

export default CustomDropdown
