import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { FiEdit2, FiLink, FiLink2 } from 'react-icons/fi'
import { toast } from 'react-toastify'

import Button from '../../../shared/components/FormElements/Button'
import MetaIcon from '../../../shared/assets/images/icons/meta-logo.png'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import useNavigator from '../../../shared/hooks/navigator-hook'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import styles from './PreviousCreativeCard.module.css'
import errorHandler from '../../../shared/util/errorHandler'

const MAX_PRODUCTS_TO_SHOW_ON_ENHENCED_CATALOG_CARD = 5

const getEnhencedCatalogProductImage = (
  aiCreativeId,
  enhencedCatalogDetails,
  product,
  userId,
) => {
  if (!enhencedCatalogDetails || !product) {
    return null
  }

  return `${process.env.REACT_APP_AWS_BASE_URL}/ai-creative/${userId}/enhenced-catalog/${aiCreativeId}/${product.retailer_id || product.id}.jpeg`
}

const PreviousCreativeCard = ({
  id,
  name,
  createdAt,
  products,
  enhencedCatalogDetails,
}) => {
  const userId = useSelector(state => state.auth.user.id)
  const dispatch = useDispatch()
  const { navigateWithParams, searchParams } = useNavigator()
  const { createSupplementaryFeed, removeSupplementaryFeed, getFeedUploadStatus } = useEnhencedCatalog()
  const [isConnected, setIsConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [showActions, setShowActions] = useState(false)
  const actionsRef = useRef(null)
  const [activePreview, setActivePreview] = useState(
    getEnhencedCatalogProductImage(
      id,
      enhencedCatalogDetails,
      products?.[0],
      userId,
    ) ||
    products?.[0]?.preview?.perma_url ||
    products?.[0]?.preview?.url_256 ||
    '',
  )

  const handleCheckConnectionStatus = async ({ supplementaryFeedId = null } = {}) => {
    if (!supplementaryFeedId) {
      return
    }

    setIsConnecting(true)

    let isConnected = false

    isConnected = await new Promise(resolve => {
      const interval = setInterval(async () => {
        const status = await getFeedUploadStatus({
          feedId: supplementaryFeedId,
        });
        if (status.isUploaded) {
          clearInterval(interval);
          resolve(status.isUploaded);
        }
      }, 2000);
    });

    setIsConnecting(false)
    setIsConnected(isConnected)
  }

  const getFeedConnectionStatus = () => {
    if (!enhencedCatalogDetails) return null

    if (isConnecting) {
      return 'Supplementary Feed is being activated...'
    }

    return isConnected ? 'Supplementary Feed is active' : 'Supplementary Feed is not active'
  }

  const handleLaunchAd = (mode) => {
    if (enhencedCatalogDetails && !isConnected) {
      return
    }
    navigateWithParams(
      `/ai-ads/full-funnel-campaigns${searchParams.campaignId ? '' : '/create'}`,
      {
        aiCreativeId: id,
        ...(searchParams.campaignId && { campaignId: searchParams.campaignId }),
        ...(searchParams.edit && { edit: searchParams.edit }),
      },
    )

    if (mode === 'carousel') {
      dispatch(aiCreativeEditorActions.setSelectedProducts(products))
    }
  }

  const handleEdit = (mode) => {
    navigateWithParams('/ai-ads/creative-lab/editor', { aiCreativeId: id, mode })

    if (mode === 'carousel') {
      dispatch(aiCreativeEditorActions.setSelectedProducts(products))
      sessionStorage.setItem('currentAiCreativeId', id)
      sessionStorage.setItem('aiCreativeProducts', JSON.stringify(products))
    }
  }

  const handleEnableFeed = async () => {
    try {
      const newSupplementaryFeedId = await createSupplementaryFeed({
        catalogId: enhencedCatalogDetails.catalogId,
        primaryFeedId: enhencedCatalogDetails.primaryFeedId,
        creativeId: id,
      })

      await handleCheckConnectionStatus({
        supplementaryFeedId: newSupplementaryFeedId,
      })
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleDisableFeed = async () => {
    setIsDisconnecting(true)
    await removeSupplementaryFeed({
      creativeId: id,
      supplementaryFeedId: enhencedCatalogDetails.supplementaryFeedId,
    })

    setIsConnected(false)
    setIsDisconnecting(false)
  }

  useEffect(() => {
    if (
      !enhencedCatalogDetails ||
      enhencedCatalogDetails.status !== 'completed'
    )
      return

    if (enhencedCatalogDetails.supplementaryFeedId) {
      setIsConnected(true)
    } else {
      setIsConnected(false)
    }
  }, [enhencedCatalogDetails])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setShowActions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  if (enhencedCatalogDetails?.status === 'ready') {
    // console.log('enhencedCatalogDetails', enhencedCatalogDetails)
    return null
  }

  const isButtonDisabled = !isConnected || isConnecting || isDisconnecting

  return (
    <div className={styles.previousCreativeCard}>
      <div className={styles.creativeCardTop}>
        <div className={styles.creativeCardDetailsBox}>
          <div className={styles.creativeCardDetilsBoxTexts}>
            <div className={styles.creativeName}>
              {enhencedCatalogDetails && (
                <Tooltip content={getFeedConnectionStatus()} size="sm">
                  <div
                    className={`${styles.statusDot} ${
                      isConnecting
                        ? styles.statusChecking
                        : isConnected
                          ? styles.statusConnected
                          : styles.statusDisconnected
                    }`}
                  />
                </Tooltip>
              )}
              {name}
              <img className={styles.creativeIcon} src={MetaIcon} alt="Meta" />
            </div>
            <div className={styles.creativeDate}>
              Created at {moment(createdAt).format('DD/MM/YYYY')}
            </div>
            <div className={styles.creativeType}>
              Type: {enhencedCatalogDetails ? 'Enhenced Catalog' : 'Carousel'}
            </div>
          </div>
          <div className={styles.creativeCardDetailsBoxActions}>
            <Button
              primary
              onClick={handleLaunchAd}
              className={styles.launchAdButton}
              disabled={enhencedCatalogDetails && isButtonDisabled}
            >
              Launch Ad
            </Button>
            {enhencedCatalogDetails ? (
              <div ref={actionsRef} className={styles.actionsContainer}>
                <Button
                  disabled={isConnecting || isDisconnecting}
                  onClick={() => setShowActions(!showActions)}
                  className={styles.editButton}
                >
                  Actions
                </Button>
                <div
                  className={`${styles.actionsDropdown} ${showActions ? styles.show : ''}`}
                >
                  <button
                    className={styles.dropdownItem}
                    onClick={() => {
                      handleEdit('enhenced-catalog')
                      setShowActions(false)
                    }}
                  >
                    <FiEdit2 size={14} />
                    Edit Creative
                  </button>
                  {enhencedCatalogDetails && (
                    <button
                      className={styles.dropdownItem}
                      onClick={() => {
                        isConnected ? handleDisableFeed() : handleEnableFeed()
                        setShowActions(false)
                      }}
                    >
                      {isConnected ? (
                        <FiLink2 size={14} />
                      ) : (
                        <FiLink size={14} />
                      )}
                      {isConnected ? 'Disable Feed' : 'Enable Feed'}
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Button
                onClick={() => handleEdit('carousel')}
                className={styles.editButton}
              >
                Edit
              </Button>
            )}
          </div>
        </div>
        <div className={styles.preview}>
          <img
            className={styles.previewImage}
            src={activePreview}
            alt="Creative Preview"
          />
        </div>
      </div>
      <div className={styles.creativeCardDivider} />
      <div className={styles.previews}>
        {products.map(product => (
          <div
            className={styles.previewSmall}
            onMouseOver={() =>
              setActivePreview(
                getEnhencedCatalogProductImage(
                  id,
                  enhencedCatalogDetails,
                  product,
                  userId,
                ) ||
                  product?.preview?.perma_url ||
                  product?.preview?.url_256,
              )
            }
            onMouseLeave={() =>
              setActivePreview(
                getEnhencedCatalogProductImage(
                  id,
                  enhencedCatalogDetails,
                  products[0],
                  userId,
                ) ||
                  products[0]?.preview?.perma_url ||
                  products[0]?.preview?.url_256,
              )
            }
          >
            <img
              className={styles.previewImageSmall}
              src={
                getEnhencedCatalogProductImage(
                  id,
                  enhencedCatalogDetails,
                  product,
                  userId,
                ) ||
                product?.preview?.perma_url ||
                product?.preview?.url_256
              }
              alt="Creative Preview"
            />
          </div>
        ))}
        {enhencedCatalogDetails &&
          enhencedCatalogDetails.totalProducts >
            MAX_PRODUCTS_TO_SHOW_ON_ENHENCED_CATALOG_CARD && (
            <div className={styles.totalProducts}>
              +{enhencedCatalogDetails.totalProducts - products.length}
            </div>
          )}
      </div>
    </div>
  )
}

export default PreviousCreativeCard
