import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaSyncAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { useFacebook } from '../../../../../../shared/hooks/facebook-hook'
import { useFacebookCampaignEditor } from '../../../../../../shared/hooks/facebook/campaign-editor-hook'
import { useModal } from '../../../../../../shared/hooks/modal-hook'
import { VALIDATOR_REQUIRE } from '../../../../../../shared/util/validators'
import Modals from '../../../../../../shared/enums/Modals'
import InlineLoadingSpinner from '../../../../../../shared/components/UIElements/InlineLoadingSpinner'
import CustomDropdown from '../../../CustomDropdown'
import Button from '../../../../../../shared/components/FormElements/Button'
import StrokeInput from '../../../../../../shared/components/FormElements/StrokeInput'
import AdTextGenerator from '../../../AdTextGenerator'

const PostCreativeMaker = () => {
  const { getFacebookPages, getInstagramAccountsForPage } = useFacebook()
  const {
    startLoadingState,
    endLoadingState,
    newFacebookPageSelected,
    newInstagramAccountSelected,
  } = useFacebookCampaignEditor()
  const { openModal } = useModal()

  const facebook = useSelector(state => state.facebook)

  const { isFetchingFacebookPages } = useSelector(
    state => state.facebookCampaignEditor.loadings,
  )

  const { details } =
    useSelector(
      state => state.facebookCampaignEditor.currentCampaign?.currentCreative,
    ) ?? {}
  const { facebookPage, instagramAccount } = details ?? {}

  const [primaryText, setPrimaryText] = useState()
  const [websiteUrl, setWebsiteUrl] = useState()

  const refreshFacebookPages = async () => {
    startLoadingState('isFetchingFacebookPages')
    await getFacebookPages({
      facebookUserId: facebook.auth.id,
      accessToken: facebook.auth.accessToken,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingFacebookPages')
  }

  const refreshInstagramAccounts = async () => {
    startLoadingState('isFetchingInstagramAccounts')
    await getInstagramAccountsForPage({
      pageId: facebookPage?.id,
      pageAccessToken: facebookPage?.access_token,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingInstagramAccounts')
  }

  const openPostSelector = () => {
    openModal({
      type: Modals.FACEBOOK_POST_SELECTOR,
      pageId: facebookPage?.id,
      closeOnClickOutside: true,
    })
  }

  return (
    <React.Fragment>
      <div className="row input-container-row">
        <label>Facebook Page</label>

        {isFetchingFacebookPages ? (
          <InlineLoadingSpinner
            size="xs"
            message="Fetching pages"
          ></InlineLoadingSpinner>
        ) : facebook.pages.length ? (
          <CustomDropdown
            placeholder="Select page..."
            initialValue={facebookPage}
            onChange={p => newFacebookPageSelected({ facebookPage: p })}
            options={facebook.pages}
          />
        ) : (
          <p className="error-text dropdown">
            No page found. Please try selecting{' '}
            <Link to="/config?io=3">another ad account</Link> or visit{' '}
            <a
              href={`https://business.facebook.com/settings/pages?business_id=${facebook?.selectedBusiness?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Meta Business Settings
            </a>{' '}
            to create a page.
          </p>
        )}

        {facebook.pages ? (
          <Button
            onClick={refreshFacebookPages}
            size="small"
            className="refresh-button"
            title="Refresh pages list"
          >
            <FaSyncAlt className="icon" />
          </Button>
        ) : null}
      </div>

      <div className="row input-container-row">
        <label>
          Instagram <br /> Account
        </label>

        {facebookPage ? (
          <>
            {facebook.instagramAccounts[facebookPage?.id] &&
            facebook.instagramAccounts[facebookPage?.id].length ? (
              <CustomDropdown
                placeholder="Select account..."
                initialValue={instagramAccount}
                forcedValue={instagramAccount}
                onChange={i =>
                  newInstagramAccountSelected({ instagramAccount: i })
                }
                options={facebook.instagramAccounts[facebookPage?.id]}
              />
            ) : (
              <p className="error-text dropdown">
                No connected Instagram account found.
              </p>
            )}
            {facebook.instagramAccounts ? (
              <Button
                onClick={refreshInstagramAccounts}
                size="small"
                className="refresh-button"
                title="Refresh Instagram accounts list"
              >
                <FaSyncAlt className="icon" />
              </Button>
            ) : null}
          </>
        ) : (
          <p className="error-text dropdown">Please select a Facebook page</p>
        )}
      </div>

      <div className="row input-container-row">
        <label>Select Post</label>

        <div>
          <Button primary onClick={openPostSelector}>
            Browse
          </Button>
        </div>
      </div>

      <div className="row input-container-row no-margin">
        <StrokeInput
          leftTitle
          element="textarea"
          label="Ad Caption"
          id="primaryText"
          rows={3}
          placeholder="Unlock the Future with Our Innovative Solution!"
          value={primaryText}
          onChange={setPrimaryText}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={!primaryText ? 'Please enter a primary text' : null}
        />
      </div>

      <AdTextGenerator
        selectedAdAccountId={facebook.selectedAdAccount.id}
        selectARecommendation={setPrimaryText}
      />

      <div className="row input-container-row no-margin">
        <StrokeInput
          leftTitle
          element="input"
          type="text"
          label="Website URL"
          id="websiteUrl"
          placeholder="https://yourwebsite.com"
          value={websiteUrl}
          onChange={setWebsiteUrl}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={!websiteUrl ? 'Please enter a redirection page' : null}
        />
      </div>
    </React.Fragment>
  )
}

export default PostCreativeMaker
