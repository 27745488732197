import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../../shared/hooks/modal-hook'
import Modals from '../../../shared/enums/Modals'
import metaLogo from '../../../shared/assets/images/icons/meta-logo.svg'
import googleLogo from '../../../shared/assets/images/icons/google-logo.svg'
import discoveryIcon from '../../../shared/assets/images/icons/dicovery-meeting.svg'
import star from '../../../shared/assets/images/icons/star.png'
import adHealthCheckupIcon from '../../../shared/assets/images/icons/ad-health-checkup.svg'
import './YourSummaryCard.css'
import ProcessBar from './ProcessBar'
import AchievementBar from './AchievementBar'
import { PopupButton } from 'react-calendly'

const YourSummaryCard = () => {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const user = useSelector(state => state.auth.user)
  const facebook = useSelector(state => state.facebook)
  const performanceManagerProfile = useSelector(
    state => state.auth.user?.crmDetails?.performanceManagerProfile,
  )
  const name = useSelector(state => state.auth.user?.name)
  const email = useSelector(state => state.auth.user?.email)

  const connectionsCompleted = useSelector(
    state => state.auth.user?.connectionsCompleted,
  )

  const isLoggedOutFromFacebook = useSelector(
    state => state.auth.user?.facebookAds?.isLoggedOutFromFacebook,
  )

  const isNotSubscribed =
    !user?.subscription || user?.subscription.status !== 'active'

  const isGoogleConnected = useSelector(
    state => state.auth.user?.googleAds?.conversionId,
  )

  const handleDiscoveryMeeting = () => {
    openModal({
      type: Modals.BOOK_A_DEMO_WITH_US,
      closeOnClickOutside: true,
      performanceManager: user.crmDetails?.performanceManager,
      scheduledMeeting: user.crmDetails.adHealthCheckupMeeting,
    })
  }
  const bookSupportMeeting = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url:
          performanceManagerProfile?.meetingUrl ||
          process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK,
        prefill: {
          email: email || '',
          name: name || '',
        },
      })
    }
  }

  const handleAdHealthCheckup = () => {
    const isReadyForAHCU =
      facebook.selectedAdAccount &&
      facebook.selectedPixel &&
      facebook.selectedBusiness &&
      !facebook.fbInfoFetching

    if (isReadyForAHCU) {
      navigate('/ad-health-checkup')
    } else {
      navigate('/checkup/config')
    }
  }

  const handleStartSubscription = () => {
    navigate('/account/subscription')
  }
  const handleConnectionClick = () => {
    navigate('/config')
  }
  const cards = [
    {
      id: 'connections',
      content: (
        <div className="platform-connections">
          <div
            className="platform"
            onClick={handleConnectionClick}
            style={{ cursor: 'pointer' }}
          >
            <img src={metaLogo} alt="Meta" className="platform-logo" />
            <div className="platform-info">
              <span className="platform-name">Meta</span>
              <div className="status">
                <span className="status-label">Status:</span>
                <span
                  className={`status-value ${connectionsCompleted ? 'connected' : 'not-connected'}`}
                >
                  {connectionsCompleted
                    ? 'Connected'
                    : isLoggedOutFromFacebook
                      ? 'Logged Out'
                      : 'Not Connected'}
                </span>
              </div>
            </div>
          </div>
          <div
            className="platform"
            onClick={handleConnectionClick}
            style={{ cursor: 'pointer' }}
          >
            <img src={googleLogo} alt="Google" className="platform-logo" />
            <div className="platform-info">
              <span className="platform-name">Google</span>
              <div className="status">
                <span className="status-label">Status:</span>
                <span
                  className={`status-value ${isGoogleConnected ? 'connected' : 'not-connected'}`}
                >
                  {isGoogleConnected ? 'Connected' : 'Not Connected'}
                </span>
              </div>
            </div>
          </div>
        </div>
      ),
      onClick: handleConnectionClick,
    },
    {
      id: 'health-checkup',
      icon: (
        <img
          src={adHealthCheckupIcon}
          alt="Health Checkup"
          className="icon-image"
        />
      ),
      title: 'Ad Health Checkup',
      description: 'Review your ad health and past performance instantly.',
      onClick: handleAdHealthCheckup,
      iconClass: 'blue',
    },
    {
      id: 'discovery',
      isPopupButton: !isNotSubscribed,
      icon: (
        <img
          src={discoveryIcon}
          alt="Discovery Meeting"
          className="icon-image"
        />
      ),
      title: isNotSubscribed ? 'Book a Demo' : 'Book a Meeting',
      description: isNotSubscribed
        ? 'Plan your Ad Success with your FREE AI Ads Discovery Meeting'
        : `Book a support meeting with ${user.crmDetails?.performanceManager?.name || 'your Performance Manager'}`,
      onClick: isNotSubscribed ? handleDiscoveryMeeting : bookSupportMeeting,
      iconClass: 'green',
    },
    {
      id: 'subscription',
      icon: <img src={star} alt="Subscription" className="icon-image" />,
      title: 'Start AI Ads Subscription',
      description: 'Create, Launch and Optimize your Ad Campaigns with AI.',
      onClick: handleStartSubscription,
      iconClass: 'orange',
      show: isNotSubscribed,
    },
  ]

  return (
    <div className="summary-card">
      <div className="summary-card-header">
        <div className="process-bar-container">
          <ProcessBar />
          {/*    <AchievementBar /> */}
        </div>
      </div>
      <div className="summary-card-content">
        {cards.map(
          card =>
            (card.show === undefined || card.show) &&
            (card.isPopupButton ? (
              <PopupButton
                key={card.id}
                url={
                  performanceManagerProfile?.meetingUrl ||
                  process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK
                }
                rootElement={document.getElementById('root')}
                prefill={{
                  email: email || '',
                  name: name || '',
                }}
                className="card-item"
                text={
                  <>
                    <div className={`card-icon ${card.iconClass}`}>
                      {card.icon}
                    </div>
                    <div className="card-text">
                      <h4>{card.title}</h4>
                      <p>{card.description}</p>
                    </div>
                  </> 
                }
              />
            ) : (
              <div key={card.id} className="card-item" onClick={card.onClick}>
                {card.id === 'connections' ? (
                  card.content
                ) : (
                  <>
                    <div className={`card-icon ${card.iconClass}`}>
                      {card.icon}
                    </div>
                    <div className="card-text">
                      <h4>{card.title}</h4>
                      <p>{card.description}</p>
                    </div>
                  </>
                )}
              </div>
            )),
        )}
      </div>
    </div>
  )
}

export default YourSummaryCard
