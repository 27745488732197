import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IoClose } from 'react-icons/io5'
import { HiPlus } from 'react-icons/hi'

import { useFacebookCampaignEditor } from '../../../shared/hooks/facebook/campaign-editor-hook'
import AdCreativeMaker from './ad-creative-maker/AdCreativeMaker'
import AdCreativePreviewer from './AdCreativePreviewer'
import CreativeModeSelection from './CreativeModeSelection'
import CreativeTypes from '../../../shared/enums/CreativeTypes'
import useNavigator from '../../../shared/hooks/navigator-hook'
import useAICreatives from '../../../shared/hooks/ai-creatives-hook'

import './CampaignCreativesSection.css'

const CampaignCreativesSectionEditMode = () => {
  const {
    selectCurrentAdCreative,
    startNewCreativeMakerForm,
    changeCreativeType,
    removeCurrentCreativeFromList,
  } = useFacebookCampaignEditor()
  const { navigateWithParams } = useNavigator()
  const aiCreativeService = useAICreatives()
  const { enhencedCatalogDetails } = aiCreativeService

  const adCreatives = useSelector(state => state.facebookCampaignEditor.currentCampaign?.adCreatives)
  const currentCreative = useSelector(state => state.facebookCampaignEditor.currentCampaign?.currentCreative)
  const existingCampaignId = useSelector(state => state.facebookCampaignEditor.currentCampaign?.id)

  const queryParams = new URLSearchParams(window.location.search)

  const aiCreativeIdInUrlParams = queryParams.get('aiCreativeId')
  const aiCreativeIdInRedux = currentCreative?.details?.aiCreativeId

  const handleCreativeModeSelect = mode => {
    if (mode === CreativeTypes.AI_CREATIVE) {
      navigateWithParams('/ai-ads/creative-lab', {
        campaignId: existingCampaignId,
      })
    } else {
      changeCreativeType({ newType: mode })
    }
  }

  useEffect(() => {
    if (aiCreativeIdInUrlParams && !currentCreative.type) {
      changeCreativeType({ newType: CreativeTypes.AI_CREATIVE })
    }
  }, [aiCreativeIdInUrlParams])

  useEffect(() => {
    if (!aiCreativeIdInRedux) {
      return
    }

    const fetchAICreativeDetails = async id => {
      const newAICreative = await aiCreativeService.getById(id)

      if (!newAICreative.enhencedCatalogDetails) {
        return
      }

      aiCreativeService.setEnhencedCatalogDetails(newAICreative.enhencedCatalogDetails)
    }

    fetchAICreativeDetails(aiCreativeIdInRedux)
  }, [aiCreativeIdInRedux])

  const showModeSelection = !currentCreative?.type

  return (
    <div className="campaign-creatives-section campaign-form">
      <div className="creatives-tabs">
        {adCreatives &&
          Object.values(adCreatives).length > 0 &&
          Object.values(adCreatives).map((creative, index) =>
            creative ? (
              <div
                key={creative.name}
                className={`creative-tab${currentCreative?.name === creative.name ? ' active' : ''}`}
                onClick={() => selectCurrentAdCreative(creative)}
              >
                {creative.name || `Creative ${index + 1}`}
                {currentCreative?.name === creative.name && (
                  <IoClose
                    className="remove-icon"
                    onClick={e => {
                      e.stopPropagation()
                      removeCurrentCreativeFromList()
                    }}
                  />
                )}
              </div>
            ) : null,
          )}

        {!currentCreative?.name && (
          <div className="creative-tab active">
            New Creative
            {currentCreative && currentCreative.type && (
              <IoClose
                className="remove-icon"
                onClick={e => {
                  e.stopPropagation()
                  removeCurrentCreativeFromList()
                }}
              />
            )}
          </div>
        )}
        <div className="creative-tab add" onClick={startNewCreativeMakerForm}>
          <HiPlus className="plus-icon" /> <span>Add New</span>
        </div>
      </div>

      <div className="campaign-content-wrapper">
        <div className="main-content">
          {showModeSelection ? (
            <CreativeModeSelection
              handleCreativeModeSelect={handleCreativeModeSelect}
            />
          ) : (
            <AdCreativeMaker />
          )}
        </div>

        {currentCreative?.type && (
          <div className="preview-panel">
            <AdCreativePreviewer
              editing={true}
              creative={currentCreative}
              enhencedCatalogDetails={enhencedCatalogDetails}
            />
          </div>
        )}
      </div>


    </div>
  )
}

export default CampaignCreativesSectionEditMode
