import React, { useEffect, useState } from 'react'

import styles from './CompanyLogo.module.css'
import helper from '../../../shared/util/helper'
import { FaCircleNotch, FaUpload } from 'react-icons/fa'
import { useUser } from '../../../shared/hooks/user-hook'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import errorHandler from '../../../shared/util/errorHandler'

const CompanyLogo = ({ loading, setLoading }) => {
  const { saveUserProfileInfo, getProfile } = useUser()
  const { companyDetails, updateCompanyDetails } = useEnhencedCatalog()
  const userId = useSelector(state => state.auth.user.id)
  const [imageURL, setImageURL] = useState('')

  const userImage = useSelector(state => state.auth.user.image)
  const userBgRemovedImage = useSelector(
    state => state.auth.user.bgRemovedImage,
  )
  const [updatedUserImage, setUpdatedUserImage] = useState(userImage)
  const [updatedUserBgRemovedImage, setUpdatedUserBgRemovedImage] =
    useState(userBgRemovedImage)

  const maxSizeInMB = 2

  const handleImageChange = async e => {
    const file = e.target.files[0]
    if (!file) return

    const url = URL.createObjectURL(file)
    const maxSizeInByte = maxSizeInMB * 1024 * 1024

    if (file.size > maxSizeInByte) {
      alert(`Uploaded image size must be smaller than ${maxSizeInMB}MB`)
      return
    }

    setImageURL(url)

    const imageHasAlphaChannel = await helper.checkAlpha(url)

    try {
      setLoading(true)

      await saveUserProfileInfo({
        userId,
        image: file,
        imageHasAlphaChannel,
        removeImageBackground: true,
      })

      await getProfile({})
    } catch (err) {
      errorHandler(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUpdatedUserImage(userImage)
    setUpdatedUserBgRemovedImage(userBgRemovedImage)
  }, [userImage, userBgRemovedImage])

  useEffect(() => {
    updateCompanyDetails({
      companyLogoUrl: companyDetails.usesBgRemovedLogo
        ? updatedUserBgRemovedImage
        : updatedUserImage,
      usesBgRemovedLogo: companyDetails.usesBgRemovedLogo,
    })
  }, [updatedUserImage, updatedUserBgRemovedImage])

  useEffect(() => {
    if (companyDetails.companyLogoUrl) {
      setImageURL(companyDetails.companyLogoUrl)
    }
  }, [companyDetails.companyLogoUrl])

  return (
    <div className={styles.companyLogo}>
      {loading && (
        <div className={styles.loading}>
          <FaCircleNotch className={styles.spinnerIcon} />
        </div>
      )}
      <label htmlFor="company-logo-input">
        <div>
          <input
            type="file"
            id="company-logo-input"
            name="image"
            className={styles.fileInput}
            accept=".jpg,.jpeg,.png,.webp"
            onChange={handleImageChange}
          />
          {imageURL ? (
            <img
              className={styles.toolbarBoxInsideItemContentImage}
              src={imageURL}
              alt="Company Logo"
            />
          ) : (
            <div
              className={styles.toolbarBoxInsideItemContentImage}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed #ccc',
                padding: '10px',
                borderRadius: '5px',
                fontSize: '0.6em',
              }}
            >
              Upload
              <FaUpload />
            </div>
          )}
        </div>
      </label>
    </div>
  )
}

export default CompanyLogo
