import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../http-hook'
import { Link } from 'react-router-dom'
import { facebookActions } from '../../../store/facebook'
import { useFacebook } from '../facebook-hook'
import { useUser } from '../user-hook'
import errorHandler from '../../util/errorHandler'

export const useFacebookAuth = () => {

  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const { setConnectFlowStep } = useUser()

  const user = useSelector(state => state.auth.user)
  const authToken = useSelector(state => state.auth.token)
  const authHeader = {
    Authorization: "Bearer " + authToken,
    "Content-Type": "application/json",
  }

  const [auth, setAuth] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const facebook = useSelector(state => state.facebook)
  const { initializeFacebookInfo } = useFacebook()

  const fetchFacebookInfo = async ({ requestByAdmin = false, userId }) => {
    const uid = requestByAdmin ? userId : user.id
    if (authToken && !facebook.fetching) {
      dispatch(facebookActions.fetching(true))

      try {
        let result = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/facebook?uid=${uid}`,
          "GET", null, authHeader
        )
        let newInfo = { ...result.info, initialOverallReport: result.initialOverallReport }
        
        if (!requestByAdmin) {
          //get from local storage, overwrite accessToken and expiresIn
          const localFacebookAuth = JSON.parse(localStorage.getItem('facebookAuth'))
          const shouldRefresh = result.info && !newInfo?.auth?.loggedOut && (!localFacebookAuth || !localFacebookAuth.accessToken || localFacebookAuth.willExpireOn - 10 * 24 * 60 * 60 * 1000 < Date.now())
          if (shouldRefresh) {
            //refresh with backend and overwrite new ones
            const refreshResult = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/facebook/refreshAccessToken`,
              "POST", JSON.stringify({ userId: uid, saveToDb: false }), authHeader
            )
            if (refreshResult.accessToken) {
              newInfo.auth.accessToken = refreshResult.accessToken
              newInfo.auth.expiresIn = refreshResult.expiresIn
              newInfo.auth.willExpireOn = refreshResult.willExpireOn

              localStorage.setItem('facebookAuth', JSON.stringify({
                ...newInfo.auth,
                accessToken: refreshResult.accessToken,
                expiresIn: refreshResult.expiresIn,
                willExpireOn: refreshResult.willExpireOn
              }))
            } else {
              //failed to refresh, set to expired
              newInfo.auth = {
                ...newInfo.auth,
                expiresIn: 0,
                accessToken: null,
                willExpireOn: 0
              }
              dispatch(facebookActions.connectionPageIsReady(true))
              toast.warn(
                <div>
                  You are logged out of your Facebook account. Please <Link to="/config?s=fbl">log in</Link> again.
                </div>
              )
            }
          } else if (localFacebookAuth) {
            newInfo.auth = {
              ...newInfo.auth,
              accessToken: localFacebookAuth.accessToken,
              expiresIn: localFacebookAuth.expiresIn,
              willExpireOn: localFacebookAuth.willExpireOn
            }
          }

        }

        if (newInfo?.auth?.accessToken && !newInfo?.auth?.loggedOut) {
          newInfo.isLoggedIn = true;
          setIsLoggedIn(true)
          initializeFacebookInfo(newInfo)
        } else {
          dispatch(facebookActions.connectionPageIsReady(true))
        }

        dispatch(facebookActions.updateInfo(newInfo))
        dispatch(facebookActions.fetching(false))

        dispatch(facebookActions.connectionPageIsReady(true))
        return true
      } catch (err) {
        console.log("err", err)
        dispatch(facebookActions.connectionPageIsReady(true))
        return false
      }
    } else if (authToken) {
      dispatch(facebookActions.connectionPageIsReady(true))
      return true
    }
  }


  const loginCallback = async (response) => {

    dispatch(facebookActions.connectionPageIsReady(false))
    if (response.accessToken) {

      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/loginCompleted`,
        "POST",
        JSON.stringify({
          userId: user.id,
          facebookAuth: response,
        }),
        authHeader,
      )
      let facebookAuth = {
        ...response,
        accessToken: result.accessToken,
        expiresIn: result.expiresIn,
        willExpireOn: result.willExpireOn
      }

      setConnectFlowStep({ step: "loggedInToFacebook" })

      dispatch(facebookActions.loginCompleted(facebookAuth))
      initializeFacebookInfo({ ...facebook, auth: facebookAuth, isLoggedIn: true })
      // save to local storage
      localStorage.setItem('facebookAuth', JSON.stringify(facebookAuth))

      setAuth(facebookAuth)
      toast.success("Logged in successfully!")
    } else {
      dispatch(facebookActions.connectionPageIsReady(true))
      errorHandler({message: "Facebook Login Failed!"})
    }
  }

  const logOut = async (forced) => {
    try {

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/logout`,
        "POST",
        JSON.stringify({
          userId: user.id,
        }),
        authHeader,
      )

      //remove from local storage
      localStorage.removeItem('facebookAuth')

      setIsLoggedIn(false)
      if (!forced) toast.success("Logged out successfully!")
      dispatch(facebookActions.logOut())
    } catch (err) {
      errorHandler(err)
    }

  }

  return {
    auth,
    isLoggedIn,
    loginCallback,
    logOut,
    fetchFacebookInfo
  }
}
