import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useFacebookCreative } from '../../../shared/hooks/facebook/creative-hook'
import useNavigator from '../../../shared/hooks/navigator-hook'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import { facebookCampaignEditorActions } from '../../../store/facebookCampaignEditor'
import EnhencedCatalogPreview from '../../../ai-creative/components/miscellaneous/EnhencedCatalogPreview'

import "./AdCreativePreviewer.css"
import useAICreatives from '../../../shared/hooks/ai-creatives-hook'
import errorHandler from '../../../shared/util/errorHandler'

const AdCreativePreviewer = ({ existingCampaignCreative, enhencedCatalogDetails, className }) => {
    const { getAdPreviews } = useFacebookCreative()
    const { searchParams } = useNavigator()
    const [previews, setPreviews] = useState()
    const ref = useRef()
    const dispatch = useDispatch()
    const [isGeneratingPreviews, setIsGeneratingPreviews] = useState(false)
    const mounted = useRef(true)

    const {
        isCreatingCreative
    } = useSelector(state => state.facebookCampaignEditor.loadings)

    const creativeIdInRedux = useSelector(state => state.facebookCampaignEditor?.currentCampaign?.currentCreative?.body?.id)
    const isCreativeValidInRedux = useSelector(state => state.facebookCampaignEditor?.currentCampaign?.currentCreative?.isValid)
    const creativeId = existingCampaignCreative?.id ?? creativeIdInRedux
    const isCreativeValid = existingCampaignCreative?.id ? true : isCreativeValidInRedux

    const aiCreativeIdInRedux = useSelector(state => state.facebookCampaignEditor?.currentCampaign?.currentCreative?.details?.aiCreativeId)
    const aiCreativeId = existingCampaignCreative?.id ?? aiCreativeIdInRedux

    const { fullList } = useAICreatives()

    const aiCreativeItem = useMemo(() => {
      return fullList.find(creative => creative._id === aiCreativeId)
    }, [fullList, aiCreativeId])

    useEffect(() => {
        return () => {
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!ref.current) return

        if (creativeId) {
            dispatch(facebookCampaignEditorActions.setIsPreviewUpToDate(false))
            getPreviewsOfCreative(creativeId)
        }

    }, [ref])

    useEffect(() => {
        if (creativeId) {
            getPreviewsOfCreative(creativeId)
        }
    }, [creativeId])

    const getPreviewsOfCreative = async (cid) => {
        try {
            setIsGeneratingPreviews(true)
            const previewResults = await getAdPreviews({ creativeId: cid ? cid : '120200070614810307' })
            
            if (mounted.current) {
                if (previewResults && previewResults.length > 0) {
                    generateAndSetDisplayedPreviews(previewResults)
                    dispatch(facebookCampaignEditorActions.setIsPreviewUpToDate(true))
                } else {
                    setPreviews([])
                    toast.warning('Ad preview is currently unavailable. Please try again later.')
                }
                setIsGeneratingPreviews(false)
            }
        } catch (error) {
            if (mounted.current) {
                setIsGeneratingPreviews(false)
                setPreviews([])
                errorHandler(error)
                console.error('Preview error:', error)
            }
        }
    }

    const generateAndSetDisplayedPreviews = (previewResults) => {
        try {
            const validPreviews = previewResults.filter(preview => {
                if (!preview.body || !preview.format) return false;
                
                const cleanHtml = preview.body
                    .replace(/chrome-extension:\/\/[^"'\s]*/g, 'about:blank')
                    .replace(
                        /(https?:\/\/[^"'\s]*?fbcdn\.net[^"'\s]*?)(?=["'\s])/g,
                        (match) => {
                            return match.includes('&_nc_sid=') ? match : 'about:blank';
                        }
                    );
                
                preview.body = cleanHtml;
                return true;
            });
            
            setPreviews(
                validPreviews.map(preview =>
                    <div key={preview.format}
                        className={`ad-creative-cont ${preview.format.toLowerCase().replaceAll("_", "-")}`}
                        style={{
                            padding: `${preview.dimensions.height / 2}px ${preview.dimensions.width / 2}px`,
                            marginRight: `${preview.dimensions.width / -2}px`,
                            marginBottom: `${preview.dimensions.height / -2}px`
                        }}
                    >
                        <div
                            className="preview-iframe-container"
                            dangerouslySetInnerHTML={{ 
                                __html: preview.body 
                            }}
                            onError={(e) => {
                                console.log('Preview render error:', e);
                                if (e.target.parentElement) {
                                    e.target.parentElement.innerHTML = `
                                        <div class="preview-error">
                                            <p>Preview could not be loaded</p>
                                        </div>
                                    `;
                                }
                            }}
                        />
                    </div>
                )
            );
        } catch (error) {
            console.error('Preview generation error:', error);
            setPreviews([]);
        }
    };

    return (
      <React.Fragment>
        <div className={`adcr ${className || ''}`}>
          <h3>Previews</h3>
          {enhencedCatalogDetails && !searchParams.edit ? (
            <EnhencedCatalogPreview
              aiCreativeId={aiCreativeItem?._id}
              enhencedCatalogDetails={enhencedCatalogDetails}
            />
          ) : isCreativeValid ? (
            <>
              {isGeneratingPreviews && (
                <InlineLoadingSpinner
                  message={`${creativeId ? 'Updating' : 'Getting'} your Ad Preview, please wait`}
                />
              )}
              {previews?.length ? (
                <div ref={ref} className="ad-creatives-box">
                  {previews}
                </div>
              ) : null}
            </>
          ) : isCreatingCreative ? (
            <InlineLoadingSpinner
              height={20}
              message={`Creating your Ad Preview, please wait...`}
            />
          ) : (
            <p className="helper-text">
              Ad preview will appear here once a creative is configured.
            </p>
          )}
        </div>
      </React.Fragment>
    )
}

export default AdCreativePreviewer

{/* <iframe src="https://www.facebook.com/ads/api/preview_iframe.php?d=AQJ2FBxqbfez_xpsAEwDMMd4d5QMzEOxFBMd_tCCN7ndufL55mZah7ca6TkfKihMPF1ZhUcJ-PmQeT8rAggHlwtX2IfDvwKlKkIM5jOlJYAm0fYjOPtIFsw5WqJiNXXw3y40GM8MZlY_NrmRVWDeVznEpiM8zCeV6WH_4nKHjAkC8dmt3Bao1dVJqYt5aBUwN0cNgmBka45-aHinxDBt9qGmpjRn1QpgGXOILnPZz6D_waANSbHiPJD5UvuAY6bL8fM4x3h8-6EW5ktBmXbfNZB5TVZvU3ugu7LOyZnnXKAgsVpbxNP6ecbgLpq-gZSjIOdHgjBhgU2dxw7duIRHb-920lRNj3DDACZErjcvkav-If2B3fZKvxyWV9T6MHH8KVRKfeTVm-xEws203xikM-fXsWoHboO86t5HcglgX637vEdny9QtIUL1Bd6g4UTbhs4&amp;t=AQK9tuQHuoI4mB0xE8E"
    width="320" height="570" scrolling="yes" style="border: none;"></iframe> */}