import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FaTimes } from 'react-icons/fa'

import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import Thumbnail from '../canvas/Thumbnail'
import GenerativeTemplates from './GenerativeTemplates'
import { toast } from 'react-toastify'

import styles from './TemplatesGrid.module.css'

const TemplatesGrid = ({ templates, onTemplateClick, initial, type }) => {
  const dispatch = useDispatch()

  const [activeGrid, setActiveGrid] = useState('geometric')

  const handleTemplateClick = ({
    id,
    hasLogo,
    forceDiscount,
    primaryText,
    secondaryText,
  }) => {
    dispatch(
      aiCreativeEditorActions.setDraftConfiguration({
        initial,
        template: { id, hasLogo, forceDiscount, type },
        primaryFontFamily: primaryText.fontFamily,
        secondaryFontFamily: secondaryText.fontFamily,
      }),
    )
    onTemplateClick()
  }

  const onCloseClick = () => {
    onTemplateClick()
  }

  if (type === 'enhenced-catalog') {
    return (
      <div className={styles.templatesGridContainer}>
        <div className={styles.enhencedCatalogTemplatesGridHeaderRow}>
          <div className={styles.enhencedCatalogTemplatesGridHeaderRowTitle}>
            Select a template
          </div>
          <div
            className={styles.templatesGridCloseButton}
            onClick={onCloseClick}
          >
            <FaTimes />
          </div>
        </div>
        <div className={styles.templatesGrid}>
          {[...templates].reverse().map(template => {
            if (template.config.hidden) {
              return null
            }
            return (
              <div
                key={template.id}
                className={styles.templateItem}
                onClick={() => handleTemplateClick(template)}
              >
                <Thumbnail templateConfig={template.config} />
                <p>{template.config.name}</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.templatesGridContainer}>
      <div className={styles.templatesGridHeaderRow}>
        <div className="row">
          <div
            className={`${styles.templatesGridTitle} ${activeGrid === 'geometric' ? styles.active : ''}`}
            onClick={() => setActiveGrid('geometric')}
          >
            Geometric templates
          </div>
          <div
            className={`${styles.templatesGridTitle} ${activeGrid === 'generative' ? styles.active : ''} ${styles.templatesGridTitleDisabled}`}
            onClick={() => {
              toast.info('Coming soon!')
              // setActiveGrid('generative')
            }}
          >
            Generative templates (Coming soon!)
          </div>
        </div>
        <div className={styles.templatesGridCloseButton} onClick={onCloseClick}>
          <FaTimes />
        </div>
      </div>
      {activeGrid === 'geometric' ? (
        <div className={styles.templatesGrid}>
          {[...templates].reverse().map(template => {
            if (template.config.hidden) {
              return null
            }
            return (
              <div
                key={template.id}
                className={styles.templateItem}
                onClick={() => handleTemplateClick(template)}
              >
                <Thumbnail templateConfig={template.config} />
                <p>{template.config.name}</p>
              </div>
            )
          })}
        </div>
      ) : (
        <GenerativeTemplates handleTemplateClick={handleTemplateClick} />
      )}
    </div>
  )
}

export default TemplatesGrid
