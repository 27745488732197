import { WORKBENCH_SIZE } from '../../../support/constants'
import temp13bg from '../../../../shared/assets/images/ai-creative/temp13bg.svg'

export const Template13Config = {
  name: 'Coral Curve',
  containerId: 'template13-carousel-single',
  backgroundColor: 'color2',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp13bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#E54343',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: (WORKBENCH_SIZE.width / 2) * 1.4,
        y: (WORKBENCH_SIZE.height / 2) * 1.3,
      },
      scale: {
        maxWidth: 850,
        maxHeight: 850,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height - 200,
      },
      scale: {
        maxHeight: 140,
        maxWidth: 300,
      },
      dynamicSrc: 'companyLogo',
      required: false,
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: 40,
        y: 280,
      },
      text: '', // No default text provided in original
      fontFamily: 'Bayon',
      fontSize: 50,
      color: 'color3',
      fontStyle: 'bold',
      fontIndex: 2,
      dynamicContent: 'productPrice', // Assumed dynamic content
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 40,
        y: 60,
      },
      text: '30% OFF',
      fontFamily: 'Be Vietnam Pro',
      fontSize: 114,
      color: 'color1',
      letterSpacing: -3,
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 40,
        y: 171,
      },
      text: 'USE CODE: GET30',
      fontFamily: 'Be Vietnam Pro',
      fontSize: 43,
      color: 'color3',
      letterSpacing: -0.05,
      fontIndex: 2,
    },
  ],
}
