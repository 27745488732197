import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  VALIDATOR_EMAIL,
  /*   VALIDATOR_MINLENGTH, */
  VALIDATOR_REQUIRE,
  VALIDATOR_DOMAIN,
  /* VALIDATOR_PASSWORD_MATCH, */
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useAuth } from '../../shared/hooks/auth-hook'
import StrokeInput from '../../shared/components/FormElements/StrokeInput'
import Button from '../../shared/components/FormElements/Button'
/* import LoginOptions from '../components/LoginOptions' */
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png'
import backgroundImage from '../../shared/assets/images/register-decor/register-computer-bg.png'

import IntroComponent from '../components/intro/IntroComponent'
import './Auth.css'

import 'react-toastify/dist/ReactToastify.css'

import IntroHeadlineContainer from '../components/intro/IntroHeadlineContainer'

const Register = props => {
  const [showIntro, setShowIntro] = useState(true)
  const [busy, setBusy] = useState(false)
  const [whereDidYouHearUsError, setWhereDidYouHearUsError] = useState('')
  const [customWhereDidYouHearUsError, setCustomWhereDidYouHearUsError] =
    useState('')
  const isMounted = useRef(true)

  const dispatch = useDispatch()
  const { register } = useAuth()

  const query = new URLSearchParams(window.location.search)

  const userName = query.get('name')
  const userEmail = query.get('email')
  const userCompanyDomain = query.get('domain')

  const headlines = [
    {
      text: 'To Get Started, Please Enter Your ',
      highlight: 'Work Email',
    },
  ]

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: userName,
        isValid: !!userName,
      },
      email: {
        value: userEmail,
        isValid: !!userEmail,
      },
      title: {
        value: '',
        isValid: false,
      },
      companyDomain: {
        value: userCompanyDomain,
        isValid: !!userCompanyDomain,
      },
      whereDidYouHearUs: {
        value: '',
        isValid: false,
      },
      customWhereDidYouHearUs: {
        value: '',
        isValid: true,
      },
    },
    !!userName && !!userEmail && !!userCompanyDomain,
  )

  useEffect(() => {
    handleAppClass(true)
    return () => {
      handleAppClass(false)
      isMounted.current = false
    }
  }, [dispatch])

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-noscroll')
      : document.getElementById('app')?.classList.remove('full-screen-noscroll')
  }

  const submit = async event => {
    event.preventDefault()

    const {
      name,
      email,
      title,
      companyDomain,
      whereDidYouHearUs,
      customWhereDidYouHearUs,
    } = formState.inputs

    let whereDidYouHearUsValue = whereDidYouHearUs.value
    if (whereDidYouHearUs.value === '') {
      setWhereDidYouHearUsError('Please select an option')
      return
    } else if (whereDidYouHearUs.value === 'Other') {
      if (customWhereDidYouHearUs.value === '') {
        setCustomWhereDidYouHearUsError('Please enter your custom option')
        return
      }
      whereDidYouHearUsValue = customWhereDidYouHearUs.value
    }

    setBusy(true)

    await register({
      name: name.value,
      email: email.value,
      /*       country: country.value, */
      url: companyDomain.value,
      /*      password: password.value, */
      whereDidYouHearUs: whereDidYouHearUsValue,
    })

    if (isMounted.current) {
      setBusy(false)
    }
  }

  const handleStartJourney = () => {
    setShowIntro(false)
  }

  if (showIntro) {
    return <IntroComponent onStart={handleStartJourney} />
  }

  return (
    <div className="full-container sign-up">
      <div className="login-cont sign-up-cont">
        <div className="sign-up-form-cont">
          <IntroHeadlineContainer
            headlines={headlines}
            animationEnabled={false}
          />
          <form onSubmit={submit}>
            <StrokeInput
              element="input"
              id="email"
              initialValue={userEmail}
              type="email"
              label="Work E-Mail"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email address."
              onInput={inputHandler}
            />
            <StrokeInput
              element="input"
              id="name"
              initialValue={userName}
              type="text"
              label="Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter your name."
              onInput={inputHandler}
            />
            <StrokeInput
              element="select"
              id="title"
              type="text"
              label="Title"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please select your job title"
              onInput={inputHandler}
              options={[
                { value: '', label: 'Select a title' },
                { value: 'Business Owner', label: 'Business Owner' },
                { value: 'CMO', label: 'CMO' },
                { value: 'Performance Marketing Specialist', label: 'Performance Marketing Specialist' },
                { value: 'Sales Manager', label: 'Sales Manager' },
                { value: 'Data Analyst', label: 'Data Analyst' },
                { value: 'Technical Specialist', label: 'Technical Specialist' },
                { value: 'Other', label: 'Other' }
              ]}
            />
            <StrokeInput
              element="input"
              id="companyDomain"
              initialValue={userCompanyDomain}
              type="text"
              label="Domain"
              validators={[VALIDATOR_DOMAIN()]}
              errorText="Please enter a valid company domain."
              onInput={inputHandler}
            />
            <StrokeInput
              element="select"
              id="whereDidYouHearUs"
              type="text"
              label="Where did you hear about us?"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please select an option"
              onInput={inputHandler}
              options={[
                { value: '', label: 'Select an option' },
                { value: 'Google', label: 'Google' },
                { value: 'Facebook / Instagram', label: 'Facebook / Instagram' },
                { value: 'Shopify', label: 'Shopify' },
                { value: 'LinkedIn', label: 'LinkedIn' },
                { value: 'Email', label: 'Email' },
                { value: 'Colleague / Friend', label: 'Colleague / Friend' },
                { value: 'Other', label: 'Other' }
              ]}
            />
            {whereDidYouHearUsError && (
              <div className="error-text">{whereDidYouHearUsError}</div>
            )}
            {formState.inputs.whereDidYouHearUs.value === 'Other' && (
              <React.Fragment>
                <StrokeInput
                  element="input"
                  id="customWhereDidYouHearUs"
                  type="text"
                  label="Please specify"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter where you heard about us"
                  onInput={inputHandler}
                />
                {customWhereDidYouHearUsError && (
                  <div className="error-text">
                    {customWhereDidYouHearUsError}
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="small-text">
              <small>
                By clicking on Sign up, you agree to{' '}
                <a
                  href="https://enhencer.com/terms-of-use"
                  className="link-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms & conditions
                </a>{' '}
                and{' '}
                <a
                  href="https://enhencer.com/privacy-policy"
                  className="link-button"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </a>
              </small>
            </div>
            <div className="cont">
              <Button
                loading={busy}
                className="action-button"
                type="submit"
                disabled={!formState.isValid}
              >
                Continue
              </Button>
            </div>
            <div className="no-account-row">
              Already have an account?
              <Link className="link-button" to="/login">
                Log In
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className="sign-up-info">
        <img
          alt="Background Image"
          src={backgroundImage}
          className="background"
        />
      </div>
    </div>
  )
}

export default Register
