import React, { useEffect, useMemo, useRef, useState } from 'react'
import Konva from 'konva'
import { useSelector } from 'react-redux'

import { WORKBENCH_SIZE } from '../../support/constants'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import {
  loadAndChangeSvgColor,
  formatPrice,
  calculateBoxFitCover,
  processTextPlaceholders,
	resizeImageToFit,
	centerTheElement,
} from '../../support/utils'
import DiscountTypes from '../../../shared/enums/DiscountTypes'

const Thumbnail = ({ templateConfig }) => {
  const stageRef = useRef(null)
  const mainLayerRef = useRef(null)

  const currency = useSelector(
    state => state.facebook?.selectedAdAccount?.currency ?? 'USD',
  )
  const { draftConfiguration, productOnDraft, companyDetails, discount } =
    useEnhencedCatalog()
  const [loadedImages, setLoadedImages] = useState({})

  const currentPalette = useMemo(() => {
    return (
      draftConfiguration?.palette ?? {
        color1: '#FF6F61',
        color2: '#FFFFFF',
        color3: '#2C2C2C',
      }
    )
  }, [draftConfiguration.palette])

  const resizeStage = () => {
    if (stageRef.current) {
      const containerWidth = 200
      const containerHeight = 200

      let horizontalScale = containerWidth / WORKBENCH_SIZE.width
      let verticalScale = (containerHeight - 20) / WORKBENCH_SIZE.height
      let scale = Math.min(horizontalScale, verticalScale)

      stageRef.current.width(WORKBENCH_SIZE.width * scale)
      stageRef.current.height(WORKBENCH_SIZE.height * scale)
      stageRef.current.scale({ x: scale, y: scale })
      stageRef.current.draw()
      return {
        width: WORKBENCH_SIZE.width * scale,
        height: WORKBENCH_SIZE.height * scale,
      }
    }

    return {
      width: WORKBENCH_SIZE.width,
      height: WORKBENCH_SIZE.height,
    }
  }

  useEffect(() => {
    loadImages()
  }, [productOnDraft?.id, currentPalette, templateConfig.containerId])

  useEffect(() => {
    if (!areImagesLoaded()) {
      return
    }

    createStage()

    return () => {
      if (stageRef.current) {
        stageRef.current.destroy()
      }
    }
  }, [loadedImages, templateConfig.containerId])

  useEffect(() => {
	if (!areImagesLoaded()) {
		return
	}

	updateCompanyLogo()
}, [draftConfiguration?.showCompanyLogo, loadedImages, companyDetails.usesBgRemovedLogo])

  const loadImages = async () => {
    const images = {}
    const loadImagePromises = []

    // Helper function to load and process SVG images
    const loadSvgImage = (svgUrl, color, customReplace) => {
      return new Promise(resolve => {
        loadAndChangeSvgColor(
          svgUrl,
          color,
          coloredSvgUrl => {
            const imgObj = new Image()
            imgObj.src = coloredSvgUrl
            imgObj.onload = () => {
              resolve(imgObj)
            }
          },
          customReplace,
        )
      })
    }

    // Process all elements that need image loading
    if (templateConfig.elements) {
      templateConfig.elements.forEach((element, index) => {
        if (element.type === 'image' && element.src) {
          loadImagePromises.push(
            new Promise(resolve => {
              const imageObj = new Image()
              let imageSource = element.src

              // For SVG images with color replacement
              if (element.isSvg && element.svgColor) {
                loadSvgImage(
                  element.src,
                  currentPalette[element.svgColor] || element.svgColor,
                  element.svgCustomReplace,
                ).then(image => {
                  images[`element_${index}`] = image
                  resolve()
                })
              } else {
                // For regular images
                let url = new URL(imageSource, window.location.origin)
                imageSource =
                  url.searchParams.size > 0
                    ? imageSource + `&konva=${Date.now()}`
                    : imageSource + `?konva=${Date.now()}`
                imageObj.src = imageSource
                imageObj.onload = () => {
                  images[`element_${index}`] = imageObj
                  resolve()
                }
                imageObj.onerror = error => {
                  resolve() // Resolve anyway to not block other images
                }
              }
            }),
          )
        }

        // Handle dynamic image sources (product image, company logo)
        if (element.type === 'image' && element.dynamicSrc === 'productImage') {
          const imageUrl = element.withBackground
            ? `${process.env.REACT_APP_BACKEND_URL}/proxy/getProxyImage?url=${encodeURIComponent(productOnDraft?.originalImageUrl)}`
            : productOnDraft?.transparentImageUrl

          if (imageUrl) {
            loadImagePromises.push(
              new Promise(resolve => {
                const imageObj = new Image()
                let url = new URL(imageUrl, window.location.origin)
                const imageSource =
                  url.searchParams.size > 0
                    ? imageUrl + `&konva=${Date.now()}`
                    : imageUrl + `?konva=${Date.now()}`

                imageObj.src = imageSource
                imageObj.onload = () => {
                  images[`element_${index}`] = imageObj
                  resolve()
                }
                imageObj.onerror = error => {
                  console.error(
                    `Failed to load product image for element ${index}`,
                    { error, src: imageSource },
                  )
                  resolve()
                }
              }),
            )
          }
        }

        if (element.type === 'image' && element.dynamicSrc === 'companyLogo') {
          if (companyDetails.companyLogoUrl) {
            loadImagePromises.push(
              new Promise(resolve => {
                const imageObj = new Image()
                let url = new URL(
                  companyDetails.companyLogoUrl,
                  window.location.origin,
                )
                const imageSource =
                  url.searchParams.size > 0
                    ? companyDetails.companyLogoUrl + `&konva=${Date.now()}`
                    : companyDetails.companyLogoUrl + `?konva=${Date.now()}`

                imageObj.src = imageSource
                imageObj.onload = () => {
                  images[`element_${index}`] = imageObj
                  resolve()
                }
                imageObj.onerror = error => {
                  resolve()
                }
              }),
            )
          }
        }
      })
    }

    // Wait for all images to load
    await Promise.all(loadImagePromises)
    setLoadedImages(images)
  }

  const areImagesLoaded = () => {
		// Check if all required images are loaded
		if (!templateConfig.elements) return false

		let allLoaded = true

		templateConfig.elements.forEach((element, index) => {
			if (element.type === 'image' && !loadedImages[`element_${index}`]) {
				if (element.required !== false) { // If not explicitly marked as optional
					allLoaded = false
				}
			}
		})

		return allLoaded
	}

  const createStage = () => {
		// Initialize the stage
    stageRef.current = new Konva.Stage({
      container: templateConfig.containerId,
      width: WORKBENCH_SIZE.width,
      height: WORKBENCH_SIZE.height,
    })

		// Create main layer
    mainLayerRef.current = new Konva.Layer()

    // Create background
    const backgroundConfig = {
      x: 0,
      y: 0,
      height: WORKBENCH_SIZE.height,
      width: WORKBENCH_SIZE.width,
      name: 'background',
    }

    if (templateConfig.backgroundColor) {
      backgroundConfig.fill = currentPalette[templateConfig.backgroundColor]
    }

    if (templateConfig.backgroundGradient?.type === 'linear') {
      backgroundConfig.fillLinearGradientStartPoint =
        templateConfig.backgroundGradient.from.position
      backgroundConfig.fillLinearGradientEndPoint =
        templateConfig.backgroundGradient.to.position
      backgroundConfig.fillLinearGradientColorStops = [
        templateConfig.backgroundGradient.from.stop,
        currentPalette[templateConfig.backgroundGradient.from.color],
        templateConfig.backgroundGradient.to.stop,
        currentPalette[templateConfig.backgroundGradient.to.color],
      ]
    }

    const background = new Konva.Rect(backgroundConfig)
    mainLayerRef.current.add(background)

    // Create elements
    createElements()

    // Add layers to stage
		stageRef.current.add(mainLayerRef.current)

    // Resize the stage to fit the container
    resizeStage()
  }

	const createElements = () => {
		if (!templateConfig.elements) return

		templateConfig.elements.forEach((element, index) => {
			switch (element.type) {
				case 'image':
					createImageElement(element, index)
					break
				case 'text':
					createTextElement(element, index)
					break
				case 'rect':
					createRectElement(element, index)
					break
				case 'circle':
					createCircleElement(element, index)
					break
				default:
					console.warn(`Unknown element type: ${element.type}`)
			}
		})

		mainLayerRef.current.draw()
	}

	const createImageElement = (element, index) => {
		const image = loadedImages[`element_${index}`]
		if (!image) return

		let imageNode
		const elementConfig = {
			x: element.position.x,
			y: element.position.y,
			width: element.scale?.width || image.naturalWidth,
			height: element.scale?.height || image.naturalHeight,
			name: `element_${index}`,
			draggable: false,
			opacity: element.opacity || 1,
			id: element.id || `element_${index}`,
			visible: element.id === 'companyLogo' && !element.required ? draftConfiguration?.showCompanyLogo : true,
		}

		// Handle resize if maxWidth or maxHeight is specified
		if (element.scale?.maxWidth || element.scale?.maxHeight) {
			const { width, height } = resizeImageToFit({
				x: element.position.x,
				y: element.position.y,
				width: image.naturalWidth,
				height: image.naturalHeight,
				maxWidth: element.scale?.maxWidth,
				maxHeight: element.scale?.maxHeight,
				center: false,
			})

			elementConfig.width = width
			elementConfig.height = height
		}

		// Handle centering if specified
		if (element.center) {
			if (elementConfig.width && elementConfig.height) {
				const { x, y } = centerTheElement({
					x: elementConfig.offsetX || elementConfig.x,
					y: elementConfig.offsetY || elementConfig.y,
					width: elementConfig.width,
					height: elementConfig.height,
				})

				elementConfig.x = x
				elementConfig.y = y
			}
		}

		// Handle objectFit: 'cover' if specified
		if (element.objectFit === 'cover') {
			const clipConfig = {
				x: element.position.x,
				y: element.position.y,
				width: element.scale.width,
				height: element.scale.height,
			}

			// Adjust clip position if centered
			if (element.center) {
				clipConfig.x = element.position.x - element.scale.width / 2;
				clipConfig.y = element.position.y - element.scale.height / 2;
			}

			const group = new Konva.Group({
				clip: clipConfig,
				name: `element_${index}_container`,
				draggable: false,
				id: element.id ? `${element.id}_container` : `element_${index}_container`,
			})

			const fittingResult = calculateBoxFitCover({
				img: { width: image.naturalWidth || image.width, height: image.naturalHeight || image.height },
				box: {
					width: element.scale.width,
					height: element.scale.height,
					positionx: element.center ? element.position.x - element.scale.width / 2 : element.position.x,
					positiony: element.center ? element.position.y - element.scale.height / 2 : element.position.y
				}
			})

			elementConfig.x = fittingResult.x
			elementConfig.y = fittingResult.y
			elementConfig.width = fittingResult.width
			elementConfig.height = fittingResult.height

			// Remove centering offsets for the image inside the group
			if (element.center) {
				elementConfig.offsetX = 0;
				elementConfig.offsetY = 0;
			}

			imageNode = new Konva.Image({
				...elementConfig,
				image: image,
			})

			group.add(imageNode)
			if (element.clickThrough) {
				group.listening(false)
			}

			// Apply centering to the group if needed
			if (element.center) {
				group.x(element.position.x);
				group.y(element.position.y);
				group.offsetX(element.scale.width / 2);
				group.offsetY(element.scale.height / 2);
			}

			mainLayerRef.current.add(group)
		} else {
			// Regular image
			imageNode = new Konva.Image({
				...elementConfig,
				image: image,
			})

			if (element.clickThrough) {
				imageNode.listening(false)
			}
			mainLayerRef.current.add(imageNode)
		}

		// Apply filters if specified
		if (element.filters) {
			imageNode.cache()

			if (element.filters.brightness !== undefined) {
				imageNode.filters([Konva.Filters.Brighten])
				imageNode.brightness(element.filters.brightness)
			}
		}
	}

	const createTextElement = (element, index) => {
		// Process dynamic text content
		let textContent = element.text

		// Check if the text contains placeholders
		const hasPlaceholders = /{{[^{}]+}}/g.test(textContent);

		if (hasPlaceholders) {
			// Process placeholders in the text
			textContent = processTextPlaceholders(textContent, {
				productName: draftConfiguration?.productName || productOnDraft?.name || '',
				productPrice: formatPrice(productOnDraft?.price || 0, productOnDraft?.currency || currency),
				companyName: companyDetails?.companyName || '',
				discountAmount: discount?.amount + (discount?.type === DiscountTypes.AMOUNT ? formatPrice(discount?.amount || 0, currency) : "%"),
				discountCode: discount?.code || '',
			});
		}

		// Calculate initial height based on maxLines if specified
		let initialHeight = element.height;
		if (element.maxLines && element.fontSize) {
			// Approximate line height based on font size (typically 1.2-1.5x fontSize)
			const lineHeight = element.fontSize * 1.2;
			initialHeight = element.maxLines * lineHeight;
		}

		const textNode = new Konva.Text({
			x: element.position.x,
			y: element.position.y,
			text: textContent,
			fontSize: element.fontSize || 20,
			fontFamily: element.fontFamily || 'Arial',
			fill: currentPalette[element.color] || element.color || '#000000',
			width: element.width,
			height: initialHeight || element.height,
			align: element.align || 'left',
			verticalAlign: element.verticalAlign || 'top',
			padding: element.padding || 0,
			letterSpacing: element.letterSpacing || 0,
			fontStyle: element.fontStyle || '',
			draggable: false,
			name: `element_${index}`,
			id: element.id || `element_${index}`,
			originalText: hasPlaceholders ? element.text : null,
			shadowColor: element.shadowColor || 'black',
			shadowBlur: element.shadowBlur || 5,
			shadowOffset: element.shadowOffset || { x: 1, y: 1 },
			shadowOpacity: element.shadowOpacity || 0.5,
			ellipsis: true, // Enable ellipsis for all text elements
		})

		mainLayerRef.current.add(textNode)
	}

	const createRectElement = (element, index) => {
		const rectNode = new Konva.Rect({
			x: element.position.x,
			y: element.position.y,
			width: element.scale.width,
			height: element.scale.height,
			fill: currentPalette[element.fill] || element.fill,
			stroke: currentPalette[element.stroke] || element.stroke,
			strokeWidth: element.strokeWidth || 0,
			cornerRadius: element.cornerRadius || 0,
			opacity: element.opacity || 1,
			draggable: false,
			name: `element_${index}`,
			id: element.id || `element_${index}`,
		})

		mainLayerRef.current.add(rectNode)
	}

	const createCircleElement = (element, index) => {
		const circleNode = new Konva.Circle({
			x: element.position.x,
			y: element.position.y,
			radius: element.radius || 50,
			fill: currentPalette[element.fill] || element.fill,
			stroke: currentPalette[element.stroke] || element.stroke,
			strokeWidth: element.strokeWidth || 0,
			opacity: element.opacity || 1,
			draggable: false,
			name: `element_${index}`,
			id: element.id || `element_${index}`,
		})

		// Note: Circles are already centered by default in Konva
		// No need to adjust offsets

		mainLayerRef.current.add(circleNode)
	}

	const updateCompanyLogo = () => {
		if (!draftConfiguration?.showCompanyLogo) {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')
			if (companyLogo) {
				companyLogo.visible(false)
			}
		} else {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')
			if (companyLogo) {
				companyLogo.visible(true)
			}
		}

		if (companyDetails.usesBgRemovedLogo) {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')

			if (companyLogo) {
				const imageObj = new Image()
				let imageSource = companyDetails.companyLogoUrl

				if (
					companyDetails.companyLogoUrl.includes('s3.amazonaws.com') ||
					companyDetails.companyLogoUrl.includes('eu-central-1.amazonaws.com')
				) {
					const url = new URL(
						companyDetails.companyLogoUrl,
						window.location.origin,
					)

					imageSource =
						url.searchParams.size > 0
						? companyDetails.companyLogoUrl + `&konva=${Date.now()}`
						: companyDetails.companyLogoUrl + `?konva=${Date.now()}`
				}
					imageObj.src = imageSource
					imageObj.crossOrigin = 'Anonymous'
					imageObj.onload = () => {
					companyLogo.image(imageObj)
				}
					imageObj.onerror = error => {
					console.error('Error loading company logo', error)
				}
			}
		} else {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')

			if (companyLogo) {
				const index = templateConfig.elements.findIndex(
					element => element.id === 'companyLogo',
				)
				companyLogo.image(loadedImages[`element_${index}`])
			}
		}
	}

  return (
    <div
      id={templateConfig.containerId}
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
    />
  )
}

export default Thumbnail
