import React, { useState } from 'react'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { FaBars } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { useModal } from '../../../shared/hooks/modal-hook'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { adminActions } from '../../../store/admin'

import './DemoMeetingCardMenu.css'
import Modals from '../../../shared/enums/Modals'
import errorHandler from '../../../shared/util/errorHandler'

const DemoMeetingCardMenu = props => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const demoMeeting = props.demoMeeting

  const deleteThisDemoMeeting = async () => {
    const query = {}

    query['status'] = 'Deleted'

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/demo-meetings/updateDemoMeeting/${demoMeeting._id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      let newUserData = {
        ...demoMeeting,
        ...query,
      }

      dispatch(
        adminActions.findDemoMeetingByIdAndUpdate({
          id: demoMeeting._id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const areYouSureDeleteThisDemoMeeting = async () => {
    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: `Are you sure to delete "${demoMeeting.companyWebsite}" demo meeting?`,
    })
    
    if (confirmed) {
      deleteThisDemoMeeting()
    }
  }
  

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'left', 'left', 'bottom']}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            onClick={e => e.stopPropagation()}
            className="demo-meeting-card-menu"
          >
            <div className="wrapper">
              <div
                className="nav-button"
                onClick={() => {
                  setIsPopoverOpen(false)
                  areYouSureDeleteThisDemoMeeting()
                }}
              >
                Delete
              </div>
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="admin-panel-customer-card-menu-icon"
        onClick={e => {
          e.stopPropagation()
          setIsPopoverOpen(!isPopoverOpen)
        }}
      >
        <FaBars className='icon' title="Navigate" />
      </div>
    </Popover>
  )
}

export default DemoMeetingCardMenu
