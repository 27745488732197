import React from 'react';
import { FaEyeSlash, FaRedo, FaUndo } from 'react-icons/fa';

import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import Button from '../../../shared/components/FormElements/Button'

import styles from './PreviewToolbar.module.css'

const PreviewToolbar = () => {
    const { historyStep, history, handleUndo, handleRedo, handleCompareWithOriginal } = useEnhencedCatalog();

    return (
        <div className={styles.konvaPreviewActions} id="previewToolbar">
            <div className={styles.leftSide}>

                <Button
                    title="Undo"
                    className={styles.actionBtn}
                    disabled={historyStep === 0}
                    onClick={handleUndo}
                >
                    <FaUndo className={styles.icon} />
                    Undo
                </Button>
                <Button
                    title="Redo"
                    className={styles.actionBtn}
                    disabled={historyStep === history.length - 1}
                    onClick={handleRedo}
                >
                    <FaRedo className={styles.icon} />
                    Redo
                </Button>
            </div>
            <div className={styles.rightSide}>
                <Button
                    title="Compare"
                    className={styles.actionBtn}
                    onMouseEnter={() => { handleCompareWithOriginal(true) }}
                    onMouseLeave={() => { handleCompareWithOriginal(false) }}
                >
                    <FaEyeSlash className={styles.icon} />
                    Compare
                </Button>
            </div>
        </div>
    );
};

export default PreviewToolbar;
