import React, { useState } from 'react';
import { FaPlus, FaUser } from 'react-icons/fa';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import Tooltip from './Tooltip';
import './PeopleSelector.css';

const PeopleSelector = ({ attachedUserIds, managerUsers, onChange }) => {
  const [showUserListOverlay, setShowUserListOverlay] = useState(false);

  return (
    <div className="property form-control people-selector">
      <div className="property-desc">People:</div>
      <div className={`attached-users`}>
        {attachedUserIds?.map(userId => {
          if (!managerUsers[userId]?.image) {
            return (
              <div key={userId}>
                <Tooltip content={userId}>
                  <div 
                    className='attached-user default-user-avatar'
                    onClick={e => {
                      e.stopPropagation();
                      onChange(attachedUserIds.filter(id => id !== userId));
                    }}
                  >
                    <FaUser />
                  </div>
                </Tooltip>
              </div>
            );
          }
          const user = managerUsers[userId];
          return (
            <Tooltip key={userId} content={user?.name}>
              <div 
                className='attached-user'
                onClick={e => {
                  e.stopPropagation();
                  onChange(attachedUserIds.filter(id => id !== userId));
                }}
              >
                <img src={user.image} alt={user?.name} className="user-image" />
              </div>
            </Tooltip>
          );
        })}
      </div>
      <Popover
        isOpen={showUserListOverlay}
        positions={['bottom', 'left', 'left', 'bottom']}
        padding={10}
        onClickOutside={() => setShowUserListOverlay(false)}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={'#c5c5c5'}
            arrowSize={10}
            arrowStyle={{ opacity: 1 }}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="user-list-overlay">
              {Object.values(managerUsers)
                .filter(user => !attachedUserIds.includes(user._id))
                .map(user => (
                  <div 
                    key={user._id} 
                    className="user-list-item"
                    onClick={() => {
                      onChange([...attachedUserIds, user._id]);
                      setShowUserListOverlay(false);
                    }}
                  >
                    <div className="user-list-item-content">
                      {user.image ? (
                        <img src={user.image} alt={user.name} className="small-user-image" />
                      ) : (
                        <div className="small-user-avatar">
                          <FaUser />
                        </div>
                      )}
                      {user.name}
                    </div>
                  </div>
                ))}
            </div>
          </ArrowContainer>
        )}
      >
        <div className="add-user-button" onClick={() => setShowUserListOverlay(true)}>
          <FaPlus />
        </div>
      </Popover>
    </div>
  );
};

export default PeopleSelector;