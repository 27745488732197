import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import './CardAdminDescription.css'
import CollapsibleCard from '../../../shared/components/UIElements/CollapsibleCard'

function CardAdminDescription() {
  const customer = useSelector(state => state.admin.customer)
  const [description, setDescription] = useState(customer.crmDetails?.notes)


  useEffect(() => {
    setDescription(customer.crmDetails?.notes)
  }, [customer])

  if (!description) {
    return null
  }

  return (
    <CollapsibleCard title="Description" className="topaz description-card">
      <div className="input-row description-box">
        <p>{description}</p>
      </div>
    </CollapsibleCard>
  )
}

export default CardAdminDescription
