import { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../store/auth';
import { generalActions } from '../../store/general';

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const auth = useSelector(state => state.auth)

  const dispatch = useDispatch();

  const activeHttpRequests = useRef([]);
  let isMounted;
  const CACHE_NAME = 'enh-cache';

  let expirationTime = process.env.NODE_ENV === 'production' ? 5 * 60 * 1000 : 60 * 60 * 1000;

  const sendRequest = useCallback(
    async (url, method = 'GET', body = null, headers = {}, cachingEnabled = false, readFromCache = true) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const request = new Request(url, {
          method,
          body,
          headers,
        });

        if (cachingEnabled && readFromCache) {
          const cachedResponse = await getWithHeaders(request);
          if (cachedResponse) {
            console.log('read from cache');
            return cachedResponse.json();
          }
        }

        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal
        });

        const responseClone = response.clone();
        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          reqCtrl => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          throw responseData;
        }

        if (cachingEnabled) {
          await cacheWithHeaders(request, responseClone);
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        // TODO: Check do we really need the condition below
        if (err && (err.status === 401 || err.message?.includes("Token verification failed"))) {
          if (auth.token) {
            toast.warn("Your session has expired. Please log in again.");
            dispatch(authActions.logout({ isForced: true }));
            dispatch(generalActions.setPageTitle({ pageTitle: null }));
          }
        }

        if (isMounted) {
          setError(err);
          setIsLoading(false);
        }
        throw err;
      }
    },
    []
  );

  // Cache a resource with expiration metadata in headers
  const cacheWithHeaders = async (request, response) => {
    const cache = await caches.open(CACHE_NAME);

    const headers = new Headers(response.headers);
    headers.append('X-Expiration-Time', new Date(Date.now() + expirationTime).toISOString());

    const responseToCache = new Response(response.body, {
      status: response.status,
      statusText: response.statusText,
      headers: headers
    });

    await cache.put(request, responseToCache);
  }

  // Retrieve a resource and check expiration
  async function getWithHeaders(request) {
    const cache = await caches.open(CACHE_NAME);
    const cachedResponse = await cache.match(request);

    if (!cachedResponse) return null;

    const expirationTime = cachedResponse.headers.get('X-Expiration-Time');
    if (expirationTime && new Date() > new Date(expirationTime)) {
      // If expired, delete from cache
      await cache.delete(request);
      return null;
    }

    return cachedResponse; // Return the cached response
  }

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    isMounted = true;
    return () => {
      isMounted = false
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach(function (abortCtrl) {
        try {
          abortCtrl.abort();
        } catch (err) {
          console.log("err", err)
        }
      });
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
