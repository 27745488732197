import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import helper from '../../shared/util/helper'
import AdminUserDetailLayout from '../components/user-detail/AdminUserDetailLayout'
import AdminUserDetailName from '../components/user-detail/AdminUserDetailName'
import CardAdminAccount from '../components/user-detail/CardAdminAccount'
import CardAdminTeam from '../components/user-detail/CardAdminTeam'
import CardAdminSubscription from '../components/user-detail/CardAdminSubscription'
import CardAdminPayments from '../components/user-detail/CardAdminPayments'
import CardAdminAudienceNetwork from '../components/user-detail/CardAdminAudienceNetwork'

import CardAdminIntegration from '../components/user-detail/CardAdminIntegration'
import CardAdminNotes from '../components/user-detail/CardAdminNotes'
import CardAdminDescription from '../components/user-detail/CardAdminDescription'
import { useAdmin } from '../../shared/hooks/admin-hook'
import CardAdminAIAudienceModel from '../components/user-detail/CardAdminAIAudienceModel'
import CardAdminDevZone from '../components/user-detail/CardAdminDevZone'
import errorHandler from '../../shared/util/errorHandler'

const AdminUserDetail = () => {
  const dispatch = useDispatch()
  const { getOneSignUp, getOneCustomer, getProjectOfCustomer } = useAdmin()
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const { loginToUser } = useAdmin()
  const { pathname, search } = useLocation()
  const userId = pathname.split('/')[3]
  const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser) || false
  const accessDetails = useSelector(state => state.auth.user.accessDetails)
  const query = useMemo(() => new URLSearchParams(search), [search])
  const isSignUp = query.get('isSignUp') ?? false

  const [loading, setLoading] = useState(false)
  const { customers, signUps, teamInfo, lastCursorCustomers, panelFilters, signUpFilters } = useSelector(state => state.admin)
  const authToken = useSelector(state => state.auth.token)

  useEffect(() => {
    let users = isSignUp ? signUps : customers;

    const getUser = async () => {
      let foundCustomer = users?.find(c => c.id === userId)

      if (!foundCustomer) {
        setLoading(true)
        if (isSignUp) {
          const fetchedUser = await getOneSignUp({ userId })
          foundCustomer = fetchedUser
        } else {
          const fetchedUser = await getOneCustomer({ userId })
          foundCustomer = fetchedUser
        }

        if (!foundCustomer) {
          navigate('/')
          return
        } else if (query.get('goDirectlyToAIAds')) {
          loginToUser({ user: foundCustomer })
        } else {
          setLoading(false)
        }
      }

      dispatch(adminActions.setCustomer(foundCustomer))
      await getProjectOfCustomer(userId)
    }


    getUser()
  }, [pathname])


  // Get the teamInfo data if it does not exist in the Redux store.
  useEffect(() => {
    if (!helper.isEmptyObject(teamInfo)) {
      return
    }

    const getTeamInfo = async () => {
      try {
        const data = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getTeamInfo`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + authToken,
          },
        )

        dispatch(adminActions.setTeamInfo(data))
      } catch (err) {
        errorHandler(err)
        throw err;
      }
    }

    getTeamInfo()
  }, [teamInfo])

  return (
    <AdminUserDetailLayout loading={loading}>
      <AdminUserDetailLayout.Header>
        <AdminUserDetailName isSignUp={isSignUp} />
      </AdminUserDetailLayout.Header>

      <AdminUserDetailLayout.Main>
        <CardAdminAccount isSignup={isSignUp} />
        <CardAdminTeam isSignup={isSignUp} />
        {<CardAdminSubscription isSignUp={isSignUp} />}
        {!isSignUp && <CardAdminPayments />}
        {<CardAdminAudienceNetwork />}

        {isSpecialUser && accessDetails.permissions.includes("devZoneChanges") &&
          <React.Fragment>
            <CardAdminAIAudienceModel userId={userId} />
            <CardAdminDevZone userId={userId} />
          </React.Fragment>
        }
      </AdminUserDetailLayout.Main>

      <AdminUserDetailLayout.Side>
        {<CardAdminIntegration />}
        <CardAdminNotes isSignup={isSignUp} />
        <CardAdminDescription />
      </AdminUserDetailLayout.Side>
    </AdminUserDetailLayout>
  )
}

export default AdminUserDetail
