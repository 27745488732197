import React, { useEffect, useRef, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import { FaTimes, FaTrash } from 'react-icons/fa'
import Konva from 'konva'
import { WORKBENCH_SIZE } from '../../../ai-creative/support/constants'

import './ImageGenerator.css'
import { useEnhencedCatalog } from '../../hooks/enhenced-catalog-hook'
import { resizeToFit, resizeImageToFit } from '../../../ai-creative/support/utils'
import useGenerativeImages from '../../hooks/generative-images-hook'
import { useImageUpload } from '../../hooks/image-upload-hook'
import { useSelector } from 'react-redux'

const ImageGenerator = () => {
    const { closeModal, modalData } = useModal()
    const { generateImage } = useGenerativeImages()
    const { uploadImageToS3, uploadImageToFacebook } = useImageUpload()
    const { user } = useSelector(state => state.auth)
    const { backgroundImage, productImage, templateId, exampleId, onSave } = modalData;
    const [x, setX] = useState(100)
    const [y, setY] = useState(100)
    const [scalingFactor, setScalingFactor] = useState(1)
    const [resultingImageUrl, setResultingImageUrl] = useState(null)

    const stageRef = useRef(null);
    const backgroundLayerRef = useRef(null);
    const productImageLayerRef = useRef(null);
    const transformerLayerRef = useRef(null);

    const [isLoading, setIsLoading] = useState(false)

    const [previewImage, setPreviewImage] = useState(null);

    const [particles, setParticles] = useState([]);
    const particlesContainerRef = useRef(null);

    const [canvasChanged, setCanvasChanged] = useState(false);

    const [generationHistory, setGenerationHistory] = useState([]);
    const MAX_HISTORY = 5;

    const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);

    useEffect(() => {
        if (backgroundImage) {
            setPreviewImage(backgroundImage);
        }
    }, [backgroundImage]);

    useEffect(() => {
        createStage()
        window.addEventListener('resize', resizeStage)

        return () => {
            window.removeEventListener('resize', resizeStage)
            if (stageRef.current) {
                stageRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (stageRef.current) {
            stageRef.current.on('dragend', handleCanvasChange);
            stageRef.current.on('transformend', handleCanvasChange);
        }
        return () => {
            if (stageRef.current) {
                stageRef.current.off('dragend', handleCanvasChange);
                stageRef.current.off('transformend', handleCanvasChange);
            }
        };
    }, []);

    const handleCanvasChange = () => {
        setCanvasChanged(true);
    };

    const createStage = async () => {
        stageRef.current = new Konva.Stage({
            container: document.getElementById('generativePlayground'),
            width: WORKBENCH_SIZE.width,
            height: WORKBENCH_SIZE.height,
        });

        // Create layers
        backgroundLayerRef.current = new Konva.Layer();
        productImageLayerRef.current = new Konva.Layer();
        transformerLayerRef.current = new Konva.Layer()

        const bgImage = await getKonvaImage(backgroundImage)
        const prodImage = await getKonvaImage(productImage)

        console.log('Background Image:', bgImage);
        console.log('Product Image:', prodImage);

        try {
            await new Promise((resolve, reject) => {
                const img = prodImage.image();
                if (img.complete) {
                    console.log("image already loaded");
                    resolve();
                } else {
                    console.log("waiting for image load");
                    img.onload = () => {
                        console.log("image loaded");
                        resolve();
                    };
                    img.onerror = (error) => {
                        console.error("image load error");
                        reject(error);
                    };
                    // Add timeout in case image never loads
                    setTimeout(() => reject(new Error('Image load timeout')),10000);
                }
            });
        } catch (error) {
            console.error('Error loading image:', error);
        }

        console.log("after promise")
        // Resize product image to fit
        const { x, y, scaleX, scaleY } = resizeImageToFit({
            image: prodImage,
            maxWidth: 500,
            maxHeight: 500,
            center: true,
            shouldSetDirectly: false
        })

        console.log('Resized Product Image:', x, y, scaleX, scaleY);

        // Center the product image
        prodImage.scale({
            x: scaleX,
            y: scaleY
        });

        prodImage.position({
            x: 100,
            y: 100
        });

        prodImage.draggable(true); // Make the image draggable

        prodImage.on('dragstart', () => deactivateTransformers())
        prodImage.on('dragmove dragend', e => transformer.nodes([e.target]))
        prodImage.on('transformend dragend', (e) => {
            const x = e.target.x();
            const y = e.target.y();
            const scaleX = e.target.scaleX();
            const scaleY = e.target.scaleY();

            console.log("transformend dragend", x, y, scaleX, scaleY)
            setX(x)
            setY(y)
            setScalingFactor(scaleX)
        });

        backgroundLayerRef.current.add(bgImage)
        productImageLayerRef.current.add(prodImage)

        const transformer = new Konva.Transformer({
            name: 'Transformer',
            rotateEnabled: false,
            keepRatio: true,
            enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            boundBoxFunc: (oldBox, newBox) => {
                // Prevent scaling smaller than 10px or larger than stage
                const minSize = 10;
                const maxWidth = stageRef.current.width();
                const maxHeight = stageRef.current.height();

                if (
                    newBox.width < minSize ||
                    newBox.height < minSize ||
                    newBox.width > maxWidth ||
                    newBox.height > maxHeight
                ) {
                    return oldBox;
                }
                return newBox;
            }
        })
        transformer.nodes([prodImage]);

        transformerLayerRef.current.add(transformer)

        // Add layers to stage
        stageRef.current.add(backgroundLayerRef.current);
        stageRef.current.add(productImageLayerRef.current);
        stageRef.current.add(transformerLayerRef.current);

        // Force a redraw of all layers
        backgroundLayerRef.current.draw();
        productImageLayerRef.current.draw();
        transformerLayerRef.current.draw();

        stageRef.current.draw()

        resizeStage()
    }

    const getKonvaImage = (imageUrl) => {
        return new Promise((resolve) => {
            Konva.Image.fromURL(`${imageUrl}?forkonv=1`, (image) => {
                resolve(image)
            })
        })
    }

    const resizeStage = () => {
        if (stageRef.current) {
            console.log("resizing stage")
            // the constant numbers in the code below has a delicate balance for resizing the stage
            const container = document.getElementById('konvaContainerBox')
            const containerWidth = container.offsetWidth
            const containerHeight = container.offsetHeight

            console.log("containerWidth", containerWidth)
            console.log("containerHeight", containerHeight)

            let scale = 0.6
            if (containerWidth < 750 || containerHeight < 750) {
                let horizontalScale = containerWidth / WORKBENCH_SIZE.width
                let verticalScale = (containerHeight - 20) / WORKBENCH_SIZE.height
                scale = Math.min(horizontalScale, verticalScale)
            }

            console.log("scale", scale)

            const calculatedWidth = WORKBENCH_SIZE.width * scale
            const calculatedHeight = WORKBENCH_SIZE.height * scale

            stageRef.current.width(calculatedWidth)
            stageRef.current.height(calculatedHeight)
            stageRef.current.scale({ x: scale, y: scale })
            stageRef.current.draw()

            return {
                width: calculatedWidth,
                height: calculatedHeight,
            }
        }
    }

    const deactivateTransformers = () => {
        const transformer = transformerLayerRef.current.findOne('.Transformer')
        if (transformer) {
            transformer.nodes([])
        }
    }

    // Parçacık oluşturma fonksiyonu
    const createParticle = (x, y) => {
        const size = Math.random() * 3 + 1;
        const tx = (Math.random() - 0.5) * 200; // X ekseni hareketi
        const ty = (Math.random() - 0.5) * 200; // Y ekseni hareketi
        
        return {
            id: Math.random(),
            x,
            y,
            size,
            tx,
            ty,
            duration: Math.random() * 1000 + 500
        };
    };

    // Parçacık efektini başlatma
    const startParticleEffect = () => {
        if (!particlesContainerRef.current) return;
        
        const container = particlesContainerRef.current;
        const containerRect = container.getBoundingClientRect();
        const newParticles = [];

        for (let i = 0; i < 50; i++) {
            const x = Math.random() * containerRect.width;
            const y = Math.random() * containerRect.height;
            newParticles.push(createParticle(x, y));
        }

        setParticles(newParticles);
    };

    // Generate fonksiyonunu güncelle
    const generate = async (isRegenerate = false) => {
        try {
            setIsLoading(true);
            startParticleEffect();
            
            const imageUrl = await generateImage({
                scalingFactor,
                x,
                y,
                templateId,
                exampleId,
                isRegenerate
            });
            
            // Geçmiş güncellemesi - Yeni generate'i history'ye ekle
            setGenerationHistory(prev => {
                const newHistory = [...prev];
                // Eğer mevcut görsel varsa ve yeni bir generate ise history'ye ekle
                if (resultingImageUrl && !isRegenerate) {
                    if (newHistory.length >= MAX_HISTORY) {
                        newHistory.shift(); // En eski olanı çıkar
                    }
                    newHistory.push({
                        url: resultingImageUrl,
                        timestamp: Date.now() - 1 // Bir önceki generate
                    });
                }
                // Yeni generate'i ekle
                if (newHistory.length >= MAX_HISTORY) {
                    newHistory.shift();
                }
                newHistory.push({
                    url: imageUrl,
                    timestamp: Date.now()
                });
                return newHistory;
            });
            
            setResultingImageUrl(imageUrl);
            setSelectedHistoryItem(null);
            setCanvasChanged(false);
            
        } catch (error) {
            console.error('Generate error:', error);
        } finally {
            setIsLoading(false);
            setParticles([]);
        }
    };

    // History item'a tıklandığında
    const switchToHistoryImage = (imageUrl) => {
        if (imageUrl !== resultingImageUrl) {
            setResultingImageUrl(imageUrl);
            setSelectedHistoryItem(imageUrl);
            setCanvasChanged(false);
        }
    };

    // History container'ı için JSX
    const renderHistoryContainer = () => {
        if (generationHistory.length === 0) return null;

        return (
            <div className="history-container">
                {generationHistory.map((item, index) => (
                    <div 
                        key={item.timestamp}
                        className={`history-item ${resultingImageUrl === item.url ? 'active' : ''}`}
                        onClick={() => switchToHistoryImage(item.url)}
                        data-index={index + 1}
                        title={`Generated version ${index + 1}`}
                    >
                        <img 
                            src={item.url} 
                            alt={`Generation ${index + 1}`}
                            onError={(e) => {
                                console.error('Image load error:', e);
                                e.target.src = 'fallback-image-url';
                            }}
                        />
                    </div>
                ))}
            </div>
        );
    };

    // Save and Continue fonksiyonu
    const handleSaveAndContinue = async () => {
        try {
            if (resultingImageUrl) {
                // Dosya adını oluştur
                const fileName = `generated-image-${Date.now()}.png`;
                
                // S3'e yükle
                const s3ImageUrl = await uploadImageToS3({
                    file: resultingImageUrl,
                    folder: 'ai-creative',
                    fileName,
                    fileType: 'image/png'
                });

                if (s3ImageUrl) {
                    if (modalData.onSave) {
                        await modalData.onSave(s3ImageUrl);
                    }
                    closeModal();
                }
            }
        } catch (error) {
            console.error('Save error:', error);
        }
    };

    useEffect(() => {
        const modal = document.querySelector('.modal-container.IMAGE_GENERATOR');
        
        const handleMouseMove = (e) => {
            const rect = modal.getBoundingClientRect();
            const x = ((e.clientX - rect.left) / rect.width) * 100;
            const y = ((e.clientY - rect.top) / rect.height) * 100;
            
            modal.style.setProperty('--mouse-x', `${x}%`);
            modal.style.setProperty('--mouse-y', `${y}%`);
        };

        modal?.addEventListener('mousemove', handleMouseMove);
        
        return () => {
            modal?.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div className="image-generator-modal">
            <div className="modal-title">
                <h2>Generate Product Image</h2>
                <Button onClick={closeModal} textButton>
                    <FaTimes />
                </Button>
            </div>
            <div className="modal-middle">
                <div id="konvaContainerBox">
                    <div
                        id="generativePlayground"
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}
                    />
                </div>

                {isLoading ? (
                    <div className="right-container">
                        <div className="loading-wrapper">
                            <div 
                                className="loading-container"
                                style={{
                                    '--preview-image': `url(${backgroundImage})`
                                }}
                            >
                                <div className="loading-shine-effect" />
                                <div className="loading-progress-infinite" />
                                <div className="loading-content">
                                    <div className="loading-text">
                                        AI is creating your image...
                                    </div>
                                    <div className="loading-bars">
                                        <div className="loading-bar" />
                                        <div className="loading-bar" />
                                        <div className="loading-bar" />
                                        <div className="loading-bar" />
                                        <div className="loading-bar" />
                                    </div>
                                </div>
                            </div>
                            {generationHistory.length > 0 && renderHistoryContainer()}
                        </div>
                    </div>
                ) : resultingImageUrl ? (
                    <div className="right-container">
                        <div 
                            className="result-container"
                            style={{
                                '--preview-image': `url(${backgroundImage})`
                            }}
                        >
                            <div className='result-image'>
                                <img 
                                    src={resultingImageUrl} 
                                    alt="Generated Product Image"
                                    onLoad={(e) => {
                                        e.target.style.animation = 'fadeInScale 0.5s cubic-bezier(0.4, 0, 0.2, 1)';
                                    }}
                                />
                            </div>
                            {renderHistoryContainer()}
                        </div>
                        <div className="action-buttons-wrapper">
                            {canvasChanged && (
                                <Button 
                                    gradientBackground
                                    onClick={() => generate(true)}
                                    disabled={isLoading}
                                >
                                    Regenerate
                                </Button>
                            )}
                            <Button 
                                gradientBorder
                                onClick={handleSaveAndContinue}
                                disabled={isLoading}
                            >
                                Save and Continue ✨
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="right-container">
                        <div className="loading-container">
                            <div className="loading-text">
                                Click the "Generate" button to create the image
                            </div>
                            <div className="generate-button-overlay">
                                <Button 
                                    gradientBackground
                                    onClick={generate}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Generating...' : 'Generate'}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}


export default ImageGenerator;
