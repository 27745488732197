import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { FaArrowRight, FaCheckCircle, FaTimes } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { useNavigate, useLocation } from 'react-router-dom'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import analytics from '../../shared/util/analytics'
import { generalActions } from '../../store/general'
import Button from '../../shared/components/FormElements/Button'
import { useShopify } from '../../shared/hooks/shopify-hook'

import SubscriptionPlanInfoCard from '../components/SubscriptionPlanInfoCard'

const ShopifyPaymentCompleted = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let location = useLocation()
  const { sendRequest } = useHttpClient()

  const authToken = useSelector(state => state.auth.token)
  const userId = useSelector(state => state.auth.user.id)
  const userSubscription = useSelector(state => state.auth.user.subscription)
  const userShopify = useSelector(state => state.auth.user.shopify)
  const userEmail = useSelector(state => state.auth.user.email)
  const authHeader = {
    Authorization: 'Bearer ' + authToken,
    'Content-Type': 'application/json',
  }

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle: 'Payment Successful' }))
  }, [])

  const { connectShopifyStore } = useShopify()
  const [processing, setProcessing] = useState(true)
  const [connectingToStore, setConnectingToStore] = useState(false)
  const [paymentError, setPaymentError] = useState(false)
  const [connectionError, setConnectionError] = useState(false)

  useEffect(() => {
    let isMounted = true
    const query = new URLSearchParams(location.search)
    const completeSubscription = async () => {
      let chargeID = query.get('charge_id')
      if (!isMounted) return
      
      if (chargeID) {
        try {
          let result = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/shopify/subscription?chargeID=${chargeID}&accessToken=${userShopify.accessToken}&shopDomain=${userShopify.domain}`,
            'GET',
            null,
            authHeader,
          )
          let charge = result.recurring_application_charge
          if (charge.status === 'active') {
            try {
              const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/paymentCompleted`,
                'POST',
                JSON.stringify({
                  userId,
                  chargeID: chargeID,
                  charge: charge,
                }),
                authHeader,
              )


              if (response && response.success) {
                analytics.completeSubscription({
                  value: charge.price,
                  currency: 'USD',
                  subscriptionId: chargeID,
                  subscriptionPlanName: charge.name,
                  userId,
                  userEmail,
                })
                setProcessing(false)
                setConnectingToStore(true)
                dispatch(
                  authActions.updateUserObjOneField({
                    field: "subscription",
                    value: response.subscriptionDetails,
                  }),
                )
                
                connectShopifyStore().then(_ => {
                  setTimeout(() => {
                    setConnectingToStore(false)
                    navigate("/subscription-completed/success");
                    
                  }, 2000)
                })
              } else {
                toast.warn(response.message || 'Something went wrong')
                setProcessing(false)
                setPaymentError(true)
              }
            } catch (err) {
              toast.warn(err?.message || 'Something went wrong')
              setConnectingToStore(false)
              setPaymentError(
                'Error occured after the payment completed. Please, contact us.',
              )
            }
          } else {
            setPaymentError('Payment could not be completed.')
            navigate('/account/subscription')
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        setPaymentError('Payment could not be completed.')
        navigate('/account/subscription')
      }
    }

    let isUpdate = query.get('isUpdate') && parseInt(query.get('isUpdate'))
    if (
      userSubscription &&
      userSubscription.status === 'active' &&
      !isUpdate
    ) {
      setTimeout(() => {
        navigate('/account/subscription')
        }, 1000);
      } else {
      console.log("complete it ", userSubscription)
      completeSubscription()
    }

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="payment-completed">
      <div className="subscription-success">
        {processing && (
          <div className="row">
            <LoadingSpinner />
            <span>Starting subscription...</span>
          </div>
        )}
        {connectingToStore && (
          <React.Fragment>
            <div className="row">
              {paymentError ? (
                <React.Fragment>
                  <FaTimes className="icon red" />
                  <span>
                    Subscription failed. Try again later or contact us.
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FaCheckCircle className="icon" />
                  <span>Subscription started</span>
                </React.Fragment>
              )}
            </div>

            <div className="row">
              <LoadingSpinner />
              <span>Establishing data connection to your store...</span>
            </div>
          </React.Fragment>
        )}
        {!connectingToStore && !processing && (
          <React.Fragment>
            <div className="row">
              <FaCheckCircle className="icon" />
              <span>Subscription started</span>
            </div>

            <div className="row">
              {connectionError ? (
                <React.Fragment>
                  <FaTimes className="icon red" />
                  <span>Data connection error, please contact us.</span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FaCheckCircle className="icon" />
                  <span>Data connection established</span>
                </React.Fragment>
              )}
            </div>

          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default ShopifyPaymentCompleted
