import { useEffect } from 'react'

/**
 * A custom hook to show a warning before leaving the page if the condition is true
 * @param {boolean} condition
 */
export const useUnsavedChangesWarning = condition => {
  useEffect(() => {
    const handleBeforeUnload = e => {
      if (condition) {
        e.preventDefault()
        e.returnValue = true
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [condition])
}
