import React, { useMemo, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import CustomDatePicker from '../UIElements/CustomDatePicker'

import "./NewDemoMeeting.css"
import Input from '../FormElements/Input'
import countriesData from "../../../shared/data/countries.json";
import { FaTimes } from 'react-icons/fa'
import { useForm } from '../../hooks/form-hook'
import { VALIDATOR_DOMAIN, VALIDATOR_EMAIL, VALIDATOR_REQUIRE } from '../../util/validators'

const NewDemoMeeting = props => {
  const { closeModal, modalData } = useModal()
  const now = new Date;

  let [newMeetingDate, setNewMeetingDate] = useState(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0))
  const [timeHour, setTimeHour] = useState(0);
  const [timeMinute, setTimeMinute] = useState(0);

  const countries = useMemo(
    () => countriesData.map(c => c.name),
    [countriesData],
  )

  const [userInfoFormState, userInfoInputHandler, setUserInfoFormData] = useForm(
    {
      name: {
        value: "",
        isValid: true
      },
      email: {
        value: "",
        isValid: true
      },
      website: {
        value: "",
        isValid: true
      },
      contactNumber: {
        value: "",
        isValid: true
      },
      country: {
        value: "",
        isValid: true
      },
      ourNote: {
        value: "",
        isValid: true
      }
    },
    true
  );

  const create = () => {
    if (userInfoFormState.isValid) {
      modalData.callback({ ...userInfoFormState.inputs, meetingStarts: { value: newMeetingDate, isValid: true } })
      closeModal()
    }
  }

  const close = () => {
    closeModal()
  }

  // Generating hours from 00:00 to 23:45
  const hours = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      const hour = (i < 10 ? '0' : '') + i + ':' + (j < 10 ? '0' : '') + j;
      hours.push(hour);
    }
  }

  function convertToHourFormat(hourInteger) {
    const hour = (hourInteger < 10 ? '0' : '') + hourInteger;
    return hour;
  }

  // Function to handle change in end hour
  const handleTimeHourChange = (e) => {
    const [hourStr, minuteStr] = e.target.value.split(":");
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);

    setTimeHour(hour);
    setTimeMinute(minute);
    let oldDueDate = new Date(newMeetingDate.getFullYear(), newMeetingDate.getMonth(), newMeetingDate.getDate(), 0, 0, 0); // Reset hours and minutes
    const newDueDate = new Date(oldDueDate.getTime() + (hour * 60 + minute) * 60 * 1000);
    setNewMeetingDate(newDueDate);
  };


  return (
    <div className="alarm-modal new-meeting-modal">
      <div className="modal-title">
        <div className='customer-name'>New Meeting</div>
        <Button size="small" onClick={close}><FaTimes /></Button>
      </div>
      <div className="modal-middle">
        <div className='property'>
          <div className='property-desc'>Customer name:</div>
          <Input element="input" placeholder="Enhencer" id="name" type="text" onInput={userInfoInputHandler} />
        </div>
        <div className='property'>
          <div className='property-desc'>E-mail:</div>
          <Input element="input" placeholder="hello@enhencer.com" id="email" type="text" onInput={userInfoInputHandler} validators={[VALIDATOR_EMAIL()]} errorText="Please enter a valid email address" />
        </div>
        <div className='property'>
          <div className='property-desc'>Website:</div>
          <Input element="input" placeholder="www.enhencer.com" id="website" type="text" onInput={userInfoInputHandler} validators={[VALIDATOR_DOMAIN(), VALIDATOR_REQUIRE()]} errorText="Please enter a valid website" />
        </div>
        <div className='property'>
          <div className='property-desc'>Phone number:</div>
          <Input element="input" id="contactNumber" type="text" onInput={userInfoInputHandler} />
        </div>
        <div className='property'>
          <div className='property-desc'>Country:</div>
          <Input element="dropdown" initialValue={"United States"} id="country" type="text" onInput={userInfoInputHandler} options={countries} />
        </div>
        <div className='property'>
          <div className='property-desc'>Meeting Date:</div>
          <div className='due-date-selector form-control'>
            <div>
              <CustomDatePicker
                placeholder="DD/MM/YYYY"
                date={newMeetingDate ?? new Date()}
                onChange={d => {
                  const dateAtMidnight = new Date(d.getFullYear(), d.getMonth(), d.getDate())
                  const newDueDate = new Date(dateAtMidnight.getTime() + (timeHour * 60 + timeMinute) * 60 * 1000);
                  setNewMeetingDate(newDueDate)
                }}
              />
            </div>
            <div>-</div>
            <div>
              <select className='selectable' id="endHour"
                value={`${convertToHourFormat(timeHour)}:${convertToHourFormat(timeMinute)}`}
                onChange={handleTimeHourChange}>
                {hours.map((hour, index) => (
                  <option key={index} value={hour}>{hour}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='property'>
          <div className='property-desc'>Note:</div>
          <Input element="input" placeholder="Add a note..." id="ourNote" type="text" onInput={userInfoInputHandler} />
        </div>
      </div>
      <div className="modal-footer">
        <Button size="small" className="action-button update" onClick={() => {
          create()
        }}>
          Create
        </Button>
      </div>
    </div>
  )
}


export default NewDemoMeeting
