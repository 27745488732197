import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import helper from '../../../shared/util/helper'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import SignUpCard from './SignUpCard'
import { adminActions } from '../../../store/admin'

import './SignUpsList.css'

const SignUpsList = (props) => {
  const { lastCursorSignUps, signUps, navigatedFromCustomerID } =
    useSelector(state => state.admin)
  const dispatch = useDispatch()

  const ref = useRef()
  const [isFetching, setIsFetching] = useState(props.isFetching)
  const bottomDetector = () => {
    if (helper.isElementInViewport(ref.current) && !isFetching) {
      setIsFetching(true)
      if (lastCursorSignUps) {
        props.reFetch()
      } else {
        setIsFetching(false)
      }
    }
  }

  useEffect(() => {
    if (navigatedFromCustomerID) {
      const signUpCard = document.querySelector("[data-id='" + navigatedFromCustomerID + "']")
      if (signUpCard) {
        signUpCard.scrollIntoView({ behavior: "smooth" })
        // block: "end"
        //görevini yerine getirdi kaldırıyoruz
        dispatch(adminActions.setNavigatedFromCustomerID(undefined))
      }
    }
  }, [])

  useEffect(() => {
    setIsFetching(props.isFetching)
  }, [props.isFetching])

  useEffect(() => {
    if (!lastCursorSignUps && signUps.length) {
      ref.current.style.display = 'none'
    } else {
      ref.current.style.display = 'flex'
    }
  }, [lastCursorSignUps])
  return (
    <div className="sign-ups-list-wrapper" onScroll={bottomDetector}>
      <div className="sign-ups-list">
        {signUps.map(signUp => (
          <SignUpCard key={signUp.id} signUp={signUp} />
        ))}
      </div>
      <div className="scroll-ending-box" ref={ref}>
        {isFetching && <InlineLoadingSpinner message="Fetching..." />}
      </div>
    </div>
  )
}

export default SignUpsList
