import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp7bg from '../../../../shared/assets/images/ai-creative/common_temp7bg.svg'
import common_temp7fg from '../../../../shared/assets/images/ai-creative/common_temp7fg.svg'

export const Template7Config = {
  name: 'Furniture #1',
  containerId: 'template7-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 40,
        y: 43
      },
      scale: {
        width: WORKBENCH_SIZE.width - 80,
        height: WORKBENCH_SIZE.height * 0.695
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp7bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#D96F2F',
      draggable: false,
      clickThrough: true
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp7fg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#D96F2F',
      draggable: false,
      clickThrough: true
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width - 210,
        y: WORKBENCH_SIZE.height - 450
      },
      scale: {
        maxWidth: 300,
        maxHeight: 140
      },
      dynamicSrc: 'companyLogo',
      required: false
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height - 250
      },
      width: 650,
      height: 130,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 60,
      fontStyle: 'bold',
      color: 'color3',
      text: '{{productName}}',
      fontIndex: 1
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: 40,
        y: WORKBENCH_SIZE.height - 120
      },
      width: 300,
      height: 100,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 50,
      fontStyle: 'bold',
      color: 'color3',
      center: true,
      verticalAlign: 'middle',
      text: '{{productPrice}}',
      fontIndex: 2
    }
  ]
}