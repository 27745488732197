import React, { useState } from 'react'
import Button from '../FormElements/Button'
import './CreateFullFunnelFromExistingCampaigns.css'
import { useModal } from '../../hooks/modal-hook'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import errorHandler from '../../util/errorHandler'
import { useFullFunnel } from '../../hooks/facebook/full-funnel-hook'

const CreateFullFunnelFromExistingCampaigns = ({ }) => {
  const { closeModal, modalData } = useModal()
  const { addNewFullFunnelFromExistingCampaigns } = useFullFunnel()
  const [adCampaigns, setAdCampaigns] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [label, setLabel] = useState('')

  const handleCreate = async () => {
    setIsLoading(true)
    try {

      let result = await addNewFullFunnelFromExistingCampaigns({
        label,
        campaignIds: adCampaigns.map(c => c.id),
      })

      toast.success('Full funnel created successfully, refresh the page')
      closeModal()
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAddCampaign = () => {
    setAdCampaigns([...adCampaigns, {
      name: 'New Campaign',
      id: 'new-campaign',
    }])
  }

  const handleRemoveCampaign = (id) => {
    setAdCampaigns(adCampaigns.filter(c => c.id !== id))
  }

  return (
    <div className="create-full-funnel-from-existing-campaigns">
      <div className="content">
        <div className="header">
          <div className="header-left">
            <div className="title-container">
              <h2>Add new Full Funnel</h2>
            </div>
          </div>
        </div>

        <div className='row'>
          <div>
            Label
          </div>
          <div>
            <input
              type="text"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
        </div>

        <div className="ad-campaigns-container">
          {
            adCampaigns.map((adCampaign, index) => (
              <div className="ad-campaign-row row" key={`ad-campaign-${index}`}>
                <label>Campaign ID</label>
                <input
                  type="text"
                  value={adCampaign.id}
                  onChange={(e) => setAdCampaigns(adCampaigns.map(c => c.id === adCampaign.id ? { ...c, id: e.target.value } : c))}
                />
                <Button size="small" className="remove-campaign-button" onClick={() => handleRemoveCampaign(adCampaign.id)}>
                  <FaTrash />
                </Button>
              </div>
            ))
          }

          <Button size="small" className="add-campaign-button" onClick={handleAddCampaign}>
            <FaPlus /> Add Campaign
          </Button>
        </div>


      </div>

      <div className="footer">

        <Button className="cancel-button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={isLoading}
          loadingText="Creating full funnel..."
          primary
          className="confirm-button"
          onClick={handleCreate}
        >
          Create Full-Funnel
        </Button>
      </div>
    </div>
  )
}

export default CreateFullFunnelFromExistingCampaigns
