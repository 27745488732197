import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { FaUser, FaSignOutAlt, FaScroll, FaEnvelope } from 'react-icons/fa'
import './NavbarProfileMenu.css'

const NavbarProfileMenu = ({
  isExpanded,
  logout,
  isLocked,
  setIsProfileMenuOpen,
  setIsMobileMenuOpen,
  setNavbarExpanded,
  isMobile
}) => {
  const [isProfileExpanded, setIsProfileExpanded] = useState(false)
  const location = useLocation()
  const user = useSelector(state => state.auth.user)
  const { hasUnreadMessages } = useSelector(state => state.supportTickets)
  const profileMenuRef = useRef()
  const profileContRef = useRef()

  const openMenu = () => {
    if (isExpanded) {
      setIsProfileExpanded(true)
      setIsProfileMenuOpen(true)
    }
  }

  const handleClick = e => {
    e.preventDefault()
    if (isMobile) {
      e.stopPropagation()
      setIsProfileExpanded(!isProfileExpanded)
      setIsProfileMenuOpen(!isProfileExpanded)
    } else {
      if (isExpanded) {
        const newExpandedState = !isProfileExpanded
        setIsProfileExpanded(newExpandedState)
        setIsProfileMenuOpen(newExpandedState)
      }
    }
  }

  const handleMouseEnter = () => {
    if (isLocked) {
      openMenu()
    }
  }

  const handleMouseLeave = () => {
    if (isLocked && !isProfileExpanded) {
      setIsProfileMenuOpen(false)
    }
  }

  const handleLinkClick = (e) => {
    if (isMobile) {
      e.stopPropagation()
      setIsProfileExpanded(false)
      setIsProfileMenuOpen(false)
      setIsMobileMenuOpen(false)
      setNavbarExpanded(false)
    }
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (event.target.closest('.navbar-account-manager-card')) {
        return
      }

      if (
        isProfileExpanded &&
        profileContRef.current &&
        !profileContRef.current.contains(event.target)
      ) {
        setIsProfileExpanded(false)
        setIsProfileMenuOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isProfileExpanded, setIsProfileMenuOpen])

  return (
    <div
      className="navbar-profile-menu-cont"
      ref={profileContRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`nav-item ${isProfileExpanded ? 'menu-open' : ''}`}
        onClick={handleClick}
      >
        <div className="nav-item-content">
          <div className="nav-item-left">
            <div className="profile-letter">{user.email[0].toUpperCase()}</div>
            {isExpanded && (
              <span>
                {user.name && <div className="user-name">{user.name}</div>}
                <div className="user-email">{user.email}</div>
              </span>
            )}
          </div>
        </div>
      </div>
      {isExpanded && isProfileExpanded && (
        <div className="navbar-profile-menu open" ref={profileMenuRef}>
          <div className="menu-list">
            <Link
              className={`menu-item ${location.pathname.includes('account/info') ? 'active' : ''}`}
              to="/account/info"
              onClick={isMobile ? handleLinkClick : undefined}
            >
              <FaUser className="icon" />
              Account
            </Link>

            {!user.isSpecialUser && (
              <>
                <Link
                  className={`menu-item ${location.pathname.includes('account/tickets') ? 'active' : ''}`}
                  to={
                    hasUnreadMessages
                      ? '/account/tickets?filter=unread_messages'
                      : '/account/tickets'
                  }
                  onClick={isMobile ? handleLinkClick : undefined}
                >
                  {hasUnreadMessages ? (
                    <div className="icon-container">
                      <FaEnvelope className="icon" />
                      <div className="badge" />
                    </div>
                  ) : (
                    <FaEnvelope className="icon" />
                  )}
                  Support Tickets
                </Link>

                <Link
                  className={`menu-item ${location.pathname.includes('account/subscription') ? 'active' : ''}`}
                  to="/account/subscription"
                  onClick={isMobile ? handleLinkClick : undefined}
                >
                  <FaScroll className="icon" />
                  Subscription
                </Link>
              </>
            )}
            {!(sessionStorage.getItem('viewingAsUser') && JSON.parse(sessionStorage.getItem('viewingAsUser')).userMail === user.email) && (
              <div
                className="menu-item"
                onClick={(e) => {
                  if (isMobile) {
                    handleLinkClick(e)
                  }
                  logout()
                }}
              >
                <FaSignOutAlt className="icon" /> Log Out
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default NavbarProfileMenu