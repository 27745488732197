import { WORKBENCH_SIZE } from '../../../support/constants'
import temp4bg from '../../../../shared/assets/images/ai-creative/temp4bg.svg'

export const Template4Config = {
  name: 'Dynamic Edge',
  containerId: 'template4-carousel-single',
  backgroundColor: 'color2',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: -106,
        y: -73,
      },
      scale: {
        width: WORKBENCH_SIZE.width + 409,
        height: WORKBENCH_SIZE.height + 673,
      },
      src: temp4bg,
      draggable: false,
      isSvg: true,
      svgColor: 'color1',
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: 45,
        y: 45,
      },
      scale: {
        maxWidth: 250,
        maxHeight: 140,
      },
      dynamicSrc: 'companyLogo',
      required: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: (WORKBENCH_SIZE.width / 2) * 1.3,
        y: (WORKBENCH_SIZE.height / 2) * 1.1,
      },
      scale: {
        maxWidth: 650,
        maxHeight: 750,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 45,
        y: 270,
      },
      width: 360,
      height: 100,
      fontIndex: 1,
      fontSize: 36,
      color: 'color3',
      text: '{{productName}}',
    },
    // Product Price Circle
    {
      id: 'productPriceCircle',
      type: 'circle',
      position: {
        x: 45 + 108,
        y: 550,
      },
      radius: 108,
      fill: 'color3',
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: 150 - 108,
        y: 550 - 200 / 2,
      },
      width: 216,
      height: 200,
      fontSize: 40,
      fontIndex: 2,
      color: 'color2',
      circle: true,
      circleColor: 'color3',
      align: 'center',
      verticalAlign: 'middle',
      text: '{{productPrice}}',
    },
  ],
}
