import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useModal } from '../../hooks/modal-hook'
import { useHttpClient } from '../../hooks/http-hook'
import VerificationInput from '../UIElements/VerificationInput'
import { authActions } from '../../../store/auth'

import './SMSVerification.css'
import errorHandler from '../../util/errorHandler'

const SMSVerification = props => {
  const dispatch = useDispatch()
  const { modalData, closeModal } = useModal()
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const [code, setCode] = useState('')
  const [showResendCodeText, setShowResendCodeText] = useState(false)
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    // If the user started to enter the code
    // we don't show the "Send the code again" text.
    if (code.length > 0) {
      setShowResendCodeText(false)
      return
    }

    let timer = setTimeout(() => {
      setShowResendCodeText(true)
    }, 7000)

    return () => {
      setShowResendCodeText(false)
      clearTimeout(timer)
    }
  }, [code])

  useEffect(() => {
    sendCodeViaSMS()
  }, [])

  const sendCodeViaSMS = async () => {
    try {
      const { contactNumber, contactNumberCountryCode } = modalData

      const body = { contactNumber, contactNumberCountryCode }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/sms-verification/generate`,
        'POST',
        JSON.stringify(body),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
    } catch (err) {
      errorHandler(err)
      closeModal()
    }
  }

  const handleVerify = async e => {
    try {
      e.preventDefault()
      setBusy(true)

      const { contactNumber, contactNumberCountryCode } = modalData

      const body = { contactNumber, contactNumberCountryCode, code }
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/sms-verification/verify`,
        'POST',
        JSON.stringify(body),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        authActions.updateUserObj({
          ...auth.user,
          contactNumber: response.contactNumber,
          contactNumberCountryCode: response.contactNumberCountryCode,
          contactNumberVerifiedAt: response.contactNumberVerifiedAt,
          crmDetails: response.crmDetails,
        }),
      )

      toast.success('Your phone number has been successfully verified')
      closeModal()
    } catch (err) {
      errorHandler(err)
    } finally {
      setBusy(false)
    }
  }

  const handleResendCode = async () => {
    try {
      await sendCodeViaSMS()
      toast.success('We sent the verification code again!')
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <div className="sms-verification">
      <h3>
        We sent a verification code to{' '}
        {modalData.contactNumberCountryCode + modalData.contactNumber}
      </h3>
      <form className="verify-form" onSubmit={handleVerify}>
        <p>Please enter the code you received</p>
        <VerificationInput onChange={code => setCode(code)} />
        <button className="verify-button" disabled={busy || code.length !== 6}>
          {busy ? 'Please wait' : 'Verify'}
        </button>
        {showResendCodeText && (
          <span onClick={handleResendCode} className="resend-code">
            Send the code again
          </span>
        )}
      </form>
    </div>
  )
}

export default SMSVerification
