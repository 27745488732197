import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaUndo } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useHttpClient } from '../../hooks/http-hook'

import './AdsetBudgetsConfig.css'
import Button from '../FormElements/Button'
import helper from '../../util/helper'
import { useModal } from '../../hooks/modal-hook'
import errorHandler from '../../util/errorHandler'

const AdsetBudgetsConfig = props => {
  const { sendRequest, isLoading } = useHttpClient()
  const adAccountCurrency = useSelector(state => state.facebook.selectedAdAccount.currency)
  const fullFunnelId = useSelector(state => state.facebook.fullFunnelInViewId)
  const adminToken = useSelector(state => state.auth.adminToken)
  const userId = useSelector(state => state.auth.user.id)
  const { adsetRows, objective } = props.data
  const { closeModal, modalData } = useModal()

  // State for budget values
  const [budgetValues, setBudgetValues] = useState({});
  // Store original budgets for comparison
  const [originalBudgets, setOriginalBudgets] = useState({});
  // Track which budgets have been changed
  const [changedBudgets, setChangedBudgets] = useState({});
  // Track the current total budget
  const [totalBudget, setTotalBudget] = useState(0);
  // Store the original total budget
  const [originalTotalBudget, setOriginalTotalBudget] = useState(0);

  // Initialize budgets on component mount
  useEffect(() => {
    // Create initial budget values
    const initialBudgets = {};
    const origBudgets = {};

    adsetRows.forEach(adset => {
      initialBudgets[adset.name] = adset.budget;
      origBudgets[adset.name] = adset.budget;
    });

    setBudgetValues(initialBudgets);
    setOriginalBudgets(origBudgets);

    // Calculate initial total budget
    const initialTotal = adsetRows.reduce((acc, adset) => acc + parseFloat(adset.budget || 0), 0);
    setTotalBudget(initialTotal);
    setOriginalTotalBudget(initialTotal);
  }, [adsetRows]);

  // Handle input change
  const handleInputChange = (adsetName, value) => {
    // Update budget values
    setBudgetValues(prev => ({
      ...prev,
      [adsetName]: value
    }));

    // Check if the budget has changed from original
    if (value !== originalBudgets[adsetName]) {
      setChangedBudgets(prev => ({
        ...prev,
        [adsetName]: true
      }));
    } else {
      // If the value is back to original, remove from changed budgets
      setChangedBudgets(prev => {
        const updated = { ...prev };
        delete updated[adsetName];
        return updated;
      });
    }

    // Calculate new total budget
    calculateTotalBudget(adsetName, value);
  };

  // Handle undo button click
  const handleUndo = (adsetName) => {
    // Revert to original value
    const originalValue = originalBudgets[adsetName];

    // Update budget values
    setBudgetValues(prev => ({
      ...prev,
      [adsetName]: originalValue
    }));

    // Remove from changed budgets
    setChangedBudgets(prev => {
      const updated = { ...prev };
      delete updated[adsetName];
      return updated;
    });

    // Recalculate total budget
    calculateTotalBudget(adsetName, originalValue);
  };

  // Calculate total budget
  const calculateTotalBudget = (changedAdsetName, newValue) => {
    const newTotal = adsetRows.reduce((acc, adset) => {
      // Use the new value for the changed adset, otherwise use the current value
      const budgetValue = adset.name === changedAdsetName
        ? parseFloat(newValue || 0)
        : parseFloat(budgetValues[adset.name] || 0);

      return acc + (isNaN(budgetValue) ? 0 : budgetValue);
    }, 0);

    setTotalBudget(newTotal);
  };

  // Calculate the difference between current and original total budget
  const getTotalBudgetDifference = () => {
    const difference = totalBudget - originalTotalBudget;
    const isPositive = difference >= 0;

    return {
      value: Math.abs(difference).toFixed(2),
      isPositive
    };
  };

  // Handle save
  const handleSave = async () => {
    // Get the list of changed adsets
    const changedAdsetNames = Object.keys(changedBudgets);

    // Create an array of changed adsets with their details
    const changedAdsetsData = changedAdsetNames.map(adsetName => {
      // Find the adset object by name to get its ID
      const adsetObj = adsetRows.find(adset => adset.name === adsetName);

      return {
        name: adsetName,
        id: adsetObj.id,
        campaignId: adsetObj.campaignId,
        budget: parseFloat(budgetValues[adsetName])
      };
    });

    try {
      let response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateAdsetBudgets`,
        'POST',
        JSON.stringify({
          userId,
          fullFunnelId,
          changedAdsets: changedAdsetsData,
          newTotalBudget: totalBudget,
          isBoostCampaigns: objective === "Boost Campaigns"
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${adminToken}`
        }
      );

      if (response && response.updatedAdCampaigns) {
        toast.success("Adset budgets updated successfully");
        modalData.resolve({
          updatedAdCampaigns: response.updatedAdCampaigns
        });
      } else {
        console.error("Invalid response format:", response);
        errorHandler({message: "Received invalid response from server"});
        modalData.resolve(null);
      }
    } catch (error) {
      console.error("Error updating adset budgets:", error);
      errorHandler({message: "Failed to update adset budgets"});
      modalData.resolve(null);
    } finally {
      closeModal();
    }
  };

  // Check if total budget has changed
  const hasTotalBudgetChanged = totalBudget !== originalTotalBudget;
  // Get budget difference info
  const budgetDifference = getTotalBudgetDifference();

  return (
    <div className="adset-budgets-config">
      <React.Fragment>
        <h3>
          Budgets of Adsets
        </h3>

        <div className="budget-rows-container">
          {adsetRows.map(a => (
            <div key={a.id} className='row budget-row'>
              <div className="adset-name">{a.name.replace("Enhencer ", "")}</div>
              <div className='audience-checkbox-container'>
                <div className="daily-budget-wrapper">
                  <span className="currency-symbol">
                    {helper.getCurrencySymbol(adAccountCurrency)}
                  </span>
                  <input
                    type="number"
                    className="budget-input"
                    value={budgetValues[a.name] || ''}
                    onChange={(e) => handleInputChange(a.name, e.target.value)}
                    min="0"
                    step="1"
                  />
                </div>
                {changedBudgets[a.name] && (
                  <>
                    <button
                      className="undo-button"
                      onClick={() => handleUndo(a.name)}
                      title="Revert to original value"
                    >
                      <FaUndo />
                    </button>
                    <div className="original-budget">
                      (was: {helper.getCurrencySymbol(adAccountCurrency)}{originalBudgets[a.name]})
                    </div>
                  
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="sticky-footer">
          <div className='total-budget-row'>
            <div>
              <b>Total Budget</b>
            </div>
            <div className="total-budget-container">
              <b>
                {helper.getCurrencySymbol(adAccountCurrency)} {totalBudget.toFixed(2)}
              </b>
              {hasTotalBudgetChanged && (
                <div className={`total-budget-difference ${budgetDifference.isPositive ? 'positive' : 'negative'}`}>
                  (was: {helper.getCurrencySymbol(adAccountCurrency)} {originalTotalBudget.toFixed(2)},
                  {budgetDifference.isPositive ? ' + ' : ' - '}{helper.getCurrencySymbol(adAccountCurrency)}{budgetDifference.value})
                </div>
              )}
            </div>
          </div>

          <div className='modal-footer'>
            <Button className="action-button update"
              onClick={handleSave}
              loadingText="Saving"
              disabled={Object.keys(changedBudgets).length === 0}
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

export default AdsetBudgetsConfig
