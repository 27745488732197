import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { authActions } from '../../store/auth'
import { useHttpClient } from './http-hook'
import { generalActions } from '../../store/general'
import helper from '../util/helper'
import { useAdmin } from './admin-hook'
import analytics from '../util/analytics'
import { facebookActions } from '../../store/facebook'
import errorHandler from '../util/errorHandler'
let logoutTimer

export const useAuth = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const { getPerformanceManagerProfileForUser } = useAdmin()

  const storedData = JSON.parse(localStorage.getItem('userData') || '[]') || []
  const lastLoggedInAccount =
    storedData.find(user => user.lastLoggedInAccount === true) || {}

  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser')) || {}

  const authTokenInStorage = viewingAsUser?.userToken || lastLoggedInAccount?.token
  const authTokenExpirationDateInStorage = lastLoggedInAccount?.tokenExpirationDate
  const emailInStorage = viewingAsUser?.userMail || lastLoggedInAccount?.email
  const adminTokenInStorage = viewingAsUser.adminToken


  const auth = useSelector(state => state.auth)
  const authToken = auth.token
  const authTokenExpirationDate = auth.tokenExpirationDate
  const isSpecialUser = auth.user?.isSpecialUser
  const authUserId = auth.user?.id
  const userEmail = auth.user?.email
  const userShopify = auth.user?.shopify

  const handleSessionExpiration = () => {
    const sessionExpired = () => {
      toast.warn('Your session has expired. Please log in again.')
      dispatch(authActions.logout({ isForced: true }))
      document.body.classList.remove('adminsr')

      dispatch(generalActions.setPageTitle({ pageTitle: null }))
      dispatch(generalActions.setCompanyTitle({ companyTitle: null }))
    }

    if (authToken && authTokenExpirationDate) {
      const currentDate = new Date()
      const remainingTime = authTokenExpirationDate - currentDate.getTime()
      logoutTimer = setTimeout(sessionExpired, remainingTime)

      if (isSpecialUser) {
        document.body.classList.add('adminsr')
      }
    } else {
      clearTimeout(logoutTimer)
    }
  }

  const register = async ({ name, email, country, url, password }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signup`,
        'POST',
        JSON.stringify({
          name,
          email,
          country,
          url,
          password,
        }),
        {
          'Content-Type': 'application/json',
        },
      )

      const token = responseData.token
      const tokenExpirationDate = responseData.expirationDate
      const user = responseData.user
      const projectId = responseData.projectId

      const updatedUser = helper.getUIReadyUserObject({ user })

      if (updatedUser.crmDetails?.performanceManager) {
        updatedUser.crmDetails.performanceManagerProfile =
          await getPerformanceManagerProfileForUser({
            performanceManager: updatedUser.crmDetails.performanceManager,
            authToken: token,
          })
      }

      dispatch(
        authActions.login({
          token,
          tokenExpirationDate,
          projectId,
          user: updatedUser,
        }),
      )
    } catch (err) {
      errorHandler(err)

      if (err?.message === 'User exists already, please login instead.') {
        navigate('/login')
      }
    }
  }

  const login = async ({ email, password }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        'POST',
        JSON.stringify({ email, password }),
        { 'Content-Type': 'application/json' },
      )

      const token = responseData.token
      const tokenExpirationDate = responseData.expirationDate
      const user = responseData.user
      const projectId = responseData.projectId

      const updatedUser = helper.getUIReadyUserObject({ user })
      if (updatedUser.crmDetails?.performanceManager) {
        updatedUser.crmDetails.performanceManagerProfile =
          await getPerformanceManagerProfileForUser({
            performanceManager: updatedUser.crmDetails.performanceManager,
            authToken: token,
          })
      }

      dispatch(
        authActions.login({
          token,
          tokenExpirationDate,
          projectId,
          user: updatedUser,
        }),
      )
    } catch (err) {
      throw err
    }
  }

  const createPassword = async ({ password }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/createPassword`,
        'POST',
        JSON.stringify({ userId: authUserId, password }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        authActions.updateUserObjOneField({
          field: 'hasPassword',
          value: true,
        }),
      )
    } catch (err) {
      throw err
    }
  }

  const completeShopifyRegistration = async ({
    name,
    email,
    title,
    whereDidYouHearUs
  }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/completeShopifyRegistration`,
        'POST',
        JSON.stringify({
          userId: authUserId,
          name,
          email,
          title,
          whereDidYouHearUs
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        authActions.updateUserObjManyFields({
          name,
          title,
          "crmDetails.contactEmail": email,
          "crmDetails.whereDidYouHearUs": whereDidYouHearUs,
          completedShopifyRegistration: true
        }),
      )
    } catch (err) {
      throw err
    }
  }

  const completePhoneRegistration = async ({ country }) => {
    try {
      const userResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/completePhoneRegistration`,
        'POST',
        JSON.stringify({
          userId: authUserId,
          country,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken,
        },
      )

      analytics.completeSignUp({
        registerType: !!userShopify
          ? 'shopify'
          : '',
        userId: authUserId,
        email: userEmail,
      })

      dispatch(
        authActions.updateUserObjManyFields({
          ...userResult.user,
          completedRegistration: true,
        }),
      )
    } catch (err) {
      throw err
    }
  }

  const loginWithGoogle = async () => { }

  const logout = async ({ isForced = false }) => {
    dispatch(authActions.logout({ isForced }))
    localStorage.removeItem('facebookAuth')
    dispatch(facebookActions.clearInfo())
  }

  const deleteAccount = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${authUserId}`,
        'DELETE',
        null,
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      errorHandler({message: 'Account deleted!'})
      dispatch(authActions.logout({ isForced: true }))
    } catch (err) {
      errorHandler(err)
    }
  }

  return {
    handleSessionExpiration,
    register,
    login,
    loginWithGoogle,
    createPassword,
    completeShopifyRegistration,
    completePhoneRegistration,
    logout,
    deleteAccount,
    auth,
    adminTokenInStorage,
    authTokenInStorage,
    authToken,
    authTokenExpirationDateInStorage,
    authTokenExpirationDate,
    emailInStorage,
  }
}
