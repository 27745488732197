import React from 'react';
import { FaPlus, FaHourglassHalf } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import adLauncher from '../../../../shared/assets/images/ai-ads/ad-launcher.png';
import './BlockingOverlay.css';
import { useSelector } from 'react-redux';

const BlockingOverlay = ({ fullFunnelsExist, isCampaignNew, children }) => {
	const navigate = useNavigate();
	const facebook = useSelector(state => state.facebook);
	const user = useSelector(state => state.auth.user);

	const isConnectionIncomplete = facebook.connectionPageIsReady && !user.connectionsCompleted;

	const handleCreateCampaignClick = () => {
		if (isConnectionIncomplete) {
			if (user.subscription?.status === "active") {
				navigate('/config');
			} else {
				navigate('/checkup/config');
			}
		} else {
			navigate('/ai-ads/full-funnel-campaigns/create');
		}
	};

	const renderOverlay = () => {
		if (!fullFunnelsExist) {
			return (
				<div className="crc-no-campaigns">
					<div className="crc-no-campaigns-content">
						<div className="crc-no-campaigns-icon-wrapper">
							<img src={adLauncher} alt="ad launcher" className="crc-no-campaigns-launcher-icon" />
						</div>
						<h2 className="crc-no-campaigns-title">
							Start Your First Campaign
						</h2>
						<p className="crc-no-campaigns-description">
							{isConnectionIncomplete 
								? "Complete your Facebook connection setup first to create campaigns."
								: "Create your first AI-powered campaign to boost your business growth."}
						</p>
						<div className="crc-create-campaign-button" onClick={handleCreateCampaignClick}>
							{!isConnectionIncomplete && <FaPlus className="button-icon" />}
							{isConnectionIncomplete ? "Complete Setup" : "Create Campaign"}
						</div>
					</div>
				</div>
			);
		}
		if (isCampaignNew) {
			return (
				<div className="crc-no-campaigns">
					<div className="crc-no-campaigns-content">
						<div className="crc-no-campaigns-icon-wrapper">
							<FaHourglassHalf className="crc-no-campaigns-waiting-icon" />
						</div>
						<h2 className="crc-no-campaigns-title">
							Campaign is still new
						</h2>
						<p className="crc-no-campaigns-description">
							Please wait for at least 3 days while we gather performance data for your campaign.
						</p>
					</div>
				</div>
			);
		}

	};

	if (!fullFunnelsExist || isCampaignNew) {
		return (
			<div style={{ position: 'relative', width: '100%', height: '100%', minHeight: '500px' }}>
				{children}
				{renderOverlay()}
			</div>
		);
	} else {
		return children
	}
};

export default BlockingOverlay; 