import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FaSyncAlt } from 'react-icons/fa'

import { useFacebookCampaignEditor } from '../../../../../../shared/hooks/facebook/campaign-editor-hook'
import { useFacebook } from '../../../../../../shared/hooks/facebook-hook'
import { VALIDATOR_REQUIRE } from '../../../../../../shared/util/validators'
import InlineLoadingSpinner from '../../../../../../shared/components/UIElements/InlineLoadingSpinner'
import Button from '../../../../../../shared/components/FormElements/Button'
import StrokeInput from '../../../../../../shared/components/FormElements/StrokeInput'
import CustomDropdown from '../../../CustomDropdown'
import ProductSetDropdownHover from '../../../ProductSetDropdownHover'
import AdTextGenerator from '../../../AdTextGenerator'

const CatalogCreativeMaker = () => {
  const facebook = useSelector(state => state.facebook)
  const user = useSelector(state => state.auth.user)

  const selectedActiveFacebookPage = useSelector(
    state => state.facebook.selectedActiveFacebookPage,
  )
  const selectedActiveInstagramAccount = useSelector(
    state => state.facebook.selectedActiveInstagramAccount,
  )

  const { isFetchingCatalogs, isFetchingFacebookPages } = useSelector(
    state => state.facebookCampaignEditor.loadings,
  )

  const { details } =
    useSelector(
      state => state.facebookCampaignEditor.currentCampaign?.currentCreative,
    ) ?? {}
  const { catalog, productSet, facebookPage, instagramAccount } = details ?? {}

  const [primaryText, setPrimaryText] = useState()
  const [websiteUrl, setWebsiteUrl] = useState()
  const [textUpdateTimer, setTextUpdateTimer] = useState()

  const {
    catalogCreativeFormTextFieldsUpdated,
    newCatalogSelected,
    newProductSetSelected,
    newFacebookPageSelected,
    newInstagramAccountSelected,
    startLoadingState,
    endLoadingState,
  } = useFacebookCampaignEditor()

  const {
    getCatalogs,
    getProductSetsOfCatalog,
    getFacebookPages,
    getInstagramAccountsForPage,
  } = useFacebook()

  const fetchCatalogsForAdmin = () => {
    if (user.isSpecialUser) {
      getCatalogs({
        businessId: facebook.selectedBusiness.id,
        accessToken: facebook.auth.accessToken,
      })
    }
  }

  const refreshCatalogs = async () => {
    startLoadingState('isFetchingCatalogs')
    await getCatalogs({
      businessId: facebook.selectedBusiness.id,
      accessToken: facebook.auth.accessToken,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingCatalogs')
  }

  const refreshProductSets = async () => {
    startLoadingState('isFetchingProductSets')
    await getProductSetsOfCatalog({
      catalogId: catalog?.id,
      accessToken: facebook.auth.accessToken,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingProductSets')
  }

  const refreshFacebookPages = async () => {
    startLoadingState('isFetchingFacebookPages')
    await getFacebookPages({
      facebookUserId: facebook.auth.id,
      accessToken: facebook.auth.accessToken,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingFacebookPages')
  }

  const refreshInstagramAccounts = async () => {
    startLoadingState('isFetchingInstagramAccounts')
    await getInstagramAccountsForPage({
      pageId: facebookPage?.id,
      pageAccessToken: facebookPage?.access_token,
      getFromCacheIfAvailable: false,
    })
    endLoadingState('isFetchingInstagramAccounts')
  }

  useEffect(() => {
    setWebsiteUrl(
      details?.websiteUrl ||
        (!user.url.includes('https://') ? `https://${user.url}` : user.url),
    )
    setPrimaryText(details?.primaryText)
  }, [])

  useEffect(() => {
    if (textUpdateTimer) {
      clearTimeout(textUpdateTimer)
    }

    if (
      primaryText &&
      websiteUrl &&
      (primaryText !== details.primaryText || websiteUrl !== details.websiteUrl)
    ) {
      startLoadingState('isProcessingTextFields')
      let timer = setTimeout(() => {
        catalogCreativeFormTextFieldsUpdated({
          primaryText: primaryText,
          websiteUrl: websiteUrl,
        })
        endLoadingState('isProcessingTextFields')
      }, 3000)
      setTextUpdateTimer(timer)
    }

    return () => {
      clearTimeout(textUpdateTimer)
    }
  }, [primaryText, websiteUrl])

  return (
    <React.Fragment>
      <div className="row input-container-row">
        <label>Catalog</label>
        {isFetchingCatalogs ? (
          <InlineLoadingSpinner
            size="xs"
            message="Fetching catalogs"
          ></InlineLoadingSpinner>
        ) : (
          <>
            {facebook.catalogs.length ? (
              <CustomDropdown
                placeholder="Select catalog..."
                initialValue={catalog}
                forcedValue={catalog}
                options={facebook.catalogs}
                onChange={c => {
                  newCatalogSelected({ catalog: c })
                }}
                onHover={(edgeId, product_count) => (
                  <ProductSetDropdownHover
                    edgeId={edgeId}
                    productCount={product_count}
                  />
                )}
              />
            ) : (
              <p className="error-text dropdown">
                No catalog found. Please try selecting{' '}
                <Link to="/config">another ad account</Link> or visit{' '}
                <a
                  href={`https://business.facebook.com/products/catalogs/new?business_id=${facebook?.selectedBusiness?.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Meta Ecommerce Manager
                </a>{' '}
                to create a new one.
              </p>
            )}
            {user.isSpecialUser ? (
              <Button
                onClick={fetchCatalogsForAdmin}
                className="admin-spec-button"
              >
                Fetch Catalogs (A)
              </Button>
            ) : null}

            {facebook.catalogs ? (
              <Button
                onClick={refreshCatalogs}
                size="small"
                className="refresh-button"
                title="Refresh catalogs list"
              >
                <FaSyncAlt className="icon" />
              </Button>
            ) : null}
          </>
        )}
      </div>

      <div className="row input-container-row">
        <label>Product Set</label>
        {catalog ? (
          <>
            {facebook.productSets[catalog?.id] &&
            facebook.productSets[catalog?.id].length ? (
              <CustomDropdown
                placeholder="Select product set..."
                initialValue={productSet}
                options={facebook.productSets[catalog.id]}
                onChange={p => {
                  newProductSetSelected({ productSet: p })
                }}
                forcedValue={productSet}
                onHover={(edgeId, product_count) => (
                  <ProductSetDropdownHover
                    edgeId={edgeId}
                    productCount={product_count}
                  />
                )}
              />
            ) : (
              <p className="error-text dropdown">
                No product set found. Please try selecting another catalog or
                visit{' '}
                <a
                  href={`https://business.facebook.com/commerce/catalogs/${catalog?.id}/sets?business_id=${facebook.selectedBusiness?.id}&layer=createProductSet&createSetMethod=Static&prefillGlobalLogicOperator=or&originLocation=SETS_PAGE`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Meta Ecommerce Manager
                </a>{' '}
                to create a new one.
              </p>
            )}
            {facebook.productSets[catalog?.id] ? (
              <Button
                onClick={refreshProductSets}
                size="small"
                className="refresh-button"
                title="Refresh product sets list"
              >
                <FaSyncAlt className="icon" />
              </Button>
            ) : null}
          </>
        ) : (
          <p className="error-text dropdown">Please select a catalog</p>
        )}
      </div>

      <div className="row input-container-row">
        <label>Facebook Page</label>

        {isFetchingFacebookPages ? (
          <InlineLoadingSpinner
            size="xs"
            message="Fetching pages"
          ></InlineLoadingSpinner>
        ) : facebook.pages.length ? (
          <CustomDropdown
            placeholder="Select page..."
            initialValue={selectedActiveFacebookPage}
            onChange={p => newFacebookPageSelected({ facebookPage: p })}
            forcedValue={facebookPage}
            options={facebook.pages}
          />
        ) : (
          <p className="error-text dropdown">
            No page found. Please try selecting{' '}
            <Link to="/config?io=3">another ad account</Link> or visit{' '}
            <a
              href={`https://business.facebook.com/settings/pages?business_id=${facebook?.selectedBusiness?.id}`}
              target="_blank"
              rel="noreferrer"
            >
              Meta Business Settings
            </a>{' '}
            to create a page.
          </p>
        )}

        {facebook.pages ? (
          <Button
            onClick={refreshFacebookPages}
            size="small"
            className="refresh-button"
            title="Refresh pages list"
          >
            <FaSyncAlt className="icon" />
          </Button>
        ) : null}
      </div>

      <div className="row input-container-row">
        <label>
          Instagram <br /> Account
        </label>

        {facebookPage ? (
          <>
            {facebook.instagramAccounts[facebookPage?.id] &&
            facebook.instagramAccounts[facebookPage?.id].length ? (
              <CustomDropdown
                placeholder="Select account..."
                initialValue={selectedActiveInstagramAccount}
                forcedValue={instagramAccount}
                onChange={i =>
                  newInstagramAccountSelected({ instagramAccount: i })
                }
                options={facebook.instagramAccounts[facebookPage?.id]}
              />
            ) : (
              <p className="error-text dropdown">
                No connected Instagram account found.
              </p>
            )}
            {facebook.instagramAccounts ? (
              <Button
                onClick={refreshInstagramAccounts}
                size="small"
                className="refresh-button"
                title="Refresh Instagram accounts list"
              >
                <FaSyncAlt className="icon" />
              </Button>
            ) : null}
          </>
        ) : (
          <p className="error-text dropdown">Please select a Facebook page</p>
        )}
      </div>

      <div className="row input-container-row no-margin">
        <StrokeInput
          leftTitle
          element="input"
          type="text"
          label="Website URL"
          id="websiteUrl"
          placeholder="https://yourwebsite.com"
          value={websiteUrl}
          onChange={setWebsiteUrl}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={!websiteUrl ? 'Please enter a redirection page' : null}
        />
      </div>

      <div className="row input-container-row no-margin">
        <StrokeInput
          leftTitle
          element="textarea"
          label="Ad Caption"
          id="primaryText"
          rows={3}
          placeholder="Unlock the Future with Our Innovative Solution!"
          value={primaryText}
          onChange={setPrimaryText}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={!primaryText ? 'Please enter a primary text' : null}
        />
      </div>

      <AdTextGenerator
        selectedAdAccountId={facebook.selectedAdAccount.id}
        selectARecommendation={setPrimaryText}
      />
    </React.Fragment>
  )
}

export default CatalogCreativeMaker
