import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useFacebookCreative } from '../../shared/hooks/facebook/creative-hook'
import placeholderImg from '../../shared/assets/images/ad-health-checkup/placeholder.jpg'
import numberFormatter from '../../shared/util/numberFormatter'
import helper from '../../shared/util/helper'
import Tooltip from '../../shared/components/UIElements/Tooltip'

import './AdCard.css'

let isToastDisplayed = false

const AdCard = ({
  className,
  order,
  compareBy,
  compareByValue,
  additionalMetric,
  additionalMetricValue,
  audienceName,
  audiences,
  creativeId,
  type,
}) => {
  const [preview, setPreview] = useState(null)
  const { getAdPreviews } = useFacebookCreative()
  const facebook = useSelector(state => state.facebook)

  const getPreviewToRender = async creativeId => {
    try {
      const previewResults = await getAdPreviews({ creativeId })

      const desktopFeedStandardPreview = previewResults.find(
        p => p.format === 'DESKTOP_FEED_STANDARD',
      )

      if (desktopFeedStandardPreview) {
        let { format, body } = desktopFeedStandardPreview

        body = body.replace('scrolling="yes"', 'scrolling="no"')

        const onLoadHandler = () => {
          const iframe = document.getElementById(`ad-preview-${order}`)
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document
          const iframeBody = iframeDocument.querySelector('body')
          const innerIframe = iframeBody.querySelector('iframe')

          iframeBody.style.width = '100%'
          iframeBody.style.height = '100%'
          iframeBody.style.margin = '0'

          innerIframe.style.width = '100%'
          innerIframe.style.height = '100vh'
          innerIframe.style.overflow = 'hidden'
        }

        const previewToRender = (
          <div
            key={format}
            className={`ad-preview-container ${format
              .toLowerCase()
              .replaceAll('_', '-')}`}
          >
            <iframe
              id={`ad-preview-${order}`}
              title="Ad Preview"
              srcDoc={body}
              onLoad={onLoadHandler}
            ></iframe>
          </div>
        )

        setPreview(previewToRender)
      }
    } catch (err) {
      if (!isToastDisplayed) {
        toast.warn(`Couldn't get the ad preview.`)
        isToastDisplayed = true
      }
    }
  }

  useEffect(() => {
    getPreviewToRender(creativeId)

    return () => {
      isToastDisplayed = false
    }
  }, [creativeId])

  const orderTextClassName =
    type === 'inefficient' ? 'red-text' : 'gradient-text'
  const backgroundClassName =
    type === 'inefficient' ? 'red-background' : 'gradient-background'

  const formattedAdditionalMetricValue = numberFormatter.formatNumber(
    additionalMetricValue,
    0,
  )

  return (
    <div className={`detail-card ${className}`}>
      <div className={`order ${orderTextClassName}`}>{order}</div>
      <div className="image-part">
        {preview ? preview : <img src={placeholderImg} alt="Sample Preview" />}
      </div>
      <div className={`content-part ${order === 1 ? backgroundClassName : ''}`}>
        <div className="row">
          <div className="secondary-text">{compareBy}</div>
          <div className="secondary-text">{compareByValue.toFixed(1)}</div>
        </div>
        <div className="row">
          <div className="secondary-text">{additionalMetric}</div>
          <div className="secondary-text">
            <span className="currency">
              {' '}
              {helper.getCurrencySymbol(facebook.selectedAdAccount?.currency) ||
                ''}
            </span>
            {formattedAdditionalMetricValue} {/* Render formatted value */}
          </div>
        </div>
        {audienceName && (
          <div className="row">
            <div className="secondary-text">Audience</div>
            <div className="secondary-text smaller">
              {audienceName === 'Mixed' && audiences ? (
                <Tooltip
                  content={
                    <div>
                      {audiences.map((audience, index) => (
                        <>
                          <div key={index}>{audience}</div>
                          {index < audiences.length - 1 && <br />}
                        </>
                      ))}
                    </div>
                  }
                >
                  <div>{audienceName}</div>
                </Tooltip>
              ) : (
                audienceName
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AdCard
