import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaTimes } from 'react-icons/fa'

import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/FormElements/Button'
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useAuth } from '../../shared/hooks/auth-hook'
import { authActions } from '../../store/auth'
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png'
import LoginOptions from '../components/LoginOptions'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import StrokeInput from '../../shared/components/FormElements/StrokeInput'

import './Auth.css'
import { useUser } from '../../shared/hooks/user-hook'
import { generalActions } from '../../store/general'
import errorHandler from '../../shared/util/errorHandler'

const Login = props => {
  const dispatch = useDispatch()
  const { login } = useAuth()
  const [busy, setBusy] = useState(false)
  const location = useLocation()
  const { getProfile } = useUser()
  const allStoredAccounts = JSON.parse(localStorage.getItem('userData') || '[]')
  const [storedAccounts, setStoredAccounts] = useState(
    allStoredAccounts.filter(a => a.email)
  )
  const today = new Date()
  const loggedOutFromAccountNotification = useSelector(state => state.general.loggedOutFromAccountNotification)

  const query = new URLSearchParams(window.location.search)
  const pageUrl = window.location.pathname
  const isComingFromShopifyLoginCallback = query.get("token") && pageUrl.includes("/login")

  useEffect(() => {
    handleAppClass(true)

    if (location.search !== '') {
      const query = new URLSearchParams(location.search)
      const token = query.get('token')
      const projectId = query.get('projectId')
      const existingEmail = query.get('email')
      fetchUserAndLogin({ projectId, token, email: existingEmail })
    }
    return () => {
      handleAppClass(false)
    }
  }, [])


  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false,
      },
      password: {
        value: '',
        isValid: false,
      },
    },
    false,
  )

  const fetchUserAndLogin = async ({ projectId, token, email }) => {
    try {
      const selectedAccount = storedAccounts.find(
        account => account.email === email,
      )

      if (
        selectedAccount &&
        (selectedAccount.tokenExpirationDate < today.getTime() || !selectedAccount.tokenExpirationDate)
        && !isComingFromShopifyLoginCallback
      ) {
        // if token is expired or not set, set the email to the stored account's email

        setFormData(
          {
            ...formState.inputs,
            email: {
              value: selectedAccount.email,
              isValid: true,
            },
            password: {
              value: '',
              isValid: false,
            },
          },
          true,
        )
        toast.info(
          'Session expired. Please enter your password to login again.',
        )
        // remove the expiration date from the stored account
        selectedAccount.tokenExpirationDate = null
        selectedAccount.token = null
        localStorage.setItem('userData', JSON.stringify(storedAccounts))
        setBusy(false)
        return
      }

      if (
        email &&
        loggedOutFromAccountNotification?.email &&
        email !== loggedOutFromAccountNotification?.email
      ) {
        toast.info(
          `You have been logged out of ${loggedOutFromAccountNotification?.email} account.`,
        )
      }
      dispatch(generalActions.showLoggedOutFromAccountNotification(null))

      let updatedUser = await getProfile({ token, shouldSetRedux: false })
      if (isComingFromShopifyLoginCallback) {
        const expiresInSeconds = 60 * 60 * 24 * 15; // 15 days in seconds
        const expirationDate = Date.now() + expiresInSeconds * 1000; // 15 days later in timestamp
        dispatch(authActions.login({ token, projectId, user: updatedUser, tokenExpirationDate: expirationDate, isComingFromShopify: true }))
        // clear the query params
        window.history.replaceState({}, document.title, window.location.pathname)
      } else {
        dispatch(authActions.login({ token, projectId, user: updatedUser }))
      }

    } catch (err) {
      errorHandler(err)
    }
  }

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-scroll')
      : document.getElementById('app')?.classList.remove('full-screen-scroll')
  }

  const submit = async event => {
    event.preventDefault()

    const { email, password } = formState.inputs

    setBusy(true)

    try {
      await login({
        email: email.value,
        password: password.value,
      })
    } catch (err) {
      setBusy(false)
      errorHandler(err)
    }
  }
  const logoutFromStoredAccount = token => {
    let updatedUserData = JSON.parse(localStorage.getItem('userData') || '[]')
    updatedUserData = updatedUserData.filter(user => user.token !== token)
    localStorage.setItem('userData', JSON.stringify(updatedUserData))
    setStoredAccounts(updatedUserData)
  }

  return (
    <React.Fragment>
      <div className="full-container">
        <div className="login-main-cont">
          {/* <div className="login row"> */}
          {storedAccounts.length > 0 && (
            <div className="stored-accounts">
              <h2>Recent Logins</h2>
              <div className="accounts-grid">
                {storedAccounts.map((account, index) => (
                  <Tooltip content={account.email}>
                    <div className="stored-account" key={account.email}>
                      <div className="remove-user-row">
                        <FaTimes
                          className="icon"
                          onClick={() => logoutFromStoredAccount(account.token)}
                        />
                      </div>
                      <div
                        className="account-box"
                        onClick={() =>
                          fetchUserAndLogin({
                            projectId: account.projectId,
                            token: account.token,
                            email: account.email,
                          })
                        }
                      >
                        <div className="account-name">
                          {account.email.substring(0, 2).toUpperCase()}
                        </div>
                        <div className="email-bar">
                          {account.email.split('@')[0]}
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                ))}
              </div>
            </div>
          )}

          <div className="login-cont">
            <div style={{ display: 'flex' }}>
              <img className="logo" src={enhencerLogo} />
            </div>
            <h1>Log In</h1>
            <form onSubmit={submit}>
              <StrokeInput
                element="input"
                id="email"
                type="text"
                label="E-Mail"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address"
                onInput={inputHandler}
                value={formState.inputs.email.value}
                forceValue={formState.inputs.email.value}
              />

              <StrokeInput
                element="input"
                id="password"
                type="password"
                label="Password"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Invalid password"
                onInput={inputHandler}
                showPasswordToggle={true}
              />

              <div className="button-cont">
                <Link className="link-button" to="/forgot-password">
                  Forgot password?
                </Link>
              </div>

              <div className="cont">
                <Button loading={busy} type="submit" className="action-button">
                  Log In
                </Button>
              </div>
            </form>
            <LoginOptions />

            <div className="no-account-row">
              Don't have an account?
              <Link className="link-button" to="/register">
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
