import { WORKBENCH_SIZE } from '../../../support/constants'
import temp2bg from '../../../../shared/assets/images/ai-creative/temp2bg.svg'

export const Template2Config = {
  name: 'Spotlight Simplicity',
  containerId: 'template2-carousel-single',
  backgroundColor: 'color2',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp2bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#E1990D',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxHeight: 550,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: 44,
        y: 100,
      },
      scale: {
        maxHeight: 140,
        maxWidth: 300,
      },
      dynamicSrc: 'companyLogo',
      required: false,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 275,
        y: WORKBENCH_SIZE.height - 150,
      },
      width: 550,
      height: 50,
      fontFamily: 'Readex Pro',
      fontSize: 36,
      color: 'color3',
      align: 'center',
      fontIndex: 1,
      text: '{{productName}}',
    },
    // Product Price Circle
    {
      id: 'productPriceCircle',
      type: 'circle',
      position: {
        x: WORKBENCH_SIZE.width - 225,
        y: 157 + 200 / 2,
      },
      radius: 108,
      fill: 'color2',
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 324,
        y: 157,
      },
      width: 200,
      height: 200,
      fontFamily: 'Readex Pro',
      color: 'color3',
      center: true,
      align: 'center',
      verticalAlign: 'middle',
      fontIndex: 2,
      fontSize: 36,
      text: '{{productPrice}}',
    },
  ],
}
