import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import useAICreatives from '../../hooks/ai-creatives-hook'
import EnhencedCatalogPreview from '../../../ai-creative/components/miscellaneous/EnhencedCatalogPreview'
import { useEnhencedCatalog } from '../../hooks/enhenced-catalog-hook'
import { useModal } from '../../hooks/modal-hook'
import Button from '../FormElements/Button'

import styles from './CompleteEnhencedCatalogModal.module.css'

const CompleteEnhencedCatalogModal = () => {
  const navigate = useNavigate()
  const { fullList } = useAICreatives()
  const { catalogId, primaryFeedId } = fullList[0].enhencedCatalogDetails
  const { createSupplementaryFeed } = useEnhencedCatalog()
  const { closeModal, modalData } = useModal()
  const [busy, setBusy] = useState(false)

  const publishEnhencedCatalog = async () => {
    setBusy(true)
    await createSupplementaryFeed({ catalogId, primaryFeedId, creativeId: modalData.creative._id })
    navigate(`/ai-ads/full-funnel-campaigns/create?aiCreativeId=${modalData.creative._id}`)
    toast.success('Enhenced Catalog created successfully')
    setBusy(false)
    closeModal()
  }

  if (!modalData?.creative) {
    return null
  }

  return (
    <div className={`${styles.modalContent} enhenced-catalog-modal`}>
      <div className={styles.headerSection}>
        <div className={styles.smallTitle}>Your products are ready!</div>
        <h2 className={styles.mainTitle}>Review before publishing</h2>
      </div>
      <div className={`${styles.previewContainer} completeEnhencedModal`}>
        <EnhencedCatalogPreview
          className={styles.preview}
          aiCreativeId={modalData.creative._id}
          enhencedCatalogDetails={modalData.creative.enhencedCatalogDetails}
          completeModal={true}
        />
        <div className={styles.buttonGroup}>
          <Button
            className={styles.publishButton}
            onClick={publishEnhencedCatalog}
            primary={true}
            loading={busy}
          >
            Publish Enhenced Catalog ✨
          </Button>
          <Button onClick={closeModal} className={styles.cancelButton}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CompleteEnhencedCatalogModal
