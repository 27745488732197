import React, { useState, useEffect } from 'react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'

import Switch from '../../../shared/components/UIElements/Switch'
import CollapsibleCard from '../../../shared/components/UIElements/CollapsibleCard'
import GoogleLogo from '../../../shared/assets/images/icons/google-ads-logo.png'
import MetaLogo from '../../../shared/assets/images/icons/meta-logo.png'
import TiktokLogo from '../../../shared/assets/images/icons/tiktok-logo.png'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useForm } from '../../../shared/hooks/form-hook'
import Input from '../../../shared/components/FormElements/Input'
import dateFormatter from '../../../shared/util/dateFormatter'
import { adminActions } from '../../../store/admin'
import { projectActions } from '../../../store/project'

import './CardAdminIntegration.css'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import errorHandler from '../../../shared/util/errorHandler'

function CardAdminIntegration() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { startViewingAsUser } = useAdmin()
  const { sendRequest } = useHttpClient()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [isDirty, setIsDirty] = useState(false)
  const [saving, setSaving] = useState(false)

  const { pathname } = useLocation()
  const userId = pathname.split('/')[3]

  let domain = customer.shopify
    ? customer.shopify.name || customer.shopify.domain
    : customer.url

  domain = domain?.replace('https://', '').replace('.myshopify.com', '')
  if (domain && domain[domain.length - 1] === '/') {
    domain = domain.substring(0, domain.length - 1)
  }

  const [
    integrationFormState,
    integrationInputHandler,
    setIntegrationFormData,
  ] = useForm(
    {
      googleConversionId: {
        value: customer.googleAds?.conversionId || '',
        isValid: true,
      },
      googleAdAccountName: {
        value: customer.googleAds?.adAccountName || '',
        isValid: true,
      },
      facebookAdAccountName: {
        value:
          customer.facebookAds?.adAccountName ||
          customer.facebookAds?.selectedAdAccount?.name ||
          '',
        isValid: true,
      },
      facebookPixelId: {
        value: customer.facebookAds?.pixelId || '',
        isValid: true,
      },
      facebookAccessToken: {
        value: customer.facebookAds?.accessToken || '',
        isValid: true,
      },
      isTiktokEnabled: {
        value: customer.tiktokAds?.isEnabled || false,
        isValid: true,
      },
    },
    true,
  )

  const save = async () => {
    try {
      setSaving(true)

      const queryData = {
        'facebookAds.adAccountName':
          integrationFormState.inputs.facebookAdAccountName.value,
        'facebookAds.pixelId':
          integrationFormState.inputs.facebookPixelId.value.trim(),
        'facebookAds.accessToken':
          integrationFormState.inputs.facebookAccessToken.value.trim(),
        'googleAds.adAccountName':
          integrationFormState.inputs.googleAdAccountName.value,
        'googleAds.conversionId':
          integrationFormState.inputs.googleConversionId.value.trim(),
        'tiktokAds.isEnabled':
          integrationFormState.inputs.isTiktokEnabled.value,
      }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
        'PATCH',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          data: {
            ...customer,
            facebookAds: {
              ...customer.facebookAds,
              adAccountName:
                integrationFormState.inputs.facebookAdAccountName.value,
              pixelId: integrationFormState.inputs.facebookPixelId.value.trim(),
              accessToken:
                integrationFormState.inputs.facebookAccessToken.value.trim(),
            },
            googleAds: {
              ...customer.googleAds,
              adAccountName:
                integrationFormState.inputs.googleAdAccountName.value,
              conversionId:
                integrationFormState.inputs.googleConversionId.value.trim(),
            },
            tiktokAds: {
              ...customer.tiktokAds,
              isEnabled: integrationFormState.inputs.isTiktokEnabled.value,
            },
          },
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const goToConnect = async () => {
    console.log("go to connect ", customer)
    if (customer.isActive) {
      startViewingAsUser({ user: customer, pageRoute: "/visiting-data-overview" })
    } else {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/checkIfDataActive`,
          'POST',
          JSON.stringify({
            userId,
          }),
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )
        if (responseData.isActive) {

          startViewingAsUser({ user: customer, pageRoute: "/visiting-data-overview" })
        } else {
          toast.warn('Data is not connected yet!')
        }
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  const goToAudience = async () => {
    if (!customer.job) {
      toast.warn('Audience is not created yet!')
    } else if (customer.job) {
      if (!customer.isReady) {
        toast.warn('Audience creation in progress. Please wait for it.')
      } else {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${customer.id}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          )
          dispatch(
            projectActions.setAdminUserUsersCampaigns({
              campaigns: responseData.campaigns,
            }),
          )
          startViewingAsUser({ user: customer, pageRoute: `/audience-event-manager` })
        } catch (err) {
          errorHandler(err)
        }
      }
    }
  }

  const onTiktokSwitchChange = state => {
    integrationInputHandler('isTiktokEnabled', state === 'yes', true)
    detectChanges('tiktokAds', 'isEnabled', state === 'yes')
  }

  const detectChanges = () => {
    const isGoogleConversionIdDirty =
      integrationFormState.inputs.googleConversionId.value !==
      (customer.googleAds?.conversionId || '')

    const isGoogleAdAccountNameDirty =
      integrationFormState.inputs.googleAdAccountName.value !==
      (customer.googleAds?.adAccountName || '')

    const isFacebookAdAccountNameDirty =
      integrationFormState.inputs.facebookAdAccountName.value !==
      (customer.facebookAds?.adAccountName ||
        customer.facebookAds?.selectedAdAccount?.name ||
        '')

    const isFacebookPixelIdDirty =
      integrationFormState.inputs.facebookPixelId.value !==
      (customer.facebookAds?.pixelId || '')

    const isFacebookAccessTokenDirty =
      integrationFormState.inputs.facebookAccessToken.value !==
      (customer.facebookAds?.accessToken || '')

    const isTiktokSwitchDirty =
      integrationFormState.inputs.isTiktokEnabled.value !==
      (customer.tiktokAds?.isEnabled || false)

    setIsDirty(
      isGoogleConversionIdDirty ||
        isGoogleAdAccountNameDirty ||
        isFacebookAdAccountNameDirty ||
        isFacebookPixelIdDirty ||
        isFacebookAccessTokenDirty ||
        isTiktokSwitchDirty,
    )
  }

  // We compare the current input values to the initial values at each render.
  useEffect(() => {
    detectChanges()
  })

  useEffect(() => {
    setIntegrationFormData(
      {
        googleConversionId: {
          value: customer.googleAds?.conversionId || '',
          isValid: true,
        },
        googleAdAccountName: {
          value: customer.googleAds?.adAccountName || '',
          isValid: true,
        },
        facebookAdAccountName: {
          value:
            customer.facebookAds?.adAccountName ||
            customer.facebookAds?.selectedAdAccount?.name ||
            '',
          isValid: true,
        },
        facebookPixelId: {
          value: customer.facebookAds?.pixelId || '',
          isValid: true,
        },
        facebookAccessToken: {
          value: customer.facebookAds?.accessToken || '',
          isValid: true,
        },
        isTiktokEnabled: {
          value: customer.tiktokAds?.isEnabled || false,
          isValid: true,
        },
      },
      true,
    )
  }, [customer])

  return (
    <CollapsibleCard title="Integration" className="ruby integration-card">
      <div className="content-wrapper">
        <div className="content-group">
          <div className="lines">
            <div className="line">
              <span>
                Data connection
                {customer.isConnected ? (
                  <FaCheckCircle className="status-icon" color="#4FC732" />
                ) : (
                  <FaTimesCircle className="status-icon" color="#C73232" />
                )}
              </span>
              <span className="link" onClick={goToConnect}>
                Connect page
              </span>
            </div>
            <div className="line">
              <span>Model updated</span>
              <span className="italic-text">
                {dateFormatter(customer.updatedAt)}
              </span>
            </div>
            {customer.job && (
              <>
                <div className="line">
                  <span>Next run</span>
                  <span className="italic-text">
                    {dateFormatter(customer.job.nextRunAt)}
                  </span>
                </div>
                <div className="line">
                  <span>Last run</span>
                  <span className="italic-text">
                    {dateFormatter(customer.job.lastRunAt)}
                  </span>
                </div>
              </>
            )}
            {customer.conversionCampaign?.audience && (
              <div className="line">
                <span>
                  Segment selection
                  {customer.conversionCampaign.audience
                    .isSelectedSegmentsCorrect ? (
                    <FaCheckCircle className="status-icon" color="#4FC732" />
                  ) : (
                    <FaTimesCircle className="status-icon" color="#C73232" />
                  )}
                </span>
                <div className="segment-selection-percentage">
                  <span className="italic-text">
                    {`${parseFloat(
                      customer.conversionCampaign.audience.percentage || 0,
                    ).toFixed(1)}%`}
                  </span>
                  <span className="link" onClick={goToAudience}>
                    Edit
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="content-group">
          <div className="content-group-title">
            <img
              width={24}
              alt="googlelogo"
              src={GoogleLogo}
            ></img>
            <h3>Google</h3>
          </div>
          <div className="divider" />
          <div className="lines inputs">
            <div className="line">
              <span>Conversion ID</span>
              <Input
                element="input"
                placeholder="No Conversion ID"
                initialValue={
                  integrationFormState.inputs.googleConversionId?.value
                }
                forceValue={
                  integrationFormState.inputs.googleConversionId?.value
                }
                id="googleConversionId"
                type="text"
                onInput={integrationInputHandler}
              />
            </div>
            <div className="line">
              <span>Ad Account</span>
              <Input
                element="input"
                placeholder="No name"
                initialValue={
                  integrationFormState.inputs.googleAdAccountName?.value
                }
                forceValue={
                  integrationFormState.inputs.googleAdAccountName?.value
                }
                id="googleAdAccountName"
                type="text"
                onInput={integrationInputHandler}
              />
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="content-group-title">
            <img
              src={MetaLogo}
              alt="metalogo"
              width={24}
            ></img>
            <h3>Meta</h3>
          </div>
          <div className="divider" />
          <div className="lines inputs">
            <div className="line">
              <span>Pixel ID</span>
              <Input
                element="input"
                placeholder="No ID"
                initialValue={
                  integrationFormState.inputs.facebookPixelId?.value
                }
                forceValue={integrationFormState.inputs.facebookPixelId?.value}
                id="facebookPixelId"
                type="text"
                onInput={integrationInputHandler}
              />
            </div>
            <div className="line">
              <span>Access Token</span>
              <Input
                element="input"
                placeholder="No Token"
                initialValue={
                  integrationFormState.inputs.facebookAccessToken?.value
                }
                forceValue={
                  integrationFormState.inputs.facebookAccessToken?.value
                }
                id="facebookAccessToken"
                type="text"
                onInput={integrationInputHandler}
              />
            </div>
            <div className="line">
              <span>Ad Account</span>
              <Input
                element="input"
                placeholder="No name"
                initialValue={
                  integrationFormState.inputs.facebookAdAccountName?.value
                }
                forceValue={
                  integrationFormState.inputs.facebookAdAccountName?.value
                }
                id="facebookAdAccountName"
                type="text"
                onInput={integrationInputHandler}
              />
            </div>
            <div className="line">
              <span>
                Logged in
                {customer.connectFlow?.loggedInToFacebook ? (
                  <FaCheckCircle className="status-icon" color="#4FC732" />
                ) : (
                  <FaTimesCircle className="status-icon" color="#C73232" />
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="content-group">
          <div className="content-group-title">
            <img
              src={TiktokLogo}
              alt="tiktoklogo"
              width={24}
            ></img>
            <h3>Tiktok</h3>
          </div>
          <div className="divider" />
          <div className="lines inputs">
            <div className="line">
              <span>Enabled</span>
              <Switch
                value={
                  integrationFormState.inputs.isTiktokEnabled?.value
                    ? 'yes'
                    : 'no'
                }
                options={['no', 'yes']}
                onChange={onTiktokSwitchChange}
              />
            </div>
          </div>
        </div>
      </div>
      {isDirty && (
        <div className="save-button-container">
          <button disabled={saving} className="save-button" onClick={save}>
            {saving ? 'Saving' : 'Save'}
          </button>
        </div>
      )}
    </CollapsibleCard>
  )
}

export default CardAdminIntegration
