import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useHttpClient } from '../../shared/hooks/http-hook'
import PricingCards from '../components/PricingCards'
import Button from '../../shared/components/FormElements/Button'
import enhencerLogo from '../../shared/assets/images/intro/enhencer-logo.svg'
import { FaHome } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { authActions } from '../../store/auth'
import './Pricing.css'
import errorHandler from '../../shared/util/errorHandler'

const handleAppClass = t => {
  return t
    ? document.getElementById('app')?.classList.add('full-screen-scroll')
    : document.getElementById('app')?.classList.remove('full-screen-scroll')
}

const Pricing = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sendRequest } = useHttpClient()

  const [countryCode, setCountryCode] = useState('TR')
  const [shopifyCheckoutStarted, setShopifyCheckoutStarted] = useState(false)

  const auth = useSelector(state => state.auth)
  const user = auth.user

  useEffect(() => {
    handleAppClass(true)
   
    dispatch(
      authActions.updateUserObjOneField({
        field: 'isInSignupFlow',
        value: true,
      }),
    )
    const getLocation = async () => {
      try {
        const res = await sendRequest(
          `https://api.ipregistry.co/?key=${process.env.REACT_APP_IP_REGISTRY_KEY}`,
          'GET',
        )
        setCountryCode(res.location.country.code)
      } catch (err) {
        console.log(err)
      }
    }
    if (process.env.NODE_ENV === 'production') {
      getLocation()
    } else {
      setCountryCode('TR')
    }

    return () => {
      handleAppClass(false)
    }
  }, [])

  const handleHomeClick = () => {
    dispatch(
      authActions.updateUserObjManyFields({
        isInSignupFlow: false,
      }),
    )
    navigate('/start')
  }

  const startShopifyPayment = async ({ price, planName, interval }) => {
    setShopifyCheckoutStarted(true)
    let params = JSON.stringify({
      price: price,
      planName: planName,
      interval: interval,
      accessToken: user.shopify.accessToken,
      shopDomain: user.shopify.domain,
    })
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/shopify/createSubscription`,
        'POST',
        params,
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      window.open(result.confirmationUrl, '_self')
    } catch (err) {
      errorHandler({message: 'An error occured. Please, contact us.'})
    }
  }

  return (
    <div className="pricing-page">
      <div className="pricing-page-header">
        <img
          src={enhencerLogo}
          alt="Enhencer Logo"
          className="pricing-page-logo"
        />
        <div className="button-container">
          <Button className="pricing-page-back-button" to="/your-team">
            Go Back to Discovery Meeting
          </Button>
          <Button
            className="pricing-page-home-button"
            onClick={handleHomeClick}
          >
            <FaHome />
          </Button>
        </div>
      </div>
      <PricingCards
        isFromShopify={user?.shopify !== undefined}
        startShopifyPayment={startShopifyPayment}
        countryCode={countryCode}
        showTestimonials={false}
      ></PricingCards>

    
    </div>
  )
}

export default Pricing
