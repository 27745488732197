import React, { useCallback, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaPlus, FaHome } from 'react-icons/fa'
import fullFunnelIcon from '../../../../src/shared/assets/images/icons/meta-icon-green.svg'
import './FullFunnelButton.css'

const FullFunnelButton = ({
  isExpanded,
  activeItem,         
  handleNavItemClick,
  disabled,
  onDisabledClick,
  isMobile,
  setIsSidebarOpen,
  isSidebarOpen,
  fullFunnels = [],
  activeFullFunnels = [],
  inactiveFullFunnels = [],
  reportsLoaded = false,
  selectedFullFunnelId,
  handleCampaignClick,
  createNewCampaign,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      
      if (disabled) {
        onDisabledClick()
        return
      }

      if (isMobile) {
        setIsDropdownOpen(prev => !prev)
        handleNavItemClick('campaigns', false)
      } else {
        setIsSidebarOpen(true)
        handleNavItemClick('campaigns', false)
      }
    },
    [disabled, onDisabledClick, handleNavItemClick, isMobile, setIsSidebarOpen]
  )

  const handleOverviewClick = (e) => {
    e.stopPropagation()
    handleNavItemClick('overview', false, '/ai-ads/full-funnel-campaigns/')
    setIsDropdownOpen(false)
  }

  const handleCreateClick = (e) => {
    e.stopPropagation()
    createNewCampaign()
    setIsDropdownOpen(false)
  }

  const handleCampaignItemClick = (e, id) => {
    e.stopPropagation()
    handleCampaignClick(id)
    setIsDropdownOpen(false)
  }

  const renderCampaignItems = (campaigns = []) => {
    return campaigns.map(funnel => {
      if (!funnel || !funnel._id) return null;
      return (
        <div 
          key={funnel._id}
          className={`dropdown-item campaign-item ${funnel._id === selectedFullFunnelId ? 'active' : ''}`}
          onClick={(e) => handleCampaignItemClick(e, funnel._id)}
        >
          {funnel.label || 'Unnamed Campaign'}
        </div>
      );
    });
  };

  const mobileDropdown = isMobile && isDropdownOpen && isExpanded && (
    <div className="mobile-dropdown">
      <button 
        className="dropdown-item overview-btn" 
        onClick={handleOverviewClick}
      >
        <FaHome /> Overview
      </button>
      <button 
        className="dropdown-item create-btn" 
        onClick={handleCreateClick}
      >
        <FaPlus /> New Campaign
      </button>
      {reportsLoaded ? (
        <>
          <div className="dropdown-section">
            <div className="section-header">Active Campaigns</div>
            {renderCampaignItems(activeFullFunnels)}
          </div>
          <div className="dropdown-section">
            <div className="section-header">Inactive Campaigns</div>
            {renderCampaignItems(inactiveFullFunnels)}
          </div>
        </>
      ) : (
        <div className="dropdown-section">
          <div className="section-header">All Campaigns</div>
          {renderCampaignItems(fullFunnels)}
        </div>
      )}
    </div>
  )

  const buttonContent = (
    <div className="nav-item-content" onClick={handleClick}>
      <div className="nav-item-left">
        <img src={fullFunnelIcon} alt="Facebook Ads" />
        {isExpanded && <span>Facebook Ads</span>}
      </div>
      {isMobile && isExpanded && (
        <div className="nav-item-right">
          {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      )}
    </div>
  )

  const buttonClasses = [
    'nav-item',
    'full-funnel-campaigns',
    activeItem === 'campaigns' ? 'active' : '',
    disabled ? 'disabled' : '',
    isSidebarOpen ? 'sidebar-open' : '',
    isDropdownOpen ? 'dropdown-open' : ''
  ].filter(Boolean).join(' ')

  return (
    <div 
      className={buttonClasses}
      onClick={disabled ? onDisabledClick : undefined}
    >
      {buttonContent}
      {mobileDropdown}
    </div>
  )
}

export default FullFunnelButton
