import { WORKBENCH_SIZE } from '../../../support/constants'
import temp9bg from '../../../../shared/assets/images/ai-creative/temp9bg.svg'

export const Template9Config = {
  name: 'Festive Delight',
  containerId: 'template9-carousel-single',
  backgroundColor: 'white',
  hasDiscount: true,
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp9bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#CF6A1E',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 40 + 650 / 2,
        y: 68 + 650 / 2,
      },
      scale: {
        maxWidth: 574,
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      center: true,
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp9bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#CF6A1E',
      draggable: false,
      clickThrough: true,
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width - 310,
        y: WORKBENCH_SIZE.height - 250,
      },
      scale: { maxWidth: 300, maxHeight: 140 },
      dynamicSrc: 'companyLogo',
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: { x: 60, y: WORKBENCH_SIZE.height - 100 },
      width: 800,
      text: '{{productName}}',
      fontFamily: 'Bayon',
      fontSize: 40,
      toUpperCase: true,
      color: 'color3',
      containerBg: 'color2',
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: { x: 60, y: WORKBENCH_SIZE.height - 270 },
      text: '30% DISCOUNT',
      fontFamily: 'Bayon',
      fontSize: 86,
      letterSpacing: 0.03,
      color: 'color1',
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: { x: 60, y: WORKBENCH_SIZE.height - 190 },
      text: 'USE CODE: GET30',
      fontFamily: 'Bayon',
      fontSize: 48,
      letterSpacing: 2.5,
      color: 'color3',
    },
  ],
}
