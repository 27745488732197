import React, { useState } from 'react'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import './NextActionsPopup.css'


const NextActionsPopup = props => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const alarm = props.alarm


  const handleSelect = (option) => {
    setIsPopoverOpen(false)
    switch (option) {
      case 'done':
        props.editAlarm({
          ...alarm.alarm,
          done: !alarm.alarm.done,
        })
        break;
      case '+6h':
        if ((new Date(alarm.alarm.dueDate)).getMinutes() === 59) {
          props.editAlarm({
            ...alarm.alarm,
            dueDate: new Date((new Date(alarm.alarm.dueDate)).getTime() + (6 * 60 * 60 * 1000) + (60 * 1000)), // Postpone by 6 hours + 1 minute
          });
        } else {
          props.editAlarm({
            ...alarm.alarm,
            dueDate: new Date((new Date(alarm.alarm.dueDate)).getTime() + 6 * 60 * 60 * 1000), // Postpone by 6 hours
          });
        }
        break;
      case '+1d':
        props.editAlarm({
          ...alarm.alarm,
          dueDate: new Date((new Date(alarm.alarm.dueDate)).getTime() + 24 * 60 * 60 * 1000), // Postpone by 1 day
        });
        break;
      case '+3d':
        props.editAlarm({
          ...alarm.alarm,
          dueDate: new Date((new Date(alarm.alarm.dueDate)).getTime() + 3 * 24 * 60 * 60 * 1000), // Postpone by 3 days
        });
        break;
      case '+7d':
        props.editAlarm({
          ...alarm.alarm,
          dueDate: new Date((new Date(alarm.alarm.dueDate)).getTime() + 7 * 24 * 60 * 60 * 1000), // Postpone by 7 days
        });
        break;
      case 'note':
        props.openEditAlarmModal()
        break;
      default:
        break;
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      reposition={false}
      positions={['bottom', 'bottom', 'bottom', 'bottom']}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
          nudgetLeft={10}
        >
          <div className="task-reminder-container">
            <div className='task-reminder-buttons'>
              <div
                onClick={() => handleSelect('done')}
                className="task-reminder-option"
                style={{ background: !alarm.alarm.done ? "green" : "red", color: "white", fontSize: "1.2em" }}
              >
                {!alarm.alarm.done ? "✓" : "✗"}
              </div>
              <div
                onClick={() => handleSelect('+6h')}
                className="task-reminder-option"
              >
                +6h
              </div>
              <div
                onClick={() => handleSelect('+1d')}
                className="task-reminder-option"
              >
                +1d
              </div>
              <div
                onClick={() => handleSelect('+3d')}
                className="task-reminder-option"
              >
                +3d
              </div>
              <div
                onClick={() => handleSelect('+7d')}
                className="task-reminder-option"
              >
                +7d
              </div>
            </div>
            <div onClick={() => handleSelect('note')} className="task-reminder-add-note">
              Add note
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="utms-popup-icon"
        onClick={e => {
          e.stopPropagation()
          if (isPopoverOpen) {
            setIsPopoverOpen(false)
          } else {
            setIsPopoverOpen(true)
          }
        }}
      >
        {props.child}
      </div>
    </Popover>
  )
}

export default NextActionsPopup
