import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import Button from '../../../shared/components/FormElements/Button'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useModal } from '../../../shared/hooks/modal-hook'

import CollapsibleCard from '../../../shared/components/UIElements/CollapsibleCard'
import { useNavigate, useParams } from 'react-router-dom'
import Modals from '../../../shared/enums/Modals'
import errorHandler from '../../../shared/util/errorHandler'
import { projectActions } from '../../../store/project'

import './CardAdminDevZone.css'

const CardAdminDevZone = ({ userId }) => {
  const authToken = useSelector(state => state.auth.token)
  const { sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const params = useParams()
  const navigate = useNavigate()

  const [newJob, setNewJob] = useState({ repeats: {} })
  const [existingJob, setExistingJob] = useState()

  const customer = useSelector(state => state.admin.customer)
  let passwordInputRef = useRef()
  const dispatch = useDispatch()

  const projectId = useSelector(state => state.project._id)
  const modelAlgorithm = useSelector(state => state.project.modelAlgorithm)

  useEffect(() => {
    getExistingCheckDataJob()
  }, [])

  const getExistingCheckDataJob = async () => {
    try {
      const jobResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getJob/${userId}?type=check_data`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )

      if (jobResult.message === 'no job') {
        setExistingJob(null)
      } else {
        setExistingJob(jobResult)
        setNewJob(jobResult)
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  const deleteUser = () => {
    const modalConfig = {
      title: 'You will delete user. Are you sure?',
      modalComponent: (
        <div>
          <h3>
            Enter the user deletion password. If you do not know the password,
            please contact the dev team.
          </h3>
          <input type="text" id="passwordInput" ref={passwordInputRef} />
        </div>
      ),
      text: '',
      actions: [
        {
          text: 'Cancel',
          callback() { },
        },
        {
          text: 'Confirm',
          callback() {
            const enteredPassword = passwordInputRef.current.value
            let pass = process.env.REACT_APP_DELETE_USER_PASSWORD

            //if the entered password is correct, delete the user
            if (enteredPassword === pass) {
              try {
                sendRequest(
                  `${process.env.REACT_APP_BACKEND_URL}/dev/${params.id}`,
                  'DELETE',
                  null,
                  {
                    Authorization: 'Bearer ' + authToken,
                  },
                ).then(res => {
                  navigate('/dev-admin-panel')
                  toast.warn('User deleted successfully.')
                })
              } catch (err) {
                errorHandler(err)
              }
            } else {
              alert('Wrong password')
            }
          },
        },
      ],
    }
    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
    })
  }

  const checkData = async () => {
    const data = {
      isActive: true,
    }
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/triggerCheckDataJob/`,
        'POST',
        JSON.stringify({
          userId,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )
      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const gotoModelLogs = async uid => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/dev/getModelLogs`
    if (uid) url += `/${uid}`
    const responseData = await sendRequest(url, 'GET', null, {
      Authorization: 'Bearer ' + authToken,
    })

    openModal({
      type: Modals.MODEL_LOGS,
      closeOnClickOutside: true,
      modelLogs: responseData.logs,
    })
  }

  const moveToVersion3 = async uid => {
    console.log('uid', uid)

    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: 'User script, data prep and model will be moved to version 3. Are you sure?',
    })

    if (confirmed) {
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/moveUserToVersion3`,
          'POST',
          JSON.stringify({ userId: uid, projectId: projectId }),
          {
            Authorization: 'Bearer ' + authToken,
            'Content-Type': 'application/json',
          },
        )

        dispatch(
          projectActions.setModelAlgorithm('Random Forest'),
        )
        toast.success("User succesfully moved to v3. \n Don't forget to update collector in GTM for non-shopify users.")
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  const moveBackToVersion2 = async uid => {
    console.log('uid', uid)

    const confirmed = await openModal({
      type: Modals.ARE_YOU_SURE,
      question: 'Move back to version 2?',
    })

    if (confirmed) {
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/moveUserToVersion2`,
          'POST',
          JSON.stringify({ userId: uid, projectId: projectId }),
          {
            Authorization: 'Bearer ' + authToken,
            'Content-Type': 'application/json',
          },
        )

        dispatch(
          projectActions.setModelAlgorithm('Random Forest'),
        )
        toast.success("User succesfully moved back to v2. \n Don't forget to update collector in GTM for non-shopify users.")
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  const onScheduleJobDayChange = e => {
    setNewJob({
      ...newJob,
      repeats: {
        ...newJob.repeats,
        [e.target.id]: parseInt(e.target.value),
      },
    })
  }

  const saveCheckDataJob = async e => {
    console.log(newJob)
    try {
      const jobResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createJob`,
        'POST',
        JSON.stringify({
          userId: userId,
          type: 'check_data',
          projectType: '',
          repeats: {
            months: 0,
            days: newJob.repeats.days,
            hours: newJob.repeats.hours,
            minutes: 0,
          },
          isCustom: true,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )
      if (jobResult === 'running_job') {
        toast.warning('Job already exists!')
      } else {
        toast.success('Changes saved!')
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  const gtmOnboardingDone = async () => {
    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/gtmOnboardingDone`,
        'POST',
        JSON.stringify({
          userId: userId,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }
  const refreshToken = async () => {
    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/refreshAccessToken`,
        'POST',
        JSON.stringify({ userId: userId, forceEvenIfThereIsError: true }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )
      if (result.accessToken) {
        console.log(result.accessToken)
        toast.success(`Token refreshed! Expires in ${result.expiresIn} seconds`)
      } else {
        errorHandler({ message: `Failed to refresh token: ${result.message}` })
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <CollapsibleCard
      title="Dev Zone"
      className="ruby ai-audience-model-card dev-zone"
    >
      <div className="row">
        {customer.connectFlow?.dataCollectionStarted ? null : (
          <Button className="action small" onClick={gtmOnboardingDone}>
            GTM Onboarding Done
          </Button>
        )}

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={refreshToken}
        >
          Trigger refresh token
        </Button>

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={checkData}
        >
          Trigger Check Data Job
        </Button>

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={() => {
            gotoModelLogs(userId)
          }}
        >
          Model Logs of User
        </Button>

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={() => {
            gotoModelLogs()
          }}
        >
          All Model Logs
        </Button>

        {modelAlgorithm !== 'Random Forest' ? (
          <Button
            className="small button--attention-drawer"
            style={{ fontSize: '0.8em' }}
            onClick={() => {
              moveToVersion3(userId)
            }}
          >
            Move to Version 3
          </Button>
        ) : (
          <Button
            className="small button--attention-drawer"
            style={{ fontSize: '0.8em' }}
            onClick={() => {
              moveBackToVersion2(userId)
            }}
          >
            Move back to Version 2
          </Button>
        )}

        <Button danger className="action small" onClick={deleteUser}>
          Delete User
        </Button>
      </div>

      <div className="edit-job-box">
        <div className="attribute-name">
          {existingJob ? 'Job exists. Update' : 'No check data job. Create New'}
        </div>
        <div className="content-box">
          {/*  <div className="row">
            <label>Starts on</label>
            <div className="type-row">
              <div className="date-input-cont">
                <DatePicker
                  className="scheduledatepicker"
                  style={{ width: "144px" }}
                  pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                  selected={jobDate}
                  onChange={(date) => setJobDate(new Date(date))}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <FaCalendarAlt className="icon" />
              </div>
            </div>
          </div> */}

          <div className="type-row row" style={{ margin: '0.5em 0' }}>
            <label>Run job every</label>
            <div className="text-edit row">
              <input
                id="days"
                className="repeat-input"
                type="number"
                value={newJob.repeats?.days || 0}
                onChange={onScheduleJobDayChange}
              />
              <label>days</label>

              <input
                id="hours"
                className="repeat-input"
                type="number"
                value={newJob.repeats?.hours || 0}
                onChange={onScheduleJobDayChange}
              />
              <label>hours</label>
            </div>
          </div>
          <Button onClick={saveCheckDataJob}>Save Check Data Job</Button>
        </div>
      </div>
    </CollapsibleCard>
  )
}

export default CardAdminDevZone
