import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { FaCalendarCheck } from "react-icons/fa";
import "./StoreConnect.css";
import { PopupButton } from "react-calendly";

const StoreConnect = (props) => {
  const auth = useSelector((state) => state.auth);
  let user = auth.user;
  if (props.userInView) {
    user = props.userInView
  }

  return (
    <div>
      {
        // we always display success if user is shopify, because even if the connection is not completed, we fix it in dailyUserChecker cronjob
        user?.connectFlow?.dataCollectionStarted || user?.shopify ?
          <div className="no-data">
            <div className="row">
              Status:
              <div className="dot"></div>
              <span style={{ fontWeight: "600" }}>Active</span>
            </div>

            <p>Enhencer has successfully connected to your store.</p>
          </div>
          : user?.crmDetails?.bookedOnboardingMeeting ?
            <div className="no-data">
              <div className="row">
                Status:
                <div className="dot"></div>
                <span style={{ fontWeight: "600" }}>Booked an onboarding meeting</span>
              </div>
              <p> Waiting the support team to take action. You do not need to do anything right now.</p>
              <div className="center"><FaCalendarCheck fontSize={24}></FaCalendarCheck></div>
            </div>
            :
            <div className="no-data">
              <div>
                Your visitor data is not connected to Enhencer. An installation should be done on your store.
                <br></br>

                <div className="connect-options">
                  <PopupButton
                    url={process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_LINK}
                    rootElement={document.getElementById('root')}
                    prefill={{
                      email: user?.email || '',
                      name: user?.name || '',
                    }}
                    text="Book an onboarding meeting"
                    className={`option-button row ${props.disabled ? "disabled" : ""}`}
                  />
                </div>

              </div>
            </div>
      }
    </div >

  );
};

export default StoreConnect;
