import { WORKBENCH_SIZE } from '../../../support/constants'
import temp6bg from '../../../../shared/assets/images/ai-creative/temp6bg.svg'
import temp6bg2 from '../../../../shared/assets/images/ai-creative/temp6bg2.svg'

export const Template6Config = {
  name: 'Frosted Focus',
  containerId: 'template6-carousel-single',
  backgroundColor: 'color2',
  hasDiscount: true,
  elements: [
    // First Background Layer SVG
    {
      id: 'background-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp6bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#006F88',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: { x: WORKBENCH_SIZE.width / 2, y: WORKBENCH_SIZE.height / 2 },
      scale: { maxWidth: 650, maxHeight: 750 },
      center: true,
      dynamicSrc: 'productImage',
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: { x: 0, y: 0 },
      scale: { width: WORKBENCH_SIZE.width, height: WORKBENCH_SIZE.height },
      src: temp6bg2,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: { x: 35, y: 40 },
      width: 365,
      text: `{{productName}}`,
      fontIndex: 1,
      fontSize: 60,
      toUpperCase: true,
      lines: false,
      center: false,
      color: 'color1',
      align: 'left',
      maxLines: 1,
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: { x: 35, y: 104 },
      width: 365,
      text: `{{productPrice}}`,
      fontFamily: 'Bayon',
      fontIndex: 2,
      fontSize: 80,
      color: 'color1',
      align: 'left',
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 300,
        y: WORKBENCH_SIZE.height - 170,
      },
      width: 600,
      text: `30% DISCOUNT!`,
      fontFamily: 'Bayon',
      fontIndex: 1,
      fontSize: 73,
      letterSpacing: 0.8,
      color: 'color1',
      align: 'center',
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 300,
        y: WORKBENCH_SIZE.height - 100,
      },
      width: 600,
      text: `USE CODE: GET30`,
      fontFamily: 'Bayon',
      fontIndex: 2,
      fontSize: 63,
      letterSpacing: 3.5,
      color: 'color3',
      align: 'center',
    },
  ],
}
