import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import auth, { authActions } from '../../store/auth'
import { useAuth } from './auth-hook'
import helper from '../util/helper'
import { generalActions } from '../../store/general'
import cookies from '../util/cookies'
import analytics from '../util/analytics'
import { useAdmin } from './admin-hook'
import errorHandler from '../util/errorHandler'
import { useConstants } from './constants-hook'
import { useNavigate } from 'react-router-dom'
export const useUser = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const { getPerformanceManagerProfileForUser } = useAdmin()
  const { pricingConstants } = useConstants()
  const { logout } = useAuth()
  const navigate = useNavigate()
  // const { user } = useSelector(state => state.user)
  // user slice is not used right now, use state.auth.user
  const user = useSelector(state => state.auth.user)
  const { authToken, authTokenInStorage, authTokenExpirationDateInStorage, adminTokenInStorage } =
    useAuth()

  const storedData = JSON.parse(localStorage.getItem('userData') || '[]')


  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser')) || {}

  const getProfile = async ({ token, shouldSetRedux = true }) => {
    if (!token && !authTokenInStorage) {
      dispatch(authActions.setIsLoginStateReady(true))
      return
    }

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/profile?viewingAsUser=${viewingAsUser.userToken ? 1 : 0}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + (token ?? authTokenInStorage),
        },
      )

      const { user } = responseData
      //dispatch(generalActions.setAnStats(anStats))
      const updatedUser = helper.getUIReadyUserObject({ user })
      if (updatedUser.crmDetails?.performanceManager) {
        updatedUser.crmDetails.performanceManagerProfile =
          await getPerformanceManagerProfileForUser({
            performanceManager: updatedUser.crmDetails.performanceManager,
            authToken: token ?? authTokenInStorage,
          })
      }

      setEmailInLocalStorageIfNotExists({
        email: updatedUser.email,
        token: token ?? authTokenInStorage,
      })

      // We added the authActions.updateUserObj temporarily because we are currently using
      // the user object from the auth slice in all of our components.
      // We will switch to using the user object from the user slice later,
      // but for the sake of simplicity, we still storing the user in the auth slice too.
      // TODO: Remove the line below after switching and use userActions.setUser instead.
      if (shouldSetRedux) {
        dispatch(
          authActions.setAuthObject({
            user: updatedUser,
            token: token ?? authTokenInStorage,
            tokenExpirationDate: authTokenExpirationDateInStorage,
            isLoginStateReady: true,
            adminToken: adminTokenInStorage

          })
        )
      }

      return updatedUser
    } catch (err) {
      if (err?.status === 401 || err?.status === 404) {
        logout({ isForced: true })
      }

      if (cookies.get("lo")) {
        errorHandler(err)
      } else {
        logout({ isForced: true })
        dispatch(generalActions.setPageTitle({ pageTitle: null }))
        cookies.set("lo", "1", 10)
      }
    }
  }

  const setUtmsOfSignup = async ({ utms }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/setUtmsOfSignup`,
        'POST',
        JSON.stringify({ userId: user.id, utms }),
        {
          Authorization: 'Bearer ' + authToken,
          "Content-Type": "application/json",
        },
      )
      return responseData
    } catch (err) {
      console.log(err)
    }
  }

  const setEmailInLocalStorageIfNotExists = ({ token, email }) => {
    let storedUserData = JSON.parse(localStorage.getItem('userData') || '[]')
    let updatedData = [...storedData]
    let foundEntryIndexInLocalStorage = helper.findIndex(storedUserData, "token", token)

    if (storedUserData[foundEntryIndexInLocalStorage] && !storedUserData[foundEntryIndexInLocalStorage].email) {
      updatedData[foundEntryIndexInLocalStorage] = {
        ...storedUserData[foundEntryIndexInLocalStorage],
        email
      }
      localStorage.setItem('userData', JSON.stringify(updatedData))
    }
  }


  const setConnectFlowStep = async ({ step, shouldOverwrite = false }) => {



    if ((user.connectFlow && !user.connectFlow[step]) || !user.connectFlow || shouldOverwrite) {
      if (step === "createdFullFunnelCampaign" && user.connectFlow?.createdFullFunnelCampaign) {
        return
      }

      let newConnectFlow = {
        ...user.connectFlow,
        [step]: Date.now(),
      }
      let url = `${process.env.REACT_APP_BACKEND_URL}/users/updateConnectFlow`

      try {
        await sendRequest(
          url,
          'POST',
          JSON.stringify({
            userId: user.id,
            connectFlow: newConnectFlow,
          }),
          {
            Authorization: 'Bearer ' + authToken,
            'Content-Type': 'application/json',
          },
        )

        dispatch(authActions.updateConnectFlow(newConnectFlow))
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  const setBookedOnboardingMeeting = async ({ userMeetingData }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/bookedOnboardingMeeting`

    try {
      await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: user.id,
          userMeetingData: userMeetingData,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        authActions.updateUserObj({
          ...user,
          crmDetails: {
            ...user.crmDetails,
            bookedOnboardingMeeting: true,
          },
        }),
      )
    } catch (err) {
      errorHandler(err)
    }
  }

  const createAdHealthCheckupMeeting = async ({ userMeetingData }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/createAdHealthCheckupMeeting`

    try {
      await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: user.id,
          userMeetingData: userMeetingData,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      analytics.bookAdHealthCheckupMeeting()

      dispatch(
        authActions.updateUserObj({
          ...user,
          crmDetails: {
            ...user.crmDetails,
            adHealthCheckupMeeting: userMeetingData,
          },
        }),
      )
    } catch (err) {
      errorHandler(err)
    }
  }

  const createSupportMeeting = async ({ userMeetingData }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/createSupportMeeting`

    try {
      await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: user.id,
          userMeetingData: userMeetingData,
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      analytics.bookASupportMeeting()
      let supportMeetings = user.crmDetails.supportMeetings
        ? [...user.crmDetails.supportMeetings]
        : []
      supportMeetings.push(userMeetingData)

      dispatch(
        authActions.updateUserObj({
          ...user,
          crmDetails: {
            ...user.crmDetails,
            supportMeetings,
          },
        }),
      )
    } catch (err) {
      errorHandler(err)
    }
  }

  const getPackageFeatures = () => {
    const activePlanName = user.subscription?.plan
    const pricingVersion = user.subscription?.pricingVersion
    const activePlanInterval = user.subscription?.interval

    const packagesInUse = ['v2', 'v3', 'v4', 'v5'].includes(pricingVersion)
      ? activePlanInterval === 'MONTHLY' || activePlanInterval === 'Monthly'
        ? pricingConstants.monthlyPackages
        : pricingConstants.yearlyPackages
      : pricingConstants.v1_packages

    let p = helper.findBy(packagesInUse, 'name', activePlanName) || {
      name: 'Custom Plan',
      minAdSpent: '10,000',
      price: -1,
      paymentInterval: 'MONTHLY',
      actionName: 'Book a Demo',
    }
    let finalPackage = { ...p }

    if (p.name === 'Ad Health Checkup') {
      //do nothing
    } else {
      if (p.features) {
        finalPackage.features = p.features
      }
    }

    return finalPackage
  }

  const saveUserProfileInfo = async ({
    userId,
    name,
    companyName,
    title,
    url,
    image,
    contactNumber,
    contactEmail,

    removeImageBackground = false,
    imageHasAlphaChannel = false,
  }) => {
    try {
      const updatePayload = {
        ...user,
      }
      const formData = new FormData()

      if (name) {
        formData.append('name', name)
        updatePayload.name = name
      }
      if (companyName) {
        formData.append('companyName', companyName)
        updatePayload.companyName = companyName
      }
      if (title) {
        formData.append('title', title)
        updatePayload.title = title
      }
      if (url) {
        formData.append('url', url)
        updatePayload.url = url
      }
      if (contactNumber) {
        formData.append('contactNumber', contactNumber)
        updatePayload.contactNumber = contactNumber
      }
      if (contactEmail) {
        formData.append('contactEmail', contactEmail)
        updatePayload.contactEmail = contactEmail
      }
      if (image) {
        formData.append('image', image)
      }

      if (removeImageBackground) {
        formData.append('removeImageBackground', removeImageBackground)
        formData.append('imageHasAlphaChannel', imageHasAlphaChannel)
      }

      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}`,
        'PATCH',
        formData,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )

      if (image) {
        updatePayload.image = result.user.image
        updatePayload.bgRemovedImage = result.bgRemovedImage
      }

      dispatch(authActions.updateUserObj(updatePayload))

      if (result.bgRemovedImage) {
        return {
          bgRemovedImage: result.bgRemovedImage,
        }
      } else {
        return true
      }
    } catch (err) {
      throw err
    }
  }

  const goBackToUserDetail = async () => {
    let viewingAsUserData = JSON.parse(sessionStorage.getItem('viewingAsUser'))
    if (viewingAsUserData?.userRole === 'Agency') {
      //close this tab
      window.close()
    } else {
      sessionStorage.removeItem('viewingAsUser')
      //in the same tab
      window.open(`/admin-panel/user-detail/${viewingAsUserData.userId}`, '_self')
    }
  }

  return {
    getProfile,
    setConnectFlowStep,
    setUtmsOfSignup,
    setBookedOnboardingMeeting,
    createAdHealthCheckupMeeting,
    createSupportMeeting,
    getPackageFeatures,
    saveUserProfileInfo,
    goBackToAdmin: goBackToUserDetail,
  }
}
