import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useConstants } from '../../../shared/hooks/constants-hook'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useForm } from '../../../shared/hooks/form-hook'
import Input from '../../../shared/components/FormElements/Input'
import CollapsibleCard from '../../../shared/components/UIElements/CollapsibleCard'
import { adminActions } from '../../../store/admin'

import './CardAdminSubscription.css'
import dateFormatter from '../../../shared/util/dateFormatter'
import { FaCheck } from 'react-icons/fa'
import CustomDatePicker from '../../../shared/components/UIElements/CustomDatePicker'
import CardAdminCustomSubscriptionPlan from './CardAdminCustomSubscriptionPlan'
import PrivateComponent from '../../../shared/components/UIElements/PrivateComponent'
import errorHandler from '../../../shared/util/errorHandler'

function CardAdminSubscription({ isSignUp }) {
  const { privateConstants, publicConstants, pricingConstants } = useConstants()

  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const customer = useSelector(state => state.admin.customer)
  const authToken = useSelector(state => state.auth.token)
  const [isDirty, setIsDirty] = useState(false)
  const [contractEndDate, setContractEndDate] = useState(null)
  const [saving, setSaving] = useState(false)
  const [creatingNewSubscription, setCreatingNewSubscription] = useState(false)
  const [planDetailsVisible, setPlanDetailsVisible] = useState(false)
  const [oldStatus, setOldStatus] = useState(customer.crmDetails?.subscription?.status)

  const [subscriptionCreatedAt, setSubscriptionCreatedAt] = useState(new Date())

  const [
    subscriptionFormState,
    subscriptionInputHandler,
    setSubscriptionFormData,
  ] = useForm(
    {
      status: {
        value:
          customer.crmDetails?.subscription?.status ||
          privateConstants.subscriptionStatusOptions[0],
        isValid: true,
      },
      isPaused: {
        value: customer.crmDetails?.subscription?.isPaused || 'No',
        isValid: true,
      },
      type: {
        value: customer.crmDetails?.subscription?.type || '',
        isValid: true,
      },
      mrr: {
        value: customer.crmDetails?.subscription?.mrr || 0,
        isValid: true,
      },
      price: {
        value: customer.crmDetails?.subscription?.price
          || (customer.crmDetails?.subscription?.mrr *
            (customer.crmDetails?.subscription?.period === "YEARLY" ? 12 : 1)
          )
          || 0,
        isValid: true,
      },
      currency: {
        value: customer.crmDetails?.subscription?.currency || 'USD',
        isValid: true,
      },
      period: {
        value: customer.crmDetails?.subscription?.period || 'MONTHLY',
        isValid: true,
      },
      plan: {
        value: customer.crmDetails?.subscription?.plan || customer.subscription?.plan || 'Custom',
        isValid: true,
      },
      contractCurrencyRate: {
        value:
          customer.crmDetails?.subscription?.contractCurrencyRate ||
          pricingConstants?.usdTlCurrencyRate,
        isValid: true,
      },
      usageChargePercentage: {
        value: customer.subscription?.usageChargePercentage 
          ? (customer.subscription.usageChargePercentage * 100).toFixed(2) 
          : 0,
        isValid: true,
      },
    },
    true,
  )


  const isTurningToRecurring = (!customer.subscription?.status || customer.subscription.status !== "active")
  && (oldStatus !== "Recurring" && subscriptionFormState.inputs.status.value === "Recurring")
  
  // We compare the current input values to the initial values at each render.
  useEffect(() => {
    detectChanges()
  })

  useEffect(() => {
    setSubscriptionFormData(
      {
        status: {
          value:
            customer.crmDetails?.subscription?.status ||
            privateConstants.subscriptionStatusOptions[0],
          isValid: true,
        },
        isPaused: {
          value: customer.crmDetails?.subscription?.isPaused || 'No',
          isValid: true,
        },
        type: {
          value: customer.crmDetails?.subscription?.type || '',
          isValid: true,
        },
        mrr: {
          value: customer.crmDetails?.subscription?.mrr || 0,
          isValid: true,
        },
        price: {
          value: customer.crmDetails?.subscription?.price
            || (customer.crmDetails?.subscription?.mrr *
              (customer.crmDetails?.subscription?.period === "YEARLY" ? 12 : 1)
            )
            || 0,
          isValid: true,
        },
        currency: {
          value: customer.crmDetails?.subscription?.currency || 'USD',
          isValid: true,
        },
        period: {
          value: customer.crmDetails?.subscription?.period || 'MONTHLY',
          isValid: true,
        },
        plan: {
          value: customer.crmDetails?.subscription?.plan || customer.subscription?.plan || 'Custom',
          isValid: true,
        },
        contractCurrencyRate: {
          value:
            customer.crmDetails?.subscription?.contractCurrencyRate ||
            pricingConstants.usdTlCurrencyRate,
          isValid: true,
        },
        usageChargePercentage: {
          value: customer.subscription?.usageChargePercentage 
            ? (customer.subscription.usageChargePercentage * 100).toFixed(2) 
            : 0,
          isValid: true,
        },
      },
      true,
    )

    setOldStatus(customer.crmDetails?.subscription?.status)
  }, [customer])

  const save = async ({ prefilledQuery }) => {
    try {
      setSaving(true)

      const mrr = parseFloat(subscriptionFormState.inputs.price.value) / (subscriptionFormState.inputs.period.value === "YEARLY" ? 12 : 1)
      let queryData = {}

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.status']) {
        queryData['crmDetails.subscription.status'] = subscriptionFormState.inputs.status.value
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.isPaused']) {
        queryData['crmDetails.subscription.isPaused'] = subscriptionFormState.inputs.isPaused.value
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.type']) {
        queryData['crmDetails.subscription.type'] = subscriptionFormState.inputs.type.value
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.mrr']) {
        queryData['crmDetails.subscription.mrr'] = mrr
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.price']) {
        queryData['crmDetails.subscription.price'] = parseFloat(subscriptionFormState.inputs.price.value)
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.currency']) {
        queryData['crmDetails.subscription.currency'] = subscriptionFormState.inputs.currency.value
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.period']) {
        queryData['crmDetails.subscription.period'] = subscriptionFormState.inputs.period.value
      }

      if (!prefilledQuery || !prefilledQuery['crmDetails.subscription.contractCurrencyRate']) {
        queryData['crmDetails.subscription.contractCurrencyRate'] = parseFloat(subscriptionFormState.inputs.contractCurrencyRate.value)
      }

      if(oldStatus === "Recurring" && subscriptionFormState.inputs.status.value === "Churn"){
        queryData['crmDetails.contractEndDate'] = new Date()
        queryData['subscription.status'] = "passive"
      }
      
      if (subscriptionFormState.inputs.status.value === 'Recurring') {
        queryData['crmDetails.contractEndDate'] = null
      }

      const usageChargeValue = parseFloat((parseFloat(subscriptionFormState.inputs.usageChargePercentage.value) / 100).toFixed(4));

      let reduxUpdateData = {
        ...customer,
        crmDetails: {
          ...customer.crmDetails,
          subscription: {
            ...customer.crmDetails.subscription,
            status: subscriptionFormState.inputs.status.value,
            isPaused: subscriptionFormState.inputs.isPaused.value,
            type: subscriptionFormState.inputs.type.value,
            mrr: mrr,
            price: parseFloat(subscriptionFormState.inputs.price.value),
            currency: subscriptionFormState.inputs.currency.value,
            period: subscriptionFormState.inputs.period.value,
            contractCurrencyRate: parseFloat(
              subscriptionFormState.inputs.contractCurrencyRate.value,
            ),
            usageChargePercentage: isNaN(usageChargeValue) ? 0 : usageChargeValue
          },
        },
        subscription: customer.subscription ? {
          ...customer.subscription,
          usageChargePercentage: isNaN(usageChargeValue) ? 0 : usageChargeValue
        } : null
      }

      if (prefilledQuery) {
        queryData = {
          ...prefilledQuery,
          ...queryData
        }

        reduxUpdateData = {
          ...reduxUpdateData,
          subscription: prefilledQuery.subscription,
          leadDetails: {
            isWon: true
          }
        }
      }

  

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
          'PATCH',
          JSON.stringify(queryData),
          {
            Authorization: 'Bearer ' + authToken,
            'Content-Type': 'application/json',
          },
        )

        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/createJob`,
          "POST",
          JSON.stringify({
            userId: customer.id,
            type: "check_data",
            projectType: "",
            repeats: {
              months: 0,
              days: 0,
              hours: 6,
              minutes: 0,
            },
            isCustom: false,
          }),
          {
            Authorization: "Bearer " + authToken,
            "Content-Type": "application/json",
          }
        );
      } catch (err) {
        console.log("error happend while creating the job!'", err)
      }

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          data: reduxUpdateData,
          isSignUp
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const changeContractEndDate = async date => {
    setContractEndDate(new Date(date))
  }

  const subscriptionStatusChanged = status => {
    if (status === 'Churn') {
      changeContractEndDate(new Date())
    }
  }

  const detectChanges = () => {
    const isStatusDirty =
      subscriptionFormState.inputs.status.value !==
      (customer.crmDetails?.subscription?.status ||
        privateConstants.subscriptionStatusOptions[0])

    const isPausedDirty =
      subscriptionFormState.inputs.isPaused.value !==
      (customer.crmDetails?.subscription?.isPaused || 'No')

    const isTypeDirty =
      subscriptionFormState.inputs.type.value !==
      (customer.crmDetails?.subscription?.type || '')

    const oldSubscriptionPrice = customer.crmDetails?.subscription?.price
      || (
        parseFloat(customer.crmDetails?.subscription?.mrr)
        * (customer.crmDetails?.subscription?.period === "YEARLY" ? 12 : 1)
      )

    const isPriceDirty =
      parseFloat(subscriptionFormState.inputs.price.value) !==
      (oldSubscriptionPrice || 0)

    const isCurrencyDirty =
      subscriptionFormState.inputs.currency.value !==
      (customer.crmDetails?.subscription?.currency || 'USD')

    const isPeriodDirty =
      subscriptionFormState.inputs.period.value !==
      (customer.crmDetails?.subscription?.period || 'MONTHLY')

    const isContractCurrencyRateDirty =
      parseFloat(subscriptionFormState.inputs.contractCurrencyRate.value) !==
      (customer.crmDetails?.subscription?.contractCurrencyRate ||
        pricingConstants.usdTlCurrencyRate)

    const isUsageChargePercentageDirty =
      parseFloat(subscriptionFormState.inputs.usageChargePercentage.value) !==
      (customer.subscription?.usageChargePercentage 
        ? (customer.subscription.usageChargePercentage * 100).toFixed(2) 
        : 0)

    setIsDirty(
      isStatusDirty ||
      isPausedDirty ||
      isTypeDirty ||
      isPriceDirty ||
      isCurrencyDirty ||
      isPeriodDirty ||
      isContractCurrencyRateDirty ||
      isUsageChargePercentageDirty
    )
  }


  const createNewUserSubscription = async () => {
    if (!planDetailsVisible) {
      setPlanDetailsVisible(true)
      return
    }

    if (subscriptionFormState.inputs.status.value !== "Recurring") {
      toast.warn("Status has to be Recurring")
    } else if (!subscriptionFormState.inputs.price.value) {
      toast.warn("Enter a valid price")
    } else {
      setCreatingNewSubscription(true)

      const ONE_MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000
      const ONE_YEAR_IN_MILLISECONDS = 365 * 24 * 60 * 60 * 1000

      const usageChargeValueForNewSub = parseFloat((parseFloat(subscriptionFormState.inputs.usageChargePercentage.value) / 100).toFixed(4));
      const newSubscription = {
        "source": subscriptionFormState.inputs.type.value,
        "isSetByAdmin": true,
        "plan": subscriptionFormState.inputs.plan.value,
        "paymentInterval": subscriptionFormState.inputs.period.value,
        "interval": subscriptionFormState.inputs.period.value,
        "price": subscriptionFormState.inputs.price.value,
        "currency": subscriptionFormState.inputs.currency.value,

        "createdDate": subscriptionCreatedAt.getTime(),
        "lastPaymentDate": subscriptionCreatedAt.getTime(),
        "nextPaymentDate": subscriptionCreatedAt.getTime()
          + (subscriptionFormState.inputs.period.value === "YEARLY" ? ONE_YEAR_IN_MILLISECONDS : ONE_MONTH_IN_MILLISECONDS),

        "status": "active",
        "pricingVersion": "v5",
        "usageChargePercentage": isNaN(usageChargeValueForNewSub) ? 0 : usageChargeValueForNewSub,
      }

      const prefilledQuery = {
        "subscription": newSubscription,
        "leadDetails.isWon": true,
        "crmDetails.subscription.status": "Recurring",
        "crmDetails.subscription.mrr": parseFloat(subscriptionFormState.inputs.price.value / (subscriptionFormState.inputs.period.value === "YEARLY" ? 12 : 1)),
        "crmDetails.subscription.price": parseFloat(subscriptionFormState.inputs.price.value),
        "crmDetails.subscription.isPaused": "No",
        "crmDetails.subscription.type": subscriptionFormState.inputs.type.value,
        "crmDetails.subscription.currency": subscriptionFormState.inputs.currency.value,
        "crmDetails.subscription.period": subscriptionFormState.inputs.period.value,
        "crmDetails.subscription.paymentInterval": subscriptionFormState.inputs.period.value,
        "crmDetails.subscription.contractCurrencyRate": pricingConstants.usdTlCurrencyRate,
        "crmDetails.contractStartDate": subscriptionCreatedAt,
        "crmDetails.subscription.usageChargePercentage": isNaN(usageChargeValueForNewSub) ? 0 : usageChargeValueForNewSub,
      }

      save({ prefilledQuery })

      setCreatingNewSubscription(false)
    }
  }


  return (
    <CollapsibleCard title="Subscription" className="emerald subscription-card">
      <div className="inputs-wrapper">
        <div className="input-cell">
          <span>Status</span>
          <Input
            element="dropdown"
            initialValue={subscriptionFormState.inputs.status.value}
            forceValue={subscriptionFormState.inputs.status.value}
            id="status"
            type="text"
            onInput={subscriptionInputHandler}
            options={privateConstants.subscriptionStatusOptions}
            apply={subscriptionStatusChanged}
          />
        </div>
        <div className="input-cell">
          <span>Type</span>
          <Input
            element="dropdown"
            initialValue={subscriptionFormState.inputs.type.value}
            forceValue={subscriptionFormState.inputs.type.value}
            id="type"
            type="text"
            onInput={subscriptionInputHandler}
            options={privateConstants.subscriptionTypeOptions}
          />
        </div>
        <div className="input-cell">
          <span>Price</span>
          <div className="half-inputs">
            <Input
              element="input"
              placeholder="Amount"
              initialValue={subscriptionFormState.inputs.price.value}
              forceValue={subscriptionFormState.inputs.price.value}
              id="price"
              type="text"
              onInput={subscriptionInputHandler}
            />
            <Input
              element="dropdown"
              placeholder="Currency"
              initialValue={subscriptionFormState.inputs.currency.value}
              forceValue={subscriptionFormState.inputs.currency.value}
              id="currency"
              type="text"
              options={privateConstants.currencyOptions}
              onInput={subscriptionInputHandler}
            />
          </div>
        </div>
        <div className="input-cell">
          <span>Critical</span>
          <Input
            element="dropdown"
            initialValue={subscriptionFormState.inputs.isPaused.value}
            forceValue={subscriptionFormState.inputs.isPaused.value}
            id="isPaused"
            type="text"
            onInput={subscriptionInputHandler}
            options={privateConstants.yesNoOptions}
          />
        </div>

        <div className="input-cell">
          <span>Currency Rate</span>
          <Input
            element="input"
            placeholder={pricingConstants.usdTlCurrencyRate?.toString()}
            initialValue={
              subscriptionFormState.inputs.contractCurrencyRate.value
            }
            forceValue={subscriptionFormState.inputs.contractCurrencyRate.value}
            id="contractCurrencyRate"
            type="text"
            onInput={subscriptionInputHandler}
          />
        </div>
        <div className="input-cell">
          <span>Period</span>
          <Input
            element="dropdown"
            initialValue={subscriptionFormState.inputs.period.value}
            forceValue={subscriptionFormState.inputs.period.value}
            id="period"
            type="text"
            onInput={subscriptionInputHandler}
            options={privateConstants.subscriptionPeriodOptions}
          />
        </div>
        <div className="input-cell">
          <span>Usage Charge (%)</span>
          <Input
            element="input"
            placeholder={pricingConstants.usdTlCurrencyRate?.toString()}
            initialValue={
              subscriptionFormState.inputs.usageChargePercentage.value
            }
            forceValue={subscriptionFormState.inputs.usageChargePercentage.value}
            id="usageChargePercentage"
            type="text"
            onInput={subscriptionInputHandler}
          />
        </div>
      </div>
      {customer.subscription?.usageCharges?.length &&
        <div className='usage-charges'>
          <div className='title'>Usage Charges</div>
          <table>
            <thead>
              <tr>
                <th>Created Date</th>
                <th>Amount</th>
                <th>Billing On</th>
              </tr>
            </thead>
            <tbody>
              {customer.subscription?.usageCharges.map(charge =>
                <tr key={charge.id}>
                  <td>{dateFormatter(new Date(charge.created_at), true)}</td>
                  <td>${charge.price}</td>
                  {/* <td className={customer.subscription?.usageCharges?.filter(charge => (new Date(charge.createdAt)).valueOf() > customer.subscription?.lastPaymentDate) ? 'billed billing-cell' : 'billing-cell'}> */}
                  <td className='billing-cell'>
                    <FaCheck className='icon' />
                    {customer.subscription.nextPaymentDate ? dateFormatter(customer.subscription.nextPaymentDate, true) : '-'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }



      <div className="inputs-wrapper single-line" style={{ marginTop: "2em" }}>
        {isDirty && !planDetailsVisible && !isTurningToRecurring &&
        (
            <div className="save-button-container">
              <button disabled={saving} className="save-button" onClick={() => { save({}) }}>
                {saving ? 'Saving' : 'Save'}
              </button>
            </div>
          )}
        {
          planDetailsVisible &&
          <React.Fragment>

            <div className="input-cell">
              <span>Plan</span>
              <Input
                element="dropdown"
                initialValue={subscriptionFormState.inputs.plan.value}
                forceValue={subscriptionFormState.inputs.plan.value}
                id="plan"
                type="text"
                onInput={subscriptionInputHandler}
                options={pricingConstants.monthlyPackages.map(p => p.name).concat(['Custom'])}
              />
            </div>

            <div className='input-cell date-picker-cont'>
              <span>Start Date</span>
              <CustomDatePicker placeholder="Start" date={subscriptionCreatedAt} onChange={setSubscriptionCreatedAt} />
            </div>
          </React.Fragment>
        }

        <div className='input-cell'>

          {isTurningToRecurring
          && <div className="save-button-container">
              <button disabled={creatingNewSubscription} className="save-button" onClick={createNewUserSubscription}>
                {saving ? 'Creating new subscription' : 'Create new subscription'}
              </button>
            </div>
          }
        </div>
      </div>

      <PrivateComponent permissions={['createCustomSubscription']}
        children={<CardAdminCustomSubscriptionPlan />}
      />



    </CollapsibleCard>
  )
}

export default CardAdminSubscription
