import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import { useEffect, useState } from 'react'
import cookies from '../util/cookies'
import { useAuth } from './auth-hook'
import errorHandler from '../util/errorHandler'



// Gets the config document from DB to check if the
// localStorage, sessionStorage should be cleaned
// or user should be forced to log out.

export const useConfig = () => {
  const { sendRequest } = useHttpClient()
  const userEmail = useSelector(state => state.auth.user?.email)
  const userId = useSelector(state => state.auth.user?.id)
  const isSpecialUser = useSelector(state => state.auth.user?.isSpecialUser)
  const [completedCheck, setCompletedCheck] = useState(false)
  const { logout } = useAuth()
  const getConfigInfo = async () => {
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/getConfigInfo`,
        'GET',
        null,
        { 'Content-Type': 'application/json' },
      )

      let config = result.config
      const version = config.version
      if (isSpecialUser) {
        config = config.admin
      } else if (config.users?.userIds?.includes(userId)) {
        config = config.users
      } else {
        return false
      }


      if (config.shouldClearSessionStorage) clearSessionStorage({ version })
      if (config.shouldClearLocalStorage) clearLocalStorage({ version })
      if (config.shouldLogout) handleLogout({ version })
      return true
    } catch (err) {
      errorHandler(err)
    }
  }

  useEffect(() => {
    if (!completedCheck) {
      setCompletedCheck(true)
      getConfigInfo()
    } // handle else case
  }, [userEmail])

  const clearSessionStorage = ({ version }) => {
    // cleared session storage
    let doneBefore = cookies.get(`cl_ss_${version}`)
    if (!doneBefore) {
      cookies.set(`cl_ss_${version}`, true, 999)
      sessionStorage.clear()
    }
  }
  const clearLocalStorage = ({ version }) => {
    // cleared local storage
    let doneBefore = cookies.get(`cl_ls_${version}`)
    if (!doneBefore) {
      cookies.set(`cl_ls_${version}`, true, 999)
      localStorage.clear()
    }
  }
  
  const handleLogout = ({ version }) => {
    // logged out
    let doneBefore = cookies.get(`cl_lo_${version}`)
    if (!doneBefore) {
      cookies.set(`cl_lo_${version}`, true, 999)
      logout({ isForced: true })
    }

  }


  return {
    getConfigInfo,
  }
}
