import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp9bg from '../../../../shared/assets/images/ai-creative/common_temp9bg.svg'
import common_temp9fg from '../../../../shared/assets/images/ai-creative/common_temp9fg.svg'

export const Template9Config = {
  name: 'Apperal #1',
  containerId: 'template9-common',
  backgroundColor: 'color2',
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 159,
        y: 118
      },
      scale: {
        width: WORKBENCH_SIZE.width * 0.705,
        height: WORKBENCH_SIZE.height * 0.806
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp9bg,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true
    },

    // Background SVG
    {
      id: 'foreground-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp9fg,
      isSvg: true,
      svgColor: 'color2',
      svgCustomReplace: 'white',
      draggable: false,
      clickThrough: true
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width * 0.72,
        y: WORKBENCH_SIZE.height * 0.78
      },
      scale: {
        maxWidth: 140,
        maxHeight: 100
      },
      dynamicSrc: 'companyLogo',
      required: false
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 190,
        y: 150
      },
      width: 250,
      height: 300,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 45,
      fontStyle: 'bold',
      color: 'color2',
      text: '{{productName}}'
    },
    // Product Price Circle
    {
      id: 'productPriceCircle',
      type: 'circle',
      position: {
        x: WORKBENCH_SIZE.width - 160,
        y: WORKBENCH_SIZE.height / 2 + 50
      },
      radius: 100,
      fill: 'color2',
      stroke: 'color2',
      strokeWidth: 2
    },

    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 160 - 180 / 2,
        y: WORKBENCH_SIZE.height / 2 + 50 - 60 / 2
      },
      width: 180,
      height: 60,
      fontFamily: 'Be Vietnam Pro',
      fontSize: 40,
      fontStyle: 'bold',
      color: 'color3',
      center: true,
      verticalAlign: 'middle',
      align: 'center',
      text: '{{productPrice}}'
    }

  ]
}