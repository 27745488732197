import React, { useEffect, useState } from 'react'
import { useModal } from '../../hooks/modal-hook'

import DemoMeetingCard from '../../../admin/components/demo-meetings/DemoMeetingCard'
import SignUpCard from '../../../admin/components/sign-ups/SignUpCard'
import CustomerCard from '../../../admin/components/customers/CustomerCard'
import { useAdmin } from '../../hooks/admin-hook'
import InlineLoadingSpinner from '../UIElements/InlineLoadingSpinner'
import Button from '../FormElements/Button'
import { FaArrowRight } from 'react-icons/fa'


const PeekCard = props => {
  const { modalData } = useModal()

  const admin = useAdmin()

  const type = props.data.user.type
  let [userData, setUserData] = useState(null)

  useEffect(() => {
    if (!userData) {
      if (type === "Demo") {
        fetchUserDataDemo(props.data.user.id)
      } else if (type === "Sign Up") {
        fetchUserDataSignUp(props.data.user.id)
      } else if (type === "Customer") {
        fetchUserDataCustomer(props.data.user.id)
      }
    }
  }, [])


  const fetchUserDataDemo = async (userId) => {
    const fetchedUser = await admin.getOneDemo({ userId })
    setUserData(fetchedUser)
  }
  const fetchUserDataSignUp = async (userId) => {
    const fetchedUser = await admin.getOneSignUp({ userId })
    setUserData(fetchedUser)
  }
  const fetchUserDataCustomer = async (userId) => {
    const fetchedUser = await admin.getOneCustomer({ userId })
    setUserData(fetchedUser)
  }

  return (
    <div className="alarm-modal edit-alarm-modal">
      {userData ?
        type === "Demo"
          ? <DemoMeetingCard demoMeeting={userData} shownInOutsideOfList={true}></DemoMeetingCard>
          : type === "Sign Up"
            ? <SignUpCard signUp={userData} shownInOutsideOfList={true}></SignUpCard>
            : type === "Customer"
              ? <CustomerCard user={userData} shownInOutsideOfList={true}></CustomerCard>
              : null
        : <InlineLoadingSpinner></InlineLoadingSpinner>}

      <div className='go-to-buttons'>
        <Button onClick={() => {

          window.open(type === "Demo"
            ? "/admin-panel/demo-meetings"
            : type === "Sign Up"
              ? "/admin-panel/sign-ups"
              : type === "Customer"
                ? "/admin-panel"
                : null)
        }}>
          <div>
            <FaArrowRight></FaArrowRight>
          </div>
          <div>
            Go to {type} List
          </div>
        </Button>
      </div>
    </div>
  )
}


export default PeekCard
