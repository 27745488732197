import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import errorHandler from '../util/errorHandler'
import { useHttpClient } from './http-hook'

/**
 * Custom hook for uploading images
 */
export const useImageUpload = () => {
  const { user, token } = useSelector(state => state.auth)
  const adAccountId = useSelector(state => state.facebook.selectedAdAccount?.id)
  const { sendRequest } = useHttpClient()

  const getS3Url = useCallback(
    async ({ userId, fileName, fileType, folder }) => {
      try {
        const url = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/storage/getS3Url`,
          'POST',
          JSON.stringify({
            userId,
            fileName,
            fileType,
            folder,
          }),
          {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        )

        return url
      } catch (err) {
        errorHandler(err)
      }
    },
    [sendRequest, token],
  )

  const uploadImageToS3 = useCallback(
    async ({ file, folder, fileName, fileType }) => {
      try {
        const url = await getS3Url({
          userId: user.id,
          fileName,
          fileType,
          folder,
        })

        const response = await fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': fileType,
          },
        })

        if (!response.ok) {
          throw new Error('Failed to upload image')
        }

        const AWS_BASE_URL =
          process.env.REACT_APP_AWS_BASE_URL ||
          'https://enhencer-content.s3.amazonaws.com'

        if (folder === 'ai-creative') {
          return `${AWS_BASE_URL}/${folder}/${user.id}/complete/${fileName}`
        }

        return `${AWS_BASE_URL}/${folder}/${fileName}`
      } catch (err) {
        errorHandler(err)
      }
    },
    [getS3Url, user.id],
  )

  const uploadImageToFacebook = useCallback(async ({ file, accessToken }) => {
    file = file.replace(/^data:image\/(png|jpg|jpeg);base64,/, '') // Remove the prefix from the base64 string

    const formData = new FormData()
    formData.append('bytes', file)

    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_FACEBOOK_GRAPH_URL}${adAccountId}/adimages`,
        'POST',
        formData,
        {
          Authorization: `Bearer ${accessToken}`,
        }
      )

      return result.images.bytes
    } catch (err) {
      errorHandler(err)
    }
  }, [adAccountId])

  return {
    uploadImageToS3,
    uploadImageToFacebook,
  }
}
