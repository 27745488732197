import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHttpClient } from '../../shared/hooks/http-hook'
import './ActivityLogs.css'
import errorHandler from '../../shared/util/errorHandler'
import { FaSearch } from 'react-icons/fa'
import usePermissionHook from '../../shared/hooks/user-permission-hook'

const ActivityLogs = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [activityLogs, setActivityLogs] = useState(null)
	const [selectedUserId, setSelectedUserId] = useState('')
	const [targetUserId, setTargetUserId] = useState('')
	const [hasMore, setHasMore] = useState(false)
	const [lastDoneAt, setLastDoneAt] = useState(null)
	const [dateRange, setDateRange] = useState({
		from: '',
		to: '',
		fromTime: '00:00',
		toTime: '23:59'
	})
	const { sendRequest } = useHttpClient()
	const auth = useSelector(state => state.auth)
	const { getSpecialUsers } = usePermissionHook()
	const specialUsers = useSelector(state => state.admin.specialUsers)

	useEffect(() => {
		if (specialUsers.length) {
			return
		}
		getSpecialUsers()
	}, [specialUsers])

	const authHeader = {
		Authorization: 'Bearer ' + auth.token,
		'Content-Type': 'application/json',
	}

	const fetchActivityLogs = async (loadMore = false) => {
		if (!selectedUserId) {
			return
		}

		setIsLoading(true)
		try {
			const queryParams = new URLSearchParams()
			if (dateRange.from) {
				let fromDate = new Date(dateRange.from)
				fromDate.setHours(dateRange.fromTime.split(':')[0], dateRange.fromTime.split(':')[1])
				queryParams.append('from', fromDate)
			}
			if (dateRange.to) {
				let toDate = new Date(dateRange.to)
				toDate.setHours(dateRange.toTime.split(':')[0], dateRange.toTime.split(':')[1])
				queryParams.append('to', toDate)
			}
			if (targetUserId.trim()) queryParams.append('targetUserId', targetUserId.trim())
			if (loadMore && lastDoneAt) queryParams.append('lastDoneAt', lastDoneAt)

			const responseData = await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/getActivityLogs/${selectedUserId}?${queryParams.toString()}`,
				'GET',
				null,
				authHeader,
			)

			if (loadMore) {
				setActivityLogs(prev => [...prev, ...responseData.activityLogs])
			} else {
				setActivityLogs(responseData.activityLogs)
			}

			setHasMore(responseData.hasMore)
			setLastDoneAt(responseData.lastDoneAt)
		} catch (err) {
			errorHandler(err)
		}
		setIsLoading(false)
	}

	const handleLoadMore = () => {
		fetchActivityLogs(true)
	}

	const handleSearch = () => {
		setLastDoneAt(null)
		setHasMore(false)
		fetchActivityLogs(false)
	}

	const handleUserChange = (event) => {
		setSelectedUserId(event.target.value)
	}

	const handleDateChange = (event) => {
		const { name, value } = event.target
		setDateRange(prev => ({
			...prev,
			[name]: value
		}))
	}

	const handleTargetUserChange = (event) => {
		setTargetUserId(event.target.value)
	}

	const formatDate = (dateString) => {
		return new Date(dateString).toLocaleString()
	}

	return (
		<div className="activity-logs-container">
			<div className="layout">
				<div className="content">
					<div className="top">
						<h1>Activity Logs</h1>
						<div className="filters">
							<div className="filter-section">
								<select
									className="user-select"
									value={selectedUserId}
									onChange={handleUserChange}
								>
									<option value="">Select a manager user...</option>
									{(Object.values(specialUsers ?? {})).map(user => (
										<option key={user._id} value={user._id}>
											{user.email}
										</option>
									))}
								</select>
							</div>
							<div className="date-filters">
								<div className="date-input-wrapper">
									<label>From:</label>
									<div className="datetime-inputs">
										<input
											type="date"
											name="from"
											value={dateRange.from}
											onChange={handleDateChange}
											className="date-input"
										/>
										<input
											type="time"
											name="fromTime"
											value={dateRange.fromTime}
											onChange={handleDateChange}
											className="time-input"
										/>
									</div>
								</div>
								<div className="date-input-wrapper">
									<label>To:</label>
									<div className="datetime-inputs">
										<input
											type="date"
											name="to"
											value={dateRange.to}
											onChange={handleDateChange}
											className="date-input"
										/>
										<input
											type="time"
											name="toTime"
											value={dateRange.toTime}
											onChange={handleDateChange}
											className="time-input"
										/>
									</div>
								</div>
							</div>
							<div className="target-user-filter">
								<div className="input-wrapper">
									<label>Target Customer ID:</label>
									<input
										type="text"
										value={targetUserId}
										onChange={handleTargetUserChange}
										placeholder="Enter customer ID"
										className="target-user-input"
									/>
								</div>
							</div>
							<button
								className="search-button"
								onClick={handleSearch}
								disabled={!selectedUserId}
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<FaSearch />
								<span>Get Activity Logs</span>
							</button>
						</div>
					</div>

					{isLoading && !activityLogs ? (
						<div className="loading">Loading...</div>
					) : !selectedUserId ? (
						<h2 className="not-found-text">
							Please select a user to view their activity logs.
						</h2>
					) : activityLogs?.length === 0 ? (
						<h2 className="not-found-text">
							No activity logs found for this user.
						</h2>
					) : (
						<>
							<div className="activity-logs-header">
								<div>TIMESTAMP</div>
								<div>CUSTOMER ID</div>
								<div>ACTION</div>
								<div>DETAILS</div>
							</div>
							<div className="activity-logs-list">
								{activityLogs?.map((log, index) => (
									<div key={index} className="activity-log-row">
										<div className="timestamp">{formatDate(log.doneAt)}</div>
										<div className="target-user-id">{log.targetUserId}</div>
										<div className="action">{log.activity}</div>
										<div className="details">
											{Object.entries(JSON.parse(log.additionalInfo || '{}')).map(([key, value]) => (
												<div key={key} style={{ display: 'flex', gap: '8px', marginBottom: '4px' }}>
													<span style={{ fontWeight: 500 }}>{key}:</span>
													<span>{typeof value === 'object' ? JSON.stringify(value) : value}</span>
												</div>
											))}
										</div>
									</div>
								))}
							</div>
							{hasMore && (
								<div className="load-more-container">
									<button
										className="load-more-button"
										onClick={handleLoadMore}
										disabled={isLoading}
									>
										{isLoading ? 'Loading...' : 'Load More'}
									</button>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	)
}

export default ActivityLogs 