import { WORKBENCH_SIZE } from '../../../support/constants'
import temp21bg from '../../../../shared/assets/images/ai-creative/temp21bg.svg'
import temp21fg from '../../../../shared/assets/images/ai-creative/temp21fg.svg'

export const Template21Config = {
  name: 'Modern Gradient',
  containerId: 'template21-carousel-single',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp21bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#00A1FF',
      draggable: false,
      clickThrough: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 180,
        y: 40,
      },
      width: 720,
      text: '{{productName}}', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 80,
      color: 'color3',
      align: 'center',
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 750,
        maxHeight: 750,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp21fg,
      draggable: false,
      clickThrough: true,
    },
    
    
    // Primary Text (Discount Percentage)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 180,
        y: WORKBENCH_SIZE.height - 160,
      },
      width: 720,
      text: '30% OFF', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 60,
      color: 'color3',
      align: 'center',
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 180,
        y: WORKBENCH_SIZE.height - 80,
      },
      width: 720,
      text: 'CODE: GET30', // Dynamic placeholder
      fontFamily: 'Readex Pro',
      fontSize: 44,
      color: 'color3',
      align: 'center',
      fontIndex: 2,
    },
  ],
}
