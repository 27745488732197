import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import dateFormatter from '../../../shared/util/dateFormatter'
import EditSubscription from './EditSubscription'
import { FaMoneyBill, FaTag } from 'react-icons/fa'
import Button from '../../../shared/components/FormElements/Button'

import './CardAdminCustomSubscriptionPlan.css'

function CardAdminCustomSubscriptionPlan() {
  const [subscriptionEditing, setSubscriptionEditing] = useState(false)
  const crmSubscription = useSelector(state => state.admin.customer?.crmDetails?.subscription)
  const userSubscription = useSelector(state => state.admin.customer?.subscription)


  const [user, setUser] = useState(null)


  let subscriptionPrice = ''
  if (userSubscription) {
    if (userSubscription.price && userSubscription.currency) {
      subscriptionPrice =
        userSubscription.price +
        (userSubscription.currency === 'TRY' ? '₺' : '$')
    } else if (userSubscription.price) {
      subscriptionPrice = userSubscription.price
    } else if (userSubscription.customPlan) {
      subscriptionPrice =
        userSubscription.customPlan.price +
        (userSubscription.customPlan.currency === 'TRY' ? '₺' : '$')
    } else {
      subscriptionPrice = 'No price info'
    }
  }

  const startSubscriptionEditing = () => {
    setSubscriptionEditing(true)
  }

  const endSubscriptionEditing = newSubscription => {
    setSubscriptionEditing(false)
    /* setUser({
      ...user,
      subscription: newSubscription,
    }) */
  }

  return <div className="section subscription-section custom-subscription-plan">
    <div className="header-row">
      <h4> User Subscription </h4>
      {
        (userSubscription?.status !== "active" || crmSubscription?.status !== "Recurring") && userSubscription?.plan !== "Custom" &&
          <Button onClick={startSubscriptionEditing}>Create custom plan</Button>
        
      }
      {
       (userSubscription?.status !== "active" || crmSubscription?.status !== "Recurring")  && userSubscription?.plan === "Custom" &&
          <Button onClick={startSubscriptionEditing}>Edit custom plan</Button>
      }
    </div>
    {subscriptionEditing ? (
      <EditSubscription
        user={user}
        setSubscriptionEditing={setSubscriptionEditing}
        endSubscriptionEditing={endSubscriptionEditing}
      />
    ) : userSubscription ? (
      <div className='row subscription-details'>
        <div>
          <p>
            {' '}
            Source: <b> {userSubscription.source}</b>
          </p>
          <p>
            <FaTag className="icon" />
            {userSubscription.plan}
          </p>
          <p>
            <FaMoneyBill className="icon" />
            {subscriptionPrice}
            {/* {userSubscription?.price ? userSubscription?.price + (userSubscription?.currency || userSubscription?.customPlan?.currency || "") + " / month" : userSubscription?.customPlan?.price ? userSubscription.customPlan?.price + (userSubscription.customPlan?.currency === "TRY" ? "₺" : "$") + " / month" : "No price info"} */}
          </p>
        </div>
        <div>
          <p>
            {' '}
            Started:{' '}
            <b>
              {' '}
              {dateFormatter(userSubscription.createdDate, true) || '-'}
            </b>
          </p>
          
          <p>
            {' '}
            Payment Interval:{' '}
            <b>
              {' '}
              {userSubscription.customPlan?.paymentInterval || '-'}
            </b>
          </p>

          <p>
            {' '}
            Last payment:{' '}
            <b>
              {' '}
              {dateFormatter(userSubscription.lastPaymentDate, true) ||
                '-'}
            </b>
          </p>
          <p>
            {' '}
            Next payment:{' '}
            <b>
              {' '}
              {dateFormatter(userSubscription.nextPaymentDate, true) ||
                '-'}
            </b>
          </p>
        </div>
      </div>
    ) : (
      <div>No subscription</div>
    )}
  </div>

}

export default CardAdminCustomSubscriptionPlan
