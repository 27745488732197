import React from 'react'
import { useSelector } from 'react-redux'

import { useModal } from '../../hooks/modal-hook'

import './FacebookReLogin.css'


const FacebookReLogin = ({ data }) => {



  return (
    <div className="facebook-session-expired">
        Your session expired, please login to Facebook.
        {/* <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={loginCallback}
              scope="email,public_profile,pages_show_list,pages_read_engagement,ads_read,ads_management,business_management,catalog_management"
              fields="name,email,picture"
              render={renderProps => (
                <Button className="fb-login-button" onClick={renderProps.onClick} disabled={!agreedToPrivacyPolicy} >
                  <FaFacebookF className="icon" />
                  Log in to your account
                </Button>
              )}
            /> */}
    </div>
  )
}

export default FacebookReLogin
