import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useForm } from '../../../shared/hooks/form-hook'
import Input from '../../../shared/components/FormElements/Input'
import { adminActions } from '../../../store/admin'
import CollapsibleCard from '../../../shared/components/UIElements/CollapsibleCard'

import './CardAdminPayments.css'
import errorHandler from '../../../shared/util/errorHandler'

const CardAdminPayments = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [isDirty, setIsDirty] = useState(false)
  const [saving, setSaving] = useState(false)

  const [paymentFormState, paymentInputHandler, setPaymentFormData] = useForm(
    {
      totalInvoiceMonths: {
        value: customer.crmDetails?.totalInvoiceMonths || 0,
        isValid: true,
      },
      totalInvoiceAmount: {
        value: customer.crmDetails?.totalInvoiceAmount || 0,
        isValid: true,
      },
      totalUnpaidMonths: {
        value: customer.crmDetails?.totalUnpaidMonths || 0,
        isValid: true,
      },
      totalUnpaidAmount: {
        value: customer.crmDetails?.totalUnpaidAmount || 0,
        isValid: true,
      },
    },
    true,
  )

  const save = async () => {
    try {
      setSaving(true)

      const queryData = {
        'crmDetails.totalInvoiceMonths': parseFloat(
          paymentFormState.inputs.totalInvoiceMonths.value,
        ),
        'crmDetails.totalInvoiceAmount': parseFloat(
          paymentFormState.inputs.totalInvoiceAmount.value,
        ),
        'crmDetails.totalUnpaidMonths': parseFloat(
          paymentFormState.inputs.totalUnpaidMonths.value,
        ),
        'crmDetails.totalUnpaidAmount': parseFloat(
          paymentFormState.inputs.totalUnpaidAmount.value,
        ),
      }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
        'PATCH',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          data: {
            ...customer,
            crmDetails: {
              ...customer.crmDetails,
              totalInvoiceMonths: parseFloat(
                paymentFormState.inputs.totalInvoiceMonths.value,
              ),
              totalInvoiceAmount: parseFloat(
                paymentFormState.inputs.totalInvoiceAmount.value,
              ),
              totalUnpaidMonths: parseFloat(
                paymentFormState.inputs.totalUnpaidMonths.value,
              ),
              totalUnpaidAmount: parseFloat(
                paymentFormState.inputs.totalUnpaidAmount.value,
              ),
            },
          },
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const detectChanges = () => {
    const isTotalInvoiceMonthsDirty =
      parseFloat(paymentFormState.inputs.totalInvoiceMonths.value) !==
      (customer.crmDetails?.totalInvoiceMonths || 0)

    const isTotalInvoiceAmountDirty =
      parseFloat(paymentFormState.inputs.totalInvoiceAmount.value) !==
      (customer.crmDetails?.totalInvoiceAmount || 0)

    const isTotalUnpaidMonthsDirty =
      parseFloat(paymentFormState.inputs.totalUnpaidMonths.value) !==
      (customer.crmDetails?.totalUnpaidMonths || 0)

    const isTotalUnpaidAmountDirty =
      parseFloat(paymentFormState.inputs.totalUnpaidAmount.value) !==
      (customer.crmDetails?.totalUnpaidAmount || 0)

    setIsDirty(
      isTotalInvoiceMonthsDirty ||
        isTotalInvoiceAmountDirty ||
        isTotalUnpaidMonthsDirty ||
        isTotalUnpaidAmountDirty,
    )
  }

  // We compare the current input values to the initial values at each render.
  useEffect(() => {
    detectChanges()
  })

  useEffect(() => {
    setPaymentFormData(
      {
        totalInvoiceMonths: {
          value: customer.crmDetails?.totalInvoiceMonths || 0,
          isValid: true,
        },
        totalInvoiceAmount: {
          value: customer.crmDetails?.totalInvoiceAmount || 0,
          isValid: true,
        },
        totalUnpaidMonths: {
          value: customer.crmDetails?.totalUnpaidMonths || 0,
          isValid: true,
        },
        totalUnpaidAmount: {
          value: customer.crmDetails?.totalUnpaidAmount || 0,
          isValid: true,
        },
      },
      true,
    )
  }, [customer])

  return (
    <CollapsibleCard title="Payments" className="emerald payments-card">
      <div className="input-row">
        <div className="input-cell">
          <span>Invoice months</span>
          <div className="input-wrapper">
            <Input
              element="input"
              placeholder=""
              initialValue={paymentFormState.inputs.totalInvoiceMonths.value}
              forceValue={paymentFormState.inputs.totalInvoiceMonths.value}
              id="totalInvoiceMonths"
              type="text"
              onInput={paymentInputHandler}
            />
          </div>
        </div>
        <div className="input-cell">
          <span>Unpaid months</span>
          <div className="input-wrapper">
            <Input
              element="input"
              placeholder=""
              initialValue={paymentFormState.inputs.totalUnpaidMonths.value}
              forceValue={paymentFormState.inputs.totalUnpaidMonths.value}
              id="totalUnpaidMonths"
              type="text"
              onInput={paymentInputHandler}
            />
          </div>
        </div>
      </div>
      <div className="input-row">
        <div className="input-cell">
          <span>Invoice amount</span>
          <div className="input-wrapper">
            <Input
              element="input"
              placeholder=""
              initialValue={paymentFormState.inputs.totalInvoiceAmount.value}
              forceValue={paymentFormState.inputs.totalInvoiceAmount.value}
              id="totalInvoiceAmount"
              type="text"
              onInput={paymentInputHandler}
            />
            <span>$</span>
          </div>
        </div>
        <div className="input-cell">
          <span>Unpaid amount</span>
          <div className="input-wrapper">
            <Input
              element="input"
              placeholder="0"
              initialValue={paymentFormState.inputs.totalUnpaidAmount.value}
              forceValue={paymentFormState.inputs.totalUnpaidAmount.value}
              id="totalUnpaidAmount"
              type="text"
              onInput={paymentInputHandler}
            />
            <span>$</span>
          </div>
        </div>
      </div>
      {isDirty && (
        <div className="save-button-container">
          <button disabled={saving} className="save-button" onClick={save}>
            {saving ? 'Saving' : 'Save'}
          </button>
        </div>
      )}
    </CollapsibleCard>
  )
}

export default CardAdminPayments
