import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useHttpClient } from './http-hook'
import { adminActions } from '../../store/admin'
import { generalActions } from '../../store/general'
import helper from '../util/helper'
import constants from '../../constants'

/**
 * It provides various functionalities for managing
 * public constants data used by regular users, as well as
 * private constants data that comes from the server and is
 * used by authorized users (e.g., admin, developer, etc.).
 * @returns {Object}
 */
export const useConstants = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)

  const authToken = auth.token

  const publicConstants = constants
  const privateConstants = useSelector(state => state.admin.privateConstants)
  const pricingConstants = useSelector(state => state.general.pricingConstants)
  const arePricingConstantsReady = useSelector(state => state.general.arePricingConstantsReady)
  const areConstantsReady = useSelector(state => state.admin.areConstantsReady)

  const getPrivateConstants = async () => {
    const isAuthorizedUser = auth.user && auth.user.isSpecialUser

    if (!authToken || !isAuthorizedUser) {
      return
    }

    const data = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/admin/constants`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
      },
    )

    dispatch(adminActions.setPrivateConstants(data))
  }

  const getPricingConstants = async () => {
    if (!authToken) {
      return
    }

    try {
      const data = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/pricingConstants`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )


      dispatch(generalActions.setPricingConstants(data))
    } catch (err) {
      console.log("An error occurred while fetching pricing constants.")
    }
  }


  const initializeConstants = () => {
    if (!areConstantsReady) {
      getPrivateConstants()
    }
    if (!arePricingConstantsReady) {
      getPricingConstants()
    }
  }

  return {
    publicConstants,
    privateConstants,
    pricingConstants,
    initializeConstants
  }
}
