import React from 'react'
import { FaMagic } from 'react-icons/fa'

import styles from './GenerateEnhencedCatalogButton.module.css'

const GenerateEnhencedCatalogButton = ({ onClick }) => {
  return (
    <div
      className={styles.generateButton}
      onClick={onClick}
      title="Generate new AI Creative project"
    >
      <div className={styles.iconContainer}>
        <FaMagic className={styles.icon} />
      </div>
      <div className={styles.textsContainer}>
        <div className={styles.generateButtonTitle}>Enhenced Catalog</div>
        <div className={styles.generateButtonDescription}>
          Create AI-powered creative assets from your entire catalog or product
          set
        </div>
      </div>
    </div>
  )
}

export default GenerateEnhencedCatalogButton
