import React, { useState, useEffect, useRef } from 'react'

import ProgressBar from '../UIElements/ProgressBar'
import { useEnhencedCatalog } from '../../hooks/enhenced-catalog-hook'
import useAICreatives from '../../hooks/ai-creatives-hook'

import styles from './CatalogProgressModal.module.css'

const CatalogProgressModal = ({ data }) => {
  const { totalProducts, catalogId, productSetId, primaryFeedId, callback } = data
  const { getBulkCreativeProgress, setIsEnhencedCatalogSaving } =
    useEnhencedCatalog()
  const { updateById } = useAICreatives()

  const [progress, setProgress] = useState(0)
  const progressRef = useRef(0)
  const startTimeRef = useRef(Date.now())

  useEffect(() => {
    progressRef.current = progress
  }, [progress])

  useEffect(() => {
    let cancelled = false

    const calculateExpectedDuration = () => {
      // Base duration for 1000 products is 4.2 minutes (250000 ms)
      const baseDuration = 250000
      const durationPerProduct = baseDuration / 1000
      return Math.max(
        5000,
        Math.min(600000, totalProducts * durationPerProduct),
      )
    }

    const calculateRandomIncrement = currentProgress => {
      if (currentProgress < 80) {
        // Early stage: larger, more random increments
        return Math.random() * 10 + 6 // Between 6% and 16%
      } else if (currentProgress < 99) {
        // Middle stage: moderate increments
        return Math.random() * 2 + 1 // Between 1% and 3%
      } else {
        // Final stage: tiny increments to simulate waiting at 99%
        return Math.random() * 0.5 // Between 0% and 0.5%
      }
    }

    const scheduleUpdate = () => {
      if (cancelled || progressRef.current >= 99) return

      const expectedDuration = calculateExpectedDuration()
      const elapsed = Date.now() - startTimeRef.current
      const elapsedPercentage = elapsed / expectedDuration

      const increment = calculateRandomIncrement(progressRef.current)
      const maxAllowedProgress = Math.min(99, elapsedPercentage * 110)
      const newVal = Math.min(
        progressRef.current + increment,
        maxAllowedProgress,
      )

      setProgress(newVal)
      progressRef.current = newVal

      const randomDelay = 500 + Math.random() * 1000 // Random delay between 500ms and 1500ms
      setTimeout(scheduleUpdate, randomDelay)
    }

    scheduleUpdate()
    return () => {
      cancelled = true
    }
  }, [totalProducts])

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setIsEnhencedCatalogSaving(false)
        callback()
      }, 1000)
    }
  }, [progress])

  useEffect(() => {
    const pollInterval = setInterval(async () => {
      const response = await getBulkCreativeProgress({
        catalogId,
        productSetId,
        primaryFeedId,
      });

      if (response.data) {
        // Update the creative in Redux store with latest data
        updateById(response.data._id, response.data);
      }

      if (response.done && progressRef.current < 100) {
        // If the progress is below 99%, jump to 99% first
        if (progressRef.current < 99) {
          setProgress(99)
          progressRef.current = 99
        }

        // Wait for 1-1.5 seconds at 99% before completing to 100%
        setTimeout(
          () => {
            setProgress(100)
          },
        1000 + Math.random() * 500,
        ) // Random wait between 2-3 seconds
      }
    }, 10000)

    return () => clearInterval(pollInterval)
  }, [catalogId, productSetId, primaryFeedId, updateById]);

  return (
    <div className={`${styles.modalContent} catalog-progress-modal`}>
      <div className={styles.headerSection}>
        <h2 className={styles.mainTitle}>Enhancing Your Catalog</h2>
        <p className={styles.infoText}>
          We're using AI to enhance your product images and optimize them for better performance. This process may take a few minutes depending on your catalog size.
        </p>
      </div>
      
      <div className={styles.progressContainer}>
        <ProgressBar 
          percentage={progress} 
          height={12} 
          className={styles.customProgress}
        />
        <div className={styles.progressDetails}>
          <span className={styles.percentage}>{Math.round(progress)}%</span>
          <span className={styles.processingInfo}>
            Processing {totalProducts} products
          </span>
        </div>
      </div>
    </div>
  )
}

export default CatalogProgressModal
