import React, { useEffect, useRef, useState } from 'react'

import KonvaArea from '../layout/KonvaArea'
import PreviewActionButtons from '../tool/PreviewActionButtons'
import TemplatesGrid from './TemplatesGrid'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import {
  NUMBER_OF_COMMON_TEMPLATES,
  NUMBER_OF_ENHENCED_CATALOG_TEMPLATES,
} from '../../support/constants'
import { convertToSvg } from '../../support/utils'
import { WORKBENCH_SIZE } from '../../support/constants'
import useNavigator from '../../../shared/hooks/navigator-hook'
import errorHandler from '../../../shared/util/errorHandler'
import { useModal } from '../../../shared/hooks/modal-hook'
import Modals from '../../../shared/enums/Modals'
import { useDispatch, useSelector } from 'react-redux'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import useAICreatives from '../../../shared/hooks/ai-creatives-hook'

import styles from './EnhencedCatalogWorkbench.module.css'

const EnhencedCatalogWorkbench = ({
  isShowingTemplates,
  setIsShowingTemplates,
  onElementSelected,
}) => {
  const {
    draftConfiguration,
    productOnDraft,
    setProductOnDraft,
    setInitialConfiguration,
    removeSupplementaryFeed,
    getCatalogProductsProgress,
    createBulkAICreative,
    setIsEnhencedCatalogSaving,
    totalProducts,
    setAreCatalogProductsFetched,
    areCatalogProductsFetched,
    startFetchingCatalogProducts,
    setTotalProducts,
  } = useEnhencedCatalog()
  const { searchParams } = useNavigator()
  const aiCreativesService = useAICreatives()
  const isInitialTemplateSelection = useRef(true)
  const stageRef = useRef(null)
  const { openModal, closeModal } = useModal()
  const dispatch = useDispatch()
  const facebook = useSelector(state => state.facebook)
  const [pollInterval, setPollInterval] = useState(null)

  const createTemplateObject = (num, templatePath) => ({
    id: `${num}-${templatePath}`,
    config: require(`../../templates/geometric/${templatePath}/Template${num}`)[
      `Template${num}Config`
    ],
    get hasLogo() {
      return Boolean(this.config.initialPositions?.companyLogo)
    },
    get forceDiscount() {
      return Boolean(this.config.forceDiscount)
    },
    get primaryText() {
      const node =
        this.config.initialPositions?.primaryText ||
        this.config.initialPositions?.productName
      return {
        fontFamily: node?.fontFamily,
      }
    },
    get secondaryText() {
      const node =
        this.config.initialPositions?.secondaryText ||
        this.config.initialPositions?.productPrice
      return {
        fontFamily: node?.fontFamily,
      }
    },
  })

  const importTemplates = (length, templatePath) =>
    Array.from({ length }, (_, i) => createTemplateObject(i + 1, templatePath))

  const commonTemplates = importTemplates(NUMBER_OF_COMMON_TEMPLATES, 'common')

  const enhencedCatalogTemplatesOnly = importTemplates(
    NUMBER_OF_ENHENCED_CATALOG_TEMPLATES,
    'enhenced-catalog',
  )

  const templates = [...enhencedCatalogTemplatesOnly, ...commonTemplates]

  const { catalogId, productSetId, primaryFeedId, aiCreativeId } = searchParams
  const isEditing = Boolean(aiCreativeId)

  useEffect(() => {
    if (areCatalogProductsFetched || isEditing || !facebook.auth?.accessToken) {
      return
    }

    const pollCatalogProducts = async () => {
      try {
        const result = await getCatalogProductsProgress({
          catalogId: searchParams.catalogId,
          productSetId: searchParams.productSetId,
          primaryFeedId: searchParams.primaryFeedId,
        })

        if (result.status === 'not_started') {
          startFetchingCatalogProducts({
            catalogId,
            productSetId,
            primaryFeedId,
          }).catch(error => {
            errorHandler(error)
          })

          return false
        }

        if (result.status === 'completed') {
          setAreCatalogProductsFetched(true)
          setTotalProducts(result.totalProducts)
          return true
        }

        if (result.status === 'failed') {
          throw new Error('Failed to fetch products')
        }

        return false
      } catch (err) {
        errorHandler(err)
        setAreCatalogProductsFetched(false)
        return true
      }
    }

    pollCatalogProducts().then(shouldStop => {
      if (shouldStop) return

      const interval = setInterval(async () => {
        const shouldStop = await pollCatalogProducts()
        if (shouldStop) {
          clearInterval(interval)
        }
      }, 5000)

      setPollInterval(interval)
    })

    return () => {
      if (pollInterval) {
        clearInterval(pollInterval)
      }
    }
  }, [
    areCatalogProductsFetched,
    isEditing,
    catalogId,
    productSetId,
    primaryFeedId,
    facebook.auth?.accessToken,
  ])

  useEffect(() => {
    const firstProductOfTheFeed = JSON.parse(
      sessionStorage.getItem(`enhenced_catalog_product_on_canvas`),
    )
    if (!firstProductOfTheFeed) return

    const { image_url: originalImageUrl, ...restProduct } =
      firstProductOfTheFeed
    const product = { ...restProduct, originalImageUrl }

    setProductOnDraft(product)

    if (searchParams.aiCreativeId) {
      const creative = aiCreativesService.fullList.find(
        creative => creative._id === searchParams.aiCreativeId,
      )

      if (creative?.enhencedCatalogDetails?.configuration) {
        dispatch(
          aiCreativeEditorActions.setDraftConfiguration({
            initial: true,
            productName: product.name,
            mode: 'enhenced-catalog',
            ...creative.enhencedCatalogDetails.configuration,
          }),
        )
      }
    } else {
      setInitialConfiguration({ type: 'enhenced-catalog' })
      setIsShowingTemplates(true)
    }
  }, [])

  const handleSave = async () => {
    if (!stageRef.current) {
      console.error('Stage not found')
      return
    }

    const stage = stageRef.current

    try {
      setIsEnhencedCatalogSaving(true)

      const svgTemplate = await convertToSvg(stage, {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      })

      let existingCreative

      // There is a creative id in the url if we are editing an existing creative
      if (searchParams.aiCreativeId) {
        existingCreative = aiCreativesService.fullList.find(
          creative => creative._id === searchParams.aiCreativeId,
        )
      }

      // If we are editing an existing creative, we need to remove the old supplementary feed
      if (existingCreative) {
        // Remove from the currently editing creative
        if (existingCreative.enhencedCatalogDetails?.supplementaryFeedId) {
          await removeSupplementaryFeed({
            creativeId: searchParams.aiCreativeId,
            supplementaryFeedId:
              existingCreative.enhencedCatalogDetails.supplementaryFeedId,
          })
        }

        // Remove from the creatives that uses the same catalog
        const sameCatalogCreatives = aiCreativesService.fullList.filter(
          creative =>
            creative.enhencedCatalogDetails?.catalogId ===
            existingCreative.enhencedCatalogDetails?.catalogId,
        )

        await Promise.all(
          sameCatalogCreatives.map(creative => {
            if (creative.enhencedCatalogDetails?.supplementaryFeedId) {
              return removeSupplementaryFeed({
                creativeId: creative._id,
                supplementaryFeedId:
                  creative.enhencedCatalogDetails.supplementaryFeedId,
              })
            }
            return Promise.resolve()
          }),
        )
      }

      const newCreative = await createBulkAICreative({
        catalogId:
          existingCreative?.enhencedCatalogDetails?.catalogId ||
          searchParams.catalogId,
        productSetId:
          existingCreative?.enhencedCatalogDetails?.productSetId ||
          searchParams.productSetId,
        primaryFeedId:
          existingCreative?.enhencedCatalogDetails?.primaryFeedId ||
          searchParams.primaryFeedId,
        supplementaryFeedId:
          existingCreative?.enhencedCatalogDetails?.supplementaryFeedId ||
          searchParams.oldSupplementaryFeedId,
        svgTemplate,
        configuration: draftConfiguration,
      })

      openModal({
        type: Modals.CATALOG_PROGRESS_MODAL,
        totalProducts,
        catalogId:
          existingCreative?.enhencedCatalogDetails?.catalogId ||
          searchParams.catalogId,
        productSetId:
          existingCreative?.enhencedCatalogDetails?.productSetId ||
          searchParams.productSetId,
        primaryFeedId:
          existingCreative?.enhencedCatalogDetails?.primaryFeedId ||
          searchParams.primaryFeedId,
        closeOnClickOutside: false,
        callback: () => {
          closeModal()
          openModal({
            type: Modals.COMPLETE_ENHENCED_CATALOG,
            closeOnClickOutside: false,
            creative: newCreative,
          })
        },
      })
    } catch (err) {
      console.log('err', err)
      errorHandler(err)
    }
  }

  if (!productOnDraft) {
    return null
  }

  return (
    <>
      {isShowingTemplates ? (
        <TemplatesGrid
          templates={templates}
          type="enhenced-catalog"
          initial={isInitialTemplateSelection.current}
          onTemplateClick={() => {
            isInitialTemplateSelection.current = false
            setIsShowingTemplates(false)
          }}
        />
      ) : (
        <div className={styles.konvaArea} id="editorFlexibleSpace">
          <KonvaArea
            templates={templates}
            type="enhenced-catalog"
            onStageReady={stage => (stageRef.current = stage)}
            onElementSelected={onElementSelected}
          />
          <PreviewActionButtons onSave={handleSave} type="enhenced-catalog" />
        </div>
      )}
    </>
  )
}

export default EnhencedCatalogWorkbench
