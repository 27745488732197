import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FaPlus, FaSearch, FaTimes, FaTrash } from 'react-icons/fa'

import countries from '../../../shared/data/countries.json'

import './RegionPopoverContent.css'
import helper from '../../../shared/util/helper'

const RegionPopoverContent = ({ countryCountLimit, regions, setRegions, close }) => {

  const [filteredRegions, setFilteredRegions] = useState(countries || [])
  const selectedRegionObjects = regions.map(regionName => helper.findBy(countries, "name", regionName))
  const [regionObjects, setRegionObjects] = useState(selectedRegionObjects ?? [])
  const [activeCPMCluster, setActiveCPMCluster] = useState(selectedRegionObjects.length ? selectedRegionObjects[0].cpmLevel : "")
  const inputRef = useRef(null)

  const onSelect = regionObj => {
    if (activeCPMCluster && regionObj.cpmLevel !== activeCPMCluster) return

    if (regions.includes(regionObj.name)) {
      const newRegions = regionObjects.filter(r => r.name !== regionObj.name)
      if (!newRegions.length) {
        setActiveCPMCluster("")
      }
      setRegionObjects(newRegions)
      setRegions(newRegions.map(r => r.name))
      return
    }


    setActiveCPMCluster(regionObj.cpmLevel)
    const newRegions = [...regionObjects, regionObj]

    setRegionObjects(newRegions)
    setRegions(newRegions.map(r => r.name))
  }

  const onSearch = e => {
    const newRegions = countries.filter(country =>
      country.name.toLowerCase().includes(e.target.value.toLowerCase()),
    )
    setFilteredRegions(newRegions)
  }

  const clearSearchText = () => {
      inputRef.current.value = ""
      setFilteredRegions(countries)
  }

  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    inputRef.current.focus()
  }, [inputRef.current])

  return (
    <div className="region-popover">
      <div onClick={close} className='close-button'>
        <FaTimes className='icon' />
      </div>
      <div className="header-section">
        <h5>Countries</h5>
        <div className='search-bar'>
          <FaSearch />
          <input
            ref={inputRef}
            type="text"
            className="search-input"
            placeholder="Search country"
            onChange={onSearch}
          />
          <div onClick={clearSearchText} className='clear-search-btn'>
            {inputRef.current && inputRef.current?.value !== "" && <FaTrash />}
          </div>
        </div>
        {regionObjects.length > 0 && (
          <span className={regionObjects.length >= countryCountLimit ? 'limit-error-text' : 'info-text'}>
            {regionObjects.length >= countryCountLimit ? (
              <>
                <b>Country selection limit reached.</b>
                Using more than 5 countries in the same campaign causes high cost per impression.
              </>
            ) : (
              'Due to the different ad costs between countries, you can only add the following countries in the same campaign which are Low CPM (cost per 1000 impressions).'
            )}
          </span>
        )}
           {regionObjects.length > 0 && (
          <>
            <div className="selected-countries">
              <h5>Selected Countries</h5>
              <div className="list">
                {regionObjects.map((region, index) => (
                  <span
                    onClick={() => onSelect(region)}
                    className="selected"
                    title="Click to remove"
                    key={index}
                  >
                    <FaTimes className="icon" />
                    {region.name}
                  </span>
                ))}
              </div>
            </div>

          </>
        )}
      </div>

      <div className="content-section">
     
        
        <div className="list-container">
          <div className="list">
            {filteredRegions.map(
              (region, index) =>
                !regions.includes(region.name) && (
                  <span onClick={() => { if (regions.length < countryCountLimit) onSelect(region) }}
                    key={index}
                    className={
                      `${regions.length >= countryCountLimit ? 'disabled' : ''}
                      ${activeCPMCluster && region.cpmLevel !== activeCPMCluster ? 'cpm-disabled' : ''}`}>
                    <FaPlus className="icon" />
                    {region.name}
                  </span>
                ),
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegionPopoverContent
