import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import helper from '../../shared/util/helper'

import AgenciesUsersList from '../components/agencies/AgencyUsersList'
import FiltersSection from '../components/listing-components/FiltersSection'
import { adminActions, resettedAgencyFilters } from '../../store/admin'

import './AdminPanel.css'
import { useAdmin } from '../../shared/hooks/admin-hook'
import AdminPanelSummarySection from '../components/customers/AdminPanelSummarySection'

const AdminPanel = () => {
  const admin = useAdmin()
  const { getAgenciesUsers } = useAdmin()
  const { agenciesUsers, agencyFilters } = useSelector(state => state.admin)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const agencyId = useSelector(state => state.auth.user.id)

  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const { managerUsers } = useSelector(state => state.admin)

  const [searchText, setSearchText] = useState(
    JSON.parse(sessionStorage.getItem('agencySearch')) || '',
  )

  const [tempFilter, setTempFilter] = useState(agencyFilters)


  useEffect(() => {
    if (agenciesUsers?.length) {
      return
    }
    const savedFilters = sessionStorage.getItem('agencyFilters')
    if (savedFilters) {
      const initialFilter = JSON.parse(savedFilters)
      getAgenciesUsersRequest({ agencyId, freshFetch: true, filter: initialFilter })
    } else {
      getAgenciesUsersRequest({ agencyId, freshFetch: true })
    }
  }, [])


  useEffect(() => {
    setTempFilter(agencyFilters)
  }, [agencyFilters])


  useEffect(() => {
    admin.getNoAlarmUserCounts({
      filters: {
        type: ["Customer"]
      }
    })
  }, [])

  const handleFilterChange = ({ groupName, type, value, checked }) => {
    switch (type) {
      case "range":
        const [group, input] = groupName.split('.')
        setTempFilter(prevFilter => {
          const prevGroup = prevFilter[group]

          return {
            ...prevFilter,
            [group]: {
              ...prevGroup,
              [input]: Number(value),
            },
          }
        })
        break;

      case "checkbox":
        if (groupName === 'sortBy' || groupName === 'googleAds' || groupName === 'didAccMngrSell' || groupName === 'platform' || groupName === 'audienceNetwork' || groupName === 'aiAds' || groupName === 'paused' || groupName === 'adsetComparison' || groupName === 'usageCharges' || groupName === 'manuallyPublishCampaign' || groupName === 'isAccessTokenValid') {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [value] : [],
            }
          })
        } else if (groupName === 'performanceManager' || groupName === 'accountManager' || groupName === 'salesPerson') {
          let objId;
          if (value !== '<Not set>') {
            objId = helper.findBy(Object.values(managerUsers), 'name', value)._id
          } else {
            objId = value
          }
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, objId] : prevGroup.filter(item => item !== objId),
            }
          })
        } else {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, value]
                : prevGroup.filter(item => item !== value),

            }
          })
        }
        break;
      case "date-range":
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break;
      case "hour-range":
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break;
      default:
        break;
    }

    setIsFilterChanged(true)
  }

  const handleApplyFilter = () => {
    dispatch(adminActions.setAgencyFilters(tempFilter))
    dispatch(adminActions.setAgenciesUsers([]))
    sessionStorage.setItem('agencyFilters', JSON.stringify(tempFilter))
    getAgenciesUsersRequest({ agencyId, freshFetch: true, filter: tempFilter })
    setIsFilterChanged(false)
  }

  const resetFilters = () => {
    const newFilter = resettedAgencyFilters
    setTempFilter(newFilter)
    dispatch(adminActions.resetAgencyFilters())
    sessionStorage.setItem('agencyFilters', JSON.stringify(newFilter))
    getAgenciesUsersRequest({ agencyId, freshFetch: true, filter: newFilter })
    setIsFilterChanged(false)
  }

  const getAgenciesUsersRequest = async ({ agencyId, freshFetch = false, filter = agencyFilters, search = searchText }) => {
    setIsLoading(true)
    await getAgenciesUsers({ agencyId, freshFetch, filter, search })
    setIsLoading(false)
  }

  const doSearch = async text => {
    dispatch(adminActions.setAgenciesUsers([]))
    setSearchText(text)
    getAgenciesUsersRequest({ agencyId, freshFetch: true, search: text })
    sessionStorage.setItem('agencySearch', JSON.stringify(text))
  }

  return (
    <div className="admin-panel-container">
      <div className="container-performance">
        {/* {isLoading && <LoadingSpinner message="Loading..." />} */}
        {!isLoading && !agenciesUsers?.length ? (
          <h2 className="not-found-text">No users found for these filters.</h2>
        ) : (
          <AgenciesUsersList isFetching={isLoading} reFetch={() => { getAgenciesUsersRequest({ agencyId, freshFetch: false }) }} />
        )}
        <FiltersSection
          page="agency"
          isFilterChanged={isFilterChanged}
          handleApplyFilter={handleApplyFilter}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          doSearch={doSearch}
          searchText={searchText}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />
      </div>
      <AdminPanelSummarySection />
    </div>
  )
}

export default AdminPanel
