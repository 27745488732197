import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaCheck, FaChevronDown, FaChevronUp, FaPen } from 'react-icons/fa'

import { useFacebookCreative } from '../../../../../../shared/hooks/facebook/creative-hook'
import { useFacebookCampaignEditor } from '../../../../../../shared/hooks/facebook/campaign-editor-hook'
import helper from '../../../../../../shared/util/helper'
import Button from '../../../../../../shared/components/FormElements/Button'
import Tooltip from '../../../../../../shared/components/UIElements/Tooltip'
import InlineLoadingSpinner from '../../../../../../shared/components/UIElements/InlineLoadingSpinner'

import './HighPerformingCreativeMaker.css'

const HighPerformingCreativeMaker = () => {
  const currentCreative = useSelector(
    state => state.facebookCampaignEditor.currentCampaign?.currentCreative,
  )

  const [allCreatives, setAllCreatives] = useState([])
  const [displayedCreatives, setDisplayedCreatives] = useState([])
  const [isGettingCreativesList, setIsGettingCreativesList] = useState(
    !currentCreative.existsInListWithId,
  )

  const {
    changeCreativeInfoForHighPerformingCreatives,
    startLoadingState,
    endLoadingState,
    setHasHighPerformingCreatives,
  } = useFacebookCampaignEditor()

  const [lastDisplayedIndex, setLastDisplayedIndex] = useState(4)
  const { getPreviousCreatives, updateCreative } = useFacebookCreative()
  const [selectedCreative, setSelectedCreative] = useState()
  const [editedCreative, setEditedCreative] = useState()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [textupdateTimer, setTextUpdateTimer] = useState()

  const [primaryText, setPrimaryText] = useState()
  const [websiteUrl, setWebsiteUrl] = useState()

  useEffect(() => {
    const fetchForList = async () => {
      let sortedCreatives
      if (!allCreatives?.length) {
        setIsGettingCreativesList(true)
        let all = await getPreviousCreatives()

        if (all.length) {
          sortedCreatives = [...all.filter(c => c.ctr > 0.3 && c.reach > 100)]
          sortedCreatives.sort((a, b) => b.ctr - a.ctr)
          setAllCreatives(sortedCreatives)
          setDisplayedCreatives(
            sortedCreatives.slice(0, lastDisplayedIndex + 1),
          )
          setHasHighPerformingCreatives(true)
        } else {
          setHasHighPerformingCreatives(false)
          setIsGettingCreativesList(false)
          return
        }
        setIsGettingCreativesList(false)
      } else {
        setHasHighPerformingCreatives(false)
        sortedCreatives = allCreatives
      }

      let index = findCurrentCreativeIndex({
        creatives: sortedCreatives,
        current: currentCreative,
      })

      selectCreative(sortedCreatives[index])
    }

    if (currentCreative.existsInListWithId) {
      setPrimaryText(currentCreative.details.primaryText)
      setWebsiteUrl(currentCreative.details.websiteUrl)
    } else {
      fetchForList()
    }
  }, [currentCreative.existsInListWithId])

  const findCurrentCreativeIndex = ({ creatives, current }) => {
    if (creatives && currentCreative.details.originCreativeId) {
      return helper.findIndex(creatives, 'id', current.details.originCreativeId)
    } else {
      return 0
    }
  }

  useEffect(() => {
    console.log('selectCreative ', selectedCreative)
    if (selectedCreative) {
      setFormDataForNewCreativeSelection(selectedCreative)
    }
  }, [selectedCreative])

  const onPrimaryTextChange = event => {
    setPrimaryText(event.target.value)
  }

  const onWebsiteUrlChange = event => {
    setWebsiteUrl(event.target.value)
  }

  useEffect(() => {
    if (textupdateTimer) {
      clearTimeout(textupdateTimer)
    }
    if (primaryText && isEditOpen) {
      startLoadingState('isProcessingTextFields')
      let timer = setTimeout(async () => {
        const afterEdit = generateCreativeBodyWithNewValues({
          oldCreative: editedCreative,
          primaryText: primaryText,
          websiteUrl: websiteUrl,
        })
        setEditedCreative(afterEdit)
        endLoadingState('isProcessingTextFields')
        const newCreativeFromFacebook = await generateCreativeInFacebook({
          creativeBody: afterEdit.body,
        })
        changeCreativeInfoForHighPerformingCreatives({
          body: newCreativeFromFacebook,
          details: {
            originCreativeId: selectedCreative.id,
            originCreative: {
              id: selectedCreative.id,
              adName: selectedCreative.adName,
              isEditable: selectedCreative.isEditable,
              ctr: selectedCreative.ctr,
            },
            primaryText: primaryText,
            websiteUrl: websiteUrl,
          },
        })
      }, 3000)
      setTextUpdateTimer(timer)
    }

    return () => {
      clearTimeout(textupdateTimer)
    }
  }, [primaryText, websiteUrl])

  const generateCreativeBodyWithNewValues = ({
    oldCreative,
    primaryText,
    websiteUrl,
  }) => {
    var newCreative = { ...oldCreative }

    if (newCreative.body.object_story_spec.link_data) {
      newCreative = {
        ...oldCreative,
        body: {
          ...oldCreative.body,
          object_story_spec: {
            ...oldCreative.body.object_story_spec,
            link_data: {
              ...oldCreative.body.object_story_spec.link_data,
              message: primaryText,
              link: handleUtms(websiteUrl),
            },
          },
        },
      }
      if (newCreative.body.object_story_spec.link_data.child_attachments) {
        let newChildAttachments = []
        newCreative.body.object_story_spec.link_data.child_attachments.forEach(
          child => {
            newChildAttachments.push({
              ...child,
              link: handleUtms(websiteUrl),
            })
          },
        )
        newCreative = {
          ...newCreative,
          body: {
            ...newCreative.body,
            object_story_spec: {
              ...newCreative.body.object_story_spec,
              link_data: {
                ...newCreative.body.object_story_spec.link_data,
                child_attachments: newChildAttachments,
              },
            },
          },
        }
      }
      return newCreative
    } else {
      console.log('not editable, but it came here2')
      return oldCreative
    }
  }

  const generateCreativeInFacebook = async ({ creativeBody }) => {
    let result = await updateCreative({ newCreativeBody: creativeBody })
    let newCreative = {
      ...creativeBody,
      id: result.id,
    }
    return newCreative
  }

  const handleUtms = oldUrl => {
    //TODO do necessary changes in the url
    return oldUrl
  }

  const setFormDataForNewCreativeSelection = selectedCr => {
    if (!selectedCr || !selectedCr.isEditable) {
      setPrimaryText(undefined)
      setWebsiteUrl(undefined)
      setIsEditOpen(false)
      return {
        websiteUrl: '',
        primaryText: '',
        instagramAccount: selectedCr?.body
          ? { id: selectedCr.body.object_story_spec?.instagram_actor_id }
          : null,
        facebookPage: selectedCr?.body
          ? { id: selectedCr.body.object_story_spec?.page_id }
          : null,
      }
    }

    let newWebsiteUrl
    let newPrimaryText

    if (selectedCr.body.object_story_spec?.video_data) {
      newWebsiteUrl = undefined
      newPrimaryText = selectedCr.body.object_story_spec.video_data.message
    }
    if (selectedCr.body.object_story_spec?.link_data) {
      newWebsiteUrl = selectedCr.body.object_story_spec.link_data.link
      newPrimaryText = selectedCr.body.object_story_spec.link_data.message
    }

    console.log('set new form data', newPrimaryText, newWebsiteUrl)
    setPrimaryText(newPrimaryText)
    setWebsiteUrl(newWebsiteUrl)

    return {
      websiteUrl: newWebsiteUrl ?? '',
      primaryText: newPrimaryText ?? '',
      instagramAccount: {
        id: selectedCr.body.object_story_spec?.instagram_actor_id,
      },
      facebookPage: { id: selectedCr.body.object_story_spec?.page_id },
    }
  }

  const seeMore = () => {
    let newIndex = lastDisplayedIndex + 5
    let newList = [...displayedCreatives]
    let listToAdd = allCreatives.slice(lastDisplayedIndex + 1, newIndex + 1)
    newList = newList.concat(listToAdd)
    setDisplayedCreatives(newList)
    setLastDisplayedIndex(newIndex)
  }

  const selectCreative = c => {
    console.log('select creative ', c)
    if (c.id !== selectedCreative?.id) {
      setSelectedCreative(c)
      setEditedCreative(c)
      const { websiteUrl, primaryText, instagramAccount, facebookPage } =
        setFormDataForNewCreativeSelection(c)

      changeCreativeInfoForHighPerformingCreatives({
        body: c.body,
        isEditable: c.isEditable,
        details: {
          originCreativeId: c.id,
          originCreative: {
            id: c.id,
            adName: c.adName,
            isEditable: c.isEditable,
            ctr: c.ctr,
          },
          primaryText: primaryText ?? '',
          websiteUrl: websiteUrl ?? '',
          instagramAccount,
          facebookPage,
        },
      })
    }
  }

  const openEdit = e => {
    if (isEditOpen) return
    e.stopPropagation()
    setFormDataForNewCreativeSelection(selectedCreative)
    setIsEditOpen(true)
    setEditedCreative(selectedCreative)
  }

  const closeEditing = e => {
    e.stopPropagation()
    setIsEditOpen(false)
  }

  return currentCreative.existsInListWithId ? (
    <div className="ad-creatives-list">
      <div className="single-creative-cont active is-edit-open">
        <div className="row">
          <div className="ad-name">
            {currentCreative.details.originCreative.adName}
          </div>
          <div className="info-row">
            <div className="creative-info">
              <span className="value">
                {currentCreative.details.originCreative.ctr.toFixed(2)}%
              </span>{' '}
              <span className="helper-text">CTR</span>
            </div>
          </div>
        </div>
        {/* {currentCreative.details.originCreative.isEditable && (
          <div className="edit-creative-form">
            <div className="row control-row">
              <h4>Edit Details</h4>
            </div>

            <div className="row">
              <label className="label">Primary text:</label>

              <div className="form-control">
                <textarea
                  id=""
                  placeholder="Unlock the Future with Our Innovative Solution!"
                  value={primaryText}
                  onChange={onPrimaryTextChange}
                ></textarea>
              </div>
            </div>

            {!primaryText && (
              <div className="error row">
                <label>&nbsp;</label>
                <p className="error-text">Please enter a primary text</p>
              </div>
            )}

            <div className="row">
              <label>Website URL:</label>
              <div className="form-control">
                <input
                  type="text"
                  placeholder="https://yourwebsite.com"
                  value={websiteUrl}
                  onChange={onWebsiteUrlChange}
                />
              </div>
            </div>

            {!websiteUrl && (
              <div className="error row">
                <label>&nbsp;</label>
                <p className="error-text">Please enter a redirection page</p>
              </div>
            )}
          </div>
        )}
        {currentCreative.details.originCreative.isEditable ? (
          <div className="edit-floating-button editable">
            <>
              Edit
              <FaPen />
            </>
          </div>
        ) : (
          <Tooltip
            content={'This creative is not editable, you can use it as it is'}
          >
            <div className="edit-floating-button">Not Editable</div>
          </Tooltip>
        )} */}
      </div>
      <p className="helper-text">
        If you want to select a new High Performing creative, please remove this
        one and create new.
      </p>
    </div>
  ) : isGettingCreativesList ? (
    <InlineLoadingSpinner messages="Loading your best performed creatives" />
  ) : displayedCreatives.length ? (
    <div className="ad-creatives-list">
      {displayedCreatives.map((c, i) => (
        <div
          className={`single-creative-cont ${c.id === selectedCreative?.id ? 'active' : ''} ${isEditOpen && c.id === selectedCreative?.id ? 'is-edit-open' : ''}`}
          key={c.id}
          onClick={() => {
            if (c.id !== selectedCreative?.id) {
              selectCreative(c)
            }
          }}
        >
          <div className="row">
            <div className="ad-name">{c.adName}</div>
            <div className="info-row">
              <div className="creative-info">
                <span className="value">{c.ctr.toFixed(2)}%</span>{' '}
                <span className="helper-text">CTR</span>
              </div>
            </div>
            <span className="active-indicator">
              <FaCheck className="icon" />
              Selected
            </span>
          </div>
          {/* {isEditOpen && c.id === selectedCreative.id && (
            <div className="edit-creative-form">
              <div className="row control-row">
                <h4>Edit Details</h4>

                <div onClick={closeEditing} className="close-edit-button">
                  <FaChevronUp />
                </div>
              </div>

              <div className="row">
                <label className="label">Primary text:</label>

                <div className="form-control">
                  <textarea
                    id=""
                    placeholder="Unlock the Future with Our Innovative Solution!"
                    value={primaryText}
                    onChange={onPrimaryTextChange}
                  ></textarea>
                </div>
              </div>

              {!primaryText && (
                <div className="error row">
                  <label>&nbsp;</label>
                  <p className="error-text">Please enter a primary text</p>
                </div>
              )}

              <div className="row">
                <label>Website URL:</label>
                <div className="form-control">
                  <input
                    type="text"
                    placeholder="https://yourwebsite.com"
                    value={websiteUrl}
                    onChange={onWebsiteUrlChange}
                  />
                </div>
              </div>

              {!websiteUrl && (
                <div className="error row">
                  <label>&nbsp;</label>
                  <p className="error-text">Please enter a redirection page</p>
                </div>
              )}
            </div>
          )}

          {c.id === selectedCreative?.id &&
            (selectedCreative.isEditable ? (
              <div
                className="edit-floating-button editable"
                onClick={e => {
                  openEdit(e)
                }}
              >
                Edit
                <FaPen />
              </div>
            ) : (
              <Tooltip content="This creative is not editable, you can use it as it is">
                <div className="edit-floating-button">Not Editable</div>
              </Tooltip>
            ))} */}
        </div>
      ))}
      {displayedCreatives.length < allCreatives.length && (
        <Button className="see-more" onClick={seeMore}>
          <FaChevronDown />
          Load More
        </Button>
      )}
    </div>
  ) : (
    <div className="helper-text">
      No high performed creatives found, if you think it is a mistake please
      contact us.
    </div>
  )
}

export default HighPerformingCreativeMaker
