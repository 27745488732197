import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  FaPlug,
  FaCalendarAlt,
  FaUser,
  FaGoogle,
  FaHome,
  FaUserCircle,
  FaLock,
  FaUnlock,
  FaPhone,
  FaBars,
  FaTimes,
  FaWindows,
} from 'react-icons/fa'
import { useModal } from '../../hooks/modal-hook'
import enhencerLogo from '../../assets/images/icons/enhencer-logo-white.png'
import eLogo from '../../assets/images/icons/e-logo.svg'
import adHealthCheckupIcon from '../../assets/images/icons/ad-health-checkup-plus.svg'

import NavbarAccountManagerCard from './NavbarAccountManagerCard'
import FullFunnelButton from '../../../ai-ads/components/campaign/FullFunnelButton'
import './NavBar.css'
import NavbarProfileMenu from './NavbarProfileMenu'
import Modals from '../../enums/Modals'
import Tooltip from '../UIElements/Tooltip'
import { useAuth } from '../../hooks/auth-hook'
import { useUser } from '../../hooks/user-hook'
import { useAdmin } from '../../hooks/admin-hook'
import FullFunnelSidebar from '../../../ai-ads/components/campaign/FullFunnelSidebar'

const Navbar = () => {
  const location = useLocation()
  const user = useSelector(state => state.auth.user)
  const auth = useSelector(state => state.auth)
  const facebook = useSelector(state => state.facebook)
  const viewingAsUser = JSON.parse(sessionStorage.getItem('viewingAsUser'))

  const { logout } = useAuth()
  const { goBackToAdmin } = useUser()

  const isConnectionIncomplete =
    facebook.connectionPageIsReady && !user.connectionsCompleted
  const isStartPage = location.pathname === '/start'
  const isNotSubscribed =
    !user?.subscription || user?.subscription.status !== 'active'

  const [isExpanded, setIsExpanded] = useState(!isStartPage)
  const [isLocked, setIsLocked] = useState(!isStartPage)
  const [isHovered, setIsHovered] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
  const [pulsatingItem, setPulsatingItem] = useState(null)
  const [isFullFunnelOpen, setIsFullFunnelOpen] = useState(() => {
    try {
      const saved = localStorage.getItem('isFullFunnelOpen')
      return saved ? JSON.parse(saved) : false
    } catch (error) {
      console.error('Error reading from localStorage:', error)
      return false
    }
  })
  const [previousFullFunnelState, setPreviousFullFunnelState] = useState(() => {
    try {
      const saved = localStorage.getItem('previousFullFunnelState')
      return saved ? JSON.parse(saved) : false
    } catch (error) {
      console.error('Error reading from localStorage:', error)
      return false
    }
  })

  const pixelEventsVerified = facebook.pixelEventsVerified
    ? facebook.pixelEventsVerified[facebook.selectedAdAccount?.id]
    : null
  const shouldShowVerifyEventsButton =
    user?.connectFlow?.completedFacebookConnections <
    new Date().valueOf() - 1000 * 60 * 60 * 24 * 20


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { openModal } = useModal()

  const navbarRef = useRef(null)
  const closeTimeoutRef = useRef(null)
  const pulsatingTimeoutRef = useRef(null)
  const fullFunnelTimeoutRef = useRef(null)

  const isAdHealthCheckupPage = location.pathname.includes('/ad-health-checkup')
  const isAiCreativeLabPage = location.pathname.includes('/ai-ads/creative-lab')

  const shouldRenderNavbar =
    auth.isLoggedIn &&
    !user.isInSignupFlow &&
    (user.completedRegistration || user.isSpecialUser)

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const selectedFullFunnelId = query.get('id')

  const { navigateWithAdminParams } = useAdmin()

  const getCampaignStatus = useCallback(fullFunnel => {
    return fullFunnel?.reports?.funnelLevel?.last_14d?.effectiveStatus || 'UNKNOWN'
  }, [])

  const isInitialized = useRef(false)

  useEffect(() => {
    if (isAdHealthCheckupPage || isAiCreativeLabPage) {
      if (!isMobile) {
        setTimeout(() => {
          setIsExpanded(false)
          setIsLocked(false)
        }, 0)
      }
    } else {
      if (!isMobile && !isInitialized.current) {
        setTimeout(() => {
          setIsExpanded(true)
          setIsLocked(true)
          isInitialized.current = true
        }, 0)
      }
    }
  }, [isAdHealthCheckupPage, isAiCreativeLabPage, isMobile])

  useEffect(() => {
    const updateActiveItem = () => {
      if (location.pathname.includes('/ai-ads/full-funnel-campaigns')) {
        setActiveItem('campaigns')
        return
      }

      if (location.pathname.includes('/ai-ads/creative-lab')) {
        setActiveItem('creative')
        return
      }

      const paths = {
        summary: '/ai-ads',
        connect: ['/checkup/config', '/config'],
        adhealth: ['/checkup/config', '/ad-health-checkup'],
        googleads: '/google-ads',
        microsoftads: '/microsoft-ads',
        admin: '/admin-panel',
      }

      for (const [key, path] of Object.entries(paths)) {
        if (Array.isArray(path)) {
          if (path.some(p => location.pathname.includes(p))) {
            setActiveItem(key)
            return
          }
        } else if (location.pathname.includes(path)) {
          setActiveItem(key)
          return
        }
      }

      setActiveItem(null)
    }

    updateActiveItem()
  }, [location.pathname, location.search])

  const handleNavItemClick = useCallback(
    (item, hasSubItems, link) => {
      if (item !== 'campaigns') {
        setIsSidebarOpen(false)
      }

      if (isMobile && !hasSubItems && item !== 'campaigns') {
        setIsMobileMenuOpen(false)
        setIsExpanded(false)
      }
      if (isMobile && item.startsWith('campaign_')) {
        setIsMobileMenuOpen(false)
        setIsExpanded(false)
      }
      if (isNotSubscribed && item === 'campaigns') {
        setPulsatingItem('demo')
        setTimeout(() => setPulsatingItem(null), 2000)
      } else if (link) {
        navigate(link)
      }
    },
    [isMobile, navigate, isNotSubscribed],
  )

  const handleDisabledClick = useCallback(() => {
    if (isNotSubscribed && !pulsatingItem) {
      setPulsatingItem('demo')
      if (pulsatingTimeoutRef.current) {
        clearTimeout(pulsatingTimeoutRef.current)
      }
      pulsatingTimeoutRef.current = setTimeout(() => {
        setPulsatingItem(null)
        pulsatingTimeoutRef.current = null
      }, 2000)
    }
  }, [isNotSubscribed, pulsatingItem])

  const handleMouseEnter = () => {
    if (!isLocked) {
      setIsExpanded(true)
      setIsHovered(true)
    }
  }

  const handleMouseLeave = () => {
    if (!isLocked) {
      setIsExpanded(false)
      setIsHovered(false)
    }
  }

  const toggleLock = useCallback(() => {
    setIsLocked(prevIsLocked => {
      const newIsLocked = !prevIsLocked
      if (newIsLocked) {
        setIsExpanded(true)
        setPreviousFullFunnelState(isFullFunnelOpen)
      } else {
        setIsExpanded(false)
      }
      return newIsLocked
    })
  }, [isFullFunnelOpen])

  useEffect(() => {
    document.body.classList.toggle('navbar-locked', isLocked)
    document.body.classList.toggle('navbar-expanded', isExpanded)
  }, [isLocked, isExpanded])

  useEffect(() => {
    try {
      localStorage.setItem('isFullFunnelOpen', JSON.stringify(isFullFunnelOpen))
    } catch (error) {
      console.error('Error writing to localStorage:', error)
    }
  }, [isFullFunnelOpen])

  useEffect(() => {
    try {
      localStorage.setItem('previousFullFunnelState', JSON.stringify(previousFullFunnelState))
    } catch (error) {
      console.error('Error writing to localStorage:', error)
    }
  }, [previousFullFunnelState])

  useEffect(() => {
    return () => {
      if (pulsatingTimeoutRef.current) {
        clearTimeout(pulsatingTimeoutRef.current)
      }
      if (fullFunnelTimeoutRef.current) {
        clearTimeout(fullFunnelTimeoutRef.current)
      }
      if (closeTimeoutRef.current) {
        clearTimeout(closeTimeoutRef.current)
      }
    }
  }, [])

  const handleLogout = useCallback(() => {
    localStorage.removeItem('isFullFunnelOpen')
    localStorage.removeItem('previousFullFunnelState')
    logout({})
    document.body.classList.remove('adminsr')
    navigate('/')
  }, [navigate, logout])

  const handleGoBackToUserDetail = () => {
    goBackToAdmin()

  }

  const openDemoMeetingPopup = useCallback(() => {
    openModal({
      type: Modals.BOOK_A_DEMO_WITH_US,
      closeOnClickOutside: true,
      performanceManager: user.crmDetails?.performanceManager,
      scheduledMeeting: user.crmDetails.adHealthCheckupMeeting,
    })
  }, [openModal, user])

  const openGoogleAdsModal = useCallback(() => {
    openModal({
      type: Modals.GOOGLE_ADS_SUPPORT,
      closeOnClickOutside: true,
    })
  }, [openModal])

  const openMicrosoftAdsModal = useCallback(() => {
    openModal({
      type: Modals.MICROSOFT_ADS_SUPPORT,
      closeOnClickOutside: true,
    })
  }, [openModal])

  const isReadyForAHCU =
    facebook.selectedAdAccount &&
    facebook.selectedPixel &&
    facebook.selectedBusiness &&
    !facebook.fbInfoFetching



  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 768
      setIsMobile(isMobileView)

      if (isMobileView) {
        setIsExpanded(false)
        setIsLocked(false)
        setIsMobileMenuOpen(false)
      } else {
        if (isAdHealthCheckupPage || isAiCreativeLabPage) {
          setIsExpanded(false)
          setIsLocked(false)
        } else {
          setIsExpanded(!isStartPage)
          setIsLocked(!isStartPage)
        }
      }
    }

    const handleClickOutside = (event) => {
      if (
        isMobile &&
        isMobileMenuOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target) &&
        !event.target.closest('.mobile-menu-button') &&
        !isProfileMenuOpen
      ) {
        setIsMobileMenuOpen(false)
        setIsExpanded(false)
      }

      if (
        !isMobile &&
        isSidebarOpen &&
        isLocked &&
        !navbarRef.current.contains(event.target) &&
        !event.target.closest('.full-funnel-sidebar') &&
        !event.target.closest('.mobile-menu-button')
      ) {
        setIsSidebarOpen(false)
      }
    }

    window.addEventListener('resize', handleResize)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMobile, isStartPage, isMobileMenuOpen, isProfileMenuOpen, isAdHealthCheckupPage, isAiCreativeLabPage, isSidebarOpen, isLocked])

  const handleNavbarClick = useCallback(() => {
    if (isMobile) {
      setIsExpanded(prev => !prev)
    }
  }, [isMobile])

  const handleMobileMenuToggle = useCallback(() => {
    if (isMobile) {
      setIsMobileMenuOpen(prev => !prev)
      setIsExpanded(true)
    }
  }, [isMobile])

  const handleProfileMenuToggle = (isOpen) => {
    setIsProfileMenuOpen(isOpen)

    if (isOpen && isSidebarOpen) {
      setIsSidebarOpen(false)
    }

    if (isOpen && isMobile) {
      setIsFullFunnelOpen(false)
      setIsMobileMenuOpen(true)
      setIsExpanded(true)
    } else if (!isOpen && isMobile) {
      setIsMobileMenuOpen(false)
      setIsExpanded(false)
    }
  }

  const handleCampaignClick = useCallback(
    campaignId => {
      navigateWithAdminParams(
        `/ai-ads/full-funnel-campaigns?id=${campaignId}`,
      )
    },
    [navigateWithAdminParams],
  )

  const createNewCampaign = useCallback(() => {
    navigateWithAdminParams('/ai-ads/full-funnel-campaigns/create')
  }, [navigateWithAdminParams])

  const handleOverviewClick = useCallback(() => {
    navigateWithAdminParams('/ai-ads/full-funnel-campaigns/')
  }, [navigateWithAdminParams])

  // Lock navbar function
  const lockNavbar = () => {
    setIsLocked(true)
    setIsExpanded(true)
    document.body.classList.add('navbar-locked')
  }

  // When clicked on Facebook Ads
  const handleFacebookAdsClick = () => {
    // If we are on ad health checkup page, first go to home
    if (isAdHealthCheckupPage) {
      navigate('/')
      // Go to Facebook Ads after a short timeout
      setTimeout(() => {
        setIsLocked(true)
        setIsExpanded(true)
        document.body.classList.add('navbar-locked')
        setIsSidebarOpen(true)
      }, 100)
      return
    }

    // If sidebar is open, close it
    if (isSidebarOpen) {
      setIsSidebarOpen(false)
      return
    }

    // Normal flow
    if (!isLocked) {
      setIsLocked(true)
      setIsExpanded(true)
      document.body.classList.add('navbar-locked')
    }
    setIsSidebarOpen(true)
  }

  // When clicked on something in sidebar
  const handleSidebarInteraction = () => {
    if (isLocked) {
      // Update states
      setIsLocked(false)
      setIsExpanded(false)

      // Update body classes
      document.body.classList.remove('navbar-locked')
      document.body.classList.remove('navbar-expanded')
      setIsHovered(false)
    }
  }

  useEffect(() => {
    const mainCont = document.querySelector('.main-cont');
    if (mainCont) {
      if (isSidebarOpen) {
        mainCont.classList.add('sidebar-open');
      } else {
        mainCont.classList.remove('sidebar-open');
      }
    }
  }, [isSidebarOpen]);

  // Effect watching URL changes
  useEffect(() => {
    // Close sidebar only when first coming to /create page
    if (location.pathname.endsWith('/create') && isSidebarOpen) {
      setIsSidebarOpen(false)
    }
    // On other pages, close if not on /full-funnel-campaigns
    else if (!location.pathname.startsWith('/ai-ads/full-funnel-campaigns')) {
      setIsSidebarOpen(false)
    }
  }, [location.pathname])

  return shouldRenderNavbar ? (
    <>
      {isMobile && (
        <button
          className="mobile-menu-button"
          onClick={handleMobileMenuToggle}
          aria-label="Toggle menu"
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </button>
      )}
      <div
        className={`navbar-container ${isLocked ? 'locked' : ''} ${isExpanded || (isMobile && isMobileMenuOpen) ? 'expanded' : 'collapsed'
          } ${isHovered ? 'hovered' : ''}`}
        onMouseEnter={!isMobile ? handleMouseEnter : undefined}
        onMouseLeave={!isMobile ? handleMouseLeave : undefined}
        ref={navbarRef}
      >
        <nav
          className={`new-navbar ${isMobile
            ? isMobileMenuOpen
              ? 'mobile-open expanded'
              : 'collapsed'
            : isExpanded
              ? 'expanded'
              : 'collapsed'
            }`}
        >
          <div className="navbar-content">
            <Link to="/" className="navbar-logo" onClick={viewingAsUser ? handleGoBackToUserDetail : undefined}>
              <img src={enhencerLogo} alt="Enhencer Logo" className="full-logo" />
              <img src={eLogo} alt="E Logo" className="e-logo" />
            </Link>
            <div className="navbar-menu">

              {viewingAsUser && viewingAsUser.userMail === user.email && (
                <NavItem
                  icon={<FaUserCircle />}
                  title={viewingAsUser?.userRole === 'Agency' ? "Go back to Agency Panel" : "Go Back to User Detail"}
                  active={false}
                  onClick={handleGoBackToUserDetail}
                  isExpanded={isExpanded}
                  onDisabledClick={handleDisabledClick}
                />
              )}
              <NavItem
                icon={<FaHome />}
                title="Home"
                active={activeItem === 'summary'}
                onClick={() => handleNavItemClick('summary', false, '/ai-ads')}
                isExpanded={isExpanded}
                onDisabledClick={handleDisabledClick}
              />

              {user.isSpecialUser ? (
                <>
                  <NavItem
                    icon={<FaUser />}
                    title="Admin Panel"
                    active={activeItem === 'admin'}
                    onClick={() =>
                      handleNavItemClick('admin', false, '/admin-panel')
                    }
                    isExpanded={isExpanded}
                  />
                </>
              ) : (
                <>
                  <NavItem
                    icon={<FaPlug />}
                    title="Connect"
                    active={activeItem === 'connect'}
                    onClick={() =>
                      handleNavItemClick('connect', false, isNotSubscribed ? '/checkup/config' : '/config')
                    }
                    badge={
                      ((facebook.connectionPageIsReady && !user.connectionsCompleted) || (facebook.connectionPageIsReady && user.connectionsCompleted && !pixelEventsVerified && shouldShowVerifyEventsButton))
                        ? '!'
                        : null
                    }
                    isExpanded={isExpanded}
                  />
                  <NavItem
                    icon={<img src={adHealthCheckupIcon} alt="Ad Health Checkup" />}
                    title="Ad Health Checkup"
                    active={activeItem === 'adhealth'}
                    onClick={() => {
                      handleNavItemClick('adhealth', false, isReadyForAHCU ? '/ad-health-checkup' : isNotSubscribed ? '/checkup/config' : '/config')
                    }}
                    isExpanded={isExpanded}
                  />


                  <Tooltip
                    content={
                      isConnectionIncomplete
                        ? 'Your connections are not completed'
                        : 'Subscribe to use this feature'
                    }
                    isHidden={!isNotSubscribed && !isConnectionIncomplete}
                  >
                    <FullFunnelButton
                      isExpanded={isExpanded}
                      activeItem={activeItem}
                      handleNavItemClick={handleNavItemClick}
                      disabled={isNotSubscribed || isConnectionIncomplete}
                      onDisabledClick={handleDisabledClick}
                      isMobile={isMobile}
                      setIsMobileMenuOpen={setIsMobileMenuOpen}
                      setNavbarExpanded={setIsExpanded}
                      setIsSidebarOpen={handleFacebookAdsClick}
                      isSidebarOpen={isSidebarOpen}
                      selectedFullFunnelId={selectedFullFunnelId}
                      handleCampaignClick={handleCampaignClick}
                      createNewCampaign={createNewCampaign}
                    />
                  </Tooltip>
                  <NavItem
                    icon={<FaGoogle />}
                    title="Google Ads"
                    active={activeItem === 'googleads'}
                    onClick={() => {
                      handleNavItemClick('googleads', false)
                      openGoogleAdsModal()
                    }}
                    isExpanded={isExpanded}
                    disabled={isNotSubscribed}
                    onDisabledClick={handleDisabledClick}
                  />
                  <NavItem
                    icon={<FaWindows />}
                    title="Microsoft Ads"
                    active={activeItem === 'microsoftads'}
                    onClick={() => {
                      handleNavItemClick('microsoftads', false)
                      openMicrosoftAdsModal()
                    }}
                    isExpanded={isExpanded}
                    disabled={isNotSubscribed}
                    onDisabledClick={handleDisabledClick}
                  />
                </>
              )}
            </div>
          </div>
          <div className="navbar-footer">
            {(!isMobile || (isMobile && isProfileMenuOpen)) && (
              <div className="navbar-line"></div>
            )}
            {isNotSubscribed ? (
              <NavItem
                icon={<FaCalendarAlt />}
                title="Book a Demo"
                active={activeItem === 'demo'}
                onClick={() => {
                  handleNavItemClick('demo', false)
                  openDemoMeetingPopup()
                }}
                isExpanded={isExpanded}
                isPulsating={pulsatingItem === 'demo'}
              />
            ) : (
              <>
                {(!isMobile || (isMobile && isProfileMenuOpen)) && (
                  <NavbarAccountManagerCard
                    isMobile={isMobile}
                    isProfileMenuOpen={isProfileMenuOpen}
                  />
                )}
                {!isExpanded && !isMobile && (
                  <NavItem
                    icon={<FaPhone />}
                    title={`Contact ${user.crmDetails?.performanceManager?.name || 'Performance Manager'}`}
                    onClick={() => {
                      console.log('Contacting performance manager')
                    }}
                    isExpanded={isExpanded}
                  />
                )}
              </>
            )}
            {(!isMobile || (isMobile && isProfileMenuOpen)) && (
              <div className="navbar-line"></div>
            )}
            <NavbarProfileMenu
              isExpanded={isExpanded}
              isLocked={isLocked}
              logout={handleLogout}
              setIsProfileMenuOpen={handleProfileMenuToggle}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              setNavbarExpanded={setIsExpanded}
              isMobile={isMobile}
            />
          </div>
          {!isAdHealthCheckupPage && (
            <div className="navbar-toggle">
              <Tooltip
                content={isLocked ? "Unlock collapsing of navigation bar" : "Lock navigation bar in place"}
                placement="right"
              >
                <button
                  onClick={toggleLock}
                  className={`lock-button ${isLocked ? 'locked' : 'unlocked'}`}
                >
                  {isLocked ? <FaLock /> : <FaUnlock />}
                </button>
              </Tooltip>
            </div>
          )}
        </nav>
      </div>
      {!isAdHealthCheckupPage && !isMobile && (
        <div className="sidebar-container">
          <FullFunnelSidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            onInteraction={handleSidebarInteraction}
            selectedFullFunnelId={selectedFullFunnelId}
            handleCampaignClick={handleCampaignClick}
            createNewCampaign={createNewCampaign}
          />
        </div>
      )}
    </>
  ) : null

}

const NavItem = ({
  icon,
  title,
  active,
  onClick,
  link,
  badge,
  isExpanded,
  disabled,
  isPulsating,
  onDisabledClick,
}) => {
  const content = (
    <div
      className={`nav-item-content ${disabled ? 'disabled' : ''} ${isPulsating ? 'pulsating' : ''
        }`}
      onClick={disabled ? onDisabledClick : onClick}
    >
      <div className="nav-item-left">
        {icon}
        {isExpanded && <span>{title}</span>}
      </div>
      {badge && <span className="nav-item-badge">{badge}</span>}
    </div>
  )

  return (
    <div
      className={`nav-item ${active ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
    >
      {link && !disabled ? (
        <Link to={link} onClick={onClick}>
          {content}
        </Link>
      ) : (
        content
      )}
    </div>
  )
}

export default Navbar