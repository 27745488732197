import React, { useEffect, useRef, useState } from 'react'
import './AlarmCard.css'
import NextActionsPopup from './NextActionsPopup'
import { FaEdit, FaUser, FaUserNinja, FaVideo } from 'react-icons/fa'
import Button from '../../../shared/components/FormElements/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useModal } from '../../../shared/hooks/modal-hook'
import { toast } from 'react-toastify'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import Modals from '../../../shared/enums/Modals'
import errorHandler from '../../../shared/util/errorHandler'
import Tooltip from '../../../shared/components/UIElements/Tooltip'

const AlarmCard = (props) => {
  const user = props.alarm
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { sendRequest } = useHttpClient()
  const { convertManagerIdToName } = useAdmin()
  const { managerUsers } = useSelector(state => state.admin)
  const shortenPersonName = (name) => {
    if (name.length > 11 && name.includes(" ")) {
      let splittedName = name.split(" ")
      if (splittedName.length > 2) {
        return `${splittedName[0]} ${splittedName[1][0]}.${splittedName[2][0]}.`
      } else {
        return `${splittedName[0]} ${splittedName[1][0]}.`
      }
    }
    return name
  }
  const formatTime = (date) => {
    const time = new Date(date).toTimeString().split(' ')[0].substring(0, 5)
    return time === "23:59" ? "" : time;
    return time
  };

  const createAlarm = async newAlarmWrapperObject => {
    try {
      let newAlarmObject = { ...newAlarmWrapperObject.new }
      newAlarmObject.alarmsPath
        = user.type === "Demo"
          ? "crmAlarmsDemoMeeting" : user.type === "Sign Up"
            ? "crmAlarmsSignUp" : user.type === "Customer"
              ? "crmAlarms" : "";

      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createNewAlarm/${user._id}`,
        'PATCH',
        JSON.stringify(newAlarmObject),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      props.refreshAlarms();

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }


  const editAlarm = async (newAlarmObject) => {
    try {
      newAlarmObject.alarmsPath
        = user.type === "Demo"
          ? "crmAlarmsDemoMeeting" : user.type === "Sign Up"
            ? "crmAlarmsSignUp" : user.type === "Customer"
              ? "crmAlarms" : "";

      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/editAlarm/${user._id}`,
        'PATCH',
        JSON.stringify(newAlarmObject),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      props.refreshAlarms();

      toast.success('Changes saved!')
      if (result.nextAlarmTitle) {
        toast.success('"' + result.nextAlarmTitle + '" alarm has been created!')
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  const removeAlarm = async (alarmId) => {
    try {

      let alarmsPath
        = user.type === "Demo"
          ? "crmAlarmsDemoMeeting" : user.type === "Sign Up"
            ? "crmAlarmsSignUp" : user.type === "Customer"
              ? "crmAlarms" : "";

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/removeAlarm/${user._id}`,
        'PATCH',
        JSON.stringify({ _id: alarmId, alarmsPath }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      props.refreshAlarms();

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  function getTypeClass(type) {
    switch (type) {
      case "Demo":
        return 'demo'
        break;
      case "Sign Up":
        return 'signup'
        break;
      case "Customer":
        return 'customer'
        break;
      default:
        return ''
        break;
    }
  }
  /* 
    function getDemoStatusClass(status) {
      switch (status) {
        case "Lost":
          return 'lost'
          break;
        case "Won":
          return 'won'
          break;
        case "Not applicable":
          return 'not-applicable'
          break;
        case "Waiting for result":
          return 'meeting-done'
          break;
        case "Rescheduling":
          return 'rescheduling'
          break;
        default:
          return ''
          break;
      }
    }
  
    function getSignUpStatusClass(status) {
      switch (status) {
        case "Touch":
          return 'touch'
          break;
        case "No Touch":
          return 'no-touch'
          break;
        default:
          return status
          break;
      }
    }
  
    function getCustomerStatusClass(status) {
      switch (status) {
        case 'No Status':
          return 'no-status'
        case 'No Touch':
          return 'no-touch'
        case 'Bad':
          return 'bad'
        case 'No Access':
          return 'no-access'
        case 'Good':
          return 'good'
        case 'Onboarding':
          return 'onboarding'
        default:
          return status
      }
    }
   */
  function openEditAlarmModal() {
    openModal({
      type: Modals.EDIT_ALARM,
      closeOnClickOutside: true,
      customer: {
        id: user._id,
        name: user.name,
      },
      alarm: user.alarm,
      callback: async ({ dueDate, title, note, done, attachedUserIds }) => {
        const newAlarmObject = {
          ...user.alarm,
          dueDate,
          title,
          note,
          done,
          attachedUserIds,
        }
        await editAlarm(newAlarmObject)
        if (done && user.alarm.done !== done && !user.alarm.nextAlarm) {
          openNewAlarmModal()
        }
      },
      removeCallback: async () => {
        await removeAlarm(user.alarm._id)
      },
    })
  }
  function openNewAlarmModal() {
    openModal({
      type: Modals.NEW_ALARM,
      closeOnClickOutside: true,
      customer: {
        id: user._id,
        name: user.name,
        type: user.type
      },
      callback: async ({ dueDate, title, note, selectedPredefinedAlarm, meetingLink, attachedUserIds }) => {
        if (selectedPredefinedAlarm) {
          let newAlarmObject = {
            new: {
              dueDate,
              title: selectedPredefinedAlarm.title,
              nextAlarm: selectedPredefinedAlarm.nextAlarm,
              note,
              done: false,
              selectedPredefinedAlarm: selectedPredefinedAlarm,
              attachedUserIds,
            },
          }
          if (meetingLink) {
            newAlarmObject.new.meetingInfo = {
              zoomJoinUrl: meetingLink
            }
          }
          await createAlarm(newAlarmObject)
        } else {
          let newAlarmObject = {
            new: {
              dueDate,
              title,
              note,
              done: false,
              attachedUserIds,
            },
          }
          if (meetingLink) {
            newAlarmObject.new.meetingInfo = {
              zoomJoinUrl: meetingLink
            }
          }
          await createAlarm(newAlarmObject)
        }
      },
    })
  }

  return (
    <div className={`meeting-card ${user.alarm.meetingInfo ? (getTypeClass(user.type) + "-zoom") : ""}`}>
      <div className={`label ${getTypeClass(user.type)}`}>{user.type} {user.alarm.meetingInfo ? "Meeting" : ""}</div>
      <div className="content-wrapper">
        <div className="header">
          <div className="header-labels">
            <div className="title">{user.alarm.title}</div>
            <div className="subtitle">{user.alarm.note}</div>
          </div>

          <div className='checkmark'>
            <NextActionsPopup
              refreshAlarms={() => props.refreshAlarms()}
              editAlarm={async (data) => {
                await editAlarm(data)
                if (data.done && user.alarm.done !== data.done && !user.alarm.nextAlarm) {
                  openNewAlarmModal()
                }
              }}
              openEditAlarmModal={openEditAlarmModal}
              alarm={user}
              child={(<div
                className={`circle`}
              >
                {user.alarm.done ? "✓" : null}
                <div className="mini-circle"></div>
              </div>)}>

            </NextActionsPopup>
          </div>
        </div>
        <div className="content-body">
          <div className='content-details'>
            <div className="company">{user.name}</div>
            <div className="company-website">{user.companyWebsite}</div>
            <div className="account-manager">{"Acc. Mngr: " + (shortenPersonName(convertManagerIdToName(user.accountManager).found ?? "<Not set>"))}</div>
            <div className="account-manager">{user.type === "Demo" || user.type === "Sign Up" ? null : "Perf. Mngr: " + (shortenPersonName(convertManagerIdToName(user.performanceManager).found ?? "<Not set>"))}</div>
            <div className='attached-users' style={{ display: user.alarm.attachedUserIds?.length > 0 ? 'flex' : 'none', paddingLeft: '0px', paddingTop: '5px' }}>
              {user.alarm.attachedUserIds?.map(userId => {
                if (!managerUsers[userId]?.image) return <Tooltip content={userId}>
                  <div key={userId} className='attached-user-mini' style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'gray', color: 'white' }}>
                    <FaUser />
                  </div></Tooltip>;
                const user = managerUsers[userId];
                return <Tooltip content={user?.name}><div key={userId} className='attached-user-mini'>
                  <img src={user.image} alt={user?.name} className="user-image" style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover' }} />
                </div></Tooltip>
              })}
            </div>
          </div>
          <div className="time">{formatTime(user.alarm.dueDate)}</div>
        </div>
        <div className='go-to-buttons'>
          <Button onClick={() => {
            openModal({
              type: Modals.PEEK_CARD,
              closeOnClickOutside: true,
              user: {
                id: user._id,
                name: user.name,
                type: user.type,
              },
            })
          }}>
            <FaUser />
            <div className='button-text'>
              User Card
            </div>
          </Button>
          <div className='buttons-divider'></div>
          <Button onClick={() => {
            openEditAlarmModal()
          }}>
            {user.alarm.meetingInfo
              ? <>
                <FaVideo />
                <div className='button-text'>
                  Meeting Info
                </div>
              </>
              : <>
                <FaEdit />
                <div className='button-text'>
                  Alarm Info
                </div>
              </>}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default AlarmCard
