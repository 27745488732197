import React, { useMemo, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  FaShopify,
  FaSave,
  FaCopy,
  FaPenSquare,
  FaHistory,
  FaHandPointUp,
  FaHandsHelping,
  FaGoogle,
  FaPhone,
  FaComments,
  FaSms,
  FaHeadset,
  FaCalendarCheck,
  FaPlug,
  FaRocket,
  FaMinus,
  FaCheckCircle,
  FaTimesCircle,
  FaUsers,
  FaFacebook
} from 'react-icons/fa'

import audienceNetworkIcon from '../../../shared/assets/images/icons/audience-network.svg'
import CustomerCardNavigationMenu from './CustomerCardNavigationMenu'
import { adminActions } from '../../../store/admin'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useConstants } from '../../../shared/hooks/constants-hook'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import helper from '../../../shared/util/helper'
import Checkbox from '../../../shared/components/FormElements/Checkbox'
import StarRating from '../cards-components/StarRating'
import CustomerCardAlarmPart from './CustomerCardAlarmPart'

import NotesPopup from '../cards-components/NotesPopup'
import QuickDropdown from '../cards-components/QuickDropdown'
import errorHandler from '../../../shared/util/errorHandler'
import CustomerCardInput from '../cards-components/CustomerCardInput'
import SegmentationScoreIcon from '../cards-components/SegmentationScoreIcon'
import Tooltip from '../../../shared/components/UIElements/Tooltip'

import './CustomerCard.css'
import CustomerCardReportSummaryPartAlternative from './CustomerCardReportSummaryPartAlternative'
import numberFormatter from '../../../shared/util/numberFormatter'
import { useFacebookAuth } from '../../../shared/hooks/facebook/auth-hook'


const CustomerCard = props => {
  const {
    user,
    dayDifference,
    connectionsCompletedInDays,
    fullFunnelCampaignStartedInDays,
    weeklyReports,
    currency,
    shownInOutsideOfList
  } = props

  const userId = user.id

  const [daysPassedSinceLastComment, setDaysPassedSinceLastComment] = useState(props.daysPassedSinceLastComment)

  const { salesPeople, accountManagers, performanceManagers } = useSelector(state => state.admin)
  const token = useSelector(state => state.auth.token)

  const isConnectedToFacebook = user?.facebookAds?.isAccessTokenValid

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loginToUser, startViewingAsUser } = useAdmin()
  const { sendRequest } = useHttpClient()
  const { privateConstants: constants, publicConstants } = useConstants()
  const fullPhoneNumber = user.crmDetails?.contactNumber ? ((user.crmDetails?.contactNumberCountryCode || "") + user.crmDetails?.contactNumber) : ""
  const country = user.crmDetails?.country === "United States" ? "USA" : user.crmDetails?.country === "United Kingdom" ? "UK" : user.crmDetails?.country
  const rippleAnimateRef = useRef()
  const usageChargeElementRef = useRef()
  const cardRef = useRef()

  const usageCharges = (() => {
    let total = 0
    if (user.subscription?.usageCharges?.length) {
      let recentCharges = user.subscription.usageCharges.filter(charge => (new Date(charge.createdAt)).valueOf() > user.subscription.lastPaymentDate)
      recentCharges.forEach((c) => {
        total += parseFloat(c.price)
      })
    }
    return total
  })()

  const handleUsageChargeCardEmphesize = () => {
    let isAnimating = false;
    let interval = setInterval(() => {
      if (cardRef.current && helper.isElementInViewport(cardRef.current) && !isAnimating) {
        isAnimating = true
        setTimeout(() => {
          rippleAnimateRef.current?.classList.add("ripple-animate")
          usageChargeElementRef.current?.classList.add("blink-animate")
          clearInterval(interval)
        }, 1000);
        setTimeout(() => {
          rippleAnimateRef.current?.classList.remove("ripple-animate")
          isAnimating = false
        }, 3000);
      }
    }, 1000);
  }

  const [fields, setFields] = useState({
    comment: { value: user.crmDetails?.comment?.value ?? 0 },
    selfLed: { value: user.crmDetails?.selfLed?.value ?? false },
  })

  const save = async (field, payload) => {
    const query = { cardType: 'customer' }

    switch (field) {
      case 'subscription.status':
        if (user.displayStatus === "Sign Up" && payload.includes("Recurring")) {
          query['leadDetails.funnel'] = constants.leadFunnelSteps.map(item => { return { ...item, completed: true } })
          query['leadDetails.isWon'] = true
          query['crmDetails.contractStartDate'] = new Date()
        }
        if (payload.includes("Churn")) {
          query['crmDetails.contractEndDate'] = new Date()
        }
        query['crmDetails.subscription.status'] = payload
        break;
      case 'performanceStatusType':
        query['crmDetails.performanceStatusType'] = payload
        query['crmDetails.performanceStatusChangeDate'] = new Date()

      case 'calls':
      case 'talks':
      case 'emails':
      case 'smses':
      case 'supports':
      case 'comment':
      case 'selfLed':
      case 'accountManager':
      case 'performanceManager':
      case 'salesPerson':
      case 'sourceType':
      case 'didAccMngrSell':
      case 'comparisonChartVisibility':
      case 'churnReason':
        query[`crmDetails.${field}`] = payload
        break;
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${user.id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      )

      let newUserData = {
        ...user,
        crmAlarms: result.user.crmAlarms,
        crmDetails: {
          ...user.crmDetails,
          [field]:
            typeof payload !== 'object'
              ? payload
              : {
                ...user.crmDetails[field],
                ...payload,
              },
        },
      }
      if (field === 'subscription') {
        newUserData.displayStatus = payload.status
      }

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: user.id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const saveDidAccMngrSell = async (didAccMngrSell) => {
    await save('didAccMngrSell', didAccMngrSell)
  }
  const saveComparisonChartVisibility = async (comparisonChartVisibility) => {
    await save('comparisonChartVisibility', comparisonChartVisibility)
  }

  const saveComment = async () => {
    await save('comment', { value: fields.comment.value, lastModified: Date.now() })
    setDaysPassedSinceLastComment(0)
  }

  const goToDetails = () => {
    dispatch(adminActions.setNavigatedFromCustomerID(userId))
    dispatch(adminActions.setCustomer(user))
    window.open(`/admin-panel/user-detail/${user.id}`, '_blank')
  }

  const goToTickets = username => {
    //TODO For shownInOutsideOfList
    dispatch(adminActions.setNavigatedFromCustomerID(userId))
    navigate(`/admin-panel/support-tickets?uid=${user.id}`, {
      state: {
        username,
      },
    })
  }

  const goToReportPage = e => {
    e.stopPropagation()
    loginToUser({ user: user })
  }

  const username = useMemo(() => {
    let domain = user.shopify
      ? user.shopify.name || user.shopify.domain
      : user.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : user.crmDetails?.contactName
  }, [user])

  const getPerformanceClass = () => {
    if (typeof user.crmDetails === 'undefined' || typeof user.crmDetails?.performanceStatusType === 'undefined') {
      return 'undefinedPerf'
    }

    switch (user.crmDetails.performanceStatusType) {
      case 'No Status':
        return 'no-status'
      case 'No Touch':
        return 'no-touch'
      case 'Bad':
        return 'bad'
      case 'No Access':
        return 'no-access'
      case 'Good':
        return 'good'
      case 'Onboarding':
        return 'onboarding'
      default:
        return user.crmDetails?.performanceStatusType
    }
  }

  const copyPhoneNumber = (e) => {
    e.stopPropagation()
    if (fullPhoneNumber) {
      navigator.clipboard.writeText(fullPhoneNumber)
      toast.info('Phone number copied')
    }
  }

  const writeEmail = (e) => {
    e.stopPropagation()
  }

  const copyUserId = () => {
    navigator.clipboard.writeText(userId)
    toast.info('Customer ID copied.')
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const year = date.getFullYear();

    const month = date?.toLocaleString('en', { month: 'long' });

    return `${day} ${month} ${year}`;
  };

  const calculateDayPassed = (date) => {
    if (!date) {
      return 0;
    }

    const currentDate = new Date();
    const givenDate = new Date(date);

    // Calculate the difference in time
    const timeDifference = currentDate - givenDate;

    // Convert time difference from milliseconds to days
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    return Math.floor(dayDifference); // Round down to the nearest whole number
  };

  const getSupportTicketIcon = () => {
    const { supportTickets } = user

    if (!supportTickets || !supportTickets.length) return null

    let allResolved = false

    for (const ticket of supportTickets) {
      if (ticket.status === 'ignored') {
        continue
      }

      if (ticket.status !== 'completed') {
        allResolved = false
        break
      }

      allResolved = true
    }

    if (!allResolved) {
      return (
        <FaHandPointUp
          className="icon has-ticket-badge"
          onClick={() => goToTickets(username)}
          title="There are unresolved tickets"
        />
      )
    }

    return (
      <FaHandsHelping
        className="icon has-tickets-resolved-badge"
        onClick={() => goToTickets(username)}
        title="All tickets resolved"
      />
    )
  }

  const calculateVisitorChangePercentage = () => {
    if (!user.lastMonthVisitorData) return null;

    const { last15DaysCustomerCount, previous15DaysCustomerCount } = user.lastMonthVisitorData;
    if (!last15DaysCustomerCount || !previous15DaysCustomerCount) return null;

    const change = ((last15DaysCustomerCount - previous15DaysCustomerCount) / previous15DaysCustomerCount) * 100;
    return change.toFixed(1) + "%";
  };

  const formatVisitorCount = (count) => {
    if (!count) return '0';
    return numberFormatter.shortenNumber(count)
  };

  const goToConnect = async () => {
    if (user.isActive) {
      startViewingAsUser({ user: user, pageRoute: "/visiting-data-overview" })
    } else {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/checkIfDataActive`,
          'POST',
          JSON.stringify({
            userId: user.id,
          }),
          {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        )
        if (responseData.isActive) {

          startViewingAsUser({ user: user, pageRoute: "/visiting-data-overview" })
        } else {
          toast.warn('Data is not connected yet!')
        }
      } catch (err) {
        errorHandler(err)
      }
    }
  }

  return (
    <div data-id={userId} className={`admin-panel-customer-card ${getPerformanceClass()}`} ref={cardRef}>
      <div className="card-header">
        <div className="company">
          <SegmentationScoreIcon userId={userId} previouslySegmentationInfo={user.segmentationInfo} />
          {user.shopify && <FaShopify className="icon" />}
          <span className="username-bar" title={user.url}>{username}</span>
        </div>
        <div className="row">
          {!isNaN(dayDifference) ? (
            <div className="days">
              <div className="day" >
                <FaCalendarCheck className="icon" /> Day {dayDifference}
              </div>
            </div>
          ) : null}
          <div className="more">
            {getSupportTicketIcon()}
            <FaFacebook
              className="icon clickable"
              title="Facebook"
              style={{
                color: '#fff',
                cursor: 'pointer',
                fontSize: '20',
                marginRight: '2px'
              }}
              onClick={async () => {
                if (!user.facebookAds) {
                  toast.warn('Facebook is not connected yet!')
                  return
                }
                if (!token) {
                  return
                }

                const authHeader = {
                  Authorization: "Bearer " + token,
                  "Content-Type": "application/json",
                }
                let result = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/facebook?uid=${user.id}`,
                  "GET", null, authHeader
                )

                let url = `https://adsmanager.facebook.com/adsmanager/manage/campaigns?nav_entry_point=lep_237&nav_source=unknown&global_scope_id=${result.info.selectedBusiness.id}&business_id=${result.info.selectedBusiness.id}&act=${result.info.selectedAdAccount.account_id}`

                window.open(url, '_blank')
              }}
            />
            <NotesPopup
              user={user}
              username={username}
              onClick={(e) => {
                if (e.altKey) {
                  copyUserId();
                } else {
                  goToDetails();
                }
              }}
            />
            <CustomerCardNavigationMenu user={user} username={username} openInNewTab={shownInOutsideOfList} />
          </div>
        </div>
      </div>
      <div className="card-content" ref={rippleAnimateRef}>
        <div className="section status">
          <div className="account-status">
            <QuickDropdown
              className="status-text"
              options={
                constants.subscriptionStatusOptions
              }
              emptyText={"<Not set>"}
              preSelectedOption={user.displayStatus}
              onSave={async (value) => {
                await save('subscription.status', value)
              }} />
          </div>
          {user.displayStatus === "Churn" ?
            <div>
              <div>
                <QuickDropdown
                  className="churn-reason"
                  options={
                    [
                      "No Customer Access",
                      "Technical Issues / Bugs",
                      "Poor Support",
                      "Low Budget",
                      "Low ROAS",
                      "No Meta Ads, Only Google Ads",
                      "Other"
                    ]
                  }
                  preText={"⛔️"}
                  emptyText={"<Not set>"}
                  preSelectedOption={user.crmDetails?.churnReason}
                  onSave={async (value) => {
                    await save('churnReason', value)
                  }} />
              </div>
              <div>{formatDate(user.crmDetails.contractEndDate)}</div>
            </div>
            : null}
          <div className="performance-status">
            <QuickDropdown
              className="status-text"
              options={
                constants.performanceStatusTypes
              }
              emptyText={"No status"}
              preSelectedOption={user.crmDetails?.performanceStatusType}
              onSave={async (value) => {
                await save('performanceStatusType', value)
              }} />
          </div>
        </div>

        <div className="section process">
          <div className="overview">

            <div className="item">
              <div className="connection-status">
                {isConnectedToFacebook ? (
                  <Tooltip content="Facebook connection is active">
                    <div className="status-badge success">
                      <FaCheckCircle className="status-icon" />
                      <span className="status-text">Connection</span>
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip content="Facebook connection is not active">
                    <div className="status-badge error">
                      <FaTimesCircle className="status-icon" />
                      <span className="status-text">Connection</span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>

            {user.lastMonthVisitorData && (
              <div className="item">
                <Tooltip size='lg' content={`${user.lastMonthVisitorData.previous15DaysCustomerCount?.toLocaleString()}
->
${user.lastMonthVisitorData.last15DaysCustomerCount?.toLocaleString()}
-
Updated: ${new Date(user.lastMonthVisitorData.fetchDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}`}>
                  <div className={`visitor-stats ${calculateVisitorChangePercentage() >= 0 ? 'positive' : 'negative'}`}
                    onClick={() => {
                      goToConnect()
                    }}>
                    <FaUsers className="status-icon" />
                    <span className="period-indicator">{dayDifference >= 15 ? "15d" : `${dayDifference}d`}:</span>
                    <span className="current-value">{formatVisitorCount(user.lastMonthVisitorData.last15DaysCustomerCount)}</span>
                    {dayDifference >= 30 ? <span className={`percentage ${calculateVisitorChangePercentage() >= 0 ? 'positive' : 'negative'}`}>
                      ({calculateVisitorChangePercentage()})
                    </span> : null}
                  </div>
                </Tooltip>
              </div>
            )}

            <div className="right-side-column">
              <div className="item">
                <Tooltip content={(!isNaN(connectionsCompletedInDays) ? (
                  `User completed Facebook connections ${connectionsCompletedInDays < 0
                    ? 0
                    : connectionsCompletedInDays
                  } days after they subscribed`
                ) : "They haven't completed Facebook connections yet")}>
                  <div className="day-indicator">
                    <FaPlug className="status-icon" />
                    <span className="day-text">
                      {!isNaN(connectionsCompletedInDays) ? (
                        `Day ${connectionsCompletedInDays < 0
                          ? 0
                          : connectionsCompletedInDays
                        }`
                      ) : (
                        <FaMinus className="no-data-icon" />
                      )}
                    </span>
                  </div>
                </Tooltip>
              </div>

              <div className="item">
                <Tooltip content=
                  {(!isNaN(fullFunnelCampaignStartedInDays) ? (
                    `User started Full Funnel Campaign ${fullFunnelCampaignStartedInDays < 0
                      ? 0
                      : fullFunnelCampaignStartedInDays
                    } days after they subscribed`
                  ) : "They haven't started Full Funnel Campaign yet")}>
                  <div className="day-indicator">
                    <FaRocket className="status-icon" />
                    <span className="day-text">
                      {!isNaN(fullFunnelCampaignStartedInDays) ? (
                        `Day ${fullFunnelCampaignStartedInDays < 0
                          ? 0
                          : fullFunnelCampaignStartedInDays
                        }`
                      ) : (
                        <FaMinus className="no-data-icon" />
                      )}
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <CustomerCardReportSummaryPartAlternative
            comparisonDataPreview={user?.facebookAds?.weeklyReports?.comparisonDataPreview}
            popupUserFacebookAds={user?.facebookAds}
            dayCountBeforeEnhencer={user?.facebookAds?.weeklyReports?.beforeEnhencerSlice?.dayCountBeforeEnhencer || 0}
            weeklyReports={user?.facebookAds?.weeklyReports}
            currency={currency}
            userId={user.id}
            username={username}
            reportLastUpdatedAt={user?.facebookAds?.weeklyReports?.lastUpdatedAt}
            onChangeComparisonChartVisibility={saveComparisonChartVisibility}
            comparisonChartVisibility={user?.crmDetails?.comparisonChartVisibility}
          />
        </div>

        <div className='section contact'>
          <div className="inputs">
            <div className="line">
              <Tooltip content={`Calls: ${user.crmDetails?.calls?.count || 0} (Last: ${user.daysPassedSinceLastCall || '-'} days ago)`}>
                <div className="input">
                  <FaPhone className="metric-icon" />
                  <CustomerCardInput
                    label=""
                    userId={user.id}
                    oldValue={user.crmDetails?.calls?.count}
                    oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastCall)}
                    onSave={async (value) => {
                      await save('calls', { count: value, lastModified: Date.now() })
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip content={`Talks: ${user.crmDetails?.talks?.count || 0} (Last: ${user.daysPassedSinceLastTalk || '-'} days ago)`}>
                <div className="input">
                  <FaComments className="metric-icon" />
                  <CustomerCardInput
                    label=""
                    userId={user.id}
                    oldValue={user.crmDetails?.talks?.count}
                    oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastTalk)}
                    onSave={async (value) => {
                      await save('talks', { count: value, lastModified: Date.now() })
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip content={`SMS: ${user.crmDetails?.smses?.count || 0} (Last: ${user.daysPassedSinceLastSMS || '-'} days ago)`}>
                <div className="input">
                  <FaSms className="metric-icon" />
                  <CustomerCardInput
                    label=""
                    userId={user.id}
                    oldValue={user.crmDetails?.smses?.count}
                    oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastSMS)}
                    onSave={async (value) => {
                      await save('smses', { count: value, lastModified: Date.now() })
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip content={`Support: ${user.crmDetails?.supports?.count || 0} (Last: ${user.daysPassedSinceLastSupport || '-'} days ago)`}>
                <div className="input">
                  <FaHeadset className="metric-icon" />
                  <CustomerCardInput
                    label=""
                    userId={user.id}
                    oldValue={user.crmDetails?.supports?.count}
                    oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastSupport)}
                    onSave={async (value) => {
                      await save('supports', { count: value, lastModified: Date.now() })
                    }}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="customer-info">
              <span
                className={`phone ${fullPhoneNumber ? '' : 'not-available'}`}
                onClick={copyPhoneNumber}
                title={fullPhoneNumber ? 'Click to copy' : 'No phone number info'}
              >
                <div>
                  <div>
                    {fullPhoneNumber ? fullPhoneNumber : 'No phone'}
                    <FaCopy className="icon" />
                  </div>
                </div>
              </span>
              <Link
                className="email"
                onClick={writeEmail}
                to={`mailto:${user.crmDetails?.contactEmail}`}
                title="Send new mail"
              >
                <FaPenSquare className="icon" />
                {user.crmDetails?.contactEmail}
              </Link>
            </div>
          </div>
        </div>

        <div className="section account-details">
          <div className='line'>
            <div className="item">
              <div className='pre-desc'>Source: </div>
              <QuickDropdown
                className="source-type"
                options={[
                  "Self-Subscribed",
                  "Outbound Reach",
                  "Meta Demo",
                  "Google Demo",
                  "Direct Demo",
                  "Organic Demo",
                  "Email Demo",
                  "Sign-up Demo",
                  "Tidio",
                  "Partner"
                ]}
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.sourceType}
                onSave={async (value) => {
                  await save('sourceType', value)
                }} />
              {user.crmDetails?.sourceType === "Partner" ? "(" + (user.crmDetails?.partner ?? "<Not set>") + ")" : null}
            </div>
          </div>
          <div className="line">
            <div className="item">
              <div className='pre-desc'>Sales: </div>
              <QuickDropdown
                className="account-manager"
                options={
                  Object.keys(salesPeople)
                }
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.salesPerson}
                isShorteningName={true}
                isConvertingFromId={true}
                onSave={async (value) => {
                  await save('salesPerson', value)
                }} />
              <Checkbox
                name={"didAccMngrSell"}
                label={"🤝"}
                value={user.crmDetails?.didAccMngrSell ?? false}
                checked={user.crmDetails?.didAccMngrSell ?? false}
                onChange={(event) => {
                  saveDidAccMngrSell(event.target.value !== "true")
                }}
              />
            </div>
            {user.crmDetails?.subscription &&
              user.crmDetails?.subscription?.status !== 'Welcome' ? (
              <div className="item">
                <span className='currency'>
                  {user.crmDetails?.subscription?.currency
                    ? helper.getCurrencySymbol(
                      user.crmDetails?.subscription?.currency,
                    )
                    : '$'}
                </span>
                <span className='price'>
                  {
                    user.crmDetails?.subscription?.price
                    ??
                    (user.crmDetails?.subscription?.mrr * (user.crmDetails?.subscription?.period === "YEARLY" ? 12 : 1))
                  }
                </span>
                /{' '}
                {user.crmDetails?.subscription?.period
                  ? user.crmDetails?.subscription?.period.toLowerCase().replace('ly', '')
                  : 'month'}

                {usageCharges ?
                  <span title='Extra usage charge' style={{ color: "var(--second-green)", fontWeight: "400" }} ref={usageChargeElementRef}>
                    + ${usageCharges.toFixed(1)}
                  </span> : null
                }
              </div>
            ) : (
              <div className="item">
                <span className='price'>Welcome ${user.crmDetails?.subscription?.price}</span>
              </div>
            )}
          </div>
          <div className="line">
            <div className="item">
              <div className='pre-desc'>Acc.Mngr: </div>
              <QuickDropdown
                className="account-manager"
                options={
                  Object.keys(accountManagers)
                }
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.accountManager}
                isShorteningName={true}
                isConvertingFromId={true}
                onSave={async (value) => {
                  await save('accountManager', value)
                }} />
            </div>
            <div className="item">
              {country}
              {user.crmDetails?.isAudienceNetworkEnabled ? (
                <img
                  src={audienceNetworkIcon}
                  alt="Audience Network"
                  title="Audience Network"
                />
              ) : null}
            </div>
          </div>
          <div className="line">
            <div className="item">
              <div className='pre-desc'>Perf.Mngr: </div>
              <QuickDropdown
                className="performance-manager"
                options={
                  Object.keys(performanceManagers)
                }
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.performanceManager}
                isShorteningName={true}
                isConvertingFromId={true}
                onSave={async (value) => {
                  await save('performanceManager', value)
                }} />
              {user.crmDetails?.performanceManagerChangeDate ? (
                <p className="description">
                  <FaHistory />
                  {calculateDayPassed(user.crmDetails?.performanceManagerChangeDate) === 0
                    ? 'Today'
                    : `${calculateDayPassed(user.crmDetails?.performanceManagerChangeDate)}d`}
                </p>
              ) : null}
            </div>
            
          </div>
          <div className='line'>
            <div className="item input" onClick={e => e.stopPropagation()} style={
              { display: 'flex', flexDirection: 'column' }
            }>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
                <label htmlFor={`comment-${user.id}`}>{"Comment"}</label>
                <StarRating initialRating={fields.comment.value} onSaveRating={(rating) => {
                  setFields(prev => ({
                    ...prev,
                    comment: {
                      ...prev.comment,
                      value: rating
                    },
                  }))
                }} />

                {fields.comment.value !== (user.crmDetails?.comment?.value ?? 0) ? (
                  <button onClick={saveComment} className="save-btn">
                    <FaSave className="icon" />
                  </button>
                ) : !isNaN(daysPassedSinceLastComment) ? (
                  <p className="description">
                    <FaHistory />
                    {daysPassedSinceLastComment === 0
                      ? 'Today'
                      : `${daysPassedSinceLastComment}d`}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="item">
              {user.googleAds?.conversionId ? (
                <FaGoogle className='icon' />
              ) : null}
            </div>
          </div>
        </div>
        {/* <PrivateComponent children={ <div className='section'>
          <CustomerCardAlarmPart user={user} findByIdAndUpdate={adminActions.findCustomerByIdAndUpdate} alarmsPath="crmAlarms" />
        </div>}
        permissions={["viewAlarmPart"]} accountManager={user.crmDetails.accountManager} performanceManager={user.crmDetails.performanceManager}  /> */}
        <div className='section'>
          <CustomerCardAlarmPart user={user} findByIdAndUpdate={adminActions.findCustomerByIdAndUpdate} alarmsPath="crmAlarms" />
        </div>
      </div>
    </div>
  )
}

export default CustomerCard
