import React, { useEffect, useState } from 'react'
import { FaCopy, FaLink, FaPenSquare, FaSave } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../../../shared/hooks/http-hook'
import { useModal } from '../../../shared/hooks/modal-hook'
import { adminActions } from '../../../store/admin'

import { Link } from 'react-router-dom'
import helper from '../../../shared/util/helper'
import CustomerCardAlarmPart from '../customers/CustomerCardAlarmPart'
import DemoMeetingCardMenu from './DemoMeetingCardMenu'
import UtmsPopup from '../cards-components/UtmsPopup'

import './DemoMeetingCard.css'
import QuickDropdown from '../cards-components/QuickDropdown'
import Modals from '../../../shared/enums/Modals'
import errorHandler from '../../../shared/util/errorHandler'

const DemoMeetingCard = ({ demoMeeting }) => {
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { openModal } = useModal()
  const { salesPeople } = useSelector(state => state.admin)

  const save = async (field, payload) => {
    const query = {}
    if (field === 'salesPerson') {
      query['salesPerson'] = payload
    }
    if (field === 'status') {
      query['status'] = payload
    }
    if (field === 'leadType') {
      query['leadType'] = payload
    }
    if (field === 'meetingStarts') {
      query['meetingStarts'] = payload
      query['meetingEnds'] = new Date(payload.valueOf() + (1000 * 60 * 30))
    }

    if (field === 'ourNote') {
      query['ourNote'] = payload
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/demo-meetings/updateDemoMeeting/${demoMeeting._id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      let newUserData = {
        ...demoMeeting,
        crmAlarmsDemoMeeting: result.demoMeeting.crmAlarmsDemoMeeting,
        ...query
      }

      dispatch(
        adminActions.findDemoMeetingByIdAndUpdate({
          id: demoMeeting._id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  function scrapMeetingDateFromData(meetingJson) {
    const { meetingStarts, meetingEnds } = meetingJson;

    // Parsing the dates
    const startDate = new Date(meetingStarts);
    const endDate = new Date(meetingEnds);

    // Formatting the date
    const formattedDate = `${startDate.getDate()} ${startDate.toLocaleString('default', { month: 'short' })}`;

    // Formatting the time
    const startTime = startDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endTime = endDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    // Creating the final string
    const meetingString = `${formattedDate} - ${startTime}`;

    return meetingString;
  }

  const copyPhoneNumber = (e) => {
    e.stopPropagation()
    if (demoMeeting.contactNumber) {
      navigator.clipboard.writeText(demoMeeting.contactNumber)
      toast.info('Phone number copied')
    }
  }

  function getStatusClass(status) {
    switch (status) {
      case "Lost":
        return 'lost'
        break;
      case "Won":
        return 'won'
        break;
      case "Not applicable":
        return 'not-applicable'
        break;
      case "Waiting for result":
        return 'meeting-done'
        break;
      case "Low budget":
        return 'low-budget'
        break;
      case "Rescheduling":
        return 'rescheduling'
        break;
      default:
        return ''
        break;
    }
  }

  function isBeforeNow(date) {
    return (new Date(date)).valueOf() < (new Date()).valueOf();
  }

  function clickOnWebsite(website) {
    if (website.includes("https://")) {
      window.open(website, "_blank")
    } else if (website.includes("http://")) {
      window.open(website.replace("http://", "https://"), "_blank")
    } else {
      window.open("https://" + website, "_blank")
    }
  }

  return (
    <div
      className={`demo-meeting-card ${getStatusClass(demoMeeting.status)}`}
      key={demoMeeting._id}
    >
      <div className={`card-header`}>
        <div className="section" style={{ maxWidth: "90%" }}>
          <FaLink style={{ flex: "1 0 auto" }} cursor={"pointer"} onClick={() => clickOnWebsite(demoMeeting.companyWebsite)} />
          <span className="username">{demoMeeting.companyWebsite.toLowerCase()}</span>
        </div>
        <div className="section">

          <DemoMeetingCardMenu demoMeeting={demoMeeting} />
        </div>
      </div>
      <div className="card-content">
        <div className='section meeting-datetime'>
          <div className='meeting-date'>
            <div className={`date ${demoMeeting.status === "Waiting for meeting" && isBeforeNow(demoMeeting.meetingStarts) ? "overdue" : ""}`} onClick={
              () => {
                openModal({
                  type: Modals.UPDATE_MEETING_DATE,
                  demoMeeting: demoMeeting,
                  callback: async ({ newMeetingDate }) => {
                    await save("meetingStarts", new Date(newMeetingDate))
                  },
                })
              }
            }>{scrapMeetingDateFromData(demoMeeting)}</div>
            <QuickDropdown
              className={`status ${getStatusClass(demoMeeting.status)}`}
              options={
                ["Waiting for meeting", "Won", "Lost", "Waiting for result", "Rescheduling", "Low budget", "Not applicable"]
              }
              preSelectedOption={demoMeeting.status}
              onSave={async (value) => {
                await save('status', value)
              }} />
          </div>
          <div className='meeting-created-at'>Meeting created at: {helper.convertDateToString(demoMeeting.createdAt)}</div>
        </div>
        <div className="section contact-info">
          <span
            className={`phone ${demoMeeting.contactNumber ? '' : 'not-available'}`}
            onClick={copyPhoneNumber}
            title={demoMeeting.contactNumber ? 'Click to copy' : 'No phone number info'}
          >
            <div>
              <div>
                {demoMeeting.contactNumber ? demoMeeting.contactNumber : 'No phone'}
                <FaCopy className="icon" />
              </div>
            </div>
          </span>
          <div className='email'><Link
            className="email"
            to={`mailto:${demoMeeting.email}`}
            title="Send new mail"
          >
            <FaPenSquare className="icon" />
            {demoMeeting.email}
          </Link></div>
        </div>
        <div className="section crm-info">
          <div className='account-manager'>
            {(!!salesPeople && Object.keys(salesPeople).length) ? <QuickDropdown
              className="account-manager"
              options={
                Object.keys(salesPeople)
              }
              preText={"Sales person: "}
              preSelectedOption={demoMeeting.salesPerson}
              isConvertingFromId={true}
              isShorteningName={true}
              onSave={async (value) => {
                await save('salesPerson', value)
              }} />
              : null}
          </div>
          <div className='country'>{demoMeeting.country}</div>
        </div>
        <div className="section customer-info">
          <div className="item">
            <UtmsPopup utms={demoMeeting.utms}
              child=
              {<QuickDropdown
                preText={"Lead:"}
                className={`lead-type`}
                options={
                  ["Direct", "Organic", "Meta", "Google", "Email Marketing", "Signup + Demo", "Tidio", "Partner", "Outbound"]
                }
                preSelectedOption={demoMeeting.leadType}
                onSave={async (value) => {
                  await save('leadType', value)
                }} />
              }
            ></UtmsPopup>
          </div>
          <div className='country'>Ad spent: {demoMeeting.monthlyAdSpent ?? "<empty>"}</div>
          <div className='country'>
            {demoMeeting.customerNote ?
              (<div className='customer-note' onClick={() => {
                openModal({
                  type: Modals.EXPAND_NOTE,
                  name: demoMeeting.name,
                  noteText: demoMeeting.customerNote,
                })
              }}>{"Customer note: " + demoMeeting.customerNote}</div>)
              : "Customer note: <empty>"}</div>
        </div>
        <div className='section'>
          <CustomerCardAlarmPart user={{ ...demoMeeting, id: demoMeeting._id }} findByIdAndUpdate={adminActions.findDemoMeetingByIdAndUpdate} alarmsPath="crmAlarmsDemoMeeting" />
        </div>
      </div>
    </div >
  )
}

export default DemoMeetingCard
