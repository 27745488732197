import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaCheck, FaTrash, FaUndo } from 'react-icons/fa'

import { useFacebookCampaignEditor } from '../../../../shared/hooks/facebook/campaign-editor-hook'
import Tooltip from '../../../../shared/components/UIElements/Tooltip'
import LoadingDotsSpinner from '../../../../shared/components/UIElements/LoadingDotsSpinner'
import CreativeTypes from '../../../../shared/enums/CreativeTypes'

import AICreativeMaker from './makers/ai-creative/AICreativeMaker'
import HighPerformingCreativeMaker from './makers/high-performing/HighPerformingCreativeMaker'
import CatalogCreativeMaker from './makers/catalog/CatalogCreativeMaker'
import PostCreativeMaker from './makers/post/PostCreativeMaker'

import './AdCreativeMaker.css'

const EditingStates = Object.freeze({
  NOT_SELECTED_TYPE: 0,
  NEW_CREATIVE_WITH_TYPE: 1,
  EXISTING_CREATIVE: 2,
})

const CREATIVE_MAKERS = {
  [CreativeTypes.CATALOG]: CatalogCreativeMaker,
  [CreativeTypes.HIGH_PERFORMING]: HighPerformingCreativeMaker,
  [CreativeTypes.AI_CREATIVE]: AICreativeMaker,
  [CreativeTypes.POST]: PostCreativeMaker,
}

const SaveButton = ({ isValid, onClick, children }) => (
  <Tooltip isHidden={isValid} content="Fill out all fields.">
    <div
      className={`creative-action-button primary${isValid ? '' : ' disabled'}`}
      onClick={() => isValid && onClick()}
    >
      <FaCheck className="icon" />
      <span>{children}</span>
    </div>
  </Tooltip>
)

const ActionButton = ({ icon: Icon, onClick, disabled, variant, children }) => (
  <div
    className={`creative-action-button${variant ? ` ${variant}` : ''}${disabled ? ' disabled' : ''}`}
    onClick={() => !disabled && onClick()}
  >
    <Icon className="icon" />
    <span>{children}</span>
  </div>
)

const AdCreativeMaker = () => {
  const {
    addCurrentCreativeToList,
    editCurrentCreativeInList,
    undoCreativeChanges, // FIXME: Undo changes does not work properly
    removeCurrentCreativeFromList,
    checkIfCurrentCreativeIsInList,
  } = useFacebookCampaignEditor()

  const [editingState, setEditingState] = useState(
    EditingStates.NOT_SELECTED_TYPE,
  )

  const { type, name, isValid, isEdited } =
    useSelector(
      state => state.facebookCampaignEditor.currentCampaign?.currentCreative,
    ) ?? {}

  const { isProcessingTextFields, isCreatingCreative } = useSelector(
    state => state.facebookCampaignEditor.loadings,
  )

  useEffect(() => {
    if (!type) {
      setEditingState(EditingStates.NOT_SELECTED_TYPE)
      return
    }

    setEditingState(
      checkIfCurrentCreativeIsInList()
        ? EditingStates.EXISTING_CREATIVE
        : EditingStates.NEW_CREATIVE_WITH_TYPE,
    )
  }, [type, name])

  const renderActions = () => {
    if (isProcessingTextFields || isCreatingCreative) {
      return <LoadingDotsSpinner />
    }

    if (editingState === EditingStates.NEW_CREATIVE_WITH_TYPE) {
      return (
        <SaveButton isValid={isValid} onClick={addCurrentCreativeToList}>
          Save
        </SaveButton>
      )
    }

    if (editingState === EditingStates.EXISTING_CREATIVE) {
      return (
        <div className="actions-row">
          <ActionButton
            icon={FaTrash}
            onClick={removeCurrentCreativeFromList}
            variant="secondary"
          >
            Remove
          </ActionButton>
          {isEdited && (
            <>
              <ActionButton
                icon={FaUndo}
                onClick={undoCreativeChanges}
                disabled={!isValid}
                variant="secondary"
              >
                Undo Changes
              </ActionButton>
              <SaveButton isValid={isValid} onClick={editCurrentCreativeInList}>
                Update
              </SaveButton>
            </>
          )}
        </div>
      )
    }

    return null
  }

  const CreativeMaker = CREATIVE_MAKERS[type]

  return (
    <div className="creative-maker-form">
      <div className="row head">
        <h4>{name ?? 'New Creative'}</h4>
        {renderActions()}
      </div>
      {CreativeMaker && <CreativeMaker />}
    </div>
  )
}

export default AdCreativeMaker
