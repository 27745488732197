import React from 'react'
import { FaDesktop } from 'react-icons/fa'
import './UseDesktop.css'

const UseDesktop = ({ data }) => {
  return (
    <div className="desktop-is-better">
      <div className="icon-container">
        {' '}
        <FaDesktop />
      </div>
      <p> For a better experience, please use desktop version.</p>
    </div>
  )
}

export default UseDesktop
