import { WORKBENCH_SIZE } from '../../../support/constants'
import common_temp2bg from '../../../../shared/assets/images/ai-creative/common_temp2bg.svg'

export const Template2Config = {
  name: 'New Holiday Sale',
  containerId: 'template2-common',
  backgroundColor: 'white',
  hasDiscount: true,
  // Sequential elements array (back-to-front)
  elements: [
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      dynamicSrc: 'productImage',
      withBackground: true,
      objectFit: 'cover'
    },
    // Second Background Layer SVG
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height
      },
      src: common_temp2bg,
      isSvg: true,
      svgColor: 'color3',
      svgCustomReplace: 'black',
      draggable: false,
      clickThrough: true
    },

    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 300,
        y: WORKBENCH_SIZE.height / 2 - 183
      },
      width: 600,
      height: 266,
      text: `{{productName}}`,
      fontFamily: 'Times New Roman',
      fontSize: 70,
      fontStyle: 'bold',
      color: 'color2',
      align: 'center',
      verticalAlign: 'middle',
      center: true,
      lines: true
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width / 2 - 490,
        y: WORKBENCH_SIZE.height / 2 + 170
      },
      width: 980,
      text: `{{productPrice}}`,
      fontFamily: 'Readex Pro',
      fontSize: 120,
      color: 'color2',
      letterSpacing: 4,
      align: 'center',
      verticalAlign: 'middle',
      center: true,
      lines: true
    }
  ]
}