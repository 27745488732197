import { createSlice, current } from "@reduxjs/toolkit";
import helper from "../shared/util/helper";
//import { generalActions } from "./general"
//import store from "./index"
const initialState = {
  fetching: false,
  fetchingReports: false,
  isLoggedIn: false,
  adAccounts: [],
  adAccountSelectionIsEligible: true,
  settings: { minDailyBudget: 40 },
  businesses: [],
  businessSelectionIsEligible: true,
  pixels: [],
  creatingAudiences: false,
  pixelSelectionIsEligible: true,
  connectionPageIsReady: false,
  audiences: {},
  hasAudienceError: true,
  creatingCampaign: false,
  catalogs: [],
  productSets: [],
  productFeeds: [],
  pages: [], // facebook pages
  instagramAccounts: [],
  userPreferences: {
    datePreset: "last_14d"
  },
  reportsFilter: {
    type: "date_preset",
    value: "last_14d"
  },
  insightsForChart: null,
  loadings: {
    businesses: false,
    adAccounts: false,
    pixels: false,
    activities: false
  },
  selectedActiveFacebookPage: null,
  selectedActiveInstagramAccount: null,
  campaignCreation: {
    advantagePlusCampaign: null,
    conversionNewUserCampaign: null,
    conversionRemarketingCampaign: null,
    awarenessCampaign: null,
    trafficCampaign: null,
    trafficToInstagramCampaign: null
  },
  fullFunnels: [],
  overallReportForFullFunnels: {},
  fullFunnelReportsReady: false,
  fullFunnelInViewId: null,
  fullFunnelEffectiveStatuses: {}
}

const facebookSlice = createSlice({
  name: "facebook",
  initialState: { ...initialState },
  reducers: {
    updateInfo(state, action) {
      state = Object.assign(state, action.payload);
    },
    updateAField(state, action) {
      state[action.payload.field] = action.payload.value
    },
    clearInfo(state, action) {
      Object.keys(state).forEach(key => {
        state[key] = initialState[key] || null
      })
    },
    loginCompleted(state, action) {
      state.auth = action.payload
      state.isLoggedIn = true
    },
    sessionExpired(state, action) {
      state.auth = {
        ...state.auth,
        expiresIn: 0,
        accessToken: null,
        sessionExpired: true
      }
    },
    logOut(state, action) {
      state.auth = {
        expiresIn: 0,
        loggedOut: true,
        accessToken: null
      }
      state.isLoggedIn = false;
    },
    adAccountSelected(state, action) {
      state.selectedAdAccount = action.payload;
      state.adAccountSelectionIsEligible = action.payload ? true : false;
      state.selectedPixel = undefined
      state.pixelSelectionIsEligible = false
    },
    adAccountSelectionIsEligible(state, action) {
      state.adAccountSelectionIsEligible = action.payload;
    },
    businessSelected(state, action) {
      state.selectedBusiness = action.payload;
      state.businessSelectionIsEligible = action.payload ? true : false;
      state.selectedAdAccount = undefined;
      state.adAccountSelectionIsEligible = false;
      state.selectedPixel = undefined;
      state.pixelSelectionIsEligible = false;
    },
    pixelSelected(state, action) {
      state.selectedPixel = action.payload?.pixel
      state.pixelSelectionIsEligible = action.payload?.pixel ? true : false
      if (action.payload?.pixel && action.payload.audiences) {
        state.audiences = Object.assign(state.audiences, action.payload.audiences);
        state.creatingAudiences = false;
      }
    },
    creatingAudiences(state, action) {
      state.creatingAudiences = action.payload;
    },
    newFullFunnelAdded(state, action) {
      let newFullFunnels = [...state.fullFunnels]
      newFullFunnels.push(action.payload)
      state.fullFunnels = newFullFunnels
    },
    audiencesAdded(state, action) {
      state.audiences = Object.assign(state.audiences, action.payload);
    },
    fullFunnelDeleted(state, action) {
      const fullFunnelId = action.payload
      state.fullFunnels = state.fullFunnels.filter(f => f._id !== fullFunnelId)
      if (state.fullFunnelInViewId === fullFunnelId) {
        state.fullFunnelInViewId = null
      }
      if (state.overallReportForFullFunnels[fullFunnelId]) {
        delete state.overallReportForFullFunnels[fullFunnelId]
      }
    },
    fullFunnelUpdated(state, action) {
      const { fullFunnel, id, isInView } = action.payload

      const index = state.fullFunnels.findIndex(f => f._id === id)

      if (index !== -1) {
        state.fullFunnels[index] = {
          ...state.fullFunnels[index],
          ...fullFunnel,
          reports: {
            ...state.fullFunnels[index].reports,
            ...(fullFunnel.reports || {})
          },
          adCreatives: {
            ...state.fullFunnels[index].adCreatives,
            ...(fullFunnel.adCreatives || {})
          }
        }
      }

      if (isInView) {
        state.fullFunnelInViewId = id
      }
    },
    fetching(state, action) {
      state.fetching = action.payload
    },
    fetchingReports(state, action) {
      state.fetchingReports = action.payload
    },
    creatingCampaign(state, action) {
      state.creatingCampaign = action.payload
    },
    connectionPageIsReady(state, action) {
      state.connectionPageIsReady = action.payload
    },
    setHasAudienceError(state, action) {
      state.hasAudienceError = action.payload
    },
    setFullFunnelInView(state, action) {
      if (!action.payload) {
        state.fullFunnelInViewId = null
        return
      }
      state.fullFunnelInViewId = action.payload
    },
    setReportsFilter(state, action) {
      state.reportsFilter = action.payload
    },
    setInsightsForChart(state, action) {
      state.insightsForChart = {
        ...state.insightsForChart,
        ...action.payload
      }
    },
    startLoading(state, action) {
      state.loadings[action.payload.field] = true
    },
    stopLoading(state, action) {
      state.loadings[action.payload.field] = false
    },
    setCampaignCreationStatus(state, action) {
      state.campaignCreation[action.payload.type] = action.payload.data;
    },
    resetCampaignCreation(state) {
      state.campaignCreation = {
        advantagePlusCampaign: null,
        conversionNewUserCampaign: null,
        conversionRemarketingCampaign: null,
        awarenessCampaign: null,
        trafficCampaign: null,
        trafficToInstagramCampaign: null
      }
    },
    updateFullFunnelReports(state, action) {
      const { fullFunnelIndex, reports } = action.payload

      if (fullFunnelIndex !== undefined && state.fullFunnels?.[fullFunnelIndex]) {
        if (!state.fullFunnels[fullFunnelIndex].reports) {
          state.fullFunnels[fullFunnelIndex].reports = {
            adsetLevel: {},
            campaignLevel: {},
            funnelLevel: {}
          }
        }

        state.fullFunnels[fullFunnelIndex].reports.adsetLevel = {
          ...state.fullFunnels[fullFunnelIndex].reports.adsetLevel,
          ...reports.adsetLevel
        }

        state.fullFunnels[fullFunnelIndex].reports.campaignLevel = {
          ...state.fullFunnels[fullFunnelIndex].reports.campaignLevel,
          ...reports.campaignLevel
        }

        state.fullFunnels[fullFunnelIndex].reports.funnelLevel = {
          ...state.fullFunnels[fullFunnelIndex].reports.funnelLevel,
          ...reports.funnelLevel
        }

      }
    },
    updateFullFunnelsOverallReport(state, action) {
      state.overallReportForFullFunnels = {
        ...state.overallReportForFullFunnels,
        ...action.payload
      }
    },
    setFullFunnelReportsReady(state, action) {
      state.fullFunnelReportsReady = action.payload
    },
    updateFullFunnelEffectiveStatuses(state, action) {
      state.fullFunnelEffectiveStatuses = {
        ...state.fullFunnelEffectiveStatuses,
        ...action.payload
      }
    },
    updateAdAccountActivity(state, action) {
      let oldData = state.adAccountActivity?.data || []
      state.adAccountActivity = {
        data: [...oldData, ...action.payload.data],
        nextUrl: action.payload.paging?.next
      }
    }
  },
});

export const facebookActions = facebookSlice.actions;

export default facebookSlice.reducer;

