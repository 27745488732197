import { useEffect } from "react"
import useAdTextGenerator from "../../../shared/hooks/ad-text-generator-hook"
import Button from "../../../shared/components/FormElements/Button"
import { FaSyncAlt, FaBolt } from "react-icons/fa"
import './AdTextGenerator.css'
import InlineLoadingSpinner from "../../../shared/components/UIElements/InlineLoadingSpinner"

const AdTextGenerator = ({
    selectedAdAccountId,
    selectARecommendation
}) => {
    const { generateAllAdTextTypes, regenerateAdText, currentChat } = useAdTextGenerator()

    const displayedInformal = currentChat.informal?.length > 0 ? currentChat.informal[currentChat.informal.length - 1] : null
    const displayedFormal = currentChat.formal?.length > 0 ? currentChat.formal[currentChat.formal.length - 1] : null
    const displayedFomo = currentChat.fomo?.length > 0 ? currentChat.fomo[currentChat.fomo.length - 1] : null

    useEffect(() => {
        const generateRecommendationsForPrimaryText = async () => {
            await generateAllAdTextTypes({
                selectedAdAccountId
            })
        }
        if (selectedAdAccountId) {
            generateRecommendationsForPrimaryText()
        }
    }, [selectedAdAccountId])



    const regenerate = async (event, type) => {
        event.stopPropagation()
        await regenerateAdText({ type })
    }

    return (
        <div className='ai-caption-section'>
            {currentChat && currentChat.informal?.length > 0 ? (
                <>
                    <div className="header-container">
                        <div className="ai-caption-picks-header">
                            AI Generated Caption Personalized for You →
                        </div>
                        <div className="gpt4-banner">
                            <FaBolt className="icon" />
                            Powered by GPT-4o
                        </div>
                    </div>
                    <div className="personalization-info">
                        Personalized by analyzing your brand's tone and style
                    </div>
                    <div className="recommendation-cards">
                        <div className="recommendation-card" onClick={() => selectARecommendation(displayedInformal)} title="Click to use">
                            <div className="card-header">
                                <div className="title">
                                    Personalized - Informal
                                </div>
                                {!currentChat.isInformalLoading ?
                                    <Button textButton className="regenerate-button" onClick={(e) => regenerate(e, 'informal')}>
                                        <FaSyncAlt />
                                        Regenerate
                                    </Button> : null}
                            </div>
                            {currentChat.isInformalLoading ?
                                <InlineLoadingSpinner message="Generating..." />
                                :
                                displayedInformal
                            }
                        </div>

                        <div className="recommendation-card" onClick={() => selectARecommendation(displayedFormal)} title="Click to use">
                            <div className="card-header">
                                <div className="title">
                                    Personalized - Formal
                                </div>
                                {!currentChat.isFormalLoading ?
                                    <Button textButton className="regenerate-button" onClick={(e) => regenerate(e, 'formal')}>
                                        <FaSyncAlt />
                                        Regenerate
                                    </Button> : null}
                            </div>
                            {currentChat.isFormalLoading ?
                                <InlineLoadingSpinner message="Generating..." />
                                :
                                displayedFormal
                            }
                        </div>

                        <div className="recommendation-card" onClick={() => selectARecommendation(displayedFomo)} title="Click to use">
                            <div className="card-header">
                                <div className="title">
                                    FOMO - Informal
                                </div>
                                {!currentChat.isFomoLoading ?
                                    <Button textButton className="regenerate-button" onClick={(e) => regenerate(e, 'fomo')}>
                                        <FaSyncAlt />
                                        Regenerate
                                    </Button> : null}
                            </div>
                            {currentChat.isFomoLoading ?
                                <InlineLoadingSpinner message="Generating..." />
                                :
                                displayedFomo
                            }
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )
}

export default AdTextGenerator