import React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import { adminActions, resettedDemoMeetingFilters } from '../../store/admin'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { toast } from 'react-toastify'
import DemoMeetingsList from '../components/demo-meetings/DemoMeetingsList'
import FiltersSection from '../components/listing-components/FiltersSection'

import './DemoMeetings.css'
import AdminPanelDemoMeetingsSummarySection from '../components/demo-meetings/AdminPanelDemoMeetingsSummarySection'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'
import helper from '../../shared/util/helper'

const DemoMeetings = () => {
  const dispatch = useDispatch()
  const { demoMeetings, demoMeetingFilters } = useSelector(state => state.admin)

  const { openModal } = useModal()
  const auth = useSelector(state => state.auth)
  const { sendRequest } = useHttpClient()
  const { getDemoMeetings, getNoAlarmUserCounts } = useAdmin()

  const [isLoading, setIsLoading] = useState(false)
  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const [searchText, setSearchText] = useState(
    JSON.parse(sessionStorage.getItem('demoMeetingsSearch')) || '',
  )
  const [tempFilter, setTempFilter] = useState(demoMeetingFilters)
  const { salesPeople } = useSelector(state => state.admin)

  useEffect(() => {
    if (demoMeetings.length) {
      return
    }
    const savedFilters = sessionStorage.getItem('demoMeetingFilters')

    if (savedFilters) {
      const initialFilter = JSON.parse(savedFilters)

      getDemoMeetingsData({ freshFetch: true, initialFilter })
    } else {
      getDemoMeetingsData({ freshFetch: true })
    }
  }, [])

  useEffect(() => {
    setTempFilter(demoMeetingFilters)
  }, [demoMeetingFilters])


  useEffect(() => {
    getNoAlarmUserCounts({
      filters: {
        type: ["Demo"]
      }
    })
  }, [])

  const handleFilterChange = ({ groupName, type, value, checked }) => {
    switch (type) {
      case 'checkbox':
        if (groupName === 'sortBy') {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [value] : [],
            }
          })
        } else if (groupName === 'salesPerson') {
          let objId;
          if (value !== '<Not set>' && value !== 'Others') {
            objId = helper.findBy(Object.values(salesPeople), 'name', value)._id
          } else {
            objId = value
          }

          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, objId] : prevGroup.filter(item => item !== objId),
            }
          })
        } else {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked
                ? [...prevGroup, value]
                : prevGroup.filter(item => item !== value),
            }
          })
        }
        break
      case 'date-range':
        setTempFilter(prevFilter => {
          const { user, ...filter } = prevFilter
          return {
            ...filter,
            [groupName]: value,
          }
        })
        break
      default:
        break
    }

    setIsFilterChanged(true)
  }

  const handleApplyFilter = () => {
    const filter = { ...tempFilter }

    dispatch(adminActions.setDemoMeetingFilters(filter))
    dispatch(adminActions.setDemoMeetings([]))
    sessionStorage.setItem('demoMeetingFilters', JSON.stringify(tempFilter))
    getDemoMeetingsData({ freshFetch: true, filter })
    setIsFilterChanged(false)
  }

  const resetFilters = () => {
    const filter = { ...resettedDemoMeetingFilters }

    setTempFilter(filter)
    dispatch(adminActions.resetDemoMeetingFilters())
    sessionStorage.setItem(
      'demoMeetingFilters',
      JSON.stringify(resettedDemoMeetingFilters),
    )
    getDemoMeetingsData({ freshFetch: true, filter })
    setIsFilterChanged(false)
  }

  const getDemoMeetingsData = async ({
    freshFetch = false,
    filter = demoMeetingFilters,
    search = searchText,
  }) => {
    setIsLoading(true)
    await getDemoMeetings({ freshFetch, filter, search })
    setIsLoading(false)
  }

  const doSearch = async text => {
    dispatch(adminActions.setDemoMeetings([]))
    setSearchText(text)
    getDemoMeetingsData({ freshFetch: true, search: text })
    sessionStorage.setItem('demoMeetingsSearch', JSON.stringify(text))
  }

  const createDemoMeeting = async newDemoMeeting => {
    let finalObject = {}

    Object.keys(newDemoMeeting).forEach(key => {
      let value = newDemoMeeting[key]
      finalObject[key] = value.value
    })

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/demo-meetings/createDemoMeeting/`,
        'POST',
        JSON.stringify(finalObject),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      /*TODO dispatch(
        adminActions.findDemoMeetingByIdAndUpdate({
          id: demoMeeting._id,
          data: newUserData,
        }),
      ) */

      toast.success('New demo meeting created!')
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <div className="demo-meetings-container">
      <div className="layout">
        <div className="content">
          <div className="top">
            <div
              className="create-new-button"
              onClick={() => {
                openModal({
                  type: Modals.NEW_DEMO_MEETING,
                  closeOnClickOutside: true,
                  callback: async newMeetingData => {
                    createDemoMeeting(newMeetingData)
                  },
                })
              }}
            >
              New Meeting
            </div>
          </div>
          {!isLoading && !demoMeetings.length ? (
            <h2 className="not-found-text">
              No demo meetings found for these filters.
            </h2>
          ) : (
            <DemoMeetingsList
              isFetching={isLoading}
              reFetch={() => {
                getDemoMeetingsData({ freshFetch: false })
              }}
            />
          )}
        </div>
        <FiltersSection
          page='demoMeetings'
          isFilterChanged={isFilterChanged}
          handleApplyFilter={handleApplyFilter}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          doSearch={doSearch}
          searchText={searchText}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />
      </div>
      <AdminPanelDemoMeetingsSummarySection />
    </div>
  )
}

export default DemoMeetings
