import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PrivateComponent from '../../../shared/components/UIElements/PrivateComponent'
import { FaRegBell, FaUsers, FaHandshake, FaUserPlus } from 'react-icons/fa'

import './AdminCustomerTabs.css'

const AdminCustomerTabs = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(2)

  const handleTabClick = tabIndex => {
    switch (tabIndex) {
      case 3:
        navigate('/admin-panel/alarms')
        break

      case 0:
        navigate('/admin-panel/demo-meetings')
        break

      case 1:
        navigate('/admin-panel/sign-ups')
        break

      case 2:
        navigate('/admin-panel')
        break

      default:
        navigate('/admin-panel')
        break
    }
  }
  const checkIfActive = () => {
    if (window.location.pathname === '/admin-panel/alarms') {
      return 3
    }
    if (window.location.pathname === '/admin-panel') {
      return 2
    }

    if (window.location.pathname === '/admin-panel/sign-ups') {
      return 1
    }

    if (window.location.pathname === '/admin-panel/demo-meetings') {
      return 0
    }

    return -1
  }

  useEffect(() => {
    setActiveTab(checkIfActive())
  }, [window.location.pathname])

  return (
    <div className="admin-tabs-container">
      <div className="tab-buttons">
        <PrivateComponent 
          permissions={['viewAlarmsPage']}
          children={
            <button
              className={`tab-button ${activeTab === 3 ? 'active' : ''}`}
              onClick={() => handleTabClick(3)}
            >
              <FaRegBell className="tab-icon" />
              <span>Alarms</span>
            </button>
          } 
        />

        <PrivateComponent 
          permissions={['viewDemoMeetingsPage']}
          children={
            <button
              className={`tab-button ${activeTab === 0 ? 'active' : ''}`}
              onClick={() => handleTabClick(0)}
            >
              <FaHandshake className="tab-icon" />
              <span>Demo Meetings</span>
            </button>
          } 
        />

        <PrivateComponent 
          permissions={['viewSignUpsPage']}
          children={
            <button
              className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
              onClick={() => handleTabClick(1)}
            >
              <FaUserPlus className="tab-icon" />
              <span>Sign Ups</span>
            </button>
          } 
        />

        <PrivateComponent 
          permissions={['viewCustomersPage']}
          children={
            <button
              className={`tab-button ${activeTab === 2 ? 'active' : ''}`}
              onClick={() => handleTabClick(2)}
            >
              <FaUsers className="tab-icon" />
              <span>Customers</span>
            </button>
          } 
        />
      </div>
    </div>
  )
}

export default AdminCustomerTabs
