import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useStore } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import allCountriesList from '../../shared/data/countries.json'
import { useModal } from '../../shared/hooks/modal-hook'
import helper from '../../shared/util/helper'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import PublishButton from '../components/campaign/PublishButton'
import CampaignLabel from '../components/campaign/CampaignLabel'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import CampaignsPageLayout from '../components/campaign/CampaignsPageLayout'
import CampaignTargetingForm from '../components/campaign/CampaignTargetingForm'
import LoadingDotsSpinner from '../../shared/components/UIElements/LoadingDotsSpinner'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook/campaign-editor-hook'
import CampaignCreativesSectionEditMode from '../components/campaign/CampaignCreativesSectionEditMode'
import Modals from '../../shared/enums/Modals'
import { FaExclamationTriangle } from 'react-icons/fa'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import CampaignFormDisplayTypes from '../../shared/enums/CampaignFormDisplayTypes'
import useNavigator from '../../shared/hooks/navigator-hook'
import useAICreatives from '../../shared/hooks/ai-creatives-hook'

import './CreateCampaign.css'

const CreateCampaign = () => {
  const { enhencedCatalogDetails } = useAICreatives()
  const { endCampaignEditingWithoutModal, startNewEmptyCampaign, changeActiveDisplay } = useFacebookCampaignEditor()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const { removeParamFromUrl } = useNavigator()
  const [isLoading, setIsLoading] = useState(false)
  const [campaignCreated, setCampaignCreated] = useState(false)

  const [shouldRecreateCampaign, setShouldRecreateCampaign] = useState()
  const [campaignIdToBeRemoved, setCampaignIdToBeRemoved] = useState()
  const store = useStore()


  const isProcessing = useSelector(
    state => state.facebookCampaignEditor?.loadings.isProcessingTextFields,
  )

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const startWithTab = searchParams.get('startWithTab')
  const aiCreativeId = searchParams.get('aiCreativeId')
  const persist = searchParams.get('persist')

  const [editorRefreshed, setEditorRefreshed] = useState(false)
  const currentCampaign = useSelector(state => state.facebookCampaignEditor.currentCampaign)
  const activeDisplay = useSelector(state => state.facebookCampaignEditor.activeDisplay)

  const hasValidTargeting = useSelector(state => {
    const targeting = state.facebookCampaignEditor.currentCampaign?.targeting;

    const isAllFieldsFilled =
      targeting?.countries?.length > 0 &&
      targeting?.dailyBudget &&
      targeting?.age?.min &&
      targeting?.age?.max &&
      targeting?.genders;

    const isAllFieldsValid =
      targeting?.dailyBudget >= state.facebook.settings.minDailyBudget &&
      targeting?.age?.min >= 0 &&
      targeting?.age?.max <= 120 &&
      targeting?.age?.min <= targeting?.age?.max;

    return isAllFieldsFilled && isAllFieldsValid;
  });

  const hasCreative = useSelector(
    state => Object.keys(state.facebookCampaignEditor?.currentCampaign?.adCreatives || {}).length > 0
  )

  const toggleTab = (tab) => {
    changeActiveDisplay({ display: tab })
  };

  useEffect(() => {
    if (aiCreativeId){
      toggleTab(CampaignFormDisplayTypes.CREATIVE)
      return
    }

    if (startWithTab) {
      toggleTab(startWithTab)
    }
  }, [startWithTab])

  useEffect(() => {
    // We don't want to reset the form fields if we are coming from the enhenced catalog
    if ((aiCreativeId || persist) && currentCampaign) {
      setEditorRefreshed(true)
      removeParamFromUrl('persist')
      return
    }
    startNewEmptyCampaign()

    setEditorRefreshed(true)
  }, [])

  const handlePublish = async () => {
    let finalEditorState =
      store.getState().facebookCampaignEditor.currentCampaign
    try {
      const { dailyBudget, age, genders, countries } =
        finalEditorState.targeting
      const label = finalEditorState.label
      const adCreatives = {}

      Object.keys(finalEditorState.adCreatives).forEach(creativeId => {
        let newCreative = { ...finalEditorState.adCreatives[creativeId] }
        delete newCreative.existsInListWithId
        delete newCreative.replacesCreativeWithId
        delete newCreative.isEdited
        adCreatives[creativeId] = newCreative
      })

      const data = {
        label: label.trim(),
        countryCodes: countries.map(
          r => helper.findBy(allCountriesList, 'name', r).code,
        ),
        countries: countries,
        dailyBudget,
        age,
        genders: helper.getGendersList(genders),
        shouldRecreateCampaign,
        campaignIdToBeRemoved,
        adCreatives,
      }

      console.log('publish it ', data)

      setIsLoading(true)

      const campaignSetupResult = await openModal({
        type: Modals.CAMPAIGN_SETUP,
        campaignData: data
      })
      console.log("campaignSetupResult", campaignSetupResult)

      if (campaignSetupResult?.cancelled) {
        setIsLoading(false)
        return
      }


      if (campaignSetupResult?.shouldRecreate) {
        setShouldRecreateCampaign(true)
        setCampaignIdToBeRemoved(campaignSetupResult.adCampaign?.id)
        setIsLoading(false)
      } else {
        cleanUp(campaignSetupResult?.fullFunnel)
      }
    } catch (err) {
      console.log("caught error ", err)
      toast.warn(
        `Could not create campaign. ${err.error_user_msg ?? 'Please contact us.'}`,
      )
      setIsLoading(false)
    }
  }

  const cleanUp = newFullFunnel => {

    setIsLoading(false)

    if (newFullFunnel) {
      openModal({
        type: Modals.CAMPAIGN_CREATION,
        enhencedCatalogDetails,
        fullFunnel: newFullFunnel,
        callback: () => {
          endCampaignEditingWithoutModal()
          navigate(`/ai-ads/full-funnel-campaigns?id=${newFullFunnel._id}`)
          setTimeout(() => {
            setCampaignCreated(false)
          }, 2000)
        },
      })
    }

    const aiCreativeProducts = sessionStorage.getItem('aiCreativeProducts')
    const currentAiCreativeId = sessionStorage.getItem('currentAiCreativeId')

    if (aiCreativeProducts) {
      sessionStorage.removeItem('aiCreativeProducts')
    }

    if (currentAiCreativeId) {
      sessionStorage.removeItem('currentAiCreativeId')
    }

    setCampaignCreated(true)
  }

  const shouldShowTargetingErrors = activeDisplay === CampaignFormDisplayTypes.CREATIVE && !hasValidTargeting;

  return editorRefreshed ? (
    <CampaignsPageLayout>
      <FluidCard className="create-campaign">
        <FluidCard.Header>
          <div className="header-wrapper">
            <div className="options">
              <CampaignLabel />
            </div>
            <div className="actions">
              {isProcessing && <LoadingDotsSpinner />}
              <PublishButton busy={isLoading} onClick={handlePublish} />
            </div>
          </div>
        </FluidCard.Header>
        <FluidCard.Content>
          <div className="create-campaign-content-wrapper">
            {/* {isLoading && (
              <div className="overlay">
                
              </div>
            )}
            {campaignCreated && (
              <div className="overlay">
                <div className="campaign-created-message">
                  <div className="icon-wrapper">
                    <img
                      src="https://cdn.enhencer.com/website-assets/images/icon-set/success.png"
                      alt="success"
                    />
                  </div>
                  Your campaign was published successfully!
                </div>
              </div>
            )} */}

            <div className="campaign-sections">
              <div className="campaign-tabs" data-active-tab={activeDisplay}>
                <div
                  className={`campaign-tab ${activeDisplay === CampaignFormDisplayTypes.TARGETING ? 'active' : ''}`}
                  onClick={() => toggleTab(CampaignFormDisplayTypes.TARGETING)}
                  data-tab={CampaignFormDisplayTypes.TARGETING}
                >
                  Targeting
                  {shouldShowTargetingErrors && (
                    <Tooltip content="Please fill in all required targeting fields">
                      <FaExclamationTriangle className="tab-error-icon" />
                    </Tooltip>
                  )}
                </div>
                <div
                  className={`campaign-tab ${activeDisplay === CampaignFormDisplayTypes.CREATIVE ? 'active' : ''} ${hasValidTargeting ? 'pulse' : ''}`}
                  onClick={() => toggleTab(CampaignFormDisplayTypes.CREATIVE)}
                  data-tab={CampaignFormDisplayTypes.CREATIVE}
                >
                  Creative
                </div>
              </div>

              <div className="campaign-content">
                <div className="left-panel">
                  <div className="targeting-section" style={{ display: activeDisplay === CampaignFormDisplayTypes.TARGETING ? 'block' : 'none' }}>
                    <CampaignTargetingForm />
                    {hasValidTargeting && !hasCreative && (
                      <div className="success-message">
                        <span>All targeting fields are complete! You can proceed to </span>
                        <span className="link" onClick={() => toggleTab(CampaignFormDisplayTypes.CREATIVE)}>
                          {CampaignFormDisplayTypes.CREATIVE}
                        </span>
                      </div>
                    )}
                  </div>
                  <div style={{ display: activeDisplay === CampaignFormDisplayTypes.CREATIVE ? 'block' : 'none' }}>
                    <CampaignCreativesSectionEditMode />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FluidCard.Content>
      </FluidCard>
    </CampaignsPageLayout>
  ) : (
    <InlineLoadingSpinner message="Initializing editor"></InlineLoadingSpinner>
  )
}

export default CreateCampaign

