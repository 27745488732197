import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Button from '../../shared/components/FormElements/Button'
import dateFormatter from '../../shared/util/dateFormatter'
import shopifyLogo from '../../shared/assets/images/icons/shopify_logo_black.png'
import iyzicoLogo from '../../shared/assets/images/icons/iyzico_logo.png'
import stripeLogo from '../../shared/assets/images/icons/stripe-logo.png'
import checkIcon from '../../shared/assets/images/icons/check-green.svg'
import { useModal } from '../../shared/hooks/modal-hook'
import Modals from '../../shared/enums/Modals'
import { useHttpClient } from '../../shared/hooks/http-hook'

import { useConstants } from '../../shared/hooks/constants-hook'
import { useUser } from '../../shared/hooks/user-hook'
import helper from '../../shared/util/helper'
import errorHandler from '../../shared/util/errorHandler'

const SubscriptionPlanInfoCard = props => {

  const auth = useSelector(state => state.auth)
  const user = auth.user
  const subscription = user.subscription
  const isFromShopify = subscription.source == 'Shopify'
  const { getPackageFeatures } = useUser()
  const { openModal } = useModal()
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()

  const currentPackage = getPackageFeatures()

  const updateCard = async () => {
    if (subscription.source === 'Stripe') {
      openModal({
        type: Modals.STRIPE_UPDATE_CREDIT_CARD,
        closeOnClickOutside: true,
      })
      return
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/payment/updatePaymentCard`,
        'POST',
        JSON.stringify({
          subscriptionReferenceCode: user.subscription.refCode,
          // subscriptionReferenceCode: '905396a2-c994-4dec-b0c8-37c2ceabc773'
        }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      if (result.status === 'success') {
        localStorage.setItem('iyziUpdateToken', result.token)
        navigate('/subscription/payment/update-card', {
          state: { checkoutFormContent: result.checkoutFormContent },
        })
      }
    } catch (err) {
      errorHandler({
        message:
          'Error while updating the payment method. Try again & contact us if the problem persists.',
      })
      console.log(err)
    }
  }
  const cancelSubscription = () => {
    openModal({
      type: Modals.BEFORE_YOU_CANCEL,
      closeOnClickOutside: true,
    })
  }

  const goToSubscriptionPage = () => {
    navigate('/account/subscription')
  }

  return (
    <div className="plan-info-card">
      <div className="plan-column">
        <div>
          <div className="plan-name">
            Your Current Plan :{' '}
            <span className="green-text">
              {subscription ? subscription.plan : 'Custom'}{' '}
            </span>
          </div>
          {subscription?.nextPaymentDate && (
            <div className="renew-date">
              Your next billing is on{' '}
              {dateFormatter(subscription.nextPaymentDate, true)}
            </div>
          )}
        </div>
        <div>
          <div className="plan-price">
            Current Plan:{' '}
            <span className="green-text">
              {subscription?.price ? (
                <>
                  {helper.getCurrencySymbol(subscription.currency)}{subscription.price} /{' '}
                  {subscription.interval
                    ? subscription.interval.toLowerCase()
                    : 'Monthly'}
                </>
              ) : (
                'No info'
              )}
            </span>
          </div>
          <div className="payment-channel">
            Payment Channel:{' '}
            <img
              alt="channel logo"
              src={
                subscription?.source === 'Iyzico'
                  ? iyzicoLogo
                  : isFromShopify
                    ? shopifyLogo
                    : stripeLogo
              }
              className="channel-icon"
            />
          </div>
        </div>
        {props.pageType == 'payment-completed' && (
          <div className="smaller-text" onClick={() => goToSubscriptionPage()}>
            See details
          </div>
        )}
        {props.pageType == 'subscription' && (
          <div className="buttons-row">
            {!isFromShopify ? (
              <>
                {' '}
                <Button
                  className="plan-button update-billing-info-button "
                  onClick={updateCard}
                >
                  Update Billing Information
                </Button>
              </>
            ) : (
              ''
            )}

            <Button
              className="plan-button cancel-subs-button"
              onClick={cancelSubscription}
            >
              Cancel Subscription
            </Button>
          </div>
        )}
      </div>
      <div className="plan-features-column">
        {currentPackage.features?.length ? (
          <div>
            Available Features in your plan:
            <div className="plan-features-box">
              {currentPackage.features.map((feature, index) => (
                <div
                  key={index}
                  className={`grid-item ${feature.isChecked ? '' : 'unchecked'}`}
                >
                  <span>
                    <img className="icon" src={checkIcon} alt="check icon" />
                  </span>
                  {feature.text}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default SubscriptionPlanInfoCard
