import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'
import AIAdsPageLayout from '../AIAdsPageLayout'
import Breadcrumb from '../../../shared/components/UIElements/Breadcrumb'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import CampaignReport from '../report/CampaignReport'
import { useFacebookCampaignEditor } from '../../../shared/hooks/facebook/campaign-editor-hook'
import adLauncher from '../../../shared/assets/images/ai-ads/ad-launcher.png'

import './CampaignsPageLayout.css'
import AIAgent from '../AIAgent'

const CampaignsPageLayout = ({ children }) => {
  const { fullFunnelInViewId, fullFunnels } = useSelector(state => state.facebook)
  const user = useSelector(state => state.auth.user)
  const scrollRef = useRef(null)
  const { pathname, search } = useLocation()

  const searchParams = new URLSearchParams(search)
  const fullFunnelId = searchParams.get('id')

  const shouldShowFullView = useMemo(() => {
    return Boolean(fullFunnelId || pathname.includes('/create'))
  }, [fullFunnelId, pathname])

  const isCreatePage = pathname.includes('/create')

  const { navigateWithAdminParams } = useAdmin()
  const isBasicUser = !user?.isSpecialUser

  const campaignInView = useMemo(() => {
    return fullFunnels.find(c => c._id === fullFunnelInViewId)
  }, [fullFunnels, fullFunnelInViewId])

  const [isEditing, setIsEditing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const { endCampaignEditing } = useFacebookCampaignEditor()
  const [filterType, setFilterType] = useState('All')

  const filteredChildren = useMemo(() => {
    if (filterType === 'All') return children;

    return children.props.children.filter(child => child.props.campaign.reports.campaignLevel.effectiveStatus === 'ACTIVE')
  }, [children, filterType]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const editParam = searchParams.get('edit')
    setIsEditing(editParam === 'true')
  }, [search])

  useEffect(() => {
    if (isEditing) {
      setIsExpanded(true)
    }
  }, [isEditing])

  useEffect(() => {
    const currentRef = scrollRef.current
    if (currentRef) {
      const handleWheel = (e) => {
        if (!e.shiftKey) {
          const delta = e.deltaY
          currentRef.scrollLeft += delta
        }
      }

      currentRef.addEventListener('wheel', handleWheel)
      return () => {
        currentRef.removeEventListener('wheel', handleWheel)
      }
    }
  }, [])

  const createNewCampaign = useCallback(() => {
    navigateWithAdminParams('/ai-ads/full-funnel-campaigns/create')
  }, [navigateWithAdminParams])


  const handleDetailCancel = useCallback(() => {
    endCampaignEditing({
      callback: () => {
        setIsEditing(false);
        const searchParams = new URLSearchParams(search);
        searchParams.delete('edit');
        navigateWithAdminParams(`/ai-ads/full-funnel-campaigns?id=${fullFunnelInViewId}`);
      }
    });
  }, [fullFunnelInViewId, search, navigateWithAdminParams, endCampaignEditing]);


  const shouldShowToggle = useMemo(() => {
    if (!fullFunnels || fullFunnels.length === 0) return false;

    const activeCount = fullFunnels.filter(
      campaign => campaign?.reports?.campaignLevel?.effectiveStatus === 'ACTIVE'
    ).length;

    const inactiveCount = fullFunnels.filter(
      campaign => campaign?.reports?.campaignLevel?.effectiveStatus !== 'ACTIVE'
    ).length;

    if (activeCount === 0 || inactiveCount === 0) return false;

    return true;
  }, [fullFunnels]);


  return (
    <AIAdsPageLayout>
      <div className="campaign-page-layout">
        <AIAdsPageLayout.Content>
          <div className="breadcrumb-container">
            <Breadcrumb actions={
              pathname === '/ai-ads/full-funnel-campaigns' || pathname.includes('/ai-ads/full-funnel-campaigns?id=') ? (
                <AIAgent isCampaignsPage={true} />
              ) : null
            }>
              <span onClick={() => navigateWithAdminParams('/ai-ads/full-funnel-campaigns')}>
                Facebook Ads
              </span>

              {isCreatePage && (
                <span onClick={() => navigateWithAdminParams('/ai-ads/full-funnel-campaigns/create')}>
                  Create
                </span>
              )}

              {fullFunnelInViewId && !isCreatePage && (
                <span
                  className={campaignInView?.label ? "clickable" : ""}
                  onClick={campaignInView?.label ? () => navigateWithAdminParams(`/ai-ads/full-funnel-campaigns?id=${fullFunnelInViewId}`) : undefined}
                >
                  {campaignInView?.label || "Overall"}
                </span>
              )}
            </Breadcrumb>
          </div>

          <div className="content-wrapper">
            {fullFunnels.length === 0 && isBasicUser && !shouldShowFullView && (
              <div className="empty-campaigns-container">
                <div className="no-campaigns-content">
                  <div className="empty-state-icon-container">
                    <img src={adLauncher} alt="ad launcher" className="empty-state-icon" />
                  </div>
                  <h2 className="empty-state-title">
                    Start Your First Campaign
                  </h2>
                  <p className="empty-state-description">
                    Create your first AI-powered campaign and unlock powerful advertising features to boost your business growth.
                  </p>
                  <button
                    onClick={createNewCampaign}
                    className="empty-state-button"
                  >
                    <FaPlus className="button-icon" />
                    Create Your First AI Ads Campaign
                  </button>
                </div>
              </div>
            )}

            {!shouldShowFullView && fullFunnels.length > 0 &&
              <CampaignReport />}

            {!shouldShowFullView && fullFunnels.length > 0 && (
              <div className="bottom-section">
                {shouldShowToggle && (
                  <div className="bottom-section-header">
                    <div className="filter-toggle-container">
                      <span
                        className={filterType === 'All' ? 'active' : ''}
                        onClick={() => setFilterType('All')}
                      >
                        All
                      </span>
                      <span
                        className={filterType === 'Active' ? 'active' : ''}
                        onClick={() => setFilterType('Active')}
                      >
                        Active
                      </span>
                    </div>
                  </div>
                )}
                <div ref={scrollRef} className="ai-ads-full-funnel-campaigns-grid">
                  {isBasicUser && (
                    <button className="create-campaign-card" onClick={createNewCampaign}>
                      <FaPlus />
                      <span>Create New Campaign</span>
                    </button>
                  )}
                  {filteredChildren}
                </div>
              </div>
            )}

            {shouldShowFullView && (
              <div className={`ai-ads-full-funnel-campaigns-grid full`}>
                {React.cloneElement(children, {
                  isExpanded,
                  onCancel: handleDetailCancel
                })}
              </div>
            )}
          </div>
        </AIAdsPageLayout.Content>
      </div>
    </AIAdsPageLayout>
  )
}

export default CampaignsPageLayout