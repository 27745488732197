import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import 'react-toastify/dist/ReactToastify.css'
import './CampaignCreationProgress.css'
import { FaCheckCircle, FaTimesCircle, FaSpinner, FaHeadset } from 'react-icons/fa'
import InlineLoadingSpinner from '../UIElements/InlineLoadingSpinner'
import Modals from '../../enums/Modals'
import { useFullFunnel } from '../../hooks/facebook/full-funnel-hook'

const CampaignCreationProgress = ({
  campaignData,
  onComplete
}) => {
  const { createFullFunnel, fetchNewlyCreatedFullFunnel } = useFullFunnel()
  const { openModal, modalData, closeModal } = useModal()
  const [startedCampaignCreation, setStartedCampaignCreation] = useState(false)
  const [createdFullFunnel, setCreatedFullFunnel] = useState(null)
  const [isCompletingProcess, setIsCompletingProcess] = useState(false)

  const campaignCreation = useSelector(state => state.facebook.campaignCreation)


  const [isConversionRemarketingTurn, setIsConversionRemarketingTurn] = useState(true)
  const [isConversionNewUserTurn, setIsConversionNewUserTurn] = useState(false)
  const [isAdvantagePlusTurn, setIsAdvantagePlusTurn] = useState(false)
  const [isAwarenessTurn, setIsAwarenessTurn] = useState(false)
  const [isTrafficTurn, setIsTrafficTurn] = useState(false)
  const [isTrafficToInstagramTurn, setIsTrafficToInstagramTurn] = useState(false)

  const [isAllSuccess, setIsAllSuccess] = useState(false)
  const [isAllError, setIsAllError] = useState(false)
  const [animationStarted, setAnimationStarted] = useState(false)
  const [titleVisible, setTitleVisible] = useState(true)

  const hasError = Object.values(campaignCreation).some(
    campaign => campaign && campaign.message && campaign.message === 'error'
  )

  const [retryingCampaignType, setRetryingCampaignType] = useState(null)


  useEffect(() => {
    if (campaignCreation.conversionRemarketingCampaign?.message === 'success') {
      setIsConversionRemarketingTurn(false)
      setIsConversionNewUserTurn(true)
    }

    if (campaignCreation.conversionNewUserCampaign?.message === 'success') {
      setIsConversionNewUserTurn(false)
      setIsAdvantagePlusTurn(true)
    }

    if (campaignCreation.advantagePlusCampaign?.message === 'success') {
      setIsAdvantagePlusTurn(false)
      if (campaignData.boostBudget) {
        setIsAwarenessTurn(true)
      }
    }

    if (campaignData.boostBudget) {
      if (campaignCreation.awarenessCampaign?.message === 'success') {
        setIsAwarenessTurn(false)
        setIsTrafficTurn(true)
      }

      if (campaignCreation.trafficCampaign?.message === 'success') {
        setIsTrafficTurn(false)
        setIsTrafficToInstagramTurn(true)
      }

      if (campaignCreation.trafficToInstagramCampaign?.message === 'success') {
        setIsTrafficToInstagramTurn(false)
      }
    }
  }, [campaignCreation])

  useEffect(() => {
    const startCampaignCreation = async () => {
      try {
        const campaignCreationResult = await createFullFunnel(campaignData)
        setCreatedFullFunnel(campaignCreationResult.fullFunnel)
      } catch (error) {
        console.log('error', error)
      }
    }

    if (!startedCampaignCreation) {
      startCampaignCreation()
      setStartedCampaignCreation(true)
    }
  }, [campaignData, startedCampaignCreation])

  const createASupportTicket = () => {
    openModal({
      type: Modals.CREATE_A_SUPPORT_TICKET,
      data: {
        subject: 'Campaign Creation Failed',
        message: `Campaign creation process failed. Campaign Data: ${JSON.stringify(campaignData)}`,
        priority: 'high',
        category: 'Campaign Creation'
      }
    })
  }

  const handleRetry = (campaignType) => {
    console.log('handleRetry', campaignType)

    setRetryingCampaignType(campaignType)

    switch (campaignType) {
      case 'conversionRemarketing':
        setIsConversionRemarketingTurn(true)
        break
      case 'conversionNewUser':
        setIsConversionNewUserTurn(true)
        break
      case 'advantagePlus':
        setIsAdvantagePlusTurn(true)
        break
      case 'awareness':
        setIsAwarenessTurn(true)
        break
      case 'traffic':
        setIsTrafficTurn(true)
        break
      case 'trafficToInstagram':
        setIsTrafficToInstagramTurn(true)
        break
    }

    createFullFunnel({
      ...campaignData,
      retryType: campaignType
    }).finally(() => {
      setRetryingCampaignType(null)
    })
  }

  const checkAllSuccess = useCallback(() => {
    if (hasError) return false;

    const requiredCampaigns = ['conversionRemarketingCampaign', 'conversionNewUserCampaign', 'advantagePlusCampaign']

    if (campaignData.boostBudget) {
      requiredCampaigns.push('awarenessCampaign', 'trafficCampaign', 'trafficToInstagramCampaign')
    }

    setIsAllError(
      requiredCampaigns.every(
        campaign => campaignCreation[campaign]?.message === 'error'
      )
    )

    return requiredCampaigns.every(
      campaign => campaignCreation[campaign]?.message === 'success'
    )
  }, [campaignCreation, campaignData.boostBudget, hasError])

  useEffect(() => {
    if (checkAllSuccess() && !animationStarted) {
      setAnimationStarted(true)

      const rows = document.querySelectorAll('.campaign-creation-progress .row')
      const totalDuration = rows.length * 400

      rows.forEach((row, index) => {
        setTimeout(() => {
          row.classList.add('animate-success')
        }, index * 400)
      })

      setTimeout(() => {
        setTitleVisible(false)
      }, totalDuration + 200)

      setTimeout(() => {
        setIsAllSuccess(true)
      }, totalDuration + 700)

      /* setTimeout(() => {
        handleDone()
      }, totalDuration + 2000) */
    }
  }, [checkAllSuccess, animationStarted])

  const handleCancel = () => {
    modalData.resolve({
      cancelled: true
    })
    closeModal()
  }

  const handleContinue = () => {
    handleDone()
  }

  const handleDone = async () => {
    setIsCompletingProcess(true)
    await fetchNewlyCreatedFullFunnel({
      fullFunnelId: campaignCreation.fullFunnelId
    })
    setIsCompletingProcess(false)
    if (onComplete) {
      onComplete({ fullFunnel: createdFullFunnel })
    }
  }

  return (
    <div className="campaign-creation-progress">
      <div className={`content ${!titleVisible && !hasError ? 'slide-up' : ''}`}>
        <div className={`title-container ${!titleVisible && !hasError ? 'collapsed' : ''}`}>
          <div className={`title ${!titleVisible && !hasError ? 'fade-out' : ''}`}>
            Your campaigns are being created...
          </div>
        </div>



        {campaignCreation.conversionRemarketingCampaign?.message === 'success' ?
          <div className="row">
            <FaCheckCircle className="icon check-icon" />
            <div className="text success-text">Conversion Remarketing Campaign is created</div>
          </div>
          :
          campaignCreation.conversionRemarketingCampaign?.message === 'error' ?
            <ErrorRow
              campaignType="conversionRemarketing"
              text="Conversion Remarketing Campaign could not be created"
              campaignCreation={campaignCreation}
              retryingCampaignType={retryingCampaignType}
              onRetry={handleRetry}
            />
            :
            <LoadingRow
              campaignType="conversionRemarketing"
              campaignName="Conversion Remarketing"
              isCurrentTurn={isConversionRemarketingTurn}
              showRetry={campaignCreation.conversionRemarketingCampaign && campaignCreation.conversionRemarketingCampaign.message === 'error'}
              onRetry={handleRetry}
              currentStageMessage={campaignCreation.conversionRemarketingCampaign?.message}
              progress={campaignCreation.conversionRemarketingCampaign?.progress}
            />
        }

        {campaignCreation.conversionNewUserCampaign?.message === 'success' ?
          <div className="row">
            <FaCheckCircle className="icon check-icon" />
            <div className="text success-text">Conversion New User Campaign is created</div>
          </div>
          :
          campaignCreation.conversionNewUserCampaign?.message === 'error' ?
            <ErrorRow
              campaignType="conversionNewUser"
              text="Conversion New User Campaign could not be created"
              campaignCreation={campaignCreation}
              retryingCampaignType={retryingCampaignType}
              onRetry={handleRetry}
            />
            :
            <LoadingRow
              campaignType="conversionNewUser"
              campaignName="Conversion New User"
              isCurrentTurn={isConversionNewUserTurn}
              showRetry={campaignCreation.conversionNewUserCampaign && campaignCreation.conversionNewUserCampaign.message === 'error'}
              onRetry={handleRetry}
              currentStageMessage={campaignCreation.conversionNewUserCampaign?.message}
              progress={campaignCreation.conversionNewUserCampaign?.progress}
            />
        }

        {campaignCreation.advantagePlusCampaign?.message === 'success' ?
          <div className="row">
            <FaCheckCircle className="icon check-icon" />
            <div className="text success-text">Advantage Plus Campaign is created</div>
          </div>
          :
          <LoadingRow
            campaignType="advantagePlus"
            campaignName="Advantage Plus"
            isCurrentTurn={isAdvantagePlusTurn}
            showRetry={campaignCreation.advantagePlusCampaign && campaignCreation.advantagePlusCampaign.message === 'error'}
            onRetry={handleRetry}
            currentStageMessage={campaignCreation.advantagePlusCampaign?.message}
            progress={campaignCreation.advantagePlusCampaign?.progress}
          />
        }
        {campaignCreation.advantagePlusCampaign &&
          campaignCreation.advantagePlusCampaign.message === 'error' &&
          <ErrorRow
            campaignType="advantagePlus"
            text="Advantage Plus Campaign could not be created"
            campaignCreation={campaignCreation}
            retryingCampaignType={retryingCampaignType}
            onRetry={handleRetry}
          />
        }

        {/* show boost campaigns */}
        {campaignData.boostBudget ? (
          <>
            <div className="subtitle">Boost Campaigns</div>

            {campaignCreation.awarenessCampaign?.message === 'success' ?
              <div className="row">
                <FaCheckCircle className="icon check-icon" />
                <div className="text success-text">Awareness Campaign is created</div>
              </div>
              :
              campaignCreation.awarenessCampaign?.message === 'error' ?
                <ErrorRow
                  campaignType="awareness"
                  text="Awareness Campaign could not be created"
                  campaignCreation={campaignCreation}
                  retryingCampaignType={retryingCampaignType}
                  onRetry={handleRetry}
                />
                :
                <LoadingRow
                  campaignType="awareness"
                  campaignName="Awareness"
                  isCurrentTurn={isAwarenessTurn}
                  showRetry={campaignCreation.awarenessCampaign && campaignCreation.awarenessCampaign.message === 'error'}
                  onRetry={handleRetry}
                  currentStageMessage={campaignCreation.awarenessCampaign?.message}
                  progress={campaignCreation.awarenessCampaign?.progress}
                />
            }

            {campaignCreation.trafficCampaign?.message === 'success' ?
              <div className="row">
                <FaCheckCircle className="icon check-icon" />
                <div className="text success-text">Traffic Campaign is created</div>
              </div>
              :
              campaignCreation.trafficCampaign?.message === 'error' ?
                <ErrorRow
                  campaignType="traffic"
                  text="Traffic Campaign could not be created"
                  campaignCreation={campaignCreation}
                  retryingCampaignType={retryingCampaignType}
                  onRetry={handleRetry}
                />
                :
                <LoadingRow
                  campaignType="traffic"
                  campaignName="Traffic"
                  isCurrentTurn={isTrafficTurn}
                  showRetry={campaignCreation.trafficCampaign && campaignCreation.trafficCampaign.message === 'error'}
                  onRetry={handleRetry}
                  currentStageMessage={campaignCreation.trafficCampaign?.message}
                  progress={campaignCreation.trafficCampaign?.progress}
                />
            }

            {campaignCreation.trafficToInstagramCampaign?.message === 'success' ?
              <div className="row">
                <FaCheckCircle className="icon check-icon" />
                <div className="text success-text">Traffic to Instagram Campaign is created</div>
              </div>
              :
              campaignCreation.trafficToInstagramCampaign?.message === 'error' ?
                <ErrorRow
                  campaignType="trafficToInstagram"
                  text="Traffic to Instagram Campaign could not be created"
                  campaignCreation={campaignCreation}
                  retryingCampaignType={retryingCampaignType}
                  onRetry={handleRetry}
                />
                :
                <LoadingRow
                  campaignType="trafficToInstagram"
                  campaignName="Traffic to Instagram"
                  isCurrentTurn={isTrafficToInstagramTurn}
                  showRetry={campaignCreation.trafficToInstagramCampaign && campaignCreation.trafficToInstagramCampaign.message === 'error'}
                  onRetry={handleRetry}
                  currentStageMessage={campaignCreation.trafficToInstagramCampaign?.message}
                  progress={campaignCreation.trafficToInstagramCampaign?.progress}
                />
            }
          </>
        ) : null}
      </div>


      {isAllSuccess && !hasError && (
        <div className="success-footer">
          <div className="success-content">
            <FaCheckCircle className="success-icon" />
            <div className="success-text">
              All campaigns have been successfully created!
            </div>
          </div>
          <Button className="button done" onClick={handleDone} loading={isCompletingProcess} loadingText="Completing process...">
            Done
          </Button>
        </div>
      )}

      {hasError && (
        <div className="footer">
          <Button
            onClick={createASupportTicket}
            className="support-button"
          >
            <FaHeadset className="support-icon" />
            Report Issue
          </Button>
          {
            isAllError ? (
              <Button
                onClick={handleCancel}
                className="cancel-button"
              >
                Cancel
              </Button>
            ) : <Button
              onClick={handleContinue}
              className="continue-button"
            >
              Continue with successful campaigns
            </Button>
          }
        </div>
      )}
    </div>
  )
}

export default CampaignCreationProgress


const LoadingRow = ({ campaignType, campaignName, isCurrentTurn, showRetry, onRetry, currentStageMessage, progress }) => {
  return (
    <div className="loading-row">
      <div className={`row ${isCurrentTurn ? 'current-turn' : 'waiting'}`}>
        {isCurrentTurn ?
          <>
            {/* <InlineLoadingSpinner className="inline-loading" /> */}
            <div className="text" style={isCurrentTurn ? { fontWeight: '600' } : undefined}>{campaignName} Campaign is being created...</div>
            {showRetry && (
              <Button
                className="retry-button"
                onClick={() => onRetry(campaignType)}
              >
                Retry
              </Button>
            )}
          </>
          :
          <div className="text">{campaignName} Campaign is waiting in queue...</div>
        }
      </div>


      {
        isCurrentTurn && !isNaN(progress) ? <>
          <div className="progress-bar">
            <div
              className="progress-fill"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className='progress-details-row'>
            <p className="progress-message">{currentStageMessage}</p>
            <div className='progress-percentage-text'>{Math.floor(progress)}%</div>
          </div>
        </>
          : null
      }

    </div>
  )
}


const ErrorRow = ({ campaignType, text, campaignCreation, retryingCampaignType, onRetry }) => (
  <div className="row error">
    <FaTimesCircle className="icon times-icon" />
    <div className="error-content">
      <div className="text">{text}</div>
      <div className="error-message">
        {campaignCreation[`${campaignType}Campaign`]?.error || 'Campaign creation failed. Please try again.'}
      </div>
    </div>
    {retryingCampaignType === campaignType ? (
      <div className="retry-loading">
        <InlineLoadingSpinner className="inline-loading" />
      </div>
    ) : (
      <Button
        className="retry-button"
        onClick={() => onRetry(campaignType)}
      >
        Retry
      </Button>
    )}
  </div>
)