import React, { useEffect, useState } from 'react'
import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import CustomDatePicker from '../UIElements/CustomDatePicker'
import { FaTimes, FaTrash } from 'react-icons/fa'
import helper from '../../util/helper'
import { useSelector } from 'react-redux'
import PeopleSelector from '../UIElements/PeopleSelector'
import StrokeInput from '../FormElements/StrokeInput'

const EditAlarm = props => {
  const { closeModal, modalData } = useModal()

  let [dueDate, setDueDate] = useState(props.data.alarm.dueDate ? new Date(props.data.alarm.dueDate) : new Date())
  const [timeHour, setTimeHour] = useState(props.data.alarm.dueDate ? new Date(props.data.alarm.dueDate).getHours() : 23);
  const [timeMinute, setTimeMinute] = useState(props.data.alarm.dueDate ? new Date(props.data.alarm.dueDate).getMinutes() : 59);
  const [titleValue, setTitleValue] = useState(props.data.alarm.title ?? "")
  const [noteValue, setNoteValue] = useState(props.data.alarm.note ?? "")
  let [isTimeActive, setIsTimeActive] = useState(false)
  const [isDone, setIsDone] = useState(props.data.alarm.done ?? false)

  const { managerUsers } = useSelector(state => state.admin)
  const [attachedUserIds, setAttachedUserIds] = useState([])

  useEffect(() => {
    setAttachedUserIds(props.data.alarm.attachedUserIds ?? [])
  }, [props.data.alarm.attachedUserIds])


  const update = (done) => {
    modalData.callback({ dueDate, title: titleValue, note: noteValue, done: done ?? isDone, attachedUserIds })
    closeModal()
  }

  const close = () => {
    closeModal()
  }
  const handleTitleChange = (event) => {
    const newValue = typeof event === 'string' ? event : event?.target?.value || '';
    setTitleValue(newValue);
  };

  const handleNoteChange = (event) => {
    const newValue = typeof event === 'string' ? event : event?.target?.value || '';
    setNoteValue(newValue);
  };

  const handleDoneToggle = () => {
    setIsDone(!isDone);
  };

  // Generating hours from 00:00 to 23:45
  const hours = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
      const hour = (i < 10 ? '0' : '') + i + ':' + (j < 10 ? '0' : '') + j;
      hours.push(hour);
    }
  }

  function convertToHourFormat(hourInteger) {
    const hour = (hourInteger < 10 ? '0' : '') + hourInteger;
    return hour;
  }

  // Function to handle change in end hour
  const handleTimeHourChange = (e) => {
    const [hourStr, minuteStr] = e.target.value.split(":");
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);

    setTimeHour(hour);
    setTimeMinute(minute);
    let oldDueDate = new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate(), 0, 0, 0); // Reset hours and minutes
    const newDueDate = new Date(oldDueDate.getTime() + (hour * 60 + minute) * 60 * 1000);
    setDueDate(newDueDate);
  };

  useEffect(() => {
    if (timeHour === 23 && timeMinute === 59) {
      setIsTimeActive(false)
    } else {
      setIsTimeActive(true)
    }
  }, [timeHour, timeMinute])


  return (
    <div className="alarm-modal edit-alarm-modal">
      <div className="modal-title">
        <div className='alarm-name'>Edit alarm</div>
        <div>-</div>
        <div className='customer-name'>{props.data.customer.name}</div>
        <Button size="small" onClick={close}><FaTimes /></Button>
      </div>
      <div className="modal-middle">
        {props.data.alarm.meetingInfo
          ? <div className='property row form-control'>
            <div className='property-desc'>Zoom:</div>
            <div className="meeting-info-row row">
              {props.data.alarm.meetingInfo.zoomJoinUrl ? <Button href={props.data.alarm.meetingInfo.zoomJoinUrl} openInNewTab={true}>Join</Button> : null}
              {props.data.alarm.meetingInfo.rescheduleUrl ? <Button href={props.data.alarm.meetingInfo.rescheduleUrl} openInNewTab={true}>Reschedule</Button> : null}
              {props.data.alarm.meetingInfo.cancelUrl ? <Button href={props.data.alarm.meetingInfo.cancelUrl} openInNewTab={true}>Cancel</Button> : null}
            </div>
            {props.data.alarm.meetingInfo.canceled && <span className="status canceled">Event has been canceled at{` ${helper.convertDateToString(props.data.alarm.meetingInfo.canceledAt)}`}</span>}
            {props.data.alarm.meetingInfo.rescheduled && <span className="status rescheduled">Event has been rescheduled at{` ${helper.convertDateToString(props.data.alarm.meetingInfo.rescheduledAt)}`}</span>}
          </div>
          : null}
        <div className='property row'>
          <div className='property-desc'>Due date:</div>
          <div className='due-date-selector form-control'>
            <div>
              <CustomDatePicker
                placeholder="DD/MM/YYYY"
                date={dueDate ?? new Date()}
                onChange={d => {
                  const dateAtMidnight = new Date(d.getFullYear(), d.getMonth(), d.getDate())
                  const newDueDate = new Date(dateAtMidnight.getTime() + (timeHour * 60 + timeMinute) * 60 * 1000);
                  setDueDate(newDueDate)
                }}
              />
            </div>
            <div>-</div>
            <div>
              {isTimeActive ? <select className='selectable' id="endHour"
                value={`${convertToHourFormat(timeHour)}:${convertToHourFormat(timeMinute)}`}
                onChange={handleTimeHourChange}>
                {hours.map((hour, index) => (
                  <option key={index} value={hour}>{hour}</option>
                ))}
              </select> : <Button onClick={() => {
                setTimeHour(0)
                setTimeMinute(0)
                setIsTimeActive(true)
              }}>Add time</Button>
              }
            </div>
          </div>
        </div>
        <StrokeInput
          id="title"
          element="input"
          type="text"
          label="Title"
          value={titleValue}
          onChange={handleTitleChange}
          required
          errorLine={false}
        />

        <StrokeInput
          id="note"
          element="textarea"
          label="Note"
          value={noteValue}
          onChange={handleNoteChange}
          rows={3}
          errorLine={false}
        />

        <PeopleSelector 
          attachedUserIds={attachedUserIds}
          managerUsers={managerUsers}
          onChange={setAttachedUserIds}
        />
      </div>
      <div className="modal-footer">
        <Button size="small" inverse={true} className="action-button remove" onClick={() => {
          modalData.removeCallback();
          closeModal()
        }}>
          Remove this alarm
          <FaTrash />
        </Button>
        <div className='row'>
          <Button size="small" className="action-button yes" onClick={() => {
            update()
          }}>
            Save
          </Button>
          {isDone
            ? <Button danger={true} size="small" className="action-button yes" onClick={() => {
              update(false)
            }}>
              Undo ✗
            </Button>
            : <Button primary={true} size="small" className="action-button yes" onClick={() => {
              update(true)
            }}>
              Done ✓
            </Button>}
        </div>
      </div>
    </div>
  )
}

export default EditAlarm
