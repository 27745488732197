import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PricingCards from '../../user/components/PricingCards'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { toast } from 'react-toastify'
import { useModal } from '../../shared/hooks/modal-hook'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'

const UpdatePlan = props => {
  const { openModal } = useModal()
  const auth = useSelector(state => state.auth)
  const state = useSelector(state => state)
  const location = useLocation()
  const { sendRequest } = useHttpClient()

  const activePkg = state.auth.user.subscription.plan

  const { subscriptionDetails, user, isFromShopify } = location.state

  const openAreYouSureModal = plan => {
    let title = (
      <React.Fragment>
        You will update your plan to{' '}
        <span style={{ color: 'green' }}> {plan.name}</span>, are you sure?
      </React.Fragment>
    )
    let text = (
      <React.Fragment>
        <span style={{ fontSize: '17px' }}>
          You will be charged{' '}
          <span style={{ color: 'green' }}>${plan.discountedPrice ? plan.discountedPrice : plan.price}</span> for this plan.<br />

        </span>
      </React.Fragment>
    )

    const modalConfig = {
      title: title,
      text: text,
      actions: [
        {
          text: 'Cancel',
          callback() { },
        },
        {
          text: 'Confirm',
          callback() {
            updatePlan(plan)
          },
        },
      ],
    }
    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
    })
  }

  const updatePlan = plan => {

    if (user.shopify) {
      let params = JSON.stringify({
        price: plan.discountedPrice ? plan.discountedPrice : plan.price,
        planName: plan.name,
        interval: plan.paymentInterval,
        accessToken: user.shopify.accessToken,
        shopDomain: user.shopify.domain,
        chargeID: user.subscription.chargeID,
        additionalFee: plan.additionalFee,
      })
      sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/shopify/updateSubscriptionPlan`,
        'POST',
        params,
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
        .then(result => {
          window.open(result.confirmationUrl, '_self')
        })
        .catch(err => {
          errorHandler({message: 'An error occured. Please, contact us.'})
        })
    }
  }

  return (
    <div>
      <PricingCards
        subscriptionDetails={subscriptionDetails}
        user={user}
        isFromShopify={isFromShopify}
        activePkg={activePkg}
        isUpdate={true}
        openAreYouSureModal={openAreYouSureModal}
      ></PricingCards>
    </div>
  )
}

export default UpdatePlan
