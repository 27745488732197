import { useSelector, useDispatch } from 'react-redux'
import { useHttpClient } from '../../hooks/http-hook'
import { facebookActions } from '../../../store/facebook'
import { useSocket } from '../socket-hook'

export const useFacebookCampaign = () => {

    const dispatch = useDispatch()
    const { sendRequest } = useHttpClient()
    const { subscribeToProgress } = useSocket()

    const createConversionRemarketingCampaign = async (data, authHeader) => {
        console.log('Starting createConversionRemarketingCampaign with data:', data);
        try {
            // Set initial status
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'conversionRemarketingCampaign',
                data: { status: 'creating', progress: 0, message: 'Initializing campaign creation...' }
            }));

            // Subscribe to progress updates
            let unsubscribe;
            if (data.userId) {
                unsubscribe = await subscribeToProgress(data.userId, (progressData) => {
                    // Map the stage to a percentage and status
                    let progress = 0;
                    let status = 'creating';
                    let message = '';

                    dispatch(facebookActions.setCampaignCreationStatus({
                        type: 'conversionRemarketingCampaign',
                        data: {
                            status,
                            progress,
                            message,
                            ...progressData
                        }
                    }));
                }, 'conversion_remarketing');
            }

            const conversionRemarketingCampaignRequest = `${process.env.REACT_APP_BACKEND_URL}/facebook/createConversionRemarketingCampaign`
            const result = await sendRequest(
                conversionRemarketingCampaignRequest,
                'POST',
                JSON.stringify(data),
                authHeader
            );

            // Cleanup subscription
            if (unsubscribe) {
                unsubscribe();
            }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'conversionRemarketingCampaign',
                data: result
            }));

            return result;
        } catch (error) {
            console.error('Conversion Remarketing Campaign Error:', error);
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'conversionRemarketingCampaign',
                data: { status: 'error', error: error.message }
            }));
            return error
        }
    }

    const createConversionNewUserCampaign = async (data, authHeader) => {
        try {
            // Set initial status
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'conversionNewUserCampaign',
                data: { status: 'creating', progress: 0, message: 'Initializing campaign creation...' }
            }));

            // Subscribe to progress updates
            let unsubscribe;
            if (data.userId) {
                unsubscribe = await subscribeToProgress(data.userId, (progressData) => {
                    let progress = 0;
                    let status = 'creating';
                    let message = '';

                    dispatch(facebookActions.setCampaignCreationStatus({
                        type: 'conversionNewUserCampaign',
                        data: {
                            status,
                            progress,
                            message,
                            ...progressData
                        }
                    }));
                }, 'conversion_new_user');
            }

            const conversionNewUserCampaignRequest = `${process.env.REACT_APP_BACKEND_URL}/facebook/createConversionNewUserCampaign`
            const result = await sendRequest(
                conversionNewUserCampaignRequest,
                'POST',
                JSON.stringify(data),
                authHeader
            );

            // Cleanup subscription
            if (unsubscribe) {
                unsubscribe();
            }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'conversionNewUserCampaign',
                data: result
            }));
            return result;
        } catch (error) {
            console.error('Conversion New User Campaign Error:', error);
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'conversionNewUserCampaign',
                data: { status: 'error', error: error.message }
            }));
            return error;
        }
    }

    const createAdvantagePlusCampaign = async (data, authHeader) => {
        try {
            // Set initial status
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'advantagePlusCampaign',
                data: { status: 'creating', progress: 0, message: 'Initializing campaign creation...' }
            }));

            // Subscribe to progress updates
            let unsubscribe;
            if (data.userId) {
                unsubscribe = await subscribeToProgress(data.userId, (progressData) => {
                    let progress = 0;
                    let status = 'creating';
                    let message = '';

                    dispatch(facebookActions.setCampaignCreationStatus({
                        type: 'advantagePlusCampaign',
                        data: {
                            status,
                            progress,
                            message,
                            ...progressData
                        }
                    }));
                }, 'advantage_plus');
            }

            const advantagePlusCampaignRequest = `${process.env.REACT_APP_BACKEND_URL}/facebook/createAdvantagePlusCampaign`
            const result = await sendRequest(
                advantagePlusCampaignRequest,
                'POST',
                JSON.stringify(data),
                authHeader
            );

            // Cleanup subscription
            if (unsubscribe) {
                unsubscribe();
            }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'advantagePlusCampaign',
                data: result
            }));
            return result;
        } catch (error) {
            console.error('Advantage Plus Campaign Error:', error);
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'advantagePlusCampaign',
                data: { status: 'error', error: error.message }
            }));
            return error;
        }
    }

    const createAwarenessCampaign = async (data, authHeader) => {
        try {
            // Set initial status
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'awarenessCampaign',
                data: { status: 'creating', progress: 0, message: 'Initializing campaign creation...' }
            }));

            // Subscribe to progress updates
            let unsubscribe;
            if (data.userId) {
                unsubscribe = await subscribeToProgress(data.userId, (progressData) => {
                    let progress = 0;
                    let status = 'creating';
                    let message = '';

                    dispatch(facebookActions.setCampaignCreationStatus({
                        type: 'awarenessCampaign',
                        data: {
                            status,
                            progress,
                            message,
                            ...progressData
                        }
                    }));
                }, 'awareness');
            }

            const awarenessCampaignRequest = `${process.env.REACT_APP_BACKEND_URL}/facebook/createAwarenessCampaign`
            const result = await sendRequest(
                awarenessCampaignRequest,
                'POST',
                JSON.stringify(data),
                authHeader
            );

            // Cleanup subscription
            if (unsubscribe) {
                unsubscribe();
            }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'awarenessCampaign',
                data: result
            }));
            return result;
        } catch (error) {
            console.error('Awareness Campaign Error:', error);
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'awarenessCampaign',
                data: { status: 'error', error: error.message }
            }));
            return error;
        }
    }

    const createTrafficCampaign = async (data, authHeader) => {
        try {
            // Set initial status
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'trafficCampaign',
                data: { status: 'creating', progress: 0, message: 'Initializing campaign creation...' }
            }));

            // Subscribe to progress updates
            let unsubscribe;
            if (data.userId) {
                unsubscribe = await subscribeToProgress(data.userId, (progressData) => {
                    let progress = 0;
                    let status = 'creating';
                    let message = '';

                    dispatch(facebookActions.setCampaignCreationStatus({
                        type: 'trafficCampaign',
                        data: {
                            status,
                            progress,
                            message,
                            ...progressData
                        }
                    }));
                }, 'traffic_to_website');
            }

            const trafficCampaignRequest = `${process.env.REACT_APP_BACKEND_URL}/facebook/createTrafficCampaign`
            const result = await sendRequest(
                trafficCampaignRequest,
                'POST',
                JSON.stringify(data),
                authHeader
            );

            // Cleanup subscription
            if (unsubscribe) {
                unsubscribe();
            }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'trafficCampaign',
                data: result
            }));
            return result;
        } catch (error) {
            console.error('Traffic Campaign Error:', error);
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'trafficCampaign',
                data: { status: 'error', error: error.message }
            }));
            return error;
        }
    }

    const createTrafficToInstagramCampaign = async (data, authHeader) => {
        try {
            // Set initial status
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'trafficToInstagramCampaign',
                data: { status: 'creating', progress: 0, message: 'Initializing campaign creation...' }
            }));

            // Subscribe to progress updates
            let unsubscribe;
            if (data.userId) {
                unsubscribe = await subscribeToProgress(data.userId, (progressData) => {
                    let progress = 0;
                    let status = 'creating';
                    let message = '';

                    dispatch(facebookActions.setCampaignCreationStatus({
                        type: 'trafficToInstagramCampaign',
                        data: {
                            status,
                            progress,
                            message,
                            ...progressData
                        }
                    }));
                }, 'traffic_to_social_media');
            }

            const trafficToInstagramCampaignRequest = `${process.env.REACT_APP_BACKEND_URL}/facebook/createTrafficToInstagramCampaign`
            const result = await sendRequest(
                trafficToInstagramCampaignRequest,
                'POST',
                JSON.stringify(data),
                authHeader
            );

            // Cleanup subscription
            if (unsubscribe) {
                unsubscribe();
            }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'trafficToInstagramCampaign',
                data: result
            }));
            return result;
        } catch (error) {
            console.error('Traffic to Instagram Campaign Error:', error);
            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'trafficToInstagramCampaign',
                data: { status: 'error', error: error.message }
            }));
            return error;
        }
    }


    return {
        createConversionRemarketingCampaign,
        createConversionNewUserCampaign,
        createAdvantagePlusCampaign,
        createAwarenessCampaign,
        createTrafficCampaign,
        createTrafficToInstagramCampaign,

    }


}