import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import helper from '../../util/helper'
import CreativeTypes from '../../enums/CreativeTypes'
import Modals from '../../enums/Modals'
import { useModal } from '../modal-hook'
import { useFacebook } from '../facebook-hook'
import { useFacebookCreative } from './creative-hook'
import { facebookCampaignEditorActions } from '../../../store/facebookCampaignEditor'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import CampaignFormDisplayTypes from '../../enums/CampaignFormDisplayTypes'
import useNavigator from '../navigator-hook'

export const useFacebookCampaignEditor = () => {
    const dispatch = useDispatch()
    const { openModal } = useModal()
    const { removeParamFromUrl } = useNavigator()
    const currentCreative = useSelector(state => state.facebookCampaignEditor.currentCampaign?.currentCreative)
    const adCreativesList = useSelector(state => state.facebookCampaignEditor.currentCampaign?.adCreatives)
    const campaignLabel = useSelector(state => state.facebookCampaignEditor.currentCampaign?.label)
    const conversionCampaignDailyBudget = useSelector(state => state.facebookCampaignEditor.currentCampaign?.targeting.dailyBudget)
    const hasHighPerformingCreatives = useSelector(state => state.facebookCampaignEditor.hasHighPerformingCreatives)

    const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
    const catalogs = useSelector(state => state.facebook.catalogs)
    const productSets = useSelector(state => state.facebook.productSets)
    const facebookPages = useSelector(state => state.facebook.pages)
    const instagramAccounts = useSelector(state => state.facebook.instagramAccounts)
    const facebookUserId = useSelector(state => state.facebook.auth?.id)
    const fullFunnels = useSelector(state => state.facebook.fullFunnels)

    const selectedActiveFacebookPage = useSelector(state => state.facebook.selectedActiveFacebookPage)
    const selectedActiveInstagramAccount = useSelector(state => state.facebook.selectedActiveInstagramAccount)

    const { getCatalogs,
        getProductSetsOfCatalog,
        getFacebookPages,
        getInstagramAccountsForPage,
        getPostsOfAnAccount } = useFacebook()

    const { createAdCreativeWithCatalog,
        createAdCreativeWithCarousel } = useFacebookCreative()

    const changeLabel = ({ newLabel, campaignId }) => {
        const isDuplicate = checkDuplicateLabel({ label: newLabel, campaignId })
        dispatch(facebookCampaignEditorActions.changeLabel({
            label: newLabel,
            isValid: newLabel !== "" && !isDuplicate,
            isDuplicate
        }))
    }

    const checkDuplicateLabel = ({ label, campaignId }) => {
        let exists = false
        fullFunnels.forEach(c => {
            if (c.label?.trim() === label.trim() && c._id !== campaignId) {
                exists = true
            }
        })

        return exists
    }

    const changeActiveDisplay = ({ display }) => {
        dispatch(facebookCampaignEditorActions.changeActiveDisplay(display))
    }


    const changeTargeting = ({ countries, dailyBudget, isDailyBudgetMoreThanMinimum, genders, age }) => {
        dispatch(facebookCampaignEditorActions.changeTargeting({ countries, dailyBudget, isDailyBudgetMoreThanMinimum, genders, age }))
    }

    const changeCreativeType = async ({ newType, prefillDetails }) => {
        if (newType === CreativeTypes.CATALOG && selectedBusiness?.id) { // we need business for catalogs and stuff
            removeParamFromUrl('aiCreativeId')
            dispatch(facebookCampaignEditorActions.updateCurrentCreative({
                type: newType,
                id: null,
                details: {
                    primaryText: "",
                    websiteUrl: "",
                },
                body: null,
                isValid: false
            }))

            fetchFacebookPagesAndCatalogs({ existingFacebookPage: selectedActiveFacebookPage, existingInstagramAccount: selectedActiveInstagramAccount })

        } else if (newType === CreativeTypes.HIGH_PERFORMING) {
            removeParamFromUrl('aiCreativeId')
            let newCreative = {
                type: newType,
                id: null,
                details: {
                    primaryText: "",
                    websiteUrl: "",
                    catalog: null,
                    productSet: null,
                    facebookPage: null,
                    instagramAccount: null,
                },
                body: null,
                isValid: false
            }
            dispatch(facebookCampaignEditorActions.updateCurrentCreative(newCreative))
        } else if (newType === CreativeTypes.AI_CREATIVE && selectedBusiness?.id) {

            dispatch(facebookCampaignEditorActions.updateCurrentCreative({
                type: newType,
                id: null,
                details: {
                    ...prefillDetails,
                    primaryText: "",
                    websiteUrl: "",
                },
                body: null,
                isValid: false,
            }))

            fetchFacebookPagesAndSetFirstOne({ existingFacebookPage: selectedActiveFacebookPage, existingInstagramAccount: selectedActiveInstagramAccount })
        } else if (newType === CreativeTypes.POST) {
            removeParamFromUrl('aiCreativeId')
            dispatch(facebookCampaignEditorActions.updateCurrentCreative({
                type: newType,
                id: null,
                details: {
                    primaryText: "",
                    websiteUrl: "",
                    facebookPage: null,
                    instagramAccount: null,
                    post: null,
                },
                body: null,
                isValid: false,
            }))

            // fetchFacebookPagesAndSetFirstOne({ existingFacebookPage: selectedActiveFacebookPage, existingInstagramAccount: selectedActiveInstagramAccount, getPosts: true })
        }
    }

    const fetchFacebookPagesAndSetFirstOne = async ({ existingFacebookPage, existingInstagramAccount, getPosts = false }) => {
        let newFacebookPage;
        if (facebookPages.length) {
            newFacebookPage = existingFacebookPage ?? facebookPages[0]
        } else {
            startLoadingState("isFetchingFacebookPages")
            let newFacebookPages = await getFacebookPages({ facebookUserId })
            newFacebookPage = existingFacebookPage ?? newFacebookPages[0]
            endLoadingState("isFetchingFacebookPages")
        }

        if (currentCreative?.details?.facebookPage?.access_token) newFacebookPageSelected({ facebookPage: currentCreative?.details.facebookPage, existingInstagramAccount, initializing: true, getPosts })
        else newFacebookPageSelected({ facebookPage: newFacebookPage, existingInstagramAccount, initializing: true, getPosts })
    }

    const fetchFacebookPagesAndCatalogs = async ({ existingCatalog, existingProductSet, existingFacebookPage, existingInstagramAccount }) => {
        let newFacebookPage;

        let newCatalogs;
        if (catalogs?.length) {
            newCatalogs = catalogs
        } else {
            startLoadingState("isFetchingCatalogs")
            newCatalogs = await getCatalogs({ businessId: selectedBusiness.id })
            endLoadingState("isFetchingCatalogs")
        }

        let newCatalog = existingCatalog ?? newCatalogs[0];


        if (currentCreative?.details.catalog) newCatalogSelected({ catalog: currentCreative.details.catalog, existingProductSet })
        else newCatalogSelected({ catalog: newCatalog, existingProductSet })


        if (facebookPages.length) {
            newFacebookPage = existingFacebookPage ?? facebookPages[0]
        } else {
            startLoadingState("isFetchingFacebookPages")
            let newFacebookPages = await getFacebookPages({ facebookUserId })
            newFacebookPage = existingFacebookPage ?? newFacebookPages[0]
            endLoadingState("isFetchingFacebookPages")
        }

        if (currentCreative?.details?.facebookPage?.access_token) newFacebookPageSelected({ facebookPage: currentCreative.details.facebookPage, existingInstagramAccount, initializing: true })
        else newFacebookPageSelected({ facebookPage: newFacebookPage, existingInstagramAccount, initializing: true })
    }


    const newCatalogSelected = async ({ catalog, existingProductSet }) => {
        if (catalog) {
            dispatch(facebookCampaignEditorActions.setNewCatalog(catalog))

            let productSetsOfCatalog;
            if (productSets && productSets[catalog.id] && productSets[catalog.id].length) {
                productSetsOfCatalog = productSets[catalog.id]
            } else {
                productSetsOfCatalog = await getProductSetsOfCatalog({ catalogId: catalog.id })
            }

            if ((!existingProductSet && !currentCreative?.details?.productSet)
                || (currentCreative?.details?.productSet && !helper.findBy(productSetsOfCatalog, 'id', currentCreative.details.productSet.id))
            ) {
                const allProducts = helper.findBy(productSetsOfCatalog, 'name', 'All Products')
                if (allProducts) {
                    newProductSetSelected({ productSet: allProducts, forcedByCatalogSelection: true, catalog })
                } else {
                    if (productSetsOfCatalog.length) newProductSetSelected({ productSet: productSetsOfCatalog[0], forcedByCatalogSelection: true, catalog })
                    else newProductSetSelected({ productSet: null, forcedByCatalogSelection: true, catalog })
                }
            } else if (existingProductSet && helper.findBy(productSetsOfCatalog, 'id', existingProductSet.id)) {
                newProductSetSelected({ productSet: existingProductSet, forcedByCatalogSelection: true, catalog })
            }
        }
    }

    const newProductSetSelected = ({ productSet, forcedByCatalogSelection, catalog }) => {
        if (currentCreative?.details.productSet?.id !== productSet.id) {
            dispatch(facebookCampaignEditorActions.setNewProductSet(productSet))
            let updatedFields = {
                productSet: productSet,
            }
            if (forcedByCatalogSelection) {
                updatedFields.catalog = catalog
            }
            createNewCreativeWithCatalogFormData({ updatedFields })
        }
    }

    const newFacebookPageSelected = async ({ facebookPage, existingInstagramAccount, initializing = false, getPosts = false }) => {
        if (facebookPage) {
            dispatch(facebookCampaignEditorActions.setNewFacebookPage(facebookPage))

            if (getPosts) {
                await getPostsOfAnAccount({ pageId: facebookPage.id, accessToken: facebookPage.access_token })
            }

            let instagramAccountsOfPage;

            if (instagramAccounts && instagramAccounts[facebookPage.id]) {
                instagramAccountsOfPage = instagramAccounts[facebookPage.id]
            } else {
                instagramAccountsOfPage = await getInstagramAccountsForPage({ pageId: facebookPage.id, pageAccessToken: facebookPage.access_token })
            }

            if ((!existingInstagramAccount && !currentCreative?.details.instagramAccount)
                || (currentCreative?.details.instagramAccount && !helper.findBy(instagramAccountsOfPage, 'id', currentCreative.details.instagramAccount.id))
                || initializing
            ) {
                if (instagramAccountsOfPage.length) newInstagramAccountSelected({ instagramAccount: instagramAccountsOfPage[0], forcedByFacebookPageSelection: true, facebookPage })
                else newInstagramAccountSelected({ instagramAccount: null, forcedByFacebookPageSelection: true, facebookPage })
            }
        }
    }

    const newInstagramAccountSelected = ({ instagramAccount, forcedByFacebookPageSelection, facebookPage }) => {
        if (currentCreative?.details.instagramAccount?.id !== instagramAccount?.id || (!currentCreative?.details.instagramAccount?.username)) {
            dispatch(facebookCampaignEditorActions.setNewInstagramAccount(instagramAccount))
            let updatedFields = {
                instagramAccount: instagramAccount
            }

            if (forcedByFacebookPageSelection) {
                updatedFields.facebookPage = facebookPage
            }

            if (currentCreative?.type === CreativeTypes.CATALOG) {
                createNewCreativeWithCatalogFormData({ updatedFields })
            }

            if (currentCreative?.type === CreativeTypes.AI_CREATIVE) {
                if (currentCreative?.details?.catalog?.id) {
                    createNewCreativeWithCatalogFormData({ updatedFields })
                } else {
                    createNewCreativeWithCarouselFormData({ updatedFields })
                }
            }
        }
    }

    const changeCreativeInfoForHighPerformingCreatives = ({ body, details }) => {
        const type = CreativeTypes.HIGH_PERFORMING
        dispatch(facebookCampaignEditorActions.updateCurrentCreative({
            type,
            body,
            details,
            isValid: true,
            id: body.id,
            isEdited: !!currentCreative.existsInListWithId, // if exists in list beforehand, then it is edited
        }))
    }

    const setHasHighPerformingCreatives = (hasHighPerformingCreatives) => {
        dispatch(facebookCampaignEditorActions.setHasHighPerformingCreatives(hasHighPerformingCreatives))
    }

    const catalogCreativeFormTextFieldsUpdated = ({ primaryText, websiteUrl }) => {
        dispatch(facebookCampaignEditorActions.updateCurrentCreative({
            details: {
                ...currentCreative.details,
                websiteUrl,
                primaryText
            }
        }))

        createNewCreativeWithCatalogFormData({ updatedFields: { primaryText, websiteUrl } })
    }

    const aiCreativeFormTextFieldsUpdated = ({ primaryText, websiteUrl, aiCreativeProducts }) => {
        dispatch(facebookCampaignEditorActions.updateCurrentCreative({
            details: {
                ...currentCreative.details,
                websiteUrl,
                primaryText
            }
        }))

        createNewCreativeWithCarouselFormData({ updatedTextFields: { primaryText, websiteUrl }, aiCreativeProducts })
    }

    const aiCreativeProductsUpdated = ({ aiCreativeProducts }) => {
        createNewCreativeWithCarouselFormData({ aiCreativeProducts })
    }

    const aiCreativeEnhencedCatalogDetailsUpdated = async ({ enhencedCatalogDetails }) => {
        await newCatalogSelected({
            catalog: { id: enhencedCatalogDetails.catalogId },
            existingProductSet: { id: enhencedCatalogDetails.productSetId },
          })
        createNewCreativeWithCatalogFormData({ updatedFields: { } })
    }

    const createNewCreativeWithCarouselFormData = async ({ updatedTextFields, aiCreativeProducts }) => {
        if (!currentCreative?.details || !currentCreative?.details?.aiCreativeId || !aiCreativeProducts) {
            return
        }

        const newFormData = { ...currentCreative.details, ...updatedTextFields }

        const carouselData = aiCreativeProducts.map(product => ({
            description: product.price,
            image_hash: product.preview.hash,
            link: product.url,
            name: product.name,
        }))

        if (!newFormData.carouselData) {
            newFormData.carouselData = carouselData
        }

        const isValid = checkCarouselFormValidation({ creativeDetails: newFormData })

        if (!isValid) {
            return
        }

        startLoadingState('isCreatingCreative')
        try {
            const creativeResult = await createAdCreativeWithCarousel({
                ...newFormData,
                campaignName: campaignLabel ?? "Enhencer",
            });

            const updateBody = {
                id: creativeResult.id,
                body: creativeResult,

                isValid: true,
                isEdited: !!currentCreative.existsInListWithId, // If exists in list beforehand, then it is edited
            }

            if (updatedTextFields) {
                updateBody.details = {
                    ...currentCreative.details,
                    ...updatedTextFields
                }
            }

            dispatch(facebookCampaignEditorActions.updateCurrentCreative(updateBody))

        } catch (err) {
            toast.warn("Error occured with AI Creative. Please contact us")
        }
        endLoadingState('isCreatingCreative')
    }

    const checkCarouselFormValidation = ({ creativeDetails }) => {
        const requiredFields = [
            creativeDetails.facebookPage,
            creativeDetails.primaryText,
            creativeDetails.websiteUrl,
            creativeDetails.carouselData,
        ]
        return requiredFields.every(Boolean)
    }

    const createNewCreativeWithCatalogFormData = async ({ updatedFields }) => {
        if (currentCreative?.details) {
            let newFormData = { ...currentCreative.details }

            Object.keys(updatedFields).forEach(field => {
                newFormData[field] = updatedFields[field]
            })

            let isValid = checkCatalogFormValidation({ creativeDetails: newFormData })
            if (isValid) {
                startLoadingState('isCreatingCreative')
                try {
                    let creativeResult = await createAdCreativeWithCatalog({
                        ...newFormData,
                        campaignName: campaignLabel ?? "Enhencer",
                    });


                    dispatch(facebookCampaignEditorActions.updateCurrentCreative({
                        id: creativeResult.id,
                        body: creativeResult,
                        details: {
                            ...currentCreative.details,
                            ...updatedFields
                        },
                        isValid: true,
                        isEdited: !!currentCreative.existsInListWithId, // if exists in list beforehand, then it is edited
                    }))
                } catch (err) {
                    toast.warn("Error occured with Ad Creative: " + err.error_user_msg, { autoClose: 10000 })
                }
                endLoadingState('isCreatingCreative')
            }
        }

    }

    const checkCatalogFormValidation = ({ creativeDetails }) => {
        return !!creativeDetails
            && !!creativeDetails.productSet
            && !!creativeDetails.facebookPage
            && !!creativeDetails.primaryText
            && !!creativeDetails.websiteUrl
    }

    const startLoadingState = (field) => {
        dispatch(facebookCampaignEditorActions.startLoadingState(field))
    }

    const endLoadingState = (field) => {
        dispatch(facebookCampaignEditorActions.endLoadingState(field))
    }

    const addAICreativeToCurrentCreative = (aiCreativeId) => {
        removeParamFromUrl('aiCreativeId')
        dispatch(facebookCampaignEditorActions.updateCurrentCreative({
            type: CreativeTypes.AI_CREATIVE,
            details: {
                ...currentCreative.details,
                aiCreativeId,
            },
        }))

        fetchFacebookPagesAndSetFirstOne({ existingFacebookPage: selectedActiveFacebookPage, existingInstagramAccount: selectedActiveInstagramAccount, getPosts: true })
    }


    const startNewEmptyCampaign = () => {
        dispatch(facebookCampaignEditorActions.initializeEmptyCampaign())
    }

    const startExistingCampaignEditing = async ({ fullFunnel, existingAICreativeId }) => {
        dispatch(facebookCampaignEditorActions.initializeCampaignEditor({ fullFunnel }))

        if (existingAICreativeId) {
            dispatch(facebookCampaignEditorActions.updateCurrentCreative({
                type: CreativeTypes.AI_CREATIVE,
                aiCreativeId: existingAICreativeId,
                name: null,
                existsInListWithId: null,

                id: null,
                details: {
                    primaryText: "",
                    websiteUrl: "",
                },
                body: null,
                isValid: false,

            }))
            dispatch(facebookCampaignEditorActions.changeActiveDisplay(CampaignFormDisplayTypes.CREATIVE))

            fetchFacebookPagesAndSetFirstOne({ existingFacebookPage: selectedActiveFacebookPage, existingInstagramAccount: selectedActiveInstagramAccount, getPosts: true })

            return
        }

        let firstCreativeInList = Object.values(fullFunnel.adCreatives)[0]
        if (firstCreativeInList.type === CreativeTypes.CATALOG) {
            fetchFacebookPagesAndCatalogs({
                existingCatalog: firstCreativeInList.details.catalog,
                existingProductSet: firstCreativeInList.details.productSet,
                existingFacebookPage: firstCreativeInList.details.facebookPage,
                existingInstagramAccount: firstCreativeInList.details.instagramAccount
            })
        }

    }

    const endCampaignEditing = async ({ callback }) => {
        const confirmed = await openModal({
            type: Modals.ARE_YOU_SURE,
            cancelButtonText: 'Continue editing',
            isConfirmButtonDanger: true,
            confirmButtonText: 'Exit editing',
            question:
                'You have unsaved changes, do you want to continue?',
        })

        if (confirmed) {
            dispatch(facebookCampaignEditorActions.endCampaignEditing())
            callback()
        }
    }

    const endCampaignEditingWithoutModal = () => {
        dispatch(facebookCampaignEditorActions.endCampaignEditing())
    }

    const startNewCreativeMakerForm = async () => {

        removeParamFromUrl('aiCreativeId')

        if (
            !currentCreative.existsInListWithId
            && (currentCreative.type !== CreativeTypes.HIGH_PERFORMING || hasHighPerformingCreatives)
            && currentCreative.type
        ) {
            const confirmed = await openModal({
                type: Modals.ARE_YOU_SURE,
                question: "You have unsaved changes, do you want to continue?",
            })

            if (confirmed) {
                dispatch(facebookCampaignEditorActions.emptyCurrentCreative())
                dispatch(aiCreativeEditorActions.resetEnhencedCatalog())
            }
        } else {
            dispatch(facebookCampaignEditorActions.emptyCurrentCreative())
            dispatch(aiCreativeEditorActions.resetEnhencedCatalog())
        }
    }

    const selectCurrentAdCreative = async (c, forcedSelection = false) => {

        const select = () => {
            if (c.type === CreativeTypes.CATALOG && (!catalogs?.length || !facebookPages.length)) {
                fetchFacebookPagesAndCatalogs({
                    existingCatalog: c.details.catalog,
                    existingProductSet: c.details.productSet,
                    existingFacebookPage: c.details.facebookPage,
                    existingInstagramAccount: c.details.instagramAccount,
                })
            }
            dispatch(facebookCampaignEditorActions.selectCreativeFromAdCreativesList(c))
        }

        if ((!currentCreative.existsInListWithId && currentCreative.type) && !forcedSelection) {
            const confirmed = await openModal({
                type: Modals.ARE_YOU_SURE,
                question: "You have unsaved changes, do you want to continue?",
            })

            if (confirmed) {
                select()
            }
        } else {
            select()
        }
    }

    const getShortCreativeName = (type) => {
        switch (type) {
            case CreativeTypes.CATALOG: return "Catalog"
            case CreativeTypes.HIGH_PERFORMING: return "High Perf"
            case CreativeTypes.AI_CREATIVE: return "AI Creative"
            // case "Instagram Post": return "Insta Post"
        }
    }

    const addCurrentCreativeToList = () => {

        let countOfCurrentType = 1;
        Object.values(adCreativesList).forEach((existingCreative) => {
            if (existingCreative.type === currentCreative.type) {
                countOfCurrentType++;
            }
        })

        dispatch(facebookCampaignEditorActions.addCreativeToAdCreativesList({
            ...currentCreative,
            name: `${getShortCreativeName(currentCreative.type)} #${countOfCurrentType}`
        }))
    }

    const editCurrentCreativeInList = () => {
        let initialId = currentCreative.replacesCreativeWithId ?? currentCreative.existsInListWithId
        let newCreative = {
            ...currentCreative,
            isEdited: false,
            replacesCreativeWithId: initialId
        }

        let oldCreativeIdToRemove = currentCreative.existsInListWithId

        dispatch(facebookCampaignEditorActions.editCreativeInList({ newCreative, oldCreativeIdToRemove }))
    }

    const undoCreativeChanges = () => {
        let foundInList = adCreativesList[currentCreative.existsInListWithId]
        dispatch(facebookCampaignEditorActions.selectCreativeFromAdCreativesList(foundInList))
    }

    const removeCurrentCreativeFromList = async () => {

        const callback = () => {
            const remainingCreatives = Object.values(adCreativesList).filter(
                creative => creative.id !== currentCreative.id
            );

            let currentIndex = Object.values(adCreativesList).findIndex(
                creative => creative.id === currentCreative.id
            );

            dispatch(facebookCampaignEditorActions.removeCreativeFromAdCreativesList(currentCreative));

            if (remainingCreatives.length > 0) {
                let nextIndex = 0
                if (currentIndex !== -1) {
                    nextIndex = currentIndex < remainingCreatives.length ? currentIndex : remainingCreatives.length - 1;
                }
                selectCurrentAdCreative(remainingCreatives[nextIndex], true);
            }
        }

        if (currentCreative.type && (currentCreative.type !== CreativeTypes.HIGH_PERFORMING || hasHighPerformingCreatives)) {
            const confirmed = await openModal({
                type: Modals.ARE_YOU_SURE,
                question: 'The creative will be permanently deleted. Are you sure you want to proceed?',
            });

            if (confirmed) {
                callback()
            }
        } else {
            callback()
        }

    }

    const checkIfCurrentCreativeIsInList = () => {
        let exists = false
        Object.keys(adCreativesList).forEach((existingCreativeId) => {
            if (existingCreativeId === currentCreative.id) {
                exists = true
            }
        })
        return exists
    }

    const editTrafficCampaign = ({
        isActive, amount, percentage
    }) => {
        let newBody = {
            isActive
        }

        if (isActive) {
            newBody.dailyBudgetAmount = amount ?? (percentage * conversionCampaignDailyBudget / 100)
            newBody.dailyBudgetPercentage = percentage
        }

        dispatch(facebookCampaignEditorActions.editTrafficCampaign(newBody))
    }

    return {
        changeActiveDisplay,

        changeLabel,
        changeTargeting,

        startNewCreativeMakerForm,
        selectCurrentAdCreative,
        addCurrentCreativeToList,
        editCurrentCreativeInList,
        removeCurrentCreativeFromList,
        checkIfCurrentCreativeIsInList,
        undoCreativeChanges,
        changeCreativeType,
        changeCreativeInfoForHighPerformingCreatives,
        setHasHighPerformingCreatives,

        newCatalogSelected,
        newProductSetSelected,
        newFacebookPageSelected,
        newInstagramAccountSelected,
        catalogCreativeFormTextFieldsUpdated,
        aiCreativeFormTextFieldsUpdated,

        startLoadingState,
        endLoadingState,

        aiCreativeProductsUpdated,
        aiCreativeEnhencedCatalogDetailsUpdated,
        addAICreativeToCurrentCreative,

        startNewEmptyCampaign,
        startExistingCampaignEditing,
        endCampaignEditing,
        endCampaignEditingWithoutModal,

        editTrafficCampaign
    }
}
