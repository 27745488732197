import React, { useState } from 'react'
import './PricingCompare.css'
import check from '../../shared/assets/images/icons/check-green-gradient.svg'
import cross from '../../shared/assets/images/icons/cross-grey.svg'
import { FaChevronDown } from 'react-icons/fa'
import { useSelector } from 'react-redux'

const PricingCompare = () => {
  const auth = useSelector(state => state.auth)
  const isFromShopify = auth.user?.shopify
  
  const packages = isFromShopify 
    ? ['AD HEALTH CHECKUP', 'Starter', 'Growth', 'Pro', 'Enterprise']
    : ['AD HEALTH CHECKUP', 'Growth', 'Pro', 'Enterprise']

  const [showMore, setShowMore] = useState(false)

  const handleShowMore = () => {
    setShowMore(!showMore)

    if (!showMore) {
      setTimeout(() => {
        const tableElement = document.querySelector('.pricing-table-cont')
        if (tableElement) {
          tableElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      }, 80)
    }
  }

  const features = [
    { name: 'Historical Ad Performance Analysis', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'Industrial Trend Comparison', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'Campaigns Efficiency Analysis', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'Up to Monthly Ad Budget Limit', values: isFromShopify ? ['-', '$5,000', '$10,000', '$25,000', '$100,000'] : ['-', '$10,000', '$25,000', '$100,000'] },
    { name: 'Read-to-Onboard Checklist', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'Ad-health Checkup Score', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'Setting KPIs and Growth Strategy Meeting', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'Full Funnel Ads Launcher on Meta (Facebook & Instagram)', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'Full Funnel Ads Launcher on Google', values: isFromShopify ? ['F', 'F', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'Full Funnel Ads Launcher on Tiktok', values: isFromShopify ? ['F', 'F', 'F', 'T', 'T'] : ['F', 'F', 'T', 'T'] },
    { name: 'Indepth Reporting Dashboard', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'AI Broad Audiences', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'AI Lookalike Audiences', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'AI Remarketing Audiences', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'AI Budget Optimization', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'E-mail and Call Support', values: isFromShopify ? ['F', 'T', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'Strategy Meetings Per 14 Days', values: isFromShopify ? ['F', 'F', 'T', 'T', 'F'] : ['F', 'T', 'T', 'F'] },
    { name: 'Weekly Strategy Meetings', values: isFromShopify ? ['F', 'F', 'T', 'T', 'T'] : ['F', 'T', 'T', 'T'] },
    { name: 'Dedicated Account Manager', values: isFromShopify ? ['F', 'F', 'F', 'T', 'T'] : ['F', 'F', 'T', 'T'] },
    { name: 'Attribution Model Comparison', values: isFromShopify ? ['F', 'F', 'F', 'T', 'T'] : ['F', 'F', 'T', 'T'] },
    { name: 'CCPA Compliance', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'SOC2 Compliance', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
    { name: 'GDPR Compliance', values: isFromShopify ? ['T', 'T', 'T', 'T', 'T'] : ['T', 'T', 'T', 'T'] },
  ];


  return (
    <div className="pricing-compare-main">
      <div
        className={`pricing-compare-cont ${showMore ? 'show-more' : 'collapsed'}`}
        onClick={() => handleShowMore()}
      >
        <span className="header-title">See the Full Feature Comparison</span>
        <span className="header-subtitle">
          Choose the Right Package to Match Your Business Needs.
        </span>

        {
          <div
            className={`pricing-table-cont ${showMore ? 'show-more' : 'collapsed'}`}
          >
            <table className="pricing-table">
              <thead>
                <tr>
                  <th>FEATURES</th>
                  {packages.map((pkg, index) => (
                    <th key={index}>{pkg}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {features.map((feature, index) => (
                  <tr key={index}>
                    <td>{feature.name}</td>
                    {feature.values.map((value, idx) => (
                      <td key={idx}>
                        {value === 'T' && (
                          <img
                            src={check}
                            alt="check icon"
                            width={24}
                            height={24}
                            className="check-icon"
                          />
                        )}
                        {value === 'F' && (
                          <img
                            src={cross}
                            alt="cross icon"
                            width={15}
                            height={15}
                            className="cross-icon"
                          />
                        )}
                        {value !== 'F' && value !== 'T' && (
                          <span className="small-text"> {value} </span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }
        <FaChevronDown className="chevron-icon" />
      </div>
    </div>
  )
}

export default PricingCompare
