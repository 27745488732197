import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import Konva from 'konva'
import { useSelector } from 'react-redux'

import { WORKBENCH_SIZE } from '../../support/constants'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import {
	loadAndChangeSvgColor,
	formatPrice,
	centerTheElement,
	resizeImageToFit,
	getObjectSnappingEdges,
	getLineGuideStops,
	drawGuides,
	getGuides,
	calculateBoxFitCover,
	processTextPlaceholders,
} from '../../support/utils'
import DiscountTypes from '../../../shared/enums/DiscountTypes'

const NewEditor = ({ templateConfig, onStageReady }) => {
	const stageRef = useRef(null)
	const mainLayerRef = useRef(null)
	const transformerLayerRef = useRef(null)

	const [loadedImages, setLoadedImages] = useState({})
	const [selectedElement, setSelectedElement] = useState(null)

	const currency = useSelector(state => state.facebook?.selectedAdAccount?.currency ?? "USD")

	const {
		setIsStageLoading,
		draftConfiguration,
		productOnDraft,
		companyDetails,
		discount,
		history,
		historyStep,
		initHistory,
		addToHistory,
		disableTransformers,
		isSingleSaving,
		setIsSingleSaving,
		savePreviewToProduct,
	} = useEnhencedCatalog()

	const currentPalette = useMemo(() => {
		return (
			draftConfiguration?.palette ?? {
				color1: '#FF6F61',
				color2: '#FFFFFF',
				color3: '#2C2C2C',
			}
		)
	}, [draftConfiguration.palette])

	useEffect(() => {
		window.addEventListener('resize', resizeStage)
		return () => {
			window.removeEventListener('resize', resizeStage)
		}
	}, [])

	useEffect(() => {
		setIsStageLoading(true)
		loadImages()
	}, [productOnDraft?.id, templateConfig.containerId])

	useEffect(() => {
		if (!areImagesLoaded()) {
			return
		}

		createStage()

		return () => {
			if (stageRef.current) {
				stageRef.current.destroy()
			}
		}
	}, [loadedImages, templateConfig.containerId, onStageReady?.current])

	useEffect(() => {
		if (!isSingleSaving || !productOnDraft?.id) return

		(async () => {
			await savePreviewToProduct(stageRef.current, productOnDraft.id)
			setIsSingleSaving(false)
		})()
	}, [isSingleSaving, setIsSingleSaving, savePreviewToProduct, stageRef, productOnDraft?.id])

	useEffect(() => {
		if (!areImagesLoaded()) {
			return
		}

		updateElements()
	}, [currentPalette, loadedImages, discount, draftConfiguration])

	useEffect(() => {
		if (!areImagesLoaded()) {
			return
		}

		updateCompanyLogo()
	}, [draftConfiguration?.showCompanyLogo, loadedImages, companyDetails.usesBgRemovedLogo])

	// Create stable callbacks for z-index operations
	const getSelectedElementNode = useCallback(() => {
		if (selectedElement === null || !mainLayerRef.current) return null;
		return mainLayerRef.current.findOne(`.element_${selectedElement}`);
	}, [selectedElement]);

	const getElementZPosition = useCallback((elementIndex) => {
		if (elementIndex === null || !mainLayerRef.current) return null;
		const element = mainLayerRef.current.findOne(`.element_${elementIndex}`);
		if (!element) return null;

		const allElements = mainLayerRef.current.children.filter(child =>
			child.name() !== 'background' && child.name().includes('element_')
		);

		return {
			index: allElements.indexOf(element),
			total: allElements.length,
			isTop: element.getZIndex() === allElements.length - 1,
			isBottom: element.getZIndex() === 0
		};
	}, []);

	// Define z-index manipulation functions with useCallback
	const bringElementToFront = useCallback((elementIndex) => {
		if (elementIndex === null || !mainLayerRef.current) return;
		const element = mainLayerRef.current.findOne(`.element_${elementIndex}`);
		if (!element) return;

		element.moveToTop();
		mainLayerRef.current.batchDraw();

		// Save to history
		addToHistory(`element_${elementIndex}`, { zIndex: 'top' });
	}, [addToHistory]);

	const bringElementForward = useCallback((elementIndex) => {
		if (elementIndex === null || !mainLayerRef.current) return;
		const element = mainLayerRef.current.findOne(`.element_${elementIndex}`);
		if (!element) return;

		const currentIndex = element.getZIndex();
		const siblings = element.getParent().children;

		if (currentIndex < siblings.length - 1) {
			element.zIndex(currentIndex + 1);
			mainLayerRef.current.batchDraw();

			// Save to history
			addToHistory(`element_${elementIndex}`, { zIndex: currentIndex + 1 });
		}
	}, [addToHistory]);

	const sendElementBackward = useCallback((elementIndex) => {
		if (elementIndex === null || !mainLayerRef.current) return;
		const element = mainLayerRef.current.findOne(`.element_${elementIndex}`);
		if (!element) return;

		const currentIndex = element.getZIndex();

		if (currentIndex > 0) {
			element.zIndex(currentIndex - 1);
			mainLayerRef.current.batchDraw();

			// Save to history
			addToHistory(`element_${elementIndex}`, { zIndex: currentIndex - 1 });
		}
	}, [addToHistory]);

	const sendElementToBack = useCallback((elementIndex) => {
		if (elementIndex === null || !mainLayerRef.current) return;
		const element = mainLayerRef.current.findOne(`.element_${elementIndex}`);
		if (!element) return;

		element.moveToBottom();
		mainLayerRef.current.batchDraw();

		// Save to history
		addToHistory(`element_${elementIndex}`, { zIndex: 'bottom' });
	}, [addToHistory]);

	// Expose selectedElement and z-index functions to parent components
	useEffect(() => {
		if (typeof onStageReady === 'function') {
			onStageReady(stageRef.current, {
				selectedElement,
				selectedElementType: selectedElement !== null ? templateConfig.elements[selectedElement].type : null,
				selectedElementId: selectedElement !== null ? templateConfig.elements[selectedElement].id : null,
				bringElementForward,
				bringElementToFront,
				sendElementBackward,
				sendElementToBack,
				// Use the stable callback versions
				getSelectedElementNode,
				getElementZPosition
			})
		}
	}, [
		selectedElement,
		onStageReady,
		bringElementForward,
		bringElementToFront,
		sendElementBackward,
		sendElementToBack,
		getSelectedElementNode,
		getElementZPosition
	])


	const resizeStage = () => {
		if (stageRef.current) {
			// the constant numbers in the code below has a delicate balance for resizing the stage
			const container = document.getElementById('konvaContainerBox')
			const editorFlexibleSpace = document.getElementById('editorFlexibleSpace')

			const editorFlexibleSpaceHeight = editorFlexibleSpace.offsetHeight
			const containerWidth = container.offsetWidth
			const containerHeight = container.offsetHeight

			let scale = 0.6
			if (containerWidth < 750 || containerHeight < 750 || editorFlexibleSpaceHeight < 1000) {
				let horizontalScale = containerWidth / WORKBENCH_SIZE.width
				let verticalScale = (containerHeight - 20) / WORKBENCH_SIZE.height
				scale = Math.min(horizontalScale, verticalScale)
			}

			if (editorFlexibleSpaceHeight < 1000) {
				let horizontalScale = containerWidth / WORKBENCH_SIZE.width
				let verticalScale = (editorFlexibleSpaceHeight - 200) / WORKBENCH_SIZE.height
				scale = Math.min(horizontalScale, verticalScale)
			}

			const calculatedWidth = WORKBENCH_SIZE.width * scale
			const calculatedHeight = WORKBENCH_SIZE.height * scale

			stageRef.current.width(calculatedWidth)
			stageRef.current.height(calculatedHeight)
			stageRef.current.scale({ x: scale, y: scale })
			stageRef.current.draw()

			if (document.getElementById('previewToolbar')) document.getElementById('previewToolbar').style.width = calculatedWidth + 'px';
			if (document.getElementById('action-buttons-container')) document.getElementById('action-buttons-container').style.width = calculatedWidth + 'px';

			return {
				width: calculatedWidth,
				height: calculatedHeight,
			}
		}
	}
	const loadImages = async () => {
		const images = {}
		const loadImagePromises = []

		// Helper function to load and process SVG images
		const loadSvgImage = (svgUrl, color, customReplace) => {
			return new Promise((resolve) => {
				loadAndChangeSvgColor(svgUrl, color, (coloredSvgUrl) => {
					const imgObj = new Image();
					imgObj.src = coloredSvgUrl;
					imgObj.onload = () => {
						resolve(imgObj);
					};
				}, customReplace);
			});
		};

		// Process all elements that need image loading
		if (templateConfig.elements) {
			templateConfig.elements.forEach((element, index) => {
				if (element.type === 'image' && element.src) {
					loadImagePromises.push(
						new Promise((resolve) => {
							const imageObj = new Image()
							let imageSource = element.src

							// For SVG images with color replacement
							if (element.isSvg && element.svgColor) {
								loadSvgImage(
									element.src,
									currentPalette[element.svgColor] || element.svgColor,
									element.svgCustomReplace
								).then((image) => {
									images[`element_${index}`] = image
									resolve()
								})
							} else {
								// For regular images
								let url = new URL(imageSource, window.location.origin)
								imageSource = url.searchParams.size > 0
									? imageSource + `&konva=${Date.now()}`
									: imageSource + `?konva=${Date.now()}`

								imageObj.src = imageSource
								imageObj.onload = () => {
									images[`element_${index}`] = imageObj
									resolve()
								}
								imageObj.onerror = (error) => {
									console.error(`Failed to load image for element ${index}`, { error, src: imageSource });
									resolve(); // Resolve anyway to not block other images
								}
							}
						})
					)
				}

				// Handle dynamic image sources (product image, company logo)
				if (element.type === 'image' && element.dynamicSrc === 'productImage') {
					const imageUrl = element.withBackground
						? `${process.env.REACT_APP_BACKEND_URL}/proxy/getProxyImage?url=${encodeURIComponent(productOnDraft?.originalImageUrl)}`
						: productOnDraft?.transparentImageUrl

					if (imageUrl) {
						loadImagePromises.push(
							new Promise((resolve) => {
								const imageObj = new Image()
								let imageSource = imageUrl

								if (
									imageUrl.includes('s3.amazonaws.com') ||
									imageUrl.includes('eu-central-1.amazonaws.com')
								) {
									const url = new URL(imageUrl, window.location.origin)
									imageSource = url.searchParams.size > 0
										? imageUrl + `&konva=${Date.now()}`
										: imageUrl + `?konva=${Date.now()}`
								}

								imageObj.src = imageSource
								imageObj.crossOrigin = 'Anonymous'
								imageObj.onload = () => {
									images[`element_${index}`] = imageObj
									resolve()
								}
								imageObj.onerror = (error) => {
									console.error(`Failed to load product image for element ${index}`, { error, src: imageSource });
									resolve();
								}
							})
						)
					}
				}

				if (element.type === 'image' && element.dynamicSrc === 'companyLogo') {
					if (companyDetails.companyLogoUrl) {
						loadImagePromises.push(
							new Promise((resolve) => {
								const imageObj = new Image()
								let imageSource = companyDetails.companyLogoUrl

								if (
									companyDetails.companyLogoUrl.includes('s3.amazonaws.com') ||
									companyDetails.companyLogoUrl.includes('eu-central-1.amazonaws.com')
								) {
									const url = new URL(companyDetails.companyLogoUrl, window.location.origin)
									imageSource = url.searchParams.size > 0
										? companyDetails.companyLogoUrl + `&konva=${Date.now()}`
										: companyDetails.companyLogoUrl + `?konva=${Date.now()}`
								}

								imageObj.src = imageSource
								imageObj.crossOrigin = 'Anonymous'
								imageObj.onload = () => {
									images[`element_${index}`] = imageObj
									resolve()
								}
								imageObj.onerror = (error) => {
									resolve();
								}
							})
						)
					}
				}
			})
		}

		// Wait for all images to load
		await Promise.all(loadImagePromises)
		setLoadedImages(images)
		setIsStageLoading(false)
	}

	const areImagesLoaded = () => {
		// Check if all required images are loaded
		if (!templateConfig.elements) return false

		let allLoaded = true

		templateConfig.elements.forEach((element, index) => {
			if (element.type === 'image' && !loadedImages[`element_${index}`]) {
				if (element.required !== false) { // If not explicitly marked as optional
					allLoaded = false
				}
			}
		})

		return allLoaded
	}

	const createStage = () => {
		// Initialize the stage
		stageRef.current = new Konva.Stage({
			container: templateConfig.containerId,
			width: WORKBENCH_SIZE.width,
			height: WORKBENCH_SIZE.height,
		})

		// Create layers
		mainLayerRef.current = new Konva.Layer()
		transformerLayerRef.current = new Konva.Layer()

		// Create main transformer
		const transformer = new Konva.Transformer({
			name: 'Transformer',
			rotateEnabled: false,
			keepRatio: true,
			enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
		})
		transformerLayerRef.current.add(transformer)

		// Create text transformer
		const textTransformer = new Konva.Transformer({
			name: 'TextTransformer',
			rotateEnabled: false,
			flipEnabled: false,
			// Allow horizontal and vertical resizing with specific handles
			enabledAnchors: ['middle-left', 'middle-right', 'top-center', 'bottom-center'],
			// Set minimum width and height constraints
			boundBoxFunc: (oldBox, newBox) => {
				if (Math.abs(newBox.width) < 100) {
					return oldBox
				}
				return newBox
			},
			visible: false, // Hide initially
		})
		transformerLayerRef.current.add(textTransformer)

		// Create background
		if (templateConfig.backgroundColor) {
			const background = new Konva.Rect({
				x: 0,
				y: 0,
				width: WORKBENCH_SIZE.width,
				height: WORKBENCH_SIZE.height,
				fill: currentPalette[templateConfig.backgroundColor] || templateConfig.backgroundColor,
				name: 'background',
			})
			mainLayerRef.current.add(background)
		}

		// Create elements
		createElements()

		// Add layers to stage
		stageRef.current.add(mainLayerRef.current)
		stageRef.current.add(transformerLayerRef.current)

		// Stage click handler
		stageRef.current.on('click tap', handleStageClick)

		// Drag guides setup
		mainLayerRef.current.on('dragmove', (e) => {
			handleDragMove(e, mainLayerRef.current, stageRef.current)
		})

		mainLayerRef.current.on('dragend', () => {
			mainLayerRef.current.find(".guid-line").forEach(l => l.destroy())
		})

		// Resize the stage to fit the container
		resizeStage()

		// Notify parent component that stage is ready
		if (onStageReady && stageRef.current) {
			onStageReady(stageRef.current)
		}

		// Initialize history for the first time
		const historyObject = createNewHistoryFromElements()
		if (!history || templateConfig.containerId !== history[historyStep]?.templateId) {
			initHistory(historyObject)
		} else {
			// Apply history if exists
			updateElementsFromHistory()
		}
	}

	const createElements = () => {
		if (!productOnDraft) return
		if (!templateConfig.elements) return

		templateConfig.elements.forEach((element, index) => {
			const elementId = `element_${index}`

			switch (element.type) {
				case 'image':
					createImageElement(element, index)
					break
				case 'text':
					createTextElement(element, index)
					break
				case 'rect':
					createRectElement(element, index)
					break
				case 'circle':
					createCircleElement(element, index)
					break
				default:
					console.warn(`Unknown element type: ${element.type}`)
			}
		})

		//after a while 1 sec
		setTimeout(() => {
			arrangeElementsOrder()
		}, 100)

		mainLayerRef.current.draw()
	}

	const createImageElement = (element, index) => {
		const image = loadedImages[`element_${index}`]
		if (!image) return

		let imageNode
		const elementConfig = {
			x: element.position.x,
			y: element.position.y,
			width: element.scale?.width || image.naturalWidth,
			height: element.scale?.height || image.naturalHeight,
			name: `element_${index}`,
			draggable: element.clickThrough ? false : (element.draggable !== false),
			opacity: element.opacity || 1,
			id: element.id || `element_${index}`,
			visible: element.id === 'companyLogo' && !element.required ? draftConfiguration?.showCompanyLogo : true,
		}

		// Handle resize if maxWidth or maxHeight is specified
		if (element.scale?.maxWidth || element.scale?.maxHeight) {
			const { width, height } = resizeImageToFit({
				x: element.position.x,
				y: element.position.y,
				width: image.naturalWidth,
				height: image.naturalHeight,
				maxWidth: element.scale?.maxWidth,
				maxHeight: element.scale?.maxHeight,
				center: false,
			})

			elementConfig.width = width
			elementConfig.height = height
		}

		// Handle centering if specified
		if (element.center) {
			if (elementConfig.width && elementConfig.height) {
				const { x, y } = centerTheElement({
					x: elementConfig.offsetX || elementConfig.x,
					y: elementConfig.offsetY || elementConfig.y,
					width: elementConfig.width,
					height: elementConfig.height,
				})

				elementConfig.x = x
				elementConfig.y = y
			}
		}

		// Handle objectFit: 'cover' if specified
		if (element.objectFit === 'cover') {
			const clipConfig = {
				x: element.position.x,
				y: element.position.y,
				width: element.scale.width,
				height: element.scale.height,
			}

			// Adjust clip position if centered
			if (element.center) {
				clipConfig.x = element.position.x - element.scale.width / 2;
				clipConfig.y = element.position.y - element.scale.height / 2;
			}

			const group = new Konva.Group({
				clip: clipConfig,
				name: `element_${index}_container`,
				draggable: element.clickThrough ? false : (element.draggable !== false),
				id: element.id ? `${element.id}_container` : `element_${index}_container`,
			})

			const fittingResult = calculateBoxFitCover({
				img: { width: image.naturalWidth || image.width, height: image.naturalHeight || image.height },
				box: {
					width: element.scale.width,
					height: element.scale.height,
					positionx: element.center ? element.position.x - element.scale.width / 2 : element.position.x,
					positiony: element.center ? element.position.y - element.scale.height / 2 : element.position.y
				}
			})

			elementConfig.x = fittingResult.x
			elementConfig.y = fittingResult.y
			elementConfig.width = fittingResult.width
			elementConfig.height = fittingResult.height

			// Remove centering offsets for the image inside the group
			if (element.center) {
				elementConfig.offsetX = 0;
				elementConfig.offsetY = 0;
			}

			imageNode = new Konva.Image({
				...elementConfig,
				image: image,
			})

			group.add(imageNode)
			if (element.clickThrough) {
				group.listening(false)
			}

			// Apply centering to the group if needed
			if (element.center) {
				group.x(element.position.x);
				group.y(element.position.y);
				group.offsetX(element.scale.width / 2);
				group.offsetY(element.scale.height / 2);
			}

			mainLayerRef.current.add(group)

			// Add event handlers to the group
			addElementEventHandlers(group, index)

		} else {
			// Regular image
			imageNode = new Konva.Image({
				...elementConfig,
				image: image,
			})

			if (element.clickThrough) {
				imageNode.listening(false)
			}
			mainLayerRef.current.add(imageNode)

			// Add event handlers
			addElementEventHandlers(imageNode, index)
		}

		// Apply filters if specified
		if (element.filters) {
			imageNode.cache()

			if (element.filters.brightness !== undefined) {
				imageNode.filters([Konva.Filters.Brighten])
				imageNode.brightness(element.filters.brightness)
			}

			// Add more filters as needed
		}
	}

	const createTextElement = (element, index) => {
		// Process dynamic text content
		let textContent = element.text

		// Check if the text contains placeholders
		const hasPlaceholders = /{{[^{}]+}}/g.test(textContent);

		if (hasPlaceholders) {
			// Process placeholders in the text
			textContent = processTextPlaceholders(textContent, {
				productName: draftConfiguration?.productName || productOnDraft?.name || '',
				productPrice: formatPrice(productOnDraft?.price || "0", productOnDraft?.currency || currency),
				companyName: companyDetails?.companyName || '',
				discountAmount: discount?.amount + (discount?.type === DiscountTypes.AMOUNT ? formatPrice(discount?.amount || 0, currency) : "%"),
				discountCode: discount?.code || '',
			});
		}

		// Calculate initial height based on maxLines if specified
		let initialHeight = element.height;
		if (element.maxLines && element.fontSize) {
			// Approximate line height based on font size (typically 1.2-1.5x fontSize)
			const lineHeight = element.fontSize * 1.2;
			initialHeight = element.maxLines * lineHeight;
		}

		const textNode = new Konva.Text({
			x: element.position.x,
			y: element.position.y,
			text: textContent,
			fontSize: element.fontSize || 20,
			fontFamily: element.fontFamily || 'Arial',
			fill: currentPalette[element.color] || element.color || '#000000',
			width: element.width,
			height: initialHeight || element.height,
			align: element.align || 'left',
			verticalAlign: element.verticalAlign || 'top',
			padding: element.padding || 0,
			letterSpacing: element.letterSpacing || 0,
			fontStyle: element.fontStyle || '',
			draggable: element.clickThrough ? false : (element.draggable !== false),
			name: `element_${index}`,
			id: element.id || `element_${index}`,
			originalText: hasPlaceholders ? element.text : null,
			shadowColor: element.shadowColor || 'black',
			shadowBlur: element.shadowBlur || 5,
			shadowOffset: element.shadowOffset || { x: 1, y: 1 },
			shadowOpacity: element.shadowOpacity || 0.5,
			ellipsis: true, // Enable ellipsis for all text elements
		})

		mainLayerRef.current.add(textNode)

		// Add event handlers
		addElementEventHandlers(textNode, index)
	}

	const createRectElement = (element, index) => {
		const rectNode = new Konva.Rect({
			x: element.position.x,
			y: element.position.y,
			width: element.scale.width,
			height: element.scale.height,
			fill: currentPalette[element.fill] || element.fill,
			stroke: currentPalette[element.stroke] || element.stroke,
			strokeWidth: element.strokeWidth || 0,
			cornerRadius: element.cornerRadius || 0,
			opacity: element.opacity || 1,
			draggable: element.clickThrough ? false : (element.draggable !== false),
			name: `element_${index}`,
			id: element.id || `element_${index}`,
		})

		mainLayerRef.current.add(rectNode)

		// Add event handlers
		addElementEventHandlers(rectNode, index)
	}

	const createCircleElement = (element, index) => {
		const circleNode = new Konva.Circle({
			x: element.position.x,
			y: element.position.y,
			radius: element.radius || 50,
			fill: currentPalette[element.fill] || element.fill,
			stroke: currentPalette[element.stroke] || element.stroke,
			strokeWidth: element.strokeWidth || 0,
			opacity: element.opacity || 1,
			draggable: element.clickThrough ? false : (element.draggable !== false),
			name: `element_${index}`,
			id: element.id || `element_${index}`,
		})

		// Note: Circles are already centered by default in Konva
		// No need to adjust offsets

		mainLayerRef.current.add(circleNode)

		// Add event handlers
		addElementEventHandlers(circleNode, index)
	}

	const addElementEventHandlers = (node, index) => {
		// Get element config to check for clickThrough
		const elementConfig = templateConfig.elements[index];

		node.on('dragstart', () => {
			deactivateTransformers()
			setSelectedElement(index)
		})

		node.on('dragmove dragend', e => {
			// Select the appropriate transformer based on element type
			const mainTransformer = transformerLayerRef.current.findOne('.Transformer')
			const textTransformer = transformerLayerRef.current.findOne('.TextTransformer')

			if (elementConfig.type === 'text') {
				textTransformer.nodes([e.target])
			} else {
				mainTransformer.nodes([e.target])
			}
		})

		node.on('transformend dragend', (e) => {
			const elementIndex = e.target.name().split('_')[1]
			const x = e.target.x()
			const y = e.target.y()
			const scaleX = e.target.scaleX()
			const scaleY = e.target.scaleY()
			const width = e.target.width ? e.target.width() * scaleX : null
			const height = e.target.height ? e.target.height() * scaleY : null
			const rotation = e.target.rotation()

			// For text elements, we want to save the actual width, not the scaled width
			if (elementConfig.type === 'text') {
				addToHistory(`element_${elementIndex}`, {
					x,
					y,
					scaleX: 1,
					scaleY: 1,
					width: e.target.width(),
					height: e.target.height(), // Save the actual height
					rotation
				})
			} else {
				addToHistory(`element_${elementIndex}`, {
					x,
					y,
					scaleX,
					scaleY,
					width,
					height,
					rotation
				})
			}
		})

		// Add transform event handler for text elements with maxLines
		if (elementConfig.type === 'text') {
			node.on('transform', (e) => {
				// Update width and height on transform, but keep scale at 1
				const newWidth = Math.max(node.width() * node.scaleX(), 100);
				const newHeight = Math.max(node.height() * node.scaleY(), 100);

				// Reset scale to 1
				node.setAttrs({
					width: newWidth,
					height: newHeight,
					scaleX: 1,
					scaleY: 1
				});
			});
		}
	}

	const updateElements = () => {
		if (!templateConfig.elements || !mainLayerRef.current) return

		templateConfig.elements.forEach((element, index) => {
			const node = mainLayerRef.current.findOne(`#${element.id}`) || mainLayerRef.current.findOne(`.element_${index}`)

			if (!node) return

			// Update colors for elements
			if (element.color && currentPalette[element.color]) {
				node.fill(currentPalette[element.color])
			}

			if (element.fill && currentPalette[element.fill]) {
				node.fill(currentPalette[element.fill])
			}

			if (element.stroke && currentPalette[element.stroke]) {
				node.stroke(currentPalette[element.stroke])
			}

			// Update text content
			if (element.type === 'text') {
				// Check if the text contains placeholders
				const originalText = element.text || node.text();
				const hasPlaceholders = /{{[^{}]+}}/g.test(originalText);

				if (hasPlaceholders) {
					// Process placeholders in the text
					const processedText = processTextPlaceholders(originalText, {
						productName: draftConfiguration?.productName || productOnDraft?.name || '',
						productPrice: formatPrice(productOnDraft?.price || 0, productOnDraft?.currency || currency),
						companyName: companyDetails?.companyName || '',
						discountAmount: discount?.amount + (discount?.type === DiscountTypes.AMOUNT ? formatPrice(discount?.amount || 0, currency) : "%"),
						discountCode: discount?.code || '',
					});

					// Update the text content
					node.text(processedText);
				}

				// Ensure ellipsis is enabled
				if (node.ellipsis() !== true) {
					node.ellipsis(true);
				}

				if (element.fontStyle) {
					node.setAttr('fontStyle', element.fontStyle)
				}
			}

			// Update SVG colors
			if (element.type === 'image' && element.isSvg && element.svgColor) {
				loadAndChangeSvgColor(
					element.src,
					currentPalette[element.svgColor] || element.svgColor,
					(coloredSvgUrl) => {
						Konva.Image.fromURL(coloredSvgUrl, (image) => {
							let attrs = node.getAttrs()
							delete attrs.image
							image.setAttrs(attrs)
							node.destroy()
							mainLayerRef.current.add(image)
							addElementEventHandlers(image, index)
							mainLayerRef.current.draw()
						})
					},
					element.svgCustomReplace
				)
			}
		})

		//after a while 1 sec
		setTimeout(() => {
			arrangeElementsOrder()
		}, 100)

		mainLayerRef.current.batchDraw()
	}

	const updateCompanyLogo = () => {
		if (!draftConfiguration?.showCompanyLogo) {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')
			if (companyLogo) {
				companyLogo.visible(false)
			}
		} else {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')
			if (companyLogo) {
				companyLogo.visible(true)
			}
		}

		if (companyDetails.usesBgRemovedLogo) {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')

			if (companyLogo) {
				const imageObj = new Image()
				let imageSource = companyDetails.companyLogoUrl

				if (
					companyDetails.companyLogoUrl.includes('s3.amazonaws.com') ||
					companyDetails.companyLogoUrl.includes('eu-central-1.amazonaws.com')
				) {
					const url = new URL(
						companyDetails.companyLogoUrl,
						window.location.origin,
					)

					imageSource =
						url.searchParams.size > 0
						? companyDetails.companyLogoUrl + `&konva=${Date.now()}`
						: companyDetails.companyLogoUrl + `?konva=${Date.now()}`
				}
					imageObj.src = imageSource
					imageObj.crossOrigin = 'Anonymous'
					imageObj.onload = () => {
					companyLogo.image(imageObj)
				}
					imageObj.onerror = error => {
					console.error('Error loading company logo', error)
				}
			}
		} else {
			const companyLogo = mainLayerRef.current.findOne('#companyLogo')

			if (companyLogo) {
				const index = templateConfig.elements.findIndex(
					element => element.id === 'companyLogo',
				)
				companyLogo.image(loadedImages[`element_${index}`])
			}
		}
	}

	const handleStageClick = (e) => {
		const target = e.target
		const mainTransformer = transformerLayerRef.current.findOne('.Transformer')
		const textTransformer = transformerLayerRef.current.findOne('.TextTransformer')

		if (target === stageRef.current) {
			deactivateTransformers()
			setSelectedElement(null)
			return
		}

		// Check if the target is a valid element
		if (target.name().includes('element_')) {
			// Get the element index from the name
			const elementIndex = parseInt(target.name().split('_')[1])

			// Check if this is a text element
			const elementConfig = templateConfig.elements[elementIndex]

			if (elementConfig.type === 'text') {
				// Use text transformer for text elements
				mainTransformer.visible(false)
				textTransformer.visible(true)
				textTransformer.nodes([target])

				// Add transform event handler
				target.off('transform')

				// For all text elements
				target.on('transform', () => {
					// Update width and height on transform, but keep scale at 1
					const newWidth = Math.max(target.width() * target.scaleX(), 100);
					const newHeight = Math.max(target.height() * target.scaleY(), 100);

					// Reset scale to 1
					target.setAttrs({
						width: newWidth,
						height: newHeight,
						scaleX: 1,
						scaleY: 1
					});

					// No need to manually truncate text since we're using Konva's ellipsis
					// The text will automatically adjust to the new dimensions
				});
			} else {
				// Use regular transformer for non-text elements
				textTransformer.visible(false)
				mainTransformer.visible(true)
				mainTransformer.nodes([target])
			}

			setSelectedElement(elementIndex)
		} else {
			deactivateTransformers()
			setSelectedElement(null)
		}
	}

	const handleDragMove = (e, layer, stage) => {
		layer.find(".guid-line").forEach(l => l.destroy())

		// find possible snapping lines
		const lineGuideStops = getLineGuideStops(stage, e.target)
		// find snapping points of current object
		const itemBounds = getObjectSnappingEdges(e.target)

		// now find where we can snap current object
		const guides = getGuides(lineGuideStops, itemBounds)
		// do nothing if no snapping
		if (!guides.length) {
			return
		}

		drawGuides(layer, guides)

		const absPos = e.target.absolutePosition()
		// now force object position
		guides.forEach((lg) => {
			switch (lg.orientation) {
				case 'V': {
					absPos.x = lg.lineGuide + lg.offset
					break
				}
				case 'H': {
					absPos.y = lg.lineGuide + lg.offset
					break
				}
			}
		})
		e.target.absolutePosition(absPos)
	}

	const deactivateTransformers = () => {
		const mainTransformer = transformerLayerRef.current.findOne('.Transformer')
		const textTransformer = transformerLayerRef.current.findOne('.TextTransformer')

		if (mainTransformer) {
			mainTransformer.nodes([])
		}

		if (textTransformer) {
			textTransformer.nodes([])
		}
	}

	const createNewHistoryFromElements = () => {
		if (!templateConfig.elements) return { templateId: templateConfig.containerId }

		const historyObject = templateConfig.elements.reduce((acc, element, index) => {
			const elementNode = mainLayerRef.current.findOne(`.element_${index}`)
			if (elementNode) {
				const historyEntry = {
					x: elementNode.x(),
					y: elementNode.y(),
					scaleX: elementNode.scaleX(),
					scaleY: elementNode.scaleY(),
					width: elementNode.width ? elementNode.width() : null,
					height: elementNode.height ? elementNode.height() : null,
					rotation: elementNode.rotation(),
				}

				// Add text-specific properties if this is a text element
				if (element.type === 'text') {
					historyEntry.text = elementNode.text();
					historyEntry.fontSize = elementNode.fontSize();
					historyEntry.fontFamily = elementNode.fontFamily();
					historyEntry.fontStyle = elementNode.attrs.fontStyle || '';
				}

				acc[`element_${index}`] = historyEntry;
			}
			return acc
		}, {})

		historyObject.templateId = templateConfig.containerId
		return historyObject
	}

	const updateElementsFromHistory = () => {
		if (!history[historyStep]) return
		if (!mainLayerRef.current) return

		if (history[historyStep].templateId && history[historyStep].templateId !== templateConfig.containerId) {
			return
		}

		Object.keys(history[historyStep]).forEach(key => {
			if (key === 'templateId') return

			const element = mainLayerRef.current.findOne(`.${key}`)
			if (element) {
				// Set position, scale and rotation
				element.setAttrs({
					x: history[historyStep][key].x,
					y: history[historyStep][key].y,
					scaleX: history[historyStep][key].scaleX || element.scaleX(),
					scaleY: history[historyStep][key].scaleY || element.scaleY(),
					rotation: history[historyStep][key].rotation || element.rotation(),
				})

				// Set text-specific properties if they exist in history
				if (history[historyStep][key].text) {
					element.text(history[historyStep][key].text);
				}

				if (history[historyStep][key].fontSize) {
					element.fontSize(history[historyStep][key].fontSize);
				}

				if (history[historyStep][key].fontFamily) {
					element.fontFamily(history[historyStep][key].fontFamily);
				}

				if (history[historyStep][key].fontStyle) {
					element.setAttr('fontStyle', history[historyStep][key].fontStyle);
				}
			}
		})

		mainLayerRef.current.draw()
	}

	// Arrange elements according to the order in the template configuration
	const arrangeElementsOrder = () => {
		if (!templateConfig.elements) return

		// Get all elements from the layer
		const allLayerElements = mainLayerRef.current.children;

		// Find the background element (if it exists)
		const backgroundIndex = allLayerElements.findIndex(el => el.name() === 'background');

		// Start z-index after background (which should be at the bottom)
		let startZIndex = backgroundIndex >= 0 ? 1 : 0;

		// First pass: handle elements with explicit zIndex in template
		templateConfig.elements.forEach((element, index) => {
			if (element.zIndex !== undefined) {
				const elementId = element.id || `element_${index}`;

				// Find the element in the layer
				let node;

				if (element.type === 'image' && element.objectFit === 'cover') {
					// For images with objectFit cover, find the container
					node = mainLayerRef.current.findOne(`#${elementId}_container`);
				} else {
					// For regular elements, find by ID
					node = mainLayerRef.current.findOne(`#${elementId}`);
				}

				// Set explicit z-index if defined in template
				if (node) {
					node.zIndex(element.zIndex);
				}
			}
		});

		// Second pass: handle elements without explicit zIndex
		// Sort them according to their position in the template array
		templateConfig.elements.forEach((element, index) => {
			if (element.zIndex === undefined) {
				const elementId = element.id || `element_${index}`;

				// Find the element in the layer
				let node;

				if (element.type === 'image' && element.objectFit === 'cover') {
					// For images with objectFit cover, find the container
					node = mainLayerRef.current.findOne(`#${elementId}_container`);
				} else {
					// For regular elements, find by ID
					node = mainLayerRef.current.findOne(`#${elementId}`);
				}

				// If element is found, set its z-index based on template order
				if (node) {
					node.zIndex(startZIndex + index);
				}
			}
		});
	}

	return (
		<div
			id={templateConfig.containerId}
			style={{
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
			}}
		/>
	)
}

export default NewEditor 