import { WORKBENCH_SIZE } from '../../../support/constants';
import temp15bg from '../../../../shared/assets/images/ai-creative/temp15bg.svg'
import temp15fg from '../../../../shared/assets/images/ai-creative/temp15fg.svg'

export const Template15Config = {
  name: 'Mystic Spotlight',
  containerId: 'template15-carousel-single',
  backgroundColor: 'color3',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp15bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#0077FF',
      draggable: false,
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp15fg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#0077FF',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 750,
        maxHeight: 750
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Primary Text (Discount)
    {
      id: 'primaryText',
      type: 'text',
      position: {
        x: 20,
        y: WORKBENCH_SIZE.height - 200,
      },
      width: WORKBENCH_SIZE.width - (WORKBENCH_SIZE.width - 750) / 2 - 20,
      text: '30% OFF',
      fontFamily: 'Readex Pro',
      fontSize: 86,
      fontStyle: 'bold',
      color: 'color2',
      letterSpacing: -0.8,
      align: 'right',
      fontIndex: 1,
    },
    // Secondary Text (Discount Code)
    {
      id: 'secondaryText',
      type: 'text',
      position: {
        x: 20,
        y: WORKBENCH_SIZE.height - 110,
      },
      width: WORKBENCH_SIZE.width - (WORKBENCH_SIZE.width - 750) / 2 - 20,
      text: 'CODE: GET30',
      fontFamily: 'Readex Pro',
      fontSize: 53,
      color: 'color2',
      letterSpacing: -0.05,
      align: 'right',
      fontIndex: 2,
    },
  ],
}