import { WORKBENCH_SIZE } from '../../../support/constants'
import temp1bg from '../../../../shared/assets/images/ai-creative/temp1bg.svg'

export const Template1Config = {
  name: 'Classic Frame',
  containerId: 'template1-carousel-single',
  backgroundColor: 'color2',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp1bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#EAB69F',
      draggable: false,
      clickThrough: true,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: (2 * WORKBENCH_SIZE.width) / 3,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 600,
        maxHeight: 900,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: 25,
        y: 25,
      },
      scale: {
        maxWidth: 350,
        maxHeight: 150,
      },
      dynamicSrc: 'companyLogo',
      required: false,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 25,
        y: 279,
      },
      width: 350,
      height: 100,
      fontIndex: 1,
      fontSize: 48,
      fontFamily: 'Readex Pro',
      color: 'color3',
      verticalAlign: 'middle',
      text: '{{productName}}',
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: 25,
        y: 450,
      },
      width: 350,
      fontFamily: 'Readex Pro',
      fontSize: 48,
      fontStyle: 'bold',
      color: 'color3',
      fontIndex: 2,
      text: '{{productPrice}}',
    },
  ],
}
