const VALIDATOR_TYPE_REQUIRE = 'REQUIRE';
const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH';
const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH';
const VALIDATOR_TYPE_MIN = 'MIN';
const VALIDATOR_TYPE_MAX = 'MAX';
const VALIDATOR_TYPE_EMAIL = 'EMAIL';
const VALIDATOR_TYPE_PHONE = 'PHONE';
const VALIDATOR_TYPE_PHONE_ONLY_DIGITS = 'PHONE_ONLY_DIGITS'
const VALIDATOR_TYPE_COUNTRY_CODE = 'COUNTRY_CODE'
const VALIDATOR_TYPE_DOMAIN = 'DOMAIN';
const VALIDATOR_TYPE_SHOPIFY_DOMAIN = 'SHOPIFY_DOMAIN';
const VALIDATOR_TYPE_FILE = 'FILE';
const VALIDATOR_TYPE_PASSWORD_MATCH = 'PASSWORD_MATCH';
const VALIDATOR_TYPE_AGE_RANGE = 'AGE_RANGE';


export const VALIDATOR_REQUIRE = () => ({ type: VALIDATOR_TYPE_REQUIRE });
export const VALIDATOR_FILE = () => ({ type: VALIDATOR_TYPE_FILE });
export const VALIDATOR_MINLENGTH = val => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val
});
export const VALIDATOR_MAXLENGTH = val => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val
});
export const VALIDATOR_MIN = val => ({ type: VALIDATOR_TYPE_MIN, val: val });
export const VALIDATOR_MAX = val => ({ type: VALIDATOR_TYPE_MAX, val: val });
export const VALIDATOR_EMAIL = () => ({ type: VALIDATOR_TYPE_EMAIL });
export const VALIDATOR_PHONE = () => ({ type: VALIDATOR_TYPE_PHONE });
export const VALIDATOR_PHONE_ONLY_DIGITS = () => ({ type: VALIDATOR_TYPE_PHONE_ONLY_DIGITS });
export const VALIDATOR_COUNTRY_CODE = () => ({ type: VALIDATOR_TYPE_COUNTRY_CODE });
export const VALIDATOR_DOMAIN = () => ({ type: VALIDATOR_TYPE_DOMAIN });
export const VALIDATOR_SHOPIFY_DOMAIN = () => ({ type: VALIDATOR_TYPE_SHOPIFY_DOMAIN });
export const VALIDATOR_PASSWORD_MATCH = (password, confirmPassword) => ({ type: VALIDATOR_TYPE_PASSWORD_MATCH, password: password });
export const VALIDATOR_AGE_RANGE = () => ({ type: VALIDATOR_TYPE_AGE_RANGE });

export const validate = (value, validators) => {
  let isValid = true;
  for (const validator of validators) {
    if (validator.type === VALIDATOR_TYPE_REQUIRE) {
      isValid = isValid && value.trim().length > 0;
    }
    if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
      isValid = isValid && value.trim().length >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
      isValid = isValid && value.trim().length <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MIN) {
      isValid = isValid && +value >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAX) {
      isValid = isValid && +value <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_EMAIL) {
      isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_PHONE) {
      isValid = isValid && /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,})[-. )]*(\d{2,})[-. ]*(\d{2,})(?: *x(\d+))?\s*$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_PHONE_ONLY_DIGITS) {
      isValid = isValid && /^\d{6,20}$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_COUNTRY_CODE) {
      isValid = isValid && /^\+\d+(\s\d+)?$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_DOMAIN) {
      isValid = isValid && /^[\p{L}\p{N}][\p{L}\p{N}-]{1,61}[\p{L}\p{N}](?:\.[a-zA-Z]{2,})+$/u.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_SHOPIFY_DOMAIN) {
      isValid = isValid && /[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com[\/]?/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_PASSWORD_MATCH) {
      isValid = isValid && validator.password === value
    }
    if (validator.type === VALIDATOR_TYPE_AGE_RANGE) {
      isValid = isValid && 
                value.min && 
                value.max && 
                Number(value.min) >= 0 && 
                Number(value.max) <= 120 &&
                Number(value.min) <= Number(value.max);
    }
  }
  return isValid;
};