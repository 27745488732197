'use client'
import React, { useState, useEffect, useRef } from 'react'
import './ReviewBoxSlider.css'
import quotes from '../../../shared/assets/images/intro/quotes.svg'
import spxLogo from '../../../shared/assets/images/intro/spx-logo.svg'
import chakraLogo from '../../../shared/assets/images/intro/chakra-logo.svg'
import tatilbudurLogo from '../../../shared/assets/images/intro/tatil-budur-logo.svg'
import sporjinalLogo from '../../../shared/assets/images/intro/sporjinal-logo.svg'

const slides = [
  {
    reviewText:
      'Thank you, Enhencer, for giving us significant increase in ROAS and making our Facebook ad campaigns more efficient.',
    brandLogo: spxLogo,
    brandLogoAlt: 'SPX Logo',
  },
  {
    reviewText:
      'Thanks to Enhencer’s AI algorithm, we can better understand and analyze the website visitors’ behaviors. ',
    brandLogo: chakraLogo,
    brandLogoAlt: 'Chakra Logo',
  },
  {
    reviewText:
      'Since we started working with Enhencer, Facebook campaigns made us smile more.',
    brandLogo: tatilbudurLogo,
    brandLogoAlt: 'Tatilbudur Logo',
  },
  {
    reviewText:
      'Our conversion rates soared, achieving a remarkable 5-6 times increase, while our costs per conversion plummeted by 2-3 times. "',
    brandLogo: sporjinalLogo,
    brandLogoAlt: 'Sporjinal Logo',
  },
]

const ReviewBoxSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const touchStartX = useRef(null)
  const touchEndX = useRef(null)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length)
    }, 5000) // Her 5 saniyede bir slayt değişir

    return () => clearInterval(timer)
  }, [])

  const handleTouchStart = e => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleTouchMove = e => {
    touchEndX.current = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    if (touchStartX.current && touchEndX.current) {
      const difference = touchStartX.current - touchEndX.current
      if (difference > 50) {
        // Sola kaydırma
        setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length)
      } else if (difference < -50) {
        // Sağa kaydırma
        setCurrentSlide(
          prevSlide => (prevSlide - 1 + slides.length) % slides.length,
        )
      }
    }
    touchStartX.current = null
    touchEndX.current = null
  }

  const slide = slides[currentSlide]

  return (
    <div
      className="slider-brand-rectangle-review"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="rectangle">
        <img src={quotes} alt="Quotes" className="quotes-icon" />
        <p className="review-text">{slide.reviewText}</p>
        <div className="brand-logo-container">
          <img
            src={slide.brandLogo}
            alt={slide.brandLogoAlt}
            className="brand-logo"
          />
        </div>
        <div className="slider-dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentSlide ? 'active' : ''}`}
            ></span>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ReviewBoxSlider
