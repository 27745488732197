import { WORKBENCH_SIZE } from '../../../support/constants'
import temp22bg from '../../../../shared/assets/images/ai-creative/temp22bg.svg'
import temp22fg from '../../../../shared/assets/images/ai-creative/temp22fg.svg'

export const Template22Config = {
  name: 'Crystal Radiance',
  containerId: 'template22-carousel-single',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp22bg,
      isSvg: true,
      svgColor: 'color1',
      svgCustomReplace: '#FFD876',
      draggable: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 650,
        maxHeight: 650,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Second Background Layer SVG (Foreground)
    {
      id: 'background-second-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp22fg,
      isSvg: true,
      svgColor: 'color3',
      svgCustomReplace: 'black',
      draggable: false,
    },
  ],
}
