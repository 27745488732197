import React from 'react'
import { useSelector } from 'react-redux'
import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import Modals from '../../enums/Modals'
import { FaEnvelope, FaCalendarAlt, FaTicketAlt } from 'react-icons/fa'
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import { PopupButton } from "react-calendly"

import './NavbarAccountManagerCard.css'

const NavbarAccountManagerCard = ({ isMobile, isProfileMenuOpen }) => {
  const performanceManagerProfile = useSelector(
    state => state.auth.user?.crmDetails?.performanceManagerProfile,
  )
  const name = useSelector(state => state.auth.user?.name)
  const email = useSelector(state => state.auth.user?.email)
  const { openModal } = useModal()

  const createASupportTicket = () => {
    openModal({
      type: Modals.CREATE_A_SUPPORT_TICKET,
      closeOnClickOutside: true,
      performanceManager: performanceManagerProfile._id,
    })
  }

  const sendEmail = () => {
    window.location.href = `mailto:${performanceManagerProfile.email}`
  }

  const handleMobileButtonClick = (e, action) => {
    if (isMobile) {
      e.preventDefault()
      e.stopPropagation()
      setTimeout(() => {
        action()
      }, 0)
    } else {
      action()
    }
  }

  return performanceManagerProfile ? (
    <div 
      className="navbar-account-manager-card"
      onClick={(e) => {
        if (isMobile) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <h3>Your Ad Expert</h3>
      <div className="expert-info">
        <img
          src={performanceManagerProfile.image}
          alt={performanceManagerProfile.name}
          className="expert-image"
        />
        <div className="expert-details">
          <div className="expert-name">{performanceManagerProfile.name}</div>
        </div>
      </div>
      <div 
        className="action-buttons"
        onClick={(e) => {
          if (isMobile) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        <Tooltip content="Book a meeting">
          <PopupButton
            url={performanceManagerProfile?.meetingUrl || process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK}
            rootElement={document.getElementById('root')}
            prefill={{
              email: email || '',
              name: name || '',
            }}
            className="action-button round-button"
            text={<FaCalendarAlt />}
          />
        </Tooltip>
        <Tooltip content="Create a ticket">
          <Button
            onClick={(e) => handleMobileButtonClick(e, createASupportTicket)}
            className="action-button round-button"
          >
            <FaTicketAlt />
          </Button>
        </Tooltip>
        <Tooltip content="Send an e-mail">
          <Button
            onClick={(e) => handleMobileButtonClick(e, sendEmail)}
            className="action-button round-button"
          >
            <FaEnvelope />
          </Button>
        </Tooltip>
      </div>
    </div>
  ) : (
    <div 
      className="navbar-account-manager-card"
      onClick={(e) => {
        if (isMobile) {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
    >
      <div 
        className="action-buttons"
        onClick={(e) => {
          if (isMobile) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        <PopupButton
          url={process.env.REACT_APP_CALENDLY_GENERAL_SUPPORT_LINK}
          rootElement={document.getElementById('root')}
          className="action-button round-button get-support"
          text={
            <>
              <FaCalendarAlt />
              Get Support
            </>
          }
        />
      </div>
    </div>
  )
}

export default NavbarAccountManagerCard
