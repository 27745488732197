import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../../hooks/http-hook'
import { useModal } from '../../hooks/modal-hook'

import './LookalikeAudienceConfig.css'
import Checkbox from '../FormElements/Checkbox'
import Button from '../FormElements/Button'
import { useAdmin } from '../../hooks/admin-hook'
import useAudienceNetworkLibrary from '../../hooks/audience-network-library-hook'
import Tooltip from '../UIElements/Tooltip'
import errorHandler from '../../util/errorHandler'

const LookalikeAudienceConfig = props => {
  const { sendRequest, isLoading } = useHttpClient()
  const { closeModal } = useModal()
  const authToken = useSelector(state => state.auth.token)
  const userId = props.data.userId
  const fullFunnel = props.data.fullFunnel
  const adAccountId = fullFunnel.adAccountId
  const adAccountAudiences = props.data.adAccountAudiences
  const anAudiences = adAccountAudiences.an

  const adset = props.data.adset
  const adsetName = props.data.adsetName
  const campaignName = props.data.campaignName

  const adsetAudiences = adset.audiences
  const callback = props.data.callback


  const { findAudienceInLibrary, libraryLoaded, findOriginalAudience } = useAudienceNetworkLibrary()

  const [initialList, setInitialList] = useState(anAudiences)
  const [list, setList] = useState(anAudiences)



  useEffect(() => {
    if (!libraryLoaded) {
      return
    }


    console.log("adsetAudiences ", adsetAudiences)

    const existingAudiences = adsetAudiences.map(audience => findAudienceInLibrary(audience.id))

    console.log("existingAudiences ", existingAudiences)
    console.log("anAudiences ", anAudiences)

    const anAudienceList = anAudiences.map(audience => {
      if (!audience) return

      if (existingAudiences.length === 0) {
        return {
          id: audience.id,
          baseAudienceId: audience.id,
          name: audience.name,
          description: audience.description,
          seed: false,
          lal10: false,
          lal1: false,
        }
      }

      const foundAudience = existingAudiences.find(a => a?.baseAudienceId === audience.id)

      

      if (!foundAudience) return {
        id: audience.id,
        baseAudienceId: audience.id,
        name: audience.name,
        description: audience.description,
        seed: true,
        lal10: false,
        lal1: false,
        nonEnhAudience: true
      }
      return {
        id: foundAudience.id,
        baseAudienceId: foundAudience.baseAudienceId,
        name: foundAudience.name,
        description: foundAudience.description,
        seed: foundAudience.percentage === 0,
        lal10: foundAudience.percentage === 10,
        lal1: foundAudience.percentage === 1
      }
    }).reduce((acc, current) => {
      const existing = acc.find(item => item.name === current.name);

      if (existing) {
        existing.seed = existing.seed || current.seed;
        existing.lal10 = existing.lal10 || current.lal10;
        existing.lal1 = existing.lal1 || current.lal1;
        return acc;
      }

      return [...acc, current];
    }, []);

    setInitialList(anAudienceList)
    setList(anAudienceList)

  }, [adsetAudiences, libraryLoaded])


  const handleCheckboxChange = ({ value, index, percentage }) => {
    let newList = [...list]
    const isSeed = percentage === 0
    const isLal10 = percentage === 10
    const isLal1 = percentage === 1

    const newCheckboxes = {
      seed: isSeed ? value : newList[index].seed,
      lal10: isLal10 ? value : newList[index].lal10,
      lal1: isLal1 ? value : newList[index].lal1
    }

    newList[index] = {
      ...newList[index],
      ...newCheckboxes
    }
    setList(newList)
  }

  const handleSave = async () => {
    if (!libraryLoaded) {
      errorHandler({message: "Library not loaded, please try again later."})
      return
    }
    console.log("save them ", list)


    let audiencesToAdd = []
    let audiencesToRemove = []

    let lookalikesToAdd = []
    let lookalikesToRemove = []

    list.forEach((audience, index) => {

      if (!initialList[index].seed && audience.seed) {
        audiencesToAdd.push(findOriginalAudience(audience.id, 0))
      } else if (initialList[index].seed && !audience.seed) {
        audiencesToRemove.push(findOriginalAudience(audience.id, 0))
      }

      if (!initialList[index].lal1 && audience.lal1) {
        lookalikesToAdd.push(findOriginalAudience(audience.baseAudienceId, 1))
      } else if (initialList[index].lal1 && !audience.lal1) {
        lookalikesToRemove.push(findOriginalAudience(audience.baseAudienceId, 1))
      }

      if (!initialList[index].lal10 && audience.lal10) {
        lookalikesToAdd.push(findOriginalAudience(audience.baseAudienceId, 10))
      } else if (initialList[index].lal10 && !audience.lal10) {
        lookalikesToRemove.push(findOriginalAudience(audience.baseAudienceId, 10))
      }

    })

    console.log("audiencesToAdd ", audiencesToAdd)
    console.log("audiencesToRemove ", audiencesToRemove)
    console.log("lookalikesToAdd ", lookalikesToAdd)
    console.log("lookalikesToRemove ", lookalikesToRemove)

    const { genders, age, countryCodes, countries } = fullFunnel
    try {
      let body = {
        adAccountId,
        adset,
        adsetName: adsetName.toLowerCase(),
        audiencesToAdd,
        audiencesToRemove,
        lookalikesToAdd,
        lookalikesToRemove,
        genders,
        age,
        countryCodes,
        countries,
        userId,
        existingAudiences: adsetAudiences,
        fullFunnelId: fullFunnel._id,
        adAccountAudiences
      }

      console.log("body ", body)
      let { finalAdsetAudiences } = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateAdsetAudiences/`,
        'POST',
        JSON.stringify(body),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      callback(finalAdsetAudiences)
      closeModal()
      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <div className="lookalike-audience-config">
      {list.length ?
        <React.Fragment>
          <h3>
            Audiences for <b>{campaignName}</b> campaign, <b>{adsetName}</b> ad set
          </h3>
          <table>
            <thead>
              <tr>
                <th className="label">Name</th>
                <th className="label">Seed</th>
                <th className="label">1% LaL</th>
                <th className="label">10% LaL</th>
              </tr>
            </thead>
            <tbody>
              {list.map((audience, index) => (
                <tr key={index}>
                  <td>
                    <Tooltip content={audience.description}>
                      <span>{audience.name}</span>
                    </Tooltip>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={audience.seed}
                      disabled={true}
                      onChange={e => {
                        handleCheckboxChange({
                          value: e.target.checked,
                          index,
                          percentage: 0
                        })
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={audience.lal1}
                      disabled={audience.nonEnhAudience}
                      onChange={e => {
                        handleCheckboxChange({
                          value: e.target.checked,
                          index,
                          percentage: 1
                        })
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={audience.lal10}
                      disabled={audience.nonEnhAudience}
                      onChange={e => {
                        handleCheckboxChange({
                          value: e.target.checked,
                          index,
                          percentage: 10
                        })
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button onClick={handleSave} loadingText="Saving"
            loading={isLoading}>
            Save
          </Button>
        </React.Fragment>
        :
        <div>
          No AN audiences found, add them first for adding lookalikes.
        </div>
      }

    </div>
  )
}

export default LookalikeAudienceConfig
