import React, { useEffect, useMemo, useRef, useState } from 'react'
import Chart from 'chart.js/auto'
import '../CampaignReportComparison.css'
import {
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Legend,
	Tooltip,
} from 'chart.js';
import helper from '../../../../../shared/util/helper'
import numberFormatter from '../../../../../shared/util/numberFormatter'
import {
	FaInfoCircle
} from 'react-icons/fa';
import { getDataKey } from '../CampaignReportComparison';
import DualLineChart, { GenericDualLineChart } from '../components/DualLineChart';
import { useSelector } from 'react-redux';
Chart.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const EnhencerVsOthersView = ({
	data,
	labels,
}) => {
	const comparisonChartVisibility = useSelector(state => state.auth.user?.crmDetails?.comparisonChartVisibility)
	const roasVisible = comparisonChartVisibility?.aiAdsVsOthersRoas ?? true
	const sharesVisible = comparisonChartVisibility?.aiAdsVsOthersShares ?? true

	const [selectedAttributionWindow, setSelectedAttributionWindow] = useState('7d_click');
	const attributionWindowOptions = [
		{ value: '7d_click', label: '7-day click' },
		{ value: '7d_click_1_day_view', label: '7-day click, 1-day view' },
	];

	const getChartData = (metric, attributionWindow) => {
		if (!data && metric === 'Performance') return {
			enhencer: [2.1, 2.8, 2.4, 3.1, 2.3, 2.9, 3.3, 2.7, 3.2, 2.6],
			others: [2.4, 2.2, 2.1, 1.9, 1.8, 2.0, 2.5, 2.3, 2.2, 2.1]
		};

		if (!data && metric === 'AdSpend') {
			const enh = [200, 300, 280, 270, 600, 600, 700, 1100, 1200, 1000]
			const others = [300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200]

			if (!data) {
				return {
					enhencer: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					others: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				}
			}
			return {
				enhencer: enh.map((value, index) => {
					const totalValue = value + (others[index] || 0);
					return totalValue > 0 ? (value / totalValue) * 100 : 0;
				})
			}
		}

		if (!data && metric === 'Sales') {
			const enh = [120, 150, 180, 270, 320, 340, 350, 480, 550, 1600]
			const others = [180, 120, 140, 160, 180, 200, 220, 240, 260, 280]

			if (!data) {
				return {
					enhencer: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
					others: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
				}
			}
			return {
				enhencer: enh.map((value, index) => {
					const totalValue = value + (others[index] || 0);
					return totalValue > 0 ? (value / totalValue) * 100 : 0;
				})
			}
		};

		const dataKey = getDataKey(metric, attributionWindow);
		if (metric === 'AdSpend' || metric === 'Sales') {
			const enhencerData = data.after[dataKey].chartData;
			const othersData = data.others[dataKey].chartData;

			return {
				enhencer: enhencerData.map((value, index) => {
					const totalValue = value + (othersData[index] || 0);
					return totalValue > 0 ? (value / totalValue) * 100 : 0;
				}),
			};
		}
		return {
			enhencer: data.after[dataKey].chartData,
			others: data.others[dataKey].chartData
		};
	};


	const performanceChartData = useMemo(() => {
		return getChartData('Performance', selectedAttributionWindow)
	}, [data, selectedAttributionWindow])

	const adSpendChartData = useMemo(() => {
		return getChartData('AdSpend', selectedAttributionWindow)
	}, [data, selectedAttributionWindow])

	const salesChartData = useMemo(() => {
		return getChartData('Sales', selectedAttributionWindow)
	}, [data, selectedAttributionWindow])

	const avgEnhRoas = useMemo(() => {
		if (!performanceChartData) return '3.6x';
		const roasData = performanceChartData.enhencer;
		const average = roasData.reduce((sum, val) => sum + val, 0) / roasData.length;
		return !data ? '0x' : `${numberFormatter.formatNumber(average, 2)}x`;

	}, [performanceChartData])

	const avgOthersRoas = useMemo(() => {
		if (!performanceChartData) return '2.7x';
		const roasData = performanceChartData.others;
		const average = roasData.reduce((sum, val) => sum + val, 0) / roasData.length;
		return !data ? '0x' : `${numberFormatter.formatNumber(average, 2)}x`;
	}, [performanceChartData])

	return (
		<div className="crc-alternative-view no-border">
			<div className="crc-trend-controls">
				<div className="crc-trend-selectors">
					<div className="crc-trend-selector-group">
						<span className="crc-trend-selector-label">Attribution</span>
						<select
							value={selectedAttributionWindow}
							onChange={(e) => setSelectedAttributionWindow(e.target.value)}
							className="crc-trend-metric-dropdown"
						>
							{attributionWindowOptions.map(option => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>
			<div className={(roasVisible && sharesVisible) ? 'crc-dual-charts-container' : 'crc-single-chart'}>
				{roasVisible && (
					<div className="crc-chart-wrapper chart-with-border">
						<div className="crc-chart-header">
							<h3 className="crc-chart-title">Return on Ad Spend (ROAS)</h3>
							<div className="crc-chart-stats">
								<div className="crc-stat-item">
									<span className="crc-stat-label">Avg. AI Ads</span>
									<span className="crc-stat-value">
										{avgEnhRoas}
									</span>
								</div>
								<div className="crc-stat-item">
									<span className="crc-stat-label">Avg. Others</span>
									<span className="crc-stat-value" style={{ color: '#94959b' }}>
										{avgOthersRoas}
									</span>
								</div>
							</div>
						</div>
						<div className="crc-single-chart">
							<DualLineChart
								title="Performance"
								data={performanceChartData}
								labels={labels}
								prefix=""
								suffix="x"
							/>
						</div>
					</div>
				)}
				{sharesVisible && (
					<div className="crc-chart-wrapper chart-with-border">
						<div className="crc-chart-header">
							<div className="row">
								<h3 className="crc-chart-title">% Shares of AI Ads</h3>
								<div className="crc-mini-info">
									<div className="crc-mini-info-icon">
										<FaInfoCircle />
									</div>
									<div className="crc-mini-info-tooltip">
										Shows the percentage of total ad spend and sales allocated to Enhencer campaigns over time
									</div>
								</div>
							</div>
							<div className="crc-chart-stats">
								<div className="crc-stat-item">
									<span className="crc-stat-label">Avg. Total Sales</span>
									<span className="crc-stat-value">
										{(() => {
											const salesData = salesChartData.enhencer;
											const average = salesData.reduce((sum, val) => sum + val, 0) / salesData.length;
											return `%${numberFormatter.formatNumber(average, 1)}`;
										})()}
									</span>
								</div>
								<div className="crc-stat-item">
									<span className="crc-stat-label">Avg. Ad Spend</span>
									<span className="crc-stat-value" style={{ color: '#f8b724' }}>
										{(() => {
											const adSpendData = adSpendChartData.enhencer;
											const average = adSpendData.reduce((sum, val) => sum + val, 0) / adSpendData.length;
											return `%${numberFormatter.formatNumber(average, 1)}`;
										})()}
									</span>
								</div>
							</div>
						</div>

						<div className="crc-single-chart">
							<GenericDualLineChart
								title="Ad Spend"
								salesChartData={salesChartData.enhencer}
								adSpendChartData={adSpendChartData.enhencer}
								labels={labels}
								prefix=""
								suffix="%"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};


export default EnhencerVsOthersView