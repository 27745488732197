import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useConstants } from '../../../shared/hooks/constants-hook'
import { useAdmin } from '../../../shared/hooks/admin-hook'

import Checkbox from '../../../shared/components/FormElements/Checkbox'
import Tooltip from '../../../shared/components/UIElements/Tooltip'
import DatePickerWithPresets from '../../../shared/components/UIElements/DatePickerWithPresets'

import './FilterGroup.css'
import HourRangePicker from '../../../shared/components/UIElements/HourRangePicker'

const FilterGroup = props => {
  const { page, category, handleFilterChange, tempFilter } = props
  const { privateConstants: constants } = useConstants()
  const { convertManagerNameToId } = useAdmin()
  let headers

  const [hasSelectedFilter, setHasSelectedFilter] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [showAll, setShowAll] = useState(false)
  const ITEMS_TO_SHOW = 10

  const {
    panelSummary,
    performanceManagers,
    accountManagers,
    salesPeople,
    overdueAlarmCounts,
    noAlarmUserCounts,
    teamInfo,
  } = useSelector(state => state.admin)
  const [summaryExtras, setSummaryExtras] = useState({})

  const performanceManagersArray = Object.values(performanceManagers)
  const accountManagersArray = Object.values(accountManagers)
  const salesPeopleArray = Object.values(salesPeople ?? {})
  const agenciesArray = Array.isArray(teamInfo.partnerOptions) ? teamInfo.partnerOptions : []
  let namesOfPerformanceManagers = performanceManagersArray.map(
    manager => manager.name,
  )
  let namesOfAccountManagers = accountManagersArray.map(manager => manager.name)
  let namesOfSalesPeople = salesPeopleArray.map(manager => manager.name)
  let namesOfAgencies = agenciesArray.map(agency => agency)
  namesOfPerformanceManagers.push('<Not set>')
  namesOfAccountManagers.push('<Not set>')
  namesOfSalesPeople.push('<Not set>')
  namesOfAgencies.push('<Not set>')
  if (page === 'demoMeetings') {
    namesOfAccountManagers.push('Others')
  }

  switch (page) {
    case 'customers':
      headers = constants.performanceFilters
      break
    case 'signUps':
      headers = constants.signUpFilters
      break
    case 'demoMeetings':
      headers = constants.demoMeetingFilters
      break
    case 'alarmCalendar':
      headers = constants.alarmsCalendarFilters
      break
    case 'supportTickets':
      headers = constants.ticketFilters
      break
    case 'agency':
      headers = constants.agencyFilters
      break
    default:
      break
  }
  // Find the filter object based on the category key
  const filterObj = headers?.find(header => header.key === category)

  const getColorForValue = value => {
    switch (value) {
      case 'Onboarding':
        return '#F1B623'
      case 'No Access':
        return '#4269F1'
      case 'Bad':
        return '#E20C0C'
      case 'Good':
        return '#46B680'
      case 'No Status':
        return '#464A4B'
      default:
        return '#464A4B'
    }
  }

  const getSummaryExtra = value => {
    return summaryExtras[value] != undefined ? ' - ' + summaryExtras[value] : ''
  }

  const showPercent = (val, panelSummary, invert = false) => {
    return (
      (
        Math.abs((invert ? 1 : 0) - panelSummary[val] / panelSummary['count']) *
        100
      ).toFixed(1) + '%'
    )
  }
  const getFontWeight = () => {
    if (filterObj?.name === 'Performance Status') return 600
  }

  const summaryMapping = {
    Country: {
      TR: 'country_tr',
      'USA & Canada': 'country_usca',
      UK: 'country_uk',
      Australia: 'country_au',
      Germany: 'country_de',
      France: 'country_fr',
      Other: 'country_other',
    },
    'Account Manager': {
      '<Not set>': 'accmanager_notset',
      '<id>': 'accmanager',
    },
    'Performance Manager': {
      '<Not set>': 'perfmanager_notset',
      '<id>': 'perfmanager',
    },
    'Sales Person': {
      '<Not set>': 'salesperson_notset',
      '<id>': 'salesperson',
    },
    'Lead Type': {
      Outbound: 'lead_outbound',
      'Facebook / Instagram': 'lead_faceinsta',
      Google: 'lead_google',
      Shopify: 'lead_shopify',
      LinkedIn: 'lead_linkedin',
      Email: 'lead_email',
    },
    'Performance Status': {
      'No Status': 'perfstatus_nostatus',
      Onboarding: 'perfstatus_onboarding',
      Bad: 'perfstatus_bad',
      Good: 'perfstatus_good',
      'No Access': 'perfstatus_noaccess',
      'No Touch': 'perfstatus_notouch',
    },
    Platform: {
      Shopify: 'platformShopifyCount',
      Other: '-platformShopifyCount',
    },
    'Audience Network': {
      Yes: 'anCount',
      No: '-anCount',
    },
    'AI Ads': {
      Yes: 'aiAdsCount',
      No: '-aiAdsCount',
    },
    Paused: {
      Yes: 'pausedCount',
      No: '-pausedCount',
    },
    'Usage Charges': {
      Yes: 'usageChargesCount',
      No: '-usageChargesCount',
    },
    'Partner': {
      '<id>': 'agency',
    },
  }


  const shortenPersonName = (name) => {
    if (name && name.length > 10 && name.includes(" ")) {
      let splittedName = name.split(" ")
      if (splittedName.length > 2) {
        return `${splittedName[0]} ${splittedName[1][0]}.${splittedName[2][0]}.`
      } else {
        return `${splittedName[0]} ${splittedName[1][0]}.`
      }
    }
    return name
  }

  useEffect(() => {
    if (panelSummary) {
      for (const groupName in summaryMapping) {
        const groupMap = summaryMapping[groupName]
        if (filterObj?.name === groupName) {
          const resultSummaryExtras = {}
          Object.keys(groupMap).forEach(filterElementName => {
            const nameValue = groupMap[filterElementName]
            if (nameValue.startsWith('-')) {
              //invert the percent
              resultSummaryExtras[filterElementName] = showPercent(
                nameValue.split('-')[1],
                panelSummary,
                true,
              )
            } else {
              resultSummaryExtras[filterElementName] = showPercent(
                nameValue,
                panelSummary,
              )
            }

            if (filterElementName === '<id>') {
              for (const key in panelSummary[nameValue]) {
                if (nameValue === 'agency') {
                  resultSummaryExtras[key] = panelSummary[nameValue][key]
                } else {
                  resultSummaryExtras[key] = (panelSummary[nameValue][key] / panelSummary['count'] * 100).toFixed(1) + '%'
                }
              }
              delete resultSummaryExtras["<id>"]
            }
          })
          setSummaryExtras(resultSummaryExtras)
        }
      }
    }
  }, [panelSummary])

  useEffect(() => {
    const thisCategoryFilter = tempFilter[category]
    if (Array.isArray(thisCategoryFilter) && tempFilter[category].length) {
      setHasSelectedFilter(true)
    } else {
      setHasSelectedFilter(false)
    }
  }, [tempFilter])

  const renderCheckboxes = (
    filterObj,
    category,
    tempFilter,
    handleFilterChange,
    getColorForValue,
    getFontWeight,
    getSummaryExtra,
  ) => {
    // Determine items to map based on filterObj key
    const getItemsToMap = () => {
      const keyToItemsMap = {
        'performanceManager': namesOfPerformanceManagers,
        'accountManager': namesOfAccountManagers,
        'salesPerson': namesOfSalesPeople,
        'agency': namesOfAgencies,
      }
      return keyToItemsMap[filterObj.key] || filterObj.values
    }

    const allItems = getItemsToMap()
    const filteredItems = searchTerm
      ? allItems.filter(value => value.toLowerCase().includes(searchTerm.toLowerCase()))
      : allItems

    // Get manager ID if applicable
    const getManagerId = (value) => {
      const isManagerCategory = ['performanceManager', 'accountManager', 'salesPerson'].includes(category)
      return (isManagerCategory && value !== '<Not set>')
        ? convertManagerNameToId(value).found
        : value
    }

    // Sort items by summary extras if there are more items than ITEMS_TO_SHOW
    const sortedItems = filteredItems.length > ITEMS_TO_SHOW
      ? [...filteredItems].sort((a, b) => {
          const aManagerId = getManagerId(a)
          const bManagerId = getManagerId(b)
          const aExtra = summaryExtras[aManagerId]
          const bExtra = summaryExtras[bManagerId]
          
          // Convert percentage strings to numbers for comparison
          const aValue = aExtra ? parseFloat(aExtra) : 0
          const bValue = bExtra ? parseFloat(bExtra) : 0
          
          // Sort in descending order (highest percentage first)
          return bValue - aValue
        })
      : filteredItems

    const itemsToDisplay = showAll ? sortedItems : sortedItems.slice(0, ITEMS_TO_SHOW)
    const hasMoreItems = sortedItems.length > ITEMS_TO_SHOW

    // Generate checkbox label based on page and category
    const getCheckboxLabel = (value, managerId) => {
      const isManagerCategory = ['performanceManager', 'accountManager', 'agency', 'salesPerson'].includes(category)
      if (!isManagerCategory) return value

      const baseLabel = shortenPersonName(value)
      const extras = getSummaryExtra(managerId)

      const noAlarmCount = noAlarmUserCounts?.[managerId]
        ? ` (💤 ${noAlarmUserCounts[managerId]})`
        : ''

      const overdueCount = page === 'alarmCalendar' && value !== '<Not set>' && overdueAlarmCounts?.[managerId]
        ? ` (⏰ ${overdueAlarmCounts[managerId]})`
        : ''

      const pageSpecificCount = {
        'customers': category === 'performanceManager' ? noAlarmCount : '',
        'signUps': category === 'salesPerson' ? noAlarmCount : '',
        'demoMeetings': category === 'salesPerson' ? noAlarmCount : '',
        'alarmCalendar': overdueCount
      }

      return `${baseLabel}${extras}${pageSpecificCount[page] || ''}`
    }

    // Check if checkbox should be checked
    const isChecked = (value, managerId) => {
      const isManagerCategory = ['performanceManager', 'accountManager', 'salesPerson'].includes(category)
      const isSpecialValue = value === '<Not set>' || value === 'Others'

      return isManagerCategory && !isSpecialValue
        ? tempFilter[category]?.includes(managerId)
        : tempFilter[category]?.includes(value)
    }

    return (
      <>
        {allItems.length > ITEMS_TO_SHOW && (
          <div className="filter-search">
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="filter-search-input"
              />
              {searchTerm && (
                <button
                  className="clear-search-button"
                  onClick={() => setSearchTerm('')}
                  aria-label="Clear search"
                >
                  ×
                </button>
              )}
            </div>
          </div>
        )}
        {itemsToDisplay.map((value, index) => {
          const managerId = getManagerId(value)

          return (
            <Tooltip
              size="sm"
              key={value}
              content={filterObj.explanations?.[index] || ''}
              offset={5}
            >
              <Checkbox
                name={category}
                value={value}
                label={getCheckboxLabel(value, managerId)}
                checked={isChecked(value, managerId)}
                color={getColorForValue(value)}
                fontWeight={getFontWeight()}
                onChange={event => {
                  handleFilterChange({
                    groupName: category,
                    value: event.target.value,
                    type: 'checkbox',
                    checked: !isChecked(value, managerId)
                  })
                }}
              />
            </Tooltip>
          )
        })}
        {hasMoreItems && !searchTerm && (
          <button
            className="show-more-button"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Show Less' : `Show More (${sortedItems.length - ITEMS_TO_SHOW} more)`}
          </button>
        )}
      </>
    )
  }
  if (page === 'alarmCalendar' && (!overdueAlarmCounts || !noAlarmUserCounts)) {
    return null
  }

  return (
    <div
      className={`filter-group ${hasSelectedFilter ? 'is-active' : ''
        } ${filterObj?.name.toLowerCase().replaceAll(' ', '')}`}
    >
      {filterObj?.type === 'checkbox' ? (
        <div className="checkboxes">
          <h2>{filterObj?.name}</h2>
          {renderCheckboxes(
            filterObj,
            category,
            tempFilter,
            handleFilterChange,
            getColorForValue,
            getFontWeight,
            getSummaryExtra,
          )}
        </div>
      ) : filterObj?.type === 'range' ? (
        <div className="range">
          <h2>{filterObj?.name}</h2>
          <div className="inputs-wrapper">
            {filterObj?.inputs.map(input => {
              return (
                <div className="input" key={input.key}>
                  <label>{input.name}</label>
                  <input
                    type={input.type}
                    min={input.minValue}
                    value={tempFilter[category][input.key]}
                    name={`${filterObj?.key}.${input.key}`}
                    onChange={event => {
                      handleFilterChange({
                        groupName: `${filterObj?.key}.${input.key}`,
                        value: event.target.value,
                        type: 'range',
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ) : filterObj?.type === 'date-range' ? (
        <div className="date-range">
          <Tooltip
            size="sm"
            key={filterObj?.name}
            content={filterObj.explanations[0]}
          >
            <h2>{filterObj?.name}</h2>
          </Tooltip>
          <DatePickerWithPresets
            filter={tempFilter[category]}
            setFilters={newFilter => {
              handleFilterChange({
                groupName: `${filterObj?.key}`,
                value: newFilter,
                type: 'date-range',
              })
            }}
          />
        </div>
      ) : filterObj?.type === 'hour-range' ? (
        <div className="hour-range">
          <Tooltip
            size="sm"
            key={filterObj?.name}
            content={filterObj.explanations[0]}
          >
            <h2>{filterObj?.name}</h2>
          </Tooltip>
          <HourRangePicker
            filter={tempFilter[category]}
            setFilters={newFilter => {
              handleFilterChange({
                groupName: `${filterObj?.key}`,
                value: newFilter,
                type: 'hour-range',
              })
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default FilterGroup
