import { createSlice } from '@reduxjs/toolkit'
import helper from '../shared/util/helper';
import CreativeTypes from '../shared/enums/CreativeTypes'
import CampaignFormDisplayTypes from '../shared/enums/CampaignFormDisplayTypes';


class AdCreative extends Object {
  id = null
  type = null
  details = {
    primaryText: "",
    websiteUrl: "",
    catalog: null,
    productSet: null,
    facebookPage: null,
    instagramAccount: null,
  }
  body = null
  isValid = false

  // below are for updating the creative in list
  isEdited = false
  existsInListWithId = null
}


class FullFunnel extends Object {
  id;
  label = "New campaign";
  isLabelValid = true;
  isLabelDuplicate = false;

  targeting = {
    countries: [],
    genders: "All",
    age: {
      min: 18,
      max: 65
    },
    dailyBudget: null,
    isDailyBudgetMoreThanMinimum: false
  };

  currentCreative = JSON.parse(JSON.stringify(new AdCreative()));
  adCreatives = {};
  trafficCampaign = {
    isActive: true,
  }
  isPublishable;

  constructor(fullFunnel) {
    super(fullFunnel)
    if (fullFunnel) {
      this.id = fullFunnel._id
      this.label = fullFunnel.label
      this.isLabelValid = true
      this.isLabelDuplicate = false
      this.targeting = {
        countries: fullFunnel.countries,
        genders: helper.getGenderString(fullFunnel.genders),
        age: fullFunnel.age,
        dailyBudget: fullFunnel.dailyBudget,
        isDailyBudgetMoreThanMinimum: true
      }

      if (fullFunnel.adCreatives) {
        // new version fullFunnel is being edited
        let editableCreatives = { ...fullFunnel.adCreatives }
        this.adCreatives = fullFunnel.adCreatives
        this.currentCreative = {
          ...Object.values(this.adCreatives)[0],
          existsInListWithId: Object.values(this.adCreatives)[0].id
        }
      } else {
        // if fullFunnel exists but is old version
        let newCreativeObj = {
          id: fullFunnel.adCreative.id,
          type: CreativeTypes.CATALOG,
          details: {
            primaryText: fullFunnel.primaryText,
            websiteUrl: fullFunnel.websiteUrl,
            catalog: fullFunnel.catalog,
            productSet: fullFunnel.productSet,
            facebookPage: fullFunnel.facebookPage ?? fullFunnel.page,
            instagramAccount: fullFunnel.instagramAccount,
          },
          body: {
            id: fullFunnel.adCreative.id
          },
          isValid: true,
        }

        this.currentCreative = newCreativeObj
        this.adCreatives = {
          [newCreativeObj.id]: newCreativeObj
        }
      }

      this.isPublishable = true // check this part, not used for now
    }
  }
}

const initialLoadings = {
  isFetchingCatalogs: false,
  isFetchingProductSets: false,
  isFetchingFacebookPages: false,
  isFetchingInstagramAccounts: false,
  isProcessingTextFields: false,
  isCreatingCreative: false
}

const initialAdTextGeneratorChat = {
  isRegenerating: false,
  chatId: null,
  messages: [],
  informal: [],
  formal: [],
  fomo: [],
  isInformalLoading: true,
  isFormalLoading: true,
  isFomoLoading: true
}

const initialState = {
  initialCampaign: null,
  currentCampaign: null,
  activeDisplay: CampaignFormDisplayTypes.TARGETING,
  loadings: initialLoadings,
  adTextGeneratorChat: initialAdTextGeneratorChat,
  hasHighPerformingCreatives: false
}

const facebookCampaignEditorSlice = createSlice({
  name: 'facebookCampaignEditor',
  initialState,
  reducers: {
    initializeCampaignEditor(state, action) {
      let newCampaign = new FullFunnel(action.payload.fullFunnel)
      state.initialCampaign = JSON.parse(JSON.stringify(newCampaign))
      state.currentCampaign = JSON.parse(JSON.stringify(newCampaign))
    },
    initializeEmptyCampaign(state, action) {
      state.initialCampaign = JSON.parse(JSON.stringify(new FullFunnel()))
      state.currentCampaign = JSON.parse(JSON.stringify(new FullFunnel()))
    },
    endCampaignEditing(state, action) {

      state.initialCampaign = null;
      state.currentCampaign = null;
      state.loadings = initialLoadings
    },
    changeLabel(state, action) {
      state.currentCampaign.label = action.payload.label
      state.currentCampaign.isLabelValid = action.payload.isValid
      state.currentCampaign.isLabelDuplicate = action.payload.isDuplicate
    },
    setLabelValidity(state, action) {
      state.currentCampaign.isLabelValid = action.payload
    },
    changeActiveDisplay(state, action) {
      state.activeDisplay = action.payload
    },
    changeTargeting(state, action) {
      Object.keys(action.payload).forEach(field => {
        if (action.payload[field] !== undefined && state.currentCampaign) state.currentCampaign.targeting[field] = action.payload[field]
      })

    },
    changeCountries(state, action) {
      state.currentCampaign.targeting.countries = action.payload
    },
    selectCreativeFromAdCreativesList(state, action) {
      state.currentCampaign.currentCreative = {
        ...action.payload,
        existsInListWithId: action.payload.id,
        isEdited: false
      }
    },
    emptyCurrentCreative(state, action) {
      state.currentCampaign.currentCreative = JSON.parse(JSON.stringify(new AdCreative()));
    },
    updateCurrentCreative(state, action) {
      Object.keys(action.payload).forEach(field => {
        if (action.payload[field] !== undefined && state.currentCampaign) state.currentCampaign.currentCreative[field] = action.payload[field]
      })
    },
    addCreativeToAdCreativesList(state, action) {
      let newList = {
        ...state.currentCampaign.adCreatives,
        [action.payload.id]: action.payload
      }

      state.currentCampaign.adCreatives = newList
      state.currentCampaign.currentCreative.name = action.payload.name
      state.currentCampaign.currentCreative.existsInListWithId = action.payload.id

    },
    editCreativeInList(state, action) {
      let { newCreative, oldCreativeIdToRemove } = action.payload
      let newList = {
        ...state.currentCampaign.adCreatives,
        [newCreative.id]: newCreative
      }
      delete newList[oldCreativeIdToRemove]

      state.currentCampaign.adCreatives = newList
      state.currentCampaign.currentCreative = newCreative
    },
    removeCreativeFromAdCreativesList(state, action) {
      let newList = { ...state.currentCampaign.adCreatives }
      delete newList[action.payload.id]

      state.currentCampaign.adCreatives = newList
      state.currentCampaign.currentCreative = JSON.parse(JSON.stringify(new AdCreative()));

    },
    setNewCatalog(state, action) {
      state.currentCampaign.currentCreative.details.catalog = action.payload
    },
    setNewProductSet(state, action) {
      state.currentCampaign.currentCreative.details.productSet = action.payload
    },
    setNewFacebookPage(state, action) {
      state.currentCampaign.currentCreative.details.facebookPage = action.payload
    },
    setNewInstagramAccount(state, action) {
      state.currentCampaign.currentCreative.details.instagramAccount = action.payload
    },
    startLoadingState(state, action) {
      state.loadings[action.payload] = true
    },
    endLoadingState(state, action) {
      state.loadings[action.payload] = false
    },
    setIsPreviewUpToDate(state, action) {
      // TODO: check this
      // state.currentCampaign.currentCreative.isPreviewUpToDate = action.payload
    },
    editTrafficCampaign(state, action) {
      state.currentCampaign.trafficCampaign = action.payload
    },
    setAllAdTextTypes(state, action) {
      state.adTextGeneratorChat.chatId = action.payload.chatId
      state.adTextGeneratorChat.messages = action.payload.messages
      state.adTextGeneratorChat.informal = action.payload.informal
      state.adTextGeneratorChat.formal = action.payload.formal
      state.adTextGeneratorChat.fomo = action.payload.fomo
      
      state.adTextGeneratorChat.isInformalLoading = false
      state.adTextGeneratorChat.isFormalLoading = false
      state.adTextGeneratorChat.isFomoLoading = false
    },
    addNewAdTextToType(state, action) {
      console.log(action.payload)
      state.adTextGeneratorChat[action.payload.type].push(action.payload.text)
      state.adTextGeneratorChat.messages = action.payload.messages
      const type = action.payload.type.charAt(0).toUpperCase() + action.payload.type.slice(1)
      state.adTextGeneratorChat[`is${type}Loading`] = false

      sessionStorage.setItem(`adTextGeneratorChat_${action.payload.userId}`, JSON.stringify(state.adTextGeneratorChat))
    },
    startRegeneratingAdText(state, action) {
      const type = action.payload.charAt(0).toUpperCase() + action.payload.slice(1)
      state.adTextGeneratorChat[`is${type}Loading`] = true
    },
    setHasHighPerformingCreatives(state, action) {
      state.hasHighPerformingCreatives = action.payload
    }

  },
})

export const facebookCampaignEditorActions = facebookCampaignEditorSlice.actions

export default facebookCampaignEditorSlice.reducer