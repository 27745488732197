import { useDispatch, useSelector } from 'react-redux'
import { useModal } from '../../hooks/modal-hook'
import { useFacebookReport } from './report-hook'
import { toast } from 'react-toastify'
import { facebookActions } from '../../../store/facebook'
import Modals from '../../enums/Modals'
import { useFacebookCampaign } from './campaign-hook'
import { useHttpClient } from '../http-hook'
import errorHandler from '../../util/errorHandler'
import { useFacebookError } from './error-hook'

const campaignTypes = [
    'conversion_remarketing',
    'conversion_new_user',
    'advantage_plus',
    'awareness',
    'traffic_to_website',
    'traffic_to_social_media'
]

const conversionCampaignTypes = [
    'conversion_remarketing',
    'conversion_new_user',
    'advantage_plus'
]

const boostCampaignTypes = [
    'awareness',
    'traffic_to_website',
    'traffic_to_social_media'
]

export const useFullFunnel = () => {
    const { openModal } = useModal()
    const dispatch = useDispatch()
    const { sendRequest } = useHttpClient()
    const { fetchReportsForSingleFullFunnel } = useFacebookReport()
    const {
        createConversionRemarketingCampaign,
        createConversionNewUserCampaign,
        createAdvantagePlusCampaign,
        createAwarenessCampaign,
        createTrafficCampaign,
        createTrafficToInstagramCampaign
    } = useFacebookCampaign()
    const { handleError } = useFacebookError()

    const campaignCreation = useSelector(state => state.facebook.campaignCreation)
    const fullFunnels = useSelector(state => state.facebook.fullFunnels)
    const fetchingReports = useSelector(state => state.facebook.fetchingReports)
    const selectedAdAccountId = useSelector(state => state.facebook.selectedAdAccount?.id)
    const selectedBusinessId = useSelector(state => state.facebook.selectedBusiness?.id)
    const facebookAuth = useSelector(state => state.facebook.auth)
    const facebookAudiences = useSelector(state => state.facebook.audiences)

    const userCountry = useSelector(state => state.auth.user?.country)
    const dataCollectionStarted = useSelector(state => state.auth.user?.connectFlow?.dataCollectionStarted)
    const enhencerCategories = useSelector(state => state.auth.user?.enhencerCategories)
    const userId = useSelector(state => state.auth.user?.id)
    const adminToken = useSelector(state => state.auth.adminToken)
    const authToken = useSelector(state => state.auth.token)

    const authHeader = {
        Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
    }

    const createFullFunnel = async ({
        label,
        dailyBudget,
        age,
        countries,
        countryCodes,
        genders,
        adCreatives,
        shouldRecreateCampaign,
        campaignIdToBeRemoved,
        boostBudget,
        retryType
    }) => {

        try {
            if (shouldRecreateCampaign) {
                await deleteFullFunnel({
                    fullFunnelId: campaignIdToBeRemoved,
                    isBackgroundTask: true,
                })
            }

            const data = {
                userId: userId,
                country: userCountry,
                userEnhencerCategories: enhencerCategories,
                label,
                accessToken: facebookAuth.accessToken,
                adAccountId: selectedAdAccountId,
                businessName: selectedBusinessId,
                dailyBudget: parseFloat(dailyBudget),
                age,
                countries,
                countryCodes,
                adCreatives,
                genders,
                boostBudget,
                dataCollectionStarted
            }
            if (retryType && campaignCreation.fullFunnelId) {
                data.fullFunnelId = campaignCreation.fullFunnelId
            }

            // if retryType is provided, only create the specified campaign
            if (retryType) {
                switch (retryType) {
                    case 'conversionRemarketing':
                        return await createConversionRemarketingCampaign(data, authHeader);
                    case 'conversionNewUser':
                        return await createConversionNewUserCampaign(data, authHeader);
                    case 'advantagePlus':
                        return await createAdvantagePlusCampaign(data, authHeader);
                    case 'awareness':
                        return await createAwarenessCampaign(data, authHeader);
                    case 'traffic':
                        return await createTrafficCampaign(data, authHeader);
                    case 'trafficToInstagram':
                        return await createTrafficToInstagramCampaign(data, authHeader);
                    default:
                        throw new Error('Invalid retry campaign type');
                }
            }




            const res = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/facebook/createFullFunnel`,
                'POST',
                JSON.stringify(data),
                authHeader,
            )
            console.log("create full funnel res", res)
            const fullFunnelId = res.fullFunnelId
            const newFullFunnel = { ...res.fullFunnel, adCampaigns: [] }

            dispatch(facebookActions.setCampaignCreationStatus({
                type: 'fullFunnelId',
                data: fullFunnelId
            }))

            if (res.error) {
                return {
                    hasError: true,
                    message: res.error.message || 'Campaign creation failed'
                }
            }

            data.fullFunnelId = fullFunnelId
            console.log("campaign creation data", data)

            // normal flow - create all campaigns
            let conversionRemarketingCampaignResult = await createConversionRemarketingCampaign(data, authHeader);
            if (!conversionRemarketingCampaignResult.error) newFullFunnel.adCampaigns.push(conversionRemarketingCampaignResult.adCampaign)
            
            let conversionNewUserCampaignResult = await createConversionNewUserCampaign(data, authHeader);
            if (!conversionNewUserCampaignResult.error) newFullFunnel.adCampaigns.push(conversionNewUserCampaignResult.adCampaign)
            
                let advantagePlusCampaignResult = await createAdvantagePlusCampaign(data, authHeader);
            if (!advantagePlusCampaignResult.error) newFullFunnel.adCampaigns.push(advantagePlusCampaignResult.adCampaign)

            if (boostBudget) {
                let awarenessCampaignResult = await createAwarenessCampaign(data, authHeader);
                if (!awarenessCampaignResult.error) newFullFunnel.adCampaigns.push(awarenessCampaignResult.adCampaign)
                let trafficCampaignResult = await createTrafficCampaign(data, authHeader);
                if (!trafficCampaignResult.error) newFullFunnel.adCampaigns.push(trafficCampaignResult.adCampaign)
                let trafficToInstagramCampaignResult = await createTrafficToInstagramCampaign(data, authHeader);
                if (!trafficToInstagramCampaignResult.error) newFullFunnel.adCampaigns.push(trafficToInstagramCampaignResult.adCampaign)
            }



            if (res.fullFunnel) {
                res.fullFunnel.reports = {
                    adsetLevel: {},
                    campaignLevel: {},
                    funnelLevel: {}
                }

                dispatch(facebookActions.newFullFunnelAdded(newFullFunnel))
            }

            return {
                hasError: false,
                message: 'success',
                fullFunnel: newFullFunnel
            }

        } catch (err) {
            console.error('Campaign creation error:', err)
            return {
                hasError: true,
                message: err.message || 'Campaign creation failed'
            }
        }
    }

    const fetchNewlyCreatedFullFunnel = async ({
        fullFunnelId = campaignCreation.fullFunnelId
    }) => {
        let res = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/facebook/getFullFunnel`,
            'POST',
            JSON.stringify({
                fullFunnelId,
                userId: userId,
            }),
            authHeader
        )

        const fullFunnel = res.fullFunnel

        const reports = await fetchReportsForSingleFullFunnel({
            fullFunnel: fullFunnel,
            fullFunnelId,
            fetchAdCampaignDetails: true
        })

        fullFunnel.reports = reports
        dispatch(facebookActions.fullFunnelUpdated({
            fullFunnel: fullFunnel,
            id: fullFunnelId,
            isInView: true
        }))
    }

    const updateFullFunnel = async ({
        fullFunnelId,
        campaignName,
        label,
        dailyBudget,
        age,
        countries,
        countryCodes,
        genders,
        adCampaign,
        adsets,
        ads,
        adCreatives,
        boostBudget
    }) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/api/facebook/updateFullFunnel`

        try {
            let res = await sendRequest(
                url,
                'POST',
                JSON.stringify({
                    fullFunnelId,
                    campaignName,
                    userId: userId,
                    userEnhencerCategories: enhencerCategories,
                    label,
                    accessToken: facebookAuth.accessToken,
                    adAccountId: selectedAdAccountId,
                    dailyBudget: parseFloat(dailyBudget),
                    age,
                    genders,
                    countries,
                    countryCodes,
                    adCampaign,
                    adsets,
                    ads,
                    adCreatives,
                }),
                authHeader,
            )

            if (res.error) {
                throw new Error(res.error.message || 'Failed to update campaign')
            }

            dispatch(
                facebookActions.fullFunnelUpdated({
                    fullFunnel: res.fullFunnel,
                    id: fullFunnelId,
                    isInView: true
                }),
            )

            return true
        } catch (err) {
            console.error('Error updating campaign:', err)
            errorHandler(err)
            return false
        }
    }

    const updateFullFunnelStatus = async ({
        fullFunnelId,
        status
    }) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/updateFullFunnelStatus`

        let res = await sendRequest(
            url,
            'POST',
            JSON.stringify({
                userId: userId,
                accessToken: facebookAuth.accessToken,
                fullFunnelId,
                status: status,
            }),
            authHeader,
        )

        if (res.error) {
            let modalConfig = {
                title: res.error.error_user_title || 'Error Occured',
                imageUrl:
                    'https://cdn.enhencer.com/website-assets/images/icon-set/error.png',
                text: res.error.error_subcode === 1487566 ? 'This campaign has been deleted, so you can only edit the name.' : `${res.error.error_user_msg
                    ? res.error.error_user_msg
                    : res.error.message
                    }`,
                actions: [
                    {
                        text: 'Close',
                        callback() { },
                    },
                ],
            }
            openModal({
                type: Modals.GENERIC_MODAL,
                data: modalConfig,
            })
            return false
        } else {

            dispatch(facebookActions.updateFullFunnelEffectiveStatuses({
                [fullFunnelId]: status
            }))

            sessionStorage.setItem("updatedStatusesRecently", "true")

            fetchReportsForSingleFullFunnel({
                fullFunnel: res.fullFunnel,
                fullFunnelId,
                fetchAdCampaignDetails: true,
                caching: false
            })
            return true
        }
    }

    const deleteFullFunnel = async ({
        id,
        isBackgroundTask,
    }) => {
        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/facebook/removeFullFunnel`,
                'POST',
                JSON.stringify({
                    userId: userId,
                    fullFunnelId: id,
                    accessToken: facebookAuth.accessToken,
                }),
                authHeader,
            )

            dispatch(facebookActions.fullFunnelDeleted(id))
            if (!isBackgroundTask) {
                toast.success('Campaign deleted successfully!')
            }
        } catch (err) {
            handleError(err)
        }
    }

    const addNewFullFunnelFromExistingCampaigns = async ({
        label,
        campaignIds,
    }) => {
        try {
            let res = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/admin/createFullFunnelFromExistingCampaigns`,
                'POST',
                JSON.stringify({
                    userId,
                    label,
                    campaignIds,
                }),
                {
                    ...authHeader,
                    Authorization: 'Bearer ' + adminToken,
                },
            )

            if (res.error) {
                throw new Error(res.error.message || 'Failed to add new full funnel from existing campaigns')
            }

            return res.fullFunnel
        } catch (err) {
            console.error('Error adding new full funnel from existing campaigns:', err)
            throw err
        }
    }

    const newFullFunnelSelected = async ({ fullFunnelId, accessToken = facebookAuth.accessToken, reportLevel = 'campaign' }) => {
        if (fetchingReports) return

        dispatch(facebookActions.setFullFunnelInView(fullFunnelId))
        dispatch(facebookActions.setFullFunnelReportsReady(false))

        if (fullFunnelId === 'allFullFunnels') {
            await Promise.all(fullFunnels.map(async (fullFunnel) => {
                await fetchReportsForSingleFullFunnel({
                    fullFunnelId: fullFunnel._id,
                    filter: { type: "date_preset", value: "last_14d" },
                    accessToken
                })
            }))
            dispatch(facebookActions.setFullFunnelReportsReady(true))
        } else {
            await fetchReportsForSingleFullFunnel({
                fullFunnelId: fullFunnelId,
                filter: { type: "date_preset", value: "last_14d" },
                accessToken
            })
            dispatch(facebookActions.setFullFunnelReportsReady(true))
        }
    }


    const checkIfFullFunnelHasMissingCampaigns = ({
        fullFunnelId
    }) => {
        const fullFunnel = fullFunnels.find(fullFunnel => fullFunnel._id === fullFunnelId)
        if (!fullFunnel || fullFunnel.createdOnFacebookPanel) return

        const existingCampaignTypes = fullFunnel.adCampaigns.map(campaign => campaign.type)
        let missingCampaignTypes = []
        
        if (fullFunnel.boostBudget) {
            let missingBoostCampaignTypes = boostCampaignTypes.filter(campaignType => !existingCampaignTypes.includes(campaignType))
            missingCampaignTypes.push(...missingBoostCampaignTypes)
            console.log("missingBoostCampaignTypes", missingBoostCampaignTypes)
        }

        if (fullFunnel.dailyBudget) {
            let missingConversionCampaignTypes = conversionCampaignTypes.filter(campaignType => !existingCampaignTypes.includes(campaignType))
            missingCampaignTypes.push(...missingConversionCampaignTypes)
            console.log("missingConversionCampaignTypes", missingConversionCampaignTypes)
        }
        
        
        return missingCampaignTypes
    }

    const createMissingCampaignsInFullFunnel = async ({
        fullFunnelId
    }) => {
        const fullFunnel = fullFunnels.find(fullFunnel => fullFunnel._id === fullFunnelId)
        if (!fullFunnel || fullFunnel.createdOnFacebookPanel) return

        const existingCampaignTypes = fullFunnel.adCampaigns.map(campaign => campaign.type)
        const missingCampaignTypes = campaignTypes.filter(campaignType => !existingCampaignTypes.includes(campaignType))
        if (missingCampaignTypes.length === 0) return

        for (const campaignType of missingCampaignTypes) {
            const data = {
                userId: userId,
                country: userCountry,
                userEnhencerCategories: enhencerCategories,
                label: fullFunnel.label,
                accessToken: facebookAuth.accessToken,
                adAccountId: selectedAdAccountId,
                businessName: selectedBusinessId,
                dailyBudget: parseFloat(fullFunnel.dailyBudget),
                age: fullFunnel.age,
                countries: fullFunnel.countries,
                countryCodes: fullFunnel.countryCodes,
                audiences: facebookAudiences,
                adCreatives: fullFunnel.adCreatives,
                genders: fullFunnel.genders,
                boostBudget: fullFunnel.boostBudget,
                dataCollectionStarted
            }

            console.log("campaign type", campaignType, "data", data)

            /* switch (campaignType) {
                case 'conversion_remarketing':
                    await createConversionRemarketingCampaign(data, authHeader);
                    break;
                case 'conversion_new_user':
                    await createConversionNewUserCampaign(data, authHeader);
                    break;
                case 'advantage_plus':
                    await createAdvantagePlusCampaign(data, authHeader);
                    break;
                case 'awareness':
                    if (fullFunnel.boostBudget) await createAwarenessCampaign(data, authHeader);
                    break;
                case 'traffic_to_website':
                    if (fullFunnel.boostBudget) await createTrafficCampaign(data, authHeader);
                    break;
                case 'traffic_to_social_media':
                    if (fullFunnel.boostBudget) await createTrafficToInstagramCampaign(data, authHeader);
                    break;
                default:
                    continue;
            } */
        }
    }

    return {
        createFullFunnel,
        fetchNewlyCreatedFullFunnel,
        updateFullFunnel,
        updateFullFunnelStatus,
        deleteFullFunnel,
        addNewFullFunnelFromExistingCampaigns,
        newFullFunnelSelected,
        checkIfFullFunnelHasMissingCampaigns,
        createMissingCampaignsInFullFunnel
    }
}