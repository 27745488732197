import React from 'react'

import sparklesIcon from '../../../shared/assets/images/icons/sparkles.svg'

import styles from './GenerateCarouselCreativeButton.module.css'

const GenerateCarouselCreativeButton = ({ onClick }) => {
  return (
    <div
      className={styles.generateButton}
      onClick={onClick}
      title="Generate new carousel creative project"
    >
      <div className={styles.icon}>
        <img src={sparklesIcon} alt="Generate" />
      </div>
      <div className={styles.textsContainer}>
        <div className={styles.generateButtonTitle}>Carousel Creative</div>
        <div className={styles.generateButtonDescription}>
          Create AI-powered carousel creative from your selected products
        </div>
      </div>
    </div>
  )
}

export default GenerateCarouselCreativeButton
