import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import Button from '../../../shared/components/FormElements/Button'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'

import styles from './CurrentCreativeCard.module.css'
import useNavigator from '../../../shared/hooks/navigator-hook'

const CurrentCreativeCard = ({ name, products }) => {
  const dispatch = useDispatch()
  const { navigateWithParams, searchParams } = useNavigator()
  const [activePreview, setActivePreview] = useState(
    products[0].isSaved
      ? products[0].preview?.url_256
      : products[0].originalImageUrl,
  )

  const aiCreativeIdInSessionStorage = sessionStorage.getItem('currentAiCreativeId')

  const handleLaunchAd = () => {
    dispatch(aiCreativeEditorActions.setSelectedProducts(products))

    navigateWithParams(`/ai-ads/full-funnel-campaigns${searchParams.campaignId ? '' : '/create'}`, { aiCreativeId: aiCreativeIdInSessionStorage })
  }

  return (
    <div className={styles.currentCreativeCard}>
      <div className={styles.creativeCardTop}>
        <div className={styles.creativeCardDetailsBox}>
          <div className={styles.creativeCardDetilsBoxTexts}>
            <div className={styles.creativeName}>{name}</div>
          </div>
          <div className={styles.creativeCardDetailsBoxActions}>
            {products.every(product => product.isSaved) && aiCreativeIdInSessionStorage && (
              <Button
                primary
                onClick={handleLaunchAd}
                className={styles.launchAdButton}
              >
                Launch Ad
              </Button>
            )}
            <Button
              onClick={() => {
                dispatch(aiCreativeEditorActions.setSelectedProducts(products))
                navigateWithParams('/ai-ads/creative-lab/editor')
              }}
              className={styles.editButton}
            >
              Continue Editing
            </Button>
          </div>
        </div>
        <div className={styles.preview}>
          <img
            className={styles.previewImage}
            src={activePreview}
            alt="Creative Preview"
          />
        </div>
      </div>
      <div className={styles.creativeCardDivider} />
      <div className={styles.previews}>
        {products.map(product => (
          <div
            key={product.id}
            className={styles.previewSmall}
            onMouseOver={() =>
              setActivePreview(
                product.isSaved
                  ? product.preview?.url_256
                  : product.originalImageUrl,
              )
            }
            onMouseLeave={() =>
              setActivePreview(
                products[0].isSaved
                  ? products[0].preview?.url_256
                  : products[0].originalImageUrl,
              )
            }
          >
            <img
              className={styles.previewImageSmall}
              src={
                product.isSaved
                  ? product.preview?.url_256
                  : product.originalImageUrl
              }
              alt="Creative Preview"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CurrentCreativeCard
