import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../../hooks/http-hook'
import { useModal } from '../../hooks/modal-hook'

import 'react-toastify/dist/ReactToastify.css'
import errorHandler from '../../util/errorHandler'

const CustomSubscriptionPlan = props => {
  const { sendRequest } = useHttpClient()
  const { closeModal } = useModal()
  const auth = useSelector(state => state.auth)
  const user = props.data.user
  const [currency, setCurrency] = useState(
    user.subscription && user.subscription.customPlan
      ? props.data.user.subscription.customPlan.currency
      : 'TRY',
  )
  const [amount, setAmount] = useState(
    user.subscription && user.subscription.customPlan
      ? user.subscription.customPlan.price
      : 1000,
  )

  const no = () => {
    closeModal()
  }

  const amountChange = e => {
    setAmount(e.target.value)
  }

  const currencyChange = e => {
    setCurrency(e.target.value)
  }

  const createPlan = async () => {
    let body = {
      price: amount,
      currency: currency,
      name: user.name,
    }
    let url = '/api/payment/createSubscriptionPlan'
    if (user.subscription && user.subscription.customPlan) {
      url = '/api/payment/updateSubscriptionPlan'
      body.refCode = user.subscription.customPlan.refCode
    }

    let result = await sendRequest(url, 'POST', JSON.stringify(body))
    if (result.data.refCode) {
      sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${user.id}`,
        'PATCH',
        JSON.stringify({
          'subscription.customPlan.refCode': result.data.refCode,
          'subscription.customPlan.price': amount,
          'subscription.customPlan.currency': currency,
        }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
        .then(res => {
          toast.success('Custom plan created successfully!')
          let newSubscription = {
            ...user.subscription,
            customPlan: {
              refCode: result.data.refCode,
              price: amount,
              currency: currency,
            },
          }

          closeModal()
        })
        .catch(err => {
          errorHandler(err)
        })
    } else {
      errorHandler({message: result.data.message})
    }
  }

  return (
    <div className="are-you-sure custom-subscription">
      <div className="question">
        {user.subscription && user.subscription.customPlan ? 'Update ' : 'Set '}
        subscription amount for <b>{user.name}</b>
        {user.subscription && user.subscription.customPlan ? (
          <span
            style={{ fontSize: '0.8em', fontWeight: 'lighter', color: 'red' }}
          >
            <br />
            Note that if you update the plan, the customer has to subscribe all
            over again.
          </span>
        ) : (
          ''
        )}
      </div>
      <div className="amount-container">
        <input type="number" value={amount} onChange={amountChange} />
        <select value={currency} onChange={currencyChange}>
          <option value="USD">USD</option>
          <option value="TRY">TRY</option>
        </select>
      </div>
      <div className="action-bar">
        <button className="action-button no" onClick={no}>
          Cancel
        </button>
        <button className="action-button yes" onClick={createPlan}>
          {user.subscription && user.subscription.customPlan
            ? 'Update '
            : 'Create '}
          Plan
        </button>
      </div>
    </div>
  )
}

export default CustomSubscriptionPlan
