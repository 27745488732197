import { WORKBENCH_SIZE } from '../../../support/constants'
import temp5bg from '../../../../shared/assets/images/ai-creative/temp5bg.svg'

export const Template5Config = {
  name: 'Ripple Wave',
  containerId: 'template5-carousel-single',
  backgroundColor: 'color2',
  elements: [
    // Background SVG
    {
      id: 'background-svg',
      type: 'image',
      position: {
        x: 0,
        y: 0,
      },
      scale: {
        width: WORKBENCH_SIZE.width,
        height: WORKBENCH_SIZE.height,
      },
      src: temp5bg,
      draggable: false,
      clickThrough: true,
      isSvg: true,
      svgColor: 'color1',
    },
    // Company Logo
    {
      id: 'companyLogo',
      type: 'image',
      position: {
        x: 35,
        y: 80,
      },
      scale: {
        maxWidth: 250,
        maxHeight: 140,
      },
      dynamicSrc: 'companyLogo',
      required: false,
    },
    // Product Image
    {
      id: 'productImage',
      type: 'image',
      position: {
        x: WORKBENCH_SIZE.width / 2,
        y: WORKBENCH_SIZE.height / 2,
      },
      scale: {
        maxWidth: 550,
        maxHeight: 550,
      },
      dynamicSrc: 'productImage',
      center: true,
    },
    // Product Name
    {
      id: 'productName',
      type: 'text',
      position: {
        x: 26,
        y: WORKBENCH_SIZE.height - 190,
      },
      width: 360,
      height: 100,
      fontIndex: 1,
      fontSize: 36,
      color: 'color3',
      text: '{{productName}}',
    },
    // Product Price Circle
    {
      id: 'productPriceCircle',
      type: 'circle',
      position: {
        x: WORKBENCH_SIZE.width - 200,
        y: 380,
      },
      radius: 108,
      fill: 'color3',
    },
    // Product Price
    {
      id: 'productPrice',
      type: 'text',
      position: {
        x: WORKBENCH_SIZE.width - 200 - 108,
        y: 380 - 108,
      },
      width: 216,
      height: 216,
      fontSize: 40,
      fontIndex: 2,
      color: 'color2',
      circle: true,
      circleColor: 'color3',
      align: 'center',
      verticalAlign: 'middle',
      text: '{{productPrice}}',
    },
  ],
}
