import React, { useState } from 'react'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { FaCompass } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'

import { useHttpClient } from '../../../shared/hooks/http-hook'
import { adminActions } from '../../../store/admin'

import './CustomerCardNavigationMenu.css'
import { useAdmin } from '../../../shared/hooks/admin-hook'
import errorHandler from '../../../shared/util/errorHandler'

const CustomerCardNavigationMenu = props => {
  const { startViewingAsUser, loginToUser } = useAdmin()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const user = props.user
  const isAgencyPage = props.isAgencyPage
  const goTo = async page => {

    switch (page) {
      case 'Connect':
        if (user.isActive) {
          startViewingAsUser({
            user,
            pageRoute: '/visiting-data-overview',
            openInNewTab: props.openInNewTab,
          })

        } else {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/admin/checkIfDataActive`,
              'POST',
              JSON.stringify({
                userId: user.id,
              }),
              {
                Authorization: 'Bearer ' + auth.token,
                'Content-Type': 'application/json',
              },
            )
            if (responseData.isActive) {

              startViewingAsUser({
                user,
                pageRoute: '/visiting-data-overview',
                openInNewTab: props.openInNewTab,
              })
            } else {
              toast.warn('Data is not connected yet!')
            }
          } catch (err) {
            errorHandler(err)
          }
        }
        break
      case 'Audience':
        if (!user.job) {
          toast.warn('Audience is not created yet!')
        } else if (user.job) {
          if (!user.isReady) {
            toast.warn('Audience creation in progress. Please wait for it.')
          } else {

            startViewingAsUser({
              user,
              pageRoute: `/audience-event-manager`,
              openInNewTab: props.openInNewTab,
            })

            dispatch(adminActions.setNavigatedFromCustomerID(user.id))

          }
        }

        break
      case 'AIAds':
        window.open(`/admin-panel/user-detail/${user.id}?goDirectlyToAIAds=true`, '_blank')
        /* loginToUser({ user: user }) */

        break
      case 'Website':
        if (user.shopify) {
          window.open('https://' + user.shopify.domain, '_blank')
        } else if (user.url) {
          let url = user.url
          if (!url.includes('https://')) {
            url = 'https://' + url
          }
          window.open(url, '_blank')
        } else {
          errorHandler({ message: 'Website URL not defined.' })
        }

        break
    }
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'left', 'left', 'bottom']}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            onClick={e => e.stopPropagation()}
            className={`admin-panel-customer-card-menu ${isAgencyPage ? 'agency-page' : ''}`}
          >
            <div className="wrapper">
              {!isAgencyPage && (
                <div
                  className="nav-button"
                onClick={() => {
                  goTo('Connect')
                }}
              >
                Connect
              </div>
              )}
              {!isAgencyPage && (
              <div
                className="nav-button"
                onClick={() => {
                  goTo('Audience')
                }}
              >
                Audience
                </div>
              )}

              <div
                className="nav-button"
                onClick={() => {
                  goTo('AIAds')
                }}
              >
                AI Ads
              </div>

              <div
                className="nav-button"
                onClick={() => {
                  goTo('Website')
                }}
                title="Visit website"
              >
                Website
              </div>
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="admin-panel-customer-card-menu-icon"
        onClick={e => {
          e.stopPropagation()
          setIsPopoverOpen(!isPopoverOpen)
        }}
      >
        <FaCompass className='icon' title="Navigate" />
      </div>
    </Popover>
  )
}

export default CustomerCardNavigationMenu
