import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaCheck, FaSearch , FaChevronDown } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useFacebook } from '../../../shared/hooks/facebook-hook'
import { useEnhencedCatalog } from '../../../shared/hooks/enhenced-catalog-hook'
import { aiCreativeEditorActions } from '../../../store/ai-creative-editor'
import { useDebounce } from '../../../shared/hooks/debounce-hook'
import helper from '../../../shared/util/helper'
import ProductSetDropdownHover from '../../../ai-ads/components/campaign/ProductSetDropdownHover'
import CustomDropdown from '../../../ai-ads/components/campaign/CustomDropdown'
import Button from '../../../shared/components/FormElements/Button'
import InlineLoadingSpinner from '../../../shared/components/UIElements/InlineLoadingSpinner'
import Tooltip from '../../../shared/components/UIElements/Tooltip'

import styles from './ProductsSelectionList.module.css'
import useNavigator from '../../../shared/hooks/navigator-hook'
import errorHandler from '../../../shared/util/errorHandler'

const ProductsSelectionList = () => {
  const dispatch = useDispatch()
  const { navigateWithParams } = useNavigator()

  const {
    getCatalogs: getCatalogsFromFacebook,
    getProductSetsOfCatalog,
    getProductsOfEdge,
  } = useFacebook()
  const {
    loadCachedSelectedProducts,
    selectedProductsWithConfigurations,
    removeBackgroundImages,
  } = useEnhencedCatalog()
  const catalogs = useSelector(state => state.facebook.catalogs)
  const productSets = useSelector(state => state.facebook.productSets)
  const facebookReady = useSelector(
    state => state.facebook.connectionPageIsReady,
  )
  const [selectedCatalog, setSelectedCatalog] = useState(catalogs[0])
  const [selectedProductSet, setSelectedProductSet] = useState()
  const [productsList, setProductsList] = useState([])
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText)
  const [isLoading, setIsLoading] = useState(true)
  const [paginationCursor, setPaginationCursor] = useState(null)
  const [fetchingNextPage, setFetchingNextPage] = useState(false)
  const hasSearched = useRef(false)

  const getCatalogs = async () => {
    const newCatalogs = await getCatalogsFromFacebook({})
    setSelectedCatalog(newCatalogs[0])
  }

  const getProductSets = async catalogId => {
    if (!catalogId) return

    setIsLoading(true)
    const newProductSets = await getProductSetsOfCatalog({ catalogId })
    setSelectedProductSet(newProductSets[0])
    setIsLoading(false)
  }

  const getProducts = async ({ edgeName, edgeId, filter, after }) => {
    setIsLoading(true)
    const config = {
      edgeName,
      edgeId,
      fields:
        'image_url,name,price,url,retailer_id,retailer_product_group_id,description,gtin,currency,product_set',
    }

    if (filter) config.filter = filter
    if (after) config.after = after

    const { products: newProducts, paging } = await getProductsOfEdge(config)

    if (!newProducts || newProducts.length < 25) {
      setPaginationCursor(null)
    } else {
      setPaginationCursor(paging?.cursors?.after)
    }

    if (after) {
      setProductsList([...productsList, ...newProducts])
    } else {
      setProductsList(newProducts)
    }
    setIsLoading(false)
  }

  const searchProducts = async query => {
    const filter = JSON.stringify({
      name: {
        contains: query,
      },
    })

    await getProducts({
      edgeName: selectedProductSet ? 'productSet' : 'catalog',
      edgeId: selectedProductSet ? selectedProductSet?.id : selectedCatalog?.id,
      filter,
    })
  }

  const toggleProduct = product => {
    const index = helper.findIndex(
      selectedProductsWithConfigurations,
      'id',
      product.id,
    )
    const newSelectedProducts = [...selectedProductsWithConfigurations]

    if (index >= 0) {
      newSelectedProducts.splice(index, 1) // Remove product from newSelectedProducts
      dispatch(aiCreativeEditorActions.setSelectedProducts(newSelectedProducts))
      return
    }

    if (newSelectedProducts.length >= 10) {
      errorHandler({message: 'You can select up to 10 products'})
      return
    }

    newSelectedProducts.push({
      id: product.id,
      retailer_id: product.retailer_id,
      retailer_product_group_id: product.retailer_product_group_id,
      name: product.name,
      description: product.description,
      price: product.price,
      currency: product.currency,
      url: product.url,
      gtin: product.gtin,
      originalImageUrl: product.image_url,
      transparentImageUrl: null,
    })

    dispatch(aiCreativeEditorActions.setSelectedProducts(newSelectedProducts))
  }

  const handleNextButton = async () => {
    navigateWithParams('/ai-ads/creative-lab/editor')
    removeBackgroundImages()
  }

  const handleLoadMoreButton = async () => {
    if (!paginationCursor) return
    setFetchingNextPage(true)
    await getProducts({
      edgeName: selectedProductSet ? 'productSet' : 'catalog',
      edgeId: selectedProductSet ? selectedProductSet.id : selectedCatalog.id,
      after: paginationCursor,
    })
    setFetchingNextPage(false)
  }

  // Check if there are selected products in session storage and set them
  useEffect(() => {
    loadCachedSelectedProducts()
  }, [])

  // Fetch catalogs and set the first one as selected
  useEffect(() => {
    if (!facebookReady || catalogs.length) return
    getCatalogs()
  }, [facebookReady])

  // Fetch product sets of selected catalog and set the first one as selected
  useEffect(() => {
    if (
      !facebookReady ||
      !selectedCatalog ||
      productSets[selectedCatalog]?.length
    )
      return
    getProductSets(selectedCatalog.id)
  }, [selectedCatalog])

  // Fetch products when selected product set changes
  useEffect(() => {
    if (!facebookReady || !selectedProductSet) return
    getProducts({
      edgeName: 'productSet',
      edgeId: selectedProductSet.id,
    })
  }, [selectedProductSet, facebookReady])

  // Search products when debounced search text changes
  useEffect(() => {
    if (!facebookReady || !hasSearched.current) {
      return
    }
    searchProducts(debouncedSearchText)
    hasSearched.current = true
  }, [debouncedSearchText])

  return (
    <div className={styles.productSelectionList}>
      <div className={styles.leftSide}>
        <div className={styles.mainColumn}>
          <div className={styles.leftSideHeader}>
            {catalogs.length > 0 && (
              <>
              <div className={styles.dropdown}>
                <div className={styles.row}>
                  <label className={styles.label}>Catalog</label>
                  <CustomDropdown
                    placeholder="Select catalog..."
                    initialValue={selectedCatalog}
                    forcedValue={selectedCatalog}
                    options={catalogs}
                    isSingleLine={true}
                    onChange={c => setSelectedCatalog(c)}
                    onHover={(edgeId, product_count) => (
                      <ProductSetDropdownHover
                        edgeId={edgeId}
                        productCount={product_count}
                      />
                    )}
                  />
                </div>
                {selectedCatalog && productSets[selectedCatalog.id] && (
                  <div className={styles.row}>
                    <label className={styles.label}>Product Set</label>
                    <CustomDropdown
                      placeholder="Select product set..."
                      initialValue={selectedProductSet}
                      forcedValue={selectedProductSet}
                      options={productSets[selectedCatalog.id]}
                      isSingleLine={true}
                      onChange={p => setSelectedProductSet(p)}
                      onHover={(edgeId, product_count) => (
                        <ProductSetDropdownHover
                          edgeId={edgeId}
                          productCount={product_count}
                        />
                      )}
                    />
                  </div>
                )}
                </div>
              </>
            )}
            <div className={styles.searchBarContainer}>
              <FaSearch className={styles.searchIcon} />
              <input
                className={styles.searchBar}
                onChange={e => {
                  setSearchText(e.target.value)
                  hasSearched.current = true
                }}
                type="text"
                placeholder="Search products"
              />
            </div>
          </div>
          <div className={styles.productsList}>
            {isLoading ? (
              <InlineLoadingSpinner message="Loading products from Facebook..." />
            ) : productsList?.length ? (
              <div className={styles.productsGrid}>
                {productsList.map(product => (
                  <div
                    className={styles.productContainer}
                    key={product.id}
                    onClick={() => {
                      toggleProduct(product)
                    }}
                  >
                    <div className={styles.selectCircle}>
                      {selectedProductsWithConfigurations
                        .map(p => p.id)
                        .includes(product.id) ? (
                        <FaCheck className={styles.icon} />
                      ) : null}
                    </div>
                    <div className={styles.productImage}>
                      <img src={product.image_url} alt={product.name} />
                    </div>
                    <div className={styles.price}>{product.price}</div>
                    <div className={styles.nameCont}>
                      <div className={styles.productName}>{product.name}</div>
                    </div>
                  </div>
                ))}
                {paginationCursor && (
                  <Button
                    className={styles.loadMoreButton}
                    disabled={fetchingNextPage}
                    loading={fetchingNextPage}
                    onClick={handleLoadMoreButton}
                  >
                    <FaChevronDown className={styles.loadMoreIcon} />{' '}
                  
                    Load More
                  </Button>
                )}
              </div>
            ) : (
              <div className={styles.helperText}>No products found</div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.rightSide}>
        <div className={styles.productsPreviewer}>
          <div className={styles.rightSideHeader}>
            <h4>
              Selected Products ({selectedProductsWithConfigurations.length})
            </h4>
          </div>
          {selectedProductsWithConfigurations?.length ? (
            <div className={styles.selectedProductsList}>
              <div className={styles.productsGrid}>
                {selectedProductsWithConfigurations.map(product => (
                  <div
                    className={styles.productContainer}
                    key={product.id}
                    onClick={() => {
                      toggleProduct(product)
                    }}
                  >
                    <div className={styles.selectCircle}>
                      {selectedProductsWithConfigurations
                        .map(p => p.id)
                        .includes(product.id) ? (
                        <FaCheck className={styles.icon} />
                      ) : null}
                    </div>
                    <div className={styles.productImage}>
                      <img src={product.originalImageUrl} alt={product.name} />
                    </div>
                    <div className={styles.price}>{product.price}</div>
                    <div className={styles.nameCont}>
                      <div className={styles.productName}>{product.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className={styles.helperText}>Start selecting products</div>
          )}
        </div>
        <Tooltip
          content="You must select at least 2 products"
          isHidden={selectedProductsWithConfigurations.length >= 2}
          position="top"
        >
          <Button
            className={styles.nextButton}
            primary={true}
            onClick={handleNextButton}
            disabled={selectedProductsWithConfigurations.length < 2}
          >
            Next
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

export default ProductsSelectionList
