import React, { useMemo, useState, useEffect } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import items from '../../../shared/data/success-stories.json'

import HeaderBackground from '../../../shared/assets/images/ai-ads/success-stories-slider-header-bg.png'

import SPXBackground from '../../../shared/assets/images/ai-ads/1.png'
import SporjinalBackground from '../../../shared/assets/images/ai-ads/3.png'
import LacosteBackground from '../../../shared/assets/images/ai-ads/2.png'
import SuiSleepBackground from '../../../shared/assets/images/ai-ads/4.png'
import JebindeBackground from '../../../shared/assets/images/ai-ads/5.png'

import './SuccessStoriesSlider.css'

const SuccessStoriesSlider = () => {
  const SLIDE_INTERVAL = 5000; // 5 seconds
  
  const backgroundImages = {
    spx: SPXBackground,
    sporjinal: SporjinalBackground,
    lacoste: LacosteBackground,
    suiSleep: SuiSleepBackground,
    jebinde: JebindeBackground,
  }

  const [currentItemInfo, setCurrentItemInfo] = useState({
    idx: 0,
    isOpen: false,
  })

  const currentItem = useMemo(
    () => items[currentItemInfo.idx],
    [currentItemInfo.idx],
  )

  useEffect(() => {
    let intervalId;

    const startInterval = () => {
      intervalId = setInterval(() => {
        setCurrentItemInfo(prev => ({
          idx: prev.idx === items.length - 1 ? 0 : prev.idx + 1,
          isOpen: false,
        }));
      }, SLIDE_INTERVAL);
    };

    const stopInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };

    const sliderElement = document.querySelector('.success-stories-slider');
    
    if (sliderElement) {
      sliderElement.addEventListener('mouseenter', stopInterval);
      sliderElement.addEventListener('mouseleave', startInterval);
    }

    startInterval();

    return () => {
      stopInterval();
      if (sliderElement) {
        sliderElement.removeEventListener('mouseenter', stopInterval);
        sliderElement.removeEventListener('mouseleave', startInterval);
      }
    };
  }, [items.length]);

  const handlePrev = (e) => {
    e.stopPropagation()
    if (currentItemInfo.idx === 0) {
      return
    }
    setCurrentItemInfo(prev => ({
      idx: prev.idx - 1,
      isOpen: false,
    }))
  }

  const handleNext = (e) => {
    e.stopPropagation()
    if (currentItemInfo.idx === items.length - 1) {
      return
    }
    setCurrentItemInfo(prev => ({
      idx: prev.idx + 1,
      isOpen: false,
    }))
  }

  const extractTitle = description => {
    const firstExclamationIndex = description.indexOf('!')
    return firstExclamationIndex !== -1 
      ? description.substring(0, firstExclamationIndex + 1)
      : description
  }

  return (
    <div className="success-stories-slider">
      <h2 className="title">Latest Success Stories</h2>
      <div className="content">
        <div 
          className="company"
          onClick={() => window.open(currentItem.url, '_blank', 'noopener,noreferrer')}
          style={{ cursor: 'pointer' }}
        >
          <div className="main">
            <div className="texts">
              <div className="title">
                {extractTitle(currentItem.description)
                  .split(' ')
                  .map((word, index) => (
                    <span key={index} className={index === 0 ? 'value' : ''}>
                      {word}
                      {index !== extractTitle(currentItem.description).split(' ').length - 1 ? ' ' : ''}
                    </span>
                  ))}
              </div>
              <p className="description">
                {currentItem.description.substring(currentItem.description.indexOf('!') + 1).trim()}
              </p>
              <div className="metrics">
                {currentItem.metrics.map((m, index) => (
                  <span key={`${m.name}-${index}`} className="metric">
                    <span className="value">{m.value}</span>
                    <span className="name">{m.name}</span>
                  </span>
                ))}
              </div>
            </div>
            <div className="image">
              <img
                className="background-image"
                src={backgroundImages[currentItem.company]}
                alt="Background"
              />
            </div>
          </div>
          <div className="slider-indicators">
            {items.map((_, index) => (
              <div
                key={index}
                className={`slider-indicator ${
                  index === currentItemInfo.idx ? 'active' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation()
                  setCurrentItemInfo(prev => ({
                    ...prev,
                    idx: index,
                  }))
                }}
              />
            ))}
          </div>
        </div>
      </div>

      {items.length > 1 && (
        <React.Fragment>
          <div
            onClick={handlePrev}
            className={`controller prev${
              currentItemInfo.idx === 0 ? ' disabled' : ''
            }`}
          >
            <FaAngleLeft className="icon" />
          </div>
          <div
            onClick={handleNext}
            className={`controller next${
              currentItemInfo.idx === items.length - 1 ? ' disabled' : ''
            }`}
          >
            <FaAngleRight className="icon" />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default SuccessStoriesSlider